import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import ModuleContainer from "./Parts/ModuleContainer";
import searchOpen from "../../images/icons/down_arrow_gray.svg";
import searchIcon from "../../images/icons/search.svg";
import { color } from "../mixins";
import { TagItem } from "store/manual/manuals/pages/types.d";
import {
  getPagesSearchRequest,
  setSearchKeywordAction,
  setSearchTagsAction
} from "store/manual/manuals/pages/actions";
import {
  searchKeywordSelector,
  searchTagsSelector
} from "store/manual/manuals/pages/selectors";
import { history } from "store/store";
import { useLocation } from "react-router";

interface SerchModuleProps {
  manualId?: number;
  topicId?: number;
  data: TagItem[];
}

const SearchModuleForManual: React.FC<SerchModuleProps> = props => {
  const { data, manualId } = props;
  console.log("manualId", manualId);

  const [open, setOpen] = React.useState<boolean>(
    window.innerWidth > 1024 ? true : false
  );

  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const storedKeyword = useSelector(searchKeywordSelector);
  const storedTags = useSelector(searchTagsSelector);

  const handleKeywordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchKeywordAction(e.target.value));
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (storedTags && storedTags.some((tag: TagItem) => tag.id === id)) {
      dispatch(
        setSearchTagsAction({
          oldTags: storedTags.filter((tag: TagItem) => tag.id !== id)
        })
      );
    } else {
      dispatch(
        setSearchTagsAction({
          oldTags: storedTags ? storedTags : [],
          tagToAdd: data.find(tag => tag.id === id)
        })
      );
    }
  };

  const location = useLocation();
  const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const named_storedTags = storedTags.map((tag: TagItem) => tag.name);

    // 検索用タグ文字列
    let connected_tags_query = "";
    named_storedTags.forEach((tag: string) => {
      connected_tags_query += `&tags[]=${tag}`;
    });

    // 移動
    if (location.pathname.includes("admin")) {
      history.push(
        `/manual/admin/results?keyword=${storedKeyword}${connected_tags_query}`
      );
    } else {
      history.push(
        `/manual/results?keyword=${storedKeyword}${connected_tags_query}`
      );
    }

    console.log("manualId", manualId);

    // アクション
    dispatch(
      getPagesSearchRequest({
        token,
        tags: named_storedTags,
        keyword: storedKeyword
      })
    );
  };

  const handleTagsClear = () => {
    dispatch(
      setSearchTagsAction({
        oldTags: []
      })
    );
  };

  console.log("datadatadata", data);

  return (
    <ModuleContainer>
      <ArticlesSearchBlockHeaderSC onClick={() => setOpen(!open)}>
        <ArticlesSearchBlockHeadingSC>検索条件</ArticlesSearchBlockHeadingSC>
        <ToggleArrowSC
          className={open ? "open" : ""}
          src={searchOpen}
          alt="toggleArrow"
        />
      </ArticlesSearchBlockHeaderSC>

      {/* 検索条件 */}
      <SearchForm className={open ? "open" : ""} action="">
        <SearchContentsSC>
          {/* キーワード */}
          <SearchContentsLabelSC>キーワード</SearchContentsLabelSC>
          <dd>
            <KeywordInput
              type="text"
              placeholder="キーワードを入力"
              value={storedKeyword}
              onChange={handleKeywordInput}
            />
          </dd>
        </SearchContentsSC>

        {/* タグ */}
        <SearchContentsSC>
          <SearchContentsLabelSC>タグ</SearchContentsLabelSC>
          <ClearButton onClick={handleTagsClear}>クリア</ClearButton>
          <dd>
            <TagsList>
              {data && data.length > 0
                ? data.map(val => {
                    return (
                      <TagsItem key={`${val.id}`}>
                        <CheckboxLabel htmlFor={`tag${val.id}`}>
                          <Checkbox
                            className="checkbox"
                            id={`tag${val.id}`}
                            type="checkbox"
                            value={val.name}
                            onChange={e => {
                              handleCheck(e, val.id);
                            }}
                            checked={
                              storedTags &&
                              storedTags.some(
                                (tag: TagItem) => tag.id === val.id
                              )
                            }
                          />
                          <CheckboxSpan className="label">
                            {val.name}({val.count ? `${val.count}` : "0"})
                          </CheckboxSpan>
                        </CheckboxLabel>
                      </TagsItem>
                    );
                  })
                : []}
            </TagsList>
          </dd>
        </SearchContentsSC>
        <SearchButton onClick={handleSearch}>
          <img src={searchIcon} alt="search icon" />
          この条件で検索
        </SearchButton>
      </SearchForm>
    </ModuleContainer>
  );
};

export default SearchModuleForManual;

// ===================================================================================
// スタイル
// ===================================================================================

const ArticlesSearchBlockHeaderSC = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArticlesSearchBlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;
// =====================================
// SerchForm  ==========================
const SearchForm = styled.form`
  margin-top: 20px;
  display: none;
  max-height: calc(100vh - 290px);
  overflow: auto;
  &.open {
    display: block;
  }
`;

const SearchContentsSC = styled.dl`
  position: relative;
  margin-bottom: 8px;
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.gray};
  margin-bottom: 12px;
`;

const KeywordInput = styled.input`
  display: block;
  width: 100%;
  padding: 0px 0px 12px 6px;
  border-bottom: 1px solid ${color.divide_border};
  margin-bottom: 20px;
  &::placeholder {
    font-size: 12px;
    color: ${color.category_bg};
  }
`;

const TagsList = styled.ul`
  display: inline-block;
`;
const TagsItem = styled.li`
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 20px;
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.gray};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.gray};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
const SearchButton = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: ${color.white};
  background-color: ${color.gray};
  font-weight: bold;
  padding: 9px;
  font-size: 12px;
  border-radius: 4px;
  img {
    transform: translateY(-1px);
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
`;
// SerchForm  ==========================
//======================================

const ClearButton = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${color.light_gray};
  background: ${color.light_gray};
  color: ${color.white};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;
