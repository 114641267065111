import React, { useState } from "react";
import styled from "styled-components";
import { pc } from "../mixins/mediaQuery";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { color } from "../mixins";
import {
  event_home,
  event_home_current,
  event_add,
  event_add_current,
  event_approval,
  event_approval_current,
  event_approval_status,
  event_approval_status_current,
  event_csv_appload,
  event_csv_appload_current,
  account_white,
  faq,
  faq_gray,
  account_current,
  account_list,
  account_list_event_current,
  account_register,
  account_register_event_current,
  up,
  down,
  sidebar_close,
  sidebar_open,
  news,
  news_current,
  inquiry,
  inquiry_current,
  inquiry_history,
  inquiry_history_event_current,
  inquiry_form,
  inquiry_form_event_current,
  news_list,
  news_list_event_current,
  news_register,
  news_register_event_current,
  manual,
  manual_current,
  camera,
  camera_current
} from "./sidebarIcons";
import {
  toggleSidebar,
  toggleInquiry
} from "../../store/common/sidebar/action";
import { setSelectedMonthToApproval } from "../../store/event/approval/action";

function SidebarAdmin() {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();
  const isOpen = useSelector((state: any) => state.sidebar.sidebarIsOpen);
  const inquiryIsOpen = useSelector(
    (state: any) => state.sidebar.inquiryIsOpen
  );

  return (
    <SidebarSC className={isOpen ? "" : "closed"}>
      <SidebarList>
        <SideBarItem>
          <SideBarLabel onClick={() => dispatch(toggleSidebar())}>
            &nbsp;
            {isOpen ? (
              <SidebarArrow src={sidebar_close} alt="close" />
            ) : (
              <SidebarArrow src={sidebar_open} alt="open" />
            )}
          </SideBarLabel>
        </SideBarItem>

        <SideBarItem style={{ height: "46px" }}>
          <SidebarServiceMenu>{isOpen && "サービス"}</SidebarServiceMenu>
        </SideBarItem>

        {/* FAQ TOP */}
        <SideBarItem style={{ height: "50px" }}>
          <SidebarTitle to="/" className="faqHome">
            {isOpen && "FAQ"}
          </SidebarTitle>
        </SideBarItem>

        {/* TOP */}
        <SideBarItem
          className={pathName === "/events/home" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/events/home" className="home">
            {isOpen && "行事予定"}
          </SidebarTitle>
        </SideBarItem>

        {/*  マニュアルTOP */}
        {/* <SideBarItem
          className={pathName === "/manual" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/manual" className="manual">
            {isOpen && "マニュアル"}
          </SidebarTitle>
        </SideBarItem> */}
        <SideBarItem
          className={pathName === "/camera" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/camera" className="camera">
            {isOpen && "アルバム管理"}
          </SidebarTitle>
        </SideBarItem>

        {/* お知らせ */}
        <SideBarItem style={{ height: "50px" }}>
          <SidebarTitle to="/news" className="news_list">
            {isOpen && "お知らせ"}
          </SidebarTitle>
        </SideBarItem>

        {/* お問合せ */}
        <SideBarItem
          className={inquiryIsOpen ? "active inquiries_list" : "inquiries_list"}
        >
          <SideBarLabel
            className="inquiry"
            onClick={() => dispatch(toggleInquiry())}
          >
            {isOpen && "お問合せ"}
            {isOpen && inquiryIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !inquiryIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {inquiryIsOpen ? (
            <ul>
              <FoldingItem className="inquiries_list">
                <Link to="/inquiries">{isOpen && "お問合せ履歴"}</Link>
              </FoldingItem>
              <FoldingItem className="inquiries_new">
                <Link to="/inquiries/new">{isOpen && "お問合せフォーム"}</Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        <SideBarItem style={{ height: "60px" }}>
          <SidebarAdminMenu>{isOpen && "メニュー"}</SidebarAdminMenu>
        </SideBarItem>

        {/* 承認・申請 */}
        <SideBarItem
          className={pathName === "/events/approvals" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle
            to="/events/approvals"
            className="approval"
            onClick={() => dispatch(setSelectedMonthToApproval(null))}
          >
            {isOpen && "承認・申請"}
          </SidebarTitle>
        </SideBarItem>

        {/* 承認状況 */}
        <SideBarItem
          className={pathName === "/events/approvals/status" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle
            to="/events/approvals/status"
            className="approval_status"
          >
            {isOpen && "承認状況"}
          </SidebarTitle>
        </SideBarItem>
      </SidebarList>
    </SidebarSC>
  );
}

export default SidebarAdmin;

// ===================================================================================
// スタイル
// ===================================================================================
const SidebarSC = styled.aside`
  position: relative;
  min-width: 200px;
  height: calc(100vh - 60px);
  background: ${color.gray};
  overflow-x: hidden;
  overflow-y: scroll;
  transition-duration: 0.2s;
  display: none;
  ${pc`
    display: block;
    position: fixed;
    min-width: 200px;
    height: calc( 100vh - 60px );
    background: ${color.gray};
    overflow-x: hidden;
    overflow-y: scroll;
    transition-duration: 0.2s;
    top: 60px;
  `}
  &.closed {
    max-width: 50px;
    min-width: 50px;
    transition-duration: 0.2s;
  }
`;

const SidebarServiceMenu = styled.p`
  color: rgba(255, 255, 255, 0.5);
  padding: 15px 0px 18px 50px;
  height: 46px;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
`;

const SidebarAdminMenu = styled.p`
  color: rgba(255, 255, 255, 0.5);
  padding: 28px 0px 18px 50px;
  height: 60px;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
`;
const SidebarTitle = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  // border-bottom: 1px solid ${color.light_gray};border-bottom
  box-sizing: border-box;
  &:hover {
    background-color: ${color.white};
    color: ${color.gray};
  }
  &.home {
    background-image: url(${event_home});
    &:hover,
    &.current {
      background-image: url(${event_home_current});
    }
  }
  &.faqHome {
    background-image: url(${faq});
    &:hover,
    &.current {
      background-image: url(${faq_gray});
    }
  }
  &.adminMenu {
    padding-top: 30px;
    color: rgba(255, 255, 255, 0.5);
  }
  &.add_edit {
    background-image: url(${event_add});
    &:hover,
    &.current {
      background-image: url(${event_add_current});
    }
  }
  &.approval {
    background-image: url(${event_approval});
    &:hover,
    &.current {
      background-image: url(${event_approval_current});
    }
  }
  &.approval_status {
    background-image: url(${event_approval_status});
    &:hover,
    &.current {
      background-image: url(${event_approval_status_current});
    }
  }
  &.account {
    background-image: url(${account_white});
    &:hover,
    &.current {
      background-image: url(${account_current});
    }
  }
  &.csv {
    background-image: url(${event_csv_appload});
    &:hover,
    &.current {
      background-image: url(${event_csv_appload_current});
    }
  }
  &.news_list {
    background-image: url(${news});
    &:hover,
    &.current {
      background-image: url(${news_current});
    }
  }
  &.manual {
    background-image: url(${manual});
    &:hover,
    &.current {
      background-image: url(${manual_current});
    }
  }
  &.camera {
    background-image: url(${camera});
    &:hover,
    &.current {
      background-image: url(${camera_current});
    }
  }
`;

const SidebarList = styled.ul`
  width: 100%;
`;

const SideBarLabel = styled.p`
  position: relative;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  white-space: nowrap;
  &.account {
    background-image: url(${account_white});
    &:hover,
    &.current {
      background-image: url(${account_current});
    }
  }
  &.news {
    background-image: url(${news});
    &:hover,
    &.current {
      background-image: url(${news_current});
    }
  }
  &.inquiry {
    background-image: url(${inquiry});
    &:hover,
    &.current {
      background-image: url(${inquiry_current});
    }
  }
  &:hover {
    background-color: ${color.white};
    color: ${color.gray};
  }
`;

const Arrow = styled.img`
  position: absolute;
  right: 16px;
  top: 23px;
`;

const SidebarArrow = styled(Arrow)`
  top: 18px;
`;

const SideBarItem = styled.li`
  height: auto;
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
  cursor: pointer;
  &.active {
    background-color: rgba(242, 242, 242, 0.3);
    > p.account {
      background-image: url(${account_current});
    }
  }
  &.inquiries_list {
    border-bottom: 1px solid ${color.light_gray};
  }
`;

const FoldingItem = styled.li`
  height: 50px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  &:hover {
    background-color: ${color.white};
    color: ${color.gray};
  }
  &.account_list {
    background-image: url(${account_list});
    &:hover,
    &.current {
      background-image: url(${account_list_event_current});
    }
  }
  &.account_register {
    background-image: url(${account_register});
    &:hover,
    &.current {
      background-image: url(${account_register_event_current});
    }
  }
  &.inquiries_list {
    background-image: url(${inquiry_history});
    &:hover,
    &.current {
      background-image: url(${inquiry_history_event_current});
    }
  }
  &.inquiries_new {
    background-image: url(${inquiry_form});
    &:hover,
    &.current {
      background-image: url(${inquiry_form_event_current});
    }
  }
  &.news_list {
    background-image: url(${news_list});
    &:hover,
    &.current {
      background-image: url(${news_list_event_current});
    }
  }
  &.news_register {
    background-image: url(${news_register});
    &:hover,
    &.current {
      background-image: url(${news_register_event_current});
    }
  }

  a,
  button {
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    padding: 18px 0 18px 50px;
  }
`;
