import React from "react";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import PageHeading from "../../../Common/PageHeading";
import ArticlesStocksListBlock from "./ArticlesStocksListBlock";
import ArticlesStocksSearchBlock from "./ArticlesStocksSearchBlock";

function Articles() {
  return (
    <MainSC>
      <PageHeading title="ブックマーク" />

      <ArticlesStocksSearchBlock />

      <ArticlesStocksListBlock />
    </MainSC>
  );
}

export default Articles;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;
