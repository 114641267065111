import { pc } from "components/mixins/mediaQuery";
import React from "react";
import styled from "styled-components";
import ManualBreadcrumb from "./ManualBreadcrumbForResults";
import ManualHeader from "./ManualHeader";

interface PageLayoutProps {
  pageTitle: string;
  toTopicsPath?: string;
  topicTitle?: string;
  toPagesPath?: string;
  currentPageTitle?: string;
  breadcrambIsInvisible?: boolean;
  children: React.ReactNode;
}

// MainBlockだけのとき
export const PageLayout = (props: PageLayoutProps) => {
  const {
    pageTitle,
    toTopicsPath,
    topicTitle,
    toPagesPath,
    currentPageTitle,
    breadcrambIsInvisible,
    children
  } = props;
  return (
    <Container>
      <ManualHeader pageTitle={pageTitle} />
      <ManualBreadcrumb
        toTopicsPath={toTopicsPath}
        topicTitle={topicTitle}
        toPagesPath={toPagesPath}
        currentPageTitle={currentPageTitle}
        breadcrambIsInvisible={breadcrambIsInvisible}
      />
      {children}
    </Container>
  );
};

//横に検索などのモジュールがある時
export const FlexPageLayout = (props: PageLayoutProps) => {
  const {
    pageTitle,
    toTopicsPath,
    topicTitle,
    toPagesPath,
    currentPageTitle,
    breadcrambIsInvisible,
    children
  } = props;
  return (
    <Container>
      <ManualHeader pageTitle={pageTitle} />
      <ManualBreadcrumb
        toTopicsPath={toTopicsPath}
        topicTitle={topicTitle}
        toPagesPath={toPagesPath}
        currentPageTitle={currentPageTitle}
        breadcrambIsInvisible={breadcrambIsInvisible}
      />
      <FlexWrapper>{children}</FlexWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 12px;
  ${pc`
    padding: 0px 30px;
  `}
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;
