const POST_INQUIRY_NEW_REQUESTED = "POST_INQUIRY_NEW_REQUESTED";
const POST_INQUIRY_NEW_SUCCEEDED = "POST_INQUIRY_NEW_SUCCEEDED";
const POST_INQUIRY_NEW_FAILED = "POST_INQUIRY_NEW_FAILED";

const POST_INQUIRY_MANAGEMENT_REQUESTED = "POST_INQUIRY_MANAGEMENT_REQUESTED";
const POST_INQUIRY_MANAGEMENT_SUCCEEDED = "POST_INQUIRY_MANAGEMENT_SUCCEEDED";
const POST_INQUIRY_MANAGEMENT_FAILED = "POST_INQUIRY_MANAGEMENT_FAILED";

const SET_INQUIRY_INPUT_NEW = "SET_INQUIRY_INPUT_NEW";

const TOGGLE_INQUIRY_NEW_POST_STATUS = "TOGGLE_INQUIRY_NEW_POST_STATUS";

const SET_TERMINAL = "SET_TERMINAL";

const RESET_POSTED_DETAILS = "RESET_POSTED_DETAILS";

export {
  POST_INQUIRY_NEW_REQUESTED,
  POST_INQUIRY_NEW_SUCCEEDED,
  POST_INQUIRY_NEW_FAILED,
  SET_INQUIRY_INPUT_NEW,
  TOGGLE_INQUIRY_NEW_POST_STATUS,
  SET_TERMINAL,
  RESET_POSTED_DETAILS,
  POST_INQUIRY_MANAGEMENT_REQUESTED,
  POST_INQUIRY_MANAGEMENT_SUCCEEDED,
  POST_INQUIRY_MANAGEMENT_FAILED
};
