import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import {
  getNewsDetailRequest,
  postNewsDetailReadRequest,
} from "../../../../store/global/news_detail/action";
import tag from "../../../../images/icons/tag.svg";
import clock from "../../../../images/icons/clock.svg";
import WysiwygNewsCurrentView from "./WysiwygNewsCurrentView";

function NewsEditPreview(props: any) {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);

  const current_news_title = useSelector(
    (state: any) => state.news_detail.title
  );
  const current_news_content = useSelector(
    (state: any) => state.news_detail.content
  );

  const edit_news_id = useSelector((state: any) => state.news_edit.id);

  useEffect(() => {
    dispatch(getNewsDetailRequest(token, edit_news_id));
  }, [dispatch, token, edit_news_id]);

  return (
    <>
      <MainSC>
        <DetailHeaderSC>
          {/* タイトル */}
          <NewsTitleSC>{current_news_title}</NewsTitleSC>
        </DetailHeaderSC>

        {/* コンテンツ */}
        <DetailContentsSC>
          {current_news_content && typeof current_news_content === "string" && (
            <>
              <WysiwygNewsCurrentView content={current_news_content} />
            </>
          )}
        </DetailContentsSC>
      </MainSC>
    </>
  );
}

export default NewsEditPreview;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
`;

const DetailHeaderSC = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  ${pc`
    padding: 30px;
  `}
`;

const NewsTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
`;
