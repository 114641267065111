import { GET_EVENT_CSV_REQUESTED } from './type'

export const getEventCSVRequest = (token: string, facility_id: number, year: number) => {
  console.log('getEventCSVRequest')
  return {
    type: GET_EVENT_CSV_REQUESTED,
    token,
    facility_id,
    year
  };
};