import { GET_EVENTS_LIST_SUCCEEDED } from "../events/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_MONTHLY_EVENTS_LIST_FAILED,
  DELETE_MONTHLY_EVENTS_SUCCEEDED,
  DELETE_MONTHLY_EVENTS_FAILED,
  POST_MONTHLY_EVENTS_SUCCEEDED,
  POST_MONTHLY_EVENTS_FAILED,
  PUT_MONTHLY_EVENTS_SUCCEEDED,
  PUT_MONTHLY_EVENTS_FAILED,
  GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_ALL_MONTHLY_EVENTS_LIST_FAILED,
} from "./type";

const initialState = {
  monthly_plans: [],
  agricultural_plans: [],
  all_monthly_plans: [],
  all_agricultural_plans: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MONTHLY_EVENTS_LIST_SUCCEEDED:
      const monthly_plans = action.monthly_events.filter((item: any) => {
        return item.type.id === 52;
      });
      const agricultural_plans = action.monthly_events.filter((item: any) => {
        return item.type.id === 53;
      });
      return {
        ...state,
        monthly_plans: monthly_plans,
        agricultural_plans: agricultural_plans,
      };

    case GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED:
      const all_monthly_plans = action.all_monthly_events.filter(
        (item: any) => {
          return item.type.id === 52;
        }
      );
      const all_agricultural_plans = action.all_monthly_events.filter(
        (item: any) => {
          return item.type.id === 53;
        }
      );
      return {
        ...state,
        all_monthly_plans: all_monthly_plans,
        all_agricultural_plans: all_agricultural_plans,
      };

    case POST_MONTHLY_EVENTS_SUCCEEDED:
      return {
        ...state,
        monthly_plans: action.monthly_events.filter((item: any) => {
          return item.type.id === 52;
        }),
        agricultural_plans: action.monthly_events.filter((item: any) => {
          return item.type.id === 53;
        }),
      };

    case POST_MONTHLY_EVENTS_FAILED:
      console.log("POST_MONTHLY_EVENTS_FAILED", action.message);
      return {
        ...state,
      };

    case PUT_MONTHLY_EVENTS_SUCCEEDED:
      return {
        ...state,
        monthly_plans: action.monthly_events.filter((item: any) => {
          return item.type.id === 52;
        }),
        agricultural_plans: action.monthly_events.filter((item: any) => {
          return item.type.id === 53;
        }),
      };

    case PUT_MONTHLY_EVENTS_FAILED:
      console.log("POST_MONTHLY_EVENTS_FAILED", action.message);
      return {
        ...state,
      };

    case DELETE_MONTHLY_EVENTS_SUCCEEDED:
      console.log("行事予定削除に成功しました status: ", action.status);
      return {
        ...state,
        monthly_plans: state.monthly_plans.filter(
          (event: any) => event.id !== action.deleted_monthly_event_id
        ),
        agricultural_plans: state.agricultural_plans.filter(
          (event: any) => event.id !== action.deleted_monthly_event_id
        ),
        all_monthly_plans: state.all_monthly_plans.filter(
          (event: any) => event.id !== action.deleted_monthly_event_id
        ),
        all_agricultural_plans: state.all_agricultural_plans.filter(
          (event: any) => event.id !== action.deleted_monthly_event_id
        ),
      };

    case DELETE_MONTHLY_EVENTS_FAILED:
      console.log("行事予定削除エラー message:", action.message);
      return {
        ...state,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
