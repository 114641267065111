import {
  CLEAR_SYSTEM_CACHE,
  HANDLE_CLEAR_SEARCH_TERMS,
} from "../../session/type";
import {
  TOGGLE_CATEGORY_TO_BE_SEARCHED_CHECK,
  SET_CATEGORY_TO_BE_SEARCHED_CHECK,
} from "./type";
interface CategoryToBeSearched {
  id: number;
  name: string;
}

const initialState: string[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_CATEGORY_TO_BE_SEARCHED_CHECK:
      // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
      if (
        state.length > 0 &&
        state.some(
          (category_name: any) => category_name === action.category_name
        )
      ) {
        return state.filter(
          (category_name: any) => category_name !== action.category_name
        );
      } else {
        return [...state, action.category_name];
      }

    case SET_CATEGORY_TO_BE_SEARCHED_CHECK:
      return [...action.category_name];

    case HANDLE_CLEAR_SEARCH_TERMS:
      if (action.target_term === "category") {
        return [];
      } else {
        return state;
      }

    // case TOGGLE_EDIT_ARTICLE_TAG_CHECK:
    //   // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
    //   if (state.tags.length > 0 && state.tags.some((tag: any) => tag.id === action.id)) {
    //     return {
    //       ...state,
    //       tags: state.tags.filter((tag: any) => tag.id !== action.id)
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       tags: [...state.tags, action.tag]
    //     };
    //   }

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
    // }
  }
};

export default reducer;
