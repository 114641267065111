import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ServiceItem from "./ServiceItem";

export default function ServiceList() {
  const position_id = useSelector((state: any) => state.session.position.id);

  // const serviceList = [
  //   { title: "FAQ", pathName: "/articles" },
  //   { title: "行事予定", pathName: "/events/home" },
  //   { title: "マニュアル", pathName: "/manual" }
  // ];

  return (
    <ServiceListSC>
      {/* 本当はmap出したいけど権限による条件分けのため応急処置的にベタ書き */}
      <ServiceItem title="FAQ" to="/articles" />
      <ServiceItem title="行事予定" to="/events/home" />
      {position_id === 3 || position_id === 2 ? (
        <ServiceItem title="マニュアル" to="/manual" />
      ) : (
        ""
      )}
    </ServiceListSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ServiceListSC = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
