import React from "react";
import styled from "styled-components";
import { color } from "components/mixins";
interface CheckboxListProps {
  selectedItem: string[];
  groupName: string;
  items: { id: number; name: string }[];
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const CheckboxList = (props: CheckboxListProps) => {
  const { selectedItem, groupName, items, onSelect } = props;
  if (!items) {
    return null;
  }
  return (
    <InputRadioCheckboxGroupSC>
      {items.map(item => {
        return (
          <CheckboxLabel
            key={`${groupName}_${item.id}`}
            htmlFor={`${groupName}_${item.id}`}
          >
            <Checkbox
              className="checkbox"
              id={`${groupName}_${item.id}`}
              type="checkbox"
              value={item.name}
              checked={selectedItem.includes(item.name)}
              onChange={onSelect}
            />
            <CheckboxSpan className="label">{item.name}</CheckboxSpan>
          </CheckboxLabel>
        );
      })}
    </InputRadioCheckboxGroupSC>
  );
};
const InputRadioCheckboxGroupSC = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 3px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
