import {
  SET_SELECTED_MONTH,
  SET_CURRENT_YEAR,
  SET_DAILY_LIST_FOR_TOP,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED,
  GET_USAGE_GUIDES_SUCCEEDED,
  GET_CALENDAR_SUCCEEDED
} from "./type";

import { CLEAR_SYSTEM_CACHE, END_SESSION_SUCCEEDED } from "../../session/type";

const initialState = {
  selected_month: "4",
  current_year: new Date().getFullYear(),
  date_list: [...Array(30)].map((item: any, index: number) => {
    const thisMonth = new Date().getMonth() - 1;
    const thisYear = new Date().getFullYear();
    if (thisMonth === 1 || thisMonth === 2 || thisMonth === 3) {
      //今現在が1月、2月、3月なら去年の4月を取得する。
      return new Date(thisYear - 1, 3, index + 1);
    } else {
      return new Date(new Date(thisYear, 3, index + 1));
    }
  }),
  annual_events: [],
  first_half_column: [...Array(6)].map((item: any, index: number) => {
    let month = 4 + index;
    return {
      month_index: month
    };
  }),
  second_half_column: [...Array(6)].map((item: any, index: number) => {
    let month = 10 + index;
    return {
      month_index: month > 12 ? index - 2 : month
    };
  }),
  array_for_row: [...Array(31)].map((item: any, index: number) => {
    return {
      date_index: index + 1
    };
  }),
  week_day: ["日", "月", "火", "水", "木", "金", "土"],
  usage_guides: [],
  calendar: []
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_MONTH:
      return {
        ...state,
        selected_month: action.selected_month
      };

    case SET_CURRENT_YEAR:
      return {
        ...state,
        current_year: action.current_year
      };

    case SET_DAILY_LIST_FOR_TOP:
      // 当月を定義
      const thisMonth = new Date(
        action.year,
        Number(action.month) - 1,
        1,
        9,
        0
      );
      // 来月を定義
      const nextMonth = new Date(action.year, Number(action.month), 1, 9, 0);
      // 当月と来月のミリ秒の差分を取り、１日のミリ秒数で割ることで1ヶ月の日数を算出する。
      const dateInterval =
        (nextMonth.getTime() - thisMonth.getTime()) / 86400000;
      // 1ヶ月の日数分の配列を回し、日付データを入れ込んでいく。
      const daily_list = [...Array(dateInterval)].map(
        (_: any, index: number) => {
          // 選択月が1,2,3月月だった場合、選択されている年度+1年の日付を取得する。
          if (
            action.month === "1" ||
            action.month === "2" ||
            action.month === "3"
          ) {
            return new Date(
              thisMonth.getFullYear() + 1,
              Number(action.month) - 1,
              thisMonth.getDate() + index
            );
          }
          return new Date(
            thisMonth.getFullYear(),
            Number(action.month) - 1,
            thisMonth.getDate() + index
          );
        }
      );
      return {
        ...state,
        date_list: daily_list
      };

    case GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED:
      return {
        ...state,
        annual_events: action.events
      };

    case END_SESSION_SUCCEEDED:
      return {
        ...state,
        selected_month: "4"
      };

    case GET_USAGE_GUIDES_SUCCEEDED:
      return {
        ...state,
        usage_guides: action.usage_guides
      };

    case GET_CALENDAR_SUCCEEDED:
      return {
        ...state,
        calendar: action.calendars
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
