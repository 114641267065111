import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import unChecked from "../../../images/icons/checked.svg";
import checked from "../../../images/icons/unChecked.svg";
import { setFacility } from "../../../store/event/facility/action";
import {
  getFacilitiesApprovalsStatusRequest,
  getApprovalMonthlyStatusRequest
} from "../../../store/event/approval/action";

import { containerBaseStyle } from "../../mixins";

function StatusListBlock() {
  const facilities = useSelector(
    (state: any) => state.approval.facilities_status
  );
  const affiliated_facilities = useSelector(
    (state: any) => state.session.facilities
  );
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const year = useSelector((state: any) => state.event_search.filtered_year);
  const this_year =
    new Date().getMonth() + 1 === (1 || 2 || 3)
      ? new Date().getFullYear() - 1
      : new Date().getFullYear();
  const handleClick = (facility_id: number, facility_name: string) => {
    dispatch(setFacility(facility_id, facility_name));
    dispatch(getApprovalMonthlyStatusRequest(token, facility_id, year));
  };

  const affiliated_facilities_status = affiliated_facilities.map(
    (facility: any) => {
      const current_item = facilities.find(
        (item: any) => facility.id === item.id
      );
      return {
        ...current_item
      };
    }
  );

  useEffect(() => {
    dispatch(
      getFacilitiesApprovalsStatusRequest(token, new Date().getFullYear())
    );
  }, [dispatch, token]);

  return (
    <StatusListBlockSC>
      <StatusListWrapperSC>
        <StatusListBlockHeader>
          <StatusListBlockHeaderItem className="facility_name">
            施設名
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="facility_manager">
            施設長
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="project_manager">
            プロジェクト管理者
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="headquarters">
            本部
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="requested_date">
            最終承認依頼日
          </StatusListBlockHeaderItem>
        </StatusListBlockHeader>
        {}
        {affiliated_facilities_status.map((facility: any) => {
          return (
            <StatusListSC>
              <StatusListItemSC>
                <StatusMonthlyLinkSC
                  to={`/events/approvals/status/${facility.id}`}
                  onClick={() => handleClick(facility.id, facility.name)}
                >
                  <p className="facility_name">{facility.name}</p>
                  <p className="facility_manager">
                    {facility.leader_approved ? (
                      <img src={checked} alt="checked" />
                    ) : (
                      <img src={unChecked} alt="unChecked" />
                    )}
                  </p>
                  <p className="project_manager">
                    {facility.administrator_approved ? (
                      <img src={checked} alt="checked" />
                    ) : (
                      <img src={unChecked} alt="unChecked" />
                    )}
                  </p>
                  <p className="headquarters">
                    {facility.director_approved ? (
                      <img src={checked} alt="checked" />
                    ) : (
                      <img src={unChecked} alt="unChecked" />
                    )}
                  </p>
                  <p className="requested_date">{facility.last_requested_at}</p>
                </StatusMonthlyLinkSC>
              </StatusListItemSC>
            </StatusListSC>
          );
        })}
      </StatusListWrapperSC>
    </StatusListBlockSC>
  );
}

export default StatusListBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const StatusListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 20px 16px;
  ${pc`
    padding: 24px 0;
    max-width: 810px;
    // width: 65.5%;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 30px;
  `}
`;
const StatusListWrapperSC = styled.div`
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  overflow: hidden;
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;

const StatusListBlockHeader = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${color.list_header};
  color: ${color.light_gray};
  padding: 12px 20px;
  border-bottom: 1px solid ${color.list_border};
`;

const StatusListBlockHeaderItem = styled.li`
  font-size: 14px;
  text-align: center;
  &.facility_name {
    text-align: left;
    width: calc(35%);
  }
  &.facility_manager {
    width: calc(10%);
  }
  &.project_manager {
    width: calc(19%);
  }
  &.headquarters {
    width: calc(10%);
  }
  &.requested_date {
    width: calc(26%);
  }
`;

const StatusListSC = styled.ul`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
`;

const StatusListItemSC = styled.li`
  color: ${color.gray};
  font-size: 14px;
  width: 100%;
`;

const StatusMonthlyLinkSC = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  &:hover {
    background-color: ${color.primary_white};
  }
  p {
    text-align: center;
    &.facility_name {
      text-align: left;
      width: calc(35%);
    }
    &.facility_manager {
      width: calc(10%);
    }
    &.project_manager {
      width: calc(19%);
    }
    &.headquarters {
      width: calc(10%);
    }
    &.requested_date {
      width: calc(26%);
    }
  }
`;
