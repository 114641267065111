import React from "react";
import styled from "styled-components";
import { color } from "components/mixins";

import { TopicItem } from "store/manual/manuals/topics/types.d";
import TopicsUneditableItem from "./TopicsUneditableItem";
import { ManualItemForSelector } from "store/manual/manuals/types.d";

interface Props {
  hookedTopics: TopicItem[];
  manual: ManualItemForSelector;
}
function TopicsUneditableList(props: Props) {
  const { hookedTopics, manual } = props;
  return (
    <TopicsAdminListSC>
      {hookedTopics.map((topic: any, index: number) => {
        return (
          <TopicsUneditableItem key={index} topic={topic} manual={manual} />
        );
      })}
    </TopicsAdminListSC>
  );
}

export default TopicsUneditableList;

const TopicsAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;
