const SET_INITIAL_EDIT_INPUT = "SET_INITIAL_EDIT_INPUT"
const SET_INITIAL_MONTHLY_EDIT_INPUT = "SET_INITIAL_MONTHLY_EDIT_INPUT"
const SET_EVENT_EDIT_INITIAL_DATE = "SET_EVENT_EDIT_INITIAL_DATE"
const SET_EDIT_EVENT_DATE = "SET_EDIT_EVENT_DATE"
const SET_EDIT_EVENT_TYPE = "SET_EDIT_EVENT_TYPE"
const SET_EDIT_EVENT_CONTENT = "SET_EDIT_EVENT_CONTENT"
const SET_EDIT_EVENT_DETAIL = "SET_EDIT_EVENT_DETAIL"
const SET_EDIT_EVENT_CLASS = "SET_EDIT_EVENT_CLASS"
const RESET_EDIT_EVENT_CONTENT = "RESET_EDIT_EVENT_CONTENT"

export {
  SET_INITIAL_EDIT_INPUT,
  SET_EVENT_EDIT_INITIAL_DATE,
  SET_INITIAL_MONTHLY_EDIT_INPUT,
  SET_EDIT_EVENT_DATE,
  SET_EDIT_EVENT_TYPE,
  SET_EDIT_EVENT_CONTENT,
  SET_EDIT_EVENT_DETAIL,
  SET_EDIT_EVENT_CLASS,
  RESET_EDIT_EVENT_CONTENT,
}