import styled from "styled-components";

export const BtnWrapperSC = styled.div`
  display: flex;
  column-gap: 8px;
  button {
    display: inline-block;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.6;
    }
  }
`;
