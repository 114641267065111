import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, inputBaseStyle } from "../../../mixins";
import {
  toggleEditArticleCategoryCheck,
  toggleEditArticleTagCheck,
  inputEditArticleTitle,
  selectEditArticleStatus,
  getArticleEditRequest,
  toggleArticleEditContentLoaded,
  toggleArticleTitleIsEdited
} from "../../../../store/faq/article_edit/action";

import WysiwygArticleEditor from "./WysiwygArticleEditOriginal";

function ArticlesEditor() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const articles = useSelector((state: any) => state.articles);
  const stocks = useSelector((state: any) => state.articles_stocked);
  const edit_article_id = useSelector((state: any) => state.article_edit.id);
  const article = articles.find(
    (article: any) => article.id === edit_article_id
  );
  const edit_title = useSelector((state: any) => state.article_edit.title);
  const edit_title_draft = useSelector(
    (state: any) => state.article_edit.title_draft
  );
  const content = useSelector((state: any) => state.article_edit.content);
  const draft = useSelector((state: any) => state.article_edit.draft);
  const edit_categories = useSelector(
    (state: any) => state.article_edit.categories
  );
  const edit_tags = useSelector((state: any) => state.article_edit.tags);
  const edit_status = useSelector((state: any) => state.article_edit.status);
  const message = useSelector((state: any) => state.article_edit.message);
  const publishedStatus = useSelector(
    (state: any) => state.article_detail.status
  );

  const registered_categories = useSelector((state: any) => state.categories);
  const registered_tags = useSelector((state: any) => state.tags);

  const location = useLocation();
  const pathName = location.pathname;
  const pathInfo = pathName.split("/");
  const fileName = Number(pathInfo.slice(-2, -1));

  const contentLoaded = useSelector(
    (state: any) => state.article_edit.contentLoaded
  );

  const savedTempTitle = useSelector(
    (state: any) => state.article_edit.savedTempData.title
  );
  const savedTempContent = useSelector(
    (state: any) => state.article_edit.savedTempData.content
  );
  const savedTempDraft = useSelector(
    (state: any) => state.article_edit.savedTempData.draft
  );
  const savedTempCategories = useSelector(
    (state: any) => state.article_edit.savedTempData.categories
  );
  const savedTempTags = useSelector(
    (state: any) => state.article_edit.savedTempData.tags
  );
  const savedTempIsEditing = useSelector(
    (state: any) => state.article_edit.savedTempData.articleIsEditing
  );
  const editorIsToggled = useSelector(
    (state: any) => state.article_edit.editorIsToggled
  );

  // 新規articleのタイトル管理
  const handleEditArticleTitle = (e: any) => {
    dispatch(inputEditArticleTitle(e.target.value));
  };

  // カテゴリーの判定
  const handleEditArticleCategoryCheck = (e: any) => {
    const target_category = registered_categories.find(
      (category: any) => category.id === Number(e.target.value)
    );
    dispatch(
      toggleEditArticleCategoryCheck(
        Number(e.target.value),
        target_category,
        editorIsToggled
      )
    );
  };

  // タグの判定
  const handleEditArticleTagCheck = (e: any) => {
    const target_tag = registered_tags.find(
      (tag: any) => tag.id === Number(e.target.value)
    );
    dispatch(
      toggleEditArticleTagCheck(
        Number(e.target.value),
        target_tag,
        editorIsToggled
      )
    );
  };

  // ステータスの判定
  const handleEditArticleStatusChange = (e: any) => {
    console.log("status consoling...", e.target.value);
    dispatch(selectEditArticleStatus(Number(e.target.value), publishedStatus));
  };

  useEffect(() => {
    dispatch(toggleArticleEditContentLoaded(false)); // 記事取得前に初期化
    dispatch(getArticleEditRequest(token, fileName, stocks));
  }, [dispatch, token, fileName]);

  return (
    <ArticlesEditorSC>
      {message === "" ? (
        <>
          {contentLoaded && (
            <>
              <TitleBlock>
                <TitleLabel>記事タイトル*</TitleLabel>
                <TitleEditorBlock>
                  {editorIsToggled ? (
                    <TitleEditorInput
                      value={savedTempTitle}
                      onChange={handleEditArticleTitle}
                      onFocus={e =>
                        toggleArticleTitleIsEdited(true, e.target.value)
                      }
                    />
                  ) : edit_title_draft === null ||
                    edit_title_draft === undefined ? (
                    <TitleEditorInput
                      value={edit_title}
                      onChange={handleEditArticleTitle}
                      onFocus={e =>
                        toggleArticleTitleIsEdited(true, e.target.value)
                      }
                    />
                  ) : (
                    <TitleEditorInput
                      value={edit_title_draft}
                      onChange={handleEditArticleTitle}
                      onFocus={e =>
                        toggleArticleTitleIsEdited(true, e.target.value)
                      }
                    />
                  )}
                </TitleEditorBlock>
              </TitleBlock>
              <BodyBlock>
                <BodyLabel>記事本文</BodyLabel>
                <BodyEditorBlock>
                  {editorIsToggled ? (
                    <WysiwygArticleEditor content={savedTempContent} />
                  ) : draft === null || draft === undefined ? (
                    contentLoaded &&
                    content &&
                    typeof content === "string" && (
                      <WysiwygArticleEditor content={content} />
                    )
                  ) : (
                    contentLoaded &&
                    draft &&
                    typeof draft === "string" && (
                      <WysiwygArticleEditor content={draft} />
                    )
                  )}
                </BodyEditorBlock>
              </BodyBlock>
              {/* カテゴリ */}
              <CategoryBlock>
                <CategoryLabel>カテゴリ</CategoryLabel>
                <CategoryEditorBlock>
                  <CategoriesList>
                    {registered_categories.map((category: any) => {
                      return (
                        <CategoriesItem key={`category_${category.id}`}>
                          <CheckboxLabel htmlFor={`category_${category.id}`}>
                            {editorIsToggled ? (
                              // 新規カテゴリ作成でチェック済み
                              <Checkbox
                                className="checkbox"
                                id={`category_${category.id}`}
                                type="checkbox"
                                value={`${category.id}`}
                                onChange={handleEditArticleCategoryCheck}
                                checked={savedTempCategories.some(
                                  (nc: any) => nc.id === category.id
                                )}
                              />
                            ) : (
                              <Checkbox
                                className="checkbox"
                                id={`category_${category.id}`}
                                type="checkbox"
                                value={`${category.id}`}
                                onChange={handleEditArticleCategoryCheck}
                                checked={edit_categories.some(
                                  (nc: any) => nc.id === category.id
                                )}
                              />
                            )}
                            <CheckboxSpan className="label">
                              {category.name}(
                              {category.articles_count
                                ? category.articles_count
                                : 0}
                              )
                            </CheckboxSpan>
                          </CheckboxLabel>
                        </CategoriesItem>
                      );
                    })}
                  </CategoriesList>
                </CategoryEditorBlock>
              </CategoryBlock>
              {/* タグ */}
              <TagBlock>
                <TagLabel>タグ</TagLabel>
                <TagEditorBlock>
                  <TagsList>
                    {registered_tags.map((tag: any) => {
                      return (
                        <TagsItem key={`tag_${tag.id}`}>
                          <CheckboxLabel htmlFor={`tag_${tag.id}`}>
                            {editorIsToggled ? (
                              // 新規タグ作成でチェック済み
                              savedTempTags.some(
                                (nt: any) => nt.id === tag.id
                              ) ? (
                                <Checkbox
                                  className="checkbox"
                                  id={`tag_${tag.id}`}
                                  type="checkbox"
                                  value={`${tag.id}`}
                                  onChange={handleEditArticleTagCheck}
                                  checked={true}
                                />
                              ) : (
                                <Checkbox
                                  className="checkbox"
                                  id={`tag_${tag.id}`}
                                  type="checkbox"
                                  value={`${tag.id}`}
                                  onChange={handleEditArticleTagCheck}
                                />
                              )
                            ) : edit_tags.some(
                                (nt: any) => nt.id === tag.id
                              ) ? (
                              <Checkbox
                                className="checkbox"
                                id={`tag_${tag.id}`}
                                type="checkbox"
                                value={`${tag.id}`}
                                onChange={handleEditArticleTagCheck}
                                checked={true}
                              />
                            ) : (
                              <Checkbox
                                className="checkbox"
                                id={`tag_${tag.id}`}
                                type="checkbox"
                                value={`${tag.id}`}
                                onChange={handleEditArticleTagCheck}
                              />
                            )}
                            <CheckboxSpan className="label">
                              {tag.name}(
                              {tag.articles_count ? tag.articles_count : 0})
                            </CheckboxSpan>
                          </CheckboxLabel>
                        </TagsItem>
                      );
                    })}
                  </TagsList>
                </TagEditorBlock>
              </TagBlock>

              {/* ステータス */}
              <StatusBlock>
                <StatusLabel>ステータス*</StatusLabel>
                <StatusEditorBlock>
                  <StatusEditorInput
                    value={edit_status}
                    className="select"
                    onChange={handleEditArticleStatusChange}
                  >
                    <option value={2}>公開</option>
                    <option value={1}>非公開</option>
                    <option value={3}>下書き</option>
                  </StatusEditorInput>
                </StatusEditorBlock>
              </StatusBlock>
            </>
          )}
        </>
      ) : (
        <>
          {contentLoaded && (
            <>
              <ArticleDetailNotFoundBlock>
                該当の記事はありません。
                <NotFoundLinkBlock>
                  <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                </NotFoundLinkBlock>
              </ArticleDetailNotFoundBlock>
            </>
          )}
        </>
      )}
    </ArticlesEditorSC>
  );
}

export default ArticlesEditor;
// ===================================================================================
// スタイル
// ===================================================================================
const ArticlesEditorSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

// Title ============================
const TitleBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
    align-items: center;
  `}
`;

const TitleEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const TitleLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const TitleEditorInput = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;
// ==================================

// Body =============================
const BodyBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
  `}
`;

const BodyEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const BodyLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;
// ==================================

// category ============================
const CategoryBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
`;
const CategoryEditorBlock = styled.div`
  width: 100%;
  max-width: 610px;
`;
const CategoryLabel = styled.label`
  display: block;
  width: 90px;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
`;

const CategoriesList = styled.ul``;
const CategoriesItem = styled.li`
  display: inline-block;
  margin-right: 20px;
`;
// =======================================

// Tag ===================================
const TagBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
`;
const TagLabel = styled.label`
  display: block;
  width: 90px;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
`;

const TagEditorBlock = styled.div`
  width: 100%;
  max-width: 610px;
`;

const TagsList = styled(CategoriesList)``;
const TagsItem = styled(CategoriesItem)``;

// ========================================

// Status =================================
const StatusLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const StatusEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const StatusEditorInput = styled.select`
  ${inputBaseStyle}
  width: 240px;
`;

const StatusBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  ${pc`
    display: flex;
    align-items: center;
  `}
`;
// ===========================================

// checkBox ==================================
const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;

const ArticleDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;
const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
