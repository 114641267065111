import {GET_REGISTERED_CATEGORIES_REQUESTED, DELETE_CATEGORY_ITEM_FROM_VIEW, UPDATE_VIEW_CATEGORY_NAME, UPDATE_VIEW_CATEGORY_ORDER, DELETE_CATEGORY_ITEM_REQUESTED, POST_NEW_CATEGORY_ITEM_REQUESTED, UPDATE_CATEGORY_ITEM_REQUESTED} from './type'

export const updateViewCategoryName = ( updated_name:string, updated_target_id: number ) => {
  return  {
    type: UPDATE_VIEW_CATEGORY_NAME,
    updated_name,
    updated_target_id
  }
}

export const updateViewCategoryOrder = ( updated_order:number, updated_target_id: number ) => {
  return  {
    type: UPDATE_VIEW_CATEGORY_ORDER,
    updated_order,
    updated_target_id
  }
}

export const deleteCategoryItemFromView = (deleteOrder:number) => {
  return {
    type: DELETE_CATEGORY_ITEM_FROM_VIEW,
    deleteOrder
  }
}

export const getRegisteredCategoriesRequested = (token: string) => 
{
  return {
    type:GET_REGISTERED_CATEGORIES_REQUESTED,
    token
  } 
}

export const deleteCategoryItemRequested = (token: string, id: number) => {
  return {
    type: DELETE_CATEGORY_ITEM_REQUESTED,
    token,
    id
  }
}

export const postCategoryItemRequested = (token: string, new_category_name: string, new_category_order:number, temp_id:number) => {
  return {
    type: POST_NEW_CATEGORY_ITEM_REQUESTED,
    token,
    new_category_name,
    new_category_order,
    temp_id
  }
}

export const updateCategorisItemRequested = (token: string, id: number, updated_name: string, updated_order: number,) => {
  return {
    type: UPDATE_CATEGORY_ITEM_REQUESTED,
    token,
    updated_name,
    updated_order,
    id
  }
}