const localeTimeCustomizerForArticles = (given_time: string) => {
  const opts = {
    year: "numeric",
    month: "narrow",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "long"
  }
  const local_time = new Date(given_time).toLocaleString('ja', opts).slice( 0, -11 );
  return local_time
}
export default localeTimeCustomizerForArticles