import React from "react";
import styled from "styled-components";
import color from "../../../mixins/color";
import { inputBaseStyle, buttonBaseStyle } from "../../../mixins/common";
import { pc, minSp } from "../../../mixins/mediaQuery";
import deleteIcon from "../../../../images/icons/close.svg";
import { TagListProps } from "./type";

const TagsList = (props: TagListProps) => {
  const { data, handlers } = props;

  // const handleDeleteItem = (id: number) => {
  //   const deletedTagsArray = tags.filter(value => id !== value.id);
  //   setTags(deletedTagsArray);
  // };
  // const handleDeleteNewItem = (id: number) => {
  //   const deletedTagsArray = newArray.filter(value => id !== value.temp_id);
  //   setNewArray(deletedTagsArray);
  // };

  return (
    <div>
      <TagsListContainerSC>
        <form action="">
          <TagsListHeadingListSC>
            <TagsListHeadingItemSC>タグ名</TagsListHeadingItemSC>
            <TagsListHeadingItemSC>並び順</TagsListHeadingItemSC>
            <TagsListHeadingItemSC>該当記事件数</TagsListHeadingItemSC>
            <TagsListHeadingItemSC>削除</TagsListHeadingItemSC>
          </TagsListHeadingListSC>
          <div>
            {data.tags[0] !== null
              ? data.tags.map(tag => {
                  console.log(tag.count);
                  return (
                    <TagsListSC>
                      <TagsListItemSC>
                        <TagsListInputSC
                          className="name"
                          type="text"
                          value={tag.name}
                          onChange={e =>
                            handlers.changeNameTobeUpdated({
                              target_id: tag.id,
                              value: e.target.value
                            })
                          }
                          onFocus={() => handlers.focusTagsInput(tag.id)}
                        />
                      </TagsListItemSC>
                      <TagsListItemSC>
                        <TagsListInputSC
                          className="order"
                          type="number"
                          value={tag.order}
                          onChange={e =>
                            handlers.changeOrderTobeUpdated({
                              target_id: tag.id,
                              order: Number(e.target.value)
                            })
                          }
                          onFocus={() => handlers.focusTagsInput(tag.id)}
                          min={0}
                        />
                      </TagsListItemSC>
                      {tag.count ? (
                        <TagsListItemSC>{tag.count}</TagsListItemSC>
                      ) : (
                        <TagsListItemSC>{"0"}</TagsListItemSC>
                      )}
                      <TagsListItemSC>
                        <TagsListDeleteButtonSC
                          onClick={e => handlers.clickDeleteBtn(e, tag.id)}
                        />
                      </TagsListItemSC>
                    </TagsListSC>
                  );
                })
              : ""}

            {data.tagsToBeCreated.map((tag, i) => {
              return (
                <TagsListSC key={i}>
                  <TagsListItemSC>
                    <TagsListInputSC
                      className="name"
                      type="text"
                      value={tag.name}
                      onChange={e =>
                        handlers.changeNameTobeCreated({
                          target_id: tag.temp_id,
                          value: e.target.value
                        })
                      }
                    />
                  </TagsListItemSC>
                  <TagsListItemSC>
                    <TagsListInputSC
                      className="order"
                      type="number"
                      value={tag.order}
                      onChange={e =>
                        handlers.changeOrderTobeCreated({
                          target_id: tag.temp_id,
                          order: Number(e.target.value)
                        })
                      }
                      min={0}
                    />
                  </TagsListItemSC>
                  <TagsListItemSC>{""}</TagsListItemSC>
                  <TagsListItemSC>
                    <TagsListDeleteButtonSC
                      onClick={e =>
                        handlers.clickDeleteTobeCreated(e, tag.temp_id)
                      }
                    />
                  </TagsListItemSC>
                </TagsListSC>
              );
            })}
          </div>
          <TagsListSC as="div">
            <TagsListAddButtonSC onClick={e => handlers.clickAddBtn(e)}>
              追加
            </TagsListAddButtonSC>
          </TagsListSC>
        </form>
      </TagsListContainerSC>
    </div>
  );
};

export default TagsList;

// ===================================================================================
// スタイル
// ===================================================================================

const TagsListContainerSC = styled.div`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
  overflow: auto;
  ${minSp`
    overflow: hidden;
  `}
  form {
    width: 440px;
    ${minSp`
      width: auto;
    `}
  }
`;

const TagsListHeadingListSC = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${color.primary_white};
  border-bottom: 1px solid ${color.list_border};
  color: ${color.tag_color};
  font-size: 14px;
  padding: 13px 10px;
  ${pc`
    max-width: 750px;
    padding: 13px 20px;
  `}
`;

const TagsListHeadingItemSC = styled.li`
  font-size: 14px;
  color: ${color.gray};
  &:nth-child(1) {
    min-width: 130px;
    width: 40%;
    ${pc`
      width: 50%;
    `}
  }
  &:nth-child(2) {
    min-width: 90px;
    width: 20%;
  }
  &:nth-child(3) {
    width: 30%;
    ${pc`
      width: 20%;
    `}
  }
  &:nth-child(4) {
    width: 10%;
    text-align: right;
  }
`;

const TagsListSC = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
  ${pc`
    padding: 12px 20px;
  `}
`;

const TagsListItemSC = styled(TagsListHeadingItemSC)`
  color: ${color.gray};
`;

const TagsListInputSC = styled.input`
  ${inputBaseStyle}
  border-color: ${color.category_bg};
  &.name {
    width: 85%;
  }
  &.order {
    max-width: 80px;
    width: 100%;
  }
  &::placeholder {
    color: ${color.primary};
  }
`;

const TagsListDeleteButtonSC = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(${deleteIcon});
  background-size: 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
`;
const TagsListAddButtonSC = styled.button`
  ${buttonBaseStyle}
  opacity: 1;
  background-color: #fff;
  color: ${color.black};
  border: 1px solid ${color.black};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  &:hover {
    opacity: 0.7;
    transition-duration: 0.2s;
    cursor: pointer;
  }
`;
