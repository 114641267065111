import {
  DoronkoMediaFileType,
  CameraTag,
  CameraClass,
  CameraFacility,
  AlbumViewType
} from "components/Camera/type";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  AlbumListItemSC,
  AlbumListItemTableSC,
  AlbumListItemTheadSC,
  AlbumListItemTbodySC,
  AlbumListItemCheckSC,
  AlbumListItemClassSC,
  AlbumListItemDateSC,
  AlbumListItemFacilitySC,
  AlbumListItemThumbnailSC,
  AlbumListItemImgSC,
  AlbumListItemTagSC,
  AlbumListItemTrSC,
  AlbumListItemTdContentSC,
  AlbumListFlexWrapperSC
} from "./style";
import { Checkbox } from "../Checkbox";

interface AlubumListProps {
  mediaFiles: DoronkoMediaFileType[];
  isSelectMode: boolean;
  onSelectMedia: (mediaFileID: number) => void;
  selectedMedia: number[];
  albumViewType?: AlbumViewType;
}

export const AlbumListTable = (props: AlubumListProps) => {
  const {
    mediaFiles,
    isSelectMode,
    onSelectMedia,
    selectedMedia,
    albumViewType
  } = props;
  const history = useHistory();
  const handleClickRow = (mediaFileID: number) => {
    if (isSelectMode) {
      onSelectMedia(mediaFileID);
    } else {
      history.push(`/camera/detail/${mediaFileID}`);
    }
  };
  const isSelecetdCheck = (targetId: number) => {
    return selectedMedia.includes(targetId);
  };

  return (
    <AlbumListItemSC>
      <AlbumListItemTableSC>
        <AlbumListItemTheadSC>
          <AlbumListItemTrSC>
            {isSelectMode && <AlbumListItemCheckSC />}
            <AlbumListItemTdContentSC>
              <AlbumListFlexWrapperSC>
                <AlbumListItemThumbnailSC />
                <AlbumListItemTagSC>タグ</AlbumListItemTagSC>
                <AlbumListItemClassSC>クラス</AlbumListItemClassSC>
                <AlbumListItemFacilitySC>登録施設</AlbumListItemFacilitySC>
                {albumViewType === "share" ? (
                  <AlbumListItemDateSC>共有日時</AlbumListItemDateSC>
                ) : (
                  <AlbumListItemDateSC>アップロード日時</AlbumListItemDateSC>
                )}
                <AlbumListItemDateSC>撮影日時</AlbumListItemDateSC>
              </AlbumListFlexWrapperSC>
            </AlbumListItemTdContentSC>
          </AlbumListItemTrSC>
        </AlbumListItemTheadSC>
        <AlbumListItemTbodySC>
          {mediaFiles.map(mediaFile => {
            const {
              tags,
              facilities,
              classes,
              taken_at,
              thumbnail_path,
              id,
              shared_at,
              created_at
            } = mediaFile;
            return (
              <AlbumListItemTrSC className="detail" key={id}>
                {isSelectMode && (
                  <AlbumListItemCheckSC>
                    <Checkbox
                      id={`list-${id}`}
                      checked={isSelecetdCheck(id)}
                      onChange={() => onSelectMedia(id)}
                    />
                  </AlbumListItemCheckSC>
                )}
                <AlbumListItemTdContentSC
                  onClick={() => {
                    handleClickRow(id);
                  }}
                >
                  <AlbumListFlexWrapperSC>
                    <AlbumListItemThumbnailSC>
                      <AlbumListItemImgSC src={thumbnail_path} />
                    </AlbumListItemThumbnailSC>
                    <TagList tags={tags} />
                    <ClassList classes={classes} />
                    <FacilityList facilities={facilities} />
                    {albumViewType === "share" && (
                      <AlbumListItemDateSC>{shared_at}</AlbumListItemDateSC>
                    )}
                    {albumViewType !== "share" && (
                      <AlbumListItemDateSC>{created_at}</AlbumListItemDateSC>
                    )}
                    <AlbumListItemDateSC>{taken_at}</AlbumListItemDateSC>
                  </AlbumListFlexWrapperSC>
                </AlbumListItemTdContentSC>
              </AlbumListItemTrSC>
            );
          })}
        </AlbumListItemTbodySC>
      </AlbumListItemTableSC>
    </AlbumListItemSC>
  );
};
// タグ一覧
const TagList = (props: { tags: CameraTag[] }) => {
  const { tags } = props;
  const TagStrings = () => {
    return (
      <>
        {tags.map(({ name }, i) => {
          return i + 1 === tags.length ? (
            <span>{`#${name}`}</span>
          ) : (
            <span>{`#${name}, `}</span>
          );
        })}
      </>
    );
  };
  if (tags.length !== 0) {
    return (
      <AlbumListItemTagSC>
        <TagStrings />
      </AlbumListItemTagSC>
    );
  } else {
    return <AlbumListItemTagSC>タグ未設定</AlbumListItemTagSC>;
  }
};
// クラス一覧
const ClassList = (props: { classes: CameraClass[] }) => {
  const { classes } = props;
  if (classes.length !== 0) {
    return (
      <AlbumListItemClassSC>
        {classes
          .sort((a, b) => Number(a.name) - Number(b.name))
          .map((classroom, i) => {
            if (i + 1 < classes.length) {
              return `${classroom.name},`;
            }
            return classroom.name;
          })}
        {classes.some(v => v.name === "6") ? "歳児以上" : "歳児"}
      </AlbumListItemClassSC>
    );
  } else {
    return <AlbumListItemClassSC>クラス未設定</AlbumListItemClassSC>;
  }
};
// 施設一覧
const FacilityList = (props: { facilities: CameraFacility[] }) => {
  const { facilities } = props;
  const FacilityStrings = () => {
    return (
      <>
        {facilities.map(({ name }, i) => {
          return i + 1 === facilities.length ? (
            <span>{`#${name}`}</span>
          ) : (
            <span>{`#${name}, `}</span>
          );
        })}
      </>
    );
  };
  if (facilities.length !== 0) {
    return (
      <AlbumListItemFacilitySC>
        <FacilityStrings />
      </AlbumListItemFacilitySC>
    );
  } else {
    return <AlbumListItemFacilitySC>共有施設未設定</AlbumListItemFacilitySC>;
  }
};
