import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../../Common/PageHeading";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import styled from "styled-components";
import { color, buttonBaseStyle } from "../../../mixins";
import { useLocation } from "react-router-dom";
import { pc } from "../../../mixins/mediaQuery";
import {
  getNewsDetailRequest,
  postNewsDetailReadRequest,
  toggleNewsDetailContentLoaded
} from "../../../../store/global/news_detail/action";
import clock from "../../../../images/icons/clock.svg";
import WysiwigNewsDetail from "./WysiwygNewsDetailOriginal";
import localeTimeCustomizer from "../../../Common/LocaleTimeCustomizerForArticles";

function NewsDetailEdit(props: any) {
  const dispatch = useDispatch();

  const newsList = useSelector((state: any) => state.news);

  const contentLoaded = useSelector(
    (state: any) => state.news_detail.contentLoaded
  );

  const news_detail_id = useSelector((state: any) => state.news_detail.id);
  const message = useSelector((state: any) => state.news_detail.message);
  const token = useSelector((state: any) => state.session.token);
  const location = useLocation();
  const pathName = location.pathname;
  const pathInfo = pathName.split("/");
  const fileName = Number(pathInfo.pop());

  const news = useSelector((state: any) => state.news_detail);

  const title = news ? news.title : "";
  const content = news ? news.content : "";
  const updated_at = news ? news.updated_at : "";

  useEffect(() => {
    console.log("working");
    console.log("filename", fileName);
    dispatch(toggleNewsDetailContentLoaded(false)); // 記事取得前に初期化
    dispatch(getNewsDetailRequest(token, fileName));
    dispatch(postNewsDetailReadRequest(token, fileName));
  }, [dispatch, token, news_detail_id]);

  return (
    <MainSC>
      <PageHeading title="お知らせ詳細" />
      <PageBreadcrumb
        firstLink="/news"
        firstTitle="お知らせ"
        secondTitle={title}
      />

      <ArticleDetailBlockSC>
        {message === "" ? (
          <>
            <DetailHeaderSC>
              <HeaderUpsideSC>
                {/* 更新日時 */}
                {contentLoaded && (
                  <UpdatedAtSC>
                    <ClockSC />
                    更新日時：{localeTimeCustomizer(updated_at)}
                  </UpdatedAtSC>
                )}
              </HeaderUpsideSC>

              {/* タイトル */}
              {contentLoaded && <ArticleTitleSC>{title}</ArticleTitleSC>}
            </DetailHeaderSC>

            {/* コンテンツ */}
            <DetailContentsSC>
              {contentLoaded && content && typeof content === "string" && (
                <WysiwigNewsDetail content={content} />
              )}
            </DetailContentsSC>
          </>
        ) : (
          <NewsDetailNotFoundBlock>
            {contentLoaded && (
              <>
                該当のお知らせはありません。
                <NotFoundLinkBlock>
                  <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                </NotFoundLinkBlock>
              </>
            )}
          </NewsDetailNotFoundBlock>
        )}
      </ArticleDetailBlockSC>
    </MainSC>
  );
}

export default NewsDetailEdit;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const ArticleDetailBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    margin-bottom: 0;
    padding: 30px;
  `}
`;

const DetailHeaderSC = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  padding: 24px 16px 8px;
  word-break: break-all;
  ${pc`
    padding: 30px;
  `}
`;

const HeaderUpsideSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
const UpdatedAtSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  line-height: 1.5;
  ${pc`
    white-space: normal;
    text-align: right;
    line-height: 1.1;
  `}
`;

const ClockSC = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 6px;
`;

const ArticleTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
`;

const NewsDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;

const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
