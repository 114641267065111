import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { useDispatch, useSelector } from "react-redux";
import PageHeading from "../../Common/PageHeading";
import NewsListBlock from "./NewsListBlock";
import {
  resetTempNewsEditData,
  setNewsEditorIsToggled
} from "../../../store/global/news_edit/action";

function News() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  const location = useLocation();
  const pathName = location.pathname;

  console.log("pathName:", pathName);
  let title: string;
  if (pathName === "/news") {
    if (!isAdmin && !isEditor) {
      title = "お知らせ";
    } else {
      title = "お知らせ";
    }
  } else {
    title = "お知らせ一覧";
  }

  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  useEffect(() => {
    dispatch(setNewsEditorIsToggled(false));
    dispatch(resetTempNewsEditData());
  }, [dispatch]);

  return (
    <MainSC>
      <PageHeading title={title} />

      <NewsListBlock />
    </MainSC>
  );
}

const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

export default News;
