import React from "react";
import styled from "styled-components";

function PageHeading({ title }: { title: string }) {
  return <PageHeadingSC>{title}</PageHeadingSC>;
}

export default PageHeading;

// ===================================================================================
// スタイル
// ===================================================================================
const PageHeadingSC = styled.h1`
  font-size: 18px;
  line-height: inherit;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: #444;
  width: 100%;
`;
