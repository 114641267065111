import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import color from "../../mixins/color";
import { pc, minSp } from "../../mixins/mediaQuery";
import headerArrow from "../../../images/icons/多角形_1.svg";
import icon from "../../../images/icons/header_icon.svg";
import bookmark from "../../../images/icons/bookmark.svg";
import bookmark_current from "../../../images/icons/bookmark_white.svg";
import news from "../../../images/icons/bell_black.svg";
import news_current from "../../../images/icons/bell.svg";
import logo from "../../../images/icons/hiroba_logo.svg";
import spOpen from "../../../images/icons/sp_open.svg";
import spClose from "../../../images/icons/sp_close.svg";
import {
  clearSystemCache,
  endSessionRequest,
  getSystemStatusRequest,
  maintenanceOnRequest,
  toggleCacheCleared
} from "../../../store/session/action";
import { toggleHamburgerMenu } from "../../../store/common/hamburger/action";
import HeaderMenuSP from "./HeaderMenuSP";
import HeaderMenuManualSP from "./HeaderManuManualSP";

function Header() {
  const token = useSelector((state: any) => state.session.token);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const [isSpClicked, setIsSpClicked] = useState(false);
  const isLoggedIn = useSelector((state: any) => state.session.isLoggedIn);
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  const name = useSelector((state: any) => state.session.name);
  const gsuite_id = useSelector((state: any) => state.session.gsuite_id);
  const picture = useSelector((state: any) => state.session.picture);
  const position_id = useSelector((state: any) => state.session.position.id);

  const hamburger_open = useSelector((state: any) => state.hamburger.open);

  const [position, setPosition] = useState(window.pageYOffset)
  const [visible, setVisible] = useState(true) 
  useEffect(()=> {
      const handleScroll = () => {
         let moving = window.pageYOffset
         if(moving > 60){
          setVisible(position > moving);
          setPosition(moving)
         }
      };      
      window.addEventListener("scroll", handleScroll);
      return(() => {
         window.removeEventListener("scroll", handleScroll);
      })
      
  }, [position, visible])

  const handleClick = () => {
    dispatch(endSessionRequest(token));
    localStorage.setItem("referer", "/");
  };

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    setInterval(() => {
      dispatch(toggleCacheCleared(false));
      window.location.reload(true);
    }, 2000);
  };

  const handleSystemClearClick = () => {
    dispatch(clearSystemCache());
    dispatch(toggleCacheCleared(true));
    refreshCacheAndReload();
  };

  const handleLogoClick = () => {
    dispatch(toggleHamburgerMenu(false));
    dispatch(getSystemStatusRequest(token));
  };

  const handleMaintenanceOn = () => {
    if (window.confirm("メンテナンスモードをONにしますか？"))
      dispatch(maintenanceOnRequest(token, 2));
  };

  console.log("hamburger_open:", hamburger_open);
  const cls = visible ? "visible" : "hidden";

  return (
    <HeaderSC className={isLoggedIn ? `signIn ${cls}` : `${cls}`}>
      <HeaderContainerSC>
        <HeaderLogoSC>
          <Link to="/" onClick={() => dispatch(toggleHamburgerMenu(false))}>
            <img src={logo} alt="ひろば ロゴ" />
          </Link>
        </HeaderLogoSC>
        {isLoggedIn && (
          <HeaderContentRightSC>
            <HeaderContentItemSC
              className={
                location.pathname === "/articles/stocks"
                  ? " bookmark current"
                  : "bookmark"
              }
            >
              <Link
                to="/articles/stocks"
                onClick={() => dispatch(toggleHamburgerMenu(false))}
              >
                {location.pathname === "/articles/stocks" ? (
                  <img src={bookmark_current} alt="bookmark" />
                ) : (
                  <img src={bookmark} alt="bookmark" />
                )}
                <HeaderContentItemText>ブックマーク</HeaderContentItemText>
              </Link>
            </HeaderContentItemSC>
            <HeaderContentItemSC
              className={
                location.pathname === "/news" ? " news current" : "bookmark"
              }
            >
              <Link
                to="/news"
                onClick={() => dispatch(toggleHamburgerMenu(false))}
              >
                {location.pathname === "/news" ? (
                  <img src={news_current} alt="news" />
                ) : (
                  <img src={news} alt="news" />
                )}
                <HeaderContentItemText>お知らせ</HeaderContentItemText>
              </Link>
            </HeaderContentItemSC>
            <HeaderMenuBtnSC
              className={isClicked ? "is-open" : ""}
              onClick={() => setIsClicked(!isClicked)}
            >
              <UserIconSC
                src={gsuite_id !== null && picture !== null ? picture : icon}
                alt="icon"
              />
              <UserNameSC>{name}</UserNameSC>
              <HeaderArrowSC src={headerArrow} alt="arrow" />
              {isClicked ? (
                <HeaderMenuListSC>
                  <HeaderMenuItemSC onClick={handleClick}>
                    ログアウト
                  </HeaderMenuItemSC>
                  <HeaderMenuItemSC onClick={handleSystemClearClick}>
                    システム更新
                  </HeaderMenuItemSC>
                  {position_id === 3 && (
                    <HeaderMenuItemSC onClick={handleMaintenanceOn}>
                      メンテナンスON
                    </HeaderMenuItemSC>
                  )}
                </HeaderMenuListSC>
              ) : (
                ""
              )}
            </HeaderMenuBtnSC>

            <HeaderMenuSPBtnSC
              className={hamburger_open ? "is-open" : ""}
              onClick={() => dispatch(toggleHamburgerMenu(!hamburger_open))}
            >
              {hamburger_open ? (
                <>
                  <SpClose src={spClose} alt="close icon" />
                  <HeaderContentItemText>閉じる</HeaderContentItemText>
                </>
              ) : (
                <>
                  <SpOpen src={spOpen} alt="menu icon" />
                  <HeaderContentItemText>メニュー</HeaderContentItemText>
                </>
              )}
            </HeaderMenuSPBtnSC>
          </HeaderContentRightSC>
        )}
      </HeaderContainerSC>

      <HeaderMenu className={hamburger_open ? "is-open" : ""}>
        {isLoggedIn &&
          location.pathname.includes("/manual") &&
          (position_id === 3 || position_id === 2) && <HeaderMenuManualSP />}
        {isLoggedIn && !location.pathname.includes("/manual") && (
          <HeaderMenuSP />
        )}

        {isLoggedIn && (
          <HeaderMenuOverlay
            className={hamburger_open ? "is-open" : ""}
            onClick={() => dispatch(toggleHamburgerMenu(!hamburger_open))}
          ></HeaderMenuOverlay>
        )}
      </HeaderMenu>
    </HeaderSC>
  );
}

export default Header;

// ===================================================================================
// スタイル
// ===================================================================================
const HeaderSC = styled.div`
  position: fixed;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  z-index: 100;
  ${pc`
    height: 60px;
    position: fixed;
  `}
  &.signIn > div {
    padding: 0 0 0 12px;
    ${pc`
      padding: 10px 20px;
    `}
  }

  &.visible {
    visibility:visible;
    opacity: 1;
    top: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  
  &.hidden {
    visibility:hidden;
    opacity: 0;
    top: -80px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    ${pc`
      visibility:visible;
      opacity: 1;
      top: 0;
    `}
  }
`;

const HeaderContainerSC = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: ${color.white};
  position: relative;
  z-index: 10;
  ${pc`
    padding: 10px 20px;
  `}
`;

const HeaderContentRightSC = styled.ul`
  display: flex;
  align-items: center;
`;

const HeaderContentItemSC = styled.li`
  text-align: center;
  cursor: pointer;
  transition-duration: 0.2s;
  width: 60px;
  ${minSp`
    width: 64px;
  `}
  ${pc`
    text-align: left;
    width: auto;
    margin-right: 12px;
    border-radius: 50%;
  `}
  &:hover {
    transition-duration: 1s;
    background-color: ${color.primary_white};
  }

  &.current {
    background-color: ${color.primary};
    color: ${color.white};
  }

  &.bookmark {
    ${pc`
      padding: 7px 9px;
    `}
  }
  &.news {
    ${pc`
      padding: 7px;
    `}
  }
  a {
    padding: 10px 0;
    display: block;
    ${pc`
      display: inline;
    `}
  }
`;

const HeaderMenuBtnSC = styled.li`
  cursor: pointer;
  display: none;
  text-align: left;
  display: flex;
  position: relative;
  align-items: center;
  color: ${color.primary};
  &.is-open {
    background-color: ${color.primary};
    ${pc`
      background-color: transparent;
    `}
  }
  &.is-open::after {
    content: "";
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
`;

const HeaderMenuSPBtnSC = styled.li`
  cursor: pointer;
  text-align: center;
  width: 64px;
  padding: 10px 0;
  display: block;
  ${pc`
    display: none;
  `}
  &.is-open {
    background-color: ${color.primary};
    ${pc`
      background-color: transparent;
    `}
    p {
      color: ${color.white};
    }
  }
`;

const UserIconSC = styled.img`
  margin-right: 6px;
  display: none;
  width: 100%;
  max-width: 32px;
  border-radius: 16px;
  ${pc`
    display: inline-block;
  `}
`;

const UserNameSC = styled.p`
  font-size: 12px;
  margin-right: 6px;
  display: none;
  ${pc`
    display: block;
  `}
`;

const HeaderMenu = styled.div`
  display: none;
  &.is-open {
    display: block;
  }
  &.is-open > div {
    right: 0;
  }
`;

const HeaderMenuOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 1;
  &.is-open {
    display: block;
    ${pc`
      display: none;
    `}
  }
  ${pc`
    display: none;
  `}
`;

const HeaderArrowSC = styled.img`
  display: none;
  cursor: pointer;
  ${pc`
    display: inline-block;
  `}
`;

const HeaderMenuListSC = styled.ul`
  display: none;
  position: absolute;
  right: 10px;
  width: 196px;
  top: 50px;
  padding: 6px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 6;
  ${pc`
    display: block;
  `}
`;

const HeaderMenuItemSC = styled.li`
  color: ${color.primary};
  padding: 8px 12px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: ${color.primary_white};
  }
`;

const HeaderLogoSC = styled.div`
  display: block;
  width: 120px;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  &:hover {
    opacity: 0.6;
  }
  ${minSp`
    width: 120px;
  `}
`;

const SpOpen = styled.img`
  display: inline-block;
  ${pc`
    display: none;
  `}
`;

const SpClose = styled.img`
  display: inline-block;
  ${pc`
    display: none;
  `}
`;

const HeaderContentItemText = styled.p`
  font-size: 10px;
  padding-top: 7px;
  display: block;
  ${pc`
    display: none;
  `}
`;
