import { color } from "components/mixins";
import React from "react";
import styled from "styled-components";

interface EventClassCheckboxProps {
  classAge: string;
  onChangeFunc: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required: boolean;
  checked: boolean;
}

export const EventClassCheckbox = (props: EventClassCheckboxProps) => {
  const { classAge, onChangeFunc, required, checked } = props;
  return (
    <>
      <InputItemCheckbox
        id={`class_age_${classAge}`}
        type="checkbox"
        name={`class_age_${classAge}`}
        value={classAge}
        onChange={onChangeFunc}
        checked={checked}
        required={required}
      />
      <InputItemCheckboxLabel htmlFor={`class_age_${classAge}`}>
        {classAge}歳児
      </InputItemCheckboxLabel>
    </>
  );
};

const InputItemCheckbox = styled.input`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: none;

  &:checked + label::before {
    background-color: ${color.gray};
  }
  &:checked + label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const InputItemCheckboxLabel = styled.label`
  font-size: 14px;
  position: relative;
  padding-left: 22px;
  &::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${color.gray}; /* 枠の色変更 お好きな色を */
    background-color: ${color.white}; /* 背景の色変更 お好きな色を */
  }
`;
