import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import BlockHeader from "../Parts/BlockHeader";
import TopicBlockHeader from "../Parts/TopicBlockHeader";
import MainBlockContainer from "../Parts/MainBlockContainer";
import TopicsList from "./TopicsList";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import { pc, minSp } from "components/mixins/mediaQuery";

export interface newTypes {
  name: string;
}

const TopicsBlock = (props: {
  manual: ManualItemForSelector;
  topics: TopicItem[];
}) => {
  const { manual, topics } = props;
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  return (
    <TopicsBlockSC className={rightSectionOpen ? "open" : "close"}>
      <MainBlockContainer>
        <Wrapper>
          <TopicBlockHeader blockTitle={manual.name} />
          <Content>{manual.description}</Content>
        </Wrapper>
      </MainBlockContainer>

      <MainBlockContainer>
        <BlockHeader blockTitle="目次" />
        <Content>
          <TopicsList topics={topics} />
        </Content>
      </MainBlockContainer>
    </TopicsBlockSC>
  );
};

export default TopicsBlock;

const TopicsBlockSC = styled.div`
  ${minSp`
    width: 100%;
    order: 2;
  `}
  ${pc`
    width: calc(100% - 380px);
    margin-right: 20px;
    order: 1;
    &.close {
    width: 100%;
    margin-right: 0px;
  }
  `}
`;

const Wrapper = styled.div`
  padding: 12px;
`;
const Content = styled.div`
  padding-bottom: 60px;
  white-space: pre-line;
  line-height: 1.6em;
  ${pc`
    width: 100%;
  `}
`;
