import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { buttonBaseStyle } from "../../mixins";
import color from "../../mixins/color";

interface Props {
  description: string;
  buttonText: string;
  isActive?: boolean;
  buttonClick?: () => void;
}

const FixedActionButton = (props: Props) => {
  const { description, buttonText, buttonClick, isActive } = props;
  const sidebarIsOpen = useSelector((state: any) => state.sidebar.sidebaIsOpen);
  return (
    <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
      <ButtonAreaDeskSC>{description}</ButtonAreaDeskSC>
      <ActionButtonSC className="active" onClick={buttonClick}>
        {buttonText}
      </ActionButtonSC>
    </FixedButtonAreaSC>
  );
};

export default FixedActionButton;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  z-index: 1000;
  ${pc`
  display: flex;
  justify-content: center;
  align-items: center;
  left: auto;
  margin-left: -30px;
  width: calc(100vw - 200px);
  height: 70px;
  padding: 16px 0;
  box-shadow: none;
`}
  &.sidebar-closed {
    width: 100%;
    ${pc`
    width: calc(100vw - 50px);
  `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const ActionButtonSC = styled.button`
  ${buttonBaseStyle}
  background: #444444;
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
