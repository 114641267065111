import { useSelector } from "react-redux";

interface CurrentPosition {
  isGeneralViewer: boolean;
  isGeneralEditor: boolean;
  isFacilityManager: boolean;
  isProjectManager: boolean;
  isHonbu: boolean;
  isAdmin: boolean;
}

export const useCurrentPosition = (): CurrentPosition => {
  const position_id = useSelector((state: any) => state.session.position.id);
  const isGeneralViewer = position_id === 4;
  const isGeneralEditor = position_id === 1;
  const isFacilityManager = position_id === 5;
  const isProjectManager = position_id === 6;
  const isHonbu = position_id === 2;
  const isAdmin = position_id === 3;
  return {
    isGeneralViewer,
    isGeneralEditor,
    isFacilityManager,
    isProjectManager,
    isHonbu,
    isAdmin
  };
};
