import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import HeroContainer from "../Parts/HeroContainer";
import ModuleArea from "../Parts/ModuleArea";
import UpdateHistoriesModule from "../UpdateHistoriesModule";
import ManualsList from "../Manuals/ManualsList";
import BlockHeader from "../Parts/BlockHeader";
import SearchModuleForManual from "../SearchBlockForManual";
import { PageLayout } from "../Parts/MainLayout";
import { useHooks } from "../Manuals/Hooks";
import { useTopHooks } from "./Hooks";
import { getManualsRequest } from "store/manual/manuals/actions";
import { containerBaseStyle } from "components/mixins";
import { useSelector } from "react-redux";
import { toggleRightSection } from "../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../styles/layout/containers";

function ManualTop() {
  const location = useLocation();
  const pathName = location.pathname;
  const { histories, tags } = useTopHooks();
  const { token, dispatch, hookedManuals } = useHooks();
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  React.useEffect(() => {
    dispatch(getManualsRequest({ token }));
  }, [dispatch, token]);

  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <PageLayout pageTitle="マニュアルHOME" breadcrambIsInvisible={true}>
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <HeroContainer data={hookedManuals} />
      <ContentSC>
        <ManualTopContainer className={rightSectionOpen ? "open" : "close"}>
          <BlockHeader blockTitle="マニュアル一覧" />
          <ManualsList types={hookedManuals} />
        </ManualTopContainer>
        <ModuleArea>
          <SearchModuleForManual data={tags} />
          <UpdateHistoriesModule data={histories} />
        </ModuleArea>
      </ContentSC>
    </PageLayout>
  );
}

export default ManualTop;
// ===================================================================================
// スタイル
// ===================================================================================

const ContentSC = styled.div`
  width: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  ${pc`
    justify-content: start;
    flex-direction: row;
    align-items: flex-start;
  `}
`;

const ManualTopContainer = styled.div`
  ${containerBaseStyle}
  width: 100%;
  max-width: auto;
  margin-bottom: 20px;
  order: 2;
  padding: 0px;
  ${pc`
    width: calc( 100% - 380px);
    max-width: 810px;
    margin-right: 20px;
    order: 1;
    padding: 30px;
    &.close {
      width: 100%;
      max-width: 1190px;
      margin-right: 0px;
    }
  `}
  position: relative;
`;
