import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import color from "../../mixins/color";
import clock from "../../../images/icons/clock.svg";
import localeTimeCustomizer from "../../Common/LocaleTimeCustomizerForArticles";
import { startNewsEdit } from "../../../store/global/news_edit/action";
import { setNewsDetailId } from "../../../store/global/news_detail/action";

function NewsItem(props: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;
  const { id, title, released_at, is_news_edit_list, updated_at, read } = props;
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  const newsList = useSelector((state: any) => state.news);
  const news = newsList.find((article: any) => article.id === id);

  const content = news.content;
  const status = props.status;
  console.log(status);
  const handleClickItem = () => {
    if (pathName === "/news") {
      dispatch(setNewsDetailId(id));
    } else if (pathName === "/news-edit-list") {
      dispatch(startNewsEdit(id, title, content, status, "editing"));
    }
  };

  const today = new Date();
  const date = new Date(updated_at);
  const two_weeks_later = new Date(date.setDate(date.getDate() + 14));
  let within_two_weeks;
  if (today > two_weeks_later) {
    within_two_weeks = false;
  } else {
    within_two_weeks = true;
  }
  return (
    <NewsItemSC>
      <NewsItemLink
        to={
          (isAdmin && is_news_edit_list) || (isEditor && is_news_edit_list)
            ? "/news/" + id + "/edit"
            : "/news/" + id
        }
        activeStyle={{ color: "red" }}
        className={read ? "read" : ""}
        onClick={handleClickItem}
      >
        {/* 記事アイテム上部 */}
        <NewsItemTop>
          {/* 公開日 */}
          <ReleasedAt>{localeTimeCustomizer(updated_at)}</ReleasedAt>
          {within_two_weeks && !is_news_edit_list && <New>新着</New>}
          {status === 3 && is_news_edit_list && (
            <NewsItemStatusIcon>下書き</NewsItemStatusIcon>
          )}
          {status === 1 && is_news_edit_list && (
            <NewsItemStatusIcon>非公開</NewsItemStatusIcon>
          )}
        </NewsItemTop>

        {/* お知らせタイトル */}
        <NewsItemHeading>{title}</NewsItemHeading>
      </NewsItemLink>
    </NewsItemSC>
  );
}

export default NewsItem;

const NewsItemSC = styled.li`
  width: 100%;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
`;

const NewsItemHeading = styled.h2`
  width: 100%;
  font-size: 16px;
  line-height: inherit;
  font-weight: 600;
  color: ${color.gray};
  margin: 14px 0;
  word-break: break-all;
`;

const NewsItemLink = styled(NavLink)`
  display: block;
  width: 100%;
  min-height: 108px;
  padding: 16px 20px;
  cursor: pointer;
  &:hover {
    background: ${color.primary_white};
    opacity: 0.6;
    ${NewsItemHeading} {
      // color: ${color.tag_color};
    }
  }
  &:visited {
    color: ${color.gray};
    background: ${color.primary_white};
    .ArticlesItemHeading {
      color: ${color.tag_color};
    }
  }
  &.read {
    color: ${color.gray};
    background: ${color.primary_white};
    .ArticlesItemHeading {
      color: ${color.gray};
    }
  }
`;

const NewsItemTop = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const ReleasedAt = styled.p`
  display: block;
  font-size: 12px;
  padding-left: 18px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 24px;
`;

const New = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 10px;
  padding: 4px 12px;
`;

const NewsItemStatusIcon = styled.span`
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  margin-right: 8px;
  margin-bottom: 4px;
  color: ${color.white};
  background-color: ${color.gray};
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
`;
