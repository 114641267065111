import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getPageRequest,
  setPageDetailFromPages
} from "store/manual/manuals/pages/actions";
import { manualPagesSelector } from "store/manual/manuals/pages/selectors";
import { Page } from "store/manual/manuals/pages/types.d";
import { getIndexRequest } from "../../../store/manual/indexes/actions";

const usePageDetailHooks = () => {
  const dispatch = useDispatch();
  const { manualId, pageId } =
    useParams<{ manualId: string; pageId: string }>();
  const token = useSelector((state: any) => state.session.token);
  const index = useSelector((state: any) => state.indexes.index);
  const pages = useSelector(manualPagesSelector);
  console.log("current pages", pages);

  React.useEffect(() => {
    if (pages.some((page: Page) => page.id === Number(pageId))) {
      //pages情報の中に選択したpage情報があるならリクエストせずに、そこから情報をとってくる。
      dispatch(setPageDetailFromPages(pages, pageId));
    } else {
      //pages情報の中に選択したpage情報がなかったらリクエストする。
      dispatch(getPageRequest({ token, id: Number(pageId) }));
    }
  }, [dispatch, token, pageId]);

  React.useEffect(() => {
    if (index.id !== Number(manualId)) {
      dispatch(getIndexRequest(token, Number(manualId)));
    }
  }, [dispatch, token, manualId]);

  return {
    index
  };
};

export default usePageDetailHooks;
