import {
  POST_INQUIRY_NEW_SUCCEEDED,
  POST_INQUIRY_NEW_FAILED,
  SET_INQUIRY_INPUT_NEW,
  TOGGLE_INQUIRY_NEW_POST_STATUS,
  SET_TERMINAL,
  RESET_POSTED_DETAILS,
  POST_INQUIRY_MANAGEMENT_REQUESTED,
  POST_INQUIRY_MANAGEMENT_FAILED,
  POST_INQUIRY_MANAGEMENT_SUCCEEDED
} from "./type";
import { GET_INQUIRIES_SUCCEEDED } from "../inquiries/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
interface Inquiry {
  id: number | null;
  user_id: number | null;
  contact: string;
  department: string;
  user_name: string;
  inquiry_name: string;
  employee_id: number | null;
  facility: string;
  summary: string;
  terminal: string;
  terminal_details: string;
  child_id: number | null;
  model: string;
  model_name: string;
  proxy_login: boolean | null;
  details: string;
  backlog_id: number | null;
  backlog_status: string;
  created_at: string;
  updated_at: string;
  post_status: boolean;
  posted_details: string;
}

const initialState: Inquiry = {
  id: null,
  user_id: null,
  contact: "",
  department: "",
  user_name: "",
  inquiry_name: "",
  employee_id: null,
  facility: "",
  summary: "",
  terminal: "",
  terminal_details: "",
  child_id: null,
  model: "",
  model_name: "",
  proxy_login: null,
  details: "",
  backlog_id: null,
  backlog_status: "",
  created_at: "",
  updated_at: "",
  post_status: false,
  posted_details: ""
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INQUIRIES_SUCCEEDED:
      return {
        ...state,
        post_status: false
      };

    case POST_INQUIRY_NEW_SUCCEEDED:
      console.log("新規投稿作成に成功しました status: ", action.status);
      return {
        id: null,
        user_id: null,
        contact: "",
        department: "",
        user_name: "",
        inquiry_name: "",
        employee_id: null,
        facility: "",
        summary: "",
        terminal: "",
        terminal_details: "",
        child_id: null,
        model: "",
        model_name: "",
        proxy_login: null,
        details: "",
        backlog_id: null,
        backlog_status: "",
        created_at: "",
        updated_at: "",
        post_status: true,
        posted_details: action.details
      };
    case POST_INQUIRY_MANAGEMENT_SUCCEEDED:
      console.log("新規投稿作成に成功しました status: ", action.status);
      return {
        id: null,
        user_id: null,
        contact: "",
        department: "",
        user_name: "",
        inquiry_name: "",
        employee_id: null,
        facility: "",
        summary: "",
        terminal: "",
        terminal_details: "",
        child_id: null,
        model: "",
        model_name: "",
        proxy_login: null,
        details: "",
        backlog_id: null,
        backlog_status: "",
        created_at: "",
        updated_at: "",
        post_status: true,
        posted_details: action.details
      };

    case POST_INQUIRY_NEW_FAILED:
      console.log("新規投稿作成エラー:", action.message);
      return {
        ...state
      };
    case POST_INQUIRY_MANAGEMENT_FAILED:
      console.log("新規投稿作成エラー:", action.message);
      return {
        ...state
      };

    case SET_INQUIRY_INPUT_NEW:
      const str2bool = (value: string) => {
        if (value && typeof value === "string") {
          if (value.toLowerCase() === "true") return true;
          if (value.toLowerCase() === "false") return false;
        }
        return value;
      };

      if (action.input_target === "proxy_login") {
        return {
          ...state,
          proxy_login: str2bool(action.input_value)
        };
      } else {
        return {
          ...state,
          [action.input_target]: action.input_value
        };
      }

    case TOGGLE_INQUIRY_NEW_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status
      };

    case SET_TERMINAL:
      return {
        ...state,
        terminal: action.terminal_string
      };

    case RESET_POSTED_DETAILS:
      return {
        ...state,
        posted_details: ""
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
