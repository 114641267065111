export const loadingReducer = (state = {}, action: any) => {
  const { type } = action;
  const matches = /(.*)_(REQUESTED|SUCCEEDED|FAILED|401|403|404|422|500|)/.exec(
    type
  );
  if (!matches) {
    return state;
  }
  const [, requestName, requestState] = matches;
  return {
    ...state,
    isLoading: requestState === "REQUESTED"
  };
};
