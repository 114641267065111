import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import close from "../../../../images/icons/close.svg";

import { toggleCSVDownloadRejectModal } from "../../../../store/common/modal/action";

export default function DeleteEventModal() {
  const dispatch = useDispatch();
  return (
    <CSVDownloadRejectModalSC>
      <TitleBlock>
        <Title>メニュー選択エラー</Title>
        <CloseButton
          src={close}
          onClick={() => dispatch(toggleCSVDownloadRejectModal(false))}
        />
      </TitleBlock>
      <ContentBlock>
        <RejectText>
          <p>ご利用のアカウントでは</p>
          <p>CSV管理は利用できません</p>
        </RejectText>

        <Button onClick={() => dispatch(toggleCSVDownloadRejectModal(false))}>
          OK
        </Button>
      </ContentBlock>
    </CSVDownloadRejectModalSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const CSVDownloadRejectModalSC = styled.div`
  max-width: 600px;
  width: 90%;
  height: 263px;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.h1`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  font-weight: 300;
  padding: 20px 24px;
  font-size: 16px;
  color: ${color.gray};
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.p``;

const CloseButton = styled.img`
  cursor: pointer;
`;

const ContentBlock = styled.div`
  text-align: center;
  padding: 40px 54px 28px;
`;

const RejectText = styled.div`
  font-size: 14px;
  color: ${color.gray};
  padding-bottom: 70px;
  > p {
    display: block;
    &:first-of-type {
      margin-bottom: 7px;
    }
  }
`;

const Button = styled.div`
  margin: 0 auto;
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  &.delete {
    color: ${color.white};
    background-color: ${color.gray};
  }
`;
