//承認関連
const SET_FACILITY_ID = "SET_FACILITY_ID";
const ADD_TO_BE_APPROVED = "ADD_TO_BE_APPROVED";
const TOGGLE_TO_BE_APPROVED = "TOGGLE_TO_BE_APROVED";
const TOGGLE_TO_BE_APPROVED_ALL = "TOGGLE_TO_BE_APPROVED_ALL";
const TOGGLE_SELECTED_OPTIONS = "TOGGLE_SELECTED_OPTIONS";
const RESET_TO_BE_APPROVED = "RESET_TO_BE_APPROVED";
const RESET_SELECTED_OPTOIONS = "RESET_SELECTED_OPTOIONS";
const RESET_SELECTED_MONTH = "RESET_SELECTED_MONTH";
const GET_FACILITIES_APPROVALS_STATUS_REQUESTED =
  "GET_APPROVALS_STATUS_REQUESTED";
const GET_FACILITIES_APPROVALS_STATUS_SUCCEEDED =
  "GET_APPROVALS_STATUS_SUCCEEDED";
const GET_FACILITIES_APPROVALS_STATUS_FAILED = "GET_APPROVALS_STATUS_FAILED";
const GET_MONTHLY_APPROVAL_STATUS_REQUESTED =
  "GET_MONTHLY_APPROVAL_STATUS_REQUESTED";
const GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED =
  "GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED";
const GET_MONTHLY_APPROVAL_STATUS_FAILED = "GET_MONTHLY_APPROVAL_STATUS_FAILED";
const POST_APPROVALS_MONTHLY_REQUSEDTED = "POST_APPROVALS_MONTHLY_REQUSEDTED";
const POST_APPROVALS_MONTHLY_SUCCEEDED = "POST_APPROVALS_MONTHLY_SUCCEEDED";
const POST_APPROVALS_MONTHLY_FAILED = "POST_APPROVALS_MONTHLY_FAILED";
const SET_ALREADY_APPROVED_MONTH = "SET_ALREADY_APPROVED_MONTH";

// 差し戻し関連
const TOGGLE_TO_BE_REJECTED = "TOGGLE_TO_BE_REJECTED";
const TOGGLE_TO_BE_REJECTED_ALL = "TOGGLE_TO_BE_REJECTED_ALL";
const RESET_TO_BE_REJECTED = "RESET_TO_BE_REJECTED";
const SET_REJECT_COMMENT = "SET_REJECT_COMMENT";
const RESET_REJECT_COMMENT = "RESET_REJECT_COMMENT";
const SET_REJECT_COMMENT_FOR_ALL = "SET_REJECT_COMMENT_FOR_ALL";
const ADD_TO_BE_DELETE_REJECT = "ADD_TO_BE_DELETE_REJECT";
const POST_REJECT_REQUESTED = "POST_REJECT_REQUESTED";
const POST_REJECT_SUCCEEDED = "POST_REJECT_SUCCEEDED";
const POST_REJECT_FAILED = "POST_REJECT_FAILED";
const DELETE_REJECT_REQUESTED = "DELETE_REJECT_REQUESTED";
const DELETE_REJECT_SUCCEEDED = "DELETE_REJECT_SUCCEEDED";
const DELETE_REJECT_FAILED = "DELETE_REJECT_FAILED";
const SET_SELECTED_MONTH_TO_APPROVAL = "SET_SELECTED_MONTH_TO_APPROVAL";
const POST_REJECT_YEAR_MONTHLY_REQUESTED = "POST_REJECT_YEAR_MONTHLY_REQUESTED";
const POST_REJECT_YEAR_MONTHLY_SUCCEEDED = "POST_REJECT_YEAR_MONTHLY_SUCCEEDED";
const POST_REJECT_YEAR_MONTHLY_FAILED = "POST_REJECT_YEAR_MONTHLY_FAILED";

// 一括取り消し
const SET_MONTH_TO_CANCEL_ALL = "SET_MONTH_TO_CANCEL_ALL";
const CANCEL_ALL_EVENTS_REQUESTED = "CANCEL_ALL_EVENTS_REQUESTED";
const CANCEL_ALL_EVENTS_SUCCEEDED = "CANCEL_ALL_EVENTS_SUCCEEDED";
const CANCEL_ALL_EVENTS_FAILED = "CANCEL_ALL_EVENTS_FAILED";

export {
  SET_FACILITY_ID,
  ADD_TO_BE_APPROVED,
  TOGGLE_TO_BE_APPROVED,
  TOGGLE_TO_BE_APPROVED_ALL,
  TOGGLE_SELECTED_OPTIONS,
  RESET_SELECTED_OPTOIONS,
  RESET_TO_BE_APPROVED,
  RESET_SELECTED_MONTH,
  GET_FACILITIES_APPROVALS_STATUS_REQUESTED,
  GET_FACILITIES_APPROVALS_STATUS_SUCCEEDED,
  GET_FACILITIES_APPROVALS_STATUS_FAILED,
  GET_MONTHLY_APPROVAL_STATUS_REQUESTED,
  GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
  GET_MONTHLY_APPROVAL_STATUS_FAILED,
  POST_APPROVALS_MONTHLY_REQUSEDTED,
  POST_APPROVALS_MONTHLY_SUCCEEDED,
  POST_APPROVALS_MONTHLY_FAILED,
  // 差し戻し関連
  TOGGLE_TO_BE_REJECTED,
  TOGGLE_TO_BE_REJECTED_ALL,
  RESET_TO_BE_REJECTED,
  SET_REJECT_COMMENT,
  RESET_REJECT_COMMENT,
  SET_REJECT_COMMENT_FOR_ALL,
  POST_REJECT_REQUESTED,
  POST_REJECT_SUCCEEDED,
  POST_REJECT_FAILED,
  ADD_TO_BE_DELETE_REJECT,
  DELETE_REJECT_REQUESTED,
  DELETE_REJECT_SUCCEEDED,
  DELETE_REJECT_FAILED,
  SET_SELECTED_MONTH_TO_APPROVAL,
  SET_ALREADY_APPROVED_MONTH,
  POST_REJECT_YEAR_MONTHLY_REQUESTED,
  POST_REJECT_YEAR_MONTHLY_SUCCEEDED,
  POST_REJECT_YEAR_MONTHLY_FAILED,
  // 一括取り消し
  SET_MONTH_TO_CANCEL_ALL,
  CANCEL_ALL_EVENTS_REQUESTED,
  CANCEL_ALL_EVENTS_SUCCEEDED,
  CANCEL_ALL_EVENTS_FAILED
};
