import { History } from "../../../components/Manual/demoData";
import {
  GET_HISTORIES_REQUESTED,
  UPDATE_ALL_HISTORIES_REQUESTED,
  UPDATE_HISTORY_REQUESTED,
} from "./types";

export const getHistoriesRequest = (token: string) => {
  return {
    type: GET_HISTORIES_REQUESTED,
    token,
  };
};

export const updateHistoryRequest = (
  token: string,
  id: number,
  priority: boolean
) => {
  return {
    type: UPDATE_HISTORY_REQUESTED,
    token,
    id,
    priority,
  };
};

export const updateAllHistoriesRequest = (
  token: string,
  histories: History[]
) => {
  return {
    type: UPDATE_ALL_HISTORIES_REQUESTED,
    token,
    histories,
  };
};
