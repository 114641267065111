import {
  TOGGLE_SIDEBAR,
  TOGGLE_FAQ,
  TOGGLE_NEWS,
  TOGGLE_MASTER,
  TOGGLE_ACCOUNT,
  TOGGLE_INQUIRY,
  TOGGLE_RIGHT_SECTION,
  SET_RIGHT_SECTION_OPEN
} from "./type";

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR
  };
};
export const toggleFaq = () => {
  return {
    type: TOGGLE_FAQ
  };
};
export const toggleNews = () => {
  return {
    type: TOGGLE_NEWS
  };
};
export const toggleMaster = () => {
  return {
    type: TOGGLE_MASTER
  };
};
export const toggleAccount = () => {
  return {
    type: TOGGLE_ACCOUNT
  };
};
export const toggleInquiry = () => {
  return {
    type: TOGGLE_INQUIRY
  };
};
export const toggleRightSection = () => {
  return {
    type: TOGGLE_RIGHT_SECTION
  };
};
export const setRightSectionOpen = (open: boolean) => {
  return {
    type: SET_RIGHT_SECTION_OPEN,
    open
  };
};
