import styled from "styled-components";

export const ModalSettingBackgroundSC = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const ModalSettingSC = styled.div`
  z-index: 200;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const ModalSettingDisplaySC = styled.div`
  background-color: #ffffff;
  width: 700px;
  height: fit-content;
  min-height: 547px;
  border: 1px solid #ffffff;
  border-radius: 26px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 100px auto;
  max-width: 100%;
  max-height: 100%;
`;

export const ModalSettingDisplayCanselSC = styled.span`
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  top: 20px;
  left: 92%;
  cursor: pointer;
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::before,
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 30px;
    background: #c4c4c4;
    border-radius: 3px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const ModalSettingTitleSC = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-left: 50px;
`;

export const ModalSettingWarningTextSC = styled.div`
  font-size: 18px;
  line-height: 1.5;
  width: 609px;
  height: 37px;
  align-items: center;
  display: flex;
  color: #ff5050;
  border: 1px solid #ff5050;
  margin: 17px auto 30px;
`;

export const ModalSettingWarningIconSC = styled.img`
  margin-left: 12px;
  margin-right: 87.7px;
`;

export const ModalSettingListSC = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin-left: 45px;
  margin-right: 45px;
  border-top: 1px solid #f2f2f2;
  margin-bottom: 195px;
`;

export const ModalSettingDlSC = styled.dl`
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
`;

export const ModalSettingDtSC = styled.dt`
  margin-left: 7px;
  align-items: center;
  min-width: 100px;
`;

export const ModalSettingDdSC = styled.dd`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 8px;
  margin-left: 20px;
`;

export const ModalSettingSelectDdSC = styled.dd`
  width: calc(100% - 8px);
  margin-left: 20px;
`;

export const ModalSettingSlectSC = styled.select`
  border: 1px solid #707070;
  border-radius: 4px;
  width: 298px;
  height: 25px;
  padding-left: 11px;
  align-items: center;
  margin-left: 51px;
  font-size: 12px;
`;

export const ModalSettingOptionSC = styled.option``;

export const ModalSettingTagDdSC = styled.dd`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 127px;
  gap: 10px 27px;
`;

export const ModalSettingClassDdSC = styled.dd`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 46px;
  gap: 0 18px;
`;

export const ModalSettingReleasDdSC = styled.dd`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 58px;
  gap: 0 18px;
`;

export const ModalSettingButtonSC = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
