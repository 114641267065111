import React, { useState } from "react";
import { ButtonSC } from "../Button/style";
import {
  ModalDeleteBackgroundSC,
  ModalDeleteButtonSC,
  ModalDeleteDisplayCanselSC,
  ModalDeleteDisplaySC,
  ModalDeleteSC,
  ModalDeleteTextSC,
  ModalDeleteTitleSC
} from "./style";

interface ModalDeleteProps {
  type?: "default" | "alert";
  disabled?: boolean;
  // モーダルの開閉
  toggleVisible: () => void;
  // 削除ボタンクリック時の挙動
  onAcceptConfirm: () => void;
  open: boolean;
}

export const ModalDelete: React.FC<ModalDeleteProps> = props => {
  const { type, disabled, onAcceptConfirm, open, toggleVisible } = props;
  const [visible, setVisible] = useState(true);
  return (
    <>
      <ModalDeleteBackgroundSC
        onClick={() => setVisible(!visible)}
        style={{ display: open ? "block" : "none" }}
      ></ModalDeleteBackgroundSC>

      <ModalDeleteSC>
        <ModalDeleteDisplaySC style={{ display: open ? "block" : "none" }}>
          <ModalDeleteDisplayCanselSC onClick={toggleVisible} />
          <ModalDeleteTitleSC>写真を削除</ModalDeleteTitleSC>
          <ModalDeleteTextSC>
            対象の写真を削除してもよろしいですか?
          </ModalDeleteTextSC>
          <ModalDeleteButtonSC>
            <ButtonSC
              style={{
                width: "160px",
                height: "38px",
                padding: "9px 45px",
                textAlign: "center"
              }}
              className="default"
              disabled={disabled}
              onClick={toggleVisible}
            >
              キャンセル
            </ButtonSC>
            <ButtonSC
              style={{
                width: "160px",
                height: "38px",
                textAlign: "center",
                marginLeft: "84px"
              }}
              className="alert"
              disabled={disabled}
              onClick={onAcceptConfirm}
            >
              削除
            </ButtonSC>
          </ModalDeleteButtonSC>
        </ModalDeleteDisplaySC>
      </ModalDeleteSC>
    </>
  );
};
