import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "../../mixins";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import { pc } from "components/mixins/mediaQuery";
import { localeTimeForManual } from "components/Common/localeTimeCustomizerForManual";

const TopicsItem = (props: TopicItem) => {
  const { id, type_id, name, updated_at } = props;
  return (
    <li>
      <TopicsItemSC to={`/manual/manuals/${type_id}/topics/${id}`}>
        <TopicsItemContent>
          <TopicsItemName>{name}</TopicsItemName>
          <TopicsItemDate>
            最終更新: {localeTimeForManual(updated_at)}
          </TopicsItemDate>
        </TopicsItemContent>
      </TopicsItemSC>
    </li>
  );
};

export default TopicsItem;

const TopicsItemSC = styled(Link)`
  ${pc`
    padding: 22px 22px 22px 55px;
    width: 100%;
  `}
  display: block;
  border-bottom: 1px solid ${color.list_border};
  padding: 12px;
  &:hover {
    opacity: 0.6;
  }
`;

const TopicsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopicsItemName = styled.dt`
  font-size: 14px;
  width: 50%;
  ${pc`
    font-size: 16px;
  `}
`;

const TopicsItemDate = styled.dd`
  font-size: 12px;
  color: ${color.light_gray};
  width: 50%;
  text-align: right;
  ${pc`
    font-size: 11px;
  `}
`;
