import React from "react";

import {
  CardContainerSC,
  CardImgSC,
  CardLinkSC,
  CardListsSC,
  CardFileNameSC,
  CardSC,
  CardTagNameSC,
  CardTitleSC,
  CardWrapperSC,
  CardCheckBoxWrapperSC
} from "./style";

import { DoronkoMediaFileType } from "components/Camera/type";
import { ClassNum } from "./classNum";
import { Checkbox } from "../Checkbox";

export const Card = (props: { mediaFile: DoronkoMediaFileType }) => {
  const { mediaFile } = props;
  const { thumbnail_path, classes, tags, facilities, name, id } = mediaFile;
  return (
    <CardSC>
      <CardLinkSC to={`/camera/detail/${id}`}>
        <CardContainerSC>
          <CardImgSC src={thumbnail_path} />
          <ClassNum classes={classes} />
        </CardContainerSC>
        <CardListsSC>
          <CardTitleSC>
            {tags.map((tag, index) => {
              return index < tags.length ? `#${tag.name} ` : `#${tag.name}`;
            })}
          </CardTitleSC>
          <CardTitleSC>
            {facilities.map(facility => {
              return (
                <CardTagNameSC key={facility.id}>{facility.name}</CardTagNameSC>
              );
            })}
          </CardTitleSC>
          <CardFileNameSC>{name}</CardFileNameSC>
        </CardListsSC>
      </CardLinkSC>
    </CardSC>
  );
};

export const CardSelectable = (props: {
  mediaFile: DoronkoMediaFileType;
  onSelectMedia: (mediaFileId: number) => void;
  selectedMedia: number[];
}) => {
  const { mediaFile, onSelectMedia, selectedMedia } = props;
  const { thumbnail_path, classes, tags, facilities, name, id } = mediaFile;
  const isSelecetdCheck = (targetId: number) => {
    return selectedMedia.includes(targetId);
  };
  const handleSelectMedia = () => {
    onSelectMedia(id);
  };
  return (
    <CardSC onClick={handleSelectMedia}>
      <CardCheckBoxWrapperSC>
        <Checkbox
          id={`thumbnail-${id}`}
          checked={isSelecetdCheck(id)}
          onChange={() => {}}
        />
      </CardCheckBoxWrapperSC>
      <CardWrapperSC>
        <CardContainerSC>
          <CardImgSC src={thumbnail_path} />
          <ClassNum classes={classes} />
        </CardContainerSC>
        <CardListsSC>
          <CardTitleSC>
            {tags.map((tag, index) => {
              return index < tags.length ? `#${tag.name} ` : `#${tag.name}`;
            })}
          </CardTitleSC>
          <CardTitleSC>
            {facilities.map(facility => {
              return (
                <CardTagNameSC key={facility.id}>{facility.name}</CardTagNameSC>
              );
            })}
          </CardTitleSC>
          <CardFileNameSC>{name}</CardFileNameSC>
        </CardListsSC>
      </CardWrapperSC>
    </CardSC>
  );
};
