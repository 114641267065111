import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import ServiceSelectModalContent from "./ServiceSelectModalContent";

import { toggleServiceModal } from "../../../../store/common/modal/action";

export default function ServiceSelectModal() {
  const dispatch = useDispatch();
  return (
    <div>
      <ServiceSelectModalBg
        onClick={() => dispatch(toggleServiceModal(false))}
      ></ServiceSelectModalBg>
      <ServiceSelectModalContent />
    </div>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ServiceSelectModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
