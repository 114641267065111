import axios from "axios";
import { env_url, env_event_url } from "../../serverEnv";

// 行事予定一覧取得
const getMonthlyEventsApi = (
  token: string,
  facility_id: number,
  year: string,
  months?: string[],
  classes?: string[],
  remand?: string
) => {
  return axios.get(`${env_event_url}/monthly_events`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      facility_id: facility_id,
      year: year,
      months: months,
      classes: classes,
      remand: remand,
    },
  });
};

// 月間行事予定登録
function* postMonthlyEventsApi(
  token: string,
  facility_id: number,
  year: number,
  month: number,
  type_id: number,
  content: string,
  detail: string,
  status: number,
  classes: string[],
  kind: number
) {
  try {
    const result = yield axios.post(
      `${env_event_url}/monthly_events`,
      {
        facility_id: facility_id,
        year: year,
        month: month,
        type_id: type_id,
        content: content,
        detail: detail,
        status: status,
        classes: classes,
        kind: kind,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 月間行事予定一覧取得
function* getMonthlyEventsAfterSaveApi(
  token: string,
  facility_id: number,
  year: string,
  months: string[],
  classes: string[],
  remand: string
) {
  return yield axios.get(`${env_event_url}/monthly_events`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      facility_id: facility_id,
      year: year,
      months: months,
      classes: classes,
      remand: remand,
    },
  });
}

// 月間行事予定更新
function* putMonthlyEventsApi(
  token: string,
  monthly_event_id: number,
  facility_id: number,
  year: number,
  month: number,
  type_id: number,
  content: string,
  detail: string,
  status: number,
  classes: string,
  kind: string
) {
  try {
    const result = yield axios.put(
      `${env_event_url}/monthly_events/${monthly_event_id}`,
      {
        facility_id: facility_id,
        year: year,
        month: month,
        type_id: type_id,
        content: content,
        detail: detail,
        status: status,

        kind: kind,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: monthly_event_id,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 月間行事予定削除
function* deleteMonthlyEventsApi(token: string, monthly_event_id: number) {
  try {
    const result = yield axios.delete(
      `${env_event_url}/monthly_events/${monthly_event_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: monthly_event_id,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

export {
  getMonthlyEventsApi,
  postMonthlyEventsApi,
  putMonthlyEventsApi,
  deleteMonthlyEventsApi,
  getMonthlyEventsAfterSaveApi,
};
