import styled from "styled-components";
export const DndContainerSC = styled.div`
  width: 90%;
  border-radius: 16px;
  background-color: #fff;
  height: 400px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionSC = styled.p`
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
  color: #888;
`;

export const SelectBtn = styled.p`
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
  background-color: #444;
  margin-top: 16px;
  color: #fff;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;
