import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { pc } from "../../mixins/mediaQuery";
import { buttonBaseStyle } from "../../mixins/common";
import color from "../../mixins/color";
import PageHeading from "../../Common/PageHeading";

import { toggleUploadSucceeded } from "../../../store/faq/csv/action";

function CSVUploadCompleted(props: any) {
  const dispatch = useDispatch();
  const facility_name = useSelector((state: any) => state.facility.name);

  // 「続けてアップロード」をクリック時に画面遷移するためにupload_succeededをfalseにする
  const handleContinueUpload = () => {
    dispatch(toggleUploadSucceeded(false));
  };

  return (
    <MainSC>
      <PageHeading title="CSV管理" />
      <ContainerSC>
        <CompleteDesc>{facility_name}の行事予定は上書きされました</CompleteDesc>
        <BackToUploadSC className="active">
          <Link to="/events/csv/upload" onClick={handleContinueUpload}>
            続けてアップロード
          </Link>
        </BackToUploadSC>
      </ContainerSC>
    </MainSC>
  );
}

export default CSVUploadCompleted;

const MainSC = styled.div`
  display: block;
  padding: 30px 12px 130px;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    padding: 30px 30px 60px;
  `}
`;

const ContainerSC = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 42px 30px;
    order: 1;
  `}
`;

const CompleteDesc = styled.p`
  font-size: 14;
  font-weight: bold;
  margin-bottom: 38px;
`;

const BackToUploadSC = styled.div`
  ${buttonBaseStyle}
  margin-right: 12px;
  color: ${color.white};
  background-color: ${color.gray};
  margin: 0 auto;
  width: 160px;
  height: 38px;
  ${pc`
    display: inline-block;
    margin: 0;
    padding: 11px;
  `}
  &:hover {
    opacity: 0.6;
  }
`;
