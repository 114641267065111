import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import trash from "../../../../images/icons/trash.svg";
import close from "../../../../images/icons/close.svg";
import {
  color,
  headingBaseStyle,
  containerBaseStyle,
  inputBaseStyle,
  buttonBaseStyle
} from "../../../mixins";
import {
  getAccountEditRequest,
  deleteAccountEditRequest,
  setAccountEditName,
  setAccountEditPositionId,
  setAccountEditEmail,
  setAccountEditPassword,
  putAccountEditRequest,
  toggleAccountEditPostStatus,
  setAccountEditFacilities
} from "../../../../store/global/account_edit/action";
import { history } from "../../../../store/store";

function AccountsEdit() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const account_id = useSelector((state: any) => state.account_edit.id);
  const account_name = useSelector((state: any) => state.account_edit.name);
  const account_position_id = useSelector(
    (state: any) => state.account_edit.position_id
  );
  const account_email = useSelector((state: any) => state.account_edit.email);
  const account_password = useSelector(
    (state: any) => state.account_edit.password
  );
  const post_status = useSelector(
    (state: any) => state.account_edit.post_status
  );
  const message = useSelector((state: any) => state.account_edit.message);
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const positions = useSelector((state: any) => state.positions);
  const account_facilities = useSelector(
    (state: any) => state.account_edit.facilities
  );
  const facilities = useSelector((state: any) => state.facilities);

  const [nameStatus, setNameStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState("correctInput");
  const [passwordStatus, setPasswordStatus] = useState("notInput");

  const location = useLocation();
  const pathName = location.pathname;
  const pathInfo = pathName.split("/");
  const fileName = Number(pathInfo.slice(-2, -1));

  useEffect(() => {
    dispatch(getAccountEditRequest(token, fileName));
  }, [dispatch]);

  // リロードした場合の初期値の状態に合わせてT/Fを変更する
  useEffect(() => {
    if (account_name !== "") {
      setNameStatus(true);
    }
    if (
      account_password !== "" &&
      account_password.match(/^[A-Za-z0-9]{8,}$/)
    ) {
      setPasswordStatus("correctInput");
    }
    if (account_email.match(/[^\s]+@[^\s]+/)) {
      setEmailStatus("correctInput");
    }
  }, []);

  const handleAccountEditName = (e: any) => {
    if (e.target.value !== "") {
      dispatch(setAccountEditName(e.target.value));
      setNameStatus(true);
    } else {
      dispatch(setAccountEditName(e.target.value));
      setNameStatus(false);
    }
  };

  const handleAccountEditPositionId = (e: any) => {
    dispatch(setAccountEditPositionId(Number(e.target.value)));
    // 本部所属だった場合全ての施設にチェックをつける
    if (e.target.value === "2" || e.target.value === "3") {
      facilities.map((facility: any) => {
        const alreadyChecked = account_facilities.some(
          (item: any) => item.id === facility.id
        );
        if (!alreadyChecked) {
          dispatch(setAccountEditFacilities(facility.id));
        }
      });
    }
  };

  const handleAccountEditEmail = (e: any) => {
    if (e.target.value === "") {
      dispatch(setAccountEditEmail(e.target.value));
      setEmailStatus("notInput");
    } else if (!e.target.value.match(/[^\s]+@[^\s]+/)) {
      dispatch(setAccountEditEmail(e.target.value));
      setEmailStatus("incorrectInput");
    } else {
      dispatch(setAccountEditEmail(e.target.value));
      setEmailStatus("correctInput");
    }
  };

  const handleAccountEditPassword = (e: any) => {
    if (e.target.value === "") {
      dispatch(setAccountEditPassword(e.target.value));
      setPasswordStatus("notInput");
    } else {
      if (e.target.value.match(/^[A-Za-z0-9]{8,}$/)) {
        dispatch(setAccountEditPassword(e.target.value));
        setPasswordStatus("correctInput");
      } else {
        dispatch(setAccountEditPassword(e.target.value));
        setPasswordStatus("incorrectInput");
      }
    }
  };

  const handleCheckFacility = (e: any) => {
    dispatch(setAccountEditFacilities(Number(e.target.value)));
  };

  // チェックボックスを全て未選択にする
  const handleClearFacilities = () => {
    facilities.map((facility: any) => {
      const alreadyChecked = account_facilities.some(
        (item: any) => item.id === facility.id
      );
      if (alreadyChecked) {
        dispatch(setAccountEditFacilities(facility.id));
      }
    });
  };

  const handleAccountEditSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      putAccountEditRequest(
        token,
        account_id,
        account_name,
        account_position_id,
        account_email,
        account_password,
        account_facilities
      )
    );
  };

  const handleAccountDelete = (e: any) => {
    e.preventDefault();
    dispatch(deleteAccountEditRequest(token, account_id));
  };

  useEffect(() => {
    dispatch(toggleAccountEditPostStatus(false));
  }, [dispatch]);

  let not_inputed_required_input_number = 0;
  if (nameStatus === false) {
    not_inputed_required_input_number++;
  }
  if (emailStatus !== "correctInput") {
    not_inputed_required_input_number++;
  }

  return (
    <>
      {post_status ? (
        history.push("/accounts")
      ) : (
        <MainSC>
          <AccountEditHeadingSC>アカウント編集</AccountEditHeadingSC>
          <AccountsEditContainerSC>
            <form action="">
              <InputListSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="name">ユーザー名*</label>
                    </dt>
                    <dd>
                      <InputBoxSC
                        id="name"
                        type="text"
                        onChange={handleAccountEditName}
                        value={account_name}
                        required
                      />
                    </dd>
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="authority">役職(仮)*</label>
                    </dt>
                    <dd>
                      <InputBoxSC
                        as="select"
                        className="select"
                        name="position_id"
                        id="position_id"
                        placeholder="選択してください"
                        defaultValue={account_position_id}
                        onChange={handleAccountEditPositionId}
                        required
                      >
                        {positions.map((position: any) => (
                          <option value={position.id}>{position.name}</option>
                        ))}
                      </InputBoxSC>
                    </dd>
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="email">メールアドレス*</label>
                    </dt>
                    {emailStatus === "correctInput" ? (
                      <dd>
                        <InputBoxSC
                          id="email"
                          type="email"
                          value={account_email}
                          onChange={handleAccountEditEmail}
                          required
                        />
                      </dd>
                    ) : (
                      <dd>
                        <InputBoxSC
                          id="email"
                          type="email"
                          value={account_email}
                          className="alert"
                          onChange={handleAccountEditEmail}
                          required
                        />
                        <InputAlert>
                          メールアドレスの形式が間違っています。
                        </InputAlert>
                      </dd>
                    )}
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC className="facility">
                    <dt>
                      <label>所属/管轄 施設</label>
                      <ClearButton onClick={handleClearFacilities}>
                        クリア
                      </ClearButton>
                    </dt>
                    <dd>
                      <FacilityListSC>
                        {facilities.map((facility: any) => {
                          return (
                            <>
                              <FacilityItemSC>
                                <CheckboxLabel>
                                  <Checkbox
                                    className="checkbox"
                                    id={facility.id}
                                    type="checkbox"
                                    value={facility.id}
                                    checked={
                                      account_facilities.some(
                                        (item: any) => item.id === facility.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={handleCheckFacility}
                                  />
                                  <CheckboxSpan className="label">
                                    {facility.name}
                                  </CheckboxSpan>
                                </CheckboxLabel>
                              </FacilityItemSC>
                            </>
                          );
                        })}
                      </FacilityListSC>
                    </dd>
                  </InputRawSC>
                </InputItemSC>
              </InputListSC>

              {not_inputed_required_input_number === 0 ? (
                <FixedButtonAreaSC
                  className={sidebarIsOpen ? "" : "sidebar-closed"}
                >
                  <ButtonAreaDeskSC>
                    未入力の必須項目は、ありません
                  </ButtonAreaDeskSC>
                  <SubmitButtonSC
                    className="active"
                    onClick={handleAccountEditSubmit}
                  >
                    更新
                  </SubmitButtonSC>
                </FixedButtonAreaSC>
              ) : (
                <FixedButtonAreaSC
                  className={sidebarIsOpen ? "" : "sidebar-closed"}
                >
                  <ButtonAreaDeskSC>
                    未入力の必須項目は、残り
                    <span>{not_inputed_required_input_number}</span>つです
                  </ButtonAreaDeskSC>
                  <SubmitButtonSC>更新</SubmitButtonSC>
                </FixedButtonAreaSC>
              )}
            </form>
          </AccountsEditContainerSC>
          {message === "" && (
            <TrashDescSC onClick={() => setModalIsOpen(!modalIsOpen)}>
              このアカウントを削除
            </TrashDescSC>
          )}

          {/* 削除モーダル */}
          {modalIsOpen && (
            <ModalBgSC onClick={() => setModalIsOpen(false)}>
              <ModalContainerSC onClick={e => e.stopPropagation()}>
                <ModalHeaderSC>
                  <ModalHeadingSC>アカウント削除</ModalHeadingSC>
                  <ModalCloseBtnSC
                    src={close}
                    alt="close"
                    onClick={() => setModalIsOpen(false)}
                  />
                </ModalHeaderSC>
                <ModalContentSC>
                  <ModalDescSC>{account_name}さんを削除しますか？</ModalDescSC>
                  <ModalButtonWrapperSC>
                    <ModalCancelBtnSC onClick={() => setModalIsOpen(false)}>
                      キャンセル
                    </ModalCancelBtnSC>

                    <ModalDeleteBtnSC onClick={handleAccountDelete}>
                      削除
                    </ModalDeleteBtnSC>
                  </ModalButtonWrapperSC>
                </ModalContentSC>
              </ModalContainerSC>
            </ModalBgSC>
          )}
        </MainSC>
      )}
    </>
  );
}

// ==========================================================
// スタイル
// ==========================================================
const MainSC = styled.div`
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const AccountsEditContainerSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  margin-bottom: 20px;
  padding: 24px 16px;
  ${pc`
    padding: 30px;
  `}
`;

const AccountEditHeadingSC = styled.div`
  ${headingBaseStyle}
`;

const InputListSC = styled.ul`
  width: 100%;
`;

const InputItemSC = styled.li`
  width: 100%;
  padding: 20px 0;
  border-bottom: solid 1px ${color.divide_border};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const InputRawSC = styled.dl`
  width: 100%;
  ${pc`
    width: 560px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.facility {
      width: 100%;
      justify-content: flex-start;
      align-items: start;
    }
  `}
  dt {
    margin-bottom: 14px;
    color: ${color.gray};
    ${pc`
      margin-bottom: 0;
      margin-right: 38px;
      width: 120px;
    `}
  }
`;
const ClearButton = styled.p`
  display: block;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 8px;
  margin: 10px 0 0 auto;
  width: 50px;
  border-radius: 4px;
  border: 1px solid ${color.primary};
  background: ${color.white};
  color: ${color.primary};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const InputBoxSC = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    width: 400px;
  `}
`;

const InputAlert = styled.p`
  display: block;
  font-size: 12px;
  color: #ff1111;
  margin-top: 12px;
`;

const InputDescSC = styled.p`
  color: ${color.primary_light};
  font-size: 12px;
  line-height: 18px;
  padding-top: 12px;
`;

const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -60px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
`;

const TrashDescSC = styled.p`
  color: ${color.primary};
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  padding-left: 18px;
  position: absolute;
  right: 30px;
  bottom: 110px;
  background-image: url(${trash});
  background-size: 12px 14px;
  background-position: left center;
  background-repeat: no-repeat;
  cursor: pointer;
  ${pc`
    bottom: auto;
  `}
`;
// =======================================
// 削除確認モーダル
// =======================================
const ModalBgSC = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: ${color.modal_bg};
  color: ${color.primary};
`;

const ModalContainerSC = styled.div`
  ${containerBaseStyle}
  padding: 0;
  width: 440px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
`;

const ModalHeaderSC = styled.div`
  width: 100%;
  background-color: ${color.primary_white};
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeadingSC = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const ModalCloseBtnSC = styled.img`
  display: inline-block;
  cursor: pointer;
`;

const ModalContentSC = styled.div`
  padding: 30px 54px;
`;

const ModalDescSC = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const ModalButtonWrapperSC = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalCancelBtnSC = styled.button`
  ${buttonBaseStyle}
  background-color: ${color.white};
  color: ${color.primary};
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
`;

const ModalDeleteBtnSC = styled.button`
  ${buttonBaseStyle}
  background-color: ${color.primary};
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
`;

//checkbox

const FacilityListSC = styled.ul`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  ${pc`
    width: 535px;
  `}
`;

const FacilityItemSC = styled.li`
  width: 33%;
  margin-bottom: 12px;
`;

const PrefectureLabel = styled.p`
  width: 100%;
  padding: 12px;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  ${pc`
    width: 535px;
  `}
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;

export default AccountsEdit;
