import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import {
  getArticleRequest,
  postArticleReadRequest,
} from "../../../../store/faq/article_detail/action";
import tag from "../../../../images/icons/tag.svg";
import clock from "../../../../images/icons/clock.svg";
import WysiwygArticleCurrentView from "./WysiwygArticleCurrentView";

function ArticleEditPreview(props: any) {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const stocks = useSelector((state: any) => state.articles_stocked);

  const current_article_title = useSelector(
    (state: any) => state.article_detail.title
  );
  const current_article_content = useSelector(
    (state: any) => state.article_detail.content
  );
  const current_article_categories = useSelector(
    (state: any) => state.article_detail.categories
  );
  const current_article_tags = useSelector(
    (state: any) => state.article_detail.tags
  );

  const edit_article_id = useSelector((state: any) => state.article_edit.id);

  useEffect(() => {
    dispatch(getArticleRequest(token, edit_article_id, stocks));
    dispatch(postArticleReadRequest(token, edit_article_id));
  }, [dispatch, token, edit_article_id]);

  return (
    <>
      <MainSC>
        <DetailHeaderSC>
          <HeaderUpsideSC>
            {/* カテゴリー一覧 */}
            <CategoryListSC>
              {current_article_categories.map((category: any) => {
                return <CategoryItemSC>{category.name}</CategoryItemSC>;
              })}
            </CategoryListSC>
          </HeaderUpsideSC>

          {/* タイトル */}
          <ArticleTitleSC>{current_article_title}</ArticleTitleSC>

          {/* タグ */}
          <TagListSC>
            {current_article_tags.map((tag: any) => {
              return <TagItemSC>{tag.name}</TagItemSC>;
            })}
          </TagListSC>
        </DetailHeaderSC>

        {/* コンテンツ */}
        <DetailContentsSC>
          {current_article_content &&
            typeof current_article_content === "string" && (
              <>
                <WysiwygArticleCurrentView content={current_article_content} />
              </>
            )}
        </DetailContentsSC>
      </MainSC>
    </>
  );
}

export default ArticleEditPreview;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
`;

const DetailHeaderSC = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  ${pc`
    padding: 30px;
  `}
`;

const HeaderUpsideSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CategoryListSC = styled.ul`
  display: inline-block;
`;

const CategoryItemSC = styled.li`
  display: inline-block;
  color: ${color.white};
  background-color: ${color.category_bg};
  font-size: 12px;
  padding: 4px 11px;
  border-radius: 10px;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 0;
  ${pc`
    margin-bottom: 5px;
  `}
`;

const TagListSC = styled.ul`
  display: flex;
  margin-bottom: 16px;
  ${pc`
    margin-bottom: 0;
  `}
`;

const TagItemSC = styled.li`
  display: inline-block;
  color: ${color.gray};
  font-size: 12px;
  border-radius: 10px;
  margin-right: 8px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background-image: url(${tag});
    background-repeat: no-repeat;
    background-position: left center;
    margin-right: 3px;
  }
`;

const ArticleTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
`;
