import { all, put, call, takeLatest } from "redux-saga/effects";
import { csvUploadApi } from "../api/event/csvApi";
import {
  CSV_UPLOAD_REQUESTED,
  CSV_UPLOAD_SUCCEEDED,
  CSV_UPLOAD_FAILED,
  RESET_CSV_STATE,
  TOGGLE_UPLOAD_SUCCEEDED
} from "../store/faq/csv/type";
import { TOGGLE_CSV_MODAL } from "../store/common/modal/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";

function* csvUpload(action: any) {
  const token = action.token;
  try {
    yield call(csvUploadApi, token, action.formData);
    yield all([
      put({ type: CSV_UPLOAD_SUCCEEDED, upload_succeeded: true }),
      put({ type: RESET_CSV_STATE }),
      put({ type: TOGGLE_CSV_MODAL, csv_modal_open: false })
    ]);
  } catch (error) {
    window.alert("アップロードに失敗しました");
    yield all([
      put({ type: CSV_UPLOAD_FAILED }),
      put({ type: TOGGLE_CSV_MODAL, csv_modal_open: false }),
      put({ type: RESET_CSV_STATE })
    ]);
    switch (error.response.status) {
      case 401:
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      case 403:
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      case 404:
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      case 422:
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      case 500:
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const csvSaga = [takeLatest(CSV_UPLOAD_REQUESTED, csvUpload)];

export default csvSaga;
