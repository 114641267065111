import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import {
  getMediaFilesMineApi,
  getMediaFilesAdminApi,
  getMediaFilesShareApi,
  putMediaFilesApi,
  postMediaFileUpload,
  deleteMediaFileApi,
  searchMediaFilesApi
} from "../api/media-files";
import {
  GET_MEDIA_FILES_MINE_SUCCEEDED,
  GET_MEDIA_FILES_MINE_REQUESTED,
  GET_MEDIA_FILES_SHARE_REQUESTED,
  GET_MEDIA_FILES_ADMIN_SUCCEEDED,
  GET_MEDIA_FILES_ADMIN_REQUESTED,
  GET_MEDIA_FILES_SHARE_SUCCEEDED,
  UPDATE_SELECTED_MEDIA_FILES_REQUESTED,
  UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED,
  UPLOAD_MEDIA_FILE_REQUESTED,
  TOGGLE_IMG_LOADING_STATE,
  DELETE_MEDIA_FILE_REQUESTED,
  RESET_SELECTED_MEDIA_FILE_IDS,
  SEARCH_MEDIA_FILES_SUCCEEDED,
  SEARCH_MEDIA_FILES_REQUESTED
} from "store/camera/mediafiles/type";

function* getMediaFileAdmin(action: any): Generator<any, any, any> {
  try {
    const result = yield call(getMediaFilesAdminApi, action.token);
    yield put({
      type: GET_MEDIA_FILES_ADMIN_SUCCEEDED,
      media_files: result.data.media_files,
      paginate_admin: result.data.paginate
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* getMediaFilesShare(action: any): Generator<any, any, any> {
  try {
    const result = yield call(getMediaFilesShareApi, action.token);
    yield put({
      type: GET_MEDIA_FILES_SHARE_SUCCEEDED,
      media_files: result.data.media_files,
      paginate_share: result.data.paginate
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* getMediaFilesMine(action: any): Generator<any, any, any> {
  try {
    const result = yield call(getMediaFilesMineApi, action.token);
    yield put({
      type: GET_MEDIA_FILES_MINE_SUCCEEDED,
      media_files: result.data.media_files,
      paginate_mine: result.data.paginate
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* searchMediaFiles(action: any): Generator<any, any, any> {
  try {
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      imgLoading: true,
      isLoading: true
    });

    const result = yield call(
      searchMediaFilesApi,
      action.token,
      action.view,
      action.params
    );

    yield put({
      type: SEARCH_MEDIA_FILES_SUCCEEDED,
      media_files: result.data.media_files,
      pagination_data: result.data.paginate,
      view: action.view,
      loading: true,
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* updateSelectedMediaFiles(action: any): Generator<any, any, any> {
  try {
    yield call(putMediaFilesApi, {
      token: action.token,
      requestBody: {
        ids: action.payload.fileIds,
        facility_ids: action.payload.selectedFacilityIds,
        class_ids: action.payload.selectedClassIds,
        tag_ids: action.payload.selectedTagIds
      }
    });
    let mediaFilesAdminResult;
    let mediaFilesSharedResult;
    let mediaFilesMineResult;
    mediaFilesMineResult = yield call(getMediaFilesMineApi, action.token);
    mediaFilesSharedResult = yield call(getMediaFilesShareApi, action.token);
    if (action.payload.isAdmin) {
      mediaFilesAdminResult = yield call(getMediaFilesAdminApi, action.token);
      yield all([
        put({ type: UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED }),
        put({
          type: GET_MEDIA_FILES_MINE_SUCCEEDED,
          media_files: mediaFilesMineResult.data.media_files
        }),
        put({
          type: GET_MEDIA_FILES_SHARE_SUCCEEDED,
          media_files: mediaFilesSharedResult.data.media_files
        }),
        put({
          type: GET_MEDIA_FILES_ADMIN_SUCCEEDED,
          media_files: mediaFilesAdminResult.data.media_files
        })
      ]);
    } else {
      yield all([
        put({ type: UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED }),
        put({
          type: GET_MEDIA_FILES_MINE_SUCCEEDED,
          media_files: mediaFilesMineResult.data.media_files
        }),
        put({
          type: GET_MEDIA_FILES_SHARE_SUCCEEDED,
          media_files: mediaFilesSharedResult.data.media_files
        })
      ]);
    }
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* uploadMediaFiles(action: any): Generator<any, any, any> {
  try {
    yield call(postMediaFileUpload, action.token, {
      encoded_file: action.param.encoded_file,
      async: action.param.async
    });
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
    window.confirm("アップロードが完了しました");
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* deleteMediaFile(action: any): Generator<any, any, any> {
  try {
    yield call(deleteMediaFileApi, action.token, action.id);
    yield all([
      put({
        type: TOGGLE_IMG_LOADING_STATE,
        isLoading: false
      }),
      put({
        type: RESET_SELECTED_MEDIA_FILE_IDS
      }),
      put({
        type: GET_MEDIA_FILES_ADMIN_REQUESTED,
        token: action.token
      }),
      put({
        type: GET_MEDIA_FILES_SHARE_REQUESTED,
        token: action.token
      }),
      put({
        type: GET_MEDIA_FILES_MINE_REQUESTED,
        token: action.token
      })
    ]);
  } catch (e) {
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const mediaFilesSaga = [
  takeLatest(GET_MEDIA_FILES_MINE_REQUESTED, getMediaFilesMine),
  takeLatest(GET_MEDIA_FILES_SHARE_REQUESTED, getMediaFilesShare),
  takeLatest(GET_MEDIA_FILES_ADMIN_REQUESTED, getMediaFileAdmin),
  takeLatest(UPDATE_SELECTED_MEDIA_FILES_REQUESTED, updateSelectedMediaFiles),
  takeEvery(UPLOAD_MEDIA_FILE_REQUESTED, uploadMediaFiles),
  takeEvery(DELETE_MEDIA_FILE_REQUESTED, deleteMediaFile),
  takeLatest(SEARCH_MEDIA_FILES_REQUESTED, searchMediaFiles),
];

export default mediaFilesSaga;
