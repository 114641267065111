import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";

interface Props {
  pageTitle: string;
}

const ManualHeader = (props: Props) => {
  return <ManualHeaderSC>{props.pageTitle}</ManualHeaderSC>;
};

export default ManualHeader;

const ManualHeaderSC = styled.h2`
  font-size: 18px;
  padding: 30px 0px;
  margin: 0;
  color: ${color.black};
`;
