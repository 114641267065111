import { AnyAction } from "redux";
import {
  ADD_TO_BE_CREATED_MANUAL_TAG,
  ADD_TO_BE_DELETED_MANUAL_TAG,
  ADD_TO_BE_UPDATED_MANUAL_TAG,
  ALL_MANUAL_TAG_UPDATE_SUCCEEDED,
  CHANGE_NAME_TO_BE_CREATED_MANUAL_TAG,
  CHANGE_NAME_TO_BE_UPDATED_MANUAL_TAG,
  CHANGE_ORDER_TO_BE_CREATED_MANUAL_TAG,
  CHANGE_ORDER_TO_BE_UPDATED_MANUAL_TAG,
  DELETE_TO_BE_CREATED_MANUAL_TAG,
  GET_MANUAL_TAGS_FAILED,
  GET_MANUAL_TAGS_SUCCEEDED,
  UPDATE_VIEW_MANUAL_TAG_NAME,
  UPDATE_VIEW_MANUAL_TAG_ORDER
} from "./types";
import { ManualTagState } from "./types.d";

const initialState: ManualTagState = {
  tags: [],
  tags_to_be_created: [],
  tags_to_be_updated: [],
  tags_to_be_deleted: [],
  tags_to_be_searchd: []
};

const reducer = (state = initialState, action: AnyAction) => {
  const { tags, tags_to_be_created, tags_to_be_updated, tags_to_be_deleted } =
    state;
  switch (action.type) {
    case GET_MANUAL_TAGS_SUCCEEDED:
      return {
        ...state,
        tags: action.result.data.tags
      };

    case GET_MANUAL_TAGS_FAILED:
      return state;

    case ALL_MANUAL_TAG_UPDATE_SUCCEEDED:
      return {
        ...state,
        tags: action.result.data.tags,
        tags_to_be_updated: [],
        tags_to_be_created: [],
        tags_to_be_deleted: []
      };

    // 既存タグの名前編集
    case UPDATE_VIEW_MANUAL_TAG_NAME:
      const updatedNameItemIndex = tags.findIndex(
        target => target.id === Number(action.target_id)
      );
      const updatedNameArray = [...tags];
      updatedNameArray[updatedNameItemIndex].name = action.updated_name;
      return {
        ...state,
        tags: [...updatedNameArray]
      };

    // 既存タグの順番編集
    case UPDATE_VIEW_MANUAL_TAG_ORDER:
      const updatedOrderItemIndex = tags.findIndex(
        target => target.id === Number(action.target_id)
      );
      const updatedOrderArray = [...tags];
      updatedOrderArray[updatedOrderItemIndex].order = action.updated_order;
      return {
        ...state,
        tags: [...updatedOrderArray]
      };

    // 更新タグ追加
    case ADD_TO_BE_UPDATED_MANUAL_TAG: {
      if (tags_to_be_updated.some(target => target.id === action.target_id)) {
        return {
          ...state
        };
      } else {
        const targetTag = tags.find(target => target.id === action.target_id);
        return {
          ...state,
          tags_to_be_updated: [...tags_to_be_updated, targetTag]
        };
      }
    }

    // 新規タグ追加
    case ADD_TO_BE_CREATED_MANUAL_TAG:
      if (tags_to_be_created.length === 0) {
        return {
          ...state,
          tags_to_be_created: [{ temp_id: 0, name: "", order: 0 }]
        };
      } else {
        return {
          ...state,
          tags_to_be_created: [
            ...tags_to_be_created,
            {
              temp_id:
                tags_to_be_created[tags_to_be_created.length - 1].temp_id + 1,
              name: "",
              order: 0
            }
          ]
        };
      }

    // 削除対象タグ追加
    case ADD_TO_BE_DELETED_MANUAL_TAG:
      const filteredTags = tags.filter(tag => tag.id !== action.target_id);
      return {
        ...state,
        tags: filteredTags,
        tags_to_be_deleted: [...tags_to_be_deleted, action.target_id]
      };

    case CHANGE_NAME_TO_BE_UPDATED_MANUAL_TAG:
      const UpdatedNameItemIndex = tags_to_be_updated.findIndex(
        target_item => target_item.id === action.target_id
      );
      const UpdatedNameArray = [...tags_to_be_updated];
      UpdatedNameArray[UpdatedNameItemIndex].name = action.value;
      return {
        ...state,
        tags_to_be_updated: [...UpdatedNameArray]
      };

    case CHANGE_ORDER_TO_BE_UPDATED_MANUAL_TAG:
      const UpdatedOrderItemIndex = tags_to_be_updated.findIndex(
        target_item => target_item.id === action.target_id
      );
      const UpdatedOrderArray = [...tags_to_be_updated];
      UpdatedOrderArray[UpdatedOrderItemIndex].order = action.order;
      return {
        ...state,
        tags_to_be_updated: [...UpdatedOrderArray]
      };

    case CHANGE_NAME_TO_BE_CREATED_MANUAL_TAG:
      const changedNameItemIndex = tags_to_be_created.findIndex(
        target_item => target_item.temp_id === action.target_id
      );
      const changedNameArray = [...tags_to_be_created];
      changedNameArray[changedNameItemIndex].name = action.value;
      return {
        ...state,
        tags_to_be_created: [...changedNameArray]
      };

    case CHANGE_ORDER_TO_BE_CREATED_MANUAL_TAG:
      const changedOrderItemIndex = tags_to_be_created.findIndex(
        target_item => target_item.temp_id === action.target_id
      );
      const changedOrderArray = [...tags_to_be_created];
      changedOrderArray[changedOrderItemIndex].order = action.order;
      return {
        ...state,
        tags_to_be_created: [...changedOrderArray]
      };

    case DELETE_TO_BE_CREATED_MANUAL_TAG:
      const deleted_to_be_created = tags_to_be_created.filter(
        target => target.temp_id !== action.target_id
      );
      return {
        ...state,
        tags_to_be_created: [...deleted_to_be_created]
      };

    default:
      return state;
  }
};

export default reducer;
