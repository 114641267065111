import { GET_EVENT_CSV_SUCCEEDED, GET_EVENT_CSV_FAILED } from "./type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";

const initialState = {
  csv: [],
};
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EVENT_CSV_SUCCEEDED:
      const file_name = action.result_headers["content-disposition"].slice(21);
      const url = window.URL.createObjectURL(new Blob([action.csv]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
      // const downLoadLink = document.createElement('a');
      // let name = 'eventsList.csv';

      // const nameArray = name.split('.');
      // const timestamp = new Date().toLocaleString().replace(/[/ :]/g, '_');
      // nameArray[0] = `${nameArray[0]}_${timestamp}`;
      // name = nameArray.join('.');

      // downLoadLink.download = name;
      // downLoadLink.href = URL.createObjectURL(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), action.csv], { type: 'text/csv' }));
      // downLoadLink.dataset.downloadurl = ['text/csv', downLoadLink.download, downLoadLink.href].join(':');
      // downLoadLink.click();

      return {
        ...state,
        // csv: action.csv
      };

    case GET_EVENT_CSV_FAILED:
      console.log("行事一覧取得エラー message:", action.message);
      return {
        ...state,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
