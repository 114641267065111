import SignIn from "components/auth/SignIn";
import { MainContainerSC, MainSC } from "components/styles/layout/containers";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { setReferer } from "store/global/referer/action";

const LoggedInValidate = (props: { children: ReactNode }) => {
  const { children } = props;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.session.isLoggedIn);
  const sidebarIsOpened = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const storeReferer = useSelector((state: any) => state.referer.referer);
  const refererIsOtherPage = storeReferer !== "/" && storeReferer !== "/signin";
  useEffect(() => {
    dispatch(setReferer(pathname));
  }, [pathname, dispatch]);
  return isLoggedIn ? (
    <>
      {/* SignIn */}
      <Route path="/signin">
        {isLoggedIn ? (
          <Redirect to={refererIsOtherPage ? storeReferer : "/"} />
        ) : (
          <SignIn />
        )}
      </Route>
      {children}
    </>
  ) : (
    <MainSC className={sidebarIsOpened ? "" : "closed"}>
      <MainContainerSC className="signIn">
        <Switch>
          <Redirect
            to={{
              pathname: "/signin",
              state: {
                referer: location.pathname
              }
            }}
          />
        </Switch>
      </MainContainerSC>
    </MainSC>
  );
};

export default LoggedInValidate;
