import React from "react";
import styled from "styled-components";
import { color } from "components/mixins";
import ContentCellNew from "./AnnualEventScheduleContentCellNew";

interface Calendar {
  date: string;
  type: {
    id: number;
    name: string;
  };
  content: string;
  detail: string;
  class: string;
}
interface AnnualEventScheduleCommonEventRowProps {
  dailyEvent: any;
  monthIndex: number;
  dayIndex: number;
}

export const AnnualEventScheduleCommonEventRowNew = (
  props: AnnualEventScheduleCommonEventRowProps
) => {
  const { dailyEvent, monthIndex, dayIndex } = props;
  /** 日付を表示する月を判定 */
  const hasDate =
    monthIndex === 1 ||
    monthIndex === 4 ||
    monthIndex === 7 ||
    monthIndex === 10;

  const holidayCheck = () => {
    if (
      dailyEvent.day_type === "Sun" ||
      dailyEvent.day_type === "Holiday" ||
      dailyEvent.day_type === "Jan3rd"
    ) {
      return "holiday";
    } else if (dailyEvent.day_type === "Sat") {
      return "saturday";
    } else {
      return "";
    }
  };

  return (
    <RowSC className={holidayCheck()}>
      <DayCell hasDate={hasDate}>{hasDate && `${dayIndex}日`}</DayCell>
      <ContentCellNew
        events={dailyEvent.events}
        holiday={dailyEvent.holiday_name}
        hasIcon={dailyEvent.events.some(
          (event: any) => event.usage_guide_flag === true
        )}
      />
    </RowSC>
  );
};

const RowSC = styled.div`
  display: flex;
  height: 31px;
  border-bottom: 1px solid ${color.gray_border};
  &.holiday {
    background-color: ${color.primary_white};
  }
  &.saturday {
    background-color: #fff;
    background-image: radial-gradient(#f2f2f2 30%, transparent 33%),
      radial-gradient(#f2f2f2 30%, transparent 33%);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px;
  }
`;

const DayCell = styled.div<{ hasDate: boolean }>`
  border-right: 1px solid ${color.gray_border};
  width: ${props => (props.hasDate ? `30px` : `10px`)};
  padding-top: 10px;
  background-color: ${color.primary_white};
`;
