import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";
import {
  POST_ARTICLE_REQUESTED,
  POST_ARTICLE_SUCCEEDED,
  POST_ARTICLE_FAILED,
  PUT_NEW_ARTICLE_REQUESTED,
  PUT_NEW_ARTICLE_SUCCEEDED,
  PUT_NEW_ARTICLE_FAILED,
  PUT_EDIT_ARTICLE_REQUESTED,
  PUT_EDIT_ARTICLE_SUCCEEDED,
  PUT_EDIT_ARTICLE_FAILED,
  GET_ARTICLE_REQUESTED,
  GET_ARTICLE_SUCCEEDED,
  GET_ARTICLE_FAILED,
  UPDATE_ARTICLE_REQUESTED,
  UPDATE_ARTICLE_SUCCEEDED,
  UPDATE_ARTICLE_FAILED,
  READ_ARTICLE_REQUESTED,
  READ_ARTICLE_SUCCEEDED,
  READ_ARTICLE_FAILED,
  POST_ARTICLE_IMAGE_REQUESTED,
  POST_ARTICLE_IMAGE_SUCCEEDED,
  POST_ARTICLE_IMAGE_FAILED
} from "../store/faq/article_new/type";

import {
  GET_ARTICLES_REQUESTED,
  GET_ARTICLES_SUCCEEDED,
  GET_ARTICLES_FAILED,
  GET_ARTICLES_FOR_USER_REQUESTED,
  GET_ARTICLES_FOR_USER_SUCCEEDED,
  GET_ARTICLES_FOR_USER_FAILED,
  GET_STOCKED_ARTICLES_REQUESTED,
  GET_STOCKED_ARTICLES_SUCCEEDED,
  GET_STOCKED_ARTICLES_FAILED,
  GET_ARTICLES_PRIORITY_COUNT_REQUESTED,
  GET_ARTICLES_PRIORITY_COUNT_SUCCEEDED,
  GET_ARTICLES_PRIORITY_COUNT_FAILED,
  GET_NEW_ARTICLES_COUNT_REQUESTED,
  GET_NEW_ARTICLES_COUNT_SUCCEEDED,
  GET_NEW_ARTICLES_COUNT_FAILED
} from "../store/faq/articles/type";

import {
  GET_ARTICLES_STOCKS_REQUESTED,
  GET_ARTICLES_STOCKS_SUCCEEDED,
  GET_ARTICLES_STOCKS_FAILED
} from "../store/faq/articles_stocked/type";

import {
  POST_ARTICLE_STOCK_REQUESTED,
  POST_ARTICLE_STOCK_SUCCEEDED,
  POST_ARTICLE_STOCK_FAILED,
  DELETE_ARTICLE_STOCK_REQUESTED,
  DELETE_ARTICLE_STOCK_SUCCEEDED,
  DELETE_ARTICLE_STOCK_FAILED,
  GET_ARTICLE_FAILED_404
} from "../store/faq/article_detail/type";

import {
  SET_CURRENT_PAGE_FAILED,
  SET_CURRENT_PAGE_SUCCEEDED,
  SET_TOTAL,
  SET_STOCKS_TOTAL,
  SET_STOCKS_CURRENT_PAGE_SUCCEEDED,
  SET_SEARCH_STATUS
} from "../store/common/paginate/type";

import { GET_REGISTERED_CATEGORIES_SUCCEEDED } from "../store/faq/categories/type";
import { GET_REGISTERED_TAGS_SUCCEEDED } from "../store/faq/tags/type";

import {
  GET_ARTICLE_EDIT_REQUESTED,
  GET_ARTICLE_EDIT_SUCCEEDED,
  GET_ARTICLE_EDIT_FAILED,
  PUT_ARTICLE_EDIT_REQUESTED,
  PUT_ARTICLE_EDIT_SUCCEEDED,
  PUT_ARTICLE_EDIT_FAILED,
  UPLOAD_ARTICLE_IMAGE_REQUEST,
  UPLOAD_ARTICLE_IMAGE_SUCCEEDED,
  UPLOAD_ARTICLE_IMAGE_FAILED,
  GET_ARTICLE_EDIT_FAILED_404,
  DELETE_ARTICLE_FAILED,
  DELETE_ARTICLE_REQUESTED,
  DELETE_ARTICLE_SUCCEEDED
} from "../store/faq/article_edit/type";

import {
  getArticlesApi,
  getArticlesToShowApi,
  postArticleApi,
  putNewArticleApi,
  putEditArticleApi,
  getArticleApi,
  getArticlesStocksApi,
  readArticleApi,
  postArticleStockApi,
  deleteArticleStockApi,
  uploadArticleImageApi,
  getArticlesApiAfterPut,
  deleteArticleApi
} from "../api/FAQ/articlesApi";

import {
  apiLogin,
  apiLogout,
  apiGetCategories,
  apiGetTags,
  getCategoriesApiAfterPut,
  getTagsApiAfterPut
} from "../api/global/sessionApi";

import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "../store/session/type";
import { responseErrorSwitcherForArticle } from "components/Common/responceErrorSwitcher";

function* getArticles(action: any) {
  console.log("heiheihei");
  try {
    let result;
    if (action.pathName === "/") {
      result = yield call(
        getArticlesToShowApi,
        action.token,
        action.articles_page_num,
        action.articles_count,
        action.categories_to_be_searched,
        action.tags_to_be_searched,
        action.keyword
      );
    } else {
      result = yield call(
        getArticlesApi,
        action.token,
        action.articles_page_num,
        action.articles_count,
        action.categories_to_be_searched,
        action.tags_to_be_searched,
        action.keyword
      );
    }
    // 何かしら検索しているかどうかの判定
    const searchIsNotWork =
      action.categories_to_be_searched.length === 0 &&
      action.tags_to_be_searched.length === 0 &&
      action.keyword === "";

    yield all([
      put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "article"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "article"
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* getArticlesForUser(action: any) {
  try {
    const result = yield call(
      getArticlesToShowApi,
      action.token,
      action.articles_page_num,
      action.articles_count,
      action.categories_to_be_searched,
      action.tags_to_be_searched,
      action.keyword
    );
    const searchIsNotWork =
      action.categories_to_be_searched.length === 0 &&
      action.tags_to_be_searched.length === 0 &&
      action.keyword === "";

    yield all([
      put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "article"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "article"
      }),
      put({
        type: SET_SEARCH_STATUS,
        search_status: false
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postArticle(action: any) {
  try {
    const result = yield call(
      postArticleApi,
      action.token,
      action.title,
      action.content,
      action.search_string,
      action.status,
      action.categories,
      action.tags
    );
    const categories = yield call(apiGetCategories, action.token);
    const tags = yield call(apiGetTags, action.token);
    yield all([
      put({
        type: POST_ARTICLE_SUCCEEDED,
        status: result.data.status,
        id: result.data.id
      }),
      put({
        type: GET_REGISTERED_CATEGORIES_SUCCEEDED,
        categories: categories.data.categories
      }),
      put({ type: GET_REGISTERED_TAGS_SUCCEEDED, tags: tags.data.tags })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getArticle(action: any) {
  try {
    const result = yield call(getArticleApi, action.token, action.id);
    console.log("getArticle result", result);
    console.log("action.stocks in saga", action.stocks);
    yield put({
      type: GET_ARTICLE_SUCCEEDED,
      article: result.data.article,
      stocks: action.stocks
    });
  } catch (e) {
    responseErrorSwitcherForArticle(e);
  }
}

function* deleteArticle(action: any) {
  try {
    const delete_result = yield* deleteArticleApi(action.token, action.id);
    const result = yield* getArticlesApiAfterPut(
      action.token,
      1,
      20,
      [],
      [],
      ""
    );
    yield all([
      put({ type: DELETE_ARTICLE_SUCCEEDED, status: delete_result.status }),
      put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_ARTICLE_FAILED_404 })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_ARTICLE_FAILED_404 })
        ]);
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getArticleEdit(action: any) {
  try {
    const result = yield call(getArticleApi, action.token, action.id);
    yield put({
      type: GET_ARTICLE_EDIT_SUCCEEDED,
      article: result.data.article,
      stocks: action.stocks
    });
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else if (
      e.message === "Request failed with status code 404" ||
      e.message === "Request failed with status code 403"
    ) {
      yield put({
        type: GET_ARTICLE_EDIT_FAILED_404,
        message: e.response.data
      });
    } else {
      yield put({ type: GET_ARTICLE_EDIT_FAILED, message: e.response.data });
    }
  }
}

function* getArticlesStocks(action: any) {
  try {
    const result = yield call(
      getArticlesStocksApi,
      action.token,
      action.user_id,
      action.articles_page_num,
      action.paginate_per_page,
      action.keyword
    );
    console.log("result", result);
    const flat_result = result.data.stocks.flatMap(
      (stock: any) => stock.articles
    );
    console.log("flat_result", flat_result);
    // yield put({ type: GET_ARTICLES_STOCKS_SUCCEEDED, stocks: flat_result });
    yield all([
      put({ type: GET_ARTICLES_STOCKS_SUCCEEDED, stocks: flat_result }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "article_stock"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "article_stock"
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// function* putNewArticleGenerator(new_article_id: number, token: string, title: string, content: string, search_string: string, status: number, categories: Category[], tags: Tag[]): any {
//   try {
//     const result: any = yield call(putNewArticle, new_article_id, token, title, content, search_string, status, categories, tags);
//     return result
//   } catch (e) {
//     console.log('error', e.message);
//   }
// }

function* putNewArticle(action: any) {
  console.log(action.new_article_id);
  console.log(action.token);
  console.log(action.title);
  console.log(action.content);
  console.log(action.search_string);
  console.log(action.status);
  console.log(action.categories);
  console.log(action.tags);
  console.log("putNewArticle is worked");
  try {
    const put_new_result = yield* putNewArticleApi(
      action.new_article_id,
      action.token,
      action.title,
      action.content,
      action.search_string,
      action.status,
      action.categories,
      action.tags
    );
    const result = yield* getArticlesApiAfterPut(
      action.token,
      1,
      20,
      [],
      [],
      ""
    );
    console.log("put_new_result", put_new_result);
    yield all([
      put({
        type: PUT_NEW_ARTICLE_SUCCEEDED,
        status: result.status,
        result: put_new_result
      }),
      put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* putEditArticle(action: any) {
  try {
    console.log("putEditArticle action", putEditArticle);
    const put_edit_result = yield* putEditArticleApi(
      action.edit_article_id,
      action.token,
      action.title,
      action.content,
      action.search_string,
      action.status,
      action.categories,
      action.tags
    );
    const result = yield* getArticlesApiAfterPut(
      action.token,
      1,
      20,
      [],
      [],
      ""
    );
    console.log("result", result);
    yield all([
      put({ type: PUT_ARTICLE_EDIT_SUCCEEDED, status: result.status }),
      put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// 記事既読登録
function* readArticle(action: any) {
  try {
    const result = yield call(readArticleApi, action.token, action.id);
    console.log("result", result);
    yield put({ type: READ_ARTICLE_SUCCEEDED, status: result.status });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// ストック記事一覧取得
function* getStockedArticles(action: any) {
  try {
    const articles = [{}];
    yield put({ type: GET_STOCKED_ARTICLES_SUCCEEDED, articles: articles });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// ストック記事登録
function* postArticleStock(action: any) {
  try {
    const result = yield call(postArticleStockApi, action.token, action.id);
    console.log("result", result);
    yield put({ type: POST_ARTICLE_STOCK_SUCCEEDED, status: result.status });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// ストック記事削除
function* deleteArticleStock(action: any) {
  try {
    const result = yield call(deleteArticleStockApi, action.token, action.id);
    console.log("result", result);
    yield put({ type: DELETE_ARTICLE_STOCK_SUCCEEDED, status: result.status });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postArticleImage(action: any) {
  try {
    const article_image = "";
    yield put({
      type: POST_ARTICLE_IMAGE_SUCCEEDED,
      article_image: article_image
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getArticlesPriorityCount(action: any) {
  try {
    const articles_priority_count = 0;
    yield put({
      type: GET_ARTICLES_PRIORITY_COUNT_SUCCEEDED,
      articles_priority_count: articles_priority_count
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getNewArticlesCount(action: any) {
  try {
    const new_articles_count = 0;
    yield put({
      type: GET_NEW_ARTICLES_COUNT_SUCCEEDED,
      new_articles_count: new_articles_count
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* uploadArticleImage(action: any) {
  console.log("action.data", action.data);
  try {
    const result = yield call(uploadArticleImageApi, action.token, action.data);
    yield put({ type: UPLOAD_ARTICLE_IMAGE_SUCCEEDED, result: result });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const articlesSaga = [
  takeLatest(GET_ARTICLES_REQUESTED, getArticles),
  takeLatest(GET_ARTICLES_FOR_USER_REQUESTED, getArticlesForUser),
  takeLatest(POST_ARTICLE_REQUESTED, postArticle),
  takeEvery(GET_ARTICLE_REQUESTED, getArticle),
  takeLatest(GET_ARTICLES_STOCKS_REQUESTED, getArticlesStocks),
  takeLatest(PUT_NEW_ARTICLE_REQUESTED, putNewArticle),
  takeLatest(READ_ARTICLE_REQUESTED, readArticle),
  takeLatest(GET_STOCKED_ARTICLES_REQUESTED, getStockedArticles),
  takeLatest(DELETE_ARTICLE_STOCK_REQUESTED, deleteArticleStock),
  takeLatest(POST_ARTICLE_IMAGE_REQUESTED, postArticleImage),
  takeLatest(GET_ARTICLES_PRIORITY_COUNT_REQUESTED, getArticlesPriorityCount),
  takeLatest(GET_NEW_ARTICLES_COUNT_REQUESTED, getNewArticlesCount),
  takeLatest(POST_ARTICLE_STOCK_REQUESTED, postArticleStock),
  takeLatest(GET_ARTICLE_EDIT_REQUESTED, getArticleEdit),
  takeLatest(PUT_ARTICLE_EDIT_REQUESTED, putEditArticle),
  takeLatest(GET_ARTICLE_EDIT_REQUESTED, getArticleEdit),
  takeLatest(UPLOAD_ARTICLE_IMAGE_REQUEST, uploadArticleImage),
  takeLatest(DELETE_ARTICLE_REQUESTED, deleteArticle)
];

export default articlesSaga;
