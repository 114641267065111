import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { systemUpRequest } from "store/session/action";

function Maintenance() {
  const position_id = useSelector((state: any) => state.session.position.id);
  const token = useSelector((state: any) => state.session.token);
  const dispatch = useDispatch();
  const handleSystemUp = () => {
    if (window.confirm("メンテナンスモードから復帰しますか？"))
      dispatch(systemUpRequest(token, 1));
  };

  return (
    <Container>
      <h2>メンテナンス中です</h2>
      {position_id === 3 && (
        <SystemUpButton onClick={handleSystemUp}>
          メンテナンスモードから復帰する
        </SystemUpButton>
      )}
    </Container>
  );
}

export default Maintenance;

const Container = styled.div`
  padding: 30px;
`;

const SystemUpButton = styled.button``;
