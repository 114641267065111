import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { historiesSelector } from "store/manual/histories/selectors";
import { manualManualsSelector } from "store/manual/manuals/selectors";
import { manualTagsSelector } from "store/manual/tags/selector";
import {
  getPagesRequest,
  updateAllPagesRequest
} from "store/manual/manuals/pages/actions";
import { getTopicRequest } from "store/manual/manuals/topics/actions";
import { manualPagesSelector } from "store/manual/manuals/pages/selectors";
import {
  manualTopicsSelector,
  manualTopicDetailSelector
} from "store/manual/manuals/topics/selectors";
import { Page } from "store/manual/manuals/pages/types.d";

export const useHooks = (data: { title?: string }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editTitleMode, setEditTitleMode] = useState<boolean>(false);
  const [editOrderMode, setEditOrderMode] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>(data.title || "");

  const [toBeDeletedPages, setToBeDeletedPages] = useState<Page[]>([]);

  const dispatch = useDispatch();
  const tags = useSelector(manualTagsSelector);

  const token = useSelector((state: any) => state.session.token);
  const { manualId, topicId } =
    useParams<{ manualId: string; topicId: string }>();

  // ストア内マニュアル一覧
  const manuals = useSelector(manualManualsSelector);

  // ストア内トピック一覧
  const topics = useSelector(manualTopicsSelector);

  // ストア内トピック詳細
  const stored_topic = useSelector(manualTopicDetailSelector);

  // コンポーネントにわたすトピック詳細
  const empty_topic = {
    id: null,
    type_id: null,
    name: "",
    order: 1,
    updated_at: ""
  };
  const [hookedTopic, setHookedTopic] = useState(
    stored_topic ? stored_topic : empty_topic
  );

  const topicName = hookedTopic.name;

  // ストア内トピックが更新されたらコンポーネントにわたすトピックも更新
  React.useEffect(() => {
    if (stored_topic) setHookedTopic(stored_topic);
  }, [stored_topic]);

  // URLと合致するトピック詳細情報を取得
  React.useEffect(() => {
    // ストア内にURLと合致するコンポーネント側トピック情報があり、かつストア内の設定中トピック詳細idとURLが合致する場合はdispatchしない
    // if (
    //   hookedTopic && // トピック詳細データがストア内に存在する
    //   hookedTopic.id && // トピック詳細データのidがある
    //   topics && //トピック一覧が取得済み
    //   topics.find((topic: any) => topic.id === Number(topicId)) && // トピック一覧内にURLのtopicIdと等しいトピックがある
    //   Number(topicId) === hookedTopic.id // ストア内のトピック詳細データがURLのtopicIdと等しい
    // ) {
    //   console.log("No Need for getTopicRequest");
    //   return;
    // }
    // トピック詳細情報をDBへリクエスト
    dispatch(getTopicRequest({ token, id: Number(topicId) }));
  }, [dispatch, token, topicId]);

  // ストアからページ一覧取得
  const pages = useSelector(manualPagesSelector);

  // コンポーネントに渡すページ一覧
  const [hookedPages, setHookedPages] = useState(pages);
  React.useEffect(() => {
    if (pages) setHookedPages(pages);
  }, [pages]);

  // ページ遷移やURL直たたきの場合のリクエスト
  React.useEffect(() => {
    dispatch(
      getPagesRequest({
        token,
        type_id: Number(manualId),
        topic_id: Number(topicId)
      })
    );
  }, [dispatch, token, manualId, topicId]);

  // 更新履歴
  const histories = useSelector(historiesSelector);

  // +アイコンで末尾にアイテムを追加
  const handleAdd = (hookedPages: Page[]) => {
    setHookedPages([
      ...hookedPages,
      {
        id: null,
        topic_id: Number(manualId),
        order: hookedPages.length + 1,
        title: "",
        title_draft: "",
        content: "",
        content_draft: "",
        status: 1,
        tags: [],
        history_flag: false
      }
    ]);
  };

  // ページの順番を変更
  const changePagesOrder = (sortedPages: Page[]) => {
    const orderUpdatedPages = sortedPages.map((page, index) => {
      return {
        ...page,
        order: index + 1
      };
    });
    setHookedPages(orderUpdatedPages);
  };

  // ページのタイトル編集
  const updatePageTitle = (data: {
    order: number;
    new_title?: string;
    status?: number;
  }) => {
    const updatedHookedPages = hookedPages.map((page: any, index: number) => {
      if (page.order === data.order) {
        // 下書きステータスじゃない場合はtitle
        if (data.status !== 3) {
          // 新規追加されてidがない場合はtitle_draftも
          if (page.id === null) {
            return {
              ...page,
              title: data.new_title,
              title_draft: data.new_title
            };
          } else {
            // すでに登録済み記事の場合はtitleのみ
            return {
              ...page,
              title: data.new_title
            };
          }
        } else {
          // // 下書きステータスの場合はtitle_draft
          return {
            ...page,
            title_draft: data.new_title
          };
        }
      }
      return page;
    });
    console.log("updatedHookedPages", updatedHookedPages);
    setHookedPages(updatedHookedPages);
  };

  // 削除用配列に追加
  const removeHookedItem = (data: { page: Page }) => {
    console.log("hookedManuals before", hookedPages);
    console.log("toBeDeletedPages before", toBeDeletedPages);

    // 追加ボタンを押した後の空アイテムを削除する場合
    if (data.page.id === null) {
      const itemRemovedHookedPages = hookedPages.filter(
        (page: any) => page.order !== data.page.order
      );
      setHookedPages(itemRemovedHookedPages);
    } else {
      // 実データを削除する場合
      const itemRemovedHookedPages = hookedPages.filter(
        (page: any) => page.id !== data.page.id
      );
      setHookedPages(itemRemovedHookedPages);
      setToBeDeletedPages([...toBeDeletedPages, data.page]);
      console.log("hookedPages after", hookedPages);
      console.log("toBeDeletedPages after", toBeDeletedPages);
    }
  };

  // 「完了」ボタンでリクエスト
  const updateAllPagesAction = (data: {
    req_body: Page[];
    type: number | null;
    topic: number | null;
  }) => {
    const sanitized_req_body = data.req_body.filter(page => page.title !== "");
    const reordered_sanitized_req_body = sanitized_req_body.map(
      (page, index) => {
        return {
          ...page,
          order: index + 1
        };
      }
    );
    const req_body = reordered_sanitized_req_body.map(page => {
      return {
        id: page.id,
        topic_id: hookedTopic.id,
        order: page.order,
        title: page.title,
        title_draft: page.title_draft,
        content: page.content,
        content_draft: page.content_draft,
        status: page.status,
        tags: page.tags,
        history_flag: false
      };
    });
    const to_be_deleted_ids =
      toBeDeletedPages && toBeDeletedPages.length > 0
        ? toBeDeletedPages.map(page => page.id)
        : null;
    console.log("toBeDeletedPages", toBeDeletedPages);
    console.log("to_be_deleted_ids", to_be_deleted_ids);
    dispatch(
      updateAllPagesRequest({
        token: token,
        req_body: req_body,
        to_be_deleted_ids: to_be_deleted_ids,
        type_id: data.type,
        topic_id: data.topic
      })
    );
  };

  // 編集中、「キャンセル」押下で編集破棄
  const cancelEdit = () => {
    setHookedPages(pages);
  };

  return {
    token,
    manualId,
    topicId,
    hookedTopic,
    manuals,
    editMode,
    setEditMode,
    topics,
    tags,
    stored_topic,
    pages,
    pageTitle,
    setPageTitle,
    histories,
    handleAdd,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    changePagesOrder,
    updatePageTitle,
    removeHookedItem,
    updateAllPagesAction,
    cancelEdit,
    hookedPages,
    setHookedPages,
    topicName
  };
};
