import { all, put, call, takeLatest, takeEvery } from "redux-saga/effects";
import {
  GET_MONTHLY_EVENTS_LIST_REQUESTED,
  GET_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_MONTHLY_EVENTS_LIST_FAILED,
  DELETE_MONTHLY_EVENTS_REQUESTED,
  DELETE_MONTHLY_EVENTS_SUCCEEDED,
  DELETE_MONTHLY_EVENTS_FAILED,
  POST_MONTHLY_EVENTS_REQUESTED,
  POST_MONTHLY_EVENTS_SUCCEEDED,
  POST_MONTHLY_EVENTS_FAILED,
  PUT_MONTHLY_EVENTS_REQUESTED,
  PUT_MONTHLY_EVENTS_SUCCEEDED,
  PUT_MONTHLY_EVENTS_FAILED,
  GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED,
  GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_ALL_MONTHLY_EVENTS_LIST_FAILED
} from "../store/event/monthly_events/type";

import {
  getMonthlyEventsApi,
  postMonthlyEventsApi,
  putMonthlyEventsApi,
  deleteMonthlyEventsApi,
  getMonthlyEventsAfterSaveApi
} from "../api/event/monthlyEventsApi";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { TOGGLE_ADD_MONTHLY_EVENT_MODAL } from "../store/common/modal/type";

function* getMonthlyEvents(action: any) {
  try {
    const get_result = yield call(
      getMonthlyEventsApi,
      action.token,
      action.facility_id,
      action.year,
      action.months,
      action.classes,
      action.remand
    );
    console.log("get_result in getMonthlyEvents", get_result);
    yield all([
      put({
        type: GET_MONTHLY_EVENTS_LIST_SUCCEEDED,
        monthly_events: get_result.data.events
      }),
      put({
        type: TOGGLE_ADD_MONTHLY_EVENT_MODAL,
        add_monthly_event_modal_open: false
      })
    ]);
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      yield put({
        type: GET_MONTHLY_EVENTS_LIST_FAILED,
        message: e.response.data
      });
    }
  }
}

function* getAllMonthlyEvents(action: any) {
  try {
    const all_monthly_events = yield call(
      getMonthlyEventsApi,
      action.token,
      action.facility_id,
      action.year,
      ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
      [],
      "0"
    );
    yield all([
      put({
        type: GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED,
        all_monthly_events: all_monthly_events.data.events
      })
    ]);
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      yield put({
        type: GET_ALL_MONTHLY_EVENTS_LIST_FAILED,
        message: e.response.data
      });
    }
  }
}

function* postMonthlyEvents(action: any) {
  try {
    const post_result = yield* postMonthlyEventsApi(
      action.token,
      action.facility_id,
      action.year_to_post,
      action.add_monthly_event_month,
      action.add_monthly_event_type_id,
      action.content,
      action.detail,
      action.status,
      action.classes,
      action.kind_to_request
    );
    const monthly_events = yield* getMonthlyEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_months,
      action.filtered_class,
      action.filtered_remand
    );
    yield all([
      put({
        type: POST_MONTHLY_EVENTS_SUCCEEDED,
        status: post_result.data.status,
        id: post_result.data.id,
        monthly_events: monthly_events.data.events
      })
    ]);
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      yield put({ type: POST_MONTHLY_EVENTS_FAILED, message: e.response.data });
    }
  }
}

function* putMonthlyEvents(action: any) {
  try {
    const put_result = yield* putMonthlyEventsApi(
      action.token,
      action.edit_monthly_event_id,
      action.facility_id,
      action.year_to_post,
      action.edit_monthly_event_month,
      action.edit_monthly_event_type_id,
      action.content,
      action.detail,
      action.status,
      action.classes,
      action.kind_to_request
    );
    const monthly_events = yield* getMonthlyEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_months,
      action.filtered_class,
      action.filtered_remand
    );
    yield all([
      put({
        type: PUT_MONTHLY_EVENTS_SUCCEEDED,
        status: put_result.data.status,
        id: put_result.data.id,
        monthly_events: monthly_events.data.events
      })
    ]);
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      yield put({ type: PUT_MONTHLY_EVENTS_FAILED, message: e.response.data });
    }
  }
}

function* deleteMonthlyEvents(action: any) {
  try {
    const delete_result = yield* deleteMonthlyEventsApi(
      action.token,
      action.monthly_event_id
    );
    console.log("delete_result", delete_result);
    yield all([
      put({
        type: DELETE_MONTHLY_EVENTS_SUCCEEDED,
        status: delete_result.status,
        deleted_monthly_event_id: action.monthly_event_id
      })
    ]);
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      yield put({
        type: DELETE_MONTHLY_EVENTS_FAILED,
        message: e.response.data
      });
    }
  }
}

const monthlyEventsSaga = [
  takeLatest(GET_MONTHLY_EVENTS_LIST_REQUESTED, getMonthlyEvents),
  takeLatest(POST_MONTHLY_EVENTS_REQUESTED, postMonthlyEvents),
  takeLatest(PUT_MONTHLY_EVENTS_REQUESTED, putMonthlyEvents),
  takeLatest(DELETE_MONTHLY_EVENTS_REQUESTED, deleteMonthlyEvents),
  takeLatest(GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED, getAllMonthlyEvents)
];

export default monthlyEventsSaga;
