import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "components/mixins";
import { Page } from "store/manual/manuals/pages/types.d";
import { localeTimeForManual } from "components/Common/localeTimeCustomizerForManual";

interface Props {
  manualId: string;
  topicId: string;
  page: Page;
}
function ManualsUneditableItem(props: Props) {
  const { manualId, topicId, page } = props;
  return (
    <li>
      <ManualsItemSC
        to={`/manual/admin/manuals/${manualId}/topics/${topicId}/pages/${page.id}`}
      >
        <ManualsItemContent>
          {
            // 下書きステータス(3)は下書き情報を表示する
            page.status === 3 ? (
              <ManualsItemName>{page.title_draft}</ManualsItemName>
            ) : (
              <ManualsItemName>{page.title}</ManualsItemName>
            )
          }
          <ManualsItemDate>
            最終更新:{localeTimeForManual(page.updated_at)}
          </ManualsItemDate>
        </ManualsItemContent>
      </ManualsItemSC>
    </li>
  );
}

export default ManualsUneditableItem;

const ManualsItemSC = styled(Link)`
  display: block;
  padding: 22px 22px 22px 55px;
  border-bottom: 1px solid ${color.list_border};
  &:hover {
    opacity: 0.6;
  }
`;

const ManualsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ManualsItemName = styled.dt`
  font-size: 16px;
`;

const ManualsItemDate = styled.dd`
  font-size: 12px;
  color: ${color.light_gray};
`;
