import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import StatusListBlock from "./StatusListBlock";
import FacilityHeader from "../FacilityHeader";

function Status() {
  const location = useLocation();
  const pathName = location.pathname;
  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <MainSC>
      <FacilityHeader toggleHidden />
      <ContentTitle>承認状況</ContentTitle>

      <ContentSC>
        <StatusListBlock />
      </ContentSC>
    </MainSC>
  );
}

export default Status;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: block;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
`;

const ContentTitle = styled.div`
  padding: 0 12px;
  font-size: 18px;
  color: ${color.gray};
  font-weight: 600;
  ${pc`
    padding: 0 30px;
  `}
`;

const ContentSC = styled.div`
  padding: 20px 12px 130px;
  display: block;
  ${pc`
    padding: 20px 30px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
  width: 100%;
`;
