import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";
import Select from "react-select";
import { toggleApprovalRequestModal } from "../../../store/common/modal/action";
import { toggleApprovalMonthRequest } from "../../../store/event/events/action";

function ApprovalRequestMonthSelect() {
  const dispatch = useDispatch();
  // const options = useSelector((state: any) => state.approval.monthly_select_options)
  const selected_options = useSelector(
    (state: any) => state.events.selected_options
  );

  const year = useSelector((state: any) => state.event_search.filtered_year);
  const options = [
    { value: `${year}-04`, label: "4月" },
    { value: `${year}-05`, label: "5月" },
    { value: `${year}-06`, label: "6月" },
    { value: `${year}-07`, label: "7月" },
    { value: `${year}-08`, label: "8月" },
    { value: `${year}-09`, label: "9月" },
    { value: `${year}-10`, label: "10月" },
    { value: `${year}-11`, label: "11月" },
    { value: `${year}-12`, label: "12月" },
    { value: `${year + 1}-01`, label: "1月" },
    { value: `${year + 1}-02`, label: "2月" },
    { value: `${year + 1}-03`, label: "3月" }
  ];

  const handleModalOpen = (e: any) => {
    e.preventDefault();
    dispatch(toggleApprovalRequestModal(true));
  };

  //SelectBoxのスタイリング
  // ...providedで既存スタイルを展開して、追加や上書きを行う
  const customStyles = {
    container: () => ({
      width: 330,
      marginRight: 14
    }),
    // 選択リストのスタイリング
    menu: (provided: any) => ({
      ...provided,
      top: "auto",
      maxWidth: 330
    }),
    // 選択リスト内アイテムのスタイリング
    option: (provided: any, { isFocused }: any) => ({
      ...provided,
      backgroundColor: isFocused && "rgba(41, 137, 72, 0.1)",
      cursor: "pointer"
    }),
    //セレクト表示エリアのスタイリング
    control: (provided: any) => ({
      ...provided,
      ":hover": {
        cursor: "pointer"
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "12px"
    })
  };

  return (
    <ApprovalRequestMonthSelectSC>
      <ApprovalForm>
        <ApprovalFormBlock>
          <ApprovalFormLabel>承認依頼</ApprovalFormLabel>
          <ApprovalField>
            <Select
              placeholder="承認依頼する月を選択してください"
              value={selected_options}
              onChange={(new_value: any) => {
                dispatch(toggleApprovalMonthRequest(new_value));
                // const toBeApprovedArray = new_value.map((item: any) => {
                //   return item.value
                // })
                // console.log('toBeApprovedArray', toBeApprovedArray)
                // dispatch(addToBeApproved(toBeApprovedArray))
              }}
              closeMenuOnSelect={false} //一つ選択してもリストが閉じないようにする
              isMulti //複数選択できるようにする
              styles={customStyles} //定義したスタイルを読み込む
              options={options} //セレクトするアイテムを読み込む（配列）
              theme={(theme: any) => ({
                //テーマカラーを上書きする
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: `${color.primary}`,
                  primary: `${color.tag_color}`
                }
              })}
            />
            <MonthSubmit
              type="submit"
              value="選択月の承認を依頼"
              onClick={e => handleModalOpen(e)}
              disabled={selected_options.length === 0}
            />
          </ApprovalField>
        </ApprovalFormBlock>
      </ApprovalForm>
    </ApprovalRequestMonthSelectSC>
  );
}

export default ApprovalRequestMonthSelect;

// ===================================================================================
// スタイル
// ===================================================================================

const ApprovalRequestMonthSelectSC = styled.div`
  margin-bottom: 19px;
  padding: 11px 25px;
  border-radius: 6px;
  background-color: ${color.primary_white};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
`;

const ApprovalForm = styled.form``;

const ApprovalFormBlock = styled.dl`
  display: flex;
  align-items: flex-start;
`;

const ApprovalFormLabel = styled.dt`
  color: ${color.gray};
  width: 68px;
  line-height: 38px;
  margin-right: 16px;
  font-size: 16px;
`;

const ApprovalField = styled.dd`
  width: calc(100% - 68px);
  display: flex;
  align-items: flex-start;
`;

const MonthSubmit = styled.input`
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
  text-align: center;
  max-width: 160px;
  width: 100%;
  height: 38px;
  padding: 12px 10px 10px;
  border-radius: 4px;
  display: block;
  background-color: ${color.gray};
  :disabled {
    opacity: 0.6;
  }
`;
