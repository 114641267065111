import {
  GET_INQUIRIES_CONTACTS_SUCCEEDED,
  GET_INQUIRIES_CONTACTS_FAILED,
  GET_INQUIRIES_KINDS_SUCCEEDED,
  GET_INQUIRIES_KINDS_FAILED,
  GET_INQUIRIES_PRIORITIES_SUCCEEDED,
  GET_INQUIRIES_PRIORITIES_FAILED,
  GET_INQUIRIES_ANSWERS_SUCCEEDED,
  GET_INQUIRIES_ANSWERS_FAILED,
  GET_INQUIRIES_CHECKLIST_SUCCEEDED,
  GET_INQUIRIES_CHECKLIST_FAILED
} from "./type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";

interface CommonData {
  id: number;
  name: string;
}
interface InquiryChoices {
  contacts?: CommonData[];
  kinds1?: CommonData[];
  kinds2?: CommonData[];
  priorities?: CommonData[];
  answers?: CommonData[];
  checklist?: CommonData[];
}

const initialState: InquiryChoices = {
  contacts: [],
  kinds1: [],
  kinds2: [],
  priorities: [],
  answers: [],
  checklist: []
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INQUIRIES_CONTACTS_SUCCEEDED:
      return {
        ...state,
        contacts: action.contacts
      };

    case GET_INQUIRIES_CONTACTS_FAILED:
      return state;

    case GET_INQUIRIES_KINDS_SUCCEEDED:
      return {
        ...state,
        kinds1: action.kinds1,
        kinds2: action.kinds2
      };

    case GET_INQUIRIES_KINDS_FAILED:
      return state;

    case GET_INQUIRIES_PRIORITIES_SUCCEEDED:
      return {
        ...state,
        priorities: action.priorities
      };

    case GET_INQUIRIES_PRIORITIES_FAILED:
      return state;

    case GET_INQUIRIES_ANSWERS_SUCCEEDED:
      return {
        ...state,
        answers: action.answers
      };

    case GET_INQUIRIES_ANSWERS_FAILED:
      return state;

    case GET_INQUIRIES_CHECKLIST_SUCCEEDED:
      return {
        ...state,
        checklist: action.checklist
      };

    case GET_INQUIRIES_CHECKLIST_FAILED:
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
