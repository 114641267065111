import { RootState } from "store/store";

export const manualPagesSelector = (state: RootState) => {
  return state.manuals.pages;
};

export const manualPageDetailSelector = (state: RootState) => {
  return state.manuals.pageDetail;
};

export const manualPageDetailOnEditSelector = (state: RootState) => {
  return state.manuals.pageDetailOnEdit;
};

export const searchTagsSelector = (state: RootState) => {
  return state.manuals.searchTags;
};

export const searchKeywordSelector = (state: RootState) => {
  return state.manuals.searchKeyword;
};
