import {
  SET_INITIAL_EDIT_MONTHLY_EVENT,
  SET_EDIT_MONTHLY_EVENT_TYPE,
  SET_EDIT_MONTHLY_EVENT_CONTENT,
  RESET_EDIT_MONTHLY_EVENT_TYPE,
  RESET_EDIT_MONTHLY_EVENT_CONTENT,
} from "./type";

interface EditEventClass {
  class_age: string;
  checked: boolean;
}

export const setInitialEditMonthlyEvent = (
  edit_monthly_event_id: number,
  year: number,
  month: number,
  type_id: number,
  content: string,
  kind: number,
  remand: object[]
) => {
  return {
    type: SET_INITIAL_EDIT_MONTHLY_EVENT,
    edit_monthly_event_id,
    year,
    month,
    type_id,
    content,
    kind,
    remand,
  };
};

export const setEditMonthlyEventType = (type_id: number) => {
  return {
    type: SET_EDIT_MONTHLY_EVENT_TYPE,
    type_id,
  };
};

export const setEditMonthlyEventContent = (content: string) => {
  return {
    type: SET_EDIT_MONTHLY_EVENT_CONTENT,
    content,
  };
};

export const resetEditMonthlyEventType = () => {
  return {
    type: RESET_EDIT_MONTHLY_EVENT_TYPE,
  };
};

export const resetEditMonthlyEventContent = () => {
  return {
    type: RESET_EDIT_MONTHLY_EVENT_CONTENT,
  };
};
