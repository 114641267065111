import React, { useEffect } from 'react'
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import FacilityItem from './FacilityItem'

export default function FacilityList() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const affiliated_facilities = useSelector((state: any) => state.session.facilities);

  return (
    <FacilityListSC>
      {
        affiliated_facilities && affiliated_facilities.length > 0 &&
        affiliated_facilities.map((facility: any) => (
          <FacilityItem
            key={`facility_${facility.id}`}
            id={facility.id}
            name={facility.name}
          />
        ))
      }
    </FacilityListSC>
  )
};

// ===================================================================================
// スタイル
// ===================================================================================
const FacilityListSC = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
