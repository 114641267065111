import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import pulldown_arrow from "../../../../images/icons/pulldown_arrow.svg";
import close from "../../../../images/icons/close.svg";

import {
  toggleCancelAllEventsModal,
  toggleReturnEventModal
} from "../../../../store/common/modal/action";
import {
  postRejectRequest,
  setRejectComment,
  setRejectCommentForAll,
  cancelAllEventsRequest
} from "../../../../store/event/approval/action";
import { getEventListRequest } from "../../../../store/event/events/action";

export default function ReturnEventsModalContent() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const to_be_rejected = useSelector(
    (state: any) => state.approval.to_be_rejected
  );
  const month_to_be_canceled_all = useSelector(
    (state: any) => state.approval.month_to_be_canceled_all
  );
  const rejection_comment_for_all = useSelector(
    (state: any) => state.approval.rejection_comment_for_all
  );
  const searched_month = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const sorted_month = searched_month.sort(
    (a: string, b: string) => Number(a) - Number(b)
  );
  const facility_id = useSelector((state: any) => state.facility.id);
  const position_id = useSelector((state: any) => state.session.position.id);
  const year = new Date().getFullYear();
  const searched_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );

  const handleCancelAllSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      cancelAllEventsRequest(token, facility_id, month_to_be_canceled_all)
    );
    // to_be_rejected.forEach((item: any) => dispatch(postRejectRequest(token, item.id, item.comment, facility_id, year)));
  };

  return (
    <ReturnModalContentSC>
      <TitleBlock>
        <Title>承認状況一括取り消し</Title>
        <CloseIcon
          src={close}
          alt="閉じるボタン"
          onClick={() => dispatch(toggleCancelAllEventsModal(false))}
        />
      </TitleBlock>
      {
        <ReturnEventsForm>
          <ReturnEventsFormDesc>
            全ての行事予定の承認状況を一括で取り消します。
            <br />
            <span style={{ lineHeight: "21px", fontSize: "12px" }}>
              ※この操作は取り消せません。
            </span>
          </ReturnEventsFormDesc>

          <Buttons>
            <CancelButton
              onClick={() => dispatch(toggleCancelAllEventsModal(false))}
            >
              キャンセル
            </CancelButton>
            <SubmitButton
              type="submit"
              value="一括取り消し"
              onClick={handleCancelAllSubmit}
            />
          </Buttons>
        </ReturnEventsForm>
      }
    </ReturnModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ReturnModalContentSC = styled.div`
  max-width: 780px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const ReturnEventsForm = styled.div`
  padding: 33px 54px 30px;
`;

const ReturnEventsFormDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 50px;
`;

const ReturnEventsFormList = styled.ul`
  margin-bottom: 60px;
`;

const ReturnEventsFormItem = styled.li`
  font-size: 14px;
  line-height: calc(21 / 14);
`;

const ReturnEventsFormBlock = styled.dl`
  width: 271px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
`;

const ReturnEventsFormLabel = styled.dt`
  font-weight: normal;
  text-align: left;
  width: 142px;
  display: block;
  position: relative;
  &::after {
    content: ":";
    display: inline-block;
    position: absolute;
    right: 5px;
  }
`;
const ReturnEventsFormField = styled.dd`
  width: calc(100% - 142px);
`;

const ReturnEventsFormInput = styled.input`
  font-size: 14px;
  width: 100%;
  margin-bottom: 38px;
  padding: 12px;
  border: 1px solid ${color.gray_border};
  border-radius: 4px;
  display: block;
  &::placeholder {
    color: ${color.gray_placeholder};
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  &:disabled {
    opacity: 0.6;
    cursor: none;
  }
`;
