import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_ARTICLE_EDIT_SUCCEEDED,
  GET_ARTICLE_EDIT_FAILED,
  PUT_ARTICLE_EDIT_SUCCEEDED,
  PUT_ARTICLE_EDIT_FAILED,
  INPUT_EDIT_ARTICLE_TITLE,
  TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK,
  TOGGLE_EDIT_ARTICLE_TAG_CHECK,
  SELECT_EDIT_ARTICLE_STATUS,
  SET_RAW_EDIT_CONTENT,
  START_ARTICLE_EDIT,
  SET_ARTICLE_EDITING_STATUS,
  UPLOAD_ARTICLE_IMAGE_SUCCEEDED,
  UPLOAD_ARTICLE_IMAGE_FAILED,
  TOGGLE_EDIT_ARTICLE_POST_STATUS,
  SET_SEARCH_STRING_ARTICLE_EDIT,
  TOGGLE_ARTICLE_EDIT_CONTENT_LOADED,
  GET_ARTICLE_EDIT_FAILED_404,
  TOGGLE_ARTICLE_IS_EDITING,
  DELETE_ARTICLE_SUCCEEDED,
  DELETE_ARTICLE_FAILED,
  DELETE_ARTICLE_FAILED_404,
  RESET_PREVIOUS_PUBLISH_STATUS,
  SAVE_TEMP_EDIT_DATA,
  RESET_TEMP_EDIT_DATA,
  SET_EDITOR_IS_TOGGLED,
  TOGGLE_ARTICLE_TITLE_IS_EDITED,
} from "./type";

interface TempData {
  title: string;
  content: any;
  search_string: string;
  categories: Category[];
  tags: Tag[];
  articleIsEditing: boolean;
  articleTitleIsEdited: boolean;
  title_draft: String;
  draft: string;
}
interface Article {
  id: number | null;
  title: string;
  content: any;
  search_string: string;
  status: number;
  categories: Category[];
  tags: Tag[];
  editing_status: "editing" | "saving" | "completed";
  post_status: boolean;
  title_input_status: boolean;
  contentLoaded: boolean;
  message: string;
  articleIsEditing: boolean;
  articleTitleIsEdited: boolean;
  title_draft: String;
  editorIsToggled: boolean;
  previousStatusIsDraft: boolean | null;
  savedTempData: TempData;
}
interface Category {
  id: number;
  name: string;
}
interface Tag {
  id: number;
  name: string;
}
const initialState: Article = {
  id: null,
  title: "",
  content:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  search_string: "",
  status: 2,
  categories: [],
  tags: [],
  editing_status: "editing",
  post_status: false,
  title_input_status: true,
  contentLoaded: false,
  message: "",
  articleIsEditing: false,
  articleTitleIsEdited: false,
  title_draft: "",
  previousStatusIsDraft: null,
  editorIsToggled: false,
  savedTempData: {
    title: "",
    content:
      '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    search_string: "",
    categories: [],
    tags: [],
    articleIsEditing: false,
    articleTitleIsEdited: false,
    title_draft: "",
    draft:
      '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ARTICLE_EDIT_SUCCEEDED:
      return {
        ...state,
        ...action.article,
        contentLoaded: true,
        message: "",
      };

    case GET_ARTICLE_EDIT_FAILED:
      return {
        ...state,
        message: action.message,
      };

    case PUT_ARTICLE_EDIT_SUCCEEDED:
      console.log("投稿編集登録に成功しました status: ", action.status);
      return {
        ...state,
        post_status: true,
      };

    case DELETE_ARTICLE_SUCCEEDED:
      console.log("記事削除に成功しました status: ", action.status);
      return {
        ...state,
      };

    case DELETE_ARTICLE_FAILED:
      console.log("記事削除に失敗しました status: ", action.message);
      return {
        ...state,
      };

    case DELETE_ARTICLE_FAILED_404:
      return {
        ...state,
        message: action.message,
      };

    case GET_ARTICLE_EDIT_FAILED_404:
      return {
        ...state,
        message: action.message,
        contentLoaded: true,
      };

    case PUT_ARTICLE_EDIT_FAILED:
      console.log("投稿編集登録作成エラー エラーコード:", action.error_code);
      return {
        ...state,
      };

    case INPUT_EDIT_ARTICLE_TITLE:
      if (action.title !== "") {
        return {
          ...state,
          title: action.title,
          title_draft: action.title,
          savedTempData: {
            ...state.savedTempData,
            title: action.title,
            title_draft: action.title,
          },
          title_input_status: true,
        };
      } else {
        return {
          ...state,
          title: action.title,
          savedTempData: {
            ...state.savedTempData,
            title: action.title,
            title_draft: action.title,
          },
          title_input_status: false,
        };
      }

    case TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK:
      if (action.editorIsToggled) {
        // もし一度でもトグルされていたら
        console.log("yoyoyo");
        console.log();
        if (
          state.savedTempData.categories.length > 0 &&
          state.savedTempData.categories.some(
            (category: any) => category.id === action.id
          )
        ) {
          return {
            ...state,
            categories: state.savedTempData.categories.filter(
              (category: any) => category.id !== action.id
            ),
            savedTempData: {
              ...state.savedTempData,
              categories: state.savedTempData.categories.filter(
                (category: any) => category.id !== action.id
              ),
            },
          };
        } else {
          return {
            ...state,
            categories: [...state.savedTempData.categories, action.category],
            savedTempData: {
              ...state.savedTempData,
              categories: [...state.savedTempData.categories, action.category],
            },
          };
        }
      } else {
        if (
          state.categories.length > 0 &&
          state.categories.some((category: any) => category.id === action.id)
        ) {
          return {
            ...state,
            categories: state.categories.filter(
              (category: any) => category.id !== action.id
            ),
          };
        } else {
          return {
            ...state,
            categories: [...state.categories, action.category],
          };
        }
      }

    case TOGGLE_EDIT_ARTICLE_TAG_CHECK:
      if (action.editorIsToggled) {
        console.log("yoyoyo");
        // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
        if (
          state.savedTempData.tags.length > 0 &&
          state.savedTempData.tags.some((tag: any) => tag.id === action.id)
        ) {
          return {
            ...state,
            tags: state.savedTempData.tags.filter(
              (tag: any) => tag.id !== action.id
            ),
            savedTempData: {
              ...state.savedTempData,
              tags: state.savedTempData.tags.filter(
                (tag: any) => tag.id !== action.id
              ),
            },
          };
        } else {
          return {
            ...state,
            tags: [...state.savedTempData.tags, action.tag],
            savedTempData: {
              ...state.savedTempData,
              tags: [...state.savedTempData.tags, action.tag],
            },
          };
        }
      } else {
        // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
        if (
          state.tags.length > 0 &&
          state.tags.some((tag: any) => tag.id === action.id)
        ) {
          return {
            ...state,
            tags: state.tags.filter((tag: any) => tag.id !== action.id),
          };
        } else {
          return {
            ...state,
            tags: [...state.tags, action.tag],
          };
        }
      }

    case SELECT_EDIT_ARTICLE_STATUS:
      return {
        ...state,
        status: action.id,
        previousStatusIsDraft: action.publishedStatus === 3 ? true : false,
      };

    case SET_RAW_EDIT_CONTENT:
      return {
        ...state,
        content: action.content,
        savedTempData: {
          ...state.savedTempData,
          content: action.content,
        },
      };

    case START_ARTICLE_EDIT:
      return {
        ...state,
        id: action.id,
        title: action.title,
        content: action.content,
        categories: action.edit_categories,
        tags: action.edit_tags,
        status: action.status,
        editing_status: action.editing_status,
      };

    case SET_ARTICLE_EDITING_STATUS:
      return {
        ...state,
        editing_status: action.editing_status,
      };

    case UPLOAD_ARTICLE_IMAGE_SUCCEEDED:
      console.log("upload_image_result", action.result);
      return {
        ...state,
        upload_image_result: action.result,
      };

    case UPLOAD_ARTICLE_IMAGE_FAILED:
      console.log("error message for image upload:", action.message);
      return state;

    case TOGGLE_EDIT_ARTICLE_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status,
      };

    case SET_SEARCH_STRING_ARTICLE_EDIT:
      return {
        ...state,
        search_string: action.search_string,
        savedTempData: {
          ...state.savedTempData,
          search_string: action.search_string,
        },
      };

    case TOGGLE_ARTICLE_EDIT_CONTENT_LOADED:
      return {
        ...state,
        contentLoaded: action.contentLoaded,
      };

    case TOGGLE_ARTICLE_IS_EDITING:
      return {
        ...state,
        articleIsEditing: action.articleIsEditing,
      };

    case TOGGLE_ARTICLE_TITLE_IS_EDITED:
      return {
        ...state,
        articleTitleIsEdited: action.articleTitleIsEdited,
        title: action.current_value,
        title_draft: action.current_value,
      };

    case RESET_PREVIOUS_PUBLISH_STATUS:
      return {
        ...state,
        previousStatusIsDraft: null,
      };

    case SAVE_TEMP_EDIT_DATA:
      return {
        ...state,
        savedTempData: {
          title:
            // 直近ステータスが下書きの場合
            action.article_edit.status === 3
              ? action.article_edit.articleTitleIsEdited
                ? //タイトルが編集されている(最新情報がcontent)
                  action.article_edit.title
                : //タイトルが編集されていない(最新情報がdraft)
                  action.article_edit.title_draft
              : action.article_edit.title,

          content:
            // 直近ステータスが下書きの場合
            action.article_edit.status === 3
              ? action.article_edit.articleIsEditing
                ? //本文が編集されている(最新情報がcontent)
                  action.article_edit.content
                : //本文が編集されていない(最新情報がdraft)
                  action.article_edit.draft
              : action.article_edit.content,

          search_string: action.article_edit.search_string,
          categories: action.article_edit.categories,
          tags: action.article_edit.tags,
          articleIsEditing: action.article_edit.articleIsEditing,
          articleTitleIsEdited: action.article_edit.articleTitleIsEdited,
          title_draft: action.article_edit.title_draft,
          draft: action.article_edit.draft,
        },
      };

    case RESET_TEMP_EDIT_DATA:
      return {
        ...state,
        savedTempData: initialState.savedTempData,
      };

    case SET_EDITOR_IS_TOGGLED:
      return {
        ...state,
        editorIsToggled: action.editorIsToggled,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
