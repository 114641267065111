import { pc, minSp } from "components/mixins/mediaQuery";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ModuleArea: React.FC = ({ children }) => {
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  return (
    <ModuleAreaColumn className={rightSectionOpen ? "open" : "close"}>
      {children}
    </ModuleAreaColumn>
  );
};

export default ModuleArea;

const ModuleAreaColumn = styled.div`
  ${pc`
    max-width: 360px;
    order: 2;
    &.close {
      display: none;
    }
  `}
  ${minSp`
    order: 1;
  `}
  display: flex;
  flex-direction: column;
  width: 100%;
  order: 1;
`;
