import React, { ReactNode } from "react";
import { color } from "components/mixins";
import { minSp, pc } from "components/mixins/mediaQuery";
import styled from "styled-components";

interface InquiryFlowProps {
  children: ReactNode;
  isCurrent?: boolean;
  flowName: string;
}

export const InquiryFlow = (props: InquiryFlowProps) => {
  return (
    <>
      {props.isCurrent ? (
        <InquiriesNewStatusCurrent>
          <span>{props.children}</span>
          {props.flowName}
        </InquiriesNewStatusCurrent>
      ) : (
        <InquiriesNewStatusNext>
          <span>{props.children}</span>
          {props.flowName}
        </InquiriesNewStatusNext>
      )}
    </>
  );
};

const InquiriesNewStatusCurrent = styled.p`
  font-size: 14px;
  color: ${color.white};
  font-weight: 600;
  text-align: center;
  max-width: 363px;
  width: calc((100% - 50%) - 18px);
  margin-right: 18px;
  padding: 13px 12px;
  border-radius: 4px;
  background-color: ${color.gray};
  position: relative;
  ${minSp`
    text-align: left;
  `}
  ${pc`
    width: 43%;
    margin-right: 24px;
  `}
  span {
    font-size: 10px;
    color: ${color.gray};
    margin: 0 auto 8px;
    padding: 4px 10px;
    background-color: ${color.white};
    border-radius: 15px;
    display: block;
    ${minSp`
      margin: 0 16px 0 0;
      display: inline;
    `}
  }
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 12px solid ${color.gray};
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
  }
`;

const InquiriesNewStatusNext = styled.p`
  font-size: 14px;
  color: ${color.gray};
  font-weight: 600;
  text-align: center;
  max-width: 363px;
  width: calc(100% - 50%);
  padding: 13px 12px;
  border-radius: 4px;
  background-color: ${color.inquiries_light_gray};
  ${minSp`
    text-align: left;
  `}
  ${pc`
    width: 43%;
  `}
  span {
    font-size: 10px;
    color: ${color.gray};
    margin: 0 auto 8px;
    padding: 4px 10px;
    background-color: ${color.white};
    border-radius: 15px;
    display: block;
    ${minSp`
      margin: 0 16px 0 0;
      display: inline;
    `}
  }
`;
