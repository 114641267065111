import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import { buttonBaseStyle } from "../../mixins/common";
import PageHeading from "../../Common/PageHeading";
import CSVUploadContainer from "./CSVUploadContainer";
import { toggleCsvModal } from "../../../store/common/modal/action";
import EventsPageHeading from "../../Common/EventsPageHeading";
import FacilityHeading from "../../Common/FacilityHeading";
import FacilitySelectNav from "../../Common/FacilitySelectNav/FacilitySelectNav";
import ServiceSelectNav from "../../Common/ServiceSelectNav/ServiceSelectNav";

function CSVUpload() {
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const upload_succeeded = useSelector(
    (state: any) => state.csv.upload_succeeded
  );
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  if (upload_succeeded) {
    history.push("/events/csv/completed");
  }

  return (
    <MainSC>
      <PageHeader>
        <EventsPageHeading title="行事予定" />
        <ServiceSelectNav />
      </PageHeader>

      <FacilityHeader>
        <FacilityHeading />
        <FacilitySelectNav />
      </FacilityHeader>
      <ContentTitle>CSV管理</ContentTitle>

      <ContentSC>
        <CSVUploadContainer setDisableButton={setDisableButton} />
      </ContentSC>

      <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
        <ButtonAreaDeskSC>
          ※ ここからアップロードすると現在のものは全て上書きされます
        </ButtonAreaDeskSC>
        <UploadButtonSC
          className={disableButton ? "" : "fileSelected"}
          onClick={() => dispatch(toggleCsvModal(true))}
          disabled={disableButton}
        >
          アップロード
        </UploadButtonSC>
      </FixedButtonAreaSC>
    </MainSC>
  );
}

export default CSVUpload;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: block;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
`;

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  padding: 10px 30px;
`;

const FacilityHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  // height: 38px;
  // margin: 20px 0 0 0;
  padding: 20px 0 8px;
  margin: 0 30px 30px;
  border-bottom: 1px solid #bbbbbb;
`;

const ContentTitle = styled.div`
  padding: 0 12px;
  font-size: 18px;
  color: ${color.gray};
  font-weight: 600;
  ${pc`
    padding: 0 30px;
  `}
`;

const ContentSC = styled.div`
  padding: 20px 12px 130px;

  display: block;
  ${pc`
    padding: 20px 30px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
  width: 100%;
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const UploadButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  color: ${color.white};
  margin: 0 auto;
  padding: 11px;
  cursor: pointer;
  background-color: ${color.gray};
  ${pc`
    display: inline-block;
    margin: 0;
  `}
  &.fileSelected {
    opacity: 1;
    &:hover {
      opacity: 0.6;
    }
  }
`;
