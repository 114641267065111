import React from "react";
import BlockContainer from "../../Parts/BlockContainer";
import BlockHeader from "../../Parts/BlockHeader";
import { History } from "../../demoData";
import UpdateHistoryList from "./UpdateHistoryList";

interface UpdateHistoryProps {
  data: History[];
  toggleCheckbox: (id: number) => void;
}

const UpdateHistoriesBlock: React.FC<UpdateHistoryProps> = (props) => {
  return (
    <BlockContainer>
      <BlockHeader blockTitle="更新履歴一覧" />
      <UpdateHistoryList {...props} />
    </BlockContainer>
  );
};

export default UpdateHistoriesBlock;
