import { CLEAR_SYSTEM_CACHE } from "store/session/type";
import {
  GET_FACILITY_USERS_FAILED,
  GET_FACILITY_USERS_SUCCEEDED
} from "./type";
export type FacilityUser = {
  id: number;
  name: string;
  picture: string;
  created_at: string;
  updated_at: string;
};

const initialState: FacilityUser[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_FACILITY_USERS_SUCCEEDED:
      return [...action.facility_users];
    case GET_FACILITY_USERS_FAILED:
      return [...state];
    case CLEAR_SYSTEM_CACHE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
