
const GET_MONTHLY_EVENTS_LIST_REQUESTED = "GET_MONTHLY_EVENTS_LIST_REQUESTED"
const GET_MONTHLY_EVENTS_LIST_SUCCEEDED = "GET_MONTHLY_EVENTS_LIST_SUCCEEDED"
const GET_MONTHLY_EVENTS_LIST_FAILED = "GET_MONTHLY_EVENTS_LIST_FAILED"
const DELETE_MONTHLY_EVENTS_REQUESTED = "DELETE_MONTHLY_EVENTS_REQUESTED"
const DELETE_MONTHLY_EVENTS_SUCCEEDED = "DELETE_MONTHLY_EVENTS_SUCCEEDED"
const DELETE_MONTHLY_EVENTS_FAILED = "DELETE_MONTHLY_EVENTS_FAILED"
const POST_MONTHLY_EVENTS_REQUESTED = "POST_MONTHLY_EVENTS_REQUESTED"
const POST_MONTHLY_EVENTS_SUCCEEDED = "POST_MONTHLY_EVENTS_SUCCEEDED"
const POST_MONTHLY_EVENTS_FAILED = "POST_MONTHLY_EVENTS_FAILED"
const PUT_MONTHLY_EVENTS_REQUESTED = "PUT_MONTHLY_EVENTS_REQUESTED"
const PUT_MONTHLY_EVENTS_SUCCEEDED = "PUT_MONTHLY_EVENTS_SUCCEEDED"
const PUT_MONTHLY_EVENTS_FAILED = "PUT_MONTHLY_EVENTS_FAILED"
const GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED = "GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED"
const GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED = "GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED"
const GET_ALL_MONTHLY_EVENTS_LIST_FAILED = "GET_ALL_MONTHLY_EVENTS_LIST_FAILED"


export {
  GET_MONTHLY_EVENTS_LIST_REQUESTED,
  GET_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_MONTHLY_EVENTS_LIST_FAILED,
  DELETE_MONTHLY_EVENTS_REQUESTED,
  DELETE_MONTHLY_EVENTS_SUCCEEDED,
  DELETE_MONTHLY_EVENTS_FAILED,
  POST_MONTHLY_EVENTS_REQUESTED,
  POST_MONTHLY_EVENTS_SUCCEEDED,
  POST_MONTHLY_EVENTS_FAILED,
  PUT_MONTHLY_EVENTS_REQUESTED,
  PUT_MONTHLY_EVENTS_SUCCEEDED,
  PUT_MONTHLY_EVENTS_FAILED,
  GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED,
  GET_ALL_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_ALL_MONTHLY_EVENTS_LIST_FAILED
}