import { ModalDelete } from "components/Camera/components/ModalDelete";
import { PhotoDetail } from "components/Camera/components/PhotoDetail";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { getMediaFileRequested } from "store/camera/mediafile/action";
import { PhotoDetailState } from "store/camera/mediafile/reducer";
import { getCameraClassesRequested } from "store/camera/classes/action";
import { RootState } from "store/store";
import { getCameraTagsRequested } from "store/camera/tags/action";
import { getFacilitiesMeRequested } from "store/camera/facilities_me/action";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";
import { deleteMediaFileApi } from "components/Camera/api/media-files";
import { BackToIndexSC } from "./style";

export const CameraDetail = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toggleVisibility = () => {
    setOpen(state => !state);
  };

  const token = useSelector((state: any) => state.session.token);
  const tags = useSelector((state: RootState) => state.camera_tags.camera_tags);
  const classes = useSelector((state: RootState) => state.classes);
  const facilities = useSelector((state: RootState) => state.facilities_me);
  //詳細
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const photoDetail: PhotoDetailState = useSelector(
    (state: any) => state.mediafile
  );
  const handleDetele = () => {
    dispatch(toggleImgLoadingState(true));
    deleteMediaFileApi(token, Number(id))
      .then(() => {
        dispatch(toggleImgLoadingState(false));
        history.push("/camera");
      })
      .then(() => {
        window.alert("削除に成功しました");
      })
      .catch(e => {
        console.log(e);
      });
  };
  // 詳細情報取得
  useEffect(() => {
    dispatch(toggleImgLoadingState(true));
    dispatch(getMediaFileRequested(token, Number(id)));
  }, [dispatch, token, id]);
  // クラス一覧取得
  useEffect(() => {
    dispatch(getCameraClassesRequested(token));
  }, [dispatch, token]);
  // タグ一覧取得
  useEffect(() => {
    dispatch(getCameraTagsRequested(token));
  }, [dispatch, token]);
  // 所属施設一覧取得
  useEffect(() => {
    dispatch(getFacilitiesMeRequested(token));
  }, [dispatch, token]);

  const handleGoback = () => {
    // history.goBack()
    history.push("/camera");
  };

  return (
    <>
      <div>
        <BackToIndexSC onClick={handleGoback}>
          <span>{"一覧に戻る"}</span>
        </BackToIndexSC>

        <PhotoDetail
          data={photoDetail}
          tags={tags}
          classes={classes}
          facilities={facilities}
          onClickDeleteBtn={toggleVisibility}
        />
      </div>
      <ModalDelete
        open={open}
        toggleVisible={toggleVisibility}
        onAcceptConfirm={handleDetele}
      />
    </>
  );
};
