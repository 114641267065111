// Reduxの値を直接更新
export const SET_PAGE_DETAIL = "SET_PAGE_DETAIL";

// 一括取得
export const GET_PAGES_REQUESTED = "GET_PAGES_REQUESTED";
export const GET_PAGES_SUCCEEDED = "GET_PAGES_SUCCEEDED";
export const GET_PAGES_FAILED = "GET_PAGES_FAILED";

// 一括取得
export const GET_PAGES_SEARCH_REQUESTED = "GET_PAGES_SEARCH_REQUESTED";
export const GET_PAGES_SEARCH_SUCCEEDED = "GET_PAGES_SEARCH_SUCCEEDED";
export const GET_PAGES_SEARCH_FAILED = "GET_PAGES_SEARCH_FAILED";

// 詳細取得
export const GET_PAGE_REQUESTED = "GET_PAGE_REQUESTED";
export const GET_PAGE_SUCCEEDED = "GET_PAGE_SUCCEEDED";
export const GET_PAGE_FAILED = "GET_PAGE_FAILED";

// 一括更新
export const UPDATE_ALL_PAGES_REQUESTED = "UPDATE_ALL_PAGES_REQUESTED";
export const UPDATE_ALL_PAGES_SUCCEEDED = "UPDATE_ALL_PAGES_SUCCEEDED";
export const UPDATE_ALL_PAGES_FAILED = "UPDATE_ALL_PAGES_FAILED";

// 詳細更新
export const UPDATE_PAGE_REQUESTED = "UPDATE_PAGE_REQUESTED";
export const UPDATE_PAGE_SUCCEEDED = "UPDATE_PAGE_SUCCEEDED";
export const UPDATE_PAGE_FAILED = "UPDATE_PAGE_FAILED";

export const SET_PAGE_DETAIL_EDIT_MODE = "SET_PAGE_DETAIL_EDIT_MODE";
export const SET_STORED_PAGE_DETAIL_STATUS = "SET_STORED_PAGE_DETAIL_STATUS";

// 検索
export const SET_SEARCH_TAGS = "SET_SEARCH_TAGS";
export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";

// ページタイトル
export const SET_PAGE_TITLE_ACTION = "SET_PAGE_TITLE_ACTION";

// ページ一覧情報から、ページ詳細にセット
export const SET_PAGE_DETAIL_FROM_PAGES = "SET_PAGE_DETAIL_FROM_PAGES";
