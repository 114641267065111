import axios from "axios";
import { env_inquiry_url, env_url } from "../../serverEnv";

export interface CommonData {
  id: number;
  name: string;
}

export interface contactWithKinds {
  id: number;
  name: string;
  kinds: CommonData[];
}
export interface GetInquiriesContactsResponseType {
  status: number;
  contacts: CommonData[];
}
export interface GetInquiriesKindsResponseType {
  status: number;
  contacts: contactWithKinds[];
}
export interface GetInquiriesPrioritiesResponseType {
  status: number;
  priorities: CommonData[];
}
export interface GetInquiriesAnswersResponseType {
  status: number;
  answers: CommonData[];
}
export interface GetInquiriesChecklistResponseType {
  status: number;
  checklist: CommonData[];
}

// お問合せ情報一覧取得
const getInquiriesApi = (token: string, page: number, limit: number) => {
  return axios.get(`${env_inquiry_url}/inquiries`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      page: page,
      limit: limit
    }
  });
};

// お問い合わせ先取得
export const getInquiryContact = (token: string) => {
  return axios.get<GetInquiriesContactsResponseType>(
    `${env_inquiry_url}/inquiries/contacts`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// お問い合わせ種別取得
export const getInquiryKinds = (token: string) => {
  return axios.get<GetInquiriesKindsResponseType>(
    `${env_inquiry_url}/inquiries/kinds`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// 回答優先度一覧取得
export const getInquiryPriorities = (token: string) => {
  return axios.get<GetInquiriesPrioritiesResponseType>(
    `${env_inquiry_url}/inquiries/priorities`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};
// 回答方法一覧取得
export const getInquiryAnswers = (token: string) => {
  return axios.get<GetInquiriesAnswersResponseType>(
    `${env_inquiry_url}/inquiries/answers`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const getInquiryCheckList = (token: string) => {
  return axios.get<GetInquiriesChecklistResponseType>(
    `${env_inquiry_url}/inquiries/checklist`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export interface PostInquiryManagementBody {
  contact?: number;
  details?: string;
  text1?: string;
  text2?: string;
  text3?: string;
}
export const postInquiryManagementApi = (
  token: string,
  requestBody: PostInquiryManagementBody
) => {
  try {
    console.log("heyhey");
    const result = axios.post(
      `${env_inquiry_url}/inquiries/management`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return result;
  } catch (e) {
    console.log(e);
  }
};
interface PostInquiryBody {
  contact: number;
  kind: number;
  terminal_details?: string;
  terminal?: string;
  model_name?: string;
  proxy_login?: boolean;
  user_id?: number;
  user_name?: string;
  user_email?: string;
  employee_check?: boolean;
  employee_id?: number;
  employee_name?: string;
  employee_email?: string;
  facility?: number;
  priority?: number;
  answer?: number;
}
interface InquiryParams {
  token: string;
  body: PostInquiryBody;
}

export const postInquiry = (params: InquiryParams) => {
  axios.post(`${env_url}/inquiries/management`, params.body, {
    headers: {
      authorization: `Bearer ${params.token}`
    }
  });
};

// お問合せ情報登録
function* postInquiryNewApi(
  token: string,
  inquiry_name: string,
  employee_id: number | null,
  facility: string,
  summary: string,
  terminal: string,
  terminal_details: string,
  child_id: number | null,
  model: string,
  model_name: string,
  proxy_login: boolean | null,
  details: string
) {
  try {
    const result = yield axios.post(
      `${env_url}/inquiries`,
      {
        inquiry_name: inquiry_name,
        employee_id: employee_id,
        facility: facility,
        summary: summary,
        terminal: terminal,
        terminal_details: terminal_details,
        child_id: child_id,
        model: model,
        model_name: model_name,
        proxy_login: proxy_login,
        details: details
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

export { getInquiriesApi, postInquiryNewApi };
