import { put, call, takeLatest } from "redux-saga/effects";
import { getIndexesApi, getIndexApi } from "../api/manual/indexesApi";
import {
  GET_INDEXES_FAILED,
  GET_INDEXES_REQUESTED,
  GET_INDEXES_SUCCEEDED,
  GET_INDEX_FAILED,
  GET_INDEX_REQUESTED,
  GET_INDEX_SUCCEEDED
} from "store/manual/indexes/types";
import { AxiosResponse } from "axios";
import { IndexProps } from "store/manual/indexes/type";

function* getIndexes(action: any) {
  console.log("saga is worked");
  try {
    const result: Promise<AxiosResponse<IndexProps[]>> = yield call(
      getIndexesApi,
      { token: action.token }
    );
    yield put({
      type: GET_INDEXES_SUCCEEDED,
      indexes: result
    });
  } catch (e) {
    console.log(e);
    yield put({ type: GET_INDEXES_FAILED, error: e });
  }
}

function* getIndex(action: any) {
  console.log("saga is worked");
  try {
    const result: Promise<AxiosResponse<IndexProps>> = yield call(getIndexApi, {
      token: action.token,
      type_id: action.type_id
    });
    yield put({
      type: GET_INDEX_SUCCEEDED,
      result: result
    });
  } catch (e) {
    console.log(e);
    yield put({ type: GET_INDEX_FAILED, error: e });
  }
}

const indexesSaga = [
  takeLatest(GET_INDEXES_REQUESTED, getIndexes),
  takeLatest(GET_INDEX_REQUESTED, getIndex)
];

export default indexesSaga;
