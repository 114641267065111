const GET_REGISTERED_CATEGORIES_REQUESTED = "GET_REGISTERED_CATEGORIES_REQUESTED";
const GET_REGISTERED_CATEGORIES_SUCCEEDED = "GET_REGISTERED_CATEGORIES_SUCCEEDED";
const GET_REGISTERED_CATEGORIES_FAILED = "GET_REGISTERED_CATEGORIES_FAILED";
const UPDATE_VIEW_CATEGORY_NAME = "UPDATE_VIEW_CATEGORY_NAME"
const UPDATE_VIEW_CATEGORY_ORDER = "UPDATE_VIEW_CATEGORY_ORDER"
const UPDATE_CATEGORY_ITEM_REQUESTED = "UPDATE_CATEGORY_ITEM_REQUESTED"
const UPDATE_CATEGORY_ITEM_SUCCEEDED = "UPDATE_CATEGORY_ITEM_SUCCEEDED"
const UPDATE_CATEGORY_ITEM_FAILED = "UPDATE_CATEGORY_ITEM_FAILED"
const DELETE_CATEGORY_ITEM_FROM_VIEW = "DELETE_CATEGORY_ITEM_FROM_VIEW"
const DELETE_CATEGORY_ITEM_REQUESTED = "DELETE_CATEGORY_ITEM_REQUESTED"
const DELETE_CATEGORY_ITEM_SUCCEEDED = "DELETE_CATEGORY_ITEM_SUCCEEDED"
const DELETE_CATEGORY_ITEM_FAILED = "DELETE_CATEGORY_ITEM_FAILED"
const POST_NEW_CATEGORY_ITEM_REQUESTED = "POST_CATEGORY_ITEM_REQUESTED"
const POST_NEW_CATEGORY_ITEM_SUCCEEDED = "POST_CATEGORY_ITEM_SUCCEEDED"
const POST_NEW_CATEGORY_ITEM_FAILED = "POST_CATEGORY_ITEM_FAILED"


export {
  GET_REGISTERED_CATEGORIES_REQUESTED, GET_REGISTERED_CATEGORIES_SUCCEEDED, GET_REGISTERED_CATEGORIES_FAILED, DELETE_CATEGORY_ITEM_FROM_VIEW, UPDATE_VIEW_CATEGORY_NAME, UPDATE_VIEW_CATEGORY_ORDER, UPDATE_CATEGORY_ITEM_REQUESTED, UPDATE_CATEGORY_ITEM_SUCCEEDED, UPDATE_CATEGORY_ITEM_FAILED, DELETE_CATEGORY_ITEM_SUCCEEDED, DELETE_CATEGORY_ITEM_FAILED, DELETE_CATEGORY_ITEM_REQUESTED, POST_NEW_CATEGORY_ITEM_REQUESTED, POST_NEW_CATEGORY_ITEM_SUCCEEDED, POST_NEW_CATEGORY_ITEM_FAILED,
}
