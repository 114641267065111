import React from 'react'
import styled from 'styled-components';
import FacilityList from './FacilityList'

export default function FacilityListBlock() {
  return (
    <FacilityListBlockSC>
      <FacilityList />
    </FacilityListBlockSC>
  )
};


// ===================================================================================
// スタイル
// ===================================================================================
const FacilityListBlockSC = styled.div`
  margin: 0 30px;
  padding-top: 78px;
  height: 600px;
  overflow: scroll;
  overflow: scroll;
  ::-webkit-scrollbar {
    display:none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;
