import axios from "axios";
import { env_url, env_event_url } from "../../serverEnv";

const csvUploadApi = (token: string, formData: FormData) => {
  return axios.post(`${env_event_url}/csv`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export { csvUploadApi };
