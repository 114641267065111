import { GET_INQUIRIES_REQUESTED } from "./type";

export const getInquiriesRequest = (token: string, page: number, limit: number) => {
  return {
    type: GET_INQUIRIES_REQUESTED,
    token,
    page,
    limit
  };
};
