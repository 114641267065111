import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_ARTICLE_SUCCEEDED,
  GET_ARTICLE_FAILED,
  READ_ARTICLE_SUCCEEDED,
  READ_ARTICLE_FAILED,
  POST_ARTICLE_STOCK_SUCCEEDED,
  POST_ARTICLE_STOCK_FAILED,
  DELETE_ARTICLE_STOCK_SUCCEEDED,
  DELETE_ARTICLE_STOCK_FAILED,
  SET_ARTICLE_DETAIL_ID,
  RESET_ARTCLE_DETAIL,
  TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED,
  GET_ARTICLE_FAILED_404
} from "./type";

interface Article {
  title: string;
  content: any;
  search_string: string;
  status: number;
  categories: Category[];
  tags: Tag[];
  user_id: number;
  stocked: boolean;
  contentLoaded: boolean;
  message: string;
}
interface Category {
  id: number;
  name: string;
}
interface Tag {
  id: number;
  name: string;
}
const initialState: Article = {
  title: "",
  content:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  search_string: "",
  status: 2,
  categories: [],
  tags: [],
  user_id: 1,
  stocked: false,
  contentLoaded: false,
  message: ""
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ARTICLE_DETAIL_ID:
      return {
        ...state,
        id: action.id
      };
    case GET_ARTICLE_SUCCEEDED:
      console.log("action.stocks", action.stocks);
      const isStocked = action.stocks.some(
        (stocked_article: any) => stocked_article.id === action.article.id
      );
      return {
        ...state,
        ...action.article,
        stocked: isStocked,
        contentLoaded: true,
        message: ""
      };

    case GET_ARTICLE_FAILED:
      return {
        ...state,
        message: action.message
      };

    case GET_ARTICLE_FAILED_404:
      return {
        ...initialState,
        message: action.message,
        contentLoaded: true
      };

    case READ_ARTICLE_SUCCEEDED:
      return {
        ...state
      };

    case READ_ARTICLE_FAILED:
      return {
        ...state,
        message: action.message
      };

    case POST_ARTICLE_STOCK_SUCCEEDED:
      return {
        ...state,
        stocked: true
      };

    case POST_ARTICLE_STOCK_FAILED:
      return {
        ...state,
        message: action.message
      };

    case DELETE_ARTICLE_STOCK_SUCCEEDED:
      return {
        ...state,
        stocked: false
      };

    case DELETE_ARTICLE_STOCK_FAILED:
      return {
        ...state,
        message: action.message
      };

    case RESET_ARTCLE_DETAIL:
      return {
        title: "",
        content:
          '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        search_string: "",
        status: 2,
        categories: [],
        tags: [],
        user_id: 1,
        stocked: false
      };

    case TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED:
      return {
        ...state,
        contentLoaded: action.contentLoaded
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
