import axios from "axios";
import { env_camera_url } from "serverEnv";

export const getFacilityUsersApi = (token: string) => {
  return axios.get(`${env_camera_url}/me/facilities/users`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
