const GET_NEWS_EDIT_REQUESTED = "GET_NEWS_EDIT_REQUESTED";
const GET_NEWS_EDIT_SUCCEEDED = "GET_NEWS_EDIT_SUCCEEDED";
const GET_NEWS_EDIT_FAILED = "GET_NEWS_EDIT_FAILED";

const PUT_NEWS_EDIT_REQUESTED = "PUT_NEWS_EDIT_REQUESTED";
const PUT_NEWS_EDIT_SUCCEEDED = "PUT_NEWS_EDIT_SUCCEEDED";
const PUT_NEWS_EDIT_FAILED = "PUT_NEWS_EDIT_FAILED";

const POST_NEWS_IMAGE_REQUESTED = "POST_NEWS_IMAGE_REQUESTED";
const POST_NEWS_IMAGE_SUCCEEDED = "POST_NEWS_IMAGE_SUCCEEDED";
const POST_NEWS_IMAGE_FAILED = "POST_NEWS_IMAGE_FAILED";

const INPUT_EDIT_NEWS_TITLE = "INPUT_EDIT_NEWS_TITLE";
const SELECT_EDIT_NEWS_STATUS = "SELECT_EDIT_NEWS_STATUS";
const SET_NEWS_RAW_EDIT_CONTENT = "SET_NEWS_RAW_EDIT_CONTENT";

const START_NEWS_EDIT = "START_NEWS_EDIT";
const SET_NEWS_EDITING_STATUS = "SET_NEWS_EDITING_STATUS";

const SET_SEARCH_STRING_NEWS_EDIT = "SET_SEARCH_STRING_NEWS_EDIT";

const TOGGLE_EDIT_NEWS_POST_STATUS = "TOGGLE_EDIT_NEWS_POST_STATUS";

const TOGGLE_NEWS_EDIT_CONTENT_LOADED = "TOGGLE_NEWS_EDIT_CONTENT_LOADED";

const GET_NEWS_EDIT_FAILED_404 = "GET_NEWS_EDIT_FAILED_404"
const DELETE_NEWS_FAILED_404 = "DELETE_NEWS_FAILED_404"


const DELETE_NEWS_REQUESTED = "DELETE_NEWS_REQUESTED"
const DELETE_NEWS_SUCCEEDED = "DELETE_NEWS_SUCCEEDED"
const DELETE_NEWS_FAILED = "DELETE_NEWS_FAILED"

const TOGGLE_NEWS_IS_EDITING = "TOGGLE_NEWS_IS_EDITING";
const TOGGLE_NEWS_TITLE_IS_EDITED = "TOGGLE_NEWS_TITLE_IS_EDITED"

const RESET_PREVIOUS_PUBLISH_STATUS = "RESET_PREVIOUS_PUBLISH_STATUS"

const SAVE_TEMP_NEWS_EDIT_DATA = "SAVE_TEMP_NEWS_EDIT_DATA"
const RESET_TEMP_NEWS_EDIT_DATA = "RESET_TEMP_NEWS_EDIT_DATA"

const SET_NEWS_EDITOR_IS_TOGGLED = "SET_NEWS_EDITOR_IS_TOGGLED"




export {

  GET_NEWS_EDIT_REQUESTED,
  GET_NEWS_EDIT_SUCCEEDED,
  GET_NEWS_EDIT_FAILED,

  PUT_NEWS_EDIT_REQUESTED,
  PUT_NEWS_EDIT_SUCCEEDED,
  PUT_NEWS_EDIT_FAILED,

  POST_NEWS_IMAGE_REQUESTED,
  POST_NEWS_IMAGE_SUCCEEDED,
  POST_NEWS_IMAGE_FAILED,

  DELETE_NEWS_REQUESTED,
  DELETE_NEWS_SUCCEEDED,
  DELETE_NEWS_FAILED,
  DELETE_NEWS_FAILED_404,

  INPUT_EDIT_NEWS_TITLE,

  SELECT_EDIT_NEWS_STATUS,

  SET_NEWS_RAW_EDIT_CONTENT,

  START_NEWS_EDIT,

  SET_NEWS_EDITING_STATUS,

  TOGGLE_EDIT_NEWS_POST_STATUS,

  SET_SEARCH_STRING_NEWS_EDIT,

  TOGGLE_NEWS_EDIT_CONTENT_LOADED,

  GET_NEWS_EDIT_FAILED_404,

  TOGGLE_NEWS_IS_EDITING,
  TOGGLE_NEWS_TITLE_IS_EDITED,

  RESET_PREVIOUS_PUBLISH_STATUS,

  SET_NEWS_EDITOR_IS_TOGGLED,

  SAVE_TEMP_NEWS_EDIT_DATA,

  RESET_TEMP_NEWS_EDIT_DATA
}
