import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";
import TopicsItem from "./TopicsItem";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import { pc } from "components/mixins/mediaQuery";

function TopicsList(props: { topics: TopicItem[] }) {
  const { topics } = props;
  return (
    <TopicsListSC>
      {/* 既存のデータ */}
      {topics &&
        topics.map((value, index) => {
          const { id, type_id, name, updated_at, order } = value;
          return (
            <TopicsItem
              key={id}
              id={id || 1}
              type_id={type_id}
              name={name}
              order={order}
              updated_at={updated_at}
            />
          );
        })}
    </TopicsListSC>
  );
}

export default TopicsList;

const TopicsListSC = styled.ul`
  border-top: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;
