import React from "react";
import styled from "styled-components";
import { color } from "components/mixins";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import editmode from "images/icons/editmode.svg";
import editmodeCancel from "images/icons/editmode_cancel.svg";
interface Props {
  hookedManual: ManualItemForSelector;
  setHookedManual: React.Dispatch<any>;
  editTitleDescMode: boolean;
  setEditTitleDescMode: React.Dispatch<React.SetStateAction<boolean>>;
  cancelEditTitleDesc: () => void;
  completeAction: (data: { hookedManual: ManualItemForSelector }) => void;
}

const TopicsTitleDesc: React.FC<Props> = ({
  hookedManual,
  setHookedManual,
  editTitleDescMode,
  setEditTitleDescMode,
  cancelEditTitleDesc,
  completeAction
}) => {
  const handleEditTitleDescStart = () => {
    setEditTitleDescMode(!editTitleDescMode);
  };

  const handleEditTitleDescCancel = () => {
    setEditTitleDescMode(!editTitleDescMode);
    cancelEditTitleDesc();
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHookedManual({
      ...hookedManual,
      description: e.target.value
    });
    e.stopPropagation();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHookedManual({
      ...hookedManual,
      name: e.target.value
    });
    e.stopPropagation();
  };

  return (
    <div>
      <EditModeBtn
        className={editTitleDescMode ? "" : "cancel"}
        onClick={
          editTitleDescMode
            ? handleEditTitleDescCancel
            : handleEditTitleDescStart
        }
      >
        <EditIcon
          src={editTitleDescMode ? editmodeCancel : editmode}
          alt="editModeimg"
        />
        <LabelSC>{editTitleDescMode ? "キャンセル" : "編集"}</LabelSC>
      </EditModeBtn>

      {editTitleDescMode ? (
        <>
          <TopicBlockHeaderInputSC
            value={hookedManual.name}
            type="text"
            onChange={handleNameChange}
          />
          <ContentInput
            value={hookedManual.description || ""}
            onChange={handleDescChange}
          />
        </>
      ) : (
        <>
          <TopicBlockHeaderSC>{hookedManual.name}</TopicBlockHeaderSC>
          <Content>{hookedManual.description}</Content>
        </>
      )}
    </div>
  );
};

export default TopicsTitleDesc;

const TopicBlockHeaderSC = styled.h2`
  color: ${color.black};
  font-size: 21px;
  margin-bottom: 20px;
  padding-left: 10px;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: ${color.light_gray};
    width: 3px;
    height: 100%;
  }
`;

const TopicBlockHeaderInputSC = styled.input`
  display: block;
  width: 100%;
  color: ${color.black};
  font-size: 21px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  position: relative;
  border: 1px solid ${color.black};
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: ${color.light_gray};
    width: 3px;
    height: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  padding-bottom: 60px;
  white-space: pre-line;
  line-height: 1.6em;
`;

const ContentInput = styled.textarea`
  position: relative;
  display: block;
  width: 100%;
  min-height: 140px;
  padding: 4px 8px;
  border: 1px solid ${color.black};
  line-height: 1.6em;
`;

const EditIcon = styled.img`
  width: 14px;
  display: inline-block;
  position: relative;
  margin-right: 4px;
`;

const LabelSC = styled.span`
  display: inline-block;
`;

const EditModeBtn = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  height: auto;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 30px;
  padding-left: 30px;
  z-index: 1;

  &.cancel {
    &::before {
      background-image: url() (${editmodeCancel});
    }
  }
`;
