import React from "react";
import styled from "styled-components";
import { FlexPageLayout } from "../../Parts/MainLayout";
import ModuleArea from "../../Parts/ModuleArea";
import { useHooks } from "./Hooks";
import { useSelector, useDispatch } from "react-redux";
import { getManualsRequest } from "store/manual/manuals/actions";
import BlockContainer from "components/Manual/Parts/BlockContainer";
import BlockHeaderAdmin from "components/Manual/Parts/BlockHeaderAdmin";
import BottomActionButtonsForManualList from "components/Manual/Parts/BottomActionButtonsForManualList";
import ManualsSortableList from "./ManualsSortableList";
import ManualsUneditableList from "./ManualsUneditableList";
import ManualsEditableList from "./ManualsEditableList";
import { toggleRightSection } from "../../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../../styles/layout/containers";

import UpdateHistoriesModule from "components/Manual/UpdateHistoriesModule";
const Manuals = () => {
  const {
    hookedManuals,
    handleAdd,
    updateAllManualsAction,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    changeManualsOrder,
    updateManualTitle,
    removeHookedItem,
    histories,
    cancelEdit
  } = useHooks({});
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  React.useEffect(() => {
    dispatch(getManualsRequest({ token }));
  }, [dispatch, token]);

  return (
    <FlexPageLayout pageTitle="マニュアル一覧" breadcrambIsInvisible={true}>
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <BlockContainer>
        <BlockHeaderAdmin
          blockTitle="マニュアル一覧"
          editTitleMode={editTitleMode}
          setEditTitleMode={setEditTitleMode}
          editOrderMode={editOrderMode}
          setEditOrderMode={setEditOrderMode}
          cancelEdit={cancelEdit}
        />

        <Content>
          <>
            {/* 最初の表示 */}
            {!editTitleMode && !editOrderMode && (
              <ManualsUneditableList hookedManuals={hookedManuals} />
            )}

            {/* 名称編集中 */}
            {editTitleMode && !editOrderMode && (
              <ManualsEditableList
                hookedManuals={hookedManuals}
                updateManualTitle={updateManualTitle}
                handleAdd={handleAdd}
                removeHookedItem={removeHookedItem}
              />
            )}

            {!editTitleMode && editOrderMode && (
              <ManualsSortableList
                hookedManuals={hookedManuals}
                changeManualsOrder={changeManualsOrder}
              />
            )}

            <BottomActionButtonsForManualList
              sidebar_is_open={true}
              hookedManuals={hookedManuals}
              editTitleMode={editTitleMode}
              setEditTitleMode={setEditTitleMode}
              editOrderMode={editOrderMode}
              setEditOrderMode={setEditOrderMode}
              complete_action={updateAllManualsAction}
            />
          </>
        </Content>
      </BlockContainer>
      <ModuleArea>
        <UpdateHistoriesModule data={histories} />
      </ModuleArea>
    </FlexPageLayout>
  );
};

export default Manuals;

const Content = styled.div`
  position: relative;
  padding-bottom: 60px;
`;
