import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import ApprovalList from "./ApprovalList";
import ApprovalMonthSelect from "./ApprovalMonthSelect";
import { containerBaseStyle } from "../../mixins";
import { setMonthToCancelAll } from "../../../store/event/approval/action";

function ApprovalListBlock() {
  const dispatch = useDispatch();
  const position_id = useSelector((state: any) => state.session.position.id);

  const selected_month = useSelector(
    (state: any) => state.approval.selected_month
  );
  const month_to_be_canceled_all = useSelector(
    (state: any) => state.approval.month_to_be_canceled_all
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const [showMenu, setShowMenu] = useState(false);

  document.onclick = function (event: MouseEvent) {
    const menuList = document.getElementById("eventTopMenuList");
    const menuButton = document.getElementById("eventTopMenuButton");
    if (event.target instanceof HTMLElement) {
      if (
        !menuList?.contains(event.target) &&
        event.target !== menuList &&
        event.target !== menuButton
      ) {
        setShowMenu(false);
      }
    }
  };

  useEffect(() => {
    dispatch(setMonthToCancelAll(null));
  }, []);
  const handleCheckCancelAll = () => {
    dispatch(setMonthToCancelAll(selected_month));
  };

  return (
    <ApprovalListBlockSC className={rightSectionOpen ? "open" : "close"}>
      <ApprovalMonthSelect />
      <ApprovalListBlockHeader>
        <ApprovalListBlockHeaderItem className="month">
          {
            //administrator(3)とプロジェクト管理者(6)で、承認状況ページから月を選択してきた場合のみ一括取り消しチェックボックスを表示する
            (position_id === 3 || position_id === 6) && selected_month !== null && (
              <ApprovalCheckboxLabel>
                <ApprovalCheckbox
                  className="checkbox"
                  type="checkbox"
                  onChange={handleCheckCancelAll}
                  checked={month_to_be_canceled_all !== null}
                />
                <ApprovalCheckboxSpan className="label" />
              </ApprovalCheckboxLabel>
            )
          }
        </ApprovalListBlockHeaderItem>
        <ApprovalListBlockHeaderItem className="date">
          日付
        </ApprovalListBlockHeaderItem>
        <ApprovalListBlockHeaderItem className="title">
          行事名
        </ApprovalListBlockHeaderItem>
        <ApprovalListBlockHeaderItem className="content">
          行事内容
        </ApprovalListBlockHeaderItem>
        <ApprovalListBlockHeaderItem className="detail">
          詳細（目的地）
        </ApprovalListBlockHeaderItem>
        <ApprovalListBlockHeaderItem className="class_age">
          クラス
        </ApprovalListBlockHeaderItem>
      </ApprovalListBlockHeader>
      <ApprovalList />
    </ApprovalListBlockSC>
  );
}

export default ApprovalListBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const ApprovalListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 20px 16px;
  ${pc`
      &.close {
      width: 100%;
      margin-right: 0px;
    }
    width: calc(100% - 380px);
    margin-right: 10px;
    padding: 30px;
  `}
`;

const ApprovalListBlockHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${color.list_header};
  color: ${color.light_gray};
  // padding: 12px 59px;padding
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  border: 1px solid ${color.divide_border};
`;

const ApprovalListBlockHeaderItem = styled.div`
  &.month {
    text-align: left;
    width: 40px;
  }
  &.date {
    width: calc(14%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
`;

const ApprovalCheckboxLabel = styled.label`
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.light_gray};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.gray};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
  &.item {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
`;

const ApprovalCheckboxSpan = styled.span`
  padding-left: 22px;
`;

const ApprovalCheckbox = styled.input`
  display: none;
`;
