import { put, call, takeLatest, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  GetManualTagResponseProps,
  GetManualTagsResponseProps,
  ManualTags,
  ManualTagsToBeCreated
} from "store/manual/tags/types.d";

import {
  getManualTagsApi,
  createManualTagApi,
  updateManualTagApi,
  deleteManualTagApi
} from "api/manual/tagsApi";

import {
  GET_MANUAL_TAGS_SUCCEEDED,
  GET_MANUAL_TAGS_FAILED,
  GET_MANUAL_TAGS_REQUESTED,
  ALL_MANUAL_TAG_UPDATE_REQUESTED,
  ALL_MANUAL_TAG_UPDATE_SUCCEEDED,
  ALL_MANUAL_TAG_UPDATE_FAILED
} from "store/manual/tags/types";

//　マニュアル一覧取得
function* getManualTags(action: any) {
  console.log("heiheihei");
  try {
    const result: Promise<AxiosResponse<GetManualTagResponseProps[]>> =
      yield call(getManualTagsApi, {
        token: action.token
      });
    yield put({
      type: GET_MANUAL_TAGS_SUCCEEDED,
      result: result
    });
  } catch (e) {
    yield put({ type: GET_MANUAL_TAGS_FAILED, error_response: e.response });
  }
}

function* deleteManualTags(action: any) {
  if (action.to_be_deleted_ids.length > 0) {
    yield all(
      action.to_be_deleted_ids.map((id: number) =>
        call(deleteManualTagApi, { token: action.token, id: id })
      )
    );
  }
}

function* createManualTags(action: any) {
  console.log("action.to_be_created_tags", action.to_be_created_tags);
  if (action.to_be_created_tags.length > 0) {
    yield all(
      action.to_be_created_tags.map((val: ManualTagsToBeCreated) => {
        console.log("val", val);
        return call(createManualTagApi, {
          token: action.token,
          req_body: { name: val.name, order: val.order }
        });
      })
    );
  }
}

function* updateManualTags(action: any) {
  if (action.to_be_updated_tags.length > 0) {
    yield all(
      action.to_be_updated_tags.map((val: ManualTags) =>
        call(updateManualTagApi, {
          token: action.token,
          id: val.id,
          req_body: { name: val.name, order: val.order }
        })
      )
    );
  }
}

function* getAfterAllUpdate(action: any) {
  const getAfterAllResult: Promise<
    AxiosResponse<GetManualTagsResponseProps[]>
  > = yield call(getManualTagsApi, { token: action.token });
  return getAfterAllResult;
}

function* clickedUpdateButton(action: any) {
  try {
    yield* updateManualTags(action);
    yield* createManualTags(action);
    yield* deleteManualTags(action);
    const get_after_result = yield* getAfterAllUpdate(action);
    yield put({
      type: ALL_MANUAL_TAG_UPDATE_SUCCEEDED,
      result: get_after_result
    });
  } catch (e) {
    console.log("error", e.response);
    yield put({ type: ALL_MANUAL_TAG_UPDATE_FAILED, error: e.response });
  }
}

const sagas = [
  takeLatest(GET_MANUAL_TAGS_REQUESTED, getManualTags),
  takeLatest(ALL_MANUAL_TAG_UPDATE_REQUESTED, clickedUpdateButton)
];

export default sagas;
