import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GET_MEDIA_FILE_REQUESTED,
  GET_MEDIA_FILE_SUCCEEDED,
  UPDATE_MEDIA_FILE_SUCCEEDED,
  UPDATE_MEDIA_FILE_REQUESTED
} from "store/camera/mediafile/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { getMediaFileDetailApi, putMediaFileApi } from "../api/media-files";
import { TOGGLE_IMG_LOADING_STATE } from "store/camera/mediafiles/type";

function* getCameraDetail(action: any): Generator<any, any, any> {
  try {
    const result = yield call(getMediaFileDetailApi, action.token, action.id);
    yield put({
      type: GET_MEDIA_FILE_SUCCEEDED,
      camera_detail: result.data.media_file
    });
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
  } catch (e) {
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
// メディアファイル情報更新
function* updateMediaFile(action: any): Generator<any, any, any> {
  try {
    yield call(putMediaFileApi, {
      token: action.token,
      id: action.id,
      requestBody: {
        tag_ids: action.payload.tag_ids,
        class_ids: action.payload.class_ids,
        facility_ids: action.payload.facility_ids
      }
    });
    const result = yield call(getMediaFileDetailApi, action.token, action.id);
    yield all([
      put({
        type: UPDATE_MEDIA_FILE_SUCCEEDED,
        camera_detail: result.data.media_file
      }),
      put({
        type: GET_MEDIA_FILE_SUCCEEDED,
        camera_detail: result.data.media_file
      }),
      put({
        type: TOGGLE_IMG_LOADING_STATE,
        isLoading: false
      })
    ]);
  } catch (e) {
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const mediaFilesSaga = [
  takeLatest(GET_MEDIA_FILE_REQUESTED, getCameraDetail),
  takeLatest(UPDATE_MEDIA_FILE_REQUESTED, updateMediaFile)
];

export default mediaFilesSaga;
