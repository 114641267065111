const GET_ARTICLE_EDIT_REQUESTED = "GET_ARTICLE_EDIT_REQUESTED";
const GET_ARTICLE_EDIT_SUCCEEDED = "GET_ARTICLE_EDIT_SUCCEEDED";
const GET_ARTICLE_EDIT_FAILED = "GET_ARTICLE_EDIT_FAILED";

const PUT_ARTICLE_EDIT_REQUESTED = "PUT_ARTICLE_EDIT_REQUESTED";
const PUT_ARTICLE_EDIT_SUCCEEDED = "PUT_ARTICLE_EDIT_SUCCEEDED";
const PUT_ARTICLE_EDIT_FAILED = "PUT_ARTICLE_EDIT_FAILED";

const POST_ARTICLE_IMAGE_REQUESTED = "POST_ARTICLE_IMAGE_REQUESTED";
const POST_ARTICLE_IMAGE_SUCCEEDED = "POST_ARTICLE_IMAGE_SUCCEEDED";
const POST_ARTICLE_IMAGE_FAILED = "POST_ARTICLE_IMAGE_FAILED";

const TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK = "TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK";
const TOGGLE_EDIT_ARTICLE_TAG_CHECK = "TOGGLE_EDIT_ARTICLE_TAG_CHECK";
const INPUT_EDIT_ARTICLE_TITLE = "INPUT_EDIT_ARTICLE_TITLE";
const SELECT_EDIT_ARTICLE_STATUS = "SELECT_EDIT_ARTICLE_STATUS";
const SET_RAW_EDIT_CONTENT = "SET_RAW_EDIT_CONTENT";

const START_ARTICLE_EDIT = "START_ARTICLE_EDIT";
const SET_ARTICLE_EDITING_STATUS = "SET_ARTICLE_EDITING_STATUS";

const UPLOAD_ARTICLE_IMAGE_REQUEST = "UPLOAD_ARTICLE_IMAGE_REQUEST";
const UPLOAD_ARTICLE_IMAGE_SUCCEEDED = "UPLOAD_ARTICLE_IMAGE_SUCCEEDED";
const UPLOAD_ARTICLE_IMAGE_FAILED = "UPLOAD_ARTICLE_IMAGE_FAILED";

const TOGGLE_EDIT_ARTICLE_POST_STATUS = "TOGGLE_EDIT_ARTICLE_POST_STATUS";

const SET_SEARCH_STRING_ARTICLE_EDIT = "SET_SEARCH_STRING_ARTICLE_EDIT";

const TOGGLE_ARTICLE_EDIT_CONTENT_LOADED = "TOGGLE_ARTICLE_EDIT_CONTENT_LOADED";

const GET_ARTICLE_EDIT_FAILED_404 = "GET_ARTICLE_EDIT_FAILED_404"
const DELETE_ARTICLE_FAILED_404 = "DELETE_ARTICLE_FAILED_404"

const DELETE_ARTICLE_REQUESTED = "DELETE_ARTICLE_REQUESTED"
const DELETE_ARTICLE_SUCCEEDED = "DELETE_ARTICLE_SUCCEEDED"
const DELETE_ARTICLE_FAILED = "DELETE_ARTICLE_FAILED"

const TOGGLE_ARTICLE_IS_EDITING = "TOGGLE_ARTICLE_IS_EDITING";
const TOGGLE_ARTICLE_TITLE_IS_EDITED = "TOGGLE_ARTICLE_TITLE_IS_EDITED"

const RESET_PREVIOUS_PUBLISH_STATUS = "RESET_PREVIOUS_PUBLISH_STATUS"

const SAVE_TEMP_EDIT_DATA = "SAVE_TEMP_EDIT_DATA"
const RESET_TEMP_EDIT_DATA = "RESET_TEMP_EDIT_DATA"

const SET_EDITOR_IS_TOGGLED = "SET_EDITOR_IS_TOGGLED"

export {
  GET_ARTICLE_EDIT_REQUESTED,
  GET_ARTICLE_EDIT_SUCCEEDED,
  GET_ARTICLE_EDIT_FAILED,
  PUT_ARTICLE_EDIT_REQUESTED,
  PUT_ARTICLE_EDIT_SUCCEEDED,
  PUT_ARTICLE_EDIT_FAILED,
  POST_ARTICLE_IMAGE_REQUESTED,
  POST_ARTICLE_IMAGE_SUCCEEDED,
  POST_ARTICLE_IMAGE_FAILED,

  DELETE_ARTICLE_REQUESTED,
  DELETE_ARTICLE_SUCCEEDED,
  DELETE_ARTICLE_FAILED,
  DELETE_ARTICLE_FAILED_404,

  TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK,
  TOGGLE_EDIT_ARTICLE_TAG_CHECK,
  INPUT_EDIT_ARTICLE_TITLE,
  SELECT_EDIT_ARTICLE_STATUS,
  SET_RAW_EDIT_CONTENT,
  START_ARTICLE_EDIT,
  SET_ARTICLE_EDITING_STATUS,
  UPLOAD_ARTICLE_IMAGE_REQUEST,
  UPLOAD_ARTICLE_IMAGE_SUCCEEDED,
  UPLOAD_ARTICLE_IMAGE_FAILED,
  TOGGLE_EDIT_ARTICLE_POST_STATUS,
  SET_SEARCH_STRING_ARTICLE_EDIT,
  TOGGLE_ARTICLE_EDIT_CONTENT_LOADED,
  GET_ARTICLE_EDIT_FAILED_404,
  TOGGLE_ARTICLE_IS_EDITING,
  RESET_PREVIOUS_PUBLISH_STATUS,
  SET_EDITOR_IS_TOGGLED,
  SAVE_TEMP_EDIT_DATA,
  RESET_TEMP_EDIT_DATA,
  TOGGLE_ARTICLE_TITLE_IS_EDITED
};
