import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PageHeading from "../../../Common/PageHeading";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import AerticleSearchBlock from "../ArticlesSearchBlockUser";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import {
  getArticleRequest,
  postArticleReadRequest,
} from "../../../../store/faq/article_detail/action";
import { putNewArticleRequest } from "../../../../store/faq/article_new/action";
import tag from "../../../../images/icons/tag.svg";
import inquiry from "../../../../images/icons/mail_gray.svg";
import clock from "../../../../images/icons/clock.svg";
import unstocked from "../../../../images/icons/bookmark_unstocked.svg";
import stocked from "../../../../images/icons/bookmark_stocked.svg";
import WysiwigArticleNewPreview from "./WysiwygArticleNewPreview";
import { history } from "../../../../store/store";

function ArticleNewPreview(props: any) {
  const dispatch = useDispatch();

  const articles = useSelector((state: any) => state.articles);
  const token = useSelector((state: any) => state.session.token);
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const stocks = useSelector((state: any) => state.articles_stocked);
  console.log("stocks", stocks);

  const article = useSelector((state: any) => state.article_new);

  const new_article_id = useSelector(
    (state: any) => state.article_new.new_article_id
  );
  const search_string = "test search string";
  const title = article.title;
  const content = article.content;
  const categories = article.categories;
  const tags = article.tags;
  const status = article.status;
  const post_status = useSelector(
    (state: any) => state.article_new.post_status
  );

  useEffect(() => {
    dispatch(getArticleRequest(token, new_article_id, stocks));
    dispatch(postArticleReadRequest(token, new_article_id));
  }, [dispatch, token, new_article_id]);

  const handleBack = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  const handleRegister = () => {
    // 「登録」ボタンクリック時に実際の新規記事情報を入力
    dispatch(
      putNewArticleRequest(
        new_article_id,
        token,
        title,
        content,
        search_string,
        status,
        categories,
        tags
      )
    );
  };

  return (
    <>
      {post_status ? (
        history.push("/")
      ) : (
        <MainSC>
          <PageHeading title="FAQ記事登録プレビュー" />

          <ArticleDetailBlockSC>
            <DetailHeaderSC>
              <HeaderUpsideSC>
                {/* カテゴリー一覧 */}
                <CategoryListSC>
                  {categories.map((category: any) => {
                    return <CategoryItemSC>{category.name}</CategoryItemSC>;
                  })}
                </CategoryListSC>
              </HeaderUpsideSC>

              {/* タイトル */}
              <ArticleTitleSC>{title}</ArticleTitleSC>

              {/* タグ */}
              <TagListSC>
                {tags.map((tag: any) => {
                  return <TagItemSC>{tag.name}</TagItemSC>;
                })}
              </TagListSC>
            </DetailHeaderSC>

            {/* コンテンツ */}
            <DetailContentsSC>
              {article.content && typeof article.content === "string" && (
                <>
                  <WysiwigArticleNewPreview content={content} />
                </>
              )}
            </DetailContentsSC>
          </ArticleDetailBlockSC>

          <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
            <SubmitButtonSC onClick={handleRegister}>登録</SubmitButtonSC>
            <BackButtonSC onClick={handleBack}>戻る</BackButtonSC>
          </FixedButtonAreaSC>
        </MainSC>
      )}
    </>
  );
}

export default ArticleNewPreview;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
`;

const ArticleDetailBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    margin-bottom: 0;
    padding: 30px;
  `}
`;

const DetailHeaderSC = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  ${pc`
    padding: 30px;
  `}
`;

const HeaderUpsideSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CategoryListSC = styled.ul`
  display: inline-block;
`;

const CategoryItemSC = styled.li`
  display: inline-block;
  color: ${color.white};
  background-color: ${color.category_bg};
  font-size: 12px;
  padding: 4px 11px;
  border-radius: 10px;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 0;
  ${pc`
    margin-bottom: 5px;
  `}
`;

const UpdatedAtSC = styled.p`
  color: ${color.tag_color};
  font-size: 12px;
  text-align: right;
  padding-left: 20px;
  white-space: nowrap;
  display: none;
  ${pc`
    display: block;
  `}
`;
const UpdatedAtSpSC = styled.p`
  color: ${color.tag_color};
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  display: block;
  ${pc`
    display: none;
  `}
`;

const ClockSC = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 6px;
`;

const TagListSC = styled.ul`
  display: flex;
  margin-bottom: 16px;
  ${pc`
    margin-bottom: 0;
  `}
`;

const TagItemSC = styled.li`
  display: inline-block;
  color: ${color.gray};
  font-size: 12px;
  border-radius: 10px;
  margin-right: 8px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background-image: url(${tag});
    background-repeat: no-repeat;
    background-position: left center;
    margin-right: 3px;
  }
`;

const BookmarkButtonSC = styled.div`
  position: absolute;
  top: 25px;
  right: 16px;
  ${pc`
    top: auto;
    bottom: 37px;
    right: 39px;
  `}
`;

const ArticleTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
`;
// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 70px;
  padding: 14px 0 12px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -30px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  ${pc`
    margin-right: 20px;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const LinkToInquirySC = styled.div`
  ${buttonBaseStyle}
  margin-right: 12px;
  color: ${color.primary};
  border: 1px solid ${color.primary};
  background-color: white;
  margin: 0 auto;
  ${pc`
    display: inline-block;
    margin: 0;
  `}
  a {
    display: block;
    padding: 10px 32px;
  }
  img {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: -1px;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  background-color: ${color.primary};
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BackButtonSC = styled(SubmitButtonSC)`
  border: 1px solid ${color.primary};
  color: ${color.primary};
  background-color: ${color.white};
  opacity: 1;
`;
