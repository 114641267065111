import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, inputBaseStyle } from "../../../mixins";
import {
  inputEditNewsTitle,
  selectEditNewsStatus,
  toggleNewsTitleIsEdited
} from "../../../../store/global/news_edit/action";
import {
  toggleNewsEditContentLoaded,
  getNewsEditRequest
} from "../../../../store/global/news_edit/action";

import WysiwygNewsEditor from "./WysiwygNewsEditOriginal";

function NewsEditor() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const edit_title = useSelector((state: any) => state.news_edit.title);
  const edit_title_draft = useSelector(
    (state: any) => state.news_edit.title_draft
  );
  const content = useSelector((state: any) => state.news_edit.content);
  const draft = useSelector((state: any) => state.news_edit.draft);
  const edit_status = useSelector((state: any) => state.news_edit.status);
  const stocks = useSelector((state: any) => state.articles_stocked);
  const message = useSelector((state: any) => state.news_edit.message);
  const publishedStatus = useSelector((state: any) => state.news_detail.status);
  const savedTempContent = useSelector(
    (state: any) => state.news_edit.savedTempData.content
  );
  const savedTempTitle = useSelector(
    (state: any) => state.news_edit.savedTempData.title
  );
  const savedTempIsEditing = useSelector(
    (state: any) => state.news_edit.savedTempData.newsIsEditing
  );
  const editorIsToggled = useSelector(
    (state: any) => state.news_edit.editorIsToggled
  );

  const contentLoaded = useSelector(
    (state: any) => state.news_edit.contentLoaded
  );

  const location = useLocation();
  const pathName = location.pathname;
  const pathInfo = pathName.split("/");
  console.log(pathInfo);
  const fileName = Number(pathInfo.slice(-2, -1));

  // 新規newsのタイトル管理
  const handleEditNewsTitle = (e: any) => {
    dispatch(inputEditNewsTitle(e.target.value));
  };

  // ステータスの判定
  const handleEditArticleStatusChange = (e: any) => {
    dispatch(selectEditNewsStatus(Number(e.target.value), publishedStatus));
  };

  useEffect(() => {
    dispatch(toggleNewsEditContentLoaded(false)); // 記事取得前に初期化
    dispatch(getNewsEditRequest(token, fileName, stocks));
  }, [dispatch, token, fileName]);

  console.log("editorIsToggled", editorIsToggled);
  console.log("savedTempIsEditing", savedTempIsEditing);

  return (
    <NewsEditorSC>
      {message === "" ? (
        <>
          {contentLoaded && (
            <>
              <TitleBlock>
                <TitleLabel>記事タイトル*</TitleLabel>
                <TitleEditorBlock>
                  {editorIsToggled ? (
                    <TitleEditorInput
                      value={savedTempTitle}
                      onChange={handleEditNewsTitle}
                      onFocus={e =>
                        dispatch(toggleNewsTitleIsEdited(true, e.target.value))
                      }
                    />
                  ) : edit_title_draft === "" ||
                    edit_title_draft === undefined ||
                    edit_title_draft === null ? (
                    <TitleEditorInput
                      value={edit_title}
                      onChange={handleEditNewsTitle}
                      onFocus={e =>
                        dispatch(toggleNewsTitleIsEdited(true, e.target.value))
                      }
                    />
                  ) : (
                    <TitleEditorInput
                      value={edit_title_draft}
                      onChange={handleEditNewsTitle}
                      onFocus={e =>
                        dispatch(toggleNewsTitleIsEdited(true, e.target.value))
                      }
                    />
                  )}
                </TitleEditorBlock>
              </TitleBlock>

              <BodyBlock>
                <BodyLabel>記事本文</BodyLabel>
                <BodyEditorBlock>
                  {editorIsToggled ? (
                    <WysiwygNewsEditor content={savedTempContent} />
                  ) : draft === null || draft === undefined ? (
                    contentLoaded &&
                    content &&
                    typeof content === "string" && (
                      <WysiwygNewsEditor content={content} />
                    )
                  ) : (
                    contentLoaded &&
                    draft &&
                    typeof draft === "string" && (
                      <WysiwygNewsEditor content={draft} />
                    )
                  )}
                </BodyEditorBlock>
              </BodyBlock>

              {/* ステータス */}
              <StatusBlock>
                <StatusLabel>ステータス*</StatusLabel>
                <StatusEditorBlock>
                  <StatusEditorInput
                    value={edit_status}
                    className="select"
                    onChange={handleEditArticleStatusChange}
                  >
                    <option value={2}>公開</option>
                    <option value={1}>非公開</option>
                    <option value={3}>下書き</option>
                  </StatusEditorInput>
                </StatusEditorBlock>
              </StatusBlock>
            </>
          )}
        </>
      ) : (
        <>
          {contentLoaded && (
            <>
              <ArticleDetailNotFoundBlock>
                該当のお知らせはありません。
                <NotFoundLinkBlock>
                  <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                </NotFoundLinkBlock>
              </ArticleDetailNotFoundBlock>
            </>
          )}
        </>
      )}
    </NewsEditorSC>
  );
}

export default NewsEditor;
// ===================================================================================
// スタイル
// ===================================================================================
const NewsEditorSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

// Title ============================
const TitleBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
    align-items: center;
  `}
`;

const TitleEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const TitleLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-bottom: 0;
    margin-right: 50px;
  `}
`;

const TitleEditorInput = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;
// ==================================

// Body =============================
const BodyBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
  `}
`;

const BodyEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const BodyLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-bottom: 0;
    margin-right: 50px;
  `}
`;
// ==================================

// Status =================================
const StatusLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-bottom: 0;
    margin-right: 50px;
  `}
`;

const StatusEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const StatusEditorInput = styled.select`
  ${inputBaseStyle}
  width: 240px;
`;

const StatusBlock = styled.div`
  width: 100%;
  ${pc`
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  `}
`;
const ArticleDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;

const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
