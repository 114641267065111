import React from "react";
import styled, { StyledConfig } from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import Select, { StylesConfig } from "react-select";

import { inputBaseStyle } from "../../../mixins";
interface IFacilitySelectBox {
  selectedFacility: string;
  facilities: Facility[];
  onChangeFacility: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface Facility {
  id: number;
  name: string;
  area_id: null | number;
}
export const FacilitySelectBox = (props: IFacilitySelectBox) => {
  const { selectedFacility, facilities, onChangeFacility } = props;
  return (
    <InputBoxSC
      as="select"
      className="select"
      name=""
      id="facility"
      placeholder="選択してください"
      value={selectedFacility}
      onChange={onChangeFacility}
      required
    >
      <option value="">選択してください</option>
      {facilities && (
        <>
          {facilities.map(facility => {
            return <option value={facility.id}>{facility.name}</option>;
          })}
        </>
      )}
    </InputBoxSC>
  );
};

interface IFacilitySelectBoxRS {
  // selectedFacility: string;
  facilities: Facility[];
  onChangeFacility: (value: string) => void;
}
interface Facility {
  id: number;
  name: string;
  area_id: null | number;
}
export const FacilitySelectBoxRS = (props: IFacilitySelectBoxRS) => {
  const { facilities, onChangeFacility } = props;
  const options = facilities.map(facility => {
    return {
      value: facility.id.toString(),
      label: facility.name
    };
  });
  const selectStyle: StylesConfig<{ value: string; label: string }, false> = {
    container: (provided, state) => ({
      ...provided,
      maxWidth: "400px"
    })
  };
  return (
    <Select
      name="facility"
      options={options}
      onChange={option => onChangeFacility(option !== null ? option.value : "")}
      styles={selectStyle}
    />
  );
};

const InputBoxSC = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    width: 400px;
  `}
`;
