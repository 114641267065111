import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../../Common/PageHeading";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import AerticleSearchBlock from "../ArticlesSearchBlockUser";
import ArticleEditPreviewContent from "./ArticleEditPreviewContent";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import {
  getArticleRequest,
  postArticleReadRequest,
} from "../../../../store/faq/article_detail/action";
import {
  postArticleStockRequest,
  deleteArticleStockRequest,
} from "../../../../store/faq/article_detail/action";
import {
  putArticleEditRequest,
  saveTempEditData,
  setEditorIsToggled,
  startArticleEdit,
  toggleArticleEditPostStatus,
} from "../../../../store/faq/article_edit/action";
import tag from "../../../../images/icons/tag.svg";
import inquiry from "../../../../images/icons/mail_gray.svg";
import clock from "../../../../images/icons/clock.svg";
import unstocked from "../../../../images/icons/bookmark_unstocked.svg";
import stocked from "../../../../images/icons/bookmark_stocked.svg";
import WysiwygArticleEditPreview from "./WysiwygArticleEditPreview";
import { history } from "../../../../store/store";

function ArticleEditPreview(props: any) {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const stocks = useSelector((state: any) => state.articles_stocked);

  const current_article_title = useSelector(
    (state: any) => state.article_detail.title
  );
  const current_article_content = useSelector(
    (state: any) => state.article_detail.content
  );
  const current_article_categories = useSelector(
    (state: any) => state.article_detail.categories
  );
  const current_article_tags = useSelector(
    (state: any) => state.article_detail.tags
  );
  const current_article_status = useSelector(
    (state: any) => state.article_detail.status
  );

  const article_edit = useSelector((state: any) => state.article_edit);
  const edit_article_id = useSelector((state: any) => state.article_edit.id);
  const edit_article_title = useSelector(
    (state: any) => state.article_edit.title
  );
  const edit_article_title_draft = useSelector(
    (state: any) => state.article_edit.title_draft
  );
  const edit_article_content = useSelector(
    (state: any) => state.article_edit.content
  );
  const edit_article_draft = useSelector(
    (state: any) => state.article_edit.draft
  );
  const edit_article_categories = useSelector(
    (state: any) => state.article_edit.categories
  );
  const edit_article_search_string = "test search string";
  const edit_article_tags = useSelector(
    (state: any) => state.article_edit.tags
  );
  const edit_article_status = useSelector(
    (state: any) => state.article_edit.status
  );
  const previousStatusIsDraft = useSelector(
    (state: any) => state.article_edit.previousStatusIsDraft
  );

  const message = useSelector((state: any) => state.article_edit.message);

  const post_status = useSelector(
    (state: any) => state.article_edit.post_status
  );

  const article_is_editing = useSelector(
    (state: any) => state.article_edit.articleIsEditing
  );
  const article_title_is_edited = useSelector(
    (state: any) => state.article_edit.articleTitleIsEdited
  );

  const editorIsToggled = useSelector(
    (state: any) => state.article_edit.editorIsToggled
  );

  const savedTempData = useSelector(
    (state: any) => state.article_edit.savedTempData
  );
  const savedTempContent = useSelector(
    (state: any) => state.article_edit.savedTempData.content
  );
  const savedTempTitle = useSelector(
    (state: any) => state.article_edit.savedTempData.title
  );
  const savedTempTitleDraft = useSelector(
    (state: any) => state.article_edit.savedTempData.title_draft
  );
  const savedTempSearchString = useSelector(
    (state: any) => state.article_edit.savedTempData.search_string
  );
  const savedTempDraft = useSelector(
    (state: any) => state.article_edit.savedTempData.draft
  );
  const savedTempCategories = useSelector(
    (state: any) => state.article_edit.savedTempData.categories
  );
  const savedTempTags = useSelector(
    (state: any) => state.article_edit.savedTempData.tags
  );
  const savedTempIsEditing = useSelector(
    (state: any) => state.article_edit.savedTempData.articleIsEditing
  );
  const savedTempTitleIsEditing = useSelector(
    (state: any) => state.article_edit.savedTempData.articleTitleIsEditing
  );

  let title: string;
  if (edit_article_title_draft !== "") {
    title = edit_article_title_draft;
  } else {
    title = edit_article_title;
  }

  useEffect(() => {
    dispatch(getArticleRequest(token, edit_article_id, stocks));
    dispatch(postArticleReadRequest(token, edit_article_id));
    if (!editorIsToggled) {
      //初回
      dispatch(saveTempEditData(article_edit, edit_article_status));
    } else {
      //二度目以降
      dispatch(saveTempEditData(savedTempData, edit_article_status));
    }
    dispatch(setEditorIsToggled(true));
  }, [dispatch, token, edit_article_id]);

  const handleBack = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  const handleRegister = (status_id: string) => {
    const selected_status = Number(status_id);
    if (editorIsToggled) {
      //一度でもセーブした場合
      if (savedTempIsEditing) {
        !savedTempTitleIsEditing && current_article_status === 3
          ? //本文は編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合（最新のタイトルの状態がtitle_draft）
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitleDraft,
                savedTempContent,
                savedTempSearchString,
                selected_status,
                savedTempCategories,
                savedTempTags
              )
            )
          : //本文・タイトルともに編集済みの場合(最新情報が全て更新されている場合)
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitle,
                savedTempContent,
                savedTempSearchString,
                selected_status,
                savedTempCategories,
                savedTempTags
              )
            );
      } else {
        !savedTempTitleIsEditing && current_article_status === 3
          ? //本文・タイトルともに未編集で、直近のステータスが下書きだった場合（最新の状態がdraftとtitle_draft）
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitleDraft,
                savedTempDraft,
                savedTempSearchString,
                selected_status,
                savedTempCategories,
                savedTempTags
              )
            )
          : //本文は未編集だが、タイトルは編集している場合（最新の状態がdraftとtitle）
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitle,
                savedTempDraft,
                savedTempSearchString,
                selected_status,
                savedTempCategories,
                savedTempTags
              )
            );
      }
    } else {
      //一度もセーブしてない場合
      if (article_is_editing) {
        !article_title_is_edited && current_article_status === 3
          ? // 本文編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合 (本文最新: content, タイトル最新: title_draft)
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title_draft,
                edit_article_content,
                edit_article_search_string,
                selected_status,
                edit_article_categories,
                edit_article_tags
              )
            )
          : // 本文・タイトルを編集している場合 ( 本文最新: content, タイトル最新: title )
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title,
                edit_article_content,
                edit_article_search_string,
                selected_status,
                edit_article_categories,
                edit_article_tags
              )
            );
      } else {
        !article_title_is_edited && current_article_status === 3
          ? // 本文、タイトルともに未編集で、直近のステータスが下書きだった場合 ( 本文最新: draft, タイトル最新: title_draft )
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title_draft,
                edit_article_draft,
                edit_article_search_string,
                selected_status,
                edit_article_categories,
                edit_article_tags
              )
            )
          : // 本文は未編集で、タイトルは編集している場合 ( 本文最新: draft, タイトル最新: title )
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title,
                edit_article_draft,
                edit_article_search_string,
                selected_status,
                edit_article_categories,
                edit_article_tags
              )
            );
      }
    }
  };

  // const handleRegister = () => {
  //   if(editorIsToggled){
  //     dispatch(putArticleEditRequest(edit_article_id, token, savedTempTitle, savedTempContent, savedTempSearchString, 2, savedTempCategories, savedTempTags))
  //   }else{
  //     // 「登録」ボタンクリック時に実際の新規記事情報を入力
  //     if(article_is_editing) {
  //       // 「非公開記事として保存」ボタンクリック時に実際の新規記事情報を入力
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, 2, edit_article_categories, edit_article_tags));
  //     } else {
  //       // もとの記事が下書き状態で編集しないまま登録した場合、draftを送信する。
  //       current_article_status === 3 ?
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_draft, edit_article_search_string, 2, edit_article_categories, edit_article_tags))
  //       :
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, 2, edit_article_categories, edit_article_tags));
  //     }
  //   }
  // };

  // const handleRegisterHidden = () => {
  //   if(editorIsToggled){
  //     dispatch(putArticleEditRequest(edit_article_id, token, savedTempTitle, savedTempContent, savedTempSearchString, 1, savedTempCategories, savedTempTags))
  //   }else{
  //     if(article_is_editing) {
  //       // 「非公開記事として保存」ボタンクリック時に実際の新規記事情報を入力
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, 1, edit_article_categories, edit_article_tags));
  //     } else {
  //       // もとの記事が下書き状態で編集しないまま登録した場合、draftを送信する。
  //       current_article_status === 3 ?
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_draft, edit_article_search_string, 1, edit_article_categories, edit_article_tags))
  //       :
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, 1, edit_article_categories, edit_article_tags));
  //     }
  //   }
  // };

  // const handleSaveAsDraft = () => {
  //   if(editorIsToggled){
  //     dispatch(putArticleEditRequest(edit_article_id, token, savedTempTitle, savedTempContent, savedTempSearchString, 3, savedTempCategories, savedTempTags))
  //   }else{
  //     if(article_is_editing) {
  //       // 「非公開記事として保存」ボタンクリック時に実際の新規記事情報を入力
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, 3, edit_article_categories, edit_article_tags));
  //     } else {
  //       // もとの記事が下書き状態で編集しないまま登録した場合、draftを送信する。
  //       current_article_status === 3 ?
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_draft, edit_article_search_string, 3, edit_article_categories, edit_article_tags))
  //       :
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, 3, edit_article_categories, edit_article_tags));
  //     }
  //   }
  // };

  return (
    <>
      {post_status ? (
        history.push("/")
      ) : (
        <MainSC>
          <PageHeading title="FAQ記事編集プレビュー" />
          <ArticleDetailWrapperBlockSC>
            <ArticleDetailOuterBlockSC>
              <ArticleDetailInnerTitle>
                現在公開中の記事
              </ArticleDetailInnerTitle>
              <ArticleDetailInnerBlockSC className="first">
                <ArticleDetailBlockSC>
                  {message === "" ? (
                    <ArticleEditPreviewContent
                      categories={current_article_categories}
                      title={current_article_title}
                      tags={current_article_tags}
                      content={current_article_content}
                    />
                  ) : (
                    <ArticleDetailNotFoundBlock>
                      該当の記事はありません
                      <NotFoundLinkBlock>
                        <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                      </NotFoundLinkBlock>
                    </ArticleDetailNotFoundBlock>
                  )}
                </ArticleDetailBlockSC>
              </ArticleDetailInnerBlockSC>
            </ArticleDetailOuterBlockSC>
            <ArticleDetailOuterBlockSC>
              <ArticleDetailInnerTitle>
                現在下書き中の記事
              </ArticleDetailInnerTitle>
              <ArticleDetailInnerBlockSC>
                <ArticleDetailBlockSC>
                  {message === "" ? (
                    <>
                      {editorIsToggled ? (
                        <ArticleEditPreviewContent
                          categories={savedTempCategories}
                          title={savedTempTitle}
                          tags={savedTempTags}
                          content={savedTempContent}
                        />
                      ) : article_is_editing ? (
                        <ArticleEditPreviewContent
                          categories={current_article_categories}
                          title={title}
                          tags={current_article_tags}
                          content={edit_article_content}
                        />
                      ) : (
                        <ArticleEditPreviewContent
                          categories={current_article_categories}
                          title={title}
                          tags={current_article_tags}
                          content={edit_article_draft}
                        />
                      )}
                    </>
                  ) : (
                    <ArticleDetailNotFoundBlock>
                      該当の記事はありません
                      <NotFoundLinkBlock>
                        <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                      </NotFoundLinkBlock>
                    </ArticleDetailNotFoundBlock>
                  )}
                </ArticleDetailBlockSC>
              </ArticleDetailInnerBlockSC>
            </ArticleDetailOuterBlockSC>
          </ArticleDetailWrapperBlockSC>

          <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
            <SubmitButtonSC
              id="2"
              onClick={(e) => handleRegister(e.currentTarget.id)}
            >
              記事として公開
            </SubmitButtonSC>
            <SubmitButtonSC
              id="1"
              onClick={(e) => handleRegister(e.currentTarget.id)}
            >
              非公開記事として保存
            </SubmitButtonSC>
            <SubmitButtonSC
              id="3"
              onClick={(e) => handleRegister(e.currentTarget.id)}
            >
              下書きとして保存
            </SubmitButtonSC>
            <BackButtonSC to={`/articles/${edit_article_id}/edit`}>
              戻る
            </BackButtonSC>
          </FixedButtonAreaSC>
        </MainSC>
      )}
    </>
  );
}

export default ArticleEditPreview;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const ArticleDetailWrapperBlockSC = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
`;

const ArticleDetailOuterBlockSC = styled.div`
  width: 100%;

  ${pc`
    width: 50%;
    max-width: 810px;
  `}
`;

const ArticleDetailInnerBlockSC = styled.div`
  &.first {
    border-right: 1px solid ${color.divide_border};
    padding-right: 2%;
    margin-right: 2%;
  }
`;

const ArticleDetailInnerTitle = styled.p`
  color: ${color.gray};
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 16px;
  `}
`;

const ArticleDetailBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  ${pc`
    max-width: 810px;
    // width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    margin-bottom: 0;
    padding: 30px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 70px;
  padding: 14px 0 12px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -30px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  background-color: ${color.primary};
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BackButtonSC = styled(Link)`
  ${buttonBaseStyle}
  margin-right: 12px;
  border: 1px solid ${color.primary};
  color: ${color.primary};
  background-color: ${color.white};
  opacity: 1;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const ArticleDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;
const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
