import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import PageHeading from "../../../Common/PageHeading";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import trash from "../../../../images/icons/trash.svg";
import {
  putNewsEditRequest,
  toggleNewsEditPostStatus,
  inputEditNewsTitle,
  resetPreviousPublishStatus,
  setNewsEditorIsToggled,
  saveTempNewsEditData
} from "../../../../store/global/news_edit/action";
import { getNewsDetailRequest } from "../../../../store/global/news_detail/action";

import NewsEditor from "./NewsEditor";
import { toggleDeleteNewsModal } from "../../../../store/common/modal/action";
import NewsCurrentView from "./NewsCurrentView";

function NewsDetailEdit(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const token = useSelector((state: any) => state.session.token);
  const title = useSelector((state: any) => state.news_edit.title);

  const news_edit = useSelector((state: any) => state.news_edit);
  const post_status = useSelector((state: any) => state.news_edit.post_status);
  const search_string = useSelector(
    (state: any) => state.news_edit.search_string
  );
  // 必須入力欄の入力バリデーションに使用
  const title_input_status = useSelector(
    (state: any) => state.news_edit.title_input_status
  );
  const editorIsToggled = useSelector(
    (state: any) => state.news_edit.editorIsToggled
  );
  const newsIsEditing = useSelector(
    (state: any) => state.news_edit.newsIsEditing
  );
  const newsTitleIsEdited = useSelector(
    (state: any) => state.news_edit.newsTitleIsEdited
  );
  const savedTempData = useSelector(
    (state: any) => state.news_edit.savedTempData
  );
  const savedTempContent = useSelector(
    (state: any) => state.news_edit.savedTempData.content
  );
  const savedTempTitle = useSelector(
    (state: any) => state.news_edit.savedTempData.title
  );
  const savedTempTitleDraft = useSelector(
    (state: any) => state.news_edit.savedTempData.title_draft
  );
  const savedTempSearchString = useSelector(
    (state: any) => state.news_edit.savedTempData.search_string
  );
  const savedTempDraft = useSelector(
    (state: any) => state.news_edit.savedTempData.draft
  );
  const savedTempIsEditing = useSelector(
    (state: any) => state.news_edit.savedTempData.newsIsEditing
  );
  const savedTempTitleIsEdited = useSelector(
    (state: any) => state.news_edit.savedTempData.newsTitleIsEdited
  );
  const currentNewsStatus = useSelector(
    (state: any) => state.news_detail.status
  );

  // 編集画面と公開記事確認画面の切り替え
  const [showEditor, setShowEditor] = useState(true);
  const handleToggleEditor = () => {
    setShowEditor(!showEditor);
    if (showEditor && !editorIsToggled) {
      //１回目
      dispatch(saveTempNewsEditData(news_edit, currentNewsStatus));
    } else if (showEditor && editorIsToggled) {
      //２回目以降
      dispatch(saveTempNewsEditData(savedTempData));
    } else {
      return false;
    }
    dispatch(setNewsEditorIsToggled(true));
  };

  //更新イベントハンドラー
  const handleRegister = () => {
    if (editorIsToggled) {
      //一度でもセーブされてたらセーブした本文とタイトルをリクエストに載せる。
      if (savedTempIsEditing) {
        !savedTempTitleIsEdited && currentNewsStatus === 3
          ? //本文は編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合（最新のタイトルの状態がtitle_draft）
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitleDraft,
                savedTempContent,
                news_edit.status,
                savedTempSearchString
              )
            )
          : //本文・タイトルともに編集済みの場合(最新情報が全て更新されている場合)
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitle,
                savedTempContent,
                news_edit.status,
                savedTempSearchString
              )
            );
      } else {
        !savedTempTitleIsEdited && currentNewsStatus === 3
          ? //本文・タイトルともに未編集で、直近のステータスが下書きだった場合（最新の状態がdraftとtitle_draft）
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitleDraft,
                savedTempDraft,
                news_edit.status,
                savedTempSearchString
              )
            )
          : //本文は未編集だが、タイトルは編集している場合（最新の状態がdraftとtitle）
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitle,
                savedTempDraft,
                news_edit.status,
                savedTempSearchString
              )
            );
      }
    } else {
      //一度もセーブしてない場合
      if (newsIsEditing) {
        !newsTitleIsEdited && currentNewsStatus === 3
          ? // 本文編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合 (本文最新: content, タイトル最新: title_draft)
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title_draft,
                news_edit.content,
                news_edit.status,
                search_string
              )
            )
          : // 本文・タイトルを編集している場合 ( 本文最新: content, タイトル最新: title )
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title,
                news_edit.content,
                news_edit.status,
                search_string
              )
            );
      } else {
        !newsTitleIsEdited && currentNewsStatus === 3
          ? // 本文、タイトルともに未編集で、直近のステータスが下書きだった場合 ( 本文最新: draft, タイトル最新: title_draft )
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title_draft,
                news_edit.draft,
                news_edit.status,
                search_string
              )
            )
          : // 本文は未編集で、タイトルは編集している場合 ( 本文最新: draft, タイトル最新: title )
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title,
                news_edit.draft,
                news_edit.status,
                search_string
              )
            );
      }
    }
  };

  useEffect(() => {
    // タイトル入力ステータスを管理
    if (editorIsToggled) {
      //データ切り替えてたら、一時保存データを入れ込む
      dispatch(inputEditNewsTitle(savedTempTitle));
    } else {
      dispatch(inputEditNewsTitle(title));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleNewsEditPostStatus(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNewsDetailRequest(token, Number(id)));
  }, [dispatch, token, id]);

  useEffect(() => {
    dispatch(resetPreviousPublishStatus());
  }, [dispatch]);

  return (
    <>
      {post_status ? (
        history.push("/news-edit-list")
      ) : (
        <MainSC>
          <PageHeading title="お知らせ編集" />
          <BreadCrumbAndToggleButtonWrapper>
            <PageBreadcrumb
              firstLink="/news-edit-list"
              firstTitle="お知らせ一覧"
              secondTitle={title}
            />
            <ToggleButton
              id="toggleButton"
              className="toggle-button"
              type="checkbox"
              onClick={handleToggleEditor}
            />
            <ToggleButtonLabel htmlFor="toggleButton"></ToggleButtonLabel>
          </BreadCrumbAndToggleButtonWrapper>
          <NewsDetailEditBlockSC
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            {showEditor ? <NewsEditor /> : <NewsCurrentView />}
          </NewsDetailEditBlockSC>
          <TrashDescSC onClick={() => dispatch(toggleDeleteNewsModal(true))}>
            このお知らせを削除
          </TrashDescSC>

          {title_input_status ? (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、ありません
              </ButtonAreaDeskSC>
              <SubmitButtonSC className="active" onClick={handleRegister}>
                更新
              </SubmitButtonSC>
              <PreviewButtonSC to="/news/edit-preview">
                プレビュー
              </PreviewButtonSC>
            </FixedButtonAreaSC>
          ) : (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、残り１つです
              </ButtonAreaDeskSC>
              <SubmitButtonSC>更新</SubmitButtonSC>
              <PreviewButtonSC to="/news/edit-preview">
                プレビュー
              </PreviewButtonSC>
            </FixedButtonAreaSC>
          )}
        </MainSC>
      )}
    </>
  );
}

export default NewsDetailEdit;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const NewsDetailEditBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    padding: 30px;
    margin-right: 10px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    margin-left: -30px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  &.active {
    &:hover {
      opacity: 0.6;
    }
  }
`;

const TrashDescSC = styled.p`
  color: ${color.primary};
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  padding-left: 18px;
  position: absolute;
  right: 30px;
  bottom: 110px;
  background-image: url(${trash});
  background-size: 12px 14px;
  background-position: left center;
  background-repeat: no-repeat;
  cursor: pointer;
  ${pc`
    bottom: 90px;
    left: 710px;
  `}
`;

const PreviewButtonSC = styled(Link)`
  ${buttonBaseStyle}
  opacity: 1;
  color: ${color.primary};
  background-color: ${color.white};
  border: 1px solid ${color.primary};
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BreadCrumbAndToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 810px;
  position: relative;
`;

const ToggleButton = styled.input`
  .toggle-size {
    width: auto;
    max-width: 60px;
    height: auto;
    max-height: 50px;
  }

  &.toggle-button {
    display: block;
    appearance: none;
    position: absolute;
    right: 0;
    width: 44px;
    height: 25px;
    border: 1px solid #b3b3b3;
    border-radius: 50px;
    background-color: #fff;
    transition: background-color 0.4s ease;
    &:focus {
      outline: none;
    }
    &:checked {
      background-color: ${color.primary};
    }
  }

  &.toggle-button + label {
    position: relative;
    display: inline-block;
    top: 2px;
    left: -22px;
    width: 20px;
    height: 20px;
    border: 1px solid #b3b3b3;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    transition: left 0.4s ease, background-color 0.4s ease;
  }

  &.toggle-button:checked + label {
    position: relative;
    display: inline-block;
    top: 2px;
    left: -2px;
    width: 20px;
    height: 20px;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
`;

const ToggleButtonLabel = styled.label``;
