import React from "react";
import styled from "styled-components";
import loading_dots from "../../images/icons/loading-dots.svg";
const LoadingModule = () => {
  return (
    <LoadingBG>
      <div>
        <img src={loading_dots} alt="loading" />
      </div>
    </LoadingBG>
  );
};

export default LoadingModule;

const LoadingBG = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
