const GET_MEDIA_FILES_MINE_REQUESTED = "GET_MEDIA_FILES_REQUESTED";
const GET_MEDIA_FILES_MINE_SUCCEEDED = "GET_MEDIA_FILES_SUCCEEDED";
const GET_MEDIA_FILES_MINE_FAILED = "GET_MEDIA_FILES_FAILED";
const GET_MEDIA_FILES_ADMIN_REQUESTED = "GET_MEDIA_FILES_ADMIN_REQUESTED";
const GET_MEDIA_FILES_ADMIN_SUCCEEDED = "GET_MEDIA_FILES_ADMIN_SUCCEEDED";
const GET_MEDIA_FILES_ADMIN_FAILED = "GET_MEDIA_FILES_ADMIN_FAILED";
const GET_MEDIA_FILES_SHARE_REQUESTED = "GET_MEDIA_FILES_SHARE_REQUESTED";
const GET_MEDIA_FILES_SHARE_SUCCEEDED = "GET_MEDIA_FILES_SHARE_SUCCEEDED";
const GET_MEDIA_FILES_SHARE_FAILED = "GET_MEDIA_FILES_SHARE_FAILED";
const UPLOAD_MEDIA_FILE_REQUESTED = "UPLOAD_MEDIA_FILE_REQUESTED";
const UPLOAD_MEDIA_FILE_SUCCEEDED = "UPLOAD_MEDIA_FILE_SUCCEEDED";
const UPLOAD_MEDIA_FILE_FAILED = "UPLOAD_MEDIA_FILE_FAILED";
const DELETE_MEDIA_FILE_REQUESTED = "DELETE_MEDIA_FILE_REQUESTED";
const DELETE_MEDIA_FILE_SUCCEEDED = "DELETE_MEDIA_FILE_SUCCEEDED";
const DELETE_MEDIA_FILE_FAILED = "DELETE_MEDIA_FILE_FAILED";
const SEARCH_MEDIA_FILES_REQUESTED = "SEARCH_MEDIA_FILES_REQUESTED";
const SEARCH_MEDIA_FILES_SUCCEEDED = "SEARCH_MEDIA_FILES_SUCCEEDED";
const SEARCH_MEDIA_FILES_FAILED = "SEARCH_MEDIA_FILES_FAILED";
const TOGGLE_MEDIA_FILES_VIEW_MODE = "TOGGLE_MEDIA_FILES_VIEW_MODE";
const TOGGLE_MEDIA_FILES_SELECT_MODE = "TOGGLE_MEDIA_FILES_SELECT_MODE";
const SET_SELECTED_MEDIA_IDS = "SET_SELECTED_MEDIA_IDS";
const TOGGLE_SELECTED_MEDIA_IDS_ALL = "TOGGLE_SELECTED_MEDIA_IDS_ALL";
const UPDATE_SELECTED_MEDIA_FILES_REQUESTED =
  "UPDATE_SELECTED_MEDIA_FILES_REQUESTED";
const UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED =
  "UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED";
const UPDATE_SELECTED_MEDIA_FILES_FAILED = "UPDATE_SELECTED_MEDIA_FILES_FAILED";
const TOGGLE_IMG_LOADING_STATE = "TOGGLE_IMG_LOADING_STATE";
const RESET_SELECTED_MEDIA_FILE_IDS = "RESET_SELECTED_MEDIA_FILE_IDS";
const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
const SET_ALBUM_VIEW_TYPE = "SET_ALBUM_VIEW_TYPE";
const RESET_SEARCH_PARAMS = "RESET_SEARCH_PARAMS";

export {
  GET_MEDIA_FILES_MINE_REQUESTED,
  GET_MEDIA_FILES_MINE_SUCCEEDED,
  GET_MEDIA_FILES_MINE_FAILED,
  GET_MEDIA_FILES_ADMIN_REQUESTED,
  GET_MEDIA_FILES_ADMIN_SUCCEEDED,
  GET_MEDIA_FILES_ADMIN_FAILED,
  GET_MEDIA_FILES_SHARE_REQUESTED,
  GET_MEDIA_FILES_SHARE_SUCCEEDED,
  GET_MEDIA_FILES_SHARE_FAILED,
  SEARCH_MEDIA_FILES_REQUESTED,
  SEARCH_MEDIA_FILES_SUCCEEDED,
  SEARCH_MEDIA_FILES_FAILED,
  TOGGLE_MEDIA_FILES_VIEW_MODE,
  TOGGLE_MEDIA_FILES_SELECT_MODE,
  SET_SELECTED_MEDIA_IDS,
  TOGGLE_SELECTED_MEDIA_IDS_ALL,
  UPDATE_SELECTED_MEDIA_FILES_REQUESTED,
  UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED,
  UPDATE_SELECTED_MEDIA_FILES_FAILED,
  TOGGLE_IMG_LOADING_STATE,
  UPLOAD_MEDIA_FILE_REQUESTED,
  UPLOAD_MEDIA_FILE_SUCCEEDED,
  UPLOAD_MEDIA_FILE_FAILED,
  RESET_SELECTED_MEDIA_FILE_IDS,
  DELETE_MEDIA_FILE_REQUESTED,
  DELETE_MEDIA_FILE_SUCCEEDED,
  DELETE_MEDIA_FILE_FAILED,
  SET_SEARCH_PARAMS,
  SET_ALBUM_VIEW_TYPE,
  RESET_SEARCH_PARAMS
};
