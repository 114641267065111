import React from "react";
import styled from "styled-components";
import { color } from "components/mixins";

import { ManualItemForSelector } from "store/manual/manuals/types.d";
import ManualsUneditableItem from "./ManualsUneditableItem";

interface Props {
  hookedManuals: ManualItemForSelector[];
}
function ManualsUneditableList(props: Props) {
  const { hookedManuals } = props;
  return (
    <ManualsAdminListSC>
      {hookedManuals.map((manual: any, index: number) => {
        return <ManualsUneditableItem key={index} manual={manual} />;
      })}
    </ManualsAdminListSC>
  );
}

export default ManualsUneditableList;

const ManualsAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;
