// api
export const GET_MANUAL_TAGS_SUCCEEDED = "GET_MANUAL_TAGS_SUCCEEDED";
export const GET_MANUAL_TAGS_FAILED = "GET_MANUAL_TAGS_FAILED";
export const GET_MANUAL_TAGS_REQUESTED = "GET_MANUAL_TAGS_REQUESTED";
export const UPDATE_VIEW_MANUAL_TAG_NAME = "UPDATE_VIEW_MANUAL_TAG_NAME";
export const UPDATE_VIEW_MANUAL_TAG_ORDER = "UPDATE_VIEW_MANUAL_TAG_ORDER";
export const UPDATE_ALL_MANUAL_TAGS_REQUESTED =
  "UPDATE_ALL_MANUAL_TAGS_REQUESTED";
export const UPDATE_ALL_MANUAL_TAGS_SUCCEEDED =
  "UPDATE_ALL_MANUAL_TAGS_SUCCEEDED";
export const UPDATE_ALL_MANUAL_TAGS_FAILED = "UPDATE_ALL_MANUAL_TAGS_FAILED";
export const DELETE_MANUAL_TAG_FROM_VIEW = "DELETE_MANUAL_TAG_FROM_VIEW";

export const ALL_MANUAL_TAG_UPDATE_REQUESTED =
  "ALL_MANUAL_TAG_UPDATE_REQUESTED";
export const ALL_MANUAL_TAG_UPDATE_SUCCEEDED =
  "ALL_MANUAL_TAG_UPDATE_SUCCEEDED";
export const ALL_MANUAL_TAG_UPDATE_FAILED = "ALL_MANUAL_TAG_UPDATE_FAILED";

//to be created
export const ADD_TO_BE_CREATED_MANUAL_TAG = "ADD_TO_BE_CREATED_MANUAL_TAG";
export const DELETE_TO_BE_CREATED_MANUAL_TAG =
  "DELETE_TO_BE_CREATED_MANUAL_TAG";
export const CHANGE_NAME_TO_BE_CREATED_MANUAL_TAG =
  "CHANGE_NAME_TO_BE_CREATED_MANUAL_TAG";
export const CHANGE_ORDER_TO_BE_CREATED_MANUAL_TAG =
  "CHANGE_ORDER_TO_BE_CREATED_MANUAL_TAG";

//to be updated
export const ADD_TO_BE_UPDATED_MANUAL_TAG = "ADD_TO_BE_UPDATED_MANUAL_TAG";
export const DELETE_TO_BE_UPDATED_MANUAL_TAG =
  "DELETE_TO_BE_UPDATED_MANUAL_TAG";
export const CHANGE_NAME_TO_BE_UPDATED_MANUAL_TAG =
  "CHANGE_NAME_TO_BE_UPDATED_MANUAL_TAG";
export const CHANGE_ORDER_TO_BE_UPDATED_MANUAL_TAG =
  "CHANGE_ORDER_TO_BE_UPDATED_MANUAL_TAG";

//to be deleted
export const ADD_TO_BE_DELETED_MANUAL_TAG = "ADD_TO_BE_DELETED_MANUAL_TAG";

//to be searched
export const TOGGLE_TO_BE_SEARCHED_MANUAL_TAG_CHECK =
  "TOGGLE_TO_BE_SEARCHED_MANUAL_TAG_CHECK";
export const SET_MANUAL_TAG_TO_BE_SEARCHED_CHECK =
  "SET_MANUAL_TAG_TO_BE_SEARCHED_CHECK";

export const SET_DRAFT_TAGS = "SET_DRAFT_TAGS";
export const TOGGLE_DRAFT_TAGS = "TOGGLE_DRAFT_TAGS";
