import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SortableHandle, SortableElement } from "react-sortable-hoc";
import { color } from "../../../mixins";
import SortHandle from "components/Manual/Parts/SortHandle";
interface Props {
  index: number;
  id: number;
  title: string;
}

const TopicsSortableItem = (props: Props) => {
  const { id, index, title } = props;

  const DragHandle = SortableHandle(() => <SortHandle />);

  const SortableItem = SortableElement((value: any) => (
    <Wrapper key={index}>
      <TopicsItemForEditModeSC>
        <DragHandle />
        <TopicsItemContent>
          <TopicsItem>{title}</TopicsItem>
        </TopicsItemContent>
      </TopicsItemForEditModeSC>
    </Wrapper>
  ));
  return <SortableItem key={id} index={index} value={props} />;
};

export default TopicsSortableItem;

const Wrapper = styled.li`
  list-style: none;
  pointer-events: auto !important;
`;

const TopicsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 22px 22px 0px;
`;

const TopicsItemForEditModeSC = styled.div`
  display: flex;
  border-bottom: 1px solid ${color.list_border};
`;

const TopicsItem = styled.p`
  font-size: 16px;
  width: 100%;
  font-weight: bold;
`;
