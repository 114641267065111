import React from "react";
import styled from "styled-components";
import { Holiday } from "@holiday-jp/holiday_jp";
import checklist from "../../../../images/icons/checklist.svg";

type DayCellProps = {
  eventName: string;
  holiday?: Holiday[];
  hasIcon?: boolean;
};
const ContentCell = (props: DayCellProps) => {
  const { eventName, holiday, hasIcon } = props;
  const splitEventName = eventName.split("");
  return (
    <EventCellSC>
      <Content
        className={
          eventName.length > 84
            ? "x-small"
            : eventName.length > 63
            ? "small"
            : ""
        }
      >
        {holiday && holiday.length !== 0 && `${holiday[0]["name"]} `}
        {splitEventName.map((chr: string) => {
          return <span className="word">{chr}</span>;
        })}
      </Content>
      <IconCell>{hasIcon && <Icon src={checklist} alt="凡例" />}</IconCell>
    </EventCellSC>
  );
};

export default ContentCell;

const EventCellSC = styled.div`
  width: 100%;
  text-align: left;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Content = styled.p`
  word-break: break-all;
  &.x-small {
    line-height: 0.6;
    .word {
      display: inline-block;
      transform: scale(0.7);
      transform-origin: 0px 3px;
      vertical-align: bottom;
      margin-right: -3px;
    }
  }
  &.small {
    line-height: 0.7;
    .word {
      display: inline-block;
      transform: scale(0.7);
      transform-origin: 0px 2px;
      vertical-align: bottom;
      margin-right: -3px;
    }
  }
`;

const IconCell = styled.div`
  width: 15px;
  display: flex;
  align-items: center;
`;
const Icon = styled.img`
  width: 15px;
  height: 15px;
  display: block;
`;
