import {
  TOGGLE_FACILITY_MODAL,
  TOGGLE_SERVICE_MODAL,
  TOGGLE_DELETE_EVENT_MODAL,
  TOGGLE_ADD_EVENT_MODAL,
  TOGGLE_EDIT_EVENT_MODAL,
  TOGGLE_DELETE_MONTHLY_EVENT_MODAL,
  TOGGLE_ADD_MONTHLY_EVENT_MODAL,
  TOGGLE_EDIT_MONTHLY_EVENT_MODAL,
  TOGGLE_CSV_MODAL,
  TOGGLE_AUTHORITY_ERROR_MODAL,
  TOGGLE_APPROVAL_MODAL,
  TOGGLE_MONTHLY_APPROVAL_MODAL,
  TOGGLE_RETURN_EVENT_MODAL,
  TOGGLE_DELETE_RETURN_EVENT_MODAL,
  TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL,
  TOGGLE_CSV_DOWNLOAD_REJECT_MODAL,
  TOGGLE_APPROVAL_REQUEST_MODAL,
  TOGGLE_ALREADY_APPROVED_MODAL,
  TOGGLE_CANCEL_ALL_EVENTS_MODAL,
  TOGGLE_DELETE_ARTICLE_MODAL,
  TOGGLE_DELETE_NEWS_MODAL,
  TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL
} from "./type";

import { POST_EVENTS_APPLY_SUCCEEDED } from "../../event/events/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { POST_REJECT_YEAR_MONTHLY_SUCCEEDED } from "store/event/approval/type";

interface Modal {
  facility_modal_open: boolean;
  service_modal_open: boolean;
  delete_event_modal_open: boolean;
  add_event_modal_open: boolean;
  edit_event_modal_open: boolean;
  delete_monthly_event_modal_open: boolean;
  add_monthly_event_modal_open: boolean;
  edit_monthly_event_modal_open: boolean;
  csv_modal_open: boolean;
  authority_error_modal_open: boolean;
  approval_modal_open: boolean;
  already_approved_error_modal_open: boolean;
  monthly_approval_modal_open: boolean;
  return_event_modal_open: boolean;
  delete_return_event_modal_open: boolean;
  annual_event_schedule_modal_open: boolean;
  csv_download_reject_modal_open: boolean;
  approval_request_modal_open: boolean;
  cancel_all_events_modal_open: boolean;
  delete_article_modal_open: boolean;
  delete_news_modal_open: boolean;
  cancel_year_monthly_events_modal_open: boolean;
}

const initialState: Modal = {
  facility_modal_open: false,
  service_modal_open: false,
  delete_event_modal_open: false,
  add_event_modal_open: false,
  edit_event_modal_open: false,
  delete_monthly_event_modal_open: false,
  add_monthly_event_modal_open: false,
  edit_monthly_event_modal_open: false,
  csv_modal_open: false,
  authority_error_modal_open: false,
  approval_modal_open: false,
  already_approved_error_modal_open: false,
  monthly_approval_modal_open: false,
  return_event_modal_open: false,
  delete_return_event_modal_open: false,
  annual_event_schedule_modal_open: false,
  csv_download_reject_modal_open: false,
  approval_request_modal_open: false,
  cancel_all_events_modal_open: false,
  delete_article_modal_open: false,
  delete_news_modal_open: false,
  cancel_year_monthly_events_modal_open: false
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_FACILITY_MODAL:
      console.log("action.facility_modal_open:", action.facility_modal_open);
      return {
        ...state,
        facility_modal_open: action.facility_modal_open
      };

    case TOGGLE_SERVICE_MODAL:
      return {
        ...state,
        service_modal_open: action.service_modal_open
      };

    case TOGGLE_DELETE_EVENT_MODAL:
      return {
        ...state,
        delete_event_modal_open: action.delete_event_modal_open
      };

    case TOGGLE_DELETE_NEWS_MODAL:
      return {
        ...state,
        delete_news_modal_open: action.delete_news_modal_open
      };

    case TOGGLE_DELETE_ARTICLE_MODAL:
      return {
        ...state,
        delete_article_modal_open: action.delete_article_modal_open
      };

    case TOGGLE_ADD_EVENT_MODAL:
      return {
        ...state,
        add_event_modal_open: action.add_event_modal_open
      };

    case TOGGLE_EDIT_EVENT_MODAL:
      return {
        ...state,
        edit_event_modal_open: action.edit_event_modal_open
      };

    case TOGGLE_DELETE_MONTHLY_EVENT_MODAL:
      return {
        ...state,
        delete_monthly_event_modal_open: action.delete_monthly_event_modal_open
      };

    case TOGGLE_ADD_MONTHLY_EVENT_MODAL:
      return {
        ...state,
        add_monthly_event_modal_open: action.add_monthly_event_modal_open
      };

    case TOGGLE_EDIT_MONTHLY_EVENT_MODAL:
      return {
        ...state,
        edit_monthly_event_modal_open: action.edit_monthly_event_modal_open
      };
    case TOGGLE_CSV_MODAL:
      return {
        ...state,
        csv_modal_open: action.csv_modal_open
      };
    case TOGGLE_AUTHORITY_ERROR_MODAL:
      return {
        ...state,
        authority_error_modal_open: action.authority_error_modal_open
      };

    case TOGGLE_APPROVAL_MODAL:
      return {
        ...state,
        approval_modal_open: action.approval_modal_open
      };

    case TOGGLE_MONTHLY_APPROVAL_MODAL:
      return {
        ...state,
        monthly_approval_modal_open: action.monthly_approval_modal_open
      };

    case TOGGLE_ALREADY_APPROVED_MODAL:
      return {
        ...state,
        already_approved_error_modal_open: action.already_approved_modal_open
      };

    case TOGGLE_RETURN_EVENT_MODAL:
      return {
        ...state,
        return_event_modal_open: action.return_event_modal_open
      };

    case TOGGLE_DELETE_RETURN_EVENT_MODAL:
      return {
        ...state,
        delete_return_event_modal_open: action.delete_return_event_modal_open
      };

    case TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL:
      return {
        ...state,
        annual_event_schedule_modal_open:
          action.annual_event_schedule_modal_open
      };

    case TOGGLE_CSV_DOWNLOAD_REJECT_MODAL:
      return {
        ...state,
        csv_download_reject_modal_open: action.csv_download_reject_modal_open
      };

    case TOGGLE_APPROVAL_REQUEST_MODAL:
      return {
        ...state,
        approval_request_modal_open: action.approval_request_modal_open
      };

    case POST_EVENTS_APPLY_SUCCEEDED:
      return {
        ...state,
        approval_request_modal_open: false
      };

    case TOGGLE_CANCEL_ALL_EVENTS_MODAL:
      return {
        ...state,
        cancel_all_events_modal_open: action.cancel_all_events_modal_open
      };

    case TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL:
      return {
        ...state,
        cancel_year_monthly_events_modal_open:
          action.cancel_year_monthly_events_modal_open
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
