import { CameraTag } from "components/Camera/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_MEDIA_FILE_SUCCEEDED,
  GET_MEDIA_FILE_FAILED,
  UPDATE_MEDIA_FILE_SUCCEEDED
} from "./type";

export interface PhotoDetailState {
  id: number | null;
  user: User;
  name: string;
  media_type: Status;
  file_path: string;
  thumbnail_path: string;
  upload_status: Status;
  additional_info: string;
  share_ban: boolean;
  taken_at: string;
  tags: CameraTag[];
  facilities: CommonData[];
  classes: CommonData[];
  created_at: string;
  updated_at: string;
}

interface Status {
  id: number | null;
  name: string;
  created_at: string;
  updated_at: string;
}
interface CommonData {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}
interface User {
  id: number | null;
  name: string;
  picture: string;
  created_at: string;
  updated_at: string;
}

const initialStatus = {
  id: null,
  name: "",
  created_at: "",
  updated_at: ""
};

const initialState: PhotoDetailState = {
  id: null,
  user: {
    id: null,
    name: "",
    picture: "",
    created_at: "",
    updated_at: ""
  },
  name: "",
  media_type: initialStatus,
  file_path: "",
  thumbnail_path: "",
  upload_status: initialStatus,
  additional_info: "",
  share_ban: false,
  taken_at: "",
  tags: [],
  facilities: [],
  classes: [],
  created_at: "",
  updated_at: ""
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MEDIA_FILE_SUCCEEDED:
      return {
        ...state,
        ...action.camera_detail
      };
    case UPDATE_MEDIA_FILE_SUCCEEDED:
      return {
        ...state
      };

    case GET_MEDIA_FILE_FAILED:
      return { ...state };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
