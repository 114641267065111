import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { pc } from "../../mixins/mediaQuery";
import ArticlesList from "./ArticlesList";
import ArticlesListForUser from "./ArticlesListForUser";
import ArticlesStocksList from "./ArticlesStock/ArticlesStocksList";
import FaqPagination from "./FaqPagination";

function ArticlesListBlock() {
  const location = useLocation();
  const pathName = location.pathname;
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  return (
    <ArticlesListBlockSC className={rightSectionOpen ? "open" : "close"}>
      <FaqPagination />
      {pathName === "/" && <ArticlesListForUser />}
      {pathName === "/articles" && (
        <>
          {!isAdmin && !isEditor ? <ArticlesListForUser /> : <ArticlesList />}
        </>
      )}
      {pathName === "/articles/stocks" && <ArticlesStocksList />}
      <FaqPagination position="bottom" />
    </ArticlesListBlockSC>
  );
}

export default ArticlesListBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const ArticlesListBlockSC = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  position: relative;
  ${pc`
    &.close {
      width: 100%;
      margin-right: 0px;
    }
    width: calc(100% - 380px);
    margin-right: 10px;
    padding: 30px;
    order: 1;
    position: static;
  `}
`;
