import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  POST_NEWS_SUCCEEDED,
  POST_NEWS_FAILED,
  PUT_NEWS_SUCCEEDED,
  PUT_NEWS_FAILED,
  INPUT_NEW_NEWS_TITLE,
  SELECT_NEW_NEWS_STATUS,
  SET_NEWS_RAW_CONTENT,
  TOGGLE_NEW_NEWS_POST_STATUS,
} from "./type";

interface News {
  new_news_id: number | null;
  title: string;
  content: any;
  status: number;
  post_status: boolean;
  title_input_status: boolean;
}

const initialState: News = {
  new_news_id: null,
  title: "",
  content:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  status: 1,
  post_status: false,
  title_input_status: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_NEWS_SUCCEEDED:
      console.log("新規投稿作成に成功しました status: ", action.status);
      return {
        ...state,
        new_news_id: action.id,
      };

    case POST_NEWS_FAILED:
      console.log("新規投稿作成エラー エラーコード:", action.error_code);
      return {
        ...state,
      };

    case PUT_NEWS_SUCCEEDED:
      console.log("新規投稿登録に成功しました status: ", action.result);
      return {
        new_news_id: null,
        title: "",
        content:
          '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        status: 2,
        post_status: true,
      };

    case PUT_NEWS_FAILED:
      console.log("新規投稿登録作成エラー エラーコード:", action.error_code);
      return {
        ...state,
        id: null,
        title: "",
        content: "",
        search_string: "",
        status: 2,
        categories: [],
        tags: [],
      };

    case INPUT_NEW_NEWS_TITLE:
      if (action.title !== "") {
        return {
          ...state,
          title: action.title,
          title_input_status: true,
        };
      } else {
        return {
          ...state,
          title: action.title,
          title_input_status: false,
        };
      }

    case SELECT_NEW_NEWS_STATUS:
      return {
        ...state,
        status: action.id,
      };

    case SET_NEWS_RAW_CONTENT:
      return {
        ...state,
        content: action.content,
      };

    case TOGGLE_NEW_NEWS_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
