import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_ADD_MONTHLY_EVENT_INITIAL_DATE,
  SET_ADD_MONTHLY_EVENT_TYPE,
  SET_ADD_MONTHLY_EVENT_CONTENT,
  RESET_ADD_MONTHLY_EVENT_TYPE,
  RESET_ADD_MONTHLY_EVENT_CONTENT,
} from "./type";

const initialState = {
  year: new Date().getFullYear(),
  month: 4,
  date: "",
  type_id: 52,
  content: "",
  detail: "",
  kind: 2,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ADD_MONTHLY_EVENT_INITIAL_DATE:
      return {
        ...state,
        yearMonthDate: action.yearMonthDate,
        year: action.year,
        month: action.month,
        date: action.date,
      };

    case SET_ADD_MONTHLY_EVENT_TYPE:
      return {
        ...state,
        type_id: action.type_id,
      };

    case SET_ADD_MONTHLY_EVENT_CONTENT:
      return {
        ...state,
        content: action.content,
      };

    case RESET_ADD_MONTHLY_EVENT_TYPE:
      return {
        ...state,
        type_id: null,
      };

    case RESET_ADD_MONTHLY_EVENT_CONTENT:
      return {
        ...state,
        content: null,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
