import React from "react";
import styled from "styled-components";
import { localeTimeCustomizerForHistory } from "../../../Common/localeTimeCustomizerForManual";
import { color } from "../../../mixins";
import { History } from "../../demoData";

interface UpdateHistoryListProps {
  data: History[];
  toggleCheckbox: (id: number) => void;
}

const UpdateHistoryList: React.FC<UpdateHistoryListProps> = props => {
  const { data, toggleCheckbox } = props;
  console.log(data);
  return (
    <Content>
      <HistoryListSC>
        {data &&
          data.map(val => {
            return (
              <UpdateHistoryItem
                itemData={val}
                toggleCheckbox={toggleCheckbox}
              />
            );
          })}
      </HistoryListSC>
    </Content>
  );
};
export default UpdateHistoryList;

//--- UpdateHistoryItem ---//
interface ItemProps {
  itemData: History;
  toggleCheckbox: (id: number) => void;
}
const UpdateHistoryItem = (props: ItemProps) => {
  const { itemData, toggleCheckbox } = props;
  const { id, changed_at, priority, manual_name, type } = itemData;

  return (
    <HistoryItemSC>
      <LeftSectionSC>
        <LabelSC>掲載</LabelSC>
        <CheckboxLabel id={`${id}`} onChange={() => toggleCheckbox(id)}>
          <Checkbox
            className="checkbox"
            id={`history_${id}`}
            type="checkbox"
            checked={priority}
          />
          <CheckboxSpan className="label" />
        </CheckboxLabel>
      </LeftSectionSC>
      <RightSectionSC>
        <p className="updatedAt">
          最終更新:{localeTimeCustomizerForHistory(changed_at)}
        </p>
        <p className="detail">
          {manual_name}が
          {type === 0
            ? "追加されました"
            : type === 1
            ? "更新されました"
            : type === 2
            ? "削除されました"
            : ""}
        </p>
      </RightSectionSC>
    </HistoryItemSC>
  );
};

//---styles---//

const Content = styled.div`
  padding-top: 30px;
  padding-bottom: 60px;
`;

const HistoryListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 6px;
`;

const HistoryItemSC = styled.li`
  min-height: 60px;
  color: ${color.gray};
  display: flex;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
`;

const LeftSectionSC = styled.div`
  border-right: 1px solid ${color.list_border};
  padding: 12px 10px 18px 12px;
`;
const RightSectionSC = styled.div`
  padding: 12px 8px 14px;
  .updatedAt {
    font-size: 12px;
    color: ${color.light_gray};
    margin-bottom: 5px;
  }
  .detail {
    font-weight: bold;
  }
`;

const LabelSC = styled.p`
  font-size: 10px;
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.black};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::after {
    content: "✓";
    position: absolute;
    top: -1px;
    left: -1px;
    font-size: 20px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
