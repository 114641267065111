import {
  TOGGLE_TAG_TO_BE_SEARCHED_CHECK,
  SET_TAG_TO_BE_SEARCHED_CHECK,
} from "./type";
// ================================================================= //
// Actions
// ----------------------------------------------------------------- //

export const toggleTagToBeSearchedCheck = (tag_name: string) => {
  return {
    type: TOGGLE_TAG_TO_BE_SEARCHED_CHECK,
    tag_name,
  };
};

export const setTagToBeSearchedCheck = (tag_name: string[]) => {
  return {
    type: SET_TAG_TO_BE_SEARCHED_CHECK,
    tag_name,
  };
};
