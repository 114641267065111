import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSystemStatusRequest } from "../../store/session/action";

/**
 *
 * @description pathNameの変更を検知してシステムステータスを取得するdispatchを走らせる
 */

const MaintenanceStatusCheckModule = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const token = useSelector((state: any) => state.session.token);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getSystemStatusRequest(token));
  }, [pathName]);
  return <></>;
};

export default MaintenanceStatusCheckModule;
