const GET_REGISTERED_TAGS_SUCCEEDED = "GET_REGISTERED_TAGS_SUCCEEDED";
const GET_REGISTERED_TAGS_FAILED = "GET_REGISTERED_TAGS_FAILED";
const GET_REGISTERED_TAGS_REQUESTED = "GET_REGISTERED_TAGS_REQUESTED";
const UPDATE_VIEW_TAG_NAME = "UPDATE_VIEW_TAG_NAME"
const UPDATE_VIEW_TAG_ORDER = "UPDATE_VIEW_TAG_ORDER"
const UPDATE_TAG_ITEM_REQUESTED = "UPDATE_TAG_ITEM_REQUESTED"
const UPDATE_TAG_ITEM_SUCCEEDED = "UPDATE_TAG_ITEM_SUCCEEDED"
const UPDATE_TAG_ITEM_FAILED = "UPDATE_TAG_ITEM_FAILED"
const DELETE_TAG_ITEM_FROM_VIEW = "DELETE_TAG_ITEM_FROM_VIEW"
const DELETE_TAG_ITEM_REQUESTED = "DELETE_TAG_ITEM_REQUESTED"
const DELETE_TAG_ITEM_SUCCEEDED = "DELETE_TAG_ITEM_SUCCEEDED"
const DELETE_TAG_ITEM_FAILED = "DELETE_TAG_ITEM_FAILED"
const POST_NEW_TAG_ITEM_REQUESTED = "POST_TAG_ITEM_REQUESTED"
const POST_NEW_TAG_ITEM_SUCCEEDED = "POST_TAG_ITEM_SUCCEEDED"
const POST_NEW_TAG_ITEM_FAILED = "POST_TAG_ITEM_FAILED"

export {
  GET_REGISTERED_TAGS_SUCCEEDED, GET_REGISTERED_TAGS_FAILED, GET_REGISTERED_TAGS_REQUESTED, DELETE_TAG_ITEM_FROM_VIEW, UPDATE_VIEW_TAG_NAME, UPDATE_VIEW_TAG_ORDER, UPDATE_TAG_ITEM_REQUESTED, UPDATE_TAG_ITEM_SUCCEEDED, UPDATE_TAG_ITEM_FAILED, DELETE_TAG_ITEM_SUCCEEDED, DELETE_TAG_ITEM_FAILED, DELETE_TAG_ITEM_REQUESTED, POST_NEW_TAG_ITEM_REQUESTED, POST_NEW_TAG_ITEM_SUCCEEDED, POST_NEW_TAG_ITEM_FAILED,
}
