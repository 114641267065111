import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import pulldown_arrow from "../../../../images/icons/pulldown_arrow.svg";
import close from "../../../../images/icons/close.svg";

import { toggleApprovalRequestModal } from "../../../../store/common/modal/action";
import { postEventsApplyRequest } from "../../../../store/event/events/action";

export default function ReturnEventsModalContent() {
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const selected_options = useSelector(
    (state: any) => state.events.selected_options
  );
  const months = selected_options.map((month: any) => month.value);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_month = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  console.log("filtered_month", filtered_month);
  const dispatch = useDispatch();
  const handleApply = (e: any) => {
    e.preventDefault();
    dispatch(
      postEventsApplyRequest(
        token,
        facility_id,
        months,
        filtered_year,
        filtered_month
      )
    );
  };

  return (
    <ApprovalRequestModalContentSC>
      <TitleBlock>
        <Title>行事予定承認依頼</Title>
        <CloseIcon
          src={close}
          alt="閉じる　ボタン"
          onClick={() => dispatch(toggleApprovalRequestModal(false))}
        />
      </TitleBlock>

      <ApprovalRequestBlock>
        <ApprovalRequestBlockDesc>
          以下の月の行事予定を承認を依頼しますがよろしいですか？
        </ApprovalRequestBlockDesc>

        <ApprovalRequestBlockSelectDesc>
          {selected_options &&
            selected_options.length > 0 &&
            selected_options.map((item: any, index: number) => {
              if (selected_options.length - 1 === index) {
                return `${item.label}`;
              }
              return `${item.label}、`;
            })}
        </ApprovalRequestBlockSelectDesc>

        {/* <ApprovalRequestBlockDesc>現在未承認の月は下記の通りです。</ApprovalRequestBlockDesc>

          <ApprovalRequestBlockUnSelectDesc>
            {
              monthly_status.map((item: any, index: number) => {
                console.log('item', item)
                console.log('leader_approved', item.leader_approved)
                if(item.leader_approved) {
                  return ""
                }
                return `${new Date(item.month).getMonth() + 1}月、`
              })
            }
          </ApprovalRequestBlockUnSelectDesc> */}
        <Buttons>
          <CancelButton
            onClick={() => dispatch(toggleApprovalRequestModal(false))}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            value="承認依頼"
            onClick={(e: any) => handleApply(e)}
          />
        </Buttons>
      </ApprovalRequestBlock>
    </ApprovalRequestModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ApprovalRequestModalContentSC = styled.div`
  max-width: 600px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const ApprovalRequestBlock = styled.div`
  padding: 33px 54px 30px;
`;

const ApprovalRequestBlockDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  span {
    margin-bottom: 10px;
    display: block;
  }
`;

const ApprovalRequestBlockSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const ApprovalRequestBlockUnSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  &:disabled {
    opacity: 0.6;
    cursor: none;
  }
`;
