import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import EditMonthlyEventModalContent from "./EditMonthlyEventModalContent";

import { toggleEditMonthlyEventModal } from "../../../../store/common/modal/action";

export default function EditMonthlyEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <EditMonthlyEventsModalBg
        onClick={() => dispatch(toggleEditMonthlyEventModal(false))}
      ></EditMonthlyEventsModalBg>
      <EditMonthlyEventModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const EditMonthlyEventsModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
