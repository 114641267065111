import React from "react";
import styled from "styled-components";
import ModuleContainer from "./Parts/ModuleContainer";
import moduleOpen from "../../images/icons/down_arrow_gray.svg";
import { History } from "./demoData";
import { localeTimeCustomizerForHistory } from "../Common/localeTimeCustomizerForManual";
import { color } from "../mixins";

interface Props {
  data: History[];
}

const UpdateHistoriesModule = (props: Props) => {
  const { data } = props;
  const [open, setOpen] = React.useState<boolean>(
    window.innerWidth > 1024 ? true : false
  );
  return (
    <ModuleContainer>
      <ModuleHeaderSC onClick={() => setOpen(!open)}>
        <BlockHeadingSC>改訂履歴</BlockHeadingSC>
        <ToggleArrowSC
          className={open ? "open" : ""}
          src={moduleOpen}
          alt="toggleArrow"
        />
      </ModuleHeaderSC>
      {open && (
        <UpdateHistoryListSC>
          {data &&
            data.map(
              val => val.priority && <HistotyItem key={val.id} {...val} />
            )}
        </UpdateHistoryListSC>
      )}
    </ModuleContainer>
  );
};

const HistotyItem = (props: History) => {
  const { changed_at, manual_name, type } = props;
  const changedAt = localeTimeCustomizerForHistory(changed_at);
  return (
    <HistoryItemSC>
      <dl>
        <UpdateDateSC>{changedAt}</UpdateDateSC>
        <HistoryTextSC>
          {manual_name}が
          {type === 0
            ? "追加されました"
            : type === 1
            ? "更新されました"
            : type === 2
            ? "削除されました"
            : ""}
        </HistoryTextSC>
      </dl>
    </HistoryItemSC>
  );
};
export default UpdateHistoriesModule;

const ModuleHeaderSC = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 14px;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;

const UpdateHistoryListSC = styled.ul`
  padding-top: 30px;
`;

const UpdateDateSC = styled.dt`
  font-size: 16px;
  margin-bottom: 12px;
`;

const HistoryTextSC = styled.dd`
  font-size: 14px;
`;

const HistoryItemSC = styled.li`
  color: ${color.black};
  border-bottom: 1px solid #707070;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;
