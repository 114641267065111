import React from "react";
import styled from "styled-components";
import { SortableHandle, SortableElement } from "react-sortable-hoc";
import { color } from "../../../mixins";
import SortHandle from "components/Manual/Parts/SortHandle";
interface Props {
  index: number;
  id: number;
  title: string;
  title_draft: string;
  status: number;
}

const PagesSortableItem = (props: Props) => {
  const { id, index, title, title_draft, status } = props;
  // const { manualTitle, setPageTitle } = useHooks({ title });

  const DragHandle = SortableHandle(() => <SortHandle />);

  const SortableItem = SortableElement((value: any) => (
    <Wrapper key={index}>
      <PagesItemForEditModeSC>
        <DragHandle />
        <PagesItemContent>
          {
            // 下書きステータス(3)は下書き情報を表示する
            status === 3 ? (
              <PagesItem>{title_draft}</PagesItem>
            ) : (
              <PagesItem>{title}</PagesItem>
            )
          }
        </PagesItemContent>
      </PagesItemForEditModeSC>
    </Wrapper>
  ));
  return <SortableItem key={id} index={index} value={props} />;
};

export default PagesSortableItem;

const Wrapper = styled.li`
  list-style: none;
  pointer-events: auto !important;
`;

const PagesItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 22px 22px 0px;
`;

const PagesItemForEditModeSC = styled.div`
  display: flex;
  border-bottom: 1px solid ${color.list_border};
`;

const PagesItem = styled.p`
  font-size: 16px;
  width: 100%;
  font-weight: bold;
`;
