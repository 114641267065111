import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import menu from "../../../images/icons/menu_dot.svg";
import pulldown_arrow from "../../../images/icons/pulldown_arrow.svg";

import EventsTopList from "./EventsTopList";
import { containerBaseStyle } from "../../mixins";
import { toggleAnnualEventScheduleModal } from "../../../store/common/modal/action";
import { setDailyListForTop } from "../../../store/event/events_top/action";
import { setFilteredMonthTop } from "../../../store/event/event_search/action";
import { getEventCSVRequest } from "../../../store/event/event_csv/action";
import { getEventListRequest } from "../../../store/event/events/action";
import { useCurrentPosition } from "../Constants/useCurrentPosition";
import {
  getMonthlyEventRequest,
  getMonthlyEventsRequest
} from "store/event/monthly_events/action";

import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function EventsTopListBlock() {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const position_id = useSelector((state: any) => state.session.position.id);
  const filtered_month_top = useSelector(
    (state: any) => state.event_search.filtered_month_top
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const event_list = useSelector((state: any) => state.events.events);

  const { isGeneralViewer } = useCurrentPosition();

  const month_array = [
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "1",
    "2",
    "3"
  ];

  // TOPページの月指定
  const handleSelectMonth = (month: string) => {
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));
    dispatch(setFilteredMonthTop(month));
    dispatch(setDailyListForTop(filtered_year, month));
    dispatch(
      getEventListRequest(token, facility_id, filtered_year, [month], [], "")
    );
    dispatch(
      getMonthlyEventsRequest(
        token,
        facility_id,
        filtered_year,
        [month],
        [],
        ""
      )
    );
  };

  document.onclick = function (event: MouseEvent) {
    const menuList = document.getElementById("eventTopMenuList");
    const menuButton = document.getElementById("eventTopMenuButton");
    if (event.target instanceof HTMLElement) {
      if (
        !menuList?.contains(event.target) &&
        event.target !== menuList &&
        event.target !== menuButton
      ) {
        setShowMenu(false);
      }
    }
  };

  const handleYearlyEventsModalOpen = () => {
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));
    dispatch(toggleAnnualEventScheduleModal(true));
  };

  // LoadingModuleの表示終了
  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, [dispatch, event_list]);

  return (
    <EventsTopListBlockSC className={rightSectionOpen ? "open" : "close"}>
      <TitleBlock>
        <Year>{`${filtered_year}年度`}</Year>
        <MonthSelectBox>
          <MonthSelect
            onChange={(e: any) => handleSelectMonth(e.target.value)}
            value={filtered_month_top}
          >
            {month_array.map((month_num: string) => {
              return (
                <MonthOption key={`month_${month_num}`} value={`${month_num}`}>
                  {month_num}月
                </MonthOption>
              );
            })}
          </MonthSelect>
          <Menu id="eventTopMenuButton" onClick={() => setShowMenu(!showMenu)}>
            {showMenu && (
              <MenuList id="eventTopMenuList">
                <MenuItem onClick={handleYearlyEventsModalOpen}>
                  年間表示
                </MenuItem>
                {!isGeneralViewer && (
                  <MenuItem
                    onClick={() =>
                      dispatch(
                        getEventCSVRequest(token, facility_id, filtered_year)
                      )
                    }
                  >
                    CSVダウンロード
                  </MenuItem>
                )}
              </MenuList>
            )}
          </Menu>
        </MonthSelectBox>
      </TitleBlock>
      {event_list.length > 0 ? (
        <>
          <EventsTopListBlockHeader>
            <EventsTopListBlockHeaderItem className="date">
              日付
            </EventsTopListBlockHeaderItem>
            <EventsTopListBlockHeaderItem className="title">
              行事名
            </EventsTopListBlockHeaderItem>
            <EventsTopListBlockHeaderItem className="content">
              行事内容
            </EventsTopListBlockHeaderItem>
            <EventsTopListBlockHeaderItem className="detail">
              詳細（目的地）
            </EventsTopListBlockHeaderItem>
            <EventsTopListBlockHeaderItem className="class_age">
              クラス
            </EventsTopListBlockHeaderItem>
          </EventsTopListBlockHeader>
          <EventsTopList />
        </>
      ) : (
        <EnptyContainerSC>
          <p>登録済みの行事予定はありません</p>
        </EnptyContainerSC>
      )}
    </EventsTopListBlockSC>
  );
}

export default EventsTopListBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const EventsTopListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 20px 16px;
  ${pc`
    &.close {
      width: 100%;
      margin-right: 0px;
    }
    width: calc(100% - 380px);
    margin-right: 10px;
    padding: 30px;
  `}
`;

const EventsTopListBlockHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${color.list_header};
  color: ${color.light_gray};
  padding: 12px 59px;
  border-radius: 4px 4px 0 0;
  border: 1px solid ${color.divide_border};
`;

const EventsTopListBlockHeaderItem = styled.div`
  &.date {
    width: calc(14%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
`;

const EnptyContainerSC = styled.div`
  height: 450px;
  border: 1px solid ${color.divide_border};
  border-radius: 4px;
  p {
    position: relative;
    text-align: center;
    top: 50%;
    font-weight: bold;
    color: ${color.gray};
    transform: translateY(-50%);
  }
`;

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 9px;
`;

const Year = styled.h2`
  font-size: 16px;
  color: ${color.gray};
  margin: 0;
  padding-top: 11px;
`;

const MonthSelectBox = styled.div`
  display: flex;
  width: 124px;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const MonthSelect = styled.select`
  background-image: url(${pulldown_arrow});
  background-repeat: no-repeat;
  background-position: top 15px right 12px;
  width: 85px;
  height: 38px;
  padding: 0 0 0 17px;
  border-radius: 4px;
  border: 1px solid ${color.gray_border};
  font-size: 14px;
  cursor: pointer;
`;

const MonthOption = styled.option``;

const Menu = styled.div`
  background-image: url(${menu});
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  position: relative;
  cursor: pointer;
`;

const MenuList = styled.ul`
  border: 1px solid ${color.gray_border};
  position: absolute;
  top: 27px;
  left: -12px;
  width: 167px;
  background-color: ${color.white};
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
`;

const MenuItem = styled.li`
  font-size: 14px;
  padding: 16px 25px;
  &:hover {
    opacity: 0.6;
  }
`;
