import { css, CSSObject, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';

//SP用メディアクエリ
export const pc = (
  first: CSSObject | TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
): FlattenSimpleInterpolation => css`
  @media (min-width: 1025px) {
    ${css(first, ...interpolations)}
  }
`;


//412px以用下メディアクエリ
export const minSp = (
  first: CSSObject | TemplateStringsArray,
  ...interpolations: SimpleInterpolation[]
): FlattenSimpleInterpolation => css`
  @media (min-width: 412px) {
    ${css(first, ...interpolations)}
  }
`;