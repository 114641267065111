import React from "react";
import styled from "styled-components";
import BlockHeader from "../Parts/BlockHeader";
import MainBlockContainer from "../Parts/MainBlockContainer";
import PagesList from "./PagesList";
import { Page } from "../demoData";
import { pc, minSp } from "components/mixins/mediaQuery";
import { useSelector } from "react-redux";

export interface newTypes {
  name: string;
}

interface PagesBlockProps {
  pages: Page[];
  manualId: number;
}

const PagesBlock = (props: PagesBlockProps) => {
  const { pages, manualId } = props;
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  return (
    <PagesBlockSC className={rightSectionOpen ? "open" : "close"}>
      <MainBlockContainer>
        <BlockHeader blockTitle="目次" />
        <Content>
          <PagesList pages={pages} manualId={manualId} />
        </Content>
      </MainBlockContainer>
    </PagesBlockSC>
  );
};

export default PagesBlock;

const PagesBlockSC = styled.div`
  ${minSp`
    width: 100%;
    order: 2;
  `}
  ${pc`
    width:calc(100% - 380px);
    margin-right: 20px;
    order: 1;
    &.close {
      width: 100%;
      margin-right: 0px;
    }
  `}
`;
const Content = styled.div`
  width: 100%;
  padding-bottom: 60px;
`;
