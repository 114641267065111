import axios, { AxiosResponse } from "axios";
import { env_manual_url } from "serverEnv";
import {
  GetTopicsResponseItem,
  DeleteTopicResponse,
  TopicsUpdateAllRequestBodyItem,
  TopicsUpdateAllResponse
} from "store/manual/manuals/topics/types.d";
export const getTopicsApi = (data: { token: string; type: number }) => {
  const { token, type } = data;
  try {
    const result: Promise<AxiosResponse<GetTopicsResponseItem[]>> = axios.get(
      `${env_manual_url}/topics`,
      {
        params: {
          type
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const getTopicApi = (data: { token: string; id: string }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<GetTopicsResponseItem>> = axios.get(
      `${env_manual_url}/topics/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const deleteTopicApi = (data: { token: string; id: string }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<DeleteTopicResponse>> = axios.delete(
      `${env_manual_url}/topics/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const updateTopicsAllApi = (data: {
  token: string;
  req_body: TopicsUpdateAllRequestBodyItem[];
}) => {
  try {
    const { token, req_body } = data;

    const result: Promise<AxiosResponse<TopicsUpdateAllResponse>> = axios.post(
      `${env_manual_url}/topics/all`,
      {
        topics: req_body
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};
