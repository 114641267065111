import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetResponseStatus } from "store/session/action";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function Error500() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, []);
  return (
    <Container>
      <h2>サーバーエラー</h2>
      <Description>サーバー管理者へお問合わせください</Description>
      <BackLink to="/" onClick={() => dispatch(resetResponseStatus())}>
        TOPにもどる
      </BackLink>
    </Container>
  );
}

export default Error500;

const Container = styled.div`
  padding: 30px;
`;

const Description = styled.p`
  margin-bottom: 1rem;
`;

const BackLink = styled(Link)`
  font-weight: bold;
  &:hover {
    opacity: 0.6;
  }
`;
