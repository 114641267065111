import { pc } from "components/mixins/mediaQuery";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { containerBaseStyle } from "../../mixins/common";

const BlockContainer: React.FC = ({ children }) => {
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  return (
    <Container className={rightSectionOpen ? "open" : "close"}>
      {children}
    </Container>
  );
};

export default BlockContainer;

const Container = styled.div`
  ${containerBaseStyle}
  width: 100%;
  max-width: auto;
  margin-bottom: 20px;
  order: 2;
  padding: 0px;
  ${pc`
    width: calc( 100% - 380px);
    margin-right: 20px;
    order: 1;
    padding: 30px;
    &.close {
      width: 100%;
      margin-right: 0px;
    }
  `}
  position: relative;
`;
