import React, { useMemo } from "react";
import { CheckBoxDomComponent, CheckBoxDomProps } from "./dom";

export const CheckBox = (props: CheckBoxDomProps) => {
  //-----------------------------------------------------
  // init
  //-----------------------------------------------------

  //-----------------------------------------------------
  // memo
  //-----------------------------------------------------
  const CheckBoxDom = useMemo(() => {
    return <CheckBoxDomComponent {...props} />;
  }, [props]);

  //-----------------------------------------------------
  // render
  //-----------------------------------------------------
  return CheckBoxDom;
};
