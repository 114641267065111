import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  manualPageDetailSelector,
  manualPageDetailOnEditSelector
} from "store/manual/manuals/pages/selectors";
import { setStoredPageTitleAction } from "store/manual/manuals/pages/actions";
import { useParams } from "react-router-dom";
import { manualTagsSelector } from "store/manual/tags/selector";
import { manualTopicDetailSelector } from "store/manual/manuals/topics/selectors";
import {
  manualEditModeSelector,
  pageTagsSelector
} from "store/manual/manuals/selectors";
import { togglePageTags } from "store/manual/manuals/actions";

export const useHooks = () => {
  const dispatch = useDispatch();
  const { manualId, topicId, pageId } =
    useParams<{ manualId: string; topicId: string; pageId: string }>();

  const storedPageDetailOnEditInitialState = {
    id: 1,
    topic_id: 1,
    order: 1,
    title: "",
    title_draft: "",
    content: "",
    content_draft: "",
    status: 2,
    read: false,
    released_at: "",
    updated_at: "",
    tags: []
  };

  // store
  const manualTags = useSelector(manualTagsSelector);
  const storedTopicDetail = useSelector(manualTopicDetailSelector);
  const storedPageDetail = useSelector(manualPageDetailSelector);
  const storedPageDetailOnEdit =
    useSelector(manualPageDetailOnEditSelector) ||
    storedPageDetailOnEditInitialState;
  const storedPageTags = useSelector(pageTagsSelector) || [];
  const storedTopicTitle = storedTopicDetail.name ? storedTopicDetail.name : "";

  // publishing state
  const publishedPageTitle = storedPageDetail.title
    ? storedPageDetail.title
    : "";
  const publishedPageTags = storedPageDetail.tags ? storedPageDetail.tags : [];
  const publishedPageContent = storedPageDetail.content
    ? storedPageDetail.content
    : "";
  const [pageTagIds, setPageTagIds] = React.useState<{ id: number }[]>(
    publishedPageTags.map((tag: { id: number }) => {
      return { id: tag.id };
    })
  );
  const publishedPageOrder = storedPageDetail.order
    ? storedPageDetail.order
    : 99;

  // draft state
  const draftPageTitle =
    storedPageDetailOnEdit && storedPageDetailOnEdit.title_draft
      ? storedPageDetailOnEdit.title_draft
      : "";
  const draftPageContent =
    storedPageDetailOnEdit && storedPageDetailOnEdit.content_draft
      ? storedPageDetailOnEdit.content_draft
      : "";

  // handler
  const handleToggleCheckbox = (id: number) => {
    dispatch(togglePageTags(id, manualTags));
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("hhh");
    console.log("e.target.value", e.target.value);
    dispatch(setStoredPageTitleAction(e.target.value));
  };

  const draftData = React.useMemo(() => {
    return {
      title: draftPageTitle,
      content: draftPageContent,
      manualTags: manualTags,
      selectedTagIds: storedPageTags
    };
  }, [draftPageContent, draftPageTitle, manualTags, storedPageTags]);

  const publishedData = React.useMemo(() => {
    return {
      title: publishedPageTitle,
      tags: publishedPageTags,
      content: publishedPageContent,
      order: publishedPageOrder
    };
  }, [
    publishedPageContent,
    publishedPageTags,
    publishedPageTitle,
    publishedPageOrder
  ]);

  const data = React.useMemo(() => {
    return {
      pageTagIds
    };
  }, [pageTagIds]);

  return {
    storedPageDetail,
    storedPageDetailOnEdit,
    pageId,
    topicId,
    manualId,
    storedTopicTitle,
    publishedPageTags,
    manualTags,
    pageTagIds,
    handleToggleCheckbox,
    handleChangeTitle,
    publishedData,
    draftData,
    data,
    storedPageTags
  };
};
