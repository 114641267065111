import React from "react";
import styled from "styled-components";
import ModuleContainer from "../../Parts/ModuleContainer";
import { Index } from "../../demoData";
import Topics from "./Topics";
import useHooks from "./Hooks";

const IndexBlock = (props: { index: Index }) => {
  //  hooks
  const { index } = props;
  const { pageId } = useHooks();

  return (
    <ModuleContainer>
      <BlockHeadingSC>インデックス</BlockHeadingSC>
      {index &&
        index.topics &&
        index.topics.map(val => {
          return <Topics key={val.id} topic={val} pageId={pageId} />;
        })}
    </ModuleContainer>
  );
};

export default IndexBlock;

const BlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  padding-right: 10px;
  margin-bottom: 30px;
`;
