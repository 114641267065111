import React, { useState } from "react";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color } from "../../../mixins";
import searchOpenIcon from "../../../../images/icons/down_arrow_green.svg";
import searchIcon from "../../../../images/icons/search.svg";
import { useSelector, useDispatch } from "react-redux";
import { getArticlesStocksRequest } from "../../../../store/faq/articles_stocked/action";
import { setKeyword } from "../../../../store/common/paginate/action";
import { animateScroll as scroll } from "react-scroll";
import { toggleSearchArticleIsOpened } from "store/session/action";

function ArticlesSearchBlock() {
  const dispatch = useDispatch();

  // PC時はRedux Stateから
  const searchIsOpened = useSelector(
    (state: any) => state.session.searchArticleIsOpened
  );

  // SP時はコンポーネントステートから
  const [searchOpen, setSearchOpen] = React.useState<boolean>(false);

  const token = useSelector((state: any) => state.session.token);
  const user_id = useSelector((state: any) => state.session.id);
  const keyword = useSelector(
    (state: any) => state.paginate.article_stock_keyword
  );
  const paginate_current_page = useSelector(
    (state: any) => state.paginate.article_stock_current_page
  );
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.article_stock_per_page
  );
  // 検索キーワード入力
  const handleKeywordInput = (e: any) => {
    dispatch(setKeyword(e.target.value, "article_stock"));
  };

  // 検索キーワード検索実施
  const submitSearch = (e: any) => {
    e.preventDefault();
    scroll.scrollToTop({ duration: 200 });
    dispatch(
      getArticlesStocksRequest(token, user_id, 1, paginate_per_page, keyword)
    );
  };

  window.onscroll = function () {
    const searchBlock = document.getElementById("article_search_block");

    if (document.documentElement.scrollTop > 67) {
      if (searchBlock) {
        searchBlock.classList.add("fixed");
      }
    } else {
      if (searchBlock) {
        searchBlock.classList.remove("fixed");
      }
    }
  };

  const handleToggleOpen = () => {
    if (window.innerWidth > 1024) {
      dispatch(toggleSearchArticleIsOpened(searchIsOpened));
    } else {
      setSearchOpen(!searchOpen);
    }
  };

  return (
    <ArticlesSearchBlockSC id="article_search_block">
      <ArticlesSearchBlockHeaderSC onClick={handleToggleOpen}>
        <ArticlesSearchBlockHeadingSC>検索条件</ArticlesSearchBlockHeadingSC>
        <ToggleArrowSC
          className={
            window.innerWidth > 1024
              ? searchIsOpened
                ? "open"
                : ""
              : searchOpen
              ? "open"
              : ""
          }
          src={searchOpenIcon}
          alt="toggleArrow"
        />

        {keyword && keyword !== "" && <SPSearchingIndicator />}
      </ArticlesSearchBlockHeaderSC>

      {/* 検索条件 */}
      <SearchForm
        className={
          window.innerWidth > 1024
            ? searchIsOpened
              ? "open"
              : ""
            : searchOpen
            ? "open"
            : ""
        }
      >
        <SearchContentsSC>
          {/* キーワード */}
          <SearchContentsLabelSC>キーワード</SearchContentsLabelSC>
          <dd>
            <KeywordInput
              type="text"
              placeholder="キーワードを入力"
              value={keyword}
              onChange={handleKeywordInput}
            />
          </dd>
        </SearchContentsSC>
        <SearchButton onClick={submitSearch}>
          <img src={searchIcon} alt="search icon" />
          この条件で検索
        </SearchButton>
      </SearchForm>
    </ArticlesSearchBlockSC>
  );
}

export default ArticlesSearchBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const ArticlesSearchBlockSC = styled.div`
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  color: ${color.primary};
  ${pc`
    max-width: 360px;
    width: 34.5%;
    margin-bottom: 0;
    padding: 30px;
    order: 2;
    position: static;
  `}
  &.fixed {
    position: fixed;
    top: 56px;
    width: 100%;
    border-radius: 6px;
    z-index: 99;
    ${pc`
    top: 67px;
    left: calc((100% - 30px) - (100% - 260px)*0.345);;
    width: calc((100% - 260px) * 0.345);
    `}
    @media(min-width: 1527px) {
      left: 1050px;
    }
  }
`;

const ArticlesSearchBlockHeaderSC = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArticlesSearchBlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;
// =====================================
// SerchForm  ==========================
const SearchForm = styled.form`
  margin-top: 20px;
  display: none;
  max-height: calc(100vh - 242px);
  overflow: auto;
  &.open {
    display: block;
  }
`;

const SearchContentsSC = styled.dl`
  margin-bottom: 8px;
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.gray};
  margin-bottom: 12px;
`;

const KeywordInput = styled.input`
  display: block;
  width: 100%;
  padding: 0px 0px 12px 6px;
  border-bottom: 1px solid ${color.divide_border};
  margin-bottom: 20px;
  &::placeholder {
    font-size: 12px;
    color: ${color.category_bg};
  }
`;

const CategoriesList = styled.ul`
  display: inline-block;
`;
const CategoriesItem = styled.li`
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 20px;
`;

const TagsList = styled(CategoriesList)``;
const TagsItem = styled(CategoriesItem)``;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
const SearchButton = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: ${color.white};
  background-color: ${color.primary};
  font-weight: bold;
  padding: 11px 9px;
  font-size: 14px;
  border-radius: 4px;
  img {
    transform: translateY(-1px);
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
  ${pc`
    padding: 9px;
    font-size: 12px;
  `}
`;
// SerchForm  ==========================
//======================================

const SPSearchingIndicator = styled.span`
  content: "";
  position: absolute;
  top: 5px;
  right: 10px;
  background: ${color.primary};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
