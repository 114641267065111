import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";

import CSVDownloadRejectModalContent from "./CSVDownloadRejectModalContent";
import { toggleCSVDownloadRejectModal } from "../../../../store/common/modal/action";

export default function AnnualEventScheduleModal() {
  const dispatch = useDispatch();

  return (
    <>
      <CSVDownloadRejectModalBg
        onClick={() => dispatch(toggleCSVDownloadRejectModal(false))}
      ></CSVDownloadRejectModalBg>
      <div>
        <CSVDownloadRejectModalContent />
      </div>
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const CSVDownloadRejectModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
