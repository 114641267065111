import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";

import ArticlesSearchBlock from "./ArticlesSearchBlock";
import PageHeading from "../../Common/PageHeading";
import ArticlesListBlock from "./ArticlesListBlock";
import {
  resetTempEditData,
  setEditorIsToggled
} from "../../../store/faq/article_edit/action";
import { toggleRightSection } from "../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../styles/layout/containers";

function Articles() {
  const dispatch = useDispatch();
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    dispatch(setEditorIsToggled(false));
    dispatch(resetTempEditData());
  }, [dispatch]);
  return (
    <MainSC>
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <PageHeader>
        <PageHeading title={pathName === "/" ? "FAQ" : "FAQ記事一覧"} />
      </PageHeader>

      <ArticlesSearchBlock />
      <ArticlesListBlock />
    </MainSC>
  );
}

export default Articles;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: block;
  padding: 30px 12px 130px;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    padding: 30px 30px 60px;
  `}
`;

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
