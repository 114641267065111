import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PageHeading from "../../../Common/PageHeading";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import AerticleSearchBlock from "../ArticlesSearchBlockUser";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import {
  getArticleRequest,
  postArticleReadRequest
} from "../../../../store/faq/article_detail/action";
import {
  postArticleStockRequest,
  deleteArticleStockRequest,
  toggleArticleDetailContentLoaded
} from "../../../../store/faq/article_detail/action";
import tag from "../../../../images/icons/tag.svg";
import inquiry from "../../../../images/icons/mail_gray.svg";
import clock from "../../../../images/icons/clock.svg";
import unstocked from "../../../../images/icons/bookmark_unstocked.svg";
import stocked from "../../../../images/icons/bookmark_stocked.svg";
import WysiwigArticleDetail from "./WysiwygArticleDetailOriginal";
import { animateScroll as scroll } from "react-scroll";
import ArticlesStockUpdatedAtSC from "../ArticlesStock/ArticlesStockUpdatedAtSC";

function ArticlesDetailEdit(props: any) {
  const dispatch = useDispatch();

  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  const articles = useSelector((state: any) => state.articles);
  const article = useSelector((state: any) => state.article_detail);
  const message = useSelector((state: any) => state.article_detail.message);

  const contentLoaded = useSelector(
    (state: any) => state.article_detail.contentLoaded
  );

  const token = useSelector((state: any) => state.session.token);
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const stocks = useSelector((state: any) => state.articles_stocked);
  const location = useLocation();
  const pathName = location.pathname;
  const pathInfo = pathName.split("/");
  const fileName = Number(pathInfo.pop());

  const isStocked = useSelector((state: any) => state.article_detail.stocked);

  const title = article ? article.title : "";
  const content = article ? article.content : "";
  const categories = article ? article.categories : [];
  const tags = article ? article.tags : [];
  const updated_at = article ? article.updated_at : "";

  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  // ストック記事に追加
  const handleClick = (e: any) => {
    e.preventDefault();
    console.log("isStocked", isStocked);
    if (isStocked) {
      dispatch(deleteArticleStockRequest(token, article.id));
    } else {
      dispatch(postArticleStockRequest(token, article.id));
    }
  };

  useEffect(() => {
    scroll.scrollToTop({ duration: 200 });
  }, []);

  useEffect(() => {
    dispatch(toggleArticleDetailContentLoaded(false)); // 記事取得前に初期化
    dispatch(getArticleRequest(token, fileName, stocks));
    dispatch(postArticleReadRequest(token, fileName));
  }, [dispatch, token, fileName]);

  console.log("message text:", message);

  return (
    <MainSC>
      <PageHeading title="FAQ詳細" />
      <PageBreadcrumb
        firstLink="/articles"
        firstTitle="FAQ一覧"
        secondTitle={title}
      />

      <ArticleDetailBlockSC>
        {message === "" ? (
          <>
            <DetailHeaderSC>
              <HeaderUpsideSC>
                {/* カテゴリー一覧 */}
                <CategoryListSC>
                  {categories.map((category: any) => {
                    return <CategoryItemSC>{category.name}</CategoryItemSC>;
                  })}
                </CategoryListSC>

                {/* 更新日時 */}
                <UpdatedAtSC>
                  <ArticlesStockUpdatedAtSC updated_at={updated_at} />
                </UpdatedAtSC>
              </HeaderUpsideSC>

              {/* タイトル */}
              <ArticleTitleSC>{title}</ArticleTitleSC>

              {/* タグ */}
              <TagListSC>
                {tags.map((tag: any) => {
                  return <TagItemSC>{tag.name}</TagItemSC>;
                })}
              </TagListSC>

              {/* 更新日時 */}
              <UpdatedAtSpSC>
                <ArticlesStockUpdatedAtSC updated_at={updated_at} />
              </UpdatedAtSpSC>

              {/* ストック記事ボタン */}
              <BookmarkButtonSC onClick={handleClick}>
                {isStocked ? (
                  <img src={stocked} alt="" />
                ) : (
                  <img src={unstocked} alt="" />
                )}
              </BookmarkButtonSC>
            </DetailHeaderSC>

            {/* コンテンツ */}
            <DetailContentsSC>
              {contentLoaded &&
                article.content &&
                typeof article.content === "string" && (
                  <WysiwigArticleDetail content={content} />
                )}
            </DetailContentsSC>
          </>
        ) : (
          <ArticleDetailNotFoundBlock>
            該当の記事はありません。
            <NotFoundLinkBlock>
              <NotFoundLink to="/">TOPへもどる</NotFoundLink>
            </NotFoundLinkBlock>
          </ArticleDetailNotFoundBlock>
        )}
      </ArticleDetailBlockSC>

      <AerticleSearchBlock />

      <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
        <ButtonAreaDeskSC>解決できない場合は</ButtonAreaDeskSC>
        <LinkToInquirySC className="active">
          <Link to="/inquiries/new">
            <img src={inquiry} alt="" />
            お問合せ
          </Link>
        </LinkToInquirySC>
      </FixedButtonAreaSC>
    </MainSC>
  );
}

export default ArticlesDetailEdit;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
`;

const ArticleDetailBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    margin-bottom: 0;
    padding: 30px;
  `}
`;

const DetailHeaderSC = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  ${pc`
    padding: 30px;
  `}
`;

const HeaderUpsideSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CategoryListSC = styled.ul`
  display: inline-block;
`;

const CategoryItemSC = styled.li`
  display: inline-block;
  color: ${color.white};
  background-color: ${color.category_bg};
  font-size: 12px;
  padding: 4px 11px;
  border-radius: 10px;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 0;
  ${pc`
    margin-bottom: 5px;
  `}
`;

const UpdatedAtSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  text-align: right;
  padding-left: 20px;
  white-space: nowrap;
  display: none;
  ${pc`
    display: block;
  `}
`;
const UpdatedAtSpSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  display: block;
  ${pc`
    display: none;
  `}
`;

const ClockSC = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 6px;
`;

const TagListSC = styled.ul`
  display: flex;
  margin-bottom: 16px;
  ${pc`
    margin-bottom: 0;
  `}
`;

const TagItemSC = styled.li`
  display: inline-block;
  color: ${color.gray};
  font-size: 12px;
  border-radius: 10px;
  margin-right: 8px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background-image: url(${tag});
    background-repeat: no-repeat;
    background-position: left center;
    margin-right: 3px;
  }
`;

const BookmarkButtonSC = styled.button`
  position: absolute;
  top: 25px;
  right: 16px;
  ${pc`
    top: auto;
    bottom: 37px;
    right: 39px;
  `}
`;

const ArticleTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
`;
// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  color: ${color.gray};
  ${pc`
  display: flex;
  justify-content: center;
  align-items: center;
  left: auto;
  margin-left: -30px;
  width: calc(100vw - 200px);
  height: 70px;
  padding: 16px 0;
  box-shadow: none;
`}
`;

const ButtonAreaDeskSC = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const LinkToInquirySC = styled.div`
  ${buttonBaseStyle}
  margin-right: 12px;
  color: ${color.primary};
  border: 1px solid ${color.primary};
  background-color: white;
  margin: 0 auto;
  ${pc`
    display: inline-block;
    margin: 0;
  `}
  a {
    display: block;
    padding: 13px 32px;
    ${pc`
      padding: 10px 32px;
    `}
  }
  img {
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: -1px;
  }
`;

const ArticleDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;

const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
