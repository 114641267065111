import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import { store, persistor, sagaMiddleware, history } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import rootSaga from "./saga";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { sentry_dns } from "./serverEnv";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

sagaMiddleware.run(rootSaga);

Sentry.init({
  dsn: sentry_dns,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
