import React from "react";
import BlockContainer from "../../Parts/BlockContainer";
import { PageLayout } from "../../Parts/MainLayout";
import styled from "styled-components";
import color from "../../../mixins/color";
import TagsList from "./TagsList";
import FixedActionButton from "../../Parts/FixedActionButton";
import useHooks from "./Hooks";

const Tags = () => {
  const { data, handlers, handleClickUpdateBtn } = useHooks();
  return (
    <PageLayout pageTitle="マニュアルタグ設定">
      <BlockContainer>
        <TagsDesc>
          ※並び順は1~99999の整数で指定してください。
          <br />
          ※カテゴリ及びタグは並び順の昇順で表示され、並び順が同じものは登録順で表示されます。
        </TagsDesc>
        <TagsList data={data} handlers={handlers} />
      </BlockContainer>
      <FixedActionButton
        description=""
        buttonText="更新"
        buttonClick={handleClickUpdateBtn}
      />
    </PageLayout>
  );
};

export default Tags;

const TagsDesc = styled.p`
  font-size: 12px;
  color: ${color.light_gray};
  margin-bottom: 10px;
`;
