import React from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { toggleServiceModal } from "../../../../store/common/modal/action";

export default function ServiceItem(props: any) {
  const dispatch = useDispatch();
  return (
    <ServiceItemSC>
      <ServiceItemLink
        to={props.to}
        onClick={() => dispatch(toggleServiceModal(false))}
      >
        {props.title}
      </ServiceItemLink>
    </ServiceItemSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ServiceItemSC = styled.li`
  max-width: 240px;
  width: calc(33% - 6px);
  height: 38px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #444444;
  font-size: 14px;
  font-weight: bold;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  text-align: center;
  color: #444444;
`;

const ServiceItemLink = styled(NavLink)`
  display: block;
  padding: 10px 0;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
