import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, inputBaseStyle } from "../../../mixins";
import {
  toggleNewArticleCategoryCheck,
  toggleNewArticleTagCheck,
  inputNewArticleTitle,
  selectNewArticleStatus,
} from "../../../../store/faq/article_new/action";

import WysiwygArticleNew from "./WysiwygArticleNewNew";

function ArticlesEditor() {
  const dispatch = useDispatch();
  const title = useSelector((state: any) => state.article_new.title);
  const content = useSelector((state: any) => state.article_new.content);
  const search_string = useSelector(
    (state: any) => state.article_new.search_string
  );
  const status = useSelector((state: any) => state.article_new.status);
  const user_id = useSelector((state: any) => state.session.id);

  const registered_categories = useSelector((state: any) => state.categories);
  const registered_tags = useSelector((state: any) => state.tags);

  const new_categories = useSelector(
    (state: any) => state.article_new.categories
  );
  const new_tags = useSelector((state: any) => state.article_new.tags);

  // 新規articleのタイトル管理
  const handleNewArticleTitle = (e: any) => {
    dispatch(inputNewArticleTitle(e.target.value));
  };

  // カテゴリーの判定
  const handleNewArticleCategoryCheck = (e: any) => {
    const target_category = registered_categories.find(
      (category: any) => category.id === Number(e.target.value)
    );
    dispatch(
      toggleNewArticleCategoryCheck(Number(e.target.value), target_category)
    );
  };

  // タグの判定
  const handleNewArticleTagCheck = (e: any) => {
    const target_tag = registered_tags.find(
      (tag: any) => tag.id === Number(e.target.value)
    );
    dispatch(toggleNewArticleTagCheck(Number(e.target.value), target_tag));
  };

  // ステータスの判定
  const handleNewArticleStatusChange = (e: any) => {
    console.log("status consoling...", e.target.value);
    dispatch(selectNewArticleStatus(Number(e.target.value)));
  };

  return (
    <ArticlesEditorSC>
      <TitleBlock>
        <TitleLabel>記事タイトル*</TitleLabel>
        <TitleEditorBlock>
          <TitleEditorInput value={title} onChange={handleNewArticleTitle} />
        </TitleEditorBlock>
      </TitleBlock>

      <BodyBlock>
        <BodyLabel>記事本文</BodyLabel>
        <BodyEditorBlock>
          <WysiwygArticleNew content={content} />
        </BodyEditorBlock>
      </BodyBlock>

      {/* カテゴリ */}
      <CategoryBlock>
        <CategoryLabel>カテゴリ</CategoryLabel>
        <CategoryEditorBlock>
          <CategoriesList>
            {registered_categories.map((category: any) => {
              return (
                <CategoriesItem>
                  <CheckboxLabel htmlFor={`category_${category.id}`}>
                    {
                      // 新規カテゴリ作成でチェック済み
                      new_categories.some(
                        (nc: any) => nc.id === category.id
                      ) ? (
                        <Checkbox
                          className="checkbox"
                          id={`category_${category.id}`}
                          type="checkbox"
                          value={`${category.id}`}
                          onChange={handleNewArticleCategoryCheck}
                          checked={true}
                        />
                      ) : (
                        <Checkbox
                          className="checkbox"
                          id={`category_${category.id}`}
                          type="checkbox"
                          value={`${category.id}`}
                          onChange={handleNewArticleCategoryCheck}
                        />
                      )
                    }

                    <CheckboxSpan className="label">
                      {category.name}(
                      {category.articles_count ? category.articles_count : 0})
                    </CheckboxSpan>
                  </CheckboxLabel>
                </CategoriesItem>
              );
            })}
          </CategoriesList>
        </CategoryEditorBlock>
      </CategoryBlock>

      {/* タグ */}
      <TagBlock>
        <TagLabel>タグ</TagLabel>
        <TagEditorBlock>
          <TagsList>
            {registered_tags.map((tag: any) => {
              return (
                <TagsItem>
                  <CheckboxLabel htmlFor={`tag_${tag.id}`}>
                    {
                      // 新規タグ作成でチェック済み
                      new_tags.some((nt: any) => nt.id === tag.id) ? (
                        <Checkbox
                          className="checkbox"
                          id={`tag_${tag.id}`}
                          type="checkbox"
                          value={`${tag.id}`}
                          onChange={handleNewArticleTagCheck}
                          checked={true}
                        />
                      ) : (
                        <Checkbox
                          className="checkbox"
                          id={`tag_${tag.id}`}
                          type="checkbox"
                          value={`${tag.id}`}
                          onChange={handleNewArticleTagCheck}
                        />
                      )
                    }

                    <CheckboxSpan className="label">
                      {tag.name}({tag.articles_count ? tag.articles_count : 0})
                    </CheckboxSpan>
                  </CheckboxLabel>
                </TagsItem>
              );
            })}
          </TagsList>
        </TagEditorBlock>
      </TagBlock>

      {/* ステータス */}
      <StatusBlock>
        <StatusLabel>ステータス*</StatusLabel>
        <StatusEditorBlock>
          <StatusEditorInput
            className="select"
            value={status}
            onChange={handleNewArticleStatusChange}
          >
            <option value={2}>公開</option>
            <option value={1}>非公開</option>
            <option value={3}>下書き</option>
          </StatusEditorInput>
        </StatusEditorBlock>
      </StatusBlock>
    </ArticlesEditorSC>
  );
}

export default ArticlesEditor;
// ===================================================================================
// スタイル
// ===================================================================================
const ArticlesEditorSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

// Title ============================
const TitleBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
    align-items: center;
  `}
`;

const TitleEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const TitleLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const TitleEditorInput = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;
// ==================================

// Body =============================
const BodyBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
  display: flex;
  `}
`;

const BodyEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const BodyLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;
// ==================================

// category ============================
const CategoryBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
`;
const CategoryEditorBlock = styled.div`
  width: 100%;
  max-width: 610px;
`;
const CategoryLabel = styled.label`
  display: block;
  width: 90px;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
`;

const CategoriesList = styled.ul``;
const CategoriesItem = styled.li`
  display: inline-block;
  margin-right: 20px;
`;
// =======================================

// Tag ===================================
const TagBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
`;
const TagLabel = styled.label`
  display: block;
  width: 90px;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
`;

const TagEditorBlock = styled.div`
  width: 100%;
  max-width: 610px;
`;

const TagsList = styled(CategoriesList)``;
const TagsItem = styled(CategoriesItem)``;

// ========================================

// Status =================================
const StatusLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const StatusEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const StatusEditorInput = styled.select`
  ${inputBaseStyle}
  width: 240px;
`;

const StatusBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  ${pc`
    display: flex;
    align-items: center;
  `}
`;
// ===========================================

// checkBox ==================================
const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
