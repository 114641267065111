import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GET_CAMERA_CLASSES_REQUESTED,
  GET_CAMERA_CLASSES_SUCEEDED
} from "store/camera/classes/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { getCameraClassesApi } from "../api/classes";

function* getCameraClasses(action: any): Generator<any, any, any> {
  try {
    const result = yield call(getCameraClassesApi, action.token);
    yield put({
      type: GET_CAMERA_CLASSES_SUCEEDED,
      classes: result.data.classes
    });
  } catch (e) {
    const error_code = e.responce.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const cameraClassesSaga = [
  takeLatest(GET_CAMERA_CLASSES_REQUESTED, getCameraClasses)
];

export default cameraClassesSaga;
