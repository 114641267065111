import React from "react";
import styled from "styled-components";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import { SortableContainer, arrayMove } from "react-sortable-hoc";
import TopicsSortableItem from "./TopicsSortableItem";
import { color } from "components/mixins";

interface Props {
  hookedTopics: TopicItem[];
  changeTopicsOrder: (orderChangedTopics: TopicItem[]) => void;
}

function TopicsSortableList(props: Props) {
  const { hookedTopics, changeTopicsOrder } = props;

  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    changeTopicsOrder(arrayMove(hookedTopics, oldIndex, newIndex));
  };

  const SortableContainerWrapper = SortableContainer(
    ({ children }: { children: any }) => {
      return <TopicsAdminListSC>{children}</TopicsAdminListSC>;
    }
  );
  return (
    <>
      <SortableContainerWrapper useDragHandle onSortEnd={onSortEnd}>
        {hookedTopics.map((topic: any, index: number) => {
          return (
            <TopicsSortableItem
              index={index}
              id={topic.id}
              title={topic.name}
            />
          );
        })}
      </SortableContainerWrapper>
    </>
  );
}

export default TopicsSortableList;

const TopicsAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;
