import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";

interface Props {
  blockTitle: string;
}

const TopicBlockHeader = (props: Props) => {
  return <TopicBlockHeaderSC>{props.blockTitle}</TopicBlockHeaderSC>;
};

export default TopicBlockHeader;

const TopicBlockHeaderSC = styled.h2`
  color: ${color.black};
  font-size: 21px;
  margin-bottom: 20px;
  padding-left: 10px;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: ${color.light_gray};
    width: 3px;
    height: 100%;
  }
`;
