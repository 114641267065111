import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../mixins/mediaQuery";
import { color } from "../mixins";
import searchOpen from "../../images/icons/down_arrow_gray.svg";
import searchIcon from "../../images/icons/search.svg";
import pulldown_arrow from "../../images/icons/pulldown_arrow.svg";

import { toggleSearchArticleIsOpened } from "../../store/session/action";
import {
  // setSelectedYear,
  setFilteredYear,
  toggleMonthToBeSearchedCheck,
  toggleMonthToBeSearchedCheckAll,
  setDateList,
  toggleClassesToBeSearchedCheck,
  toggleClassesToBeSearchedCheckAll,
  toggleRejectToBeSearchedCheck,
  handleClearEventSearchTerms,
  SetIsSearchByEventType
} from "../../store/event/event_search/action";
import { getEventListRequest } from "../../store/event/events/action";

import { animateScroll as scroll } from "react-scroll";
import {
  resetRejectComment,
  resetSelectedMonth,
  resetToBeRejected
} from "../../store/event/approval/action";
import { getMonthlyEventsRequest } from "../../store/event/monthly_events/action";
import { SearchEventTypeSelect } from "./SerchEventTypeSelect";
import { useEventYear } from "utils/eventYear";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function EventsSearchBlock() {
  const dispatch = useDispatch();
  const { years } = useEventYear();
  const searchIsOpened = useSelector(
    (state: any) => state.session.searchArticleIsOpened
  );
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);

  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const filtered_class = useSelector(
    (state: any) => state.event_search.filtered_class
  );
  const filtered_remand = useSelector(
    (state: any) => state.event_search.filtered_remand
  );
  const type_ids = useSelector((state: any) => state.event_search.type_ids);

  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  // 検索実施
  const submitSearch = (e: any) => {
    e.preventDefault();
    scroll.scrollToTop({ duration: 200 });
    dispatch(resetSelectedMonth());
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));
    if (
      filtered_months.some((month: any) => month === "すべて") &&
      !filtered_class.some((selected_class: any) => selected_class === "すべて")
    ) {
      dispatch(
        getEventListRequest(
          token,
          facility_id,
          filtered_year,
          ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
          filtered_class,
          filtered_remand,
          type_ids
        )
      );
      dispatch(
        getMonthlyEventsRequest(
          token,
          facility_id,
          filtered_year,
          ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
          filtered_class,
          filtered_remand
        )
      );
      dispatch(setDateList());
      dispatch(SetIsSearchByEventType(type_ids.length));
    } else if (
      !filtered_months.some((month: any) => month === "すべて") &&
      filtered_class.some((selected_class: any) => selected_class === "すべて")
    ) {
      dispatch(
        getEventListRequest(
          token,
          facility_id,
          filtered_year,
          filtered_months,
          ["0", "1", "2", "3", "4", "5"],
          filtered_remand,
          type_ids
        )
      );
      dispatch(
        getMonthlyEventsRequest(
          token,
          facility_id,
          filtered_year,
          ["0", "1", "2", "3", "4", "5"],
          filtered_class,
          filtered_remand
        )
      );
      dispatch(setDateList());
      dispatch(SetIsSearchByEventType(type_ids.length));
    } else if (
      filtered_months.some((month: any) => month === "すべて") &&
      filtered_class.some((selected_class: any) => selected_class === "すべて")
    ) {
      dispatch(
        getEventListRequest(
          token,
          facility_id,
          filtered_year,
          ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
          ["0", "1", "2", "3", "4", "5"],
          filtered_remand,
          type_ids
        )
      );
      dispatch(
        getMonthlyEventsRequest(
          token,
          facility_id,
          filtered_year,
          ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
          ["0", "1", "2", "3", "4", "5"],
          filtered_remand
        )
      );
      dispatch(setDateList());
      dispatch(SetIsSearchByEventType(type_ids.length));
    } else {
      dispatch(
        getEventListRequest(
          token,
          facility_id,
          filtered_year,
          filtered_months,
          filtered_class,
          filtered_remand,
          type_ids
        )
      );
      dispatch(
        getMonthlyEventsRequest(
          token,
          facility_id,
          filtered_year,
          filtered_months,
          filtered_class,
          filtered_remand
        )
      );
      dispatch(setDateList());
      dispatch(SetIsSearchByEventType(type_ids));
    }
    dispatch(resetRejectComment());
    dispatch(resetToBeRejected());
  };

  const handleYearSelect = (e: any) => {
    console.log(e.target.value);
    // const selected_year = e.target.value.slice(0, 4);
    // dispatch(setSelectedYear(Number(selected_year)));
    dispatch(setFilteredYear(e.target.value));
  };

  const handleMonthCheck = (e: any) => {
    dispatch(toggleMonthToBeSearchedCheck(e.target.value));
    if (filtered_months.some((month: any) => month === "すべて")) {
      dispatch(toggleMonthToBeSearchedCheck("すべて"));
    }
  };

  const handleMonthCheckAll = (e: any) => {
    dispatch(toggleMonthToBeSearchedCheckAll());
  };

  const handleClassesCheck = (e: any) => {
    dispatch(toggleClassesToBeSearchedCheck(e.target.value));
    if (
      filtered_class.some((selected_class: any) => selected_class === "すべて")
    ) {
      dispatch(toggleClassesToBeSearchedCheck("すべて"));
    }
  };

  const handleClassesCheckAll = (e: any) => {
    dispatch(toggleClassesToBeSearchedCheckAll());
  };

  const handleRemandCheck = (e: any) => {
    dispatch(toggleRejectToBeSearchedCheck(e.target.value));
  };

  const handleClear = (e: any, target_term: string) => {
    e.preventDefault();
    dispatch(handleClearEventSearchTerms(target_term));
  };

  const months = [
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "1",
    "2",
    "3"
  ];
  const classes = [0, 1, 2, 3, 4, 5];

  window.onscroll = function () {
    const searchBlock = document.getElementById("event_search_block");

    if (document.documentElement.scrollTop > 181) {
      if (searchBlock) {
        searchBlock.classList.add("fixed");
      }
    } else {
      if (searchBlock) {
        searchBlock.classList.remove("fixed");
      }
    }
  };

  return (
    <EventsSearchBlockSC
      id="event_search_block"
      className={rightSectionOpen ? "open" : "close"}
    >
      <EventsSearchBlockHeaderSC
        onClick={() => dispatch(toggleSearchArticleIsOpened(searchIsOpened))}
      >
        <EventsSearchBlockHeadingSC>絞り込み条件</EventsSearchBlockHeadingSC>
        <ToggleArrowSC
          className={searchIsOpened ? "open" : ""}
          src={searchOpen}
          alt="toggleArrow"
        />
      </EventsSearchBlockHeaderSC>

      {/* 検索条件 */}
      <SearchForm className={searchIsOpened ? "open" : ""} action="">
        <SearchContentsSC>
          {/* 年度 */}
          <SearchContentsLabelSC>年度</SearchContentsLabelSC>
          <SelectLabel onChange={handleYearSelect} value={filtered_year}>
            {years.map((year: number) => (
              <option key={year} value={year}>{`${year}年度`}</option>
            ))}
          </SelectLabel>
        </SearchContentsSC>
        <SearchContentsSC>
          {/* 行事名 */}
          <SearchContentsLabelSC>行事名</SearchContentsLabelSC>
          <SearchEventTypeSelect />
        </SearchContentsSC>
        <SearchContentsSC>
          <SearchContentsLabelSC>月</SearchContentsLabelSC>
          <ClearButton onClick={(e: any) => handleClear(e, "month")}>
            クリア
          </ClearButton>
          <CheckboxList>
            {months.map((month: string, month_index: number) => (
              <CheckboxItem key={`month_index_${month_index}`}>
                <CheckboxLabel>
                  <Checkbox
                    onChange={handleMonthCheck}
                    className="checkbox"
                    id={`month_${month}`}
                    type="checkbox"
                    name="month"
                    value={month}
                    checked={filtered_months.some(
                      (selected_month: string) => selected_month === month
                    )}
                  />
                  <CheckboxSpan className="label">{`${month}月`}</CheckboxSpan>
                </CheckboxLabel>
              </CheckboxItem>
            ))}
            <CheckboxItem key="month_checkAll">
              <CheckboxLabel>
                <Checkbox
                  onChange={handleMonthCheckAll}
                  className="checkbox"
                  id="month_all"
                  type="checkbox"
                  name="month"
                  value="すべて"
                  checked={filtered_months.some(
                    (selected_month: any) => selected_month === "すべて"
                  )}
                />
                <CheckboxSpan className="label">すべて</CheckboxSpan>
              </CheckboxLabel>
            </CheckboxItem>
          </CheckboxList>
        </SearchContentsSC>

        <SearchContentsSC>
          <SearchContentsLabelSC>クラス</SearchContentsLabelSC>
          <ClearButton onClick={(e: any) => handleClear(e, "class_age")}>
            クリア
          </ClearButton>
          <CheckboxList>
            {classes.map((class_item: any, class_index: number) => {
              return (
                <CheckboxItem key={`class_index_${class_index}`}>
                  <CheckboxLabel>
                    <Checkbox
                      onChange={handleClassesCheck}
                      className="checkbox"
                      id={`class_${class_item}`}
                      type="checkbox"
                      value={class_item}
                      name="classes"
                      checked={filtered_class.some(
                        (selected_class: any) =>
                          Number(selected_class) === class_item
                      )}
                    />
                    <CheckboxSpan className="label">
                      {`${class_item}歳児`}
                    </CheckboxSpan>
                  </CheckboxLabel>
                </CheckboxItem>
              );
            })}
            <CheckboxItem key="class_checkAll" className="checkAll">
              <CheckboxLabel>
                <Checkbox
                  onChange={handleClassesCheckAll}
                  className="checkbox"
                  id="class_すべて"
                  type="checkbox"
                  value="すべて"
                  name="classes"
                  checked={filtered_class.some(
                    (selected_class: any) => selected_class === "すべて"
                  )}
                />
                <CheckboxSpan className="label">すべて</CheckboxSpan>
              </CheckboxLabel>
            </CheckboxItem>
          </CheckboxList>
        </SearchContentsSC>

        <SearchContentsSC>
          <SearchContentsLabelSC>差戻し</SearchContentsLabelSC>
          <RadioButtonList>
            <RadioButtonItem>
              <RadioButton
                type="radio"
                id="あり"
                value="1"
                name="remand"
                onChange={handleRemandCheck}
                checked={filtered_remand === "1"}
              />
              <RadioButtonLabel htmlFor="あり">あり</RadioButtonLabel>
            </RadioButtonItem>
            <RadioButtonItem>
              <RadioButton
                type="radio"
                id="なし"
                value="0"
                name="remand"
                onChange={handleRemandCheck}
                checked={filtered_remand === "0"}
              />
              <RadioButtonLabel htmlFor="なし">なし</RadioButtonLabel>
            </RadioButtonItem>
            <RadioButtonItem>
              <RadioButton
                type="radio"
                id="すべて"
                value=""
                name="remand"
                onChange={handleRemandCheck}
                checked={filtered_remand === ""}
              />
              <RadioButtonLabel htmlFor="すべて">すべて</RadioButtonLabel>
            </RadioButtonItem>
          </RadioButtonList>
        </SearchContentsSC>

        <SearchButton onClick={submitSearch}>
          <img src={searchIcon} alt="search icon" />
          この条件で絞り込み
        </SearchButton>
      </SearchForm>
    </EventsSearchBlockSC>
  );
}

export default EventsSearchBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const EventsSearchBlockSC = styled.div`
  max-width: 370px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  &.close {
    display: none;
  }
  ${pc`
    max-width: 370px;
    width: 34.5%;
    margin-bottom: 0;
    padding: 30px;
    order: 2;
  `}
  &.fixed {
    display: block;
    position: fixed;
    top: 67px;
    right: 40px;
    width: calc((100% - 260px) * 0.345);
    border-radius: 6px;
    z-index: 99;
    @media (min-width: 1527px) {
      left: calc(100vw - 400px);
    }
    & + div {
      top: 68px;
    }

    form {
      max-height: calc(100vh - 220px);
    }
    &.close {
      display: none;
    }
  }
`;

const EventsSearchBlockHeaderSC = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const EventsSearchBlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;
// =====================================
// SerchForm  ==========================
const SearchForm = styled.form`
  padding-top: 20px;
  display: none;
  max-height: calc(100vh - 300px);
  overflow: auto;
  transition: all 0.2s ease;
  &.open {
    display: block;
  }
`;

const SearchContentsSC = styled.dl`
  position: relative;
  margin-bottom: 19px;
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.light_gray};
  margin-bottom: 12px;
`;

const SelectLabel = styled.select`
  background-image: url(${pulldown_arrow});
  background-repeat: no-repeat;
  background-position: top 2px right 0px;
  width: 75px;
  height: 13px;
  font-size: 12px;
  color: ${color.light_gray};
  font-weight: 600;
  cursor: pointer;
`;

const ClearButton = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${color.gray};
  background: ${color.gray};
  color: ${color.white};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const Option = styled.option``;
const CheckboxList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const CheckboxItem = styled.li`
  width: 77px;
  margin-bottom: 12px;
  &:nth-of-type(4n) {
    width: auto;
  }
  &:last-of-type {
    width: auto;
    margin-bottom: 0;
  }
  &.checkAll {
    width: 100%;
  }
`;
const Checkbox = styled.input`
  display: none;
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.gray};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.gray};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;
const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const RadioButtonList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const RadioButtonItem = styled.li`
  width: 77px;
  margin-bottom: 12px;
  &:last-of-type {
    width: 100%;
    margin-bottom: 0;
  }
`;
const RadioButton = styled.input`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: none;
  &:checked + label {
    font-weight: 600;
  }
  &:checked + label::after {
    content: "";
    position: absolute;
    left: 3px;
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: ${color.gray};
  }
`;

const RadioButtonLabel = styled.label`
  margin-right: 35px;
  font-size: 12px;
  color: ${color.gray};
  position: relative;
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-right: 0;
  }
  &::before {
    content: ""; //before, afterはこのプロパティが必須。
    display: block; //横幅・縦幅を指定するため、blockに。
    border-radius: 50%; //これで形を円形に。
    border: 1px solid ${color.light_gray};
    width: 16px;
    height: 16px;
  }
`;

const SearchButton = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: ${color.white};
  background-color: ${color.gray};
  font-weight: bold;
  padding: 11px 9px;
  font-size: 14px;
  border-radius: 4px;
  img {
    transform: translateY(-1px);
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
  ${pc`
    padding: 9px;
    font-size: 12px;
  `}
`;
// SerchForm  ==========================
//======================================
