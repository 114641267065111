import React, { FC } from "react";
import { Button } from "../Button";
import { FixedButtonContainerSC, FixedButtonWrapperSC } from "./styles";

interface FixedButtonAreaProps {
  onDeleteFunc?: () => void;
  onUpdateFunc?: () => void;
  updateLabel?: string;
  deleteLabel?: string;
}

export const FixedButtonArea: FC<FixedButtonAreaProps> = ({
  onDeleteFunc,
  onUpdateFunc,
  updateLabel = "更新",
  deleteLabel = "削除"
}) => {
  if (!onDeleteFunc && !onUpdateFunc) {
    return <></>;
  }
  return (
    <FixedButtonContainerSC>
      <FixedButtonWrapperSC>
        {onUpdateFunc && <Button label={updateLabel} onClick={onUpdateFunc} />}
        {onDeleteFunc && (
          <Button label={deleteLabel} onClick={onDeleteFunc} type="alert" />
        )}
      </FixedButtonWrapperSC>
    </FixedButtonContainerSC>
  );
};
