import {
  GET_ARTICLE_REQUESTED,
  READ_ARTICLE_REQUESTED,
  POST_ARTICLE_STOCK_REQUESTED,
  DELETE_ARTICLE_STOCK_REQUESTED,
  SET_ARTICLE_DETAIL_ID,
  RESET_ARTCLE_DETAIL,
  TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED,
  RESET_ARTICLE_DETAIL_MESSAGE
} from "./type";

// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// =====================================TOGGLE_CHECKED_ARTICLE_CATEGORY============================ //
// Actions
// ----------------------------------------------------------------- //

// 記事情報取得
export const getArticleRequest = (token: string, id: number, stocks: any) => {
  return {
    type: GET_ARTICLE_REQUESTED,
    token,
    id,
    stocks
  };
};

// 記事既読情報登録
export const postArticleReadRequest = (token: string, id: number) => {
  return {
    type: READ_ARTICLE_REQUESTED,
    token,
    id
  };
};

// ストック記事登録
export const postArticleStockRequest = (token: string, id: number) => {
  return {
    type: POST_ARTICLE_STOCK_REQUESTED,
    token,
    id
  };
};

// ストック記事削除
export const deleteArticleStockRequest = (token: string, id: number) => {
  return {
    type: DELETE_ARTICLE_STOCK_REQUESTED,
    token,
    id
  };
};

export const setArticleDetailId = (id: number) => {
  return {
    type: SET_ARTICLE_DETAIL_ID,
    id
  };
};

export const resetArticleDetail = () => {
  console.log("YOYOYOYOO");
  return {
    type: RESET_ARTCLE_DETAIL
  };
};

export const toggleArticleDetailContentLoaded = (contentLoaded: boolean) => {
  return {
    type: TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED,
    contentLoaded
  };
};

export const resetArticleDetailMessage = () => {
  return {
    type: RESET_ARTICLE_DETAIL_MESSAGE
  };
};
