import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_NEWS_EDIT_SUCCEEDED,
  GET_NEWS_EDIT_FAILED,
  PUT_NEWS_EDIT_SUCCEEDED,
  PUT_NEWS_EDIT_FAILED,
  INPUT_EDIT_NEWS_TITLE,
  SELECT_EDIT_NEWS_STATUS,
  SET_NEWS_RAW_EDIT_CONTENT,
  START_NEWS_EDIT,
  SET_NEWS_EDITING_STATUS,
  TOGGLE_EDIT_NEWS_POST_STATUS,
  SET_SEARCH_STRING_NEWS_EDIT,
  TOGGLE_NEWS_EDIT_CONTENT_LOADED,
  GET_NEWS_EDIT_FAILED_404,
  DELETE_NEWS_SUCCEEDED,
  DELETE_NEWS_FAILED,
  DELETE_NEWS_FAILED_404,
  TOGGLE_NEWS_IS_EDITING,
  RESET_PREVIOUS_PUBLISH_STATUS,
  SET_NEWS_EDITOR_IS_TOGGLED,
  SAVE_TEMP_NEWS_EDIT_DATA,
  RESET_TEMP_NEWS_EDIT_DATA,
  TOGGLE_NEWS_TITLE_IS_EDITED
} from "./type";

interface TempData {
  title: string;
  content: any;
  search_string: string;
  newsIsEditing: boolean;
  newsTitleIsEdited: boolean;
  title_draft: String;
  draft: string;
}
interface News {
  id: number | null;
  title: string;
  content: any;
  draft: any;
  search_string: string;
  status: number;
  editing_status: "editing" | "saving" | "completed";
  post_status: boolean;
  title_input_status: boolean;
  contentLoaded: boolean;
  message: string;
  title_draft: string;
  newsIsEditing: boolean;
  newsTitleIsEdited: boolean;
  previousStatusIsDraft: boolean | null;
  editorIsToggled: boolean;
  savedTempData: TempData;
}

const initialState: News = {
  id: null,
  title: "",
  content:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  draft:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  search_string: "",
  status: 2,
  editing_status: "editing",
  post_status: false,
  title_input_status: true,
  contentLoaded: false,
  message: "",
  title_draft: "",
  newsIsEditing: false,
  newsTitleIsEdited: false,
  previousStatusIsDraft: null,
  editorIsToggled: false,
  savedTempData: {
    title: "",
    content:
      '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    search_string: "",
    newsIsEditing: false,
    newsTitleIsEdited: false,
    title_draft: "",
    draft:
      '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
  }
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_NEWS_EDIT_SUCCEEDED:
      return {
        ...state,
        ...action.news_detail,
        contentLoaded: true,
        message: ""
      };

    case GET_NEWS_EDIT_FAILED:
      return {
        ...state,
        message: action.message
      };

    case GET_NEWS_EDIT_FAILED_404:
      return {
        ...state,
        message: action.message,
        contentLoaded: true
      };

    case PUT_NEWS_EDIT_SUCCEEDED:
      console.log("投稿編集登録に成功しました status: ", action.status);
      return {
        ...state,
        post_status: true
      };

    case PUT_NEWS_EDIT_FAILED:
      console.log("投稿編集登録作成エラー エラーコード:", action.error_code);
      return {
        ...state
      };

    case DELETE_NEWS_SUCCEEDED:
      console.log("お知らせ削除に成功しました status: ", action.status);
      return {
        ...state
      };

    case DELETE_NEWS_FAILED:
      console.log("お知らせ削除に失敗しました status: ", action.message);
      return {
        ...state
      };

    case DELETE_NEWS_FAILED_404:
      return {
        ...state,
        message: action.message
      };

    case INPUT_EDIT_NEWS_TITLE:
      if (action.title !== "") {
        return {
          ...state,
          title: action.title,
          title_draft: action.title,
          savedTempData: {
            ...state.savedTempData,
            title: action.title,
            title_draft: action.title
          },
          title_input_status: true
        };
      } else {
        return {
          ...state,
          title: action.title,
          title_draft: action.title,
          savedTempData: {
            ...state.savedTempData,
            title: action.title,
            title_draft: action.title
          },
          title_input_status: false
        };
      }

    case SELECT_EDIT_NEWS_STATUS:
      return {
        ...state,
        status: action.id,
        previousStatusIsDraft: action.publishedStatus === 3 ? true : false
      };

    case SET_NEWS_RAW_EDIT_CONTENT:
      return {
        ...state,
        content: action.content,
        savedTempData: {
          ...state.savedTempData,
          content: action.content
        }
      };

    case START_NEWS_EDIT:
      return {
        ...state,
        id: action.id,
        title: action.title,
        content: action.content,
        status: action.status,
        editing_status: action.editing_status
      };

    case SET_NEWS_EDITING_STATUS:
      return {
        ...state,
        editing_status: action.editing_status
      };

    case TOGGLE_EDIT_NEWS_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status
      };

    case SET_SEARCH_STRING_NEWS_EDIT:
      return {
        ...state,
        search_string: action.search_string,
        savedTempData: {
          ...state.savedTempData,
          search_string: action.search_string
        }
      };

    case TOGGLE_NEWS_EDIT_CONTENT_LOADED:
      return {
        ...state,
        contentLoaded: action.contentLoaded
      };

    case TOGGLE_NEWS_IS_EDITING:
      return {
        ...state,
        newsIsEditing: action.newsIsEditing
      };

    case TOGGLE_NEWS_TITLE_IS_EDITED:
      return {
        ...state,
        newsTitleIsEdited: action.newsTitleIsEdited,
        title: action.current_value,
        title_draft: action.current_value
      };

    case RESET_PREVIOUS_PUBLISH_STATUS:
      return {
        ...state,
        previousStatusIsDraft: null
      };

    case SAVE_TEMP_NEWS_EDIT_DATA:
      return {
        ...state,
        savedTempData: {
          title:
            // 直近ステータスが下書きの場合
            action.current_news_status === 3
              ? action.news_edit.newsTitleIsEdited
                ? // タイトルが編集済み（最新状態がtitle）
                  action.news_edit.title
                : // タイトルが未編集の場合(最新状態がtitle_draft)
                  action.news_edit.title_draft
              : // 直近ステータスが下書き以外
                action.news_edit.title,

          content:
            // 直近ステータスが下書きの場合
            action.current_news_status === 3
              ? action.news_edit.newsIsEditing
                ? //本文が編集されている(最新情報がcontent)
                  action.news_edit.content
                : //本文が編集されていない(最新情報がdraft)
                  action.news_edit.draft
              : // 直近ステータスがそれ以外
                action.news_edit.content,
          search_string: action.news_edit.search_string,
          newsIsEditing: action.news_edit.newsIsEditing,
          newsTitleIsEdited: action.news_edit.newsTitleIsEdited,
          title_draft: action.news_edit.title_draft,
          draft: action.news_edit.draft
        }
      };

    case RESET_TEMP_NEWS_EDIT_DATA:
      return {
        ...state,
        savedTempData: initialState.savedTempData
      };

    case SET_NEWS_EDITOR_IS_TOGGLED:
      return {
        ...state,
        editorIsToggled: action.editorIsToggled
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
