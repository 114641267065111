import axios, { AxiosResponse } from "axios";
import { env_manual_url } from "serverEnv";
import {
  GetManualTagsResponseProps,
  CreateTagRequestBodyProps,
  CreateTagResponseBodyProps,
  GetManualTagResponseProps,
  updateManualTagRequestBodyProps,
  updateAllManualTagsRequestBodyProps
} from "store/manual/tags/types.d";

export const getManualTagsApi = (data: { token: string }) => {
  const { token } = data;
  try {
    const result: Promise<AxiosResponse<GetManualTagsResponseProps[]>> =
      axios.get(`${env_manual_url}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};

export const createManualTagApi = (data: {
  token: string;
  req_body: CreateTagRequestBodyProps;
}) => {
  const { token, req_body } = data;
  try {
    const result: Promise<AxiosResponse<CreateTagResponseBodyProps>> =
      axios.post(
        `${env_manual_url}/tags`,
        { ...req_body },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const getManualTagApi = (data: { token: string; id: number }) => {
  const { token, id } = data;
  try {
    const result: Promise<AxiosResponse<GetManualTagResponseProps>> = axios.get(
      `${env_manual_url}/tags/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const updateManualTagApi = (data: {
  token: string;
  id: number;
  req_body: updateManualTagRequestBodyProps;
}) => {
  const { token, id, req_body } = data;
  try {
    const result: Promise<AxiosResponse<{ stats: number; id: number }>> =
      axios.put(
        `${env_manual_url}/tags/${id}`,
        {
          ...req_body
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    return result;
  } catch (e) {
    console.log("error", e);
  }
};

export const deleteManualTagApi = (data: { token: string; id: number }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<{ status: number }>> = axios.delete(
      `${env_manual_url}/tags/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const updateAllManualTagsApi = (data: {
  token: string;
  req_body: updateAllManualTagsRequestBodyProps[];
}) => {
  try {
    const { token, req_body } = data;
    const result: Promise<AxiosResponse<{ status: number }>> = axios.put(
      `${env_manual_url}/tags/all`,
      { ...req_body },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};
