import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "components/mixins";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import { localeTimeForManual } from "components/Common/localeTimeCustomizerForManual";

interface Props {
  topic: TopicItem;
  manual: ManualItemForSelector;
}
function TopicsUneditableItem(props: Props) {
  const { topic, manual } = props;
  return (
    <li>
      <TopicsItemSC
        to={`/manual/admin/manuals/${manual.id}/topics/${topic.id}`}
      >
        <TopicsItemContent>
          <TopicsItemName>{topic.name}</TopicsItemName>
          <TopicsItemDate>
            最終更新:{localeTimeForManual(topic.updated_at)}
          </TopicsItemDate>
        </TopicsItemContent>
      </TopicsItemSC>
    </li>
  );
}

export default TopicsUneditableItem;

const TopicsItemSC = styled(Link)`
  display: block;
  padding: 22px 22px 22px 55px;
  border-bottom: 1px solid ${color.list_border};
  &:hover {
    opacity: 0.6;
  }
`;

const TopicsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopicsItemName = styled.dt`
  font-size: 16px;
`;

const TopicsItemDate = styled.dd`
  font-size: 12px;
  color: ${color.light_gray};
`;
