import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import FacilitySelectModalContent from "./FacilitySelectModalContent";

import { toggleFacilityModal } from "../../../../store/common/modal/action";

export default function FacilitySelectModal() {
  const dispatch = useDispatch();
  const facility_id = useSelector((state: any) => state.facility.id);

  // 施設未選択状態だったら背景クリックしてもモーダルが閉じない
  const handleBgClick = () => {
    if (facility_id === null) {
      return false;
    }
    dispatch(toggleFacilityModal(false));
  };

  return (
    <div>
      <FacilitySelectModalBg onClick={handleBgClick}></FacilitySelectModalBg>
      <FacilitySelectModalContent />
    </div>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const FacilitySelectModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
