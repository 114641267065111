import { all, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ACCOUNTS_REQUESTED,
  GET_ACCOUNTS_SUCCEEDED,
  GET_ACCOUNTS_FAILED
} from "../store/global/accounts/type";
import {
  POST_ACCOUNT_NEW_REQUESTED,
  POST_ACCOUNT_NEW_SUCCEEDED,
  POST_ACCOUNT_NEW_FAILED
} from "../store/global/account_new/type";
import {
  PUT_ACCOUNT_EDIT_REQUESTED,
  PUT_ACCOUNT_EDIT_SUCCEEDED,
  PUT_ACCOUNT_EDIT_FAILED,
  DELETE_ACCOUNT_EDIT_REQUESTED,
  DELETE_ACCOUNT_EDIT_SUCCEEDED,
  DELETE_ACCOUNT_EDIT_FAILED
} from "../store/global/account_edit/type";

import {
  GET_ACCOUNT_EDIT_REQUESTED,
  GET_ACCOUNT_EDIT_SUCCEEDED,
  GET_ACCOUNT_EDIT_FAILED,
  GET_ACCOUNT_EDIT_FAILED_404
} from "../store/global/account_edit/type";

import {
  getAccountEditApi,
  getAccountsApi,
  postAccountApi,
  putAccountEditApi,
  deleteAccountEditApi
} from "../api/global/accountsApi";

import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";

import { responseErrorSwitcher } from "components/Common/responceErrorSwitcher";
// import { SET_ACCOUNTS_TOTAL, SET_ACCOUNTS_CURRENT_PAGE, SET_ACCOUNTS_LAST_PAGE } from "../store/paginate/type";
import {
  SET_CURRENT_PAGE_SUCCEEDED,
  SET_TOTAL
} from "../store/common/paginate/type";

function* getAccounts(action: any) {
  try {
    const result = yield call(
      getAccountsApi,
      action.token,
      action.accounts_page_num,
      action.accounts_per_page,
      action.email_to_search
    );
    console.log("result", result);
    yield all([
      put({ type: GET_ACCOUNTS_SUCCEEDED, accounts: result.data.users }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "account"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "account"
      })
    ]);
  } catch (e) {
    responseErrorSwitcher(e);
  }
}

function* postAccountNew(action: any) {
  try {
    // 本当はこっちのほうがよい：
    // const post_result = yield* postAccountNewApi(action.token, action.name, action.position_id, action.email, action.password);
    // const get_result = yield* getAccountssAfterPostApi(action.token, 1, 20, "");
    // yield all([yield put({ type: POST_ACCOUNT_NEW_SUCCEEDED, status: post_result }), yield put({ type: GET_ACCOUNTS_SUCCEEDED, accounts: get_result.data.users })]);
    const post_result = yield postAccountApi(
      action.token,
      action.account_name,
      action.account_position_id,
      action.account_email,
      action.account_password,
      action.account_facilities
    );
    yield all([
      yield put({ type: POST_ACCOUNT_NEW_SUCCEEDED, status: post_result })
      // yield put({ type: GET_ACCOUNTS_SUCCEEDED, accounts: get_result.data.users })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* putAccountEdit(action: any) {
  try {
    // 本当はこっちのほうがよい：
    // const post_result = yield* postAccountNewApi(action.token, action.name, action.role_id, action.email, action.password);
    // const get_result = yield* getAccountssAfterPostApi(action.token, 1, 20, "");
    // yield all([yield put({ type: POST_ACCOUNT_NEW_SUCCEEDED, status: post_result }), yield put({ type: GET_ACCOUNTS_SUCCEEDED, accounts: get_result.data.users })]);
    const put_result = yield putAccountEditApi(
      action.token,
      action.account_id,
      action.account_name,
      action.account_position_id,
      action.account_email,
      action.account_password,
      action.account_facilities
    );
    yield all([
      yield put({ type: PUT_ACCOUNT_EDIT_SUCCEEDED, status: put_result })
      // yield put({ type: GET_ACCOUNTS_SUCCEEDED, accounts: get_result.data.users })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* deleteAccountEdit(action: any) {
  try {
    const delete_result = yield deleteAccountEditApi(
      action.token,
      action.account_id
    );
    yield all([
      yield put({ type: DELETE_ACCOUNT_EDIT_SUCCEEDED, status: delete_result })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getAccountEdit(action: any) {
  console.log("YA");
  try {
    const result = yield call(getAccountEditApi, action.token, action.id);
    console.log("getAccountEdit result", result);
    yield put({
      type: GET_ACCOUNT_EDIT_SUCCEEDED,
      account_edit: result.data.user
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const accountSaga = [
  takeLatest(GET_ACCOUNTS_REQUESTED, getAccounts),
  takeLatest(POST_ACCOUNT_NEW_REQUESTED, postAccountNew),
  takeLatest(PUT_ACCOUNT_EDIT_REQUESTED, putAccountEdit),
  takeLatest(DELETE_ACCOUNT_EDIT_REQUESTED, deleteAccountEdit),
  takeLatest(GET_ACCOUNT_EDIT_REQUESTED, getAccountEdit)
];

export default accountSaga;
