import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_ARTICLES_SUCCEEDED,
  GET_ARTICLES_FAILED,
  RESET_ARTCLE_DETAIL
} from "./type";

interface Article {
  id: number;
  title: string;
  content: string;
  status: number;
  stock: boolean;
  priority: number;
  views: number;
  released_at: Date;
  created_at: Date;
  updated_at: Date;
  categories: Category[];
  tags: Tag[];
}

interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

const initialState: Article[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ARTICLES_SUCCEEDED:
      return [...action.articles];

    case GET_ARTICLES_FAILED:
      return {
        ...state,
        message: action.message
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
