import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom"
import styled from "styled-components";
import { color } from "../../mixins";
import { pc } from "../../mixins/mediaQuery";
import clock from "../../../images/icons/clock.svg"
import localeTimeCustomizer from "../../Common/LocaleTimeCustomizerForArticles"


function ArticlesUpdatedAtSC(props: any) {
  const updated_at = props.updated_at;

  return (
    <>
      <ClockSC/>更新日時：{localeTimeCustomizer(updated_at)}
    </>
  );
}

export default ArticlesUpdatedAtSC;

const UpdatedAtSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  text-align: right;
  padding-left: 20px;
  white-space: nowrap;
  display: none;
  ${pc`
    display: block;
  `}
`
const UpdatedAtSpSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  display: block;
  ${pc`
    display: none;
  `}
`
const ClockSC = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 6px;
`