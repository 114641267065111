const POST_NEWS_REQUESTED = "POST_NEWS_REQUESTED";
const POST_NEWS_SUCCEEDED = "POST_NEWS_SUCCEEDED";
const POST_NEWS_FAILED = "POST_NEWS_FAILED";

const PUT_NEWS_REQUESTED = "PUT_NEWS_REQUESTED";
const PUT_NEWS_SUCCEEDED = "PUT_NEWS_SUCCEEDED";
const PUT_NEWS_FAILED = "PUT_NEWS_FAILED";

const GET_NEWS_REQUESTED = "GET_NEWS_REQUESTED";
const GET_NEWS_SUCCEEDED = "GET_NEWS_SUCCEEDED";
const GET_NEWS_FAILED = "GET_NEWS_FAILED";

const UPDATE_NEWS_REQUESTED = "UPDATE_NEWS_REQUESTED";
const UPDATE_NEWS_SUCCEEDED = "UPDATE_NEWS_SUCCEEDED";
const UPDATE_NEWS_FAILED = "UPDATE_NEWS_FAILED";

const READ_NEWS_REQUESTED = "READ_NEWS_REQUESTED";
const READ_NEWS_SUCCEEDED = "READ_NEWS_SUCCEEDED";
const READ_NEWS_FAILED = "READ_NEWS_FAILED";

const STOCK_NEWS_REQUESTED = "STOCK_NEWS_REQUESTED";
const STOCK_NEWS_SUCCEEDED = "STOCK_NEWS_SUCCEEDED";
const STOCK_NEWS_FAILED = "STOCK_NEWS_FAILED";


const DELETE_STOCKED_NEWS_REQUESTED = "DELETE_STOCKED_NEWS_REQUESTED";
const DELETE_STOCKED_NEWS_SUCCEEDED = "DELETE_STOCKED_NEWS_SUCCEEDED";
const DELETE_STOCKED_NEWS_FAILED = "DELETE_STOCKED_NEWS_FAILED";

const POST_NEWS_IMAGE_REQUESTED = "POST_NEWS_IMAGE_REQUESTED";
const POST_NEWS_IMAGE_SUCCEEDED = "POST_NEWS_IMAGE_SUCCEEDED";
const POST_NEWS_IMAGE_FAILED = "POST_NEWS_IMAGE_FAILED";

const INPUT_NEW_NEWS_TITLE = "INPUT_NEW_NEWS_TITLE";
const SELECT_NEW_NEWS_STATUS = "SELECT_NEW_NEWS_STATUS";
const SET_NEWS_RAW_CONTENT = "SET_NEWS_RAW_CONTENT";

const TOGGLE_NEW_NEWS_POST_STATUS = "TOGGLE_NEW_NEWS_POST_STATUS";

export {

  POST_NEWS_REQUESTED,
  POST_NEWS_SUCCEEDED,
  POST_NEWS_FAILED,

  PUT_NEWS_REQUESTED,
  PUT_NEWS_SUCCEEDED,
  PUT_NEWS_FAILED,

  GET_NEWS_REQUESTED,
  GET_NEWS_SUCCEEDED,
  GET_NEWS_FAILED,

  UPDATE_NEWS_REQUESTED,
  UPDATE_NEWS_SUCCEEDED,
  UPDATE_NEWS_FAILED,

  READ_NEWS_REQUESTED,
  READ_NEWS_SUCCEEDED,
  READ_NEWS_FAILED,

  STOCK_NEWS_REQUESTED,
  STOCK_NEWS_SUCCEEDED,
  STOCK_NEWS_FAILED,

  DELETE_STOCKED_NEWS_REQUESTED,
  DELETE_STOCKED_NEWS_SUCCEEDED,
  DELETE_STOCKED_NEWS_FAILED,

  POST_NEWS_IMAGE_REQUESTED,
  POST_NEWS_IMAGE_SUCCEEDED,
  POST_NEWS_IMAGE_FAILED,

  INPUT_NEW_NEWS_TITLE,

  SELECT_NEW_NEWS_STATUS,

  SET_NEWS_RAW_CONTENT,

  TOGGLE_NEW_NEWS_POST_STATUS

}
