import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { toggleAnnualEventScheduleModal } from "../../../../store/common/modal/action";
import { AnnualEventScheduleModalContentNew } from "./AnnualEventScheduleModalContentNew";
import { env_event_url } from "serverEnv";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";
import { RootState } from "store/store";

export default function AnnualEventScheduleModal() {
  // const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  console.log("data in useState", data);

  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const facility_id: number = useSelector((state: any) => state.facility.id);
  const filtered_year: number = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  // const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const isImgLoading = useSelector(
    (state: RootState) => state.mediafiles.imgLoading
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          year: filtered_year.toString(),
          facility_id: facility_id.toString()
        };
        const query = new URLSearchParams(params);

        const response = await fetch(
          `${env_event_url}/events/year_calendar?${query}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const apiData = await response.json();
        console.log("apiData", apiData);
        setData({ apiData: apiData });
      } catch (error) {
        // エラーハンドリング
      } finally {
        // setLoading(false);
        dispatch(toggleImgLoadingState(false));
      }
    };

    fetchData();
  }, [token, facility_id, filtered_year, dispatch]);

  if (isImgLoading) {
    return <></>;
  }

  return (
    <>
      <AnnualEventScheduleModalBg
        onClick={() => dispatch(toggleAnnualEventScheduleModal(false))}
      />
      <div>
        <AnnualEventScheduleModalContentNew
          filteredYear={filtered_year}
          facilityId={facility_id}
          stateData={data}
        />
      </div>
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const AnnualEventScheduleModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
