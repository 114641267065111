import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";
import LocalTimeCustomizerForEvents from "../../Common/LocaleTimeCustomizerForEvents";

import plus_button from "../../../images/icons/plus_button.svg";
import remand_icon from "../../../images/icons/exclamation_red.svg";

import {
  addIdIntoDeleteItemArray,
  removeIdFromDeleteItemArray
} from "../../../store/event/events/action";
import {
  toggleAddEventModal,
  toggleEditEventModal
} from "../../../store/common/modal/action";
import { setInitialEditInput } from "../../../store/event/event_edit/action";
import {
  setAddEventModalInitialDate,
  resetAddEventModalContent,
  resetAddEventClass
} from "../../../store/event/event_add/action";

import alert from "../../../images/icons/alert.svg";
import { useCurrentPosition } from "../Constants/useCurrentPosition";
import { ApprovalMonthlyStatus, ApprovalStatus } from "../type";
import { EventItemCheckbox } from "./EventItemCheckbox";

const EventsItem = React.memo((props: any) => {
  const dispatch = useDispatch();
  const position_id = useSelector((state: any) => state.session.position.id);
  const approval_monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );

  // 削除対象として行事予定を追加
  const handleClickCheckbox = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (
      props.alert_flag === true &&
      event.currentTarget.tagName.toLowerCase() === "label"
    ) {
      window.alert(
        "編集警告フラグが設定されている行事予定です。\n編集する場合は注意してください。"
      );
    }
    if (event.target instanceof HTMLInputElement) {
      if (event.currentTarget.checked) {
        dispatch(addIdIntoDeleteItemArray(Number(event.currentTarget.id)));
      } else {
        dispatch(removeIdFromDeleteItemArray(Number(event.currentTarget.id)));
      }
    } else {
      return;
    }
  };

  const {
    isAdmin,
    isFacilityManager,
    isProjectManager,
    isHonbu,
    isGeneralEditor,
    isGeneralViewer
  } = useCurrentPosition();

  const year = new Date(props.date).getFullYear();
  const month = new Date(props.date).getMonth() + 1;
  const date = new Date(props.date).getDate();
  const currentMonth = month < 10 ? `0${month}` : `${month}`;
  const currentDate = date < 10 ? `0${date}` : `${date}`;
  const targetMonth = `${year}-${currentMonth}`;
  const targetDate = `${year}-${currentMonth}-${currentDate}`;

  const currentDateApprovedStatus = approval_monthly_status.find(
    (status: ApprovalMonthlyStatus) => status.month.includes(targetMonth)
  );
  /**システム管理者(本部)が対象イベントが存在している月を月別承認しているかどうか*/
  const isMonthlyApprovedFromAdmin = currentDateApprovedStatus
    ? currentDateApprovedStatus.director_approved
    : false;
  console.log("isMonthlyApprovedFromAdmin", isMonthlyApprovedFromAdmin);
  /**施設長が対象イベントが存在している月を月別承認しているかどうか */
  const isMonthlyApprovedFromFacilityManager = currentDateApprovedStatus
    ? currentDateApprovedStatus.leader_approved
    : false;

  /**プロジェクト管理者が対象イベントが存在している月を月別承認しているかどうか */
  const isMonthlyApprovedFromProjectManager = currentDateApprovedStatus
    ? currentDateApprovedStatus.administrator_approved
    : false;

  const isMonthlyApprovedFromAnyPosition =
    isMonthlyApprovedFromAdmin ||
    isMonthlyApprovedFromFacilityManager ||
    isMonthlyApprovedFromProjectManager;

  /**現在ログインしているユーザーのロールが、対象イベントを承認しているかどうか*/
  const isApprovedFromCurrentPosition = props.approval_statuses.some(
    (approval_status: ApprovalStatus) =>
      approval_status.id === position_id && approval_status.approved === true
  );

  /**承認権限を持ついずれかのロールが、対象イベントを承認しているかどうか*/
  const isApprovedFromAnyPosition = props.approval_statuses.some(
    (approval_status: ApprovalStatus) => approval_status.approved === true
  );

  /** 対象イベントのステータスが本部登録（CSVによるアップロード）によるイベントかどうか*/
  const isCSVUploadedEvent = props.editing_status === 5;

  /** 対象イベントのステータスが登録完了しているかどうか*/
  const isRegisterCompleted = props.editing_status === 4;

  /** 対象イベントのステータスが差し戻されているかどうか*/
  const isRejected = props.editing_status === 2;

  const itemEditableCheck =
    isCSVUploadedEvent ||
    isRegisterCompleted ||
    isApprovedFromCurrentPosition ||
    (isGeneralViewer &&
      (isApprovedFromAnyPosition ||
        isMonthlyApprovedFromFacilityManager ||
        isMonthlyApprovedFromProjectManager ||
        isMonthlyApprovedFromAdmin)) ||
    ((isFacilityManager || isGeneralEditor) &&
      isMonthlyApprovedFromFacilityManager) ||
    (isProjectManager && isMonthlyApprovedFromProjectManager) ||
    ((isHonbu || isAdmin) && isMonthlyApprovedFromAdmin)
      ? "not_editable"
      : "";

  /**イベント追加のDispatch*/
  const AddEventDispatches = (
    targetDate: Date,
    year: number,
    month: number,
    date: number
  ) => {
    dispatch(toggleAddEventModal(true));
    dispatch(resetAddEventClass());
    dispatch(setAddEventModalInitialDate(targetDate, year, month, date));
    dispatch(resetAddEventModalContent());
  };

  // 行事予定の追加
  const handleClickAddEvents = (event: any, targetDate: Date) => {
    event.stopPropagation();
    //position_id === 1(一般編集者)の時は、誰か一人でも承認してたら追加不可
    if (isGeneralEditor) {
      if (isApprovedFromAnyPosition || isMonthlyApprovedFromAnyPosition) {
        return false;
      } else {
        AddEventDispatches(targetDate, year, month, date);
      }
    } else if (isFacilityManager) {
      if (!isMonthlyApprovedFromFacilityManager) {
        AddEventDispatches(targetDate, year, month, date);
      } else {
        if (isRejected) {
          AddEventDispatches(targetDate, year, month, date);
        } else {
          console.log("追加不可");
        }
      }
    } else if (isProjectManager) {
      if (!isMonthlyApprovedFromProjectManager) {
        AddEventDispatches(targetDate, year, month, date);
      } else {
        if (isRejected) {
          AddEventDispatches(targetDate, year, month, date);
        } else {
          console.log("追加不可");
        }
      }
    } else {
      if (!isMonthlyApprovedFromAdmin) {
        AddEventDispatches(targetDate, year, month, date);
      } else {
        if (isRejected) {
          AddEventDispatches(targetDate, year, month, date);
        } else {
          console.log("追加不可");
        }
      }
    }
  };

  /**イベント編集のDispatch*/
  const EditEventDispatches = () => {
    dispatch(toggleEditEventModal(true));
    dispatch(
      setInitialEditInput(
        props.id,
        props.date,
        year,
        month,
        date,
        props.title,
        props.type_id,
        props.content,
        props.detail,
        props.class_age,
        props.remand,
        props.additional_information
      )
    );
  };

  //props.additional_information[index]のdestinationを'／'で区切って文字列にする
  const multipleDetail = props.additional_information
    ? props.additional_information
        .map((item: any) => item.destination)
        .join("／")
    : undefined;

  //props.additional_information[index]のclassを','で区切って文字列にする
  const multipleClasses = props.additional_information
    ? props.additional_information.map((item: any) => item.class).join("／")
    : undefined;

  // 行事予定の編集
  const handleClickEditEvents = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (props.alert_flag === true) {
      window.alert(
        "編集警告フラグが設定されている行事予定です。\n編集する場合は注意してください。"
      );
    }
    // editing_statusが５（CSVUpload、本部登録イベント）の場合、もしくはediting_statusが4(登録完了)だった場合は編集不可
    if (isCSVUploadedEvent || isRegisterCompleted) {
      console.log("編集不可");
    } else {
      if (isGeneralEditor) {
        if (isApprovedFromAnyPosition) {
          console.log("編集不可");
        } else if (isMonthlyApprovedFromAnyPosition) {
          return console.log("追加不可");
        } else {
          EditEventDispatches();
        }
      } else if (isFacilityManager) {
        if (
          !isMonthlyApprovedFromFacilityManager &&
          props.editing_status !== 3
        ) {
          EditEventDispatches();
        } else {
          if (isRejected) {
            EditEventDispatches();
          } else {
            console.log("編集不可");
          }
        }
      } else if (isProjectManager) {
        if (!isMonthlyApprovedFromProjectManager) {
          EditEventDispatches();
        } else {
          if (isRejected) {
            EditEventDispatches();
          } else {
            console.log("編集不可");
          }
        }
      } else {
        if (!isMonthlyApprovedFromAdmin) {
          EditEventDispatches();
        } else {
          if (isRejected) {
            EditEventDispatches();
          } else {
            console.log("編集不可");
          }
        }
      }
    }
  };

  return (
    <>
      {props.title !== "" ||
      props.content !== "" ||
      props.detail !== "" ||
      props.class_age !== "" ? (
        <EventsItemSC
          onClick={(e: any) => handleClickEditEvents(e)}
          className={itemEditableCheck}
        >
          <EventsItemWrapper>
            <EventsItemCheckboxWrapper>
              {isCSVUploadedEvent ||
              isApprovedFromCurrentPosition ||
              (isGeneralViewer &&
                (isApprovedFromAnyPosition ||
                  isMonthlyApprovedFromFacilityManager ||
                  isMonthlyApprovedFromAdmin ||
                  isMonthlyApprovedFromProjectManager)) ||
              ((isFacilityManager || isGeneralEditor) &&
                isMonthlyApprovedFromFacilityManager) ||
              (isProjectManager && isMonthlyApprovedFromProjectManager) ||
              ((isHonbu || isAdmin) && isMonthlyApprovedFromAdmin) ? (
                <EventItemCheckboxSpan />
              ) : (
                <EventItemCheckbox
                  id={props.id}
                  disabled={
                    (props.editing_status !== 1 &&
                      props.editing_status !== 2) ||
                    isRegisterCompleted ||
                    isCSVUploadedEvent
                  }
                  onClick={(e: any) => handleClickCheckbox(e)}
                />
              )}
            </EventsItemCheckboxWrapper>
            <EventsItemContent className="date">
              {props.date_to_show !== ""
                ? LocalTimeCustomizerForEvents(props.date)
                : ""}
            </EventsItemContent>
            {props.title && props.title.length >= 7 ? (
              <>
                <EventsItemContent className="title pointer">
                  {props.title.slice(0, 6) + "..."}
                </EventsItemContent>
                <EventsItemHiddenContent className="title">
                  {props.title}
                </EventsItemHiddenContent>
              </>
            ) : (
              <EventsItemContent className="title">
                <p>{props.title}</p>
              </EventsItemContent>
            )}
            {props.content && props.content.length >= 9 ? (
              <>
                <EventsItemContent className="content pointer">
                  {props.content.slice(0, 8) + "..."}
                </EventsItemContent>
                <EventsItemHiddenContent className="content">
                  {props.content}
                </EventsItemHiddenContent>
              </>
            ) : (
              <EventsItemContent className="content">
                <p>{props.content}</p>
              </EventsItemContent>
            )}

            {/* 詳細（目的地） */}
            {props.detail && !multipleDetail && (
              <>
                {props.detail.length >= 10 ? (
                  <>
                    <EventsItemContent className="detail pointer">
                      {props.detail.slice(0, 9) + "..."}
                    </EventsItemContent>
                    <EventsItemHiddenContent className="detail">
                      {props.detail}
                    </EventsItemHiddenContent>
                  </>
                ) : (
                  <EventsItemContent className="detail">
                    <p>{props.detail}</p>
                  </EventsItemContent>
                )}
              </>
            )}

            {/* 複数入力の詳細（目的地） */}
            {multipleDetail && (
              <>
                {multipleDetail.length >= 10 ? (
                  <>
                    <EventsItemContent className="detail pointer">
                      {multipleDetail.slice(0, 9) + "..."}
                    </EventsItemContent>
                    <EventsItemHiddenContent className="detail">
                      {multipleDetail}
                    </EventsItemHiddenContent>
                  </>
                ) : (
                  <EventsItemContent className="detail">
                    <p>{multipleDetail}</p>
                  </EventsItemContent>
                )}
              </>
            )}

            {/* 詳細（目的地）が存在しない場合 */}
            {!props.detail && !multipleDetail && (
              <EventsItemContent className="detail" />
            )}

            {/* クラス */}
            {props.class_age && !multipleClasses && (
              <>
                {props.class_age.length >= 4 ? (
                  <>
                    <EventsItemContent className="class_age pointer">
                      {props.class_age.slice(0, 3) + "..."}
                    </EventsItemContent>
                    <EventsItemHiddenContent className="class_age">
                      {props.class_age}
                    </EventsItemHiddenContent>
                  </>
                ) : (
                  <EventsItemContent className="class_age">
                    <p>{props.class_age}</p>
                  </EventsItemContent>
                )}
              </>
            )}

            {/* 複数入力のクラス */}
            {multipleClasses && (
              <>
                {multipleClasses.length >= 4 ? (
                  <>
                    <EventsItemContent className="class_age pointer">
                      {multipleClasses.slice(0, 3) + "..."}
                    </EventsItemContent>
                    <EventsItemHiddenContent className="class_age">
                      {multipleClasses}
                    </EventsItemHiddenContent>
                  </>
                ) : (
                  <EventsItemContent className="class_age">
                    <p>{multipleClasses}</p>
                  </EventsItemContent>
                )}
              </>
            )}

            {/* クラスが存在しない場合 */}
            {!props.class_age && !multipleClasses && (
              <EventsItemContent className="class_age" />
            )}

            {props.alert_flag && <EventsRemandButton src={alert} />}

            {isRejected || props.remand.length > 0 ? (
              <EventsRemandButton src={remand_icon} />
            ) : (
              ""
            )}
            <EventsItemAddButton
              src={plus_button}
              onClick={event => handleClickAddEvents(event, props.date)}
              className={
                ((isFacilityManager || isGeneralEditor) &&
                  !isMonthlyApprovedFromFacilityManager) ||
                (isProjectManager && !isMonthlyApprovedFromProjectManager) ||
                ((isHonbu || isAdmin) && !isMonthlyApprovedFromAdmin)
                  ? "addable"
                  : "not_addable"
              }
            />
          </EventsItemWrapper>
        </EventsItemSC>
      ) : (
        <EventsItemSC
          onClick={event => handleClickAddEvents(event, props.date)}
          className={
            props.editing_status === 5 ||
            props.editing_status === 4 ||
            isApprovedFromCurrentPosition ||
            (isGeneralViewer &&
              (isMonthlyApprovedFromFacilityManager ||
                isMonthlyApprovedFromProjectManager ||
                isMonthlyApprovedFromAdmin)) ||
            ((isFacilityManager || isGeneralEditor) &&
              isMonthlyApprovedFromFacilityManager) ||
            (isProjectManager && isMonthlyApprovedFromProjectManager) ||
            ((isHonbu || isAdmin) && isMonthlyApprovedFromAdmin)
              ? "not_editable"
              : ""
          }
        >
          <EventsItemWrapper>
            <EventsItemCheckboxWrapper>
              {isApprovedFromAnyPosition ||
              (isGeneralViewer &&
                (isMonthlyApprovedFromAdmin ||
                  isMonthlyApprovedFromProjectManager ||
                  isMonthlyApprovedFromAdmin)) ||
              ((isFacilityManager || isGeneralEditor) &&
                isMonthlyApprovedFromFacilityManager) ||
              (isProjectManager && isMonthlyApprovedFromProjectManager) ||
              ((isHonbu || isAdmin) && isMonthlyApprovedFromAdmin) ? (
                <EventItemCheckboxSpan />
              ) : (
                <EventItemCheckboxSpan />
              )}
            </EventsItemCheckboxWrapper>

            <EventsItemContent className="date">
              {props.date_to_show !== ""
                ? LocalTimeCustomizerForEvents(props.date)
                : ""}
            </EventsItemContent>
            <EventsItemContent className="title">
              {props.title}
            </EventsItemContent>
            <EventsItemContent className="content">
              {props.content}
            </EventsItemContent>
            <EventsItemContent className="detail">
              {props.detail}
            </EventsItemContent>
            <EventsItemContent className="class_age">
              {props.class_age}
            </EventsItemContent>
            {props.alert_flag && <EventsRemandButton src={alert} />}
            {(isRejected || props.remand.length > 0) && (
              <EventsRemandButton src={remand_icon} />
            )}
          </EventsItemWrapper>
        </EventsItemSC>
      )}
    </>
  );
});

export default EventsItem;

const EventsItemSC = styled.li`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
  cursor: pointer;
  &.not_editable {
    background-color: ${color.divide_border};
    cursor: default;
  }
`;

const EventsItemWrapper = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% - 20px);
  padding: 0 20px;
`;

const EventsItemCheckboxWrapper = styled.li`
  padding-top: 25px;
`;

const EventItemCheckboxSpan = styled.span`
  width: 39px;
  display: block;
`;

// const EventsRemandButton = styled.img`
//   position: absolute;
//   right: 16px;
//   top: 25px;
// `;

const EventsRemandButton = styled.img`
  position: absolute;
  right: 16px;
  top: 25px;
  width: 22px;
  height: 22px;
  cursor: default;
`;

const EventsItemAddButton = styled.img`
  position: absolute;
  right: -11px;
  top: 25px;
  &.addable {
    cursor: pointer;
  }
  &.not_addable {
    display: none;
  }
`;

const EventsItemContent = styled.li`
  padding: 25px 0 21px;
  font-size: 14px;
  color: #444;
  flex-shrink: 0;
  &:hover + li {
    display: block;
  }
  &.date {
    width: calc(14%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
`;

const EventsItemHiddenContent = styled.li`
  display: none;
  position: absolute;
  top: 12px;
  left: 4px;
  border: 1px solid ${color.light_gray};
  border-radius: 4px;
  padding: 11px;
  background-color: ${color.white};
  font-size: 14px;
  color: ${color.gray};
  z-index: 100;
  &.title {
    left: calc(57px + (100%) * 0.13 + 5px);
    &.hover {
      display: block;
    }
  }
  &.content {
    left: calc(57px + (100% - 77px) * 0.32 + 7px);
  }
  &.detail {
    left: calc(57px + (100% - 77px) * 0.54 + 7px);
  }
  &.class_age {
    left: calc(57px + (100% - 77px) * 0.81 + 7px);
  }
  &:hover {
    display: block;
  }
`;
