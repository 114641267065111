import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import searchOpenIcon from "../../../images/icons/down_arrow_green.svg";
import searchIcon from "../../../images/icons/search.svg";

import {
  getArticlesRequest,
  getArticlesForUserRequest
} from "../../../store/faq/articles/action";
import { toggleCategoryToBeSearchedCheck } from "../../../store/faq/categories_to_be_searched/action";
import { toggleTagToBeSearchedCheck } from "../../../store/faq/tags_to_be_searched/action";
import {
  toggleSearchArticleIsOpened,
  handleClearSearchTerms
} from "../../../store/session/action";

import {
  setKeyword,
  setSearchStatus
} from "../../../store/common/paginate/action";

import { animateScroll as scroll } from "react-scroll";

import searchBlockFollow from "../../Common/SearchBlockFollow";

function ArticlesSearchBlock() {
  const dispatch = useDispatch();
  const categories = useSelector((state: any) => state.categories);
  const tags = useSelector((state: any) => state.tags);
  const token = useSelector((state: any) => state.session.token);

  // PC時はRedux Stateから
  const searchIsOpened = useSelector(
    (state: any) => state.session.searchArticleIsOpened
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  // SP時はコンポーネントステートから
  const [searchOpen, setSearchOpen] = React.useState<boolean>(false);

  const categories_to_be_searched = useSelector(
    (state: any) => state.categories_to_be_searched
  );
  const tags_to_be_searched = useSelector(
    (state: any) => state.tags_to_be_searched
  );
  const keyword = useSelector((state: any) => state.paginate.article_keyword);
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.article_per_page
  );
  const location = useLocation();
  const pathName = location.pathname;
  const history = useHistory();

  // 検索キーワード入力
  const handleKeywordInput = (e: any) => {
    dispatch(setKeyword(e.target.value, "article"));
  };

  // search_statusの状態変化を検知してスクロールしようとしてもうまくいってない
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [search_status])

  // 検索キーワード検索実施
  const submitSearch = (e: any) => {
    e.preventDefault();

    // SP時は常にfalseにする
    if (window.innerWidth <= 1024) setSearchOpen(false);

    scroll.scrollToTop({ duration: 200 });
    dispatch(
      getArticlesRequest(
        token,
        pathName,
        1,
        paginate_per_page,
        categories_to_be_searched,
        tags_to_be_searched,
        keyword
      )
    );
    dispatch(setSearchStatus(true));

    // 選択されたカテゴリ、タグ、キーワードからパスを生成する
    let categories = "";
    let tags = "";
    if (categories_to_be_searched) {
      categories_to_be_searched.forEach((item: any, index: number) => {
        if (index === 0) {
          categories = item;
        } else {
          categories = `${categories}+${item}`;
        }
      });
    }
    if (tags_to_be_searched) {
      tags_to_be_searched.forEach((item: any, index: number) => {
        if (index === 0) {
          tags = item;
        } else {
          tags = `${tags}+${item}`;
        }
      });
    }
    let newPath = "";
    if (keyword) {
      newPath = `keyword=${keyword}`;
    }
    if (categories) {
      if (keyword) {
        newPath = `${newPath}&categories=${categories}`;
      } else {
        newPath = `categories=${categories}`;
      }
    }
    if (tags) {
      if (keyword || categories) {
        newPath = `${newPath}&tags=${tags}`;
      } else {
        newPath = `tags=${tags}`;
      }
    }
    if (pathName.includes("/articles")) {
      history.push(`/articles?${newPath}`);
    } else {
      history.push(`/?${newPath}`);
    }
  };

  const handleCategoryCheck = (e: any) => {
    console.log("handleCategoryCheck", e.target.value);
    dispatch(toggleCategoryToBeSearchedCheck(e.target.value));
  };

  const handleTagCheck = (e: any) => {
    console.log("handleTagCheck", e.target.value);
    dispatch(toggleTagToBeSearchedCheck(e.target.value));
  };

  const handleClear = (e: any, target_term: string) => {
    e.preventDefault();
    dispatch(handleClearSearchTerms(target_term));
  };

  window.onscroll = function () {
    const searchBlock = document.getElementById("article_search_block");

    if (document.documentElement.scrollTop > 67) {
      if (searchBlock) {
        searchBlock.classList.add("fixed");
      }
    } else {
      if (searchBlock) {
        searchBlock.classList.remove("fixed");
      }
    }
  };

  const handleToggleOpen = () => {
    if (window.innerWidth > 1024) {
      dispatch(toggleSearchArticleIsOpened(searchIsOpened));
    } else {
      setSearchOpen(!searchOpen);
    }
  };

  return (
    <ArticlesSearchBlockSC
      id="article_search_block"
      className={rightSectionOpen ? "open" : "close"}
    >
      <ArticlesSearchBlockHeaderSC onClick={handleToggleOpen}>
        <ArticlesSearchBlockHeadingSC>検索条件</ArticlesSearchBlockHeadingSC>
        <ToggleArrowSC
          className={
            window.innerWidth > 1024
              ? searchIsOpened
                ? "open"
                : ""
              : searchOpen
              ? "open"
              : ""
          }
          src={searchOpenIcon}
          alt="toggleArrow"
        />
        {((keyword && keyword !== "") ||
          (categories_to_be_searched && categories_to_be_searched.length > 0) ||
          (tags_to_be_searched && tags_to_be_searched.length > 0)) && (
          <SPSearchingIndicator />
        )}
      </ArticlesSearchBlockHeaderSC>

      {/* 検索条件 */}
      <SearchForm
        className={
          window.innerWidth > 1024
            ? searchIsOpened
              ? "open"
              : ""
            : searchOpen
            ? "open"
            : ""
        }
        action=""
      >
        <SearchContentsSC>
          {/* キーワード */}
          <SearchContentsLabelSC>キーワード</SearchContentsLabelSC>
          <dd>
            <KeywordInput
              type="text"
              placeholder="キーワードを入力"
              value={keyword}
              onChange={handleKeywordInput}
            />
          </dd>
        </SearchContentsSC>

        {/* カテゴリ */}
        <SearchContentsSC>
          <SearchContentsLabelSC>カテゴリ</SearchContentsLabelSC>
          <ClearButton onClick={(e: any) => handleClear(e, "category")}>
            クリア
          </ClearButton>
          <dd>
            <CategoriesList>
              {categories &&
                categories.map((category: any, catIndex: number) => {
                  return (
                    <CategoriesItem key={`category_index_${catIndex}`}>
                      <CheckboxLabel onChange={handleCategoryCheck}>
                        <Checkbox
                          className="checkbox"
                          id={`category_${category.id}`}
                          type="checkbox"
                          value={category.name}
                          checked={
                            categories_to_be_searched &&
                            categories_to_be_searched.some(
                              (cat_name: any) => cat_name === category.name
                            )
                          }
                        />
                        <CheckboxSpan className="label">
                          {category.name}(
                          {category.articles_count
                            ? `${category.articles_count}`
                            : "0"}
                          )
                        </CheckboxSpan>
                      </CheckboxLabel>
                    </CategoriesItem>
                  );
                })}
            </CategoriesList>
          </dd>
        </SearchContentsSC>

        {/* タグ */}
        <SearchContentsSC>
          <SearchContentsLabelSC>タグ</SearchContentsLabelSC>
          <ClearButton onClick={(e: any) => handleClear(e, "tag")}>
            クリア
          </ClearButton>
          <dd>
            <TagsList>
              {tags &&
                tags.map((tag: any, tagIndex: number) => {
                  return (
                    <TagsItem key={`tag_index_${tagIndex}`}>
                      <CheckboxLabel onChange={handleTagCheck}>
                        <Checkbox
                          className="checkbox"
                          id={`tag_${tag.id}`}
                          type="checkbox"
                          value={tag.name}
                          checked={
                            tags_to_be_searched &&
                            tags_to_be_searched.some(
                              (tag_name: any) => tag_name === tag.name
                            )
                          }
                        />
                        <CheckboxSpan className="label">
                          {tag.name}(
                          {tag.articles_count ? `${tag.articles_count}` : "0"})
                        </CheckboxSpan>
                      </CheckboxLabel>
                    </TagsItem>
                  );
                })}
            </TagsList>
          </dd>
        </SearchContentsSC>
        <SearchButton onClick={submitSearch}>
          <img src={searchIcon} alt="search icon" />
          この条件で検索
        </SearchButton>
      </SearchForm>
    </ArticlesSearchBlockSC>
  );
}

export default ArticlesSearchBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const ArticlesSearchBlockSC = styled.div`
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  color: ${color.primary};
  &.close {
    display: none;
  }
  ${pc`
    max-width: 360px;
    width: 34.5%;
    margin-bottom: 0;
    padding: 30px;
    order: 2;
    position: static;
  `}
  &.fixed {
    display: block;
    position: fixed;
    top: 56px;
    width: calc(100% - 24px);
    border-radius: 6px;
    z-index: 99;
    ${pc`
      top: 67px;
      right: 40px;
      width: calc((100% - 260px) * 0.345);
      &.close {
        right: 30px;
      }
    `}
    @media(min-width: 1527px) {
      left: calc(100vw - 400px);
    }
    & + div {
      top: 68px;
    }
    &.close {
      display: none;
    }
  }
`;

const ArticlesSearchBlockHeaderSC = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArticlesSearchBlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

const ClearButton = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${color.tag_color};
  background: ${color.tag_color};
  color: ${color.white};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;

// =====================================
// SerchForm  ==========================
const SearchForm = styled.form`
  margin-top: 20px;
  display: none;
  max-height: calc(100vh - 290px);
  overflow: auto;
  &.open {
    display: block;
  }
`;

const SearchContentsSC = styled.dl`
  position: relative;
  margin-bottom: 8px;
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.gray};
  margin-bottom: 12px;
`;

const KeywordInput = styled.input`
  display: block;
  width: 100%;
  padding: 0px 0px 12px 6px;
  border-bottom: 1px solid ${color.divide_border};
  margin-bottom: 20px;
  &::placeholder {
    font-size: 12px;
    color: ${color.category_bg};
  }
`;

const CategoriesList = styled.ul`
  display: inline-block;
`;
const CategoriesItem = styled.li`
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 20px;
`;

const TagsList = styled(CategoriesList)``;
const TagsItem = styled(CategoriesItem)``;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
const SearchButton = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: ${color.white};
  background-color: ${color.primary};
  font-weight: bold;
  padding: 11px 9px;
  font-size: 14px;
  border-radius: 4px;
  img {
    transform: translateY(-1px);
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
  ${pc`
    padding: 9px;
    font-size: 12px;
  `}
`;
// SerchForm  ==========================
//======================================

const SPSearchingIndicator = styled.span`
  content: "";
  position: absolute;
  top: 5px;
  right: 10px;
  background: ${color.primary};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
