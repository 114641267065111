import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  TOGGLE_SIDEBAR,
  TOGGLE_FAQ,
  TOGGLE_NEWS,
  TOGGLE_MASTER,
  TOGGLE_ACCOUNT,
  TOGGLE_INQUIRY,
  TOGGLE_RIGHT_SECTION,
  SET_RIGHT_SECTION_OPEN
} from "./type";

const initialState = {
  sidebarIsOpen: true,
  faqIsOpen: false,
  newsIsOpen: false,
  masterIsOpen: false,
  accountIsOpen: false,
  inquiryIsOpen: false,
  rightSectionOpen: true
};

const sidebarReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarIsOpen: !state.sidebarIsOpen };

    case TOGGLE_FAQ:
      return { ...state, faqIsOpen: !state.faqIsOpen };

    case TOGGLE_NEWS:
      return { ...state, newsIsOpen: !state.newsIsOpen };

    case TOGGLE_MASTER:
      return { ...state, masterIsOpen: !state.masterIsOpen };

    case TOGGLE_ACCOUNT:
      return { ...state, accountIsOpen: !state.accountIsOpen };

    case TOGGLE_INQUIRY:
      return { ...state, inquiryIsOpen: !state.inquiryIsOpen };

    case TOGGLE_RIGHT_SECTION:
      return { ...state, rightSectionOpen: !state.rightSectionOpen };

    case SET_RIGHT_SECTION_OPEN:
      return { ...state, rightSectionOpen: action.open };

    case CLEAR_SYSTEM_CACHE:
      return initialState;
    default:
      return state;
  }
};

export default sidebarReducer;
