import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import tagIcon from "../../../images/icons/tag.svg";
import { getArticleRequest } from "../../../store/faq/article_detail/action";
import localeTimeCustomizer from "../../Common/LocaleTimeCustomizerForArticles";
import clock from "../../../images/icons/clock.svg";

function ArticlesItem(props: any) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const {
    id,
    title,
    content,
    status,
    priority,
    views,
    released_at,
    created_at,
    updated_at,
    categories,
    tags,
    stocked,
    read,
  } = props;
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const stocks = useSelector((state: any) => state.articles_stocked);
  return (
    <AriticlesItemSC key={`article_${id}`}>
      <ArticlesItemLink
        to={"/articles/stocks/" + id}
        activeStyle={{ color: "red" }}
        className={read ? "read" : ""}
      >
        {/* 記事アイテム上部 */}
        <ArticlesItemTop>
          {/* カテゴリーとタグ */}
          <Categories>
            {/* カテゴリー */}
            {categories &&
              categories.map((category: any, categoryIndex: number) => (
                <Category key={`category_index_${categoryIndex}`}>
                  {category.name}
                </Category>
              ))}
          </Categories>

          {/* 作成日 */}
          <CreatedAt>
            <ClockSC />
            <span>{localeTimeCustomizer(updated_at)}</span>
          </CreatedAt>
        </ArticlesItemTop>

        {/* 記事タイトル */}
        <ArticlesItemHeading>
          {title && title.length >= 32 ? title.slice(0, 31) + "..." : title}
        </ArticlesItemHeading>

        {/* 記事アイテム下部 */}
        <ArticlesItemBottom>
          {/* タグ */}
          <Tags>
            {tags &&
              tags.map((tag: any, tagIndex: number) => (
                <Tag key={`tag_index_${tagIndex}`}>
                  <TagIconImg src={tagIcon} />
                  {tag.name}
                </Tag>
              ))}
          </Tags>
        </ArticlesItemBottom>

        <ArticlesItemStockIcon>
          {stocked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
            >
              <path
                id="bookmark"
                d="M19,21l-7-5L5,21V5A2,2,0,0,1,7,3H17a2,2,0,0,1,2,2Z"
                transform="translate(-5 -3)"
                fill="#298948"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
            >
              <g
                id="bookmark"
                transform="translate(-5 -3)"
                fill="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path
                  d="M 18.5 20.02840232849121 L 12.29061985015869 15.59313011169434 L 12 15.38554954528809 L 11.70938014984131 15.59313011169434 L 5.5 20.02840232849121 L 5.5 5 C 5.5 4.172900199890137 6.172900199890137 3.5 7 3.5 L 17 3.5 C 17.82710075378418 3.5 18.5 4.172900199890137 18.5 5 L 18.5 20.02840232849121 Z"
                  stroke="none"
                />
                <path
                  d="M 18 19.05681228637695 L 18 5 C 18 4.448599815368652 17.55139923095703 4 17 4 L 7 4 C 6.448599815368652 4 6 4.448599815368652 6 5 L 6 19.05681228637695 L 11.41876029968262 15.18626976013184 C 11.59261035919189 15.06208992004395 11.79630470275879 15 12 15 C 12.20369529724121 15 12.40738964080811 15.06208992004395 12.58123970031738 15.18626976013184 L 18 19.05681228637695 M 19 21 L 12 16 L 5 21 L 5 5 C 5 3.895430088043213 5.895430088043213 3 7 3 L 17 3 C 18.10457038879395 3 19 3.895430088043213 19 5 L 19 21 Z"
                  stroke="none"
                  fill="#298948"
                />
              </g>
            </svg>
          )}
        </ArticlesItemStockIcon>
      </ArticlesItemLink>
    </AriticlesItemSC>
  );
}

export default ArticlesItem;

const AriticlesItemSC = styled.li`
  width: 100%;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
`;

const ArticlesItemHeading = styled.h2`
  width: 100%;
  font-size: 16px;
  line-height: inherit;
  font-weight: 600;
  color: ${color.gray};
  margin: 14px 0;
  word-break: break-all;
  padding-right: 30px;
`;

const ArticlesItemLink = styled(NavLink)`
  position: relative;
  display: block;
  width: 100%;
  min-height: 108px;
  padding: 16px 20px;
  cursor: pointer;
  &:hover {
    background: ${color.primary_white};
    opacity: 0.6;
    ${ArticlesItemHeading} {
      // color: ${color.tag_color};
    }
  }
  &.read {
    color: ${color.gray};
    background: ${color.primary_white};
    .ArticlesItemHeading {
      color: ${color.tag_color};
    }
  }
`;

const ArticlesItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Categories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  ${pc`
    width: calc(100% - 125px);
    padding-right: 5%;
  `}
`;

const Category = styled.li`
  display: block;
  margin-right: 8px;
  margin-bottom: 4px;
  color: ${color.white};
  background-color: ${color.gray};
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
`;

const CreatedAt = styled.div`
  text-align: right;
  width: 160px;
  padding-right: 35px;
  display: none;
  ${pc`
    width: 125px;
    padding-right: 0;
    display: block;
  `}
  span {
    font-size: 12px;
  }
`;

const ArticlesItemBottom = styled.div`
  width: 100%;
`;

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.li`
  position: relative;
  display: block;
  margin-right: 12px;
  margin-bottom: 5px;
  font-size: 12px;
  color: ${color.gray};
`;

const TagIconImg = styled.img`
  display: inline-block;
  width: 12px;
  margin-right: 5px;
`;

const ArticlesItemStockIcon = styled.button`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 7px;
  ${pc`
    top: auto;
    bottom: 16px;
  `}
  svg {
    width: 14px;
  }
`;

const ClockSC = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 6px;
`;
