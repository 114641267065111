import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../../Common/PageHeading";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";

import NewsNewEditor from "./NewsNewEditor";
import {
  putNewsRequest,
  toggleNewsNewPostStatus
} from "../../../../store/global/news_new/action";
import { history } from "../../../../store/store";

function ArticlesNew(props: any) {
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const dispatch = useDispatch();
  // const history = useHistory();

  const token = useSelector((state: any) => state.session.token);
  const new_news_id = useSelector((state: any) => state.news_new.new_news_id);
  const title = useSelector((state: any) => state.news_new.title);
  const content = useSelector((state: any) => state.news_new.content);
  const status = useSelector((state: any) => state.news_new.status);

  const post_status = useSelector((state: any) => state.news_new.post_status);
  // 必須入力欄の入力バリデーションに使用
  const title_input_status = useSelector(
    (state: any) => state.news_new.title_input_status
  );

  const handleRegister = () => {
    // 「登録」ボタンクリック時に実際の新規記事情報を入力
    dispatch(putNewsRequest(new_news_id, token, title, content, status));
  };

  // ①コンポーネント表示時にPOSTリクエストで記事作成
  // useEffect(() => {
  //   dispatch(postArticleRequest(token, title, content, search_string, status, categories, tags));
  // }, [dispatch, token, title, content, search_string, status, categories, tags]);

  useEffect(() => {
    dispatch(toggleNewsNewPostStatus(false));
  }, [dispatch]);

  return (
    <>
      {post_status ? (
        history.push("/news-edit-list")
      ) : (
        <MainSC>
          <PageHeading title="お知らせ登録" />
          <ArticlesNewBlockSC
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            <NewsNewEditor />
          </ArticlesNewBlockSC>

          {title_input_status ? (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、ありません
              </ButtonAreaDeskSC>
              <SubmitButtonSC className="active" onClick={handleRegister}>
                登録
              </SubmitButtonSC>
            </FixedButtonAreaSC>
          ) : (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、残り１つです
              </ButtonAreaDeskSC>
              <SubmitButtonSC>登録</SubmitButtonSC>
            </FixedButtonAreaSC>
          )}
        </MainSC>
      )}
    </>
  );
}

export default ArticlesNew;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const ArticlesNewBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    padding: 30px;
    margin-right: 10px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    margin-left: -30px;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 0;
  ${pc`
    margin-right: 12px;
  `}
  &.active {
    &:hover {
      opacity: 0.6;
    }
  }
`;
