import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "components/mixins";
import { Page } from "store/manual/manuals/pages/types.d";
import { useHooks } from "./Hooks";

interface Props {
  manualId: string;
  topicId: string;
  page: Page;
}
function ManualsUneditableItem(props: Props) {
  const { manualId, topicId, page } = props;
  const { location } = useHooks({});
  return (
    <>
      {
        // 管理側検索
        location.pathname.includes("admin") ? (
          <li>
            <ManualsItemSC
              to={
                location.pathname.includes("admin")
                  ? `/manual/admin/manuals/${page.type_id}/topics/${page.topic_id}/pages/${page.id}`
                  : `/manual/manuals/${page.type_id}/topics/${page.topic_id}/pages/${page.id}`
              }
            >
              <ManualsItemContent>
                <ManualsItemName>{page.title}</ManualsItemName>
                <ManualsItemDate>最終更新:{page.updated_at}</ManualsItemDate>
              </ManualsItemContent>
            </ManualsItemSC>
          </li>
        ) : (
          // ユーザー側検索
          // 非公開以外を表示
          page.status !== 1 && (
            <li>
              <ManualsItemSC
                to={`/manual/manuals/${page.type_id}/topics/${page.topic_id}/pages/${page.id}`}
              >
                <ManualsItemContent>
                  <ManualsItemName>{page.title}</ManualsItemName>
                  <ManualsItemDate>最終更新:{page.updated_at}</ManualsItemDate>
                </ManualsItemContent>
              </ManualsItemSC>
            </li>
          )
        )
      }
    </>
  );
}

export default ManualsUneditableItem;

const ManualsItemSC = styled(Link)`
  display: block;
  padding: 22px 22px 22px 55px;
  border-bottom: 1px solid ${color.list_border};
  &:hover {
    opacity: 0.6;
  }
`;

const ManualsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ManualsItemName = styled.dt`
  font-size: 16px;
`;

const ManualsItemDate = styled.dd`
  font-size: 12px;
  color: ${color.light_gray};
`;
