import {
  GET_ARTICLE_EDIT_REQUESTED, 
  PUT_ARTICLE_EDIT_REQUESTED, 
  INPUT_EDIT_ARTICLE_TITLE,
  TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK,
  TOGGLE_EDIT_ARTICLE_TAG_CHECK,
  SELECT_EDIT_ARTICLE_STATUS,
  SET_RAW_EDIT_CONTENT,
  START_ARTICLE_EDIT,
  SET_ARTICLE_EDITING_STATUS,
  UPLOAD_ARTICLE_IMAGE_REQUEST,
  TOGGLE_EDIT_ARTICLE_POST_STATUS,
  SET_SEARCH_STRING_ARTICLE_EDIT,
  TOGGLE_ARTICLE_EDIT_CONTENT_LOADED,
  DELETE_ARTICLE_REQUESTED, 
  TOGGLE_ARTICLE_IS_EDITING,
  TOGGLE_ARTICLE_TITLE_IS_EDITED,
  RESET_PREVIOUS_PUBLISH_STATUS,
  SAVE_TEMP_EDIT_DATA,
  RESET_TEMP_EDIT_DATA,
  SET_EDITOR_IS_TOGGLED,
} from "./type";

// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// =====================================TOGGLE_CHECKED_ARTICLE_CATEGORY============================ //
// Actions
// ----------------------------------------------------------------- //

// 記事情報取得
export const getArticleEditRequest = (token: string, id: number, stocks: any) => {
  return {
    type: GET_ARTICLE_EDIT_REQUESTED,
    token,
    id,
    stocks,
  };
};

// 記事情報登録
export const putArticleEditRequest = (
  edit_article_id: number,
  token: string,
  title: string,
  content: any,
  search_string: string,
  status: number,
  categories: Category[],
  tags: Tag[]
) => {
  return {
    type: PUT_ARTICLE_EDIT_REQUESTED,
    edit_article_id,
    token,
    title,
    content,
    search_string,
    status,
    categories,
    tags,
  };
};

//記事情報削除
export const deleteArticleRequest = (token: string, id: number) => {
  return {
    type: DELETE_ARTICLE_REQUESTED,
    token,
    id
  }
}


export const inputEditArticleTitle = (title: string) => {
  return {
    type: INPUT_EDIT_ARTICLE_TITLE,
    title,
  };
};

export const toggleEditArticleCategoryCheck = (id: number, category: Category, editorIsToggled: boolean) => {
  return {
    type: TOGGLE_EDIT_ARTICLE_CATEGORY_CHECK,
    id,
    category,
    editorIsToggled
  };
};

export const toggleEditArticleTagCheck = (id: number, tag: Tag, editorIsToggled: boolean) => {
  return {
    type: TOGGLE_EDIT_ARTICLE_TAG_CHECK,
    id,
    tag,
    editorIsToggled
  };
};

export const selectEditArticleStatus = (id: number, publishedStatus: number) => {
  return {
    type: SELECT_EDIT_ARTICLE_STATUS,
    id,
    publishedStatus
  };
};

export const setRawEditContent = (content: any) => {
  return {
    type: SET_RAW_EDIT_CONTENT,
    content,
  };
};

export const startArticleEdit = (
  id: number,
  title: string,
  content: any,
  edit_categories: any,
  edit_tags: any,
  status: number,
  editing_status: any
) => {
  return {
    type: START_ARTICLE_EDIT,
    id,
    title,
    content,
    edit_categories,
    edit_tags,
    status,
    editing_status,
  };
};

export const setArticleEditingStatus = (editing_status: string) => {
  return {
    type: SET_ARTICLE_EDITING_STATUS,
    editing_status,
  };
};

export const uploadArticleImageRequest = (token: string, data: any) => {
  return {
    type: UPLOAD_ARTICLE_IMAGE_REQUEST,
    token,
    data,
  };
};

export const toggleArticleEditPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_EDIT_ARTICLE_POST_STATUS,
    post_status,
  };
};

export const setSearchStringArticleEdit = (search_string: string) => {
  return {
    type: SET_SEARCH_STRING_ARTICLE_EDIT,
    search_string,
  };
};

export const toggleArticleEditContentLoaded = (contentLoaded: boolean) => {
  return {
    type: TOGGLE_ARTICLE_EDIT_CONTENT_LOADED,
    contentLoaded,
  };
};

// 現在その記事が編集中かどうか判断する
export const toggleArticleIsEditing = (articleIsEditing: boolean) => {
  return {
    type: TOGGLE_ARTICLE_IS_EDITING,
    articleIsEditing,
  };
};

export const toggleArticleTitleIsEdited = (articleTitleIsEdited: boolean, current_value: string) => {
  return {
    type: TOGGLE_ARTICLE_TITLE_IS_EDITED,
    articleTitleIsEdited,
    current_value
  }
}

export const resetPreviousPublishStatus = () => {
  return {
    type: RESET_PREVIOUS_PUBLISH_STATUS,
  }
}

export const saveTempEditData = (article_edit: object, current_article_status?: number) => {
  return {
    type: SAVE_TEMP_EDIT_DATA,
    article_edit,
    current_article_status
  }
}
export const resetTempEditData = () => {
  return {
    type: RESET_TEMP_EDIT_DATA,
  }
}

export const setEditorIsToggled = (editorIsToggled: boolean) => {
  return {
    type: SET_EDITOR_IS_TOGGLED,
    editorIsToggled
  }
}
