import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../Common/PageHeading";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import TagsList from "./TagsList";
import { color, buttonBaseStyle, containerBaseStyle } from "../../mixins";
import {
  deleteTagItemRequested,
  postTagItemRequested,
  updateTagItemRequested
} from "../../../store/faq/tags/action";
import { getRegisteredTagsRequested } from "../../../store/faq/tags/action";

function Tags(props: any) {
  const dispatch = useDispatch();
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const to_be_deleted_array = useSelector(
    (state: any) => state.tags_to_be_deleted
  );
  const to_be_created_array = useSelector(
    (state: any) => state.tags_to_be_created
  );
  const to_be_updated_array = useSelector(
    (state: any) => state.tags_to_be_updated
  );
  const token = useSelector((state: any) => state.session.token);

  const handleUpdateButtonClicked = (e: any) => {
    e.preventDefault();
    to_be_created_array.forEach((item: any) => {
      const temp_id = item.temp_id;
      const new_tag_name = item.name;
      const new_tag_order = item.order;
      dispatch(
        postTagItemRequested(token, new_tag_name, new_tag_order, temp_id)
      );
    });
    to_be_updated_array.forEach((item: any) => {
      const id = item.id;
      const updated_name = item.name;
      const updated_order = item.order;
      dispatch(updateTagItemRequested(token, id, updated_name, updated_order));
    });
    to_be_deleted_array.forEach((id: number) => {
      dispatch(deleteTagItemRequested(token, id));
    });
  };
  useEffect(() => {
    dispatch(getRegisteredTagsRequested(token));
  }, [dispatch, token]);

  return (
    <MainSC>
      <PageHeading title="タグ設定" />
      <TagsListBlockSC>
        <TagsDescSC>
          ※並び順は1~99999の整数で指定してください。
          <br />
          ※カテゴリ及びタグは並び順の昇順で表示され、並び順が同じものは登録順で表示されます。
        </TagsDescSC>
        <TagsList />
      </TagsListBlockSC>
      <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
        <SubmitButtonSC onClick={handleUpdateButtonClicked}>
          更新
        </SubmitButtonSC>
      </FixedButtonAreaSC>
    </MainSC>
  );
}

export default Tags;

// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const TagsListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 24px 16px;
  ${pc`
    max-width: 810px;
    width: 65.5%;
    margin-right: 10px;
    padding: 30px;
  `}
`;

const TagsDescSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 12px;
`;

const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 70px;
  padding: 13px 0;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -30px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width:calc(100vw - 50px);
    `}
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
