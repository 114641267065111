import axios from "axios";
import { env_camera_url } from "serverEnv";
import { CameraTag } from "../type";

interface FetchTagsResponseType {
  status: number;
  tags: CameraTag[];
}
interface ResponseOnlyStatusType {
  status: number;
}
export interface BatchTagRequestBodyType {
  tags: {
    id: number | null;
    name: string;
    order: number;
    is_enabled: boolean;
  }[];
}
interface CreateTagRequestBodyType {
  name: string;
  order: number;
  is_enabled: boolean;
}
interface UpdateTagRequestBodyType {
  name: string;
  order: number;
  is_enabled: boolean;
}

interface CommonResponseType {
  status: number;
  id: number;
}

// タグ取得
export const fetchTagsApi = (params: { token: string }) => {
  const { token } = params;
  return axios.get<FetchTagsResponseType>(`${env_camera_url}/tags`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

// タグ削除
export const deleteTagApi = (params: { token: string; tagId: number }) => {
  const { token, tagId } = params;
  return axios.delete<ResponseOnlyStatusType>(
    `${env_camera_url}/tags/${tagId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// タグ一括更新・登録
export const postTagBatchApi = (params: {
  token: string;
  requestBody: BatchTagRequestBodyType;
}) => {
  const { token, requestBody } = params;
  return axios.post<ResponseOnlyStatusType>(
    `${env_camera_url}/tags/`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// 新規タグ作成
export const postTagCreateApi = (params: {
  token: string;
  requestBody: CreateTagRequestBodyType;
}) => {
  const { token, requestBody } = params;
  return axios.post<CommonResponseType>(
    `${env_camera_url}/tags/`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// タグ更新
export const putTagUpdateApi = (params: {
  token: string;
  tagId: number;
  requestBody: UpdateTagRequestBodyType;
}) => {
  const { token, tagId, requestBody } = params;
  return axios.put<CommonResponseType>(
    `${env_camera_url}/tags/${tagId}`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// タグ一括更新
export const batchCameraTagsUpdateApi = (params: {
  token: string;
  requestBody: BatchTagRequestBodyType;
}) => {
  const { token, requestBody } = params;
  return axios.post<CommonResponseType>(
    `${env_camera_url}/tags/batch`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};
