/**yyyt-mm-ddの形で返す
 * @param date 対象の日付
 */
export const formatdate = (date: Date | null) => {
  if (!date) {
    return null;
  } else {
    const y = date.getFullYear();
    const m = ("00" + (date.getMonth() + 1)).slice(-2);
    const d = ("00" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
  }
};
