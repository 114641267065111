import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../mixins";
import { pc } from "../../mixins/mediaQuery";
import EventsItem from "./EventsItem";
import AgriculturalPlanItem from "./AgriculturaPlanItem";
import MonthlyPlanItem from "./MonthlyPlanItem";
import { Event } from "../type";

function EventsList() {
  const eventList = useSelector((state: any) => state.events.events);
  const dateList = useSelector((state: any) => state.event_search.date_list);
  const isSearchByEventType = useSelector(
    (state: any) => state.event_search.is_search_by_event_type
  );

  return (
    <EventsListSC>
      {dateList.map((date: any) => {
        const dailyEvents = eventList.filter(
          (event: Event) =>
            new Date(event.date).getFullYear() ===
              new Date(date).getFullYear() &&
            new Date(event.date).getMonth() === new Date(date).getMonth() &&
            new Date(event.date).getDate() === new Date(date).getDate()
        );
        const current_day = new Date(date).getTime();
        const last_date = new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth() + 1,
          0
        ).getTime();
        // 月末かどうかを判定
        if (current_day === last_date) {
          //その日にイベントは存在しているかを判定
          if (dailyEvents.length > 0) {
            return dailyEvents.map((event: Event, index: number) => {
              return (
                // 月末にイベントが複数個存在していた場合
                dailyEvents.length > 1 ? (
                  // 最初の要素には日付を表示し、月間農業計画は表示しない
                  index === 0 ? (
                    <EventsItem
                      key={event.id}
                      id={event.id}
                      date_to_show={event.date}
                      date={event.date}
                      title={event.type.name}
                      type_id={event.type.id}
                      content={event.content}
                      detail={event.detail}
                      class_age={event.class}
                      remand={event.rejects}
                      editing_status={event.status}
                      approval_statuses={event.approval_statuses}
                      alert_flag={event.alert_flag}
                      additional_information={event.additional_information}
                    />
                  ) : index + 1 === dailyEvents.length ? (
                    // 最後の要素だった場合は、日付は表示せず月間農業計画を表示する
                    <>
                      <EventsItem
                        key={event.id}
                        id={event.id}
                        date_to_show=""
                        date={event.date}
                        title={event.type.name}
                        type_id={event.type.id}
                        content={event.content}
                        detail={event.detail}
                        class_age={event.class}
                        remand={event.rejects}
                        editing_status={event.status}
                        approval_statuses={event.approval_statuses}
                        alert_flag={event.alert_flag}
                        additional_information={event.additional_information}
                      />
                      <MonthlyPlanItem date={event.date} />
                      <AgriculturalPlanItem date={event.date} />
                    </>
                  ) : (
                    //月末の要素の最初でも最後でもない要素の時は日付も月間農業計画も表示しない
                    <>
                      <EventsItem
                        key={event.id}
                        id={event.id}
                        date_to_show=""
                        date={event.date}
                        title={event.type.name}
                        type_id={event.type.id}
                        content={event.content}
                        detail={event.detail}
                        class_age={event.class}
                        remand={event.rejects}
                        editing_status={event.status}
                        approval_statuses={event.approval_statuses}
                        alert_flag={event.alert_flag}
                        additional_information={event.additional_information}
                      />
                    </>
                  )
                ) : (
                  //月末にイベントが一つのみ存在しない時は、日付も月間農業計画も表示する
                  <>
                    <EventsItem
                      key={event.id}
                      id={event.id}
                      date_to_show={event.date}
                      date={event.date}
                      title={event.type.name}
                      type_id={event.type.id}
                      content={event.content}
                      detail={event.detail}
                      class_age={event.class}
                      remand={event.rejects}
                      editing_status={event.status}
                      approval_statuses={event.approval_statuses}
                      alert_flag={event.alert_flag}
                      additional_information={event.additional_information}
                    />
                    <MonthlyPlanItem date={event.date} />
                    <AgriculturalPlanItem date={event.date} />
                  </>
                )
              );
            });
          } else {
            //存在してない場合は日付のみを返す
            //絞り込み条件で行事名が選択されている場合は何も返さない
            if (isSearchByEventType) {
              return "";
              //絞り込み条件で行事名が選択されていない場合は日付のみ返す
            } else {
              return (
                <>
                  <EventsItem
                    key={date}
                    id=""
                    date={date}
                    title=""
                    type_id=""
                    content=""
                    detail=""
                    class_age=""
                    remand=""
                    approval_statuses={[]}
                  />
                  <MonthlyPlanItem date={date} />
                  <AgriculturalPlanItem date={date} />
                </>
              );
            }
          }
          //末日ではない場合
        } else {
          //イベントが存在しているかどうか判定
          if (dailyEvents.length > 0) {
            return dailyEvents.map((event: any, index: number) => {
              return (
                //最初の要素だった場合日付を表示
                index === 0 ? (
                  <EventsItem
                    key={event.id}
                    id={event.id}
                    date_to_show={event.date}
                    date={event.date}
                    title={event.type.name}
                    type_id={event.type.id}
                    content={event.content}
                    detail={event.detail}
                    class_age={event.class}
                    remand={event.rejects}
                    editing_status={event.status}
                    approval_statuses={event.approval_statuses}
                    alert_flag={event.alert_flag}
                    additional_information={event.additional_information}
                  />
                ) : (
                  //そうではない時は日付を表示しない
                  <EventsItem
                    key={event.id}
                    id={event.id}
                    date_to_show=""
                    date={event.date}
                    title={event.type.name}
                    type_id={event.type.id}
                    content={event.content}
                    detail={event.detail}
                    class_age={event.class}
                    remand={event.rejects}
                    editing_status={event.status}
                    approval_statuses={event.approval_statuses}
                    alert_flag={event.alert_flag}
                    additional_information={event.additional_information}
                  />
                )
              );
            });
          } else {
            //絞り込み条件で行事名が選択されている場合何も返さない
            if (isSearchByEventType) {
              return "";
              //絞り込み条件で行事名が選択されていない場合は日付のみ返す
            } else {
              return (
                <EventsItem
                  key={date}
                  id=""
                  date={date}
                  title=""
                  type_id=""
                  content=""
                  detail=""
                  class_age=""
                  remand=""
                  approval_statuses={[]}
                />
              );
            }
          }
        }
      })}
    </EventsListSC>
  );
}

const EventsListSC = styled.ul`
  width: 100%;
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;

export default EventsList;
