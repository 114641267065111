import React, { FC } from "react";
import {
  DatePickButtonSC,
  FlexWrapperSC,
  LabelSC,
  DatePickerWrapper,
  ResetBtn
} from "./style";
import ja from "date-fns/locale/ja";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import reset from "../../../../images/icons/close.svg";

interface DatePickerProps {
  onChangeFrom: (date: Date | [Date, Date] | null) => void;
  onChangeTo: (date: Date | [Date, Date] | null) => void;
  onResetTakenFrom: () => void;
  onResetTakenTo: () => void;
  from: Date | [Date, Date] | null;
  to: Date | [Date, Date] | null;
}
export const DatePicker: FC<DatePickerProps> = ({
  onChangeFrom,
  onChangeTo,
  onResetTakenFrom,
  onResetTakenTo,
  from,
  to
}) => {
  registerLocale("ja", ja);
  return (
    <FlexWrapperSC>
      <LabelSC>撮影期間</LabelSC>
      <DatePickerWrapper>
        <ReactDatePicker
          locale="ja"
          onChange={date => onChangeFrom(date)}
          wrapperClassName="camera_calendar_wrapper"
          customInput={
            <DatePickButtonSC>
              {from && from instanceof Date
                ? from.toLocaleDateString("ja")
                : "指定なし"}
            </DatePickButtonSC>
          }
        />
        <ResetBtn onClick={onResetTakenFrom}>
          <img src={reset} alt="" />
        </ResetBtn>
      </DatePickerWrapper>
      <span>〜</span>
      <DatePickerWrapper>
        <ReactDatePicker
          locale="ja"
          onChange={date => onChangeTo(date)}
          wrapperClassName="camera_calendar_wrapper"
          customInput={
            <DatePickButtonSC>
              {to && to instanceof Date
                ? to.toLocaleDateString("ja")
                : "指定なし"}
            </DatePickButtonSC>
          }
        />
        <ResetBtn onClick={onResetTakenTo}>
          <img src={reset} alt="" />
        </ResetBtn>
      </DatePickerWrapper>
    </FlexWrapperSC>
  );
};
