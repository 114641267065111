import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FlexPageLayout } from "../../Parts/MainLayout";
import PageDetailBlock from "./PagesDetailBlock";
import { useHooks } from "./Hooks";
import styled from "styled-components";
import { color, buttonBaseStyle } from "components/mixins";
import { pc } from "components/mixins/mediaQuery";
import { history } from "store/store";
import {
  savePageDetailRequest,
  setPageDetailEditMode
} from "store/manual/manuals/pages/actions";
import { getPageRequest } from "store/manual/manuals/pages/actions";
import { getManualTagsRequest } from "store/manual/tags/actions";
const PageDetail = () => {
  const {
    storedPageDetail,
    storedPageDetailOnEdit,
    manualId,
    topicId,
    storedTopicTitle
  } = useHooks();

  const { publishedData, draftData, pageId } = useHooks();

  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );

  // ページとマニュアルタグを取得
  React.useEffect(() => {
    dispatch(getPageRequest({ token, id: Number(pageId) }));
    dispatch(getManualTagsRequest({ token }));
  }, [dispatch, token, pageId]);

  // 編集画面と公開記事確認画面の切り替え
  const [showEditor, setShowEditor] = useState(true);

  // プレビューモード切り替え
  const [previewMode, setPreviewMode] = useState(false);

  // 編集中記事 / 公開中記事の切り替え
  const handleToggleEditor = () => {
    setShowEditor(!showEditor);
  };

  // 保存処理
  const requestSave = async () => {
    // ページ編集モードをオフ
    history.push(`/manual/admin/manuals/${manualId}/topics/${topicId}`);
    // 保存処理
    dispatch(
      savePageDetailRequest({
        token,
        id: storedPageDetailOnEdit.id,
        manual_id: Number(manualId),
        req_body: {
          topic_id: storedPageDetailOnEdit.topic_id,
          order: storedPageDetailOnEdit.order,
          title: storedPageDetailOnEdit.title_draft,
          status: storedPageDetailOnEdit.status,
          tags:
            storedPageDetailOnEdit &&
            storedPageDetailOnEdit.tags.map((tag: any) => {
              return { id: tag.id };
            }),
          history_flag: false,
          content: storedPageDetailOnEdit.content_draft
        }
      })
    );
  };

  // 記事として公開
  const handlePublish = () => {
    // ページ編集モードをオフ
    dispatch(setPageDetailEditMode(false));
    history.push(`/manual/admin/manuals/${manualId}/topics/${topicId}`);
    dispatch(
      savePageDetailRequest({
        token,
        id: Number(pageId),
        manual_id: Number(manualId),
        req_body: {
          topic_id: Number(topicId),
          order: publishedData.order,
          title: draftData.title,
          content: draftData.content,
          status: 2,
          tags:
            storedPageDetailOnEdit &&
            storedPageDetailOnEdit.tags.map((tag: any) => {
              return { id: tag.id };
            }),
          history_flag: false
        }
      })
    );
  };
  // 非公開記事として公開
  const handlePrivatePublish = () => {
    // ページ編集モードをオフ
    dispatch(setPageDetailEditMode(false));
    history.push(`/manual/admin/manuals/${manualId}/topics/${topicId}`);
    dispatch(
      savePageDetailRequest({
        token,
        id: Number(pageId),
        manual_id: Number(manualId),
        req_body: {
          topic_id: Number(topicId),
          order: publishedData.order,
          title: draftData.title,
          content: draftData.content,
          status: 1,
          tags:
            storedPageDetailOnEdit &&
            storedPageDetailOnEdit.tags.map((tag: any) => {
              return { id: tag.id };
            }),
          history_flag: false
        }
      })
    );
  };
  // 下書きとして保存
  const handleEditSave = () => {
    // ページ編集モードをオフ
    dispatch(setPageDetailEditMode(false));
    history.push(`/manual/admin/manuals/${manualId}/topics/${topicId}`);
    dispatch(
      savePageDetailRequest({
        token,
        id: Number(pageId),
        manual_id: Number(manualId),
        req_body: {
          topic_id: Number(topicId),
          order: publishedData.order,
          title: draftData.title,
          content: draftData.content,
          status: 3,
          tags:
            storedPageDetailOnEdit &&
            storedPageDetailOnEdit.tags.map((tag: any) => {
              return { id: tag.id };
            }),
          history_flag: false
        }
      })
    );
  };

  return (
    <FlexPageLayout
      pageTitle={storedPageDetailOnEdit.title_draft}
      toTopicsPath={`/manual/admin/manuals/${manualId}/`}
      topicTitle={storedTopicTitle}
      toPagesPath={`/manual/admin/manuals/${manualId}/topics/${topicId}`}
      currentPageTitle={storedPageDetailOnEdit.title_draft}
      previewMode={previewMode}
    >
      {/* パンくずと編集中記事/公開中記事トグルボタン */}
      {!previewMode && (
        <BreadCrumbAndToggleButtonWrapper>
          <ToggleButtonWrapper>
            <p>編集中記事</p>
            <div>
              <ToggleButton
                id="toggleButton"
                className="toggle-button"
                type="checkbox"
                onClick={handleToggleEditor}
              />
              <ToggleButtonLabel htmlFor="toggleButton"></ToggleButtonLabel>
            </div>

            <p>公開中記事</p>
          </ToggleButtonWrapper>
        </BreadCrumbAndToggleButtonWrapper>
      )}

      {/* エディター部分本体(プレビュー機能も内包) */}
      <PageDetailBlock
        storedPageDetail={storedPageDetail}
        storedPageDetailOnEdit={storedPageDetailOnEdit}
        showEditor={showEditor}
        previewMode={previewMode}
        publishedData={publishedData}
        draftData={draftData}
      />

      {!previewMode ? (
        <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
          <SubmitButtonSC className="active" onClick={requestSave}>
            登録
          </SubmitButtonSC>
          <PreviewButtonSC onClick={() => setPreviewMode(true)}>
            プレビュー
          </PreviewButtonSC>
        </FixedButtonAreaSC>
      ) : (
        <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
          <SubmitButtonSC id="2" className="active" onClick={handlePublish}>
            公開
          </SubmitButtonSC>
          <SubmitButtonSC
            id="1"
            className="active"
            onClick={handlePrivatePublish}
          >
            非公開として保存
          </SubmitButtonSC>
          <SubmitButtonSC id="3" className="active" onClick={handleEditSave}>
            下書きとして保存
          </SubmitButtonSC>
          <BackButtonSC onClick={() => setPreviewMode(false)}>
            戻る
          </BackButtonSC>
        </FixedButtonAreaSC>
      )}
    </FlexPageLayout>
  );
};

export default PageDetail;

const BreadCrumbAndToggleButtonWrapper = styled.div`
  justify-content: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

const ToggleButtonLabel = styled.label``;
const ToggleButtonWrapper = styled.div`
  max-width: 810px;
  display: flex;
  justify-content: flex-start;
  div {
    position: relative;
    width: 44px;
    margin: 0 10px;
  }
  p {
    padding: 6px 0;
    font-size: 12px;
  }
`;

const ToggleButton = styled.input`
  .toggle-size {
    width: auto;
    max-width: 60px;
    height: auto;
    max-height: 50px;
  }

  &.toggle-button {
    display: block;
    appearance: none;
    position: absolute;
    right: 0;
    width: 44px;
    height: 25px;
    border: 1px solid #b3b3b3;
    border-radius: 50px;
    background-color: #fff;
    transition: background-color 0.4s ease;
    &:focus {
      outline: none;
    }
    &:checked {
      background-color: ${color.primary};
    }
  }

  &.toggle-button + label {
    position: relative;
    display: inline-block;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border: 1px solid #b3b3b3;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    transition: left 0.4s ease, background-color 0.4s ease;
  }

  &.toggle-button:checked + label {
    position: relative;
    display: inline-block;
    top: 2px;
    left: 22px;
    width: 20px;
    height: 20px;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    margin-left: -30px;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${color.gray};
  ${pc`
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 14px;
    `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  &.active {
    &:hover {
      opacity: 0.6;
    }
  }
`;

const PreviewButtonSC = styled.button`
  ${buttonBaseStyle}
  opacity: 1;
  color: ${color.primary};
  background-color: ${color.white};
  border: 1px solid ${color.primary};
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BackButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  border: 1px solid ${color.primary};
  color: ${color.primary};
  background-color: ${color.white};
  opacity: 1;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
