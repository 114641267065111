import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_INITIAL_EDIT_MONTHLY_EVENT,
  SET_EDIT_MONTHLY_EVENT_TYPE,
  SET_EDIT_MONTHLY_EVENT_CONTENT,
  RESET_EDIT_MONTHLY_EVENT_TYPE,
  RESET_EDIT_MONTHLY_EVENT_CONTENT,
} from "./type";

const initialState = {
  year: new Date().getFullYear(),
  id: null,
  month: 4,
  date: "",
  type_id: 52,
  content: "",
  detail: "",
  kind: 2,
  remand: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_INITIAL_EDIT_MONTHLY_EVENT:
      return {
        ...state,
        id: action.edit_monthly_event_id,
        year: action.year,
        month: action.month,
        type_id: action.type_id,
        content: action.content,
        kind: action.kind,
        remand: action.remand,
      };

    case SET_EDIT_MONTHLY_EVENT_TYPE:
      return {
        ...state,
        type_id: action.type_id,
      };

    case SET_EDIT_MONTHLY_EVENT_CONTENT:
      return {
        ...state,
        content: action.content,
      };

    case RESET_EDIT_MONTHLY_EVENT_TYPE:
      return {
        ...state,
        type_id: null,
      };

    case RESET_EDIT_MONTHLY_EVENT_CONTENT:
      return {
        ...state,
        content: null,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
