import PageHeading from "components/Common/PageHeading";
import { color, containerBaseStyle } from "components/mixins";
import { pc } from "components/mixins/mediaQuery";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FacilitySelectBoxRS } from "../InquiryNew/FacilitySelectBox";
import { InquiryFlow } from "./InquiriesFlow";
import { InquiryInputRow } from "./InquiryInputRow";
import { RadioButtonList } from "./RadioButtonList";
import { TextInput } from "./TextInput";
import { useInquiry } from "../hooks/useInquiry";
import { FixedInquiryButton } from "./FIxedButton";
import { PROXY_LOGINS, TERMINALS } from "./constants";
import { useHistory } from "react-router-dom";
import { contactPathGen, kindPathGen } from "utils/pathGenerator";
import { RequestData } from "./type";
import { contactValueGen, kindValueGen } from "utils/paramGenerator";
import { CheckboxList } from "./CheckboxList";

const InquiryNewV2 = () => {
  const { push } = useHistory();
  const [requestData, setRequestData] = useState<RequestData>({
    contact: null,
    details: "",
    priority: 3,
    employee_check: "true"
  });
  const {
    contacts,
    kinds1,
    kinds2,
    answers,
    facilities,
    checklist,
    priorities,
    userName,
    userEmail,
    userId,
    contactFromParams,
    kindFromParams,
    handleSubmit
  } = useInquiry(requestData);

  const {
    contact,
    kind,
    details,
    terminal_details,
    terminal,
    model_name,
    proxy_login,
    employee_check,
    employee_id,
    employee_name,
    employee_email,
    priority,
    answer,
    child_id,
    checklist: selectedChecklist
  } = requestData;

  const handleSetData = (target: keyof RequestData, value: any) => {
    if (target === "contact") {
      const contact = Number(value);
      push(`/inquiries/new/${contactPathGen(value)}`);
      setRequestData(state => {
        if (contact !== 1 && contact !== 2) {
          delete state.kind;
        }
        delete state.child_id;
        delete state.terminal;
        delete state.terminal_details;
        delete state.proxy_login;
        return {
          ...state,
          contact
        };
      });
    } else if (target === "kind") {
      const kind = Number(value);
      push(`/inquiries/new/${contactFromParams}/${kindPathGen(value)}`);
      setRequestData(state => {
        delete state.child_id;
        delete state.terminal;
        delete state.terminal_details;
        delete state.proxy_login;
        return {
          ...state,
          kind
        };
      });
    } else if (target === "employee_check") {
      console.log("value", value);
      push(`/inquiries/new/${contactFromParams}/${kindPathGen(value)}`);
      setRequestData(state => {
        return {
          ...state,
          employee_check: value
        };
      });
    } else if (target === "proxy_login") {
      setRequestData(state => {
        return {
          ...state,
          proxy_login: value === "true" ? "true" : "false"
        };
      });
    } else if (target === "checklist") {
      setRequestData(state => {
        if (state.checklist) {
          return {
            ...state,
            checklist: state.checklist.includes(value)
              ? state.checklist.filter(item => item !== value)
              : [...state.checklist, value]
          };
        } else {
          return {
            ...state,
            checklist: [value]
          };
        }
      });
    } else {
      setRequestData(state => {
        return {
          ...state,
          [target]: value
        };
      });
    }
  };

  // urlからお問合せ先の初期値設定
  useEffect(() => {
    setRequestData(state => {
      return {
        ...state,
        contact: contactValueGen(contactFromParams)
      };
    });
  }, []);

  // urlからお問い合わせ種別の初期値設定
  useEffect(() => {
    setRequestData(state => {
      return {
        ...state,
        kind: kindValueGen(kindFromParams)!
      };
    });
  }, []);

  useEffect(() => {
    setRequestData(state => {
      return {
        ...state,
        user_email: userEmail
      };
    });
  }, [userEmail]);

  useEffect(() => {
    setRequestData(state => {
      return {
        ...state,
        user_name: userName
      };
    });
  }, [userName]);

  useEffect(() => {
    setRequestData(state => {
      if (userId !== null) {
        return {
          ...state,
          user_id: userId
        };
      } else {
        return {
          ...state
        };
      }
    });
  }, [userId]);

  return (
    <MainSC>
      <PageHeading title="お問合せフォーム" />
      <InquiriesNewContainerSC>
        <StepWrapper>
          <InquiryFlow flowName="入力" isCurrent>
            STEP.1
          </InquiryFlow>
          <InquiryFlow flowName="完了">STEP.2</InquiryFlow>
        </StepWrapper>
        <InquiryText>
          お問い合わせ内容を入力してください（<span>＊</span>は必須項目）
        </InquiryText>
        <InquiryInputRow label="お問い合せ先" required>
          <RadioButtonList
            groupName="contacts"
            items={contacts}
            selectedContact={contact !== null ? contact.toString() : ""}
            onContactChange={e =>
              handleSetData("contact", Number(e.target.value))
            }
          />
        </InquiryInputRow>
        {(contact === 1 || contact === 2) && (
          <InquiryInputRow label="お問い合せ種別" required>
            <RadioButtonList
              groupName="kinds"
              items={kinds1}
              selectedContact={kind ? kind.toString() : ""}
              onContactChange={e =>
                handleSetData("kind", Number(e.target.value))
              }
            />
          </InquiryInputRow>
        )}
        {contact === 3 && (
          <InquiryInputRow label="お問い合せ種別" required>
            <RadioButtonList
              groupName="kinds"
              items={kinds2}
              selectedContact={kind ? kind.toString() : ""}
              onContactChange={e =>
                handleSetData("kind", Number(e.target.value))
              }
            />
          </InquiryInputRow>
        )}
        {/* IT関連：職員関連 */}
        {contact === 1 && kind === 1 && (
          <InquiryInputRow label="機器詳細">
            <TextInput
              id="terminal_detail"
              value={terminal_details ?? ""}
              onChange={e => handleSetData("terminal_details", e.target.value)}
            />
          </InquiryInputRow>
        )}
        {/* IT関連：保護者関連 */}
        {contact === 1 && kind === 2 && (
          <>
            <InquiryInputRow label="園児ID">
              <TextInput
                id="child_id"
                value={child_id ?? ""}
                onChange={e => handleSetData("child_id", e.target.value)}
              />
            </InquiryInputRow>
            <InquiryInputRow label="使用端末">
              <RadioButtonList
                groupName="teminals"
                items={TERMINALS}
                selectedContact={terminal ?? ""}
                onContactChange={e => handleSetData("terminal", e.target.value)}
              />
            </InquiryInputRow>
            <InquiryInputRow label="機種名">
              <TextInput
                id="model_name"
                value={model_name ?? ""}
                onChange={e => handleSetData("model_name", e.target.value)}
              />
            </InquiryInputRow>
            <InquiryInputRow label="代理ログイン許諾" required>
              <RadioButtonList
                groupName="proxy_login"
                items={PROXY_LOGINS}
                selectedContact={
                  proxy_login === "true"
                    ? "1"
                    : proxy_login === "false"
                    ? "2"
                    : ""
                }
                onContactChange={e =>
                  handleSetData(
                    "proxy_login",
                    e.target.value === "1" ? "true" : "false"
                  )
                }
              />
            </InquiryInputRow>
          </>
        )}
        {contact === 2 && kind === 2 && (
          <InquiryInputRow label="園児ID">
            <TextInput
              id="child_id"
              value={child_id ?? ""}
              onChange={e => handleSetData("child_id", e.target.value)}
            />
          </InquiryInputRow>
        )}
        {contact === 3 && kind === 6 && (
          <InquiryInputRow label="該当する項目にチェック" required>
            <CheckboxList
              groupName="checklist"
              items={checklist ?? []}
              selectedItem={selectedChecklist ?? []}
              onSelect={e => handleSetData("checklist", e.target.value)}
            />
          </InquiryInputRow>
        )}
        <Hr />
        {/* 共通入力エリア */}
        <InquiryInputRow label="お問い合せ詳細" required>
          <TextInput
            id="terminal_detail"
            value={details ?? ""}
            onChange={e => handleSetData("details", e.target.value)}
            multiple
          />
        </InquiryInputRow>
        <InquiryInputRow label="ログインユーザー名">
          <p>{userName ?? ""}</p>
        </InquiryInputRow>
        <InquiryInputRow label="ログインユーザーID">
          <p>{userId ?? ""}</p>
        </InquiryInputRow>
        <InquiryInputRow label="ログインユーザーメールアドレス">
          <p>{userEmail ?? ""}</p>
          <CheckboxLabel>
            <Checkbox
              onChange={() =>
                handleSetData(
                  "employee_check",
                  employee_check === "true" ? "false" : "true"
                )
              }
              className="checkbox"
              id="isSame"
              type="checkbox"
              name="month"
              checked={employee_check === "true"}
            />
            <CheckboxSpan className="label">
              ログインユーザーと対象の職員は同じ
            </CheckboxSpan>
          </CheckboxLabel>
        </InquiryInputRow>
        {employee_check === "false" && (
          <>
            <InquiryInputRow label="対象の職員名" required>
              <TextInput
                id="employee_name"
                value={employee_name ?? ""}
                onChange={e => handleSetData("employee_name", e.target.value)}
              />
            </InquiryInputRow>
            <InquiryInputRow label="対象の職員ID" required>
              <TextInput
                id="employee_id"
                value={employee_id ?? ""}
                onChange={e => handleSetData("employee_id", e.target.value)}
              />
            </InquiryInputRow>
            <InquiryInputRow label="対象の職員メールアドレス" required>
              <TextInput
                id="employee_email"
                value={employee_email ?? ""}
                onChange={e => handleSetData("employee_email", e.target.value)}
              />
            </InquiryInputRow>
          </>
        )}
        <InquiryInputRow label="対象施設" required>
          <FacilitySelectBoxRS
            facilities={facilities}
            onChangeFacility={value =>
              handleSetData("facility_id", Number(value))
            }
          />
        </InquiryInputRow>
        <InquiryInputRow label="回答優先度" required>
          <RadioButtonList
            groupName="priorities"
            items={priorities}
            selectedContact={priority ? priority.toString() : ""}
            onContactChange={e => handleSetData("priority", e.target.value)}
          />
        </InquiryInputRow>
        <InquiryInputRow label="回答方法">
          <Text>
            お問い合わせの回答はメール・チャットもしくはお電話となります。
          </Text>
        </InquiryInputRow>
        <FixedInquiryButton
          onClickButton={handleSubmit}
          requiredCount={0}
          sidebarIsOpen={true}
        />
      </InquiriesNewContainerSC>
    </MainSC>
  );
};

export default InquiryNewV2;

const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const InquiriesNewContainerSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  padding: 24px 16px;
  ${pc`
    padding: 30px;
  `}
`;

const StepWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const InquiryText = styled.h2`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${color.divide_border};
  span {
    color: red;
  }
`;

const Hr = styled.hr`
  border-top: 1px solid ${color.divide_border};
  margin-bottom: 24px;
`;

const Checkbox = styled.input`
  display: none;
`;
const Text = styled.p`
  font-size: 12px;
  line-height: 18px;
`;
const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;
const CheckboxSpan = styled.span`
  padding-left: 22px;
`;
