import axios from "axios";
import { env_url, env_faq_url } from "../../serverEnv";
const deleteTagItemApi = (token: string, id: number) => {
  return axios.delete(`${env_faq_url}/tags/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// const postNewTagItemApi = (token: string, name: string, order:number) => {
//   return (
//     axios.post(
//       `${env_faq_url}/tags`,
//       {
//         "name": name,
//         "order": order
//       },
//       {
//         headers: {
//           "Authorization": `Bearer ${token}`,
//         }
//       }
//     )
//   )
// }

function* postNewTagItemApi(token: string, name: string, order: number) {
  try {
    const result = yield axios.post(
      `${env_faq_url}/tags`,
      {
        name: name,
        order: order,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

function* getTagsApiAfterPost(token: string) {
  const result = yield axios.get(`${env_faq_url}/tags`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result;
}

const updateTagItemApi = (
  token: string,
  name: string,
  order: number,
  id: number
) => {
  return axios.put(
    `${env_faq_url}/tags/${id}`,
    {
      name: name,
      order: order,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export {
  deleteTagItemApi,
  postNewTagItemApi,
  updateTagItemApi,
  getTagsApiAfterPost,
};
