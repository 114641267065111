import React from "react";
import styled from "styled-components";
import handle from "../../../images/icons/handle.svg";

const SortHandle: React.VFC = () => {
  return <SortHandleSC src={handle} alt="handle" />;
};

export default SortHandle;

const SortHandleSC = styled.img`
  display: block;
  width: 17px;
  margin: 0 19px;
  &:hover {
    cursor: move;
  }
`;
