export const GET_HISTORIES_REQUESTED = "GET_HISTORIES_REQUESTED";
export const GET_HISTORIES_SUCCEEDED = "GET_HISTORIES_SUCCEEDED";
export const GET_HISTORIES_FAILED = "GET_HISTORIES_FAILED";

export const UPDATE_HISTORY_REQUESTED = "UPDATE_HISTORY_REQUESTED";
export const UPDATE_HISTORY_SUCCEEDED = "UPDATE_HISTORY_SUCCEEDED";
export const UPDATE_HISTORY_FAILED = "UPDATE_HISTORY_FAILED";

export const UPDATE_ALL_HISTORIES_REQUESTED = "UPDATE_ALL_HISTORIES_REQUESTED";
export const UPDATE_ALL_HISTORIES_SUCCEEDED = "UPDATE_ALL_HISTORIES_SUCCEEDED";
export const UPDATE_ALL_HISTORIES_FAILED = "UPDATE_ALL_HISTORIES_FAILED";
