import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../../Common/PageHeading";
import NewsEditPreviewContent from "./NewsEditPreviewContent";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import { getNewsDetailRequest } from "../../../../store/global/news_detail/action";
import {
  putNewsEditRequest,
  setNewsEditorIsToggled,
  saveTempNewsEditData
} from "../../../../store/global/news_edit/action";
import { history } from "../../../../store/store";

function NewsEditPreview(props: any) {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );

  const current_news_title = useSelector(
    (state: any) => state.news_detail.title
  );
  const current_news_content = useSelector(
    (state: any) => state.news_detail.content
  );
  const current_news_status = useSelector(
    (state: any) => state.news_detail.status
  );

  const news_edit = useSelector((state: any) => state.news_edit);
  const edit_news_id = useSelector((state: any) => state.news_edit.id);
  const edit_news_title = useSelector((state: any) => state.news_edit.title);
  const edit_news_title_draft = useSelector(
    (state: any) => state.news_edit.title_draft
  );
  const edit_news_content = useSelector(
    (state: any) => state.news_edit.content
  );
  const edit_news_draft = useSelector((state: any) => state.news_edit.draft);
  const edit_search_string = useSelector(
    (state: any) => state.news_edit.search_string
  );
  const edit_news_status = useSelector((state: any) => state.news_edit.status);

  const message = useSelector((state: any) => state.news_edit.message);

  const post_status = useSelector((state: any) => state.news_edit.post_status);

  const news_is_editing = useSelector(
    (state: any) => state.news_edit.newsIsEditing
  );
  const newsTitleIsEdited = useSelector(
    (state: any) => state.news_edit.newsTitleIsEdited
  );

  const editorIsToggled = useSelector(
    (state: any) => state.news_edit.editorIsToggled
  );
  const savedTempData = useSelector(
    (state: any) => state.news_edit.savedTempData
  );
  const savedTempContent = useSelector(
    (state: any) => state.news_edit.savedTempData.content
  );
  const savedTempTitle = useSelector(
    (state: any) => state.news_edit.savedTempData.title
  );
  const savedTempTitleDraft = useSelector(
    (state: any) => state.news_edit.savedTempData.title_draft
  );
  const savedTempSearchString = useSelector(
    (state: any) => state.news_edit.savedTempData.search_string
  );
  const savedTempDraft = useSelector(
    (state: any) => state.news_edit.savedTempData.draft
  );
  const savedTempIsEditing = useSelector(
    (state: any) => state.news_edit.savedTempData.newsIsEditing
  );
  const savedTempTitleIsEdited = useSelector(
    (state: any) => state.news_edit.savedTempData.newsTitleIsEdited
  );

  let title: string;
  if (edit_news_title_draft !== "") {
    title = edit_news_title_draft;
  } else {
    title = edit_news_title;
  }

  useEffect(() => {
    dispatch(getNewsDetailRequest(token, edit_news_id));
    if (!editorIsToggled) {
      //初回
      dispatch(saveTempNewsEditData(news_edit, edit_news_status));
    } else {
      //二度目以降
      dispatch(saveTempNewsEditData(savedTempData, edit_news_status));
    }
    dispatch(setNewsEditorIsToggled(true));
  }, [dispatch, token, edit_news_id]);

  const handleBack = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  //更新イベントハンドラー
  const handleRegister = (status_id: string) => {
    const selected_status = Number(status_id);
    console.log("selected_status", selected_status);
    if (editorIsToggled) {
      //一度でもセーブされてたらセーブした本文とタイトルをリクエストに載せる。
      if (savedTempIsEditing) {
        !savedTempTitleIsEdited && current_news_status === 3
          ? //本文は編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合（最新のタイトルの状態がtitle_draft）
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitleDraft,
                savedTempContent,
                selected_status,
                savedTempSearchString
              )
            )
          : //本文・タイトルともに編集済みの場合(最新情報が全て更新されている場合)
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitle,
                savedTempContent,
                selected_status,
                savedTempSearchString
              )
            );
      } else {
        !savedTempTitleIsEdited && current_news_status === 3
          ? //本文・タイトルともに未編集で、直近のステータスが下書きだった場合（最新の状態がdraftとtitle_draft）
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitleDraft,
                savedTempDraft,
                selected_status,
                savedTempSearchString
              )
            )
          : //本文は未編集だが、タイトルは編集している場合（最新の状態がdraftとtitle）
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                savedTempTitle,
                savedTempDraft,
                selected_status,
                savedTempSearchString
              )
            );
      }
    } else {
      //一度もセーブしてない場合
      if (news_is_editing) {
        !newsTitleIsEdited && current_news_status === 3
          ? // 本文編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合 (本文最新: content, タイトル最新: title_draft)
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title_draft,
                news_edit.content,
                selected_status,
                edit_search_string
              )
            )
          : // 本文・タイトルを編集している場合 ( 本文最新: content, タイトル最新: title )
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title,
                news_edit.content,
                selected_status,
                edit_search_string
              )
            );
      } else {
        !newsTitleIsEdited && current_news_status === 3
          ? // 本文、タイトルともに未編集で、直近のステータスが下書きだった場合 ( 本文最新: draft, タイトル最新: title_draft )
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title_draft,
                news_edit.draft,
                selected_status,
                edit_search_string
              )
            )
          : // 本文は未編集で、タイトルは編集している場合 ( 本文最新: draft, タイトル最新: title )
            dispatch(
              putNewsEditRequest(
                news_edit.id,
                token,
                news_edit.title,
                news_edit.draft,
                selected_status,
                edit_search_string
              )
            );
      }
    }
  };

  // const handleRegister = () => {
  //   if(editorIsToggled){
  //     dispatch(putNewsEditRequest(edit_news_id, token, savedTempTitle, savedTempContent, 2, savedTempSearchString))
  //   }else{
  //     // 「登録」ボタンクリック時に実際の新規記事情報を入力
  //     if(news_is_editing) {
  //       // 「非公開記事として保存」ボタンクリック時に実際の新規記事情報を入力
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_content, 2, edit_news_search_string));
  //     } else {
  //       // もとの記事が下書き状態で編集しないまま登録した場合、draftを送信する。
  //       current_news_status === 3 ?
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_draft, 2, edit_news_search_string))
  //       :
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_content, 2, edit_news_search_string));
  //     }
  //   }
  // };

  // const handleRegisterHidden = () => {
  //   if(editorIsToggled) {
  //     dispatch(putNewsEditRequest(edit_news_id, token, savedTempTitle, savedTempContent, 1, savedTempSearchString))
  //   } else {
  //     if(news_is_editing) {
  //       // 「非公開記事として保存」ボタンクリック時に実際の新規記事情報を入力
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_content, 1, edit_news_search_string));
  //     } else {
  //       // もとの記事が下書き状態で編集しないまま登録した場合、draftを送信する。
  //       current_news_status === 3 ?
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_draft, 1, edit_news_search_string))
  //       :
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_content, 1, edit_news_search_string));
  //     }
  //   }
  // };

  // const handleSaveAsDraft = () => {
  //   if(editorIsToggled) {
  //     dispatch(putNewsEditRequest(edit_news_id, token, savedTempTitle, savedTempContent, 3, savedTempSearchString))
  //   } else {
  //     if(news_is_editing) {
  //       // 「非公開記事として保存」ボタンクリック時に実際の新規記事情報を入力
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_content, 3, edit_news_search_string));
  //     } else {
  //       // もとの記事が下書き状態で編集しないまま登録した場合、draftを送信する。
  //       current_news_status === 3 ?
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_draft, 3, edit_news_search_string))
  //       :
  //       dispatch(putNewsEditRequest(edit_news_id, token, edit_news_title, edit_news_content, 3, edit_news_search_string));
  //     }
  //   }
  // };

  return (
    <>
      {post_status ? (
        history.push("/")
      ) : (
        <MainSC>
          <PageHeading title="お知らせ編集プレビュー" />
          <NewsDetailWrapperBlockSC>
            <NewsDetailOuterBlockSC>
              <NewsDetailInnerTitle>現在公開中のお知らせ</NewsDetailInnerTitle>
              <NewsDetailInnerBlockSC className="first">
                <NewsDetailBlockSC>
                  {message === "" ? (
                    <NewsEditPreviewContent
                      title={current_news_title}
                      content={current_news_content}
                    />
                  ) : (
                    <NewsDetailNotFoundBlock>
                      該当の記事はありません
                      <NotFoundLinkBlock>
                        <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                      </NotFoundLinkBlock>
                    </NewsDetailNotFoundBlock>
                  )}
                </NewsDetailBlockSC>
              </NewsDetailInnerBlockSC>
            </NewsDetailOuterBlockSC>
            <NewsDetailOuterBlockSC>
              <NewsDetailInnerTitle>現在下書き中の記事</NewsDetailInnerTitle>
              <NewsDetailInnerBlockSC>
                <NewsDetailBlockSC>
                  {message === "" ? (
                    <>
                      {editorIsToggled ? (
                        <NewsEditPreviewContent
                          title={savedTempTitle}
                          content={savedTempContent}
                        />
                      ) : news_is_editing ? (
                        newsTitleIsEdited ? (
                          <NewsEditPreviewContent
                            title={title}
                            content={edit_news_content}
                          />
                        ) : (
                          <NewsEditPreviewContent
                            title={edit_news_title_draft}
                            content={edit_news_content}
                          />
                        )
                      ) : newsTitleIsEdited ? (
                        <NewsEditPreviewContent
                          title={title}
                          content={edit_news_draft}
                        />
                      ) : (
                        <NewsEditPreviewContent
                          title={edit_news_title_draft}
                          content={edit_news_draft}
                        />
                      )}
                    </>
                  ) : (
                    <NewsDetailNotFoundBlock>
                      該当の記事はありません
                      <NotFoundLinkBlock>
                        <NotFoundLink to="/">TOPへもどる</NotFoundLink>
                      </NotFoundLinkBlock>
                    </NewsDetailNotFoundBlock>
                  )}
                </NewsDetailBlockSC>
              </NewsDetailInnerBlockSC>
            </NewsDetailOuterBlockSC>
          </NewsDetailWrapperBlockSC>

          <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
            <SubmitButtonSC
              id="2"
              onClick={e => handleRegister(e.currentTarget.id)}
            >
              記事として公開
            </SubmitButtonSC>
            <SubmitButtonSC
              id="1"
              onClick={e => handleRegister(e.currentTarget.id)}
            >
              非公開記事として保存
            </SubmitButtonSC>
            <SubmitButtonSC
              id="3"
              onClick={e => handleRegister(e.currentTarget.id)}
            >
              下書きとして保存
            </SubmitButtonSC>
            <BackButtonSC to={`/news/${edit_news_id}/edit`}>戻る</BackButtonSC>
          </FixedButtonAreaSC>
        </MainSC>
      )}
    </>
  );
}

export default NewsEditPreview;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const NewsDetailWrapperBlockSC = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
`;

const NewsDetailOuterBlockSC = styled.div`
  width: 100%;

  ${pc`
    width: 50%;
    max-width: 810px;
  `}
`;

const NewsDetailInnerBlockSC = styled.div`
  &.first {
    border-right: 1px solid ${color.divide_border};
    padding-right: 2%;
    margin-right: 2%;
  }
`;

const NewsDetailInnerTitle = styled.p`
  color: ${color.gray};
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 16px;
  `}
`;

const NewsDetailBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  ${pc`
    max-width: 810px;
    // width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    margin-bottom: 0;
    padding: 30px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 70px;
  padding: 14px 0 12px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -30px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  background-color: ${color.primary};
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BackButtonSC = styled(Link)`
  ${buttonBaseStyle}
  margin-right: 12px;
  border: 1px solid ${color.primary};
  color: ${color.primary};
  background-color: ${color.white};
  opacity: 1;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const NewsDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;
const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
