import { css } from 'styled-components'
import color from './color'
import { pc } from './mediaQuery'
import pullDown from "../../images/icons/多角形_1.svg"


// ページHedingの基本CSS
const headingBaseStyle = css`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
`;

/* containerの基本CSS */
const containerBaseStyle = css`
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0,0,0,0.16);
  background-color: #fff;
  padding: 30px;
`;

/* inputの基本CSS */
/* selectBoxのときは対象要素にclassName="select"をつける */
const inputBaseStyle = css`
  border-radius: 4px;
  border: solid 1px #BBB;
  padding: 8px 12px 9px 12px;
  box-sizing: border-box;
  font-size: 14px;
  color: ${color.gray};
  cursor: pointer;
  &::placeholder {
    color: #BBB;
  }
  &.select {
    background-image:url(${pullDown});
    background-position: right 12px center;
    background-repeat: no-repeat;
    border: solid 1px ${color.primary};
  }
  &.alert {
    background-color: #FFF5F2;
    border: solid 1px #EA3729;
  }
`

const buttonBaseStyle = css`
  width: 160px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  color: ${color.white};
  font-size: 14px;
  font-weight: bold;
  background-color: ${color.primary};
  opacity: 0.3;
  ${pc`
    height: 38px;
  `}
  &:hover {
    cursor: default;
  }
  &.active {
    opacity: 1;
    &:hover {
      cursor: pointer;
    }
  }
`

export {headingBaseStyle, containerBaseStyle, inputBaseStyle, buttonBaseStyle}