import { GET_ACCOUNTS_REQUESTED } from "./type";

// ================================================================= //
// Interface
// ----------------------------------------------------------------- //


// ================================================================= //
// Actions
// ----------------------------------------------------------------- //

export const getAccountsRequest = (token: string, accounts_page_num: number, accounts_per_page: number, email_to_search: string) => {
  return {
    type: GET_ACCOUNTS_REQUESTED,
    token,
    accounts_page_num,
    accounts_per_page,
    email_to_search
  };
};
