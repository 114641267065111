import { all, call, put, takeLatest } from "redux-saga/effects";
import { GET_FACILITY_USERS_SUCCEEDED } from "store/camera/facility_users/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { getFacilityUsersApi } from "../api/facility-users";
import { GET_FACILITIES_ME_REQUESTED } from "store/camera/facilities_me/type";

function* getFacilityUsers(action: any): Generator<any, any, any> {
  try {
    const response = yield call(getFacilityUsersApi, action.token);
    console.log(response);
    yield put({
      type: GET_FACILITY_USERS_SUCCEEDED,
      facility_users: response.data.users
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const cameraFacillityUsersSaga = [
  takeLatest(GET_FACILITIES_ME_REQUESTED, getFacilityUsers)
];

export default cameraFacillityUsersSaga;
