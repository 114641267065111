import {
  TOGGLE_CATEGORY_TO_BE_SEARCHED_CHECK,
  SET_CATEGORY_TO_BE_SEARCHED_CHECK,
} from "./type";
// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// ================================================================= //
// Actions
// ----------------------------------------------------------------- //

export const toggleCategoryToBeSearchedCheck = (category_name: string) => {
  return {
    type: TOGGLE_CATEGORY_TO_BE_SEARCHED_CHECK,
    category_name,
  };
};

export const setCategoryToBeSearchedCheck = (category_name: string[]) => {
  return {
    type: SET_CATEGORY_TO_BE_SEARCHED_CHECK,
    category_name,
  };
};
