import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc, minSp } from "../../../mixins/mediaQuery";
import { useLocation } from "react-router-dom";
import { color } from "../../../mixins";
import selectArrow from "../../../../images/icons/多角形_1.svg";
import { setPerPage } from "../../../../store/common/paginate/action";
import { getArticlesStocksRequest } from "../../../../store/faq/articles_stocked/action";
import pageNumControl from "../../../Common/PaginationPageControl";
import { animateScroll as scroll } from "react-scroll";

function ArticleStockPagination(props: any) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);

  const user_id = useSelector((state: any) => state.session.id);

  const handlePageNumClick = (e: any, page_num: number) => {
    scroll.scrollToTop({ duration: 200 });
    dispatch(
      getArticlesStocksRequest(token, user_id, page_num, paginate_per_page)
    );
  };

  const paginate_stock_total = useSelector(
    (state: any) => state.paginate.article_stock_total
  );
  const paginate_stock_current_page = useSelector(
    (state: any) => state.paginate.article_stock_current_page
  );
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.article_stock_per_page
  );
  const keyword = useSelector(
    (state: any) => state.paginate.article_stock_keyword
  );
  // ページネーションの数字の数
  const pagenate_stock_num_array_count = Math.floor(
    paginate_stock_total / paginate_per_page
  );
  const rest_articles_stock = paginate_stock_total % paginate_per_page;
  const articles_stock_in_rest_page =
    rest_articles_stock !== 0 ? rest_articles_stock : 0;
  const stock_last_page =
    rest_articles_stock !== 0
      ? pagenate_stock_num_array_count + 1
      : pagenate_stock_num_array_count;

  const PageNumStockItems: any = [];

  for (let step = 1; step <= pagenate_stock_num_array_count; step++) {
    PageNumStockItems.push(
      <PageNum
        className={paginate_stock_current_page === step ? "current" : ""}
        onClick={(e: any) => handlePageNumClick(e, step)}
      >{`${step.toString()}`}</PageNum>
    );
  }

  if (articles_stock_in_rest_page !== 0) {
    PageNumStockItems.push(
      <PageNum
        className={
          paginate_stock_current_page === pagenate_stock_num_array_count + 1
            ? "current"
            : ""
        }
        onClick={(e: any) =>
          handlePageNumClick(e, pagenate_stock_num_array_count + 1)
        }
      >{`${(pagenate_stock_num_array_count + 1).toString()}`}</PageNum>
    );
  }

  const pageNumbers = pageNumControl(
    PageNumStockItems,
    paginate_stock_current_page,
    PageNum,
    handlePageNumClick
  );

  const handlePaginatePerPage = (e: any) => {
    dispatch(
      getArticlesStocksRequest(
        token,
        user_id,
        1,
        Number(e.target.value),
        keyword
      )
    );
    dispatch(setPerPage(Number(e.target.value), "article_stock"));
  };

  return (
    <PaginationContainer className={props.position}>
      <PaginationRightBlock>
        <PaginationInfo>
          <>
            全<span>{paginate_stock_total}</span>件中
            <span>
              {paginate_stock_current_page === 1
                ? "1"
                : (paginate_stock_current_page - 1) * paginate_per_page + 1}
            </span>
            〜
            <span>
              {paginate_stock_current_page !== stock_last_page
                ? paginate_stock_current_page * paginate_per_page
                : (stock_last_page - 1) * paginate_per_page +
                  articles_stock_in_rest_page}
            </span>
            件を表示
          </>
        </PaginationInfo>
        <PageNums>
          {paginate_stock_current_page !== 1 && (
            <PageNum
              onClick={(e: any) =>
                handlePageNumClick(e, paginate_stock_current_page - 1)
              }
            >
              前へ
            </PageNum>
          )}
          <>
            {pageNumbers.map((item: any) => {
              return item;
            })}
          </>

          {paginate_stock_current_page !== stock_last_page && (
            <PageNum
              onClick={(e: any) =>
                handlePageNumClick(e, paginate_stock_current_page + 1)
              }
            >
              次へ
            </PageNum>
          )}
        </PageNums>
      </PaginationRightBlock>
      <PaginationLeftBlock>
        <ShowPagesSelect
          className="select"
          name="paginate_per_page"
          id="paginate_per_page"
          value={paginate_per_page}
          onChange={handlePaginatePerPage}
        >
          <option value="20">20件表示</option>
          <option value="50">50件表示</option>
          <option value="100">100件表示</option>
        </ShowPagesSelect>
      </PaginationLeftBlock>
    </PaginationContainer>
  );
}

export default ArticleStockPagination;

const PaginationContainer = styled.div`
  font-size: 12px;
  color: ${color.gray};
  margin-bottom: 12px;
  padding: 0 16px;
  ${minSp`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
  ${pc`
    align-items: center;
    padding: 0;
  `}
  &.bottom {
    margin-bottom: 0px;
    padding-top: 12px;
  }
`;

const PaginationRightBlock = styled.div`
  margin-bottom: 12px;
  ${minSp`
    margin-bottom: 0;
  `}
  ${pc`
    display: flex;
  `}
`;

const PaginationInfo = styled.p`
  margin-bottom: 12px;
  padding-top: 5px;
  ${pc`
    margin-bottom: 0;
    margin-right: 20px;
  `}
  span {
    color: ${color.gray};
    margin: 0 4px;
  }
`;

const PageNums = styled.ul`
  display: flex;
`;
const PageNum = styled.li`
  padding: 5px 7px;
  margin-right: 6px;
  cursor: pointer;
  color: ${color.primary};
  &.current {
    color: ${color.white};
    border-radius: 50%;
    background-color: ${color.primary};
  }
  &.leader {
    padding: 5px 0px;
  }
`;

const PaginationLeftBlock = styled.div``;

const ShowPagesSelect = styled.select`
  border: solid 1px ${color.primary};
  height: 26px;
  width: 96px;
  padding: 5px 7px;
  border-radius: 14px;
  font-size: 12px;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: right 7px top 9px;
  color: ${color.gray};
  ${pc`
    height: 22px;
    padding: 5px 7px;
    border-radius: 11px;
    font-size: 10px;
    background-position: right 7px top 7px;
  `}
`;
