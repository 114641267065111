export default {
  primary: "#298948",
  primary_light: "#396d3f",
  primary_white: "#F2F2F2",
  white: "#FFF",
  black: "#444",
  gray: "#444",
  light_gray: "#888",
  gray_placeholder: "#BBB",
  divide_border: "#E0E0E0",
  gray_border: "#BBB",
  list_border: "#E0E0E0",
  list_header: "#F2F2F2",
  category_bg: "#BBBBBB",
  tag_color: "#68a07a",
  modal_bg: "rgba(68, 68, 68, 0.3)",
  inquiries_item_bg: "#F2F2F2",
  inquiries_gray: "#68a07a",
  inquiries_black: "#298948",
  inquiries_light_gray: "#F2F2F2",
  wysiwyg_color: "#393939",
  wysiwyg_border: "#a3a3a3",
};
