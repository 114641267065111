import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetResponseStatus } from "./store/session/action";
import { useLocation } from "react-router-dom";

const ResetResponseErrorModule = (props: { history: any }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;

  const response_status = useSelector((state: any) => state.session.response_status) || 200;

  React.useEffect(() => {
    console.log(pathName);
    console.log("response_status", response_status);
    if (pathName !== response_status.toString()) {
      dispatch(resetResponseStatus());
    }
    if (response_status !== 200) {
      props.history.push(`/${response_status.toString()}`);
    }
  }, [dispatch, response_status, pathName, props.history]);

  return <></>;
};

export default ResetResponseErrorModule;
