import {
  TOGGLE_MONTHLY_EVENT_ITEM_TO_DELETE,
  RESET_MONTHLY_EVENT_ITEM_TO_DELETE_ARRAY
} from "./type";

interface EditEventClass {
  class_age: string;
  checked: boolean;
}

export const toggleMonthlyEventItemToDelete = (
  monthly_event_item_to_delete_id: number
) => {
  return {
    type: TOGGLE_MONTHLY_EVENT_ITEM_TO_DELETE,
    monthly_event_item_to_delete_id
  };
};

export const resetMonthlyEventItemToDeleteArray = () => {
  return {
    type: RESET_MONTHLY_EVENT_ITEM_TO_DELETE_ARRAY
  }
}