import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setSearchParams,
  searchMediaFilesRequested,
  resetSearchParams
} from "store/camera/mediafiles/action";
import { SearchParams } from "store/camera/mediafiles/reducer";
import { numArrayGen } from "utils/numArrayGen";
import { isMultiValue } from "components/Camera/type";
import { AlbumViewType } from "components/Camera/type";
import { formatdate } from "utils/formatDate";
import { useEffect, useCallback } from "react";

export const useSearchBlock = (token: string) => {
  const dispatch = useDispatch();
  const setSearchParamsDispatch = useCallback(
    (target: keyof SearchParams, value: string | number[] | boolean | number) => {
      dispatch(setSearchParams(target, value));
    },
    [dispatch]
  );

  const searchParams: SearchParams = useSelector(
    (state: RootState) => state.mediafiles.search_params
  );
  const view: AlbumViewType = useSelector(
    (state: RootState) => state.mediafiles.albumViewType
  );

  const { tag_id, class_id, taken_from, taken_to, only_doesnt_have_class } = searchParams;
  // 対象期間：開始日
  const setTakenFromForSearch = (date: Date | [Date, Date] | null) => {
    if (date instanceof Date) {
      const value = formatdate(date);
      if (value) {
        setSearchParamsDispatch("taken_from", value);
      } else {
        setSearchParamsDispatch("taken_from", "");
      }
    }
  };
  // 対象期間：終了日
  const setTakenToForSearch = useCallback(
    (date: Date | [Date, Date] | null) => {
      if (date instanceof Date) {
        const value = formatdate(date);
        if (value) {
          setSearchParamsDispatch("taken_to", value);
        } else {
          setSearchParamsDispatch("taken_to", "");
        }
      }
    },
    [setSearchParamsDispatch]
  );

  const setPageNumberForSearch = (page: string) => {
    setSearchParamsDispatch("page", page);
  }

  // set fileId for search
  const setFileIdForSearch = (fileId: string) => {
    setSearchParamsDispatch("file_id", fileId);
  };

  // 対象タグ
  const setTagIdsForSearch = (id: number) => {
    const value = numArrayGen(tag_id!, id);
    setSearchParamsDispatch("tag_id", value);
  };
  // 対象クラス
  const setClassIdsForSearch = (id: number) => {
    console.log('setClassIdsForSearch ==> ' + id)
    // const value = numArrayGen(class_id!, id);
    // setSearchParamsDispatch("class_id", value);

    if (id !== 99) {
      const value = numArrayGen(class_id!, id);
      setSearchParamsDispatch("class_id", value);
    }
    else if ( id === 99) {
      setSearchParamsDispatch("only_doesnt_have_class", only_doesnt_have_class === 1 ? 0 : 1 );
    }
  };
  // 公開非公開
  const setShareBanForSearch = (value: boolean) => {
    setSearchParamsDispatch("share_ban", value);
  };
  // アップロード者
  const setUploadUserIdsForSearch = (v: isMultiValue) => {
    const value = v.map(option => Number(option.value));
    setSearchParamsDispatch("upload_user_id", value);
  };
  // 対象施設
  const setFacilityIdsForSearch = (v: isMultiValue) => {
    const value = v.map(opiton => Number(opiton.value));
    setSearchParamsDispatch("facility_id", value);
    dispatch(
      searchMediaFilesRequested(token, view, {
        ...searchParams,
        facility_id: value
      })
    );
  };
  const resetTakenFrom = () => {
    setSearchParamsDispatch("taken_from", "");
  };
  const resetTakenTo = () => {
    setSearchParamsDispatch("taken_to", "");
  };
  const OnResetSearchParams = () => {
    dispatch(resetSearchParams());
    setTimeout(() => dispatch(searchMediaFilesRequested(token, view, searchParams)), 300);
  };
  const onSearchFunction = () => {
    if ((taken_from && !taken_to) || (!taken_from && taken_to)) {
      window.alert(
        "撮影期間を指定する場合は開始と終了の両方を選択してください。"
      );
    } else {
      dispatch(searchMediaFilesRequested(token, view, searchParams));
    }
  };

  useEffect(() => {
    if (taken_from && taken_to) {
      const from = new Date(taken_from).getTime();
      const to = new Date(taken_to).getTime();
      if (to < from) {
        window.alert("終了日は開始日より後ろの日付を選択してください");
        setTakenToForSearch(new Date(taken_from));
      }
    }
  }, [taken_from, taken_to, setTakenToForSearch]);

  return {
    onChangeFrom: setTakenFromForSearch,
    onChangeTo: setTakenToForSearch,
    onChangeTags: setTagIdsForSearch,
    onChangeShareBan: setShareBanForSearch,
    onChangeClasses: setClassIdsForSearch,
    onChangeUsers: setUploadUserIdsForSearch,
    onChangeFacility: setFacilityIdsForSearch,
    onSearchFunc: onSearchFunction,
    from: taken_from ? new Date(taken_from) : null,
    to: taken_to ? new Date(taken_to) : null,
    searchParams,
    onResetFunc: OnResetSearchParams,
    onResetTakenFrom: resetTakenFrom,
    onResetTakenTo: resetTakenTo,
    onChangeFileIdHook: setFileIdForSearch,
    setPageNumberHook: setPageNumberForSearch
  };
};
