import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { TOGGLE_FACILITY_MODAL, GET_FACILITIES_SUCCEEDED } from "./type";

interface Facilities {
  id: number;
  name: string;
  area_name: string;
}

const initialState: Facilities[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_FACILITIES_SUCCEEDED:
      console.log("action facilities", action);
      return [...action.facilities];

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
