import axios, { AxiosResponse } from "axios";
import { env_manual_url } from "serverEnv";
import {
  GetPagesResponseItem,
  UpdatePageRequestBody,
  DeletePageResponse,
  UpdateAllPagesRequestBodyItem,
  UpdatePagesAllResponse,
  CreatePageImageRequestBody,
  CreatePageImageResponse,
  ReadPageResponse,
  GetPageStatusesResponse,
  UpdatePageResponse
} from "store/manual/manuals/pages/types.d";

// ページ一覧取得
export const getPagesApi = (data: {
  token: string;
  type_id?: number;
  topic_id?: number;
  statuses?: number[];
  tags?: string[];
  keyword?: string;
}) => {
  const { token, type_id, topic_id, statuses, tags, keyword } = data;
  try {
    const result: Promise<AxiosResponse<GetPagesResponseItem[]>> = axios.get(
      `${env_manual_url}/contents`,
      {
        params: {
          type: type_id,
          topic: topic_id,
          statuses: statuses,
          tags: tags,
          keyword: keyword
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ検索取得
export const getPagesSearchApi = (data: {
  token: string;
  type_id?: number;
  topic_id?: number;
  // statuses?: number[];
  tags?: string[];
  keyword?: string;
}) => {
  const { token, tags, keyword, type_id, topic_id } = data;
  try {
    const result: Promise<AxiosResponse<GetPagesResponseItem[]>> = axios.get(
      `${env_manual_url}/contents`,
      {
        params: {
          tags: tags,
          type: type_id,
          topic: topic_id,
          keyword: keyword
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ詳細取得
export const getPageApi = (data: { token: string; id: string }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<GetPagesResponseItem>> = axios.get(
      `${env_manual_url}/contents/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ詳細更新
export const updatePageApi = (data: {
  token: string;
  id: string;
  req_body: UpdatePageRequestBody;
}) => {
  console.log("data in axios updatePageApi", data);
  try {
    const { token, id, req_body } = data;
    const result: Promise<AxiosResponse<UpdatePageResponse>> = axios.put(
      `${env_manual_url}/contents/${id}`,
      req_body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ削除
export const deletePageApi = (data: { token: string; id: string }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<DeletePageResponse>> = axios.delete(
      `${env_manual_url}/contents/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ一括更新
export const updatePagesAllApi = (data: {
  token: string;
  req_body: UpdateAllPagesRequestBodyItem[];
}) => {
  try {
    const { token, req_body } = data;

    const result: Promise<AxiosResponse<UpdatePagesAllResponse>> = axios.post(
      `${env_manual_url}/contents/all`,
      {
        contents: req_body
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ画像登録
export const createManualImageApi = (data: {
  token: string;
  req_body: CreatePageImageRequestBody;
}) => {
  try {
    const { token, req_body } = data;
    const result: Promise<AxiosResponse<CreatePageImageResponse>> = axios.post(
      `${env_manual_url}/contents/image`,
      { ...req_body },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページ既読登録
export const readPageApi = (data: { token: string; id: number }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<ReadPageResponse>> = axios.post(
      `${env_manual_url}/contents/image`,
      {
        content_id: id
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

// ページステータス一覧取得
export const getPageStatusesApi = (data: { token: string }) => {
  const { token } = data;
  try {
    const result: Promise<AxiosResponse<GetPageStatusesResponse>> = axios.get(
      `${env_manual_url}/contents`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};
