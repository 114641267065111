import { TopicsUpdateAllRequestBodyItem } from "./types.d";
import {
  GET_TOPICS_REQUESTED,
  GET_TOPIC_REQUESTED,
  UPDATE_ALL_TOPICS_REQUESTED
} from "./types";

export const getTopicsRequest = (data: { token: string; type_id: number }) => {
  const { token, type_id } = data;
  return {
    type: GET_TOPICS_REQUESTED,
    token,
    type_id
  };
};

export const getTopicRequest = (data: { token: string; id: number }) => {
  const { token, id } = data;
  return {
    type: GET_TOPIC_REQUESTED,
    token,
    id
  };
};

export const updateAllTopicsRequest = (data: {
  token: string;
  id: number | null;
  req_body: TopicsUpdateAllRequestBodyItem[];
  to_be_deleted_ids: (number | null)[] | null;
}) => {
  const { token, req_body, to_be_deleted_ids, id } = data;
  return {
    type: UPDATE_ALL_TOPICS_REQUESTED,
    token,
    req_body,
    to_be_deleted_ids,
    id
  };
};
