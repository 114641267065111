import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc, minSp } from "../../mixins/mediaQuery";
import { color, inputBaseStyle, buttonBaseStyle } from "../../mixins";
import deleteIcon from "../../../images/icons/close.svg";
import {
  deleteCategoryItemFromView,
  updateViewCategoryName,
  updateViewCategoryOrder,
} from "../../../store/faq/categories/action";
import {
  addToBeCreated,
  changeNameToBeCreated,
  changeOrderToBeCreated,
  deleteToBeCreated,
} from "../../../store/faq/categories_to_be_created/action";
import {
  addToBeUpdated,
  changeNameToBeUpdated,
  changeOrderToBeUpdated,
  deleteToBeUpdated,
} from "../../../store/faq/categories_to_be_updated/action";
import { addToBeDeleted } from "../../../store/faq/categories_to_be_deleted/action";

const CategoryList = () => {
  const categories = useSelector((state: any) => state.categories);
  const categories_to_be_created = useSelector(
    (state: any) => state.categories_to_be_created
  );
  const dispatch = useDispatch();

  // ==========================================================================
  // 新規カテゴリ
  // ==========================================================================
  const handleAddCategory = (e: any) => {
    console.log("clicked");
    e.preventDefault();
    dispatch(addToBeCreated());
  };
  // 新規:カテゴリー欄を削除
  const handleDeleteAddedCategory = (e: any, index: number) => {
    e.preventDefault();
    const target_remove_index = index;
    dispatch(deleteToBeCreated(target_remove_index));
  };
  // 新規:カテゴリ名編集
  const handleChangeNameTobeCreated = (e: any) => {
    const changed_name = e.target.value;
    const changed_target_id = Number(e.target.id);
    dispatch(changeNameToBeCreated(changed_name, changed_target_id));
  };
  //新規:並び順編集
  const handleChangeOrderTobeCreated = (e: any) => {
    const changed_order = Number(e.target.value);
    const changed_target_id = Number(e.target.id);
    dispatch(changeOrderToBeCreated(changed_order, changed_target_id));
  };
  // ==========================================================================

  // ==========================================================================
  // 既存カテゴリ
  // ==========================================================================
  //フォーカスが当たったらto_be_updatedに追加
  const handleUpdateFocus = (e: any, categories: []) => {
    const update_target_id = Number(e.target.id);
    dispatch(addToBeUpdated(update_target_id, categories));
  };

  const handleUpdateCategoryName = (e: any) => {
    const updated_name = e.target.value;
    const updated_target_id = Number(e.target.id);
    dispatch(updateViewCategoryName(updated_name, updated_target_id));
    dispatch(changeNameToBeUpdated(updated_name, updated_target_id));
  };

  // カテゴリー並び順編集
  const handleUpdateCategoryOrder = (e: any) => {
    const updated_order = Number(e.target.value);
    const updated_target_id = Number(e.target.id);
    dispatch(updateViewCategoryOrder(updated_order, updated_target_id));
    dispatch(changeOrderToBeUpdated(updated_order, updated_target_id));
  };

  // カテゴリー削除(VIEW)
  const handleRemoveCategory = (e: any, index: number, categories: []) => {
    e.preventDefault();
    const target_remove_index = index;
    dispatch(addToBeDeleted(target_remove_index, categories));
    dispatch(deleteCategoryItemFromView(target_remove_index));
  };
  // ==========================================================================

  return (
    <CategoryListContainerSC>
      <form action="">
        <CategoryListHeadingListSC>
          <CategoryListHeadingItemSC>カテゴリ名</CategoryListHeadingItemSC>
          <CategoryListHeadingItemSC>並び順</CategoryListHeadingItemSC>
          <CategoryListHeadingItemSC>該当記事件数</CategoryListHeadingItemSC>
          <CategoryListHeadingItemSC>削除</CategoryListHeadingItemSC>
        </CategoryListHeadingListSC>
        <div>
          {categories[0] === null
            ? ""
            : categories.map((category: any, index: number) => {
                return (
                  <CategoryListSC>
                    <CategoryListItemSC>
                      <CategoryListInputSC
                        className="name"
                        type="text"
                        id={category.id}
                        value={category.name}
                        onChange={handleUpdateCategoryName}
                        onFocus={(e) => handleUpdateFocus(e, categories)}
                      />
                    </CategoryListItemSC>
                    <CategoryListItemSC>
                      <CategoryListInputSC
                        className="order"
                        type="number"
                        id={category.id}
                        value={category.order}
                        onChange={handleUpdateCategoryOrder}
                        onFocus={(e) => handleUpdateFocus(e, categories)}
                        min={0}
                      />
                    </CategoryListItemSC>
                    {category.articles_count ? (
                      <CategoryListItemSC>
                        {category.articles_count}
                      </CategoryListItemSC>
                    ) : (
                      <CategoryListItemSC />
                    )}
                    <CategoryListItemSC>
                      <CategoryListDeleteButtonSC
                        onClick={(e) =>
                          handleRemoveCategory(e, index, categories)
                        }
                      />
                    </CategoryListItemSC>
                  </CategoryListSC>
                );
              })}
          {categories_to_be_created.map((add_item: any, index: number) => {
            return (
              <CategoryListSC>
                <CategoryListItemSC>
                  <CategoryListInputSC
                    id={add_item.temp_id}
                    className="name"
                    type="text"
                    value={add_item.name}
                    onChange={handleChangeNameTobeCreated}
                  />
                </CategoryListItemSC>
                <CategoryListItemSC>
                  <CategoryListInputSC
                    id={add_item.temp_id}
                    className="order"
                    type="number"
                    min={0}
                    value={add_item.order}
                    onChange={handleChangeOrderTobeCreated}
                  />
                </CategoryListItemSC>
                {add_item.articles_count ? (
                  <CategoryListItemSC>
                    {add_item.articles_count}
                  </CategoryListItemSC>
                ) : (
                  <CategoryListItemSC />
                )}
                <CategoryListItemSC>
                  <CategoryListDeleteButtonSC
                    onClick={(e) => handleDeleteAddedCategory(e, index)}
                  />
                </CategoryListItemSC>
              </CategoryListSC>
            );
          })}
        </div>
        <CategoryListSC as="div">
          <CategoryListAddButtonSC onClick={handleAddCategory}>
            追加
          </CategoryListAddButtonSC>
        </CategoryListSC>
      </form>
    </CategoryListContainerSC>
  );
};

export default CategoryList;

// ===================================================================================
// スタイル
// ===================================================================================

const CategoryListContainerSC = styled.div`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
  overflow: auto;
  ${minSp`
    overflow: hidden;
  `}
  form {
    width: 440px;
    ${minSp`
      width: auto;
    `}
  }
`;

const CategoryListHeadingListSC = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${color.primary_white};
  border-bottom: 1px solid ${color.list_border};
  color: ${color.tag_color};
  font-size: 14px;
  padding: 13px 10px;

  ${pc`
    max-width: 750px;
    padding: 13px 20px;
  `}
`;

const CategoryListHeadingItemSC = styled.li`
  font-size: 14px;
  color: ${color.gray};

  &:nth-child(1) {
    min-width: 130px;
    width: 40%;
    ${pc`
      width: 50%;
    `}
  }
  &:nth-child(2) {
    min-width: 90px;
    width: 20%;
  }
  &:nth-child(3) {
    width: 30%;
    ${pc`
      width: 20%;
    `}
  }
  &:nth-child(4) {
    width: 10%;
    text-align: right;
  }
`;

const CategoryListSC = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
  ${pc`
    padding: 12px 20px;
  `}
`;

const CategoryListItemSC = styled(CategoryListHeadingItemSC)`
  color: ${color.gray};
`;

const CategoryListInputSC = styled.input`
  ${inputBaseStyle}
  border-color: ${color.category_bg};
  &.name {
    width: 85%;
  }
  &.order {
    max-width: 80px;
  }
  &::placeholder {
    color: ${color.primary};
  }
`;

const CategoryListDeleteButtonSC = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(${deleteIcon});
  background-size: 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
`;
const CategoryListAddButtonSC = styled.button`
  ${buttonBaseStyle}
  opacity: 1;
  background-color: #fff;
  color: ${color.primary};
  border: 1px solid ${color.primary};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition-duration: 0.2s;
  }
`;
