import { RootState } from "store/store";
export const manualTagsSelector = (state: RootState) => {
  return state.manual_tags.tags;
};
export const toBeCreateManualTagSelector = (state: RootState) => {
  return state.manual_tags.tags_to_be_created;
};

export const toBeDeletedManualTagSelector = (state: RootState) => {
  return state.manual_tags.tags_to_be_deleted;
};

export const toBeUpdatedManualTagSelector = (state: RootState) => {
  return state.manual_tags.tags_to_be_updated;
};
