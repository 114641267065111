import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_EVENT_ADD_INITIAL_DATE,
  SET_ADD_EVENT_DATE,
  SET_ADD_EVENT_TYPE,
  SET_ADD_EVENT_CONTENT,
  SET_ADD_EVENT_DETAIL,
  SET_ADD_EVENT_CLASS,
  RESET_ADD_EVENT_CONTENT,
  RESET_ADD_EVENT_CLASS,
} from "./type";

interface EventAddClass {
  class_age: string;
  checked: boolean;
}
interface Event {
  date: string;
  type_id: number | string;
  type_name: string;
  content: string;
  detail: string;
  classes: EventAddClass[];
  kind: number | null;
}

const initialState: Event = {
  date: "",
  type_id: "",
  type_name: "",
  content: "",
  detail: "",
  classes: [
    {
      class_age: "0",
      checked: false,
    },
    {
      class_age: "1",
      checked: false,
    },
    {
      class_age: "2",
      checked: false,
    },
    {
      class_age: "3",
      checked: false,
    },
    {
      class_age: "4",
      checked: false,
    },
    {
      class_age: "5",
      checked: false,
    },
  ],
  kind: 1,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_EVENT_ADD_INITIAL_DATE:
      return {
        ...state,
        yearMonthDate: action.yearMonthDate,
        year: action.year,
        month: action.month,
        date: action.date,
      };

    case RESET_ADD_EVENT_CONTENT:
      return {
        ...state,
        type_id: "",
        content: "",
        detail: "",
        class_age: "",
      };

    case SET_ADD_EVENT_DATE:
      return {
        ...state,
        year: action.year,
        month: action.month,
        date: action.date,
      };

    case SET_ADD_EVENT_TYPE:
      return {
        ...state,
        type_id: action.type_id,
        type_name: action.type_name,
      };

    case SET_ADD_EVENT_CONTENT:
      return {
        ...state,
        content: action.content,
      };

    case SET_ADD_EVENT_DETAIL:
      return {
        ...state,
        detail: action.detail,
      };

    case SET_ADD_EVENT_CLASS:
      console.log("action.classes_class_age", action.classes_class_age);
      const updated_classes = state.classes.map((class_item: EventAddClass) => {
        if (class_item.class_age === action.classes_class_age) {
          return {
            class_age: class_item.class_age,
            checked: !class_item.checked,
          };
        }
        return class_item;
      });
      return {
        ...state,
        classes: updated_classes,
      };

    case RESET_ADD_EVENT_CLASS:
      return {
        ...state,
        classes: [
          {
            class_age: "0",
            checked: false,
          },
          {
            class_age: "1",
            checked: false,
          },
          {
            class_age: "2",
            checked: false,
          },
          {
            class_age: "3",
            checked: false,
          },
          {
            class_age: "4",
            checked: false,
          },
          {
            class_age: "5",
            checked: false,
          },
        ],
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
