import { put, call, takeLatest, all } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  GetManualResponseItem,
  DeleteManualResponse,
  UpdateAllManualsResponse,
  CreateUpdateManualResponse,
  CreateManualImageResponse
} from "store/manual/manuals/types.d";
import { GetTopicsResponseItem } from "store/manual/manuals/topics/types.d";

import {
  getManualsApi,
  createManualApi,
  getManualApi,
  updateManualApi,
  deleteManualApi,
  updateAllManualsApi,
  createManualImageApi
  // deleteManualGenerator
} from "api/manual/manualsApi";

import {
  getTopicsApi,
  getTopicApi,
  deleteTopicApi,
  updateTopicsAllApi
} from "api/manual/topicsApi";

import {
  GET_MANUALS_REQUESTED,
  GET_MANUALS_SUCCEEDED,
  GET_MANUALS_FAILED,
  GET_MANUAL_REQUESTED,
  GET_MANUAL_SUCCEEDED,
  GET_MANUAL_FAILED,
  CREATE_MANUAL_REQUESTED,
  CREATE_MANUAL_SUCCEEDED,
  CREATE_MANUAL_FAILED,
  UPDATE_MANUAL_REQUESTED,
  UPDATE_MANUAL_SUCCEEDED,
  UPDATE_MANUAL_FAILED,
  DELETE_MANUAL_REQUESTED,
  DELETE_MANUAL_SUCCEEDED,
  DELETE_MANUAL_FAILED,
  UPDATE_ALL_MANUALS_REQUESTED,
  UPDATE_ALL_MANUALS_SUCCEEDED,
  UPDATE_ALL_MANUALS_FAILED,
  CREATE_MANUAL_IMAGE_REQUESTED,
  CREATE_MANUAL_IMAGE_SUCCEEDED,
  CREATE_MANUAL_IMAGE_FAILED,
  UPDATE_MANUAL_WITH_IMAGE_REQUESTED,
  UPDATE_MANUAL_WITH_IMAGE_SUCCEEDED,
  UPDATE_MANUAL_WITH_IMAGE_FAILED,
  UPDATE_MANUAL_TITLE_DESC_REQUESTED,
  UPDATE_MANUAL_TITLE_DESC_SUCCEEDED,
  UPDATE_MANUAL_TITLE_DESC_FAILED
} from "store/manual/manuals/types";

import {
  GET_TOPICS_REQUESTED,
  GET_TOPICS_SUCCEEDED,
  GET_TOPICS_FAILED,
  GET_TOPIC_REQUESTED,
  GET_TOPIC_SUCCEEDED,
  GET_TOPIC_FAILED,
  UPDATE_ALL_TOPICS_REQUESTED,
  UPDATE_ALL_TOPICS_SUCCEEDED,
  UPDATE_ALL_TOPICS_FAILED
} from "store/manual/manuals/topics/types";

import {
  GET_PAGES_REQUESTED,
  GET_PAGES_SUCCEEDED,
  GET_PAGES_FAILED,
  GET_PAGE_REQUESTED,
  GET_PAGE_SUCCEEDED,
  GET_PAGE_FAILED,
  UPDATE_ALL_PAGES_REQUESTED,
  UPDATE_ALL_PAGES_SUCCEEDED,
  UPDATE_ALL_PAGES_FAILED,
  UPDATE_PAGE_REQUESTED,
  UPDATE_PAGE_SUCCEEDED,
  UPDATE_PAGE_FAILED,
  GET_PAGES_SEARCH_REQUESTED,
  GET_PAGES_SEARCH_SUCCEEDED,
  GET_PAGES_SEARCH_FAILED
} from "store/manual/manuals/pages/types";

import {
  deletePageApi,
  getPageApi,
  getPagesApi,
  updatePageApi,
  updatePagesAllApi,
  getPagesSearchApi
} from "api/manual/pagesApi";
import {
  GetPagesResponseItem,
  UpdatePageResponse,
  UpdatePageResponseItem
} from "store/manual/manuals/pages/types.d";
import { savePageDetailRequest } from "store/manual/manuals/pages/actions";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";

//　マニュアル一覧取得
function* getManuals(action: any) {
  try {
    const result: Promise<AxiosResponse<GetManualResponseItem[]>> = yield call(
      getManualsApi,
      {
        token: action.token
      }
    );
    yield put({
      type: GET_MANUALS_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_MANUAL_FAILED, error_response: e.response });
    }
  }
}

// =================================================================
// マニュアル詳細情報取得
function* getManual(action: any) {
  try {
    const result: Promise<AxiosResponse<GetManualResponseItem>> = yield call(
      getManualApi,
      {
        token: action.token,
        id: action.id
      }
    );
    yield put({
      type: GET_MANUAL_SUCCEEDED,
      result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_MANUAL_FAILED, error_response: e.response });
    }
  }
}
// マニュアル詳細情報取得ここまで
// =================================================================

// =================================================================
//　マニュアル新規登録
function* createManual(action: any) {
  try {
    const result: Promise<AxiosResponse<CreateUpdateManualResponse>> =
      yield call(createManualApi, {
        token: action.token,
        req_body: action.req_body
      });
    yield put({
      type: CREATE_MANUAL_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: CREATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: CREATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: CREATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: CREATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: CREATE_MANUAL_FAILED, error_response: e.response });
    }
  }
}
//　マニュアル新規登録ここまで
// =================================================================

// =================================================================
//　マニュアル個別更新
function* updateManual(action: any) {
  try {
    const result: Promise<AxiosResponse<CreateUpdateManualResponse>> =
      yield call(updateManualApi, {
        token: action.token,
        req_body: action.req_body
      });

    yield put({
      type: UPDATE_MANUAL_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: UPDATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: UPDATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: UPDATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: UPDATE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: UPDATE_MANUAL_FAILED, error_response: e.response });
    }
  }
}
//　マニュアル個別更新ここまで
// =================================================================

// ===============================================================================
//　マニュアル一覧更新

// マニュアル一括更新時の削除
function* deleteForUpdate(action: any) {
  if (
    action.to_be_deleted_ids !== null &&
    action.to_be_deleted_ids.length > 0
  ) {
    yield all(
      action.to_be_deleted_ids.map((id: any) =>
        call(deleteManualApi, { token: action.token, id: id })
      )
    );
  }
}

// マニュアル一括更新時の新規追加と更新
function* updateForAllUpdate(action: any) {
  yield call(updateAllManualsApi, {
    token: action.token,
    req_body: action.req_body
  });
}

// マニュアル一括更新時の更新後一覧取得
function* getAllForUpdate(action: any) {
  const get_all_result: Promise<AxiosResponse<GetManualResponseItem[]>> =
    yield call(getManualsApi, {
      token: action.token
    });
  console.log("get_all_result", get_all_result);
  return get_all_result;
}

// マニュアル一括更新の本体
function* updateAllManuals(action: any) {
  try {
    yield* deleteForUpdate(action);
    yield* updateForAllUpdate(action);

    const get_all_result = yield* getAllForUpdate(action);

    console.log("get_all_result returned after", get_all_result);
    yield put({
      type: UPDATE_ALL_MANUALS_SUCCEEDED,
      result: get_all_result
    });
  } catch (e) {
    console.log("update all manuals error", e.response);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: UPDATE_ALL_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: UPDATE_ALL_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: UPDATE_ALL_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: UPDATE_ALL_MANUALS_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: UPDATE_ALL_MANUALS_FAILED, error_response: e.response });
    }
  }
}

//　マニュアル一覧更新 ここまで
// ===============================================================================

// ===============================================================================
//　マニュアル削除
function* deleteManual(action: any) {
  try {
    const result: Promise<AxiosResponse<DeleteManualResponse>> = yield call(
      deleteManualApi,
      {
        token: action.token,
        id: action.id
      }
    );

    yield put({
      type: DELETE_MANUAL_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: DELETE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: DELETE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: DELETE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: DELETE_MANUAL_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: DELETE_MANUAL_FAILED, error_response: e.response });
    }
  }
}
//　マニュアル削除ここまで
// ===============================================================================

// ===============================================================================
//　マニュアル画像新規登録
function* createManualImage(action: any) {
  try {
    const result: Promise<AxiosResponse<CreateManualImageResponse>> =
      yield call(createManualImageApi, {
        token: action.token,
        req_body: action.req_body
      });

    yield put({
      type: CREATE_MANUAL_IMAGE_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: CREATE_MANUAL_IMAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: CREATE_MANUAL_IMAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: CREATE_MANUAL_IMAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: CREATE_MANUAL_IMAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: CREATE_MANUAL_IMAGE_FAILED, error_response: e.response });
    }
  }
}
//　マニュアル画像新規登録ここまで
// ===============================================================================

// ===============================================================================
//　トピック一覧画面、マニュアル画像・キャッチ・タイトル更新

// マニュアル一括更新時の新規追加と更新
function* updateManualImageForHeroUpdate(action: any) {
  if (!action.imageToUpload) return;
  try {
    console.log("updateManualImageForHeroUpdate start");
    let formData = new FormData();
    formData.append("file", action.imageToUpload);
    formData.append("type_id", action.hookedManual.id);
    formData.append("file_name", action.imageToUpload.name);
    formData.append("alt", action.imageToUpload.name);
    // const req_body = {
    //   file: action.imageToUpload,
    //   file_name: `${action.hookedManual.id}_image`,
    //   type_id: action.hookedManual.id,
    //   alt: `${action.hookedManual.id}_image`
    // };
    const result: Promise<AxiosResponse<CreateManualImageResponse>> =
      yield call(createManualImageApi, {
        token: action.token,
        req_body: formData
      });
    console.log("updateManualImageForHeroUpdate result", result);
    return result;
  } catch (e) {
    console.log("updateManualImageForHeroUpdate e", e);
  }
}

function* updateManualForHeroUpdate(action: any) {
  try {
    const result: Promise<AxiosResponse<CreateUpdateManualResponse>> =
      yield call(updateManualApi, {
        token: action.token,
        req_body: {
          id: action.hookedManual.id,
          name: action.hookedManual.name,
          catch: action.hookedManual.catch,
          description: action.hookedManual.description,
          order: action.hookedManual.order,
          history_flag: action.hookedManual.history_flag || false
        }
      });
    return result;
  } catch (e) {
    console.log("action", action);
    console.log("e", e);
    throw Error(e.error_response);
  }
}

function* getManualForHeroUpdate(action: any) {
  const result: Promise<AxiosResponse<GetManualResponseItem>> = yield call(
    getManualApi,
    {
      token: action.token,
      id: action.hookedManual.id
    }
  );
  return result;
}

// マニュアル一括更新の本体
function* heroUpdate(action: any) {
  try {
    const update_manual_image_result = yield* updateManualImageForHeroUpdate(
      action
    );
    const update_manual_result = yield* updateManualForHeroUpdate(action);
    const get_result = yield* getManualForHeroUpdate(action);

    console.log("update_manual_image_result", update_manual_image_result);
    console.log("update_manual_result", update_manual_result);
    console.log("get_result returned after", get_result);
    yield put({
      type: UPDATE_MANUAL_WITH_IMAGE_SUCCEEDED,
      result: get_result
    });
  } catch (e) {
    console.log("update hero update error", e);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({
            type: UPDATE_MANUAL_WITH_IMAGE_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({
            type: UPDATE_MANUAL_WITH_IMAGE_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({
            type: UPDATE_MANUAL_WITH_IMAGE_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({
            type: UPDATE_MANUAL_WITH_IMAGE_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      default:
        put({
          type: UPDATE_MANUAL_WITH_IMAGE_FAILED,
          error_response: e.response
        });
    }
  }
}

//　トピック一覧画面、マニュアル画像・キャッチ・タイトル更新ここまで
// ===============================================================================

// ===============================================================================
//　トピック一覧画面、マニュアルタイトル・説明更新

function* updateManualForTitleDescUpdate(action: any) {
  try {
    const result: Promise<AxiosResponse<CreateUpdateManualResponse>> =
      yield call(updateManualApi, {
        token: action.token,
        req_body: {
          id: action.hookedManual.id,
          name: action.hookedManual.name,
          catch: action.hookedManual.catch,
          description: action.hookedManual.description,
          order: action.hookedManual.order,
          history_flag: action.hookedManual.history_flag || false
        }
      });
    return result;
  } catch (e) {
    console.log("action", action);
    console.log("e", e);
    throw Error(e.error_response);
  }
}
function* getManualForTitleDescUpdate(action: any) {
  const result: Promise<AxiosResponse<GetManualResponseItem>> = yield call(
    getManualApi,
    {
      token: action.token,
      id: action.hookedManual.id
    }
  );
  return result;
}

function* titleDescUpdate(action: any) {
  try {
    const update_result = yield* updateManualForTitleDescUpdate(action);
    const get_result = yield* getManualForTitleDescUpdate(action);

    console.log("get_result returned after", get_result);
    yield put({
      type: UPDATE_MANUAL_TITLE_DESC_SUCCEEDED,
      result: get_result
    });
  } catch (e) {
    console.log("update hero update error", e);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({
            type: UPDATE_MANUAL_TITLE_DESC_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({
            type: UPDATE_MANUAL_TITLE_DESC_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({
            type: UPDATE_MANUAL_TITLE_DESC_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({
            type: UPDATE_MANUAL_TITLE_DESC_FAILED,
            error_response: e.response
          })
        ]);
        break;
      }
      default:
        put({
          type: UPDATE_MANUAL_TITLE_DESC_FAILED,
          error_response: e.response
        });
    }
  }
}
//　トピック一覧画面、マニュアルタイトル・説明更新ここまで
// ===============================================================================

// ===============================================================================
//　トピック一覧取得
function* getTopics(action: any) {
  try {
    const result: Promise<AxiosResponse<GetTopicsResponseItem[]>> = yield call(
      getTopicsApi,
      {
        token: action.token,
        type: action.type_id
      }
    );
    yield put({
      type: GET_TOPICS_SUCCEEDED,
      result: result
    });
  } catch (e) {
    console.log("get TOPICS error", e.response);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_TOPICS_FAILED, error_response: e.response });
    }
  }
}

//　トピック一覧取得ここまで
// ===============================================================================

// ===============================================================================
//　トピック詳細取得
function* getTopic(action: any) {
  try {
    const result: Promise<AxiosResponse<GetTopicsResponseItem>> = yield call(
      getTopicApi,
      {
        token: action.token,
        id: action.id
      }
    );
    yield put({
      type: GET_TOPIC_SUCCEEDED,
      result
    });
  } catch (e) {
    console.log("get TOPIC error", e.response);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_TOPIC_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_TOPIC_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_TOPIC_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_TOPIC_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_TOPIC_FAILED, error_response: e.response });
    }
  }
}
//　トピック詳細取得ここまで
// ===============================================================================

// ===============================================================================
//　トピック一覧更新

// トピック一括更新時の削除
function* deleteForTopicsUpdateAll(action: any) {
  if (
    action.to_be_deleted_ids !== null &&
    action.to_be_deleted_ids.length > 0
  ) {
    yield all(
      action.to_be_deleted_ids.map((id: any) =>
        call(deleteTopicApi, { token: action.token, id: id })
      )
    );
  }
}

// トピック一括更新時の新規追加と更新
function* updateForTopicsUpdateAll(action: any) {
  yield call(updateTopicsAllApi, {
    token: action.token,
    req_body: action.req_body
  });
}

// トピック一括更新時の更新後一覧取得
function* getAllForTopicsUpdateAll(action: any) {
  const get_all_result: Promise<AxiosResponse<GetTopicsResponseItem[]>> =
    yield call(getTopicsApi, {
      token: action.token,
      type: action.id
    });
  console.log("get_all_result", get_all_result);
  return get_all_result;
}

// トピック一括更新の本体
function* updateTopicsAll(action: any) {
  try {
    yield* deleteForTopicsUpdateAll(action);
    yield* updateForTopicsUpdateAll(action);

    const get_all_result = yield* getAllForTopicsUpdateAll(action);

    console.log("get_all_result returned after", get_all_result);
    yield put({
      type: UPDATE_ALL_TOPICS_SUCCEEDED,
      result: get_all_result
    });
  } catch (e) {
    console.log("update all TOPICS error", e.response);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: UPDATE_ALL_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: UPDATE_ALL_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: UPDATE_ALL_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: UPDATE_ALL_TOPICS_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: UPDATE_ALL_TOPICS_FAILED, error_response: e.response });
    }
  }
}

//　トピック一覧更新 ここまで
// ===============================================================================

// ===============================================================================
//　ページ一覧取得
function* getPages(action: any) {
  try {
    const result: Promise<AxiosResponse<GetPagesResponseItem[]>> = yield call(
      getPagesApi,
      {
        ...action
      }
    );
    yield put({
      type: GET_PAGES_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_PAGES_FAILED, error_response: e.response });
    }
  }
}
//　ページ一覧取得ここまで
// ===============================================================================

// ===============================================================================
//　ページ一覧検索取得
function* getPagesSearch(action: any) {
  try {
    const result: Promise<AxiosResponse<GetPagesResponseItem[]>> = yield call(
      getPagesSearchApi,
      {
        ...action
      }
    );
    yield put({
      type: GET_PAGES_SEARCH_SUCCEEDED,
      result: result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_PAGES_SEARCH_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_PAGES_SEARCH_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_PAGES_SEARCH_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_PAGES_SEARCH_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_PAGES_SEARCH_FAILED, error_response: e.response });
    }
  }
}
//　ページ一覧取得ここまで
// ===============================================================================

// ===============================================================================
//　ページ詳細取得
function* getPage(action: any) {
  try {
    const result: Promise<AxiosResponse<GetPagesResponseItem>> = yield call(
      getPageApi,
      {
        token: action.token,
        id: action.id
      }
    );
    yield put({
      type: GET_PAGE_SUCCEEDED,
      result
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: GET_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: GET_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: GET_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: GET_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: GET_PAGE_FAILED, error_response: e.response });
    }
  }
}

//　ページ詳細取得ここまで
// ===============================================================================

// ===============================================================================
//　ページ一括更新
// ページ一括更新時の削除
function* deleteForPagesUpdateAll(action: any) {
  if (
    action.to_be_deleted_ids !== null &&
    action.to_be_deleted_ids.length > 0
  ) {
    yield all(
      action.to_be_deleted_ids.map((id: any) =>
        call(deletePageApi, { token: action.token, id: id })
      )
    );
  }
}

// ページ一括更新時の新規追加と更新
function* updateForPagesUpdateAll(action: any) {
  yield call(updatePagesAllApi, {
    token: action.token,
    req_body: action.req_body
  });
}

// ページ一括更新時の更新後一覧取得
function* getAllForPagesUpdateAll(action: any) {
  const get_all_result: Promise<AxiosResponse<GetPagesResponseItem[]>> =
    yield call(getPagesApi, {
      token: action.token,
      type_id: action.type_id,
      topic_id: action.topic_id
    });
  console.log("get_all_result", get_all_result);
  return get_all_result;
}

// ページ一括更新の本体
function* updateAllPages(action: any) {
  try {
    yield* deleteForPagesUpdateAll(action);
    yield* updateForPagesUpdateAll(action);

    const get_all_result = yield* getAllForPagesUpdateAll(action);

    console.log("get_all_result returned after", get_all_result);
    yield put({
      type: UPDATE_ALL_PAGES_SUCCEEDED,
      result: get_all_result
    });
  } catch (e) {
    console.log("update all PAGES error", e.response);
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: UPDATE_ALL_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: UPDATE_ALL_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: UPDATE_ALL_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: UPDATE_ALL_PAGES_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: UPDATE_ALL_PAGES_FAILED, error_response: e.response });
    }
  }
}

//　ページ一括更新ここまで
// ===============================================================================

// ===============================================================================
//　ページ詳細更新

// ページ一括更新時の新規追加と更新
function* updateForPagesUpdatePage(action: any) {
  yield updatePageApi({
    token: action.token,
    id: action.id.toString(),
    req_body: {
      topic_id: action.req_body.topic_id,
      order: action.req_body.order,
      title: action.req_body.title,
      content: action.req_body.content,
      status: action.req_body.status,
      tags: action.req_body.tags,
      history_flag: action.req_body.history_flag || false
    }
  });
}

// ページ一括更新時の更新後一覧取得
function* getAllForPagesUpdatePage(action: any) {
  const get_all_result: Promise<AxiosResponse<GetPagesResponseItem[]>> =
    yield call(getPagesApi, {
      token: action.token,
      type_id: action.manual_id,
      topic_id: action.req_body.topic_id
    });
  console.log("get_all_result", get_all_result);
  return get_all_result;
}

function* updatePage(action: any) {
  try {
    const result = yield* updateForPagesUpdatePage(action);
    const result_pages = yield* getAllForPagesUpdatePage(action);

    yield put({
      type: UPDATE_PAGE_SUCCEEDED,
      result: result,
      result_pages: result_pages
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield all([
          put({ type: RESPONSE_STATUS_403 }),
          put({ type: UPDATE_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 404: {
        yield all([
          put({ type: RESPONSE_STATUS_404 }),
          put({ type: UPDATE_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 422: {
        yield all([
          put({ type: RESPONSE_STATUS_422 }),
          put({ type: UPDATE_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      case 500: {
        yield all([
          put({ type: RESPONSE_STATUS_500 }),
          put({ type: UPDATE_PAGE_FAILED, error_response: e.response })
        ]);
        break;
      }
      default:
        put({ type: UPDATE_PAGE_FAILED, error_response: e.response });
    }
  }
}
//　ページ詳細更新ここまで
// ===============================================================================

const sagas = [
  takeLatest(GET_MANUALS_REQUESTED, getManuals),
  takeLatest(CREATE_MANUAL_REQUESTED, createManual),
  takeLatest(GET_MANUAL_REQUESTED, getManual),
  takeLatest(UPDATE_MANUAL_REQUESTED, updateManual),
  takeLatest(DELETE_MANUAL_REQUESTED, deleteManual),
  takeLatest(UPDATE_ALL_MANUALS_REQUESTED, updateAllManuals),
  takeLatest(CREATE_MANUAL_IMAGE_REQUESTED, createManualImage),
  takeLatest(GET_TOPICS_REQUESTED, getTopics),
  takeLatest(GET_TOPIC_REQUESTED, getTopic),
  takeLatest(UPDATE_ALL_TOPICS_REQUESTED, updateTopicsAll),
  takeLatest(GET_PAGES_REQUESTED, getPages),
  takeLatest(UPDATE_MANUAL_WITH_IMAGE_REQUESTED, heroUpdate),
  takeLatest(UPDATE_MANUAL_TITLE_DESC_REQUESTED, titleDescUpdate),
  takeLatest(GET_PAGE_REQUESTED, getPage),
  takeLatest(UPDATE_ALL_PAGES_REQUESTED, updateAllPages),
  takeLatest(UPDATE_PAGE_REQUESTED, updatePage),
  takeLatest(GET_PAGES_SEARCH_REQUESTED, getPagesSearch)
];

export default sagas;
