import {
  TOGGLE_TAG_TO_BE_SEARCHED_CHECK,
  SET_TAG_TO_BE_SEARCHED_CHECK,
} from "./type";
import {
  CLEAR_SYSTEM_CACHE,
  HANDLE_CLEAR_SEARCH_TERMS,
} from "../../session/type";

const initialState: string[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_TAG_TO_BE_SEARCHED_CHECK:
      // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
      if (
        state.length > 0 &&
        state.some((tag_name: any) => tag_name === action.tag_name)
      ) {
        return state.filter((tag_name: any) => tag_name !== action.tag_name);
      } else {
        return [...state, action.tag_name];
      }

    case SET_TAG_TO_BE_SEARCHED_CHECK:
      return [...action.tag_name];

    case HANDLE_CLEAR_SEARCH_TERMS:
      if (action.target_term === "tag") {
        return [];
      } else {
        return state;
      }

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
