import { RootState } from "store/store";
export const manualManualsSelector = (state: RootState) => {
  return state.manuals.manuals;
};
export const manualManualDetailSelector = (state: RootState) => {
  return state.manuals.manualDetail;
};
export const manualEditModeSelector = (state: RootState) => {
  return state.manuals.pageDetailEditMode;
};
export const pageTagsSelector = (state: RootState) => {
  return state.manuals.pageTags;
};
