import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import AddEventModalContent from "./AddMonthlyEventModalContent";

import { toggleAddMonthlyEventModal } from "../../../../store/common/modal/action";

export default function AddEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <AddMonthlyEventsModalBg
        onClick={() => dispatch(toggleAddMonthlyEventModal(false))}
      ></AddMonthlyEventsModalBg>
      <AddEventModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const AddMonthlyEventsModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
