import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  getInquiryAnswers,
  getInquiryContact,
  getInquiryKinds,
  getInquiryPriorities,
  CommonData,
  getInquiryCheckList
} from "api/FAQ/inquiriesApi";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { env_inquiry_url } from "serverEnv";

import { Facility, RequestData } from "../InquiryNewV2/type";

import {
  getInquiriesContacts,
  getInquiriesKinds,
  getInquiriesPriorities,
  getInquiriesAnswers,
  getInquiriesChecklist
} from "store/faq/inquiries_choices/action";
import { responseStatus401, loginStatus401 } from "store/session/action";

export const useInquiry = (requestData: RequestData) => {
  const {
    contact,
    details,
    kind,
    facility_id,
    employee_check,
    employee_email,
    employee_id,
    employee_name,
    priority,
    answer,
    proxy_login,
    checklist: selectedCheck
  } = requestData;
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { contact: contactFromParams } = useParams<{ contact: string }>();
  const { kind: kindFromParams } = useParams<{ kind: string }>();
  const user_name = useSelector((state: RootState) => state.session.name);
  const user_email = useSelector((state: RootState) => state.session.email);
  const user_id = useSelector((state: RootState) => state.session.employee_id);
  const token = useSelector((state: RootState) => state.session.token);
  const facilities: Facility[] = useSelector(
    (state: RootState) => state.facilities
  );
  const contactsList = useSelector(
    (state: RootState) => state.inquiries_choices.contacts
  );
  const kinds1List = useSelector(
    (state: RootState) => state.inquiries_choices.kinds1
  );
  const kinds2List = useSelector(
    (state: RootState) => state.inquiries_choices.kinds2
  );
  const prioritiesList = useSelector(
    (state: RootState) => state.inquiries_choices.priorities
  );
  const answersList = useSelector(
    (state: RootState) => state.inquiries_choices.answers
  );
  const inquiriesChecklist = useSelector(
    (state: RootState) => state.inquiries_choices.checklist
  );
  const [contacts, setContacts] = useState<CommonData[]>([]);
  const [answers, setAnswers] = useState<CommonData[]>([]);
  const [kinds1, setKinds1] = useState<CommonData[]>([]);
  const [kinds2, setKinds2] = useState<CommonData[]>([]);
  const [priorities, setPriorities] = useState<CommonData[]>([]);
  const [checklist, setChecklist] = useState<CommonData[]>([]);

  //初回訪問時に、それぞれの選択肢を取得。
  useEffect(() => {
    dispatch(getInquiriesContacts(token));
    dispatch(getInquiriesKinds(token));
    dispatch(getInquiriesPriorities(token));
    dispatch(getInquiriesAnswers(token));
    dispatch(getInquiriesChecklist(token));
  }, []);

  useEffect(() => {
    if (!inquiriesChecklist) {
      alert(
        "マスターデータの取得に失敗しました。TOPから再度お問い合わせください。"
      );
      push("/");
    }
    setContacts(contactsList);
    setKinds1(kinds1List);
    setKinds2(kinds2List);
    setPriorities(prioritiesList);
    setAnswers(answersList);
    setChecklist(inquiriesChecklist);
  }, [
    dispatch,
    contactsList,
    kinds1List,
    kinds2List,
    prioritiesList,
    answersList,
    inquiriesChecklist
  ]);
  const postInquiry = () => {
    console.log(requestData);
    console.table(requestData);
    axios
      .post(`${env_inquiry_url}/inquiries`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(data => {
        push("/inquiries/complete", requestData);
      })
      .catch(e => {
        if (e.response.status === 401) {
          dispatch(responseStatus401());
          dispatch(loginStatus401());
        }
        window.alert("お問い合わせの送信に失敗しました");
      });
  };

  const handleSubmit = () => {
    console.table(requestData);
    // もしお問合せ先が選択されていなかった場合
    if (contact === null) {
      window.alert("お問合せ先を選択してください");
    }
    // お問合せ先が「人事総務関連」または、「その他・わからない」だった場合
    if (contact === 4) {
      if (!details || !facility_id || !priority) {
        window.alert("必須項目は必ず入力してください。");
      } else {
        // ログインユーザーと対象の職員が異なる場合
        if (!employee_check) {
          if (!employee_name || !employee_email || !employee_id) {
            window.alert("必須項目は必ず入力してください。");
            return;
          } else {
            postInquiry();
          }
        } else {
          postInquiry();
        }
      }
    }

    if (contact === 3) {
      if (!kind) {
        window.alert("種別は必ず選択してください");
        return;
      }
      if (kind === 6) {
        if (
          !details ||
          !facility_id ||
          !priority ||
          !selectedCheck ||
          selectedCheck.length === 0
        ) {
          window.alert("必須項目は必ず入力してください。");
        } else {
          console.log("checklist", checklist);
          // ログインユーザーと対象の職員が異なる場合
          if (!employee_check) {
            if (!employee_name || !employee_email || !employee_id) {
              window.alert("必須項目は必ず入力してください。");
              return;
            } else {
              postInquiry();
            }
          } else {
            postInquiry();
          }
        }
      } else {
        if (!details || !facility_id || !priority) {
          window.alert("必須項目は必ず入力してください。");
        } else {
          // ログインユーザーと対象の職員が異なる場合
          if (!employee_check) {
            if (!employee_name || !employee_email || !employee_id) {
              window.alert("必須項目は必ず入力してください。");
              return;
            } else {
              postInquiry();
            }
          } else {
            postInquiry();
          }
        }
      }
    }

    // お問合せ先が「経営関連」の場合
    if (contact === 2) {
      // 種別が選択されてなければアラート
      if (!kind) {
        window.alert("種別は必ず選択してください");
        return;
      }
      // 必須項目が入力されていなければアラート
      if (!details || !facility_id || !priority) {
        window.alert("必須項目は必ず入力してください。");
        return;
      } else {
        // ログインユーザーと対象の職員が異なる場合
        if (!employee_check) {
          if (!employee_name || !employee_email || !employee_id) {
            window.alert("対象の職員情報は必ず入力してください。");
            return;
          } else {
            postInquiry();
          }
        } else {
          postInquiry();
        }
      }
    }

    // お問合せ先が「IT関連」の場合
    if (contact === 1) {
      // 種別が選択されてなかった
      if (!kind) {
        window.alert("種別は必ず選択してください");
        return;
      }
      // 種別が「保護者関連」だった場合
      if (kind === 2) {
        if (!details || !facility_id || !priority || !proxy_login) {
          window.alert("必須項目は必ず入力してください。");
        } else {
          // ログインユーザーと対象の職員が異なる
          if (!employee_check) {
            // 職員情報が未入力
            if (!employee_name || !employee_email || !employee_id) {
              window.alert("必須項目は必ず入力してください。");
              return;
              // 職員情報が入力済み
            } else {
              postInquiry();
            }
            // ログインユーザーと対象の職員が同一
          } else {
            postInquiry();
          }
        }
        // 種別が「保護者関連」以外だった場合
      } else {
        // 必須項目が入力されていない
        if (!details || !facility_id || !priority) {
          window.alert("必須項目は必ず入力してください。");
          return;
        } else {
          if (!employee_check) {
            // 職員情報が未入力
            if (!employee_name || !employee_email || !employee_id) {
              window.alert("必須項目は必ず入力してください。");
              return;
              // 職員情報が入力済み
            } else {
              postInquiry();
            }
            // ログインユーザーと対象の職員が同一
          } else {
            postInquiry();
          }
        }
      }
    }
  };

  return {
    contacts,
    answers,
    kinds1,
    kinds2,
    checklist,
    facilities,
    priorities,
    userName: user_name,
    userEmail: user_email,
    userId: user_id,
    contactFromParams,
    kindFromParams,
    handleSubmit
  };
};
