const GET_ACCOUNT_EDIT_REQUESTED = "GET_ACCOUNT_EDIT_REQUESTED";
const GET_ACCOUNT_EDIT_SUCCEEDED = "GET_ACCOUNT_EDIT_SUCCEEDED";
const GET_ACCOUNT_EDIT_FAILED = "GET_ACCOUNT_EDIT_FAILED";

const PUT_ACCOUNT_EDIT_REQUESTED = "PUT_ACCOUNT_EDIT_REQUESTED";
const PUT_ACCOUNT_EDIT_SUCCEEDED = "PUT_ACCOUNT_EDIT_SUCCEEDED";
const PUT_ACCOUNT_EDIT_FAILED = "PUT_ACCOUNT_EDIT_FAILED";

const DELETE_ACCOUNT_EDIT_REQUESTED = "DELETE_ACCOUNT_EDIT_REQUESTED";
const DELETE_ACCOUNT_EDIT_SUCCEEDED = "DELETE_ACCOUNT_EDIT_SUCCEEDED";
const DELETE_ACCOUNT_EDIT_FAILED = "DELETE_ACCOUNT_EDIT_FAILED";

const SET_EDIT_ACCOUNT_ID = "SET_EDIT_ACCOUNT_ID";
const SET_EDIT_ACCOUNT_NAME = "SET_EDIT_ACCOUNT_NAME";
const SET_EDIT_ACCOUNT_POSITION_ID = "SET_EDIT_ACCOUNT_POSITION_ID";
const SET_EDIT_ACCOUNT_EMAIL = "SET_EDIT_ACCOUNT_EMAIL";
const SET_EDIT_ACCOUNT_PASSWORD = "SET_EDIT_ACCOUNT_PASSWORD";
const SET_EDIT_ACCOUNT_FACILITIES = "SET_EDIT_ACCOUNT_FACILITIES"
const TOGGLE_EDIT_ACCOUNT_POST_STATUS = "TOGGLE_EDIT_ACCOUNT_POST_STATUS";

const GET_ACCOUNT_EDIT_FAILED_404 = "GET_ACCOUNT_EDIT_FAILED_404"

export {

  GET_ACCOUNT_EDIT_REQUESTED,
  GET_ACCOUNT_EDIT_SUCCEEDED,
  GET_ACCOUNT_EDIT_FAILED,

  PUT_ACCOUNT_EDIT_REQUESTED,
  PUT_ACCOUNT_EDIT_SUCCEEDED,
  PUT_ACCOUNT_EDIT_FAILED,

  DELETE_ACCOUNT_EDIT_REQUESTED,
  DELETE_ACCOUNT_EDIT_SUCCEEDED,
  DELETE_ACCOUNT_EDIT_FAILED,

  SET_EDIT_ACCOUNT_ID,

  SET_EDIT_ACCOUNT_NAME,

  SET_EDIT_ACCOUNT_POSITION_ID,

  SET_EDIT_ACCOUNT_EMAIL,

  SET_EDIT_ACCOUNT_PASSWORD,

  SET_EDIT_ACCOUNT_FACILITIES,

  TOGGLE_EDIT_ACCOUNT_POST_STATUS,

  GET_ACCOUNT_EDIT_FAILED_404

}
