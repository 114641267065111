import styled from "styled-components";

export const AlbumListItemSC = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

export const AlbumListItemTableSC = styled.table`
  width: 100%;
`;

export const AlbumListItemTheadSC = styled.thead``;
export const AlbumListItemThContentSC = styled.th`
  width: 100%;
`;
export const AlbumListItemTdContentSC = styled.td`
  width: 100%;
`;
export const AlbumListFlexWrapperSC = styled.div`
  display: flex;
  align-items: center;
`;

export const AlbumListItemTrSC = styled.tr`
  font-size: 12px;
  letter-spacing: 0.04em;
  line-height: 16px;
  font-weight: 600;
  color: #848484;
  align-items: center;
  display: flex;
  padding: 10.5px 0;
  border-bottom: 0.8px solid #848484;
  &.detail {
    cursor: pointer;
    &:hover {
      background-color: rgba(105, 160, 58, 0.1);
    }
  }
`;

export const AlbumListItemThCheckSC = styled.th`
  width: 30px;
`;

export const AlbumListItemThImgSC = styled.th`
  width: 48px;
`;

export const AlbumListItemThTagSC = styled.th`
  width: 330px;
`;

export const AlbumListItemThClassSC = styled.th`
  width: 115px;
`;

export const AlbumListItemThFacilitySC = styled.th`
  width: 350px;
`;

export const AlbumListItemThDateSC = styled.th`
  width: 141px;
`;

export const AlbumListItemTbodySC = styled.tbody``;

export const AlbumListItemCheckSC = styled.td`
  margin-left: 8px;
  width: 30px;
`;

export const AlbumListItemThumbnailSC = styled.div`
  width: 48px;
`;

export const AlbumListItemImgSC = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
`;

export const AlbumListItemTagSC = styled.div`
  width: 300px;
  margin-left: 24px;
`;

export const AlbumListItemClassSC = styled.div`
  width: 115px;
  overflow-wrap: break-word;
  margin-left: 24px;
`;

export const AlbumListItemFacilitySC = styled.div`
  width: 240px;
  margin-left: 24px;
`;

export const AlbumListItemDateSC = styled.div`
  width: 120px;
  margin-left: 24px;
`;

export const MouseOnToolTip = styled.p`
  position: absolute;
  padding: 8px;
  border: #707070 1px solid;
  border-radius: 4px;
  background-color: white;
  z-index: 10;
  max-width: 300px;
`;
