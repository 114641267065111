import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import color from "../../mixins/color";

interface BreaadCrumbProps {
  toTopicsPath?: string;
  topicTitle?: string;
  toPagesPath?: string;
  currentPageTitle?: string;
  breadcrambIsInvisible?: boolean;
}

const longTextToDots = (title: string): string => {
  if (title.length >= 32) {
    return title.slice(0, 31) + "...";
  }
  return title;
};

const ManualBreadcrumb: React.VFC<BreaadCrumbProps> = props => {
  const {
    toTopicsPath,
    topicTitle,
    toPagesPath,
    currentPageTitle,
    breadcrambIsInvisible
  } = props;
  return (
    <ManualBreadcrumbSC className={breadcrambIsInvisible ? "hide" : ""}>
      {toTopicsPath ? (
        <TitleLinkSC to={toTopicsPath}>マニュアル一覧</TitleLinkSC>
      ) : (
        <TitleSC>マニュアル一覧</TitleSC>
      )}
      {topicTitle && (
        <>
          <Divider>/</Divider>
          {toPagesPath ? (
            <TitleLinkSC to={toPagesPath}>
              {longTextToDots(topicTitle)}
            </TitleLinkSC>
          ) : (
            <TitleSC>{longTextToDots(topicTitle)}</TitleSC>
          )}
        </>
      )}
      {currentPageTitle && (
        <>
          <Divider>/</Divider>
          <TitleSC>{longTextToDots(currentPageTitle)}</TitleSC>
        </>
      )}
    </ManualBreadcrumbSC>
  );
};

export default ManualBreadcrumb;
// ===================================================================================
// スタイル
// ===================================================================================
const ManualBreadcrumbSC = styled.div`
  width: 100%;
  margin-bottom: 20px;
  &.hide {
    display: none;
  }
`;

const TitleLinkSC = styled(Link)`
  display: inline-block;
  font-size: 12px;
  color: ${color.gray};
  text-decoration: underline;
  &:hover {
    opacity: 0.6;
  }
`;

const TitleSC = styled.p`
  display: inline-block;
  font-size: 12px;
  color: ${color.gray};
`;

const Divider = styled.span`
  display: inline-block;
  margin: 0 10px;
  font-size: 12px;
  color: ${color.gray};
`;
