import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { toggleFacilityModal } from "../../../../store/common/modal/action";

export default function FacilityListBlock() {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(toggleFacilityModal(false));
  };
  return (
    <NoFacilityBlockSC>
      <NoFacilityMessage>閲覧できる施設はありません。</NoFacilityMessage>
      <BackToFAQLink to="/" onClick={handleButtonClick}>
        FAQトップへ戻る
      </BackToFAQLink>
    </NoFacilityBlockSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const NoFacilityBlockSC = styled.div`
  margin: 0 30px;
  padding-top: 120px;
  height: 300px;
  overflow: scroll;
  overflow: scroll;
  text-align: center;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const NoFacilityMessage = styled.p`
  margin-bottom: 20px;
`;

const BackToFAQLink = styled(Link)`
  background-color: #444444;
  padding: 9px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  width: 88px;
  height: 30px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;
