import { color } from "components/mixins";
import React from "react";
import styled from "styled-components";
import checklist from "../../../../images/icons/checklist.svg";

interface AnnualScheduleHeadingProps {
  facilityName: string;
  filteredYear: number;
  span: "4-9" | "10-3";
  usageGuides: any[];
}

export const AnnualEventScheduleContentHeading = (
  props: AnnualScheduleHeadingProps
) => {
  const { facilityName, filteredYear, span, usageGuides } = props;
  return (
    <HeadingWrapper>
      <HeadingSC>
        {facilityName}_年間スケジュール({filteredYear}年{span}月版)
      </HeadingSC>
      <CommonEventWrapper>
        <CommonWrapper>
          <Desc>
            ※チェック
            <br />
            <span>点検行事表記</span>
          </Desc>
          <Icon src={checklist} />
        </CommonWrapper>
        <CommonEventList>
          {usageGuides.map(usageGuide => (
            <CommonEventItem>・{usageGuide.content}</CommonEventItem>
          ))}
          <CommonEventItem className="note">
            ※土日祝の場合は前平日
          </CommonEventItem>
        </CommonEventList>
      </CommonEventWrapper>
    </HeadingWrapper>
  );
};

const HeadingSC = styled.h2`
  font-size: 14px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: center;
`;

const CommonEventList = styled.ul`
  padding: 5px 4px 4px;
  text-align: left;
  font-size: 10px;
  border: 1px solid ${color.gray_border};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 640px;
`;
const CommonEventItem = styled.li`
  margin-bottom: 2px;
  &.note {
    padding-left: 10px;
  }
  &:nth-child(1),
  &:nth-child(4),
  &:nth-child(7) {
    width: 25%;
  }
  &:nth-child(2),
  &:nth-child(5),
  &:nth-child(8) {
    width: 37%;
  }
  &:nth-child(3),
  &:nth-child(6),
  &:nth-child(9) {
    width: 37%;
  }
  &:nth-child(7),
  &:nth-child(8),
  &:nth-child(9) {
    margin-bottom: 0px;
  }
`;

const CommonEventWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CommonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const Desc = styled.p`
  font-size: 10px;
  text-align: left;
  margin-right: 8px;
  span {
    padding-left: 10px;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  display: block;
`;
