const SET_ADD_MONTHLY_EVENT_INITIAL_DATE = "SET_ADD_MONTHLY_EVENT_INITIAL_DATE"
const SET_ADD_MONTHLY_EVENT_TYPE = "SET_ADD_MONTHLY_EVENT_TYPE"
const SET_ADD_MONTHLY_EVENT_CONTENT = "SET_ADD_MONTHLY_EVENT_CONTENT"
const RESET_ADD_MONTHLY_EVENT_TYPE = "RESET_ADD_MONTHLY_EVENT_TYPE"
const RESET_ADD_MONTHLY_EVENT_CONTENT = "RESET_ADD_MONTHLY_EVENT_CONTENT"

export {
  SET_ADD_MONTHLY_EVENT_INITIAL_DATE,
  SET_ADD_MONTHLY_EVENT_TYPE,
  SET_ADD_MONTHLY_EVENT_CONTENT,
  RESET_ADD_MONTHLY_EVENT_TYPE,RESET_ADD_MONTHLY_EVENT_CONTENT
}