import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "components/Common/PageHeading";
import ManualEditPreviewContent from "./ManualEditPreviewContent";
import styled from "styled-components";
import { pc } from "components/mixins/mediaQuery";
import { color, buttonBaseStyle } from "components/mixins";
import {
  savePageDetailRequest,
  setPageDetailEditMode
} from "store/manual/manuals/pages/actions";
import { useHooks } from "../Hooks";
import { history } from "store/store";
import { pageTagsSelector } from "store/manual/manuals/selectors";

function ManualEditPreview(props: any) {
  const { publishedData, draftData } = props;

  return (
    <MainSC>
      <PageHeading title="ページ編集プレビュー" />
      <ArticleDetailWrapperBlockSC>
        <ArticleDetailOuterBlockSC>
          <ArticleDetailInnerTitle>現在公開中の記事</ArticleDetailInnerTitle>
          <ArticleDetailInnerBlockSC>
            <ArticleDetailBlockSC>
              <ManualEditPreviewContent
                title={publishedData.title}
                tags={publishedData.tags}
                currentTagIds={[]}
                content={publishedData.content}
                isPublished={true}
              />
            </ArticleDetailBlockSC>
          </ArticleDetailInnerBlockSC>
        </ArticleDetailOuterBlockSC>
        <Border />
        <ArticleDetailOuterBlockSC>
          <ArticleDetailInnerTitle>現在下書き中の記事</ArticleDetailInnerTitle>
          <ArticleDetailInnerBlockSC>
            <ArticleDetailBlockSC>
              <ManualEditPreviewContent
                title={draftData.title}
                tags={draftData.manualTags}
                currentTagIds={draftData.selectedTagIds}
                content={draftData.content}
                isPublished={false}
              />
            </ArticleDetailBlockSC>
          </ArticleDetailInnerBlockSC>
        </ArticleDetailOuterBlockSC>
      </ArticleDetailWrapperBlockSC>
    </MainSC>
  );
}

export default ManualEditPreview;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 0;
  `}
`;

const ArticleDetailWrapperBlockSC = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
`;

const ArticleDetailOuterBlockSC = styled.div`
  width: 100%;

  ${pc`
    width: 50%;
    max-width: 810px;
  `}
`;

const ArticleDetailInnerBlockSC = styled.div``;

const Border = styled.div`
  border-right: 1px solid ${color.divide_border};
  padding-right: 2%;
  margin-right: 2%;
`;

const ArticleDetailInnerTitle = styled.p`
  color: ${color.gray};
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 16px;
  `}
`;

const ArticleDetailBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  ${pc`
    max-width: 810px;
    // width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    margin-bottom: 0;
    padding: 30px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  width: 100%;
  height: 70px;
  padding: 14px 0 12px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -30px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  background-color: ${color.primary};
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BackButtonSC = styled(Link)`
  ${buttonBaseStyle}
  margin-right: 12px;
  border: 1px solid ${color.primary};
  color: ${color.primary};
  background-color: ${color.white};
  opacity: 1;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const ArticleDetailNotFoundBlock = styled.div`
  color: ${color.gray};
`;
const NotFoundLinkBlock = styled.div`
  margin-top: 10px;
`;
const NotFoundLink = styled(Link)`
  color: ${color.primary};
  text-decoration: underline;
`;
