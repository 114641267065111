import axios from "axios";
import { env_url, env_faq_url } from "../../serverEnv";
// お知らせ情報一覧取得
const getNewsListApi = (token: string, page: number, limit: number) => {
  return axios.get(`${env_faq_url}/news`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
      limit: limit,
    },
  });
};

// お知らせ情報一覧取得
const getNewsListToShowApi = (token: string, page: number, limit: number) => {
  return axios.get(`${env_faq_url}/news`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
      limit: limit,
      status: ["2", "3"],
    },
  });
};

// お知らせ情報登録
const postNewsApi = (
  token: string,
  title: string,
  content: any,
  status: number
) => {
  return axios.post(
    `${env_faq_url}/news`,
    {
      title: title,
      content: content,
      status: status,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// お知らせ詳細情報取得
const getNewsDetailApi = (token: string, id: number) => {
  console.log("id:", id);
  return axios.get(`${env_faq_url}/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

function* deleteNewsApi(token: string, id: number) {
  const result = yield axios.delete(`${env_faq_url}/news/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      id: id,
    },
  });
  return result;
}

function* getNewsApiAfterPut(token: string, page: number, limit: number) {
  const result = yield axios.get(`${env_faq_url}/news`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
      limit: limit,
    },
  });
  return result;
}

// お知らせ情報更新
function* putNewsApi(
  new_news_id: number,
  token: string,
  title: string,
  content: any,
  status: number
) {
  try {
    const result = yield axios.put(
      `${env_faq_url}/news/${new_news_id}`,
      {
        title: title,
        content: content,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// お知らせ既読登録
const readNewsApi = (token: string, id: number) => {
  return axios.post(
    `${env_faq_url}/news/read`,
    {
      news_id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// 画像アップロード
const postNewsImageApi = (token: string) => {
  return axios.post(
    `${env_faq_url}/news/image`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

function* putNewNewsApi(
  id: number,
  token: string,
  title: string,
  content: any,
  status: number
) {
  try {
    const result = yield axios.put(
      `${env_faq_url}/news/${id}`,
      {
        title: title,
        content: content,
        search_string: "test",
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e);
  }
}

function* putEditNewsApi(
  id: number,
  token: string,
  title: string,
  content: any,
  status: number,
  search_string: string
) {
  try {
    const result = yield axios.put(
      `${env_faq_url}/news/${id}`,
      {
        title: title,
        content: content,
        status: status,
        search_string: search_string,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

export {
  getNewsListApi,
  getNewsListToShowApi,
  postNewsApi,
  getNewsDetailApi,
  putNewsApi,
  readNewsApi,
  postNewsImageApi,
  putNewNewsApi,
  putEditNewsApi,
  deleteNewsApi,
  getNewsApiAfterPut,
};
