import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_TO_BE_UPLOADED_CSV,
  TOGGLE_TO_BE_REFLECTED_ALL_FACILITIES,
  TOGGLE_UPLOAD_SUCCEEDED,
  CSV_UPLOAD_SUCCEEDED,
  SET_TARGET_YEAR
} from "./type";

const initialState = {
  fileName: "",
  isAll: false,
  upload_succeeded: false,
  file_data: null,
  year: new Date().getFullYear()
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TARGET_YEAR:
      return {
        ...state,
        year: action.year
      };
    case SET_TO_BE_UPLOADED_CSV:
      return {
        ...state,
        fileName: action.fileName,
        file_data: action.file_data
      };

    case TOGGLE_TO_BE_REFLECTED_ALL_FACILITIES:
      return {
        ...state,
        isAll: !action.isAll
      };

    case CSV_UPLOAD_SUCCEEDED:
      return {
        ...state,
        fileName: "",
        isAll: false,
        upload_succeeded: true,
        file_data: null
      };

    case TOGGLE_UPLOAD_SUCCEEDED:
      return {
        ...state,
        upload_succeeded: action.upload_succeeded
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
