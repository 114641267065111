import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import color from "../mixins/color";

function PageBreadcrumb({
  firstLink,
  firstTitle,
  secondLink,
  secondTitle
}: {
  firstLink: string;
  firstTitle: string;
  secondLink?: string;
  secondTitle?: string;
}) {
  return (
    <PageBreadcrumbSC>
      <FirstLinkSC to={firstLink}>{firstTitle}</FirstLinkSC>

      {secondTitle && (
        <>
          <Divider>/</Divider>
          <SecondTitleSC>
            {secondTitle.length >= 32
              ? secondTitle.slice(0, 31) + "..."
              : secondTitle}
          </SecondTitleSC>
        </>
      )}
    </PageBreadcrumbSC>
  );
}

export default PageBreadcrumb;
// ===================================================================================
// スタイル
// ===================================================================================
const PageBreadcrumbSC = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const FirstLinkSC = styled(Link)`
  display: inline-block;
  font-size: 12px;
  color: ${color.primary};
  text-decoration: underline;
`;

const Divider = styled.span`
  display: inline-block;
  margin: 0 10px;
  font-size: 12px;
  color: ${color.gray};
`;

const SecondTitleSC = styled.span`
  display: inline-block;
  font-size: 12px;
  color: ${color.gray};
`;
