import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";
import Select from "react-select";
import {
  addToBeApproved,
  toggleSelectedOptions,
  setAlreadyApprovedMonths
} from "../../../store/event/approval/action";
import {
  toggleMonthlyApprovalModal,
  toggleAlreadyApprovedErrorModal
} from "../../../store/common/modal/action";

function ApprovalMonthSelect() {
  const dispatch = useDispatch();
  const to_be_approved = useSelector(
    (state: any) => state.approval.to_be_approved
  );
  const options = useSelector(
    (state: any) => state.approval.monthly_select_options
  );
  const selected_options = useSelector(
    (state: any) => state.approval.selected_options
  );
  const monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );
  const authority_status = useSelector(
    (state: any) => state.session.position.id
  );
  // 承認済みの月が配列に入っているか確認する
  // 月別承認状況の配列から、現在選択されている月だけを抜き出す
  const checkedMonth = to_be_approved.map((target_month: any) => {
    const checkedArray = monthly_status.find(
      (item: any) => item.month === target_month
    );
    return checkedArray;
  });
  // 権限ごとに、現在選択されている月の承認状況を抜き出す。
  const status_array = checkedMonth.map((month: any) => {
    if (authority_status === 5) {
      return { month: month.month, status: month.leader_approved };
    } else if (authority_status === 6) {
      return { month: month.month, status: month.administrator_approved };
    } else {
      return { month: month.month, status: month.director_approved };
    }
  });
  // すでに承認されている月が1つでもあるかどうか
  const alreadyApproved = status_array.some(
    (item: any) => item.status === true
  );
  // 選択されている月のうちすでに承認されている月は何月か
  const alreadyApprovedMonths = status_array
    .filter((item: any) => item.status === true)
    .map((item: any) => item.month);

  const handleModalOpen = (e: any) => {
    e.preventDefault();
    if (alreadyApproved) {
      dispatch(toggleAlreadyApprovedErrorModal(true));
      dispatch(setAlreadyApprovedMonths(alreadyApprovedMonths));
    } else {
      dispatch(toggleMonthlyApprovalModal(true));
    }
  };

  //SelectBoxのスタイリング
  // ...providedで既存スタイルを展開して、追加や上書きを行う
  const customStyles = {
    container: () => ({
      width: 330,
      marginRight: 14
    }),
    // 選択リストのスタイリング
    menu: (provided: any) => ({
      ...provided,
      top: "auto",
      maxWidth: 330
    }),
    // 選択リスト内アイテムのスタイリング
    option: (provided: any, { isFocused }: any) => ({
      ...provided,
      backgroundColor: isFocused && "rgba(41, 137, 72, 0.1)",
      cursor: "pointer"
    }),
    //セレクト表示エリアのスタイリング
    control: (provided: any) => ({
      ...provided,
      ":hover": {
        cursor: "pointer"
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "12px"
    })
  };

  return (
    <ApprovalMonthSelectSC>
      <ApprovalForm>
        <ApprovalFormBlock>
          <ApprovalFormLabel>承認</ApprovalFormLabel>
          <ApprovalField>
            <Select
              placeholder="承認する月を選択してください"
              value={selected_options}
              onChange={(new_value: any) => {
                dispatch(toggleSelectedOptions(new_value));
                const toBeApprovedArray = new_value.map((item: any) => {
                  return item.value;
                });
                console.log("toBeApprovedArray", toBeApprovedArray);
                dispatch(addToBeApproved(toBeApprovedArray));
              }}
              closeMenuOnSelect={false} //一つ選択してもリストが閉じないようにする
              isMulti //複数選択できるようにする
              styles={customStyles} //定義したスタイルを読み込む
              options={options} //セレクトするアイテムを読み込む（配列）
              theme={(theme: any) => ({
                //テーマカラーを上書きする
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: `${color.primary}`,
                  primary: `${color.tag_color}`
                }
              })}
            />
            {/* <MonthSelect>
              <MonthOption>4月</MonthOption>
            </MonthSelect> */}
            <MonthSubmit
              type="submit"
              value="選択した月を承認"
              onClick={e => handleModalOpen(e)}
              disabled={to_be_approved.length === 0}
            />
          </ApprovalField>
        </ApprovalFormBlock>
      </ApprovalForm>
    </ApprovalMonthSelectSC>
  );
}

export default ApprovalMonthSelect;

// ===================================================================================
// スタイル
// ===================================================================================

const ApprovalMonthSelectSC = styled.div`
  margin-bottom: 19px;
  padding: 11px 25px;
  border-radius: 6px;
  background-color: ${color.primary_white};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
`;

const ApprovalForm = styled.form``;

const ApprovalFormBlock = styled.dl`
  display: flex;
  align-items: flex-start;
`;

const ApprovalFormLabel = styled.dt`
  color: ${color.gray};
  width: 68px;
  line-height: 38px;
  font-size: 16px;
`;

const ApprovalField = styled.dd`
  width: calc(100% - 68px);
  display: flex;
  align-items: flex-start;
`;

const MonthSubmit = styled.input`
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
  text-align: center;
  max-width: 160px;
  width: 100%;
  height: 38px;
  padding: 12px 10px 10px;
  border-radius: 4px;
  display: block;
  background-color: ${color.gray};
  :disabled {
    opacity: 0.6;
  }
`;
