import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import color from "../../../mixins/color";
import unChecked from "../../../../images/icons/checked.svg";
import checked from "../../../../images/icons/unChecked.svg";

import { containerBaseStyle } from "../../../mixins";

import {
  toggleToBeApproved,
  toggleToBeApprovedAll,
  setSelectedMonthToApproval,
} from "../../../../store/event/approval/action";
import { getApprovalMonthlyStatusRequest } from "../../../../store/event/approval/action";

function StatusMonthlyListBlock() {
  const monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );
  const to_be_approved = useSelector(
    (state: any) => state.approval.to_be_approved
  );
  const token = useSelector((state: any) => state.session.token);
  const this_year =
    new Date().getMonth() + 1 === (1 || 2 || 3)
      ? new Date().getFullYear() - 1
      : new Date().getFullYear();
  console.log("monthly_status", monthly_status);
  console.log("to_be_approved", to_be_approved);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  return (
    <StatusListBlockSC>
      <StatusListWrapperSC>
        <StatusListBlockHeader>
          <StatusListBlockHeaderItem className="month">
            <CheckboxLabel>
              <Checkbox
                className="checkbox"
                type="checkbox"
                onChange={() =>
                  dispatch(toggleToBeApprovedAll(id, monthly_status))
                }
                checked={to_be_approved.length === monthly_status.length}
              />
              <CheckboxSpan className="label" />
            </CheckboxLabel>
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="facility_manager">
            施設長
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="project_manager">
            プロジェクト管理者
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="headquarters">
            本部
          </StatusListBlockHeaderItem>
          <StatusListBlockHeaderItem className="requested_date">
            最終承認依頼日
          </StatusListBlockHeaderItem>
        </StatusListBlockHeader>
        {monthly_status.map((monthly: any) => {
          const ExistingCheck = to_be_approved.some(
            (item: any) => item === monthly.month
          );
          return (
            <StatusListSC>
              <StatusListItemSC>
                <CheckboxLabel className="item">
                  <Checkbox
                    className="checkbox"
                    type="checkbox"
                    onChange={() =>
                      dispatch(toggleToBeApproved(id, monthly.month))
                    }
                    checked={ExistingCheck}
                  />
                  <CheckboxSpan className="label"></CheckboxSpan>
                </CheckboxLabel>
                <StatusMonthlyLinkSC
                  className={ExistingCheck && "check"}
                  to={`/events/approvals`}
                  onClick={() =>
                    dispatch(setSelectedMonthToApproval(monthly.month))
                  }
                >
                  <p className="month">{monthly.month}</p>
                  <p className="facility_manager">
                    {monthly.leader_approved ? (
                      <img src={checked} alt="checked" />
                    ) : (
                      <img src={unChecked} alt="unChecked" />
                    )}
                  </p>
                  <p className="project_manager">
                    {monthly.administrator_approved ? (
                      <img src={checked} alt="checked" />
                    ) : (
                      <img src={unChecked} alt="unChecked" />
                    )}
                  </p>
                  <p className="headquarters">
                    {monthly.director_approved ? (
                      <img src={checked} alt="checked" />
                    ) : (
                      <img src={unChecked} alt="unChecked" />
                    )}
                  </p>
                  <p className="requested_date">{monthly.last_requested_at}</p>
                </StatusMonthlyLinkSC>
              </StatusListItemSC>
            </StatusListSC>
          );
        })}
      </StatusListWrapperSC>
    </StatusListBlockSC>
  );
}

export default StatusMonthlyListBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const StatusListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 20px 16px;
  ${pc`
    padding: 24px 0;
    max-width: 810px;
    // width: 65.5%;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 30px;
  `}
`;
const StatusListWrapperSC = styled.div`
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  overflow: hidden;
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;

const StatusListBlockHeader = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${color.list_header};
  color: ${color.light_gray};
  padding: 12px 20px;
  border-bottom: 1px solid ${color.list_border};
`;

const StatusListBlockHeaderItem = styled.li`
  font-size: 14px;
  text-align: center;
  &.month {
    text-align: left;
    width: calc(35%);
  }
  &.facility_manager {
    width: calc(10%);
  }
  &.project_manager {
    width: calc(19%);
  }
  &.headquarters {
    width: calc(10%);
  }
  &.requested_date {
    width: calc(26%);
  }
`;

const StatusListSC = styled.ul`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
`;

const StatusListItemSC = styled.li`
  color: ${color.gray};
  font-size: 14px;
  width: 100%;
  position: relative;
`;

const StatusMonthlyLinkSC = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  position: relative;
  &:hover {
    background-color: ${color.primary_white};
  }
  &.check {
    background-color: ${color.primary_white};
  }
  p {
    text-align: center;
    &.month {
      text-align: left;
      padding-left: 36px;
      width: calc(35%);
    }
    &.facility_manager {
      width: calc(10%);
    }
    &.project_manager {
      width: calc(19%);
    }
    &.headquarters {
      width: calc(10%);
    }
    &.requested_date {
      width: calc(26%);
    }
  }
`;

const CheckboxLabel = styled.label`
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.light_gray};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.gray};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
  &.item {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
