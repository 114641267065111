import { CLEAR_SYSTEM_CACHE, END_SESSION_SUCCEEDED } from "../../session/type";
import { SET_FACILITY_SUCCEEDED } from "./type";

interface Facility {
  id: number | null;
  name: string;
  area_name: string;
}

const initialState: Facility = {
  id: null,
  name: "",
  area_name: "",
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FACILITY_SUCCEEDED:
      return {
        id: action.id,
        name: action.name,
      };

    case END_SESSION_SUCCEEDED:
      return initialState;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
