import styled from "styled-components";

export const TagItemListSC = styled.div`
  background-color: #ffffff;
  width: 750px;
  position: relative;
`;

export const TagItemListUpdateSC = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 70px;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;
`;

export const TagItemListUpdateButtonSC = styled.div`
  display: flex;
  padding-bottom: 17px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(0%);
  z-index: 200;
  @media (max-width: 1024px) {
    transform: translateX(-50%);
  }
`;

export const TagItemListThSC = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 4px 4px 0 0;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
  color: #888888;
`;

export const TagItemListThTagSC = styled.p`
  width: 50%;
`;

export const TagItemListThOrderSC = styled.p`
  width: 30%;
`;

export const TagItemListThDeleteSC = styled.p`
  width: 30px;
  margin-left: auto;
`;

export const TagItemListTrSC = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border: 1px solid #e0e0e0;
  border-top: none;
`;

export const TagItemListTrTagSC = styled.div`
  width: 50%;
  .outline {
    width: 320px;
    height: 38px;
    border-radius: 4px;
  }
`;

export const TagItemListTrOrderSC = styled.div`
  width: 30%;
  .outline {
    width: 80px;
    height: 38px;
    border-radius: 4px;
  }
`;

export const TagItemListTrDeleteSC = styled.span`
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: auto;
  top: 0%;
  left: 0%;
  cursor: pointer;
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::before,
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5px;
    height: 18px;
    background: #888888;
    border-radius: 3px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const TagItemListAddSC = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #e0e0e0;
  border-top: none;
  .alert {
    background-color: #ffffff;
    color: #444444;
    border: 1px solid #444444;
    margin-left: 20px;
    width: 160px;
    height: 38px;
    text-align: center;
  }
`;
