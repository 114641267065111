import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import AddApprovalEventsModalContent from "./AddApprovalEventsModalContent";

import { toggleMonthlyApprovalModal } from "../../../../store/common/modal/action";

export default function ReturnEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <AddApprovalEventsModalBg
        onClick={() => dispatch(toggleMonthlyApprovalModal(false))}
      ></AddApprovalEventsModalBg>
      <AddApprovalEventsModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const AddApprovalEventsModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
