import axios, { AxiosResponse } from "axios";
import {
  AllHistoriesUpdateResponseProps,
  AllHitoriesUpdateRequestBodyProps,
  HistoriesGetResponseProps,
  HistoryUpdateResponseProps
} from "store/manual/histories/type";
import { env_manual_url } from "../../serverEnv";

export const getHistoriesApi = (data: { token: string }) => {
  const { token } = data;
  try {
    const result: Promise<AxiosResponse<HistoriesGetResponseProps[]>> =
      axios.get(`${env_manual_url}/histories`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    return result;
  } catch (e) {
    console.log("axios error", e);
    return e;
  }
};

export const updateHistoryApi = (data: {
  token: string;
  id: number;
  priority: boolean;
}) => {
  try {
    const { token, id, priority } = data;
    console.log("token", token);
    console.log("id", id);
    const result: Promise<AxiosResponse<HistoryUpdateResponseProps>> =
      axios.put(
        `${env_manual_url}/histories/${id}`,
        {
          priority
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};

export const updateAllHistoryApi = (data: {
  token: string;
  req_body: AllHitoriesUpdateRequestBodyProps[];
}) => {
  try {
    const { token, req_body } = data;
    const result: Promise<AxiosResponse<AllHistoriesUpdateResponseProps>> =
      axios.put(
        `${env_manual_url}/histories/all`,
        {
          req_body
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};
