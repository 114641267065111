import { GET_NEWS_DETAIL_REQUESTED, READ_NEWS_DETAIL_REQUESTED, SET_NEWS_DETAIL_ID, TOGGLE_NEWS_DETAIL_CONTENT_LOADED} from "./type";

// 記事情報取得
export const getNewsDetailRequest = (token: string, id: number) => {
  return {
    type: GET_NEWS_DETAIL_REQUESTED,
    token,
    id,
  };
};

// 記事既読情報登録
export const postNewsDetailReadRequest = (token: string, id: number) => {
  return {
    type: READ_NEWS_DETAIL_REQUESTED,
    token,
    id
  };
};
// 記事既読情報登録
export const setNewsDetailId = (id: number) => {
  return {
    type: SET_NEWS_DETAIL_ID,
    id
  };
};

export const toggleNewsDetailContentLoaded = (contentLoaded: boolean) => {
  return {
    type: TOGGLE_NEWS_DETAIL_CONTENT_LOADED,
    contentLoaded
  };
};