import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";

import { GET_ARTICLE_FAILED_404 } from "store/faq/article_detail/type";

import { all, put, call } from "redux-saga/effects";

export function* responseErrorSwitcher(e: any) {
  const error_code = e.response.data.error_code;
  switch (error_code) {
    case 401: {
      yield all([
        put({ type: LOGIN_STATUS_401 }),
        put({ type: RESPONSE_STATUS_401 })
      ]);
      break;
    }
    case 403: {
      yield put({ type: RESPONSE_STATUS_403 });
      break;
    }
    case 404: {
      yield put({ type: RESPONSE_STATUS_404 });
      break;
    }
    case 422: {
      yield put({ type: RESPONSE_STATUS_422 });
      break;
    }
    case 500: {
      yield put({ type: RESPONSE_STATUS_500 });
      break;
    }
    default:
      yield put({ type: RESPONSE_STATUS_500 });
  }
}
export function* responseErrorSwitcherForArticle(e: any) {
  const error_code = e.response.data.error_code;
  switch (error_code) {
    case 401: {
      yield all([
        put({ type: LOGIN_STATUS_401 }),
        put({ type: RESPONSE_STATUS_401 })
      ]);
      break;
    }
    case 403: {
      yield all([
        put({ type: RESPONSE_STATUS_404 }),
        put({ type: GET_ARTICLE_FAILED_404 })
      ]);
      break;
    }
    case 404: {
      yield all([
        put({ type: RESPONSE_STATUS_404 }),
        put({ type: GET_ARTICLE_FAILED_404 })
      ]);
      break;
    }
    case 422: {
      yield put({ type: RESPONSE_STATUS_422 });
      break;
    }
    case 500: {
      yield put({ type: RESPONSE_STATUS_500 });
      break;
    }
    default:
      yield put({ type: RESPONSE_STATUS_500 });
  }
}
