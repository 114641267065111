import { Event } from "components/Events/Top/annualEventScheduleNew/type";
import { color } from "components/mixins";
import React from "react";
import styled from "styled-components";
import ContentCell from "../annualEventSchedule/AnnualEventScheduleContentCell";

interface AnnualEventScheduleMonthlyPlanRowProps {
  filteredYear: number;
  allMonthlyPlans: Event[];
  monthIndex: number;
  type: "monthly" | "agricultural";
}

export const AnnualEventScheduleMonthlyPlanRowNew = (
  props: AnnualEventScheduleMonthlyPlanRowProps
) => {
  const { allMonthlyPlans, monthIndex, type } = props;

  const hasDate =
    monthIndex === 1 ||
    monthIndex === 4 ||
    monthIndex === 7 ||
    monthIndex === 10;

  const planType = type === "monthly" ? "月間" : "農業";

  const targetPlan = allMonthlyPlans.find(
    (plan: any) => plan.month === monthIndex
  );
  const event_name = targetPlan ? targetPlan.event_name : "";
  return (
    <RowSC>
      <DayCellSC hasDate={hasDate}>
        {hasDate && (
          <>
            {planType}
            <br />
            計画
          </>
        )}
      </DayCellSC>
      <ContentCell eventName={event_name!} />
    </RowSC>
  );
};

const RowSC = styled.div`
  display: flex;
  height: 30px;
  border-bottom: 1px solid ${color.gray_border};
`;

const DayCellSC = styled.div<{ hasDate: boolean }>`
  border-right: 1px solid ${color.gray_border};
  padding-top: 5px;
  width: ${props => (props.hasDate ? `30px` : `10px`)};
  padding-top: 5px;
  background-color: ${color.primary_white};
`;
