import { History } from "../../../components/Manual/demoData";
import { TagItem } from "./pages/types.d";
import {
  GET_MANUALS_REQUESTED,
  GET_MANUAL_REQUESTED,
  CREATE_MANUAL_REQUESTED,
  UPDATE_MANUAL_REQUESTED,
  DELETE_MANUAL_REQUESTED,
  UPDATE_ALL_MANUALS_REQUESTED,
  UPDATE_MANUAL_WITH_IMAGE_REQUESTED,
  UPDATE_MANUAL_TITLE_DESC_REQUESTED,
  TOGGLE_PAGE_TAGS,
  SET_PAGE_TAGS,
  MANUAL_SET_WITHOUT_REQUEST
} from "./types";
import {
  ManualCreateUpdateRequestBody,
  ManualUpdateRequestBody,
  ManualImageCreateRequestBody,
  ManualItemForSelector
} from "./types.d";

export const getManualsRequest = (data: { token: string }) => {
  const { token } = data;
  return {
    type: GET_MANUALS_REQUESTED,
    token
  };
};

export const getManualRequest = (data: { token: string; id: number }) => {
  const { token, id } = data;
  return {
    type: GET_MANUAL_REQUESTED,
    token,
    id
  };
};

export const createManualRequest = (data: {
  token: string;
  req_body: ManualCreateUpdateRequestBody;
}) => {
  const { token, req_body } = data;
  return {
    type: GET_MANUAL_REQUESTED,
    token,
    req_body
  };
};

export const updateManualRequest = (data: {
  token: string;
  id: number;
  req_body: ManualCreateUpdateRequestBody;
}) => {
  const { token, id, req_body } = data;
  return {
    type: UPDATE_MANUAL_REQUESTED,
    token,
    id,
    req_body
  };
};

export const deleteManualRequest = (data: { token: string; id: number }) => {
  const { token, id } = data;
  return {
    type: DELETE_MANUAL_REQUESTED,
    token,
    id
  };
};

export const updateAllManualsRequest = (data: {
  token: string;
  req_body: ManualUpdateRequestBody[];
  to_be_deleted_ids: (number | null)[] | null;
}) => {
  const { token, req_body, to_be_deleted_ids } = data;
  return {
    type: UPDATE_ALL_MANUALS_REQUESTED,
    token,
    req_body,
    to_be_deleted_ids
  };
};

export const updateManualWithImageRequest = (data: {
  token: string;
  hookedManual: ManualItemForSelector;
  imageToUpload: any;
}) => {
  const { token, hookedManual, imageToUpload } = data;
  return {
    type: UPDATE_MANUAL_WITH_IMAGE_REQUESTED,
    token,
    hookedManual,
    imageToUpload
  };
};

export const updateManualTitleDesc = (data: {
  token: string;
  hookedManual: ManualItemForSelector;
}) => {
  const { token, hookedManual } = data;
  return {
    type: UPDATE_MANUAL_TITLE_DESC_REQUESTED,
    token,
    hookedManual
  };
};

export const setPageTags = (pageTags: TagItem[]) => {
  return {
    type: SET_PAGE_TAGS,
    pageTags
  };
};

export const togglePageTags = (selected_id: number, manualTags: any[]) => {
  return {
    type: TOGGLE_PAGE_TAGS,
    selected_id,
    manualTags
  };
};

export const manualSetWithoutRequest = (manualId: number) => {
  return {
    type: MANUAL_SET_WITHOUT_REQUEST,
    manualId
  };
};
