import styled from "styled-components";
import { color } from "../../../../mixins";

export const InputItemCheckbox = styled.input`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: none;

  &:checked + label::before {
    background-color: ${color.gray};
  }
  &:checked + label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

export const InputItemCheckboxLabel = styled.label`
  font-size: 14px;
  position: relative;
  padding-left: 22px;
  &::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${color.gray}; /* 枠の色変更 お好きな色を */
    background-color: ${color.white}; /* 背景の色変更 お好きな色を */
  }
`;
