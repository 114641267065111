import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import close from "../../../images/icons/close.svg";
import color from "../../mixins/color";
import { pc } from "../../mixins/mediaQuery";
import { containerBaseStyle, buttonBaseStyle } from "../../mixins/common";
import { toggleAuthorityErrorModal } from "../../../store/common/modal/action";

export default function ConfirmationModal(props: any) {
  const dispatch = useDispatch();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(toggleAuthorityErrorModal(false));
  };
  return (
    <ModalBgSC onClick={() => dispatch(toggleAuthorityErrorModal(false))}>
      <ModalContainerSC onClick={(e) => e.stopPropagation()}>
        <ModalHeaderSC>
          <ModalHeadingSC>CSVアップロード</ModalHeadingSC>
          <ModalCloseBtnSC
            src={close}
            alt="close"
            onClick={() => dispatch(toggleAuthorityErrorModal(false))}
          />
        </ModalHeaderSC>
        <ModalContentSC>
          <ModalDescSC>
            ご利用のアカウントでは
            <br />
            CSV管理はご利用できません。
          </ModalDescSC>
          <ModalButtonWrapperSC>
            <ModalBtnSC onClick={handleSubmit}>OK</ModalBtnSC>
          </ModalButtonWrapperSC>
        </ModalContentSC>
      </ModalContainerSC>
    </ModalBgSC>
  );
}

const ModalBgSC = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: ${color.modal_bg};
  color: ${color.primary};
`;

const ModalContainerSC = styled.div`
  ${containerBaseStyle}
  padding: 0;
  width: 600px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
`;

const ModalHeaderSC = styled.div`
  width: 100%;
  background-color: ${color.primary_white};
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeadingSC = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const ModalCloseBtnSC = styled.img`
  display: inline-block;
  cursor: pointer;
`;

const ModalContentSC = styled.div`
  padding: 30px 54px;
`;

const ModalDescSC = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const ModalButtonWrapperSC = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalBtnSC = styled.button`
  display: block;
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
  width: 160px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  color: ${color.white};
  font-size: 14px;
  font-weight: bold;
  background-color: ${color.primary};
  ${pc`
    height: 38px;
  `}
  &:hover {
    opacity: 0.6;
  }
`;
