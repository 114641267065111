import React, { InputHTMLAttributes, Ref } from "react";
import { InputItemCheckbox, InputItemCheckboxLabel } from "./style";

//-----------------------------------------------------
// props
//-----------------------------------------------------
export interface CheckBoxDomProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  ref?: Ref<HTMLInputElement>;
}

export const CheckBoxDomComponent = (props: CheckBoxDomProps) => {
  //-----------------------------------------------------
  // init
  //-----------------------------------------------------
  const { label, ...rest } = props;

  //-----------------------------------------------------
  // handler
  //-----------------------------------------------------

  //-----------------------------------------------------
  // render
  //-----------------------------------------------------
  return (
    <>
      <InputItemCheckbox
        {...rest}
        id={props.name}
        name={props.name}
        type="checkbox"
      />
      <InputItemCheckboxLabel htmlFor={props.name}>
        {label}
      </InputItemCheckboxLabel>
    </>
  );
};
