import styled, { keyframes } from "styled-components";

export const PhotoDetailContainerSC = styled.div`
  max-width: 700px;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto 40px;

  @media screen and (max-width: 700px) {
    padding: 10px;
  }
`;

export const PhotoDetailIconContainerSC = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 700px) {
    padding-top: 10px;
    padding-right: 10px;
  }
`;

export const PhotoDetailIconSC = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const PhotoDetailItemSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px;

  @media screen and (max-width: 700px) {
    padding: 15px 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const PhotoDetailTitleSC = styled.p`
  width: 20%;
  font-size: 14px;
  color: #444444;
  font-weight: bold;
  line-height: 16px;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`;

export const PhotoDetailTextSC = styled.p`
  width: 80%;
  font-size: 12px;
  color: #444444;
  line-height: 16px;
`;

export const FlexBoxSC = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 8px;
`;

const skeletonAnimation = keyframes`
  0% {
    transform: translateX(-25px)
  }
  100% {
    transform: translateX(200px)
  }
`;

export const ImageSkelton = styled.div`
  background-color: #d9d9d9;
  position: relative;
  /* overflow: hidden; */
  width: 350px;
  height: 350px;
  margin: 0 auto;
  border-radius: 24px;
  &::before {
    content: "";
    display: block;
    height: 350px;
    width: 175px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      transparent
    );
    position: absolute;
    top: 0;
    left: 0;
    animation: ${skeletonAnimation} 1.2s linear infinite;
  }
`;
