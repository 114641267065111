import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";

import {
  toggleAddMonthlyEventModal,
  toggleEditMonthlyEventModal
} from "../../../store/common/modal/action";

import {
  resetEditMonthlyEventContent,
  resetEditMonthlyEventType,
  setInitialEditMonthlyEvent
} from "../../../store/event/monthly_event_edit/action";

import {
  resetAddMonthlyEventContent,
  resetAddMonthlyEventType,
  setAddMonthlyEventModalInitialDate
} from "../../../store/event/monthly_event_add/action";

import { toggleMonthlyEventItemToDelete } from "../../../store/event/monthly_event_delete/action";

function EventsItem(props: any) {
  const dispatch = useDispatch();
  const monthlyPlanItms = useSelector(
    (state: any) => state.monthly_events.monthly_plans
  );
  const monthly_item_to_delete_array = useSelector(
    (state: any) => state.monthly_event_delete.to_delete_array
  );
  const position_id = useSelector((state: any) => state.session.position.id);
  const approval_monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );
  const monthlyPlanTarget = monthlyPlanItms.find((monthly_plan: any) => {
    return (
      new Date(monthly_plan.date).getMonth() === new Date(props.date).getMonth()
    );
  });
  const month = new Date(props.date).getMonth() + 1;
  console.log(monthlyPlanItms);

  let isEditableClass = "";
  if (
    // 現在ログインしているユーザーがすでにその行事予定を承認している場合
    // monthlyPlanTarget.approval_statuses.some((approval_status: any) => approval_status.id === position_id && approval_status.approved === true)
    // ||
    // 施設長（5）一般閲覧者（4）一般編集者（1）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    ((position_id === 5 || position_id === 4 || position_id === 1) &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.leader_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      )) ||
    // プロジェクト管理者（6）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    (position_id === 6 &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.administrator_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      )) ||
    // 本部（2）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    (position_id === 2 &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.director_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      ))
  ) {
    isEditableClass = "not_editable";
  } else {
    isEditableClass = "";
  }

  // 月間計画の新規追加時
  const handleClickAddEvents = (event: any, yearMonthDate: any) => {
    if (
      // 現在ログインしているユーザーがすでにその行事予定を承認している場合
      // agriculturalPlanTarget.approval_statuses.some((approval_status: any) => approval_status.id === position_id && approval_status.approved === true)
      // ||
      // 施設長（5）一般閲覧者（4）一般編集者（1）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
      ((position_id === 5 || position_id === 4 || position_id === 1) &&
        approval_monthly_status.some(
          (status_item: any) =>
            status_item.leader_approved === true &&
            `${new Date(props.date).getFullYear()}-${(
              "0" +
              (new Date(props.date).getMonth() + 1)
            ).slice(-2)}`.includes(status_item.month)
        )) ||
      // プロジェクト管理者（6）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
      (position_id === 6 &&
        approval_monthly_status.some(
          (status_item: any) =>
            status_item.administrator_approved === true &&
            `${new Date(props.date).getFullYear()}-${(
              "0" +
              (new Date(props.date).getMonth() + 1)
            ).slice(-2)}`.includes(status_item.month)
        )) ||
      // 本部（2）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
      (position_id === 2 &&
        approval_monthly_status.some(
          (status_item: any) =>
            status_item.director_approved === true &&
            `${new Date(props.date).getFullYear()}-${(
              "0" +
              (new Date(props.date).getMonth() + 1)
            ).slice(-2)}`.includes(status_item.month)
        ))
    ) {
      return false;
    }
    if (event.target.tagName !== "INPUT") {
      dispatch(resetAddMonthlyEventType());
      dispatch(resetAddMonthlyEventContent());
      dispatch(toggleAddMonthlyEventModal(true));

      const year = new Date(yearMonthDate).getFullYear();
      const month = new Date(yearMonthDate).getMonth() + 1;
      const date = new Date(yearMonthDate).getDate();
      dispatch(
        setAddMonthlyEventModalInitialDate(yearMonthDate, year, month, date)
      );
    }
  };

  // 月間計画の編集時
  const handleClickEditEvents = (event: any) => {
    console.log("monthlyPlanTarget", monthlyPlanTarget);
    if (monthlyPlanTarget.status === 1 || monthlyPlanTarget.status === 2) {
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "IMG" &&
        event.target.tagName !== "LABEL"
      ) {
        dispatch(resetEditMonthlyEventType());
        dispatch(resetEditMonthlyEventContent());

        dispatch(toggleEditMonthlyEventModal(true));

        const year = new Date(props.date).getFullYear();
        const month = new Date(props.date).getMonth() + 1;
        dispatch(
          setInitialEditMonthlyEvent(
            monthlyPlanTarget.id,
            year,
            month,
            monthlyPlanTarget.type.id,
            monthlyPlanTarget.content,
            2, // 月間計画のkindは2
            []
          )
        );
      }
    } else {
      console.log("編集不可");
      console.log("monthlyPlanTarget.status", monthlyPlanTarget.status);
    }
  };

  // 月間計画の削除用リストに追加
  const handleClickCheckbox = (event: any) => {
    dispatch(toggleMonthlyEventItemToDelete(Number(event.target.value)));
  };

  return (
    <>
      {monthlyPlanTarget ? (
        <EventsItemSC
          onClick={handleClickEditEvents}
          className={isEditableClass}
          style={
            (position_id === 1 &&
              (approval_monthly_status.some(
                (status_item: any) =>
                  status_item.leader_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              ) ||
                approval_monthly_status.some(
                  (status_item: any) =>
                    status_item.administrator_approved === true &&
                    `${new Date(props.date).getFullYear()}-${(
                      "0" +
                      (new Date(props.date).getMonth() + 1)
                    ).slice(-2)}`.includes(status_item.month)
                ) ||
                approval_monthly_status.some(
                  (status_item: any) =>
                    status_item.director_approved === true &&
                    `${new Date(props.date).getFullYear()}-${(
                      "0" +
                      (new Date(props.date).getMonth() + 1)
                    ).slice(-2)}`.includes(status_item.month)
                ))) ||
            // 現在ログインしているユーザーが施設長（5）または一般編集者（1）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
            ((position_id === 5 || position_id === 1) &&
              approval_monthly_status.some(
                (status_item: any) =>
                  status_item.leader_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              )) ||
            // プロジェクト管理者（6）で、PJ管理者承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
            (position_id === 6 &&
              approval_monthly_status.some(
                (status_item: any) =>
                  status_item.administrator_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              )) ||
            // 本部（2）で、本部承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
            (position_id === 2 &&
              approval_monthly_status.some(
                (status_item: any) =>
                  status_item.director_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              )) ||
            (monthlyPlanTarget.status !== 1 && monthlyPlanTarget.status !== 2)
              ? { backgroundColor: `${color.divide_border}`, cursor: "default" }
              : {}
          }
        >
          <EventsItemWrapper>
            <EventsItemCheckboxWrapper>
              {
                //本部登録のステータスの場合は非表示
                props.editing_status === 5 ||
                // 一般編集者の場合は、いずれかの権限が承認している場合に編集不可。
                (position_id === 1 &&
                  (approval_monthly_status.some(
                    (status_item: any) =>
                      status_item.leader_approved === true &&
                      `${new Date(props.date).getFullYear()}-${(
                        "0" +
                        (new Date(props.date).getMonth() + 1)
                      ).slice(-2)}`.includes(status_item.month)
                  ) ||
                    approval_monthly_status.some(
                      (status_item: any) =>
                        status_item.administrator_approved === true &&
                        `${new Date(props.date).getFullYear()}-${(
                          "0" +
                          (new Date(props.date).getMonth() + 1)
                        ).slice(-2)}`.includes(status_item.month)
                    ) ||
                    approval_monthly_status.some(
                      (status_item: any) =>
                        status_item.director_approved === true &&
                        `${new Date(props.date).getFullYear()}-${(
                          "0" +
                          (new Date(props.date).getMonth() + 1)
                        ).slice(-2)}`.includes(status_item.month)
                    ))) ||
                // 現在ログインしているユーザーが施設長（5）または一般編集者（1）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
                ((position_id === 5 || position_id === 1) &&
                  approval_monthly_status.some(
                    (status_item: any) =>
                      status_item.leader_approved === true &&
                      `${new Date(props.date).getFullYear()}-${(
                        "0" +
                        (new Date(props.date).getMonth() + 1)
                      ).slice(-2)}`.includes(status_item.month)
                  )) ||
                // プロジェクト管理者（6）で、PJ管理者承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
                (position_id === 6 &&
                  approval_monthly_status.some(
                    (status_item: any) =>
                      status_item.administrator_approved === true &&
                      `${new Date(props.date).getFullYear()}-${(
                        "0" +
                        (new Date(props.date).getMonth() + 1)
                      ).slice(-2)}`.includes(status_item.month)
                  )) ||
                // 本部（2）で、本部承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
                (position_id === 2 &&
                  approval_monthly_status.some(
                    (status_item: any) =>
                      status_item.director_approved === true &&
                      `${new Date(props.date).getFullYear()}-${(
                        "0" +
                        (new Date(props.date).getMonth() + 1)
                      ).slice(-2)}`.includes(status_item.month)
                  )) ||
                (monthlyPlanTarget.status !== 1 &&
                  monthlyPlanTarget.status !== 2) ? (
                  <EventItemCheckboxSpan></EventItemCheckboxSpan>
                ) : (
                  <>
                    <EventsItemCheckbox
                      id={`monthly_plan_target_${monthlyPlanTarget.id}`}
                      type="checkbox"
                      name={`monthly_plan_target_${monthlyPlanTarget.id}`}
                      onClick={handleClickCheckbox}
                      value={monthlyPlanTarget.id}
                      checked={monthly_item_to_delete_array.some(
                        (item_to_delete: number) =>
                          item_to_delete === monthlyPlanTarget.id
                      )}
                    />
                    <EventsItemCheckboxLabel
                      htmlFor={`monthly_plan_target_${monthlyPlanTarget.id}`}
                    />
                  </>
                )
              }
            </EventsItemCheckboxWrapper>
            <EventsItemContent className="title">
              {month}月:月間計画
            </EventsItemContent>
            <EventsItemContent className="content">
              {monthlyPlanTarget.content}
            </EventsItemContent>
          </EventsItemWrapper>
        </EventsItemSC>
      ) : (
        <EventsItemSC
          onClick={event => handleClickAddEvents(event, props.date)}
          className={isEditableClass}
          style={
            (position_id === 1 &&
              (approval_monthly_status.some(
                (status_item: any) =>
                  status_item.leader_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              ) ||
                approval_monthly_status.some(
                  (status_item: any) =>
                    status_item.administrator_approved === true &&
                    `${new Date(props.date).getFullYear()}-${(
                      "0" +
                      (new Date(props.date).getMonth() + 1)
                    ).slice(-2)}`.includes(status_item.month)
                ) ||
                approval_monthly_status.some(
                  (status_item: any) =>
                    status_item.director_approved === true &&
                    `${new Date(props.date).getFullYear()}-${(
                      "0" +
                      (new Date(props.date).getMonth() + 1)
                    ).slice(-2)}`.includes(status_item.month)
                ))) ||
            // 現在ログインしているユーザーが施設長（5）または一般編集者（1）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
            ((position_id === 5 || position_id === 1) &&
              approval_monthly_status.some(
                (status_item: any) =>
                  status_item.leader_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              )) ||
            // プロジェクト管理者（6）で、PJ管理者承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
            (position_id === 6 &&
              approval_monthly_status.some(
                (status_item: any) =>
                  status_item.administrator_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              )) ||
            // 本部（2）で、本部承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
            (position_id === 2 &&
              approval_monthly_status.some(
                (status_item: any) =>
                  status_item.director_approved === true &&
                  `${new Date(props.date).getFullYear()}-${(
                    "0" +
                    (new Date(props.date).getMonth() + 1)
                  ).slice(-2)}`.includes(status_item.month)
              ))
              ? { backgroundColor: `${color.divide_border}`, cursor: "default" }
              : {}
          }
        >
          <EventsItemWrapper>
            <EventsItemCheckboxWrapper>
              <EventItemCheckboxSpan />
            </EventsItemCheckboxWrapper>
            <EventsItemContent className="title">
              {month}月:月間計画
            </EventsItemContent>
            <EventsItemContent className="content">
              月間計画を設定してください
            </EventsItemContent>
          </EventsItemWrapper>
        </EventsItemSC>
      )}
    </>
  );
}

export default EventsItem;

const EventsItemSC = styled.li`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  padding: 0 59px 0 18px;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
  cursor: pointer;
  &.not_editable {
    background-color: ${color.divide_border};
    cursor: default;
  }
`;

const EventsItemWrapper = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

const EventsItemCheckboxWrapper = styled.li`
  padding-top: 25px;
`;

const EventItemCheckboxSpan = styled.span`
  width: 39px;
  display: block;
`;

const EventsItemCheckbox = styled.input`
  display: none;
  &:checked + label::before {
    background-color: ${color.gray};
  }
  &:checked + label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;
const EventsItemCheckboxLabel = styled.label`
  padding-right: 39px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${color.gray}; /* 枠の色変更 お好きな色を */
    background-color: ${color.white}; /* 背景の色変更 お好きな色を */
  }
  &.hover {
    cursor: pointer;
  }
`;

const EventsremandButton = styled.img`
  position: absolute;
  right: 16px;
  top: 25px;
`;

const EventsItemAddButton = styled.img`
  position: absolute;
  right: -11px;
  top: 25px;
  cursor: pointer;
`;

const EventsItemContent = styled.li`
  padding: 25px 0 21px;
  font-size: 14px;
  color: #444;
  flex-shrink: 0;
  &:hover + li {
    display: block;
  }
  &.title {
    font-weight: bold;
    width: calc(14%);
  }
  &.content {
    width: calc(86%);
  }
  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
`;

const EventsItemHiddenContent = styled.li`
  display: none;
  position: absolute;
  top: 12px;
  left: 4px;
  border: 1px solid ${color.light_gray};
  border-radius: 4px;
  padding: 11px;
  background-color: ${color.white};
  font-size: 14px;
  color: ${color.gray};
  &.title {
    left: calc(57px + (100%) * 0.13 + 5px);
    &.hover {
      display: block;
    }
  }
  &.content {
    left: calc(57px + (100% - 77px) * 0.32 + 7px);
  }
  &.detail {
    left: calc(57px + (100% - 77px) * 0.54 + 7px);
  }
  &.class_age {
    left: calc(57px + (100% - 77px) * 0.81 + 7px);
  }
  &:hover {
    display: block;
  }
`;
