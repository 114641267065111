import axios from "axios";
import { env_url, env_event_url } from "../../serverEnv";

// 差し戻し情報登録
const postRejectApi = (token: string, event_id: number, comment: string) => {
  return axios.post(
    `${env_event_url}/reject`,
    {
      event_id: event_id,
      comment: comment
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const deleteRejectApi = (token: string, reject_id: number) => {
  return axios.delete(`${env_event_url}/reject/${reject_id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

//一括年間差し戻し
// const postRejectYearMonthlyApi = (
//   token: string,
//   facility_id: number,
//   year_months: string[]
// ) => {
//   try {
//     return axios.post(
//       `${env_event_url}/reject/year_monthly`,
//       {
//         facility_id: facility_id,
//         year_months: year_months
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       }
//     );
//   } catch (e) {
//     console.log("e", e);
//   }
// };

function* postRejectYearMonthlyApi(
  token: string,
  facility_id: number,
  year_months: string[]
) {
  try {
    return yield axios.post(
      `${env_event_url}/reject/year_monthly`,
      {
        facility_id: facility_id,
        year_months: year_months
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (e) {
    console.log("e", e);
  }
}

export { postRejectApi, deleteRejectApi, postRejectYearMonthlyApi };
