import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { color } from "../../mixins";
import { pc } from "../../mixins/mediaQuery";
import InquiriesItem from "./InquiriesItem";
import styled from "styled-components";
import { getInquiriesRequest } from "../../../store/faq/inquiries/action";
import { toggleInquiryNewPostStatus } from "../../../store/faq/inquiry_new/action";

function InquiriesList() {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const inquiries = useSelector((state: any) => state.inquiries);
  const page = useSelector((state: any) => state.paginate.inquiry_current_page);
  const limit = useSelector((state: any) => state.paginate.inquiry_per_page);

  // 一覧記事取得
  useEffect(() => {
    dispatch(getInquiriesRequest(token, page, limit));
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(toggleInquiryNewPostStatus(false));
  }, [dispatch]);

  console.log(inquiries);

  return (
    <InquiriesListSC>
      {inquiries &&
        inquiries.length > 0 &&
        inquiries.map((inquiry: any) => (
          <InquiriesItem
            key={inquiry.id}
            id={inquiry.id}
            user_name={inquiry.user.name}
            inquiry_name={inquiry.employee.name}
            employee_id={inquiry.employee.id}
            facility={inquiry.facility}
            created_at={inquiry.created_at}
          />
        ))}
    </InquiriesListSC>
  );
}

const InquiriesListSC = styled.ul`
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;

export default InquiriesList;
