import { AnyAction } from "redux";

import {
  GET_MANUALS_SUCCEEDED,
  GET_MANUALS_FAILED,
  UPDATE_ALL_MANUALS_SUCCEEDED,
  UPDATE_ALL_MANUALS_FAILED,
  GET_MANUAL_SUCCEEDED,
  GET_MANUAL_FAILED,
  UPDATE_MANUAL_WITH_IMAGE_SUCCEEDED,
  UPDATE_MANUAL_WITH_IMAGE_FAILED,
  UPDATE_MANUAL_TITLE_DESC_SUCCEEDED,
  UPDATE_MANUAL_TITLE_DESC_FAILED,
  SET_PAGE_TAGS,
  TOGGLE_PAGE_TAGS,
  MANUAL_SET_WITHOUT_REQUEST
} from "./types";

import {
  GET_TOPICS_SUCCEEDED,
  GET_TOPICS_FAILED,
  GET_TOPIC_SUCCEEDED,
  GET_TOPIC_FAILED,
  UPDATE_ALL_TOPICS_SUCCEEDED,
  UPDATE_ALL_TOPICS_FAILED
} from "./topics/types";

import {
  SET_PAGE_DETAIL,
  GET_PAGES_SUCCEEDED,
  GET_PAGES_FAILED,
  GET_PAGES_SEARCH_SUCCEEDED,
  GET_PAGES_SEARCH_FAILED,
  GET_PAGE_SUCCEEDED,
  GET_PAGE_FAILED,
  UPDATE_ALL_PAGES_SUCCEEDED,
  UPDATE_ALL_PAGES_FAILED,
  UPDATE_PAGE_SUCCEEDED,
  UPDATE_PAGE_FAILED,
  SET_PAGE_DETAIL_EDIT_MODE,
  SET_STORED_PAGE_DETAIL_STATUS,
  SET_SEARCH_TAGS,
  SET_SEARCH_KEYWORD,
  SET_PAGE_TITLE_ACTION,
  SET_PAGE_DETAIL_FROM_PAGES
} from "./pages/types";
import { ManualsInitialState } from "./types.d";
import { Page, TagItem } from "./pages/types.d";

const initialState: ManualsInitialState = {
  manuals: [],
  manualImage: {},
  manualDetail: {
    id: null,
    name: "",
    catch: "",
    description: "",
    order: 1,
    updated_at: "",
    image: "",
    history_flag: false
  },
  topics: [],
  topicDetail: {
    id: null,
    type_id: null,
    name: "",
    order: 1,
    updated_at: ""
  },
  pages: [],
  pageDetail: {
    id: 1,
    topic_id: 1,
    order: 1,
    title: "",
    title_draft: "",
    content: "",
    content_draft: "",
    status: 2,
    read: false,
    released_at: "",
    updated_at: "",
    tags: []
  },
  pageDetailOnEdit: {
    id: 1,
    topic_id: 1,
    order: 1,
    title: "",
    title_draft: "",
    content: "",
    content_draft: "",
    status: 2,
    read: false,
    released_at: "",
    updated_at: "",
    tags: []
  },
  pageDetailEditMode: false,
  pageTags: [],
  searchTags: [],
  searchKeyword: ""
};

const reducer = (
  state: ManualsInitialState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case GET_MANUALS_SUCCEEDED:
      console.log("action.result", action.result);
      return {
        ...state,
        manuals: action.result.data.types
      };

    case GET_MANUALS_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case UPDATE_ALL_MANUALS_SUCCEEDED:
      console.log("action.result", action.result);
      return {
        ...state,
        manuals: action.result.data.types
      };

    case UPDATE_ALL_MANUALS_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case GET_MANUAL_SUCCEEDED:
      return {
        ...state,
        manualDetail: action.result.data.type
      };

    case GET_MANUAL_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case UPDATE_MANUAL_WITH_IMAGE_SUCCEEDED:
      console.log("SUCCEEDED!", action.result);
      return {
        ...state,
        manualDetail: {
          ...state.manualDetail,
          ...action.result.data.type
        }
      };

    case UPDATE_MANUAL_WITH_IMAGE_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case UPDATE_MANUAL_TITLE_DESC_SUCCEEDED:
      console.log("SUCCEEDED!", action.result);
      return {
        ...state,
        manualDetail: {
          ...state.manualDetail,
          ...action.result.data.type
        }
      };

    case UPDATE_MANUAL_TITLE_DESC_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case GET_TOPICS_SUCCEEDED:
      console.log("action.result", action.result);
      return {
        ...state,
        topics: action.result.data.topics
      };

    case GET_TOPICS_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case GET_TOPIC_SUCCEEDED:
      return {
        ...state,
        topicDetail: action.result.data.topic
      };

    case GET_TOPIC_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case UPDATE_ALL_TOPICS_SUCCEEDED:
      console.log("action.result", action.result);
      return {
        ...state,
        topics: action.result.data.topics
      };

    case UPDATE_ALL_TOPICS_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case GET_PAGES_SUCCEEDED:
      console.log("action.result", action.result);
      return {
        ...state,
        pages: action.result.data.contents
      };

    case GET_PAGES_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case GET_PAGES_SEARCH_SUCCEEDED:
      console.log("action.result", action.result);
      return {
        ...state,
        pages: action.result.data.contents
      };

    case GET_PAGES_SEARCH_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case UPDATE_ALL_PAGES_SUCCEEDED:
      console.log("action.result", action.result);
      const updated_page_detail_on_edit = action.result.data.contents.find(
        (page: any) => page.id === state.pageDetailOnEdit.id
      );
      console.log("updated_page_detail_on_edit", updated_page_detail_on_edit);
      return {
        ...state,
        pages: action.result.data.contents,
        pageDetailOnEdit: updated_page_detail_on_edit
      };

    case UPDATE_ALL_PAGES_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case GET_PAGE_SUCCEEDED:
      console.log("action.result", action.result);
      if (
        state.pageDetailOnEdit &&
        state.pageDetailOnEdit.id === action.result.data.content.id
      ) {
        console.log(
          "if: action.result.data.content",
          action.result.data.content
        );
        return {
          ...state,
          pageDetail: {
            ...action.result.data.content
          }
        };
      } else {
        console.log(
          "else: action.result.data.content",
          action.result.data.content
        );
        return {
          ...state,
          pageDetail: {
            ...action.result.data.content
          },
          pageDetailOnEdit: {
            ...action.result.data.content
          }
        };
      }

    case GET_PAGE_FAILED:
      console.log("error_response in reducer", action.error_response);
      return state;

    case UPDATE_PAGE_SUCCEEDED:
      console.log("action", action);
      return {
        ...state,
        pages: action.result_pages.data.contents
      };

    case UPDATE_PAGE_FAILED:
      console.log("error_response in reducer", action.error_response);
      console.log("failed action", action);
      return state;

    case SET_PAGE_DETAIL:
      console.log("SET_PAGE_DETAIL action", action);
      return {
        ...state,
        pageDetailOnEdit: action.pageDetail
      };

    case SET_PAGE_DETAIL_EDIT_MODE:
      return {
        ...state,
        pageDetailEditMode: action.pageDetailEditMode
      };

    case SET_PAGE_TAGS:
      return {
        ...state,
        pageTags: action.pageTags
      };

    case TOGGLE_PAGE_TAGS:
      console.log("actionaction", action);
      const updated_page_detail_on_edit_tags = state.pageDetailOnEdit.tags.some(
        (tag: any) => tag && tag.id === action.selected_id
      )
        ? state.pageDetailOnEdit.tags.filter(
            (tag: any) => tag && tag.id !== action.selected_id
          )
        : [
            ...state.pageDetailOnEdit.tags,
            action.manualTags.find(
              (tag: any) => tag && tag.id === action.selected_id
            )
          ];
      return {
        ...state,
        pageDetailOnEdit: {
          ...state.pageDetailOnEdit,
          tags: updated_page_detail_on_edit_tags
        }
      };
    case SET_STORED_PAGE_DETAIL_STATUS:
      return {
        ...state,
        pageDetailOnEdit: {
          ...state.pageDetailOnEdit,
          status: action.status
        }
      };
    case SET_SEARCH_TAGS:
      return {
        ...state,
        searchTags: action.tagToAdd
          ? [...action.oldTags, action.tagToAdd]
          : action.oldTags
      };
    case SET_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.searchKeyword
      };

    case SET_PAGE_TITLE_ACTION:
      return {
        ...state,
        pageDetailOnEdit: {
          ...state.pageDetailOnEdit,
          title_draft: action.title_draft
        }
      };

    case MANUAL_SET_WITHOUT_REQUEST:
      return {
        ...state,
        manualDetail: state.manuals.find(
          (manual: any) => manual.id === action.manualId
        )
      };

    case SET_PAGE_DETAIL_FROM_PAGES:
      const selectedPage = action.pages.find(
        (page: Page) => page.id === Number(action.pageId)
      );
      return {
        ...state,
        pageDetail: selectedPage ? selectedPage : state.pageDetail
      };
    default:
      return state;
  }
};

export default reducer;
