import { put, call, takeLatest, takeEvery, all } from "redux-saga/effects";
import {
  deleteTagItemApi,
  postNewTagItemApi,
  updateTagItemApi,
  getTagsApiAfterPost
} from "../api/FAQ/tagsApi";
import { apiGetTags } from "../api/global/sessionApi";
import {
  DELETE_TAG_ITEM_SUCCEEDED,
  DELETE_TAG_ITEM_FAILED,
  DELETE_TAG_ITEM_REQUESTED,
  GET_REGISTERED_TAGS_REQUESTED,
  GET_REGISTERED_TAGS_SUCCEEDED,
  POST_NEW_TAG_ITEM_REQUESTED,
  POST_NEW_TAG_ITEM_SUCCEEDED,
  POST_NEW_TAG_ITEM_FAILED,
  UPDATE_TAG_ITEM_SUCCEEDED,
  UPDATE_TAG_ITEM_FAILED,
  UPDATE_TAG_ITEM_REQUESTED
} from "../store/faq/tags/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
function* getRegisteredTags(action: any) {
  const token = action.token;
  try {
    const result = yield call(apiGetTags, token);
    console.log("get registered Tags success: ", result);
    // return result.data.Tags
    yield put({ type: GET_REGISTERED_TAGS_SUCCEEDED, tags: result.data.tags });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* deleteRegisteredTag(action: any) {
  try {
    const token = action.token;
    const id = action.id;
    const result = yield call(deleteTagItemApi, token, id);
    yield put({ type: DELETE_TAG_ITEM_SUCCEEDED, status: result.status });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postNewTagItem(action: any) {
  try {
    const token = action.token;
    const temp_id = action.temp_id;
    const name = action.new_tag_name;
    const order = action.new_tag_order;
    const post_result = yield* postNewTagItemApi(token, name, order);
    const get_result = yield* getTagsApiAfterPost(token);
    yield all([
      put({
        type: POST_NEW_TAG_ITEM_SUCCEEDED,
        status: post_result.status,
        temp_id: temp_id
      }),
      put({ type: GET_REGISTERED_TAGS_SUCCEEDED, tags: get_result.data.tags })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* updateTagItem(action: any) {
  try {
    const token = action.token;
    const name = action.updated_name;
    const order = action.updated_order;
    const id = action.id;
    console.log("token", token);
    console.log("id", id);
    console.log("order", order);
    console.log("name", name);
    const result = yield call(updateTagItemApi, token, name, order, id);
    yield put({ type: UPDATE_TAG_ITEM_SUCCEEDED, status: result.status, id });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const categorySaga = [
  takeLatest(GET_REGISTERED_TAGS_REQUESTED, getRegisteredTags),
  takeEvery(DELETE_TAG_ITEM_REQUESTED, deleteRegisteredTag),
  takeEvery(POST_NEW_TAG_ITEM_REQUESTED, postNewTagItem),
  takeEvery(UPDATE_TAG_ITEM_REQUESTED, updateTagItem)
];

export default categorySaga;
