export const localeTimeCustomizerForHistory = (given_time: string) => {
  const year = new Date(given_time.replace(/-/g, "/")).getFullYear();
  const month = new Date(given_time.replace(/-/g, "/")).getMonth() + 1;
  const date = new Date(given_time.replace(/-/g, "/")).getDate();
  const hour = new Date(given_time.replace(/-/g, "/")).getHours();
  const minute = new Date(given_time.replace(/-/g, "/")).getMinutes();
  const local_time =
    year + "年" + month + "月" + date + "日" + hour + "時" + minute + "分";
  return local_time;
};

export const localeTimeForManual = (given_time: string) => {
  const year = new Date(given_time).getFullYear();
  const month = new Date(given_time).getMonth() + 1;
  const date = new Date(given_time).getDate();
  const dayOfWeek = new Date(given_time).getDay(); // 曜日(数値)
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
  const local_time =
    year + "年" + month + "月" + date + "日(" + dayOfWeekStr + ")";
  console.log(year);
  return local_time;
};
