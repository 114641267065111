import {
  SET_ACCOUNT_NEW_POSITION_ID,
  SET_ACCOUNT_NEW_NAME,
  SET_ACCOUNT_NEW_EMAIL,
  SET_ACCOUNT_NEW_PASSWORD,
  POST_ACCOUNT_NEW_SUCCEEDED,
  POST_ACCOUNT_NEW_FAILED,
  TOGGLE_ACCOUNT_NEW_POST_STATUS,
  SET_ACCOUNT_NEW_FACILITIES,
} from "./type";

import { GET_ACCOUNTS_SUCCEEDED } from "../accounts/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";

interface AccountNew {
  name: string;
  email: string;
  password: string;
  position_id: number | null;
  post_status: boolean;
  facilities: [];
}

const initialState: AccountNew = {
  name: "",
  email: "goest@faq.com",
  password: "password",
  position_id: null,
  post_status: false,
  facilities: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ACCOUNTS_SUCCEEDED:
      return {
        ...state,
        post_status: false,
      };

    case SET_ACCOUNT_NEW_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_ACCOUNT_NEW_POSITION_ID:
      return {
        ...state,
        position_id: action.position_id,
      };
    case SET_ACCOUNT_NEW_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_ACCOUNT_NEW_PASSWORD:
      return {
        ...state,
        password: action.password,
      };

    case SET_ACCOUNT_NEW_FACILITIES:
      if (
        state.facilities.some(
          (facility: any) => facility.id === action.facility_id
        )
      ) {
        return {
          ...state,
          facilities: state.facilities.filter(
            (facility: any) => facility.id !== action.facility_id
          ),
        };
      }
      return {
        ...state,
        facilities: [
          ...state.facilities,
          {
            id: action.facility_id,
          },
        ],
      };

    case POST_ACCOUNT_NEW_SUCCEEDED:
      console.log("新規ユーザー作成に成功しました status: ", action.status);
      return {
        name: "",
        email: "",
        password: "",
        position_id: 1,
        post_status: true,
        facilities: [],
      };

    case POST_ACCOUNT_NEW_FAILED:
      console.log("新規ユーザー作成エラー エラーコード:", action.error_code);
      return {
        ...state,
      };

    case TOGGLE_ACCOUNT_NEW_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
