import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FlexPageLayout } from "../../Parts/MainLayout";
import SearchModuleForManual from "../../SearchBlockForManual";
import ModuleArea from "../../Parts/ModuleArea";
import { useHooks } from "./Hooks";
import UpdateHistoriesModule from "components/Manual/UpdateHistoriesModule";
import { useDispatch } from "react-redux";
import BlockContainer from "components/Manual/Parts/BlockContainer";
import BlockHeaderAdmin from "components/Manual/Parts/BlockHeaderAdmin";
import PagesUneditableList from "./PagesUneditableList";
import PagesEditableList from "./PagesEditableList";
import PagesSortableList from "./PagesSortableList";
import BottomActionButtonsForPagesList from "components/Manual/Parts/BottomActionButtonsForPagesList";
import { manualTagsSelector } from "store/manual/tags/selector";
import { toggleRightSection } from "../../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../../styles/layout/containers";

function Page() {
  const {
    histories,
    manualId,
    topicId,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    cancelEdit,
    hookedPages,
    updatePageTitle,
    handleAdd,
    removeHookedItem,
    changePagesOrder,
    updateAllPagesAction,
    hookedTopic,
    topicName
  } = useHooks({});
  const dispatch = useDispatch();

  const tags = useSelector(manualTagsSelector);
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  return (
    <FlexPageLayout
      pageTitle={topicName}
      topicTitle={topicName}
      toTopicsPath={`/manual/admin/manuals/${manualId}/`}
    >
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <BlockContainer>
        <BlockHeaderAdmin
          blockTitle="ページ一覧"
          editTitleMode={editTitleMode}
          setEditTitleMode={setEditTitleMode}
          editOrderMode={editOrderMode}
          setEditOrderMode={setEditOrderMode}
          cancelEdit={cancelEdit}
        />

        <Content>
          <>
            {/* 最初の表示 */}
            {!editTitleMode && !editOrderMode && (
              <PagesUneditableList
                hookedPages={hookedPages}
                manualId={manualId}
                topicId={topicId}
              />
            )}

            {/* 名称編集中 */}
            {editTitleMode && !editOrderMode && (
              <PagesEditableList
                hookedPages={hookedPages}
                updatePageTitle={updatePageTitle}
                handleAdd={handleAdd}
                removeHookedItem={removeHookedItem}
              />
            )}

            {!editTitleMode && editOrderMode && (
              <PagesSortableList
                hookedPages={hookedPages}
                changePagesOrder={changePagesOrder}
              />
            )}

            <BottomActionButtonsForPagesList
              hookedTopic={hookedTopic}
              sidebar_is_open={true}
              hookedPages={hookedPages}
              editTitleMode={editTitleMode}
              setEditTitleMode={setEditTitleMode}
              editOrderMode={editOrderMode}
              setEditOrderMode={setEditOrderMode}
              complete_action={updateAllPagesAction}
            />
          </>
        </Content>
      </BlockContainer>
      <ModuleArea>
        <SearchModuleForManual
          manualId={Number(manualId)}
          topicId={Number(topicId)}
          data={tags}
        />
        <UpdateHistoriesModule data={histories} />
      </ModuleArea>
    </FlexPageLayout>
  );
}

export default Page;

const Content = styled.div`
  position: relative;
  padding-bottom: 60px;
`;
