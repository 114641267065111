import axios from "axios";
import { env_url, env_event_url } from "../../serverEnv";
import { getEventsYearsApi } from "./eventApi";

const getFacilitiesApprovalsStatusApi = (token: string, year: number) => {
  return axios.get(`${env_event_url}/approvals`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      year: year,
    },
  });
};

const getApprovalsMonthlyApi = (
  token: string,
  facility_id: number,
  year: number
) => {
  return axios.get(`${env_event_url}/approvals/monthly`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      facility_id: facility_id,
      year: year,
    },
  });
};
function* getApprovalsAfterPostApi(
  token: string,
  facility_id: number,
  year: number
) {
  try {
    return yield axios.get(`${env_event_url}/approvals/monthly`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        facility_id: facility_id,
        year: year,
      },
    });
  } catch (e) {
    console.log("e", e);
  }
}

// 行事予定月別承認登録API
function* postApprovalMonthlyApi(
  token: string,
  facility_id: number,
  months: []
) {
  try {
    return yield axios.post(
      `${env_event_url}/approvals/monthly`,
      {
        facility_id: facility_id,
        months: months,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
  }
}

// const postCancelAllEventsApi = (token: string, facility_id: number, year: number, month: number) => {
//   return axios.post(
//     `${env_event_url}/reject/monthly`,
//     {
//       facility_id: facility_id,
//       year: year,
//       month: month,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

// 一括承認解除
function* postCancelAllEventsApi(
  token: string,
  facility_id: number,
  year: number,
  month: number
) {
  try {
    return yield axios.post(
      `${env_event_url}/reject/monthly`,
      {
        facility_id: facility_id,
        year: year,
        month: month,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    console.log("e", e);
  }
}

export {
  getFacilitiesApprovalsStatusApi,
  getApprovalsMonthlyApi,
  postApprovalMonthlyApi,
  postCancelAllEventsApi,
  getApprovalsAfterPostApi,
};
