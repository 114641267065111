import React from "react";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../mixins";
import { Link } from "react-router-dom";
import { Page } from "store/manual/manuals/pages/types.d";
import { TopicItem } from "store/manual/manuals/topics/types.d";

interface Props {
  hookedTopic: TopicItem;
  hookedPages: Page[];
  editTitleMode: boolean;
  setEditTitleMode: (editTitleMode: boolean) => void;
  editOrderMode: boolean;
  setEditOrderMode: (editOrderMode: boolean) => void;
  sidebar_is_open: boolean;
  complete_action: (data: {
    req_body: Page[];
    type: number | null;
    topic: number | null;
  }) => void;
}

function ActionButtonsBottomBar(props: Props) {
  const {
    hookedTopic,
    hookedPages,
    sidebar_is_open,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    complete_action
  } = props;

  const handleUpdate = () => {
    setEditTitleMode(false);
    setEditOrderMode(false);
    complete_action({
      req_body: hookedPages,
      type: hookedTopic.type_id,
      topic: hookedTopic.id
    });
  };

  return (
    <>
      {editTitleMode && !editOrderMode && (
        <FixedButtonAreaSC className={sidebar_is_open ? "" : "sidebar-closed"}>
          <SubmitButtonSC className="active" onClick={handleUpdate}>
            完了
          </SubmitButtonSC>
        </FixedButtonAreaSC>
      )}

      {!editTitleMode && editOrderMode && (
        <FixedButtonAreaSC className={sidebar_is_open ? "" : "sidebar-closed"}>
          <SubmitButtonSC className="active" onClick={handleUpdate}>
            完了
          </SubmitButtonSC>
        </FixedButtonAreaSC>
      )}
    </>
  );
}

export default ActionButtonsBottomBar;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    margin-left: -30px;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${color.gray};
  ${pc`
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 14px;
    `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  &.active {
    &:hover {
      opacity: 0.6;
    }
  }
`;

const PreviewButtonSC = styled(Link)`
  ${buttonBaseStyle}
  opacity: 1;
  color: ${color.primary};
  background-color: ${color.white};
  border: 1px solid ${color.primary};
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const BackButtonSC = styled(Link)`
  ${buttonBaseStyle}
  margin-right: 12px;
  border: 1px solid ${color.primary};
  color: ${color.primary};
  background-color: ${color.white};
  opacity: 1;
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
