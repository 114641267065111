import React from "react";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import styled from "styled-components";
import { color } from "components/mixins";
import editCancelIcon from "images/icons/editmode_cancel.svg";

interface Props {
  topic: TopicItem;
  updateTopicTitle: (data: { order: number; new_name: string }) => void;
  removeHookedItem: (data: { topic: TopicItem }) => void;
}

function TopicsEditableItem(props: Props) {
  const { topic, updateTopicTitle, removeHookedItem } = props;

  const handleChange = (data: {
    e: React.ChangeEvent<HTMLInputElement>;
    order: number;
  }) => {
    const { e, order } = data;
    updateTopicTitle({ order: order, new_name: e.target.value });
  };

  return (
    <TopicsItemTitleEditable>
      <TopicsItemContent>
        <input
          type="text"
          value={topic.name}
          onChange={e => handleChange({ e: e, order: topic.order })}
        />
        <img
          src={editCancelIcon}
          alt="Remove"
          style={{ cursor: "pointer" }}
          onClick={() => {
            removeHookedItem({ topic });
          }}
        />
      </TopicsItemContent>
    </TopicsItemTitleEditable>
  );
}

export default TopicsEditableItem;

const TopicsItemTitleEditable = styled.li`
  display: block;
  padding: 22px 22px 22px 55px;
  border-bottom: 1px solid ${color.list_border};
`;

const TopicsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
