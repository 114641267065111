import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import PageHeading from "../../../Common/PageHeading";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";

import ArticlesNewEditor from "./ArticleNewEditor";
import {
  putNewArticleRequest,
  toggleArticleNewPostStatus
} from "../../../../store/faq/article_new/action";

import { history } from "../../../../store/store";

function ArticlesNew(props: any) {
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const dispatch = useDispatch();
  // const history = useHistory();

  const token = useSelector((state: any) => state.session.token);
  const new_article_id = useSelector(
    (state: any) => state.article_new.new_article_id
  );
  const title = useSelector((state: any) => state.article_new.title);
  const content = useSelector((state: any) => state.article_new.content);
  // const search_string = useSelector((state: any) => state.article_new.search_string);
  const search_string = useSelector(
    (state: any) => state.article_new.search_string
  );
  const status = useSelector((state: any) => state.article_new.status);
  const categories = useSelector((state: any) => state.article_new.categories);
  const tags = useSelector((state: any) => state.article_new.tags);

  const post_status = useSelector(
    (state: any) => state.article_new.post_status
  );
  // 必須入力項目の入力バリデーションに必要
  const title_input_status = useSelector(
    (state: any) => state.article_new.title_input_status
  );

  const handleRegister = () => {
    console.log(new_article_id);
    console.log(token);
    console.log(title);
    console.log(content);
    console.log(search_string);
    console.log(status);
    console.log(categories);
    console.log(tags);
    // 「登録」ボタンクリック時に実際の新規記事情報を入力
    dispatch(
      putNewArticleRequest(
        new_article_id,
        token,
        title,
        content,
        search_string,
        status,
        categories,
        tags
      )
    );
  };

  useEffect(() => {
    dispatch(toggleArticleNewPostStatus(false));
  }, [dispatch]);

  // ①コンポーネント表示時にPOSTリクエストで記事作成
  // useEffect(() => {
  //   dispatch(postArticleRequest(token, title, content, search_string, status, categories, tags));
  // }, [dispatch, token, title, content, search_string, status, categories, tags]);

  return (
    <>
      {post_status ? (
        history.push("/articles")
      ) : (
        <MainSC>
          <PageHeading title="FAQ記事登録" />
          <ArticlesNewBlockSC
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            <ArticlesNewEditor />
          </ArticlesNewBlockSC>

          {title_input_status ? (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、ありません
              </ButtonAreaDeskSC>
              <SubmitButtonSC className="active" onClick={handleRegister}>
                登録
              </SubmitButtonSC>
              <PreviewButtonSC to="/articles/new-preview" target="_blank">
                プレビュー
              </PreviewButtonSC>
            </FixedButtonAreaSC>
          ) : (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、残り１つです
              </ButtonAreaDeskSC>
              <SubmitButtonSC>登録</SubmitButtonSC>
              <PreviewButtonSC to="/articles/new-preview" target="_blank">
                プレビュー
              </PreviewButtonSC>
            </FixedButtonAreaSC>
          )}
        </MainSC>
      )}
    </>
  );
}

export default ArticlesNew;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const ArticlesNewBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    padding: 30px;
    margin-right: 10px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    margin-left: -30px;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  &.active {
    &:hover {
      opacity: 0.6;
    }
  }
`;

const PreviewButtonSC = styled(Link)`
  ${buttonBaseStyle}
  opacity: 1;
  color: ${color.primary};
  background-color: ${color.white};
  border: 1px solid ${color.primary};
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
