import React from "react";
import styled from "styled-components";
import { pc } from "../mixins/mediaQuery";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { color } from "../mixins";
import {
  faq_list,
  faq_list_current,
  faq_register,
  faq_register_current,
  event_home,
  event_home_current,
  faq,
  faq_gray,
  account_current,
  up,
  down,
  sidebar_close,
  sidebar_open,
  news,
  news_current,
  inquiry,
  inquiry_current,
  inquiry_history,
  inquiry_history_event_current,
  inquiry_form,
  inquiry_form_event_current,
  manual,
  manual_current,
  home,
  home_current,
  master_setting,
  master_setting_gray
} from "./sidebarIcons";
import {
  toggleSidebar,
  toggleNews,
  toggleInquiry
} from "../../store/common/sidebar/action";
import { manualManualsSelector } from "store/manual/manuals/selectors";

function SidebarManual() {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const isOpen = useSelector((state: any) => state.sidebar.sidebarIsOpen);
  const newsIsOpen = useSelector((state: any) => state.sidebar.newsIsOpen);
  const inquiryIsOpen = useSelector(
    (state: any) => state.sidebar.inquiryIsOpen
  );
  const position_id = useSelector((state: any) => state.session.position.id);
  const hookedManuals = useSelector(manualManualsSelector);

  return (
    <SidebarSC className={isOpen ? "" : "closed"}>
      <SidebarList>
        <SideBarItem>
          <SideBarLabel onClick={() => dispatch(toggleSidebar())}>
            {isOpen ? (
              <SidebarArrow src={sidebar_close} alt="close" />
            ) : (
              <SidebarArrow src={sidebar_open} alt="open" />
            )}
          </SideBarLabel>
        </SideBarItem>

        <SideBarItem style={{ height: "46px" }}>
          <SidebarServiceMenu>{isOpen && "サービス"}</SidebarServiceMenu>
        </SideBarItem>

        {/* FAQ TOP */}
        <SideBarItem
          className={pathName === "/" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/" className="faqHome">
            {isOpen && "FAQ"}
          </SidebarTitle>
        </SideBarItem>

        {/*  行事予定TOP */}
        <SideBarItem
          className={pathName === "/events/home" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/events/home" className="eventHome">
            {isOpen && "行事予定"}
          </SidebarTitle>
        </SideBarItem>

        {/*  マニュアルTOP */}
        <SideBarItem
          className={pathName === "/manual" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/manual" className="manual">
            {isOpen && "マニュアル"}
          </SidebarTitle>
        </SideBarItem>

        {/* お知らせ */}
        <SideBarItem
          className={pathName === "/news" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/news" className="news_list">
            {isOpen && "お知らせ"}
          </SidebarTitle>
        </SideBarItem>

        {/* お問合せ */}
        <SideBarItem
          className={inquiryIsOpen ? "active inquiries_list" : "inquiries_list"}
        >
          <SideBarLabel
            className="inquiry"
            onClick={() => dispatch(toggleInquiry())}
          >
            {isOpen && "お問合せ"}
            {isOpen && inquiryIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !inquiryIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {inquiryIsOpen ? (
            <ul>
              <FoldingItem className="inquiries_list">
                <Link to="/inquiries">{isOpen && "お問合せ履歴"}</Link>
              </FoldingItem>
              <FoldingItem className="inquiries_new">
                <Link to="/inquiries/new">{isOpen && "お問合せフォーム"}</Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        <SideBarItem>
          <SidebarAdminMenu>{isOpen && "マニュアルメニュー"}</SidebarAdminMenu>
        </SideBarItem>

        {/* マニュアルHOME */}
        <SideBarItem
          className={pathName === "/manual" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/manual" className="home">
            {isOpen && "マニュアルHOME"}
          </SidebarTitle>
        </SideBarItem>

        {/* マニュアル一覧 */}
        <SideBarItem
          style={{ borderBottom: `1px solid ${color.light_gray}` }}
          className={newsIsOpen ? "active" : ""}
        >
          <SideBarLabel
            className="manual"
            onClick={() => dispatch(toggleNews())}
          >
            {isOpen && "マニュアル一覧"}
            {isOpen && newsIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !newsIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {newsIsOpen ? (
            <ul>
              {hookedManuals &&
                hookedManuals.map((val: any, index: number) => (
                  <FoldingItem key={index}>
                    <Link className="no-label" to={`/manual/manuals/${val.id}`}>
                      {isOpen && `  - ${val.name}`}
                    </Link>
                  </FoldingItem>
                ))}
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        <SideBarItem>
          <SidebarAdminMenu>{isOpen && "管理者メニュー"}</SidebarAdminMenu>
        </SideBarItem>

        <SideBarItem
          className={pathName.includes("/manual/admin/manuals") ? "active" : ""}
        >
          <SidebarTitle className="manualList" to="/manual/admin/manuals">
            {isOpen && "マニュアル管理"}
          </SidebarTitle>
        </SideBarItem>
        {position_id === 3 && (
          <>
            {/* タグ設定 */}
            <SideBarItem
              className={pathName === "/manual/admin/tags" ? "active" : ""}
            >
              <SidebarTitle to="/manual/admin/tags" className="tags">
                {isOpen && "タグ設定"}
              </SidebarTitle>
            </SideBarItem>

            {/* 更新履歴 */}
            <SideBarItem
              className={pathName === "/manual/admin/histories" ? "active" : ""}
            >
              <SidebarTitle className="manualList" to="/manual/admin/histories">
                {isOpen && "更新履歴"}
              </SidebarTitle>
            </SideBarItem>
          </>
        )}
      </SidebarList>
    </SidebarSC>
  );
}

export default SidebarManual;

// ===================================================================================
// スタイル
// ===================================================================================
const SidebarSC = styled.aside`
  position: relative;
  min-width: 200px;
  max-width: 200px;
  height: calc(100vh - 60px);
  background: ${color.gray};
  overflow-x: hidden;
  overflow-y: scroll;
  transition-duration: 0.2s;
  display: none;
  ${pc`
    display: block;
    position: fixed;
    min-width: 200px;
    height: calc( 100vh - 60px );
    background: ${color.gray};
    overflow-x: hidden;
    overflow-y: scroll;
    transition-duration: 0.2s;
    top: 60px;
  `}
  &.closed {
    max-width: 50px;
    min-width: 50px;
    transition-duration: 0.2s;
  }
`;

const SidebarServiceMenu = styled.p`
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  height: 46px;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
  padding-top: 9%;
`;

const SidebarAdminMenu = styled.p`
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  height: 46px;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
  padding-top: 9%;
`;
const SidebarTitle = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  /* border-bottom: 1px solid ${color.light_gray}; */
  box-sizing: border-box;
  &:hover {
    background-color: ${color.white};
    color: ${color.gray};
  }
  &.eventHome {
    background-image: url(${event_home});
    &:hover,
    &.current {
      background-image: url(${event_home_current});
    }
  }
  &.faqHome {
    background-image: url(${faq});
    &:hover,
    &.current {
      background-image: url(${faq_gray});
    }
  }
  &.manual {
    background-image: url(${manual});
    &:hover,
    &.current {
      background-image: url(${manual_current});
    }
  }
  &.home {
    background-image: url(${home});
    &:hover,
    &.current {
      background-image: url(${home_current});
    }
  }
  &.tags {
    background-image: url(${master_setting});
    &:hover,
    &.current {
      background-image: url(${master_setting_gray});
    }
  }
  &.adminMenu {
    padding-top: 30px;
    color: rgba(255, 255, 255, 0.5);
  }
  &.news_list {
    background-image: url(${news});
    &:hover,
    &.current {
      background-image: url(${news_current});
    }
  }
  &.manualList {
    background-image: url(${faq});
    &:hover,
    &.current {
      background-image: url(${faq_gray});
    }
  }
`;

const SidebarList = styled.ul`
  width: 100%;
`;

const SideBarLabel = styled.p`
  position: relative;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  white-space: nowrap;
  &.news {
    background-image: url(${news});
    &:hover,
    &.current {
      background-image: url(${news_current});
    }
  }
  &.inquiry {
    background-image: url(${inquiry});
    &:hover,
    &.current {
      background-image: url(${inquiry_current});
    }
  }
  &.manual {
    background-image: url(${manual});
    &:hover,
    &.current {
      background-image: url(${manual_current});
    }
  }
  &.manualList {
    background-image: url(${faq});
    &:hover,
    &.current {
      background-image: url(${faq_gray});
    }
  }
  &:hover {
    background-color: ${color.white};
    color: ${color.gray};
  }
`;

const Arrow = styled.img`
  position: absolute;
  right: 16px;
  top: 23px;
`;

const SidebarArrow = styled(Arrow)`
  top: 18px;
`;

const SideBarItem = styled.li`
  height: auto;
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
  cursor: pointer;
  &.active {
    background-color: rgba(242, 242, 242, 0.3);
    > p.account {
      background-image: url(${account_current});
    }
  }
  &.inquiries_list {
    border-bottom: 1px solid ${color.light_gray};
  }
`;

const FoldingItem = styled.li`
  height: 50px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  &:hover {
    background-color: ${color.white};
    color: ${color.gray};
  }
  &.inquiries_list {
    background-image: url(${inquiry_history});
    &:hover,
    &.current {
      background-image: url(${inquiry_history_event_current});
    }
  }
  &.inquiries_new {
    background-image: url(${inquiry_form});
    &:hover,
    &.current {
      background-image: url(${inquiry_form_event_current});
    }
  }
  &.manual_list {
    background-image: url(${faq_list});
    &:hover,
    &.current {
      background-image: url(${faq_list_current});
    }
  }
  &.manual_register {
    background-image: url(${faq_register});
    &:hover,
    &.current {
      background-image: url(${faq_register_current});
    }
  }

  a,
  button {
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    padding: 18px 0 18px 50px;
    &.no-label {
      padding: 18px 16px 18px 20px;
    }
  }
`;
