import {
  CameraClass,
  CameraFacility,
  CameraTag,
  User,
  isMultiValue,
  AlbumViewType
} from "components/Camera/type";
import React, { ChangeEvent } from "react";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { TextInput } from "../TextInput";
import { DatePicker } from "./DatePicker";
import { FacilitySelect } from "./FacilitySelect";
import {
  BtnWrapperSC,
  CommonSearchBlockSC,
  FacilitySearchBlockSC,
  FlexWrapperSC,
  WrapperSC,
  LabelSC,
  SearchBlockContainerSC
} from "./style";
import { UploaderSelect } from "./UploaderSelect";
import { SearchParams } from "store/camera/mediafiles/reducer";
import { useSearchBlock } from "./use-search-block";

interface SearchBlockType {
  isAdmin: boolean;
  viewType: AlbumViewType;
  fileId: string;
  classes: CameraClass[];
  tags: CameraTag[];
  users: User[];
  facilities: CameraFacility[];
  from: Date | [Date, Date] | null;
  to: Date | [Date, Date] | null;
  onChangeFrom: (date: Date | [Date, Date] | null) => void;
  onChangeTo: (date: Date | [Date, Date] | null) => void;
  onChangeFileId: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeClasses: (id: number) => void;
  onChangeTags: (id: number) => void;
  onChangeUsers: (value: isMultiValue) => void;
  onChangeFacility: (value: isMultiValue) => void;
  onChangeShareBan: (value: boolean) => void;
  onResetFunc: () => void;
  onSearchFunc: () => void;
  onResetTakenFrom: () => void;
  onResetTakenTo: () => void;
  searchParams: SearchParams;
}

export const SearchBlock = (props: SearchBlockType) => {
  const { onChangeFileIdHook, setPageNumberHook } = useSearchBlock("");
  const {
    isAdmin,
    viewType,
    fileId,
    classes,
    tags,
    facilities,
    users,
    from,
    to,
    onChangeFrom,
    onChangeTo,
    onChangeClasses,
    onChangeFacility,
    onChangeUsers,
    onChangeFileId,
    onChangeTags,
    onSearchFunc,
    onResetTakenFrom,
    onResetTakenTo,
    searchParams,
    onResetFunc
  } = props;

  return (
    <SearchBlockContainerSC>
      <CommonSearchBlockSC>
        {/* {isAdmin && (
          <TextInput
            label="ファイルID"
            value={fileId}
            onChange={e => {
              onChangeFileIdHook(e.target.value);
              onChangeFileId(e);
            }}
            placeholder="IDをカンマ区切りで入力(例: 1,2,3)"
          />
        )} */}
        <DatePicker
          from={from}
          to={to}
          onChangeFrom={onChangeFrom}
          onChangeTo={onChangeTo}
          onResetTakenFrom={onResetTakenFrom}
          onResetTakenTo={onResetTakenTo}
        />
        <FlexWrapperSC>
          <LabelSC>クラス</LabelSC>
          {classes.map((classroom, i) => {
            return (
              <Checkbox
                key={i}
                id={`class-${classroom.id.toString()}`}
                label={
                  classroom?.name === "6"
                    ? `${classroom.name}歳児以上`
                    : `${classroom.name}歳児`
                }
                checked={searchParams.class_id!.includes(classroom.id)}
                onChange={() => onChangeClasses(classroom.id)}
              />
            );
          })}
          <Checkbox
            id={`only_doesnt_have_class`}
            label={`設定無し`}
            checked={searchParams?.only_doesnt_have_class === 1}
            onChange={() => onChangeClasses(99)}
          />
        </FlexWrapperSC>
        <FlexWrapperSC>
          <LabelSC>タグ</LabelSC>
          {tags.map((tag, i) => {
            return (
              <Checkbox
                key={i}
                id={`tag-${tag.id.toString()}`}
                label={tag.name}
                checked={searchParams.tag_id!.includes(tag.id)}
                onChange={() => onChangeTags(tag.id)}
              />
            );
          })}
        </FlexWrapperSC>
        {/* <FlexWrapperSC>
          <LabelSC>公開設定</LabelSC>
          <RadioButton
            id="search_published"
            name="search_setting"
            label="公開"
            value="公開"
            checked={searchParams.share_ban! === false}
            onChange={() => onChangeShareBan(false)}
          />
          <RadioButton
            id="search_private"
            name="search_setting"
            label="非公開"
            value="非公開"
            checked={searchParams.share_ban! === true}
            onChange={() => onChangeShareBan(true)}
          />
        </FlexWrapperSC> */}
        <WrapperSC>
          <LabelSC fullWidth>アップロード者</LabelSC>
          <UploaderSelect
            disabled={viewType === "mine"}
            users={users}
            onSelectUploader={onChangeUsers}
          />
        </WrapperSC>
        <BtnWrapperSC>
          <Button label="項目をリセット" onClick={onResetFunc} />
          <Button label="絞り込み" onClick={onSearchFunc} />
        </BtnWrapperSC>
      </CommonSearchBlockSC>
      {/* 所属施設が２つ以上の場合のみ表示 */}
      {facilities.length > 1 && (
        <FacilitySearchBlockSC>
          <LabelSC fullWidth>施設</LabelSC>
          <FacilitySelect
            facilities={facilities}
            onFacilitySelect={onChangeFacility}
            selectedFacilities={searchParams.facility_id}
          />
        </FacilitySearchBlockSC>
      )}
    </SearchBlockContainerSC>
  );
};
