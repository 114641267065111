import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";
import editName from "../../../images/icons/editmode.svg";
import editOrder from "../../../images/icons/order-change.svg";
import editmodeCancel from "../../../images/icons/editmode_cancel.svg";

interface Props {
  blockTitle: string;
  editTitleMode: boolean;
  setEditTitleMode: (editTitleMode: boolean) => void;
  editOrderMode: boolean;
  setEditOrderMode: (editOrderMode: boolean) => void;
  cancelEdit: () => void;
}

const BlockHeaderAdmin = (props: Props) => {
  const {
    blockTitle,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    cancelEdit
  } = props;

  const handleStartEditOrder = () => {
    setEditOrderMode(!editOrderMode);
  };

  const handleStartEditTitle = () => {
    setEditTitleMode(!editTitleMode);
  };

  const handleEditTitleCancel = () => {
    cancelEdit();
    setEditTitleMode(!editTitleMode);
  };

  const handleEditOrderCancel = () => {
    cancelEdit();
    setEditOrderMode(!editOrderMode);
  };

  return (
    <BlockHeaderAdminSC>
      <BlockHeaderAdminTitle>{blockTitle}</BlockHeaderAdminTitle>
      <Buttons>
        {!editTitleMode && !editOrderMode && (
          <>
            <EditModeBtn
              className={editTitleMode ? "" : "cancel"}
              onClick={handleStartEditTitle}
            >
              <EditIcon className="name" src={editName} alt="editModePen" />
              <LabelSC>名称編集</LabelSC>
            </EditModeBtn>
            <EditModeBtn
              className={editOrderMode ? "" : "cancel"}
              onClick={handleStartEditOrder}
            >
              <EditIcon className="order" src={editOrder} alt="editModePen" />
              <LabelSC>順番編集</LabelSC>
            </EditModeBtn>
          </>
        )}
        {editTitleMode && !editOrderMode && (
          <>
            <EditModeBtn
              className={editTitleMode ? "" : "cancel"}
              onClick={handleEditTitleCancel}
            >
              <EditIcon src={editmodeCancel} alt="editModeCancel" />
              <LabelSC>キャンセル</LabelSC>
            </EditModeBtn>
          </>
        )}
        {!editTitleMode && editOrderMode && (
          <>
            <EditModeBtn
              className={editTitleMode ? "" : "cancel"}
              onClick={handleEditOrderCancel}
            >
              <EditIcon src={editmodeCancel} alt="editModeCancel" />
              <LabelSC>キャンセル</LabelSC>
            </EditModeBtn>
          </>
        )}
      </Buttons>
    </BlockHeaderAdminSC>
  );
};

export default BlockHeaderAdmin;

const BlockHeaderAdminSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BlockHeaderAdminTitle = styled.h2`
  color: ${color.black};
  font-size: 16px;
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
`;

const EditIcon = styled.img`
  width: 14px;
  display: inline-block;
  position: relative;
  margin-right: 4px;
  position: relative;
  &.name {
    top: -1px;
  }
  &.order {
    margin-right: 5px;
    width: 13px;
    top: 1px;
  }
`;

const LabelSC = styled.span`
  display: inline-block;
`;

const EditModeBtn = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  height: auto;
  text-align: center;
  position: relative;
  margin-left: 20px;
  &.cancel {
    &::before {
      background-image: url() (${editmodeCancel});
    }
  }
  &:hover {
    opacity: 0.6;
  }
`;
