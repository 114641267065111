import {
  GET_MEDIA_FILES_MINE_REQUESTED,
  GET_MEDIA_FILES_ADMIN_REQUESTED,
  GET_MEDIA_FILES_SHARE_REQUESTED,
  SEARCH_MEDIA_FILES_REQUESTED,
  TOGGLE_MEDIA_FILES_VIEW_MODE,
  TOGGLE_MEDIA_FILES_SELECT_MODE,
  SET_SELECTED_MEDIA_IDS,
  UPDATE_SELECTED_MEDIA_FILES_REQUESTED,
  TOGGLE_IMG_LOADING_STATE,
  UPLOAD_MEDIA_FILE_REQUESTED,
  RESET_SELECTED_MEDIA_FILE_IDS,
  DELETE_MEDIA_FILE_REQUESTED,
  SET_SEARCH_PARAMS,
  SET_ALBUM_VIEW_TYPE,
  RESET_SEARCH_PARAMS
} from "./type";
import { SearchParams } from "./reducer";
import { AlbumViewType } from "components/Camera/type";

export interface SearchMediaFilesQueryType {
  [key: string]: number | string | boolean | number[] | undefined;
}

export const getMediaFilesMineRequested = (token: string) => {
  return {
    type: GET_MEDIA_FILES_MINE_REQUESTED,
    token
  };
};
export const getMediaFilesShareRequested = (token: string) => {
  return {
    type: GET_MEDIA_FILES_SHARE_REQUESTED,
    token
  };
};
export const getMediaFilesAdminRequested = (token: string) => {
  return {
    type: GET_MEDIA_FILES_ADMIN_REQUESTED,
    token
  };
};
export const uploadMediaFileRequested = (
  token: string,
  param: {
    encoded_file: string;
    async: boolean;
  }
) => {
  return {
    type: UPLOAD_MEDIA_FILE_REQUESTED,
    token,
    param
  };
};

export const searchMediaFilesRequested = (
  token: string,
  view: AlbumViewType,
  params: SearchParams
) => {
  return {
    type: SEARCH_MEDIA_FILES_REQUESTED,
    token,
    view,
    params
  };
};

export const toggleMediaFileViewMode = (mode: "thumbnail" | "list") => {
  return {
    type: TOGGLE_MEDIA_FILES_VIEW_MODE,
    mode
  };
};

export const toggleMediaFileSelectMode = () => {
  return {
    type: TOGGLE_MEDIA_FILES_SELECT_MODE
  };
};

export const setSelectedMediaIds = (targetMediaId: number) => {
  return {
    type: SET_SELECTED_MEDIA_IDS,
    targetMediaId
  };
};

export const updateSelectedMediaFilesRequested = (
  token: string,
  payload: {
    fileIds: number[];
    selectedFacilityIds?: number[] | null;
    selectedClassIds?: number[] | null;
    selectedTagIds?: number[] | null;
    isAdmin?: boolean;
  }
) => {
  return {
    type: UPDATE_SELECTED_MEDIA_FILES_REQUESTED,
    token,
    payload
  };
};

export const deleteMediaFileRequested = (token: string, id: number) => {
  return {
    type: DELETE_MEDIA_FILE_REQUESTED,
    token,
    id
  };
};

export const toggleImgLoadingState = (isLoading: boolean) => {
  return {
    type: TOGGLE_IMG_LOADING_STATE,
    isLoading
  };
};

export const resetSelectedMediaIds = () => {
  return {
    type: RESET_SELECTED_MEDIA_FILE_IDS
  };
};

export const setSearchParams = (target: keyof SearchParams, value: any) => {
  return {
    type: SET_SEARCH_PARAMS,
    target,
    value
  };
};

export const setAlbumViewType = (view: AlbumViewType) => {
  return {
    type: SET_ALBUM_VIEW_TYPE,
    view
  };
};

export const resetSearchParams = () => {
  return {
    type: RESET_SEARCH_PARAMS
  };
};
