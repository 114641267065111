import { toggleSearchAccountIsOpened } from "../../session/action";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_FACILITY_ID,
  TOGGLE_TO_BE_APPROVED,
  TOGGLE_TO_BE_APPROVED_ALL,
  TOGGLE_SELECTED_OPTIONS,
  TOGGLE_TO_BE_REJECTED,
  TOGGLE_TO_BE_REJECTED_ALL,
  SET_REJECT_COMMENT,
  SET_REJECT_COMMENT_FOR_ALL,
  ADD_TO_BE_APPROVED,
  RESET_TO_BE_APPROVED,
  RESET_SELECTED_OPTOIONS,
  RESET_SELECTED_MONTH,
  POST_REJECT_SUCCEEDED,
  POST_REJECT_FAILED,
  GET_FACILITIES_APPROVALS_STATUS_SUCCEEDED,
  GET_FACILITIES_APPROVALS_STATUS_FAILED,
  GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
  GET_MONTHLY_APPROVAL_STATUS_FAILED,
  ADD_TO_BE_DELETE_REJECT,
  SET_SELECTED_MONTH_TO_APPROVAL,
  RESET_TO_BE_REJECTED,
  RESET_REJECT_COMMENT,
  SET_ALREADY_APPROVED_MONTH,
  SET_MONTH_TO_CANCEL_ALL,
} from "./type";

const initialState = {
  facilities_status: [],
  monthly_status: [],
  to_be_approved: [],
  to_be_deleted_reject: {
    id: null,
    date_to_show: "",
    date: "",
    title: "",
    type_id: null,
    content: "",
    detail: "",
    class_age: "",
    remand: [],
    editing_status: null,
    approval_statuses: [],
  },
  to_be_rejected: [],
  monthly_select_options: [],
  selected_options: [],
  selected_month: null,
  rejection_comment_for_all: "",
  already_approved_month: [],
  month_to_be_canceled_all: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_TO_BE_APPROVED:
      return {
        ...state,
        to_be_approved: action.to_be_approved_array,
      };

    case TOGGLE_TO_BE_APPROVED:
      //既に同じ月が存在しているかチェックして、存在していればそれを取り除くようにfilterをかける
      const checkSameMonth = state.to_be_approved.some(
        (item: any) => item === action.month
      );
      if (checkSameMonth) {
        return {
          ...state,
          to_be_approved: state.to_be_approved.filter(
            (item: any) => item !== action.month
          ),
        };
      } else {
        //存在していなければ、今あるstateにオブジェクトを追加する。
        return {
          ...state,
          to_be_approved: [...state.to_be_approved, action.month],
        };
      }

    case TOGGLE_TO_BE_APPROVED_ALL:
      //もし全て選択されている状態なら配列を空っぽにする
      if (state.to_be_approved.length === action.monthly_list.length) {
        return {
          ...state,
          to_be_approved: [],
        };
      } else {
        return {
          ...state,
          to_be_approved: action.monthly_list.map((item: any) => {
            return item.month;
          }),
        };
      }

    case TOGGLE_SELECTED_OPTIONS:
      return {
        ...state,
        selected_options: action.selected_options,
      };

    case RESET_TO_BE_APPROVED:
      return {
        ...state,
        to_be_approved: [],
      };

    case RESET_SELECTED_OPTOIONS:
      return {
        ...state,
        selected_options: [],
      };

    case RESET_SELECTED_MONTH:
      return {
        ...state,
        selected_month: null,
      };

    case GET_FACILITIES_APPROVALS_STATUS_SUCCEEDED:
      console.log("action", action);
      return {
        ...state,
        facilities_status: action.result,
      };

    case GET_MONTHLY_APPROVAL_STATUS_FAILED:
      console.log("action", action);
      return {
        ...state,
      };

    case GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED:
      console.log("action", action);
      const month_array = action.result.months;
      return {
        ...state,
        monthly_status: [...month_array],
        monthly_select_options: month_array.map((item: any) => {
          return {
            value: item.month,
            label: new Date(item.month).getMonth() + 1 + "月",
          };
        }),
      };

    case TOGGLE_TO_BE_REJECTED:
      if (
        state.to_be_rejected.length === 0 ||
        state.to_be_rejected.some((item: any) => item.id !== action.id)
      ) {
        return {
          ...state,
          to_be_rejected: [
            {
              id: action.id,
              date: action.date,
              event_type: action.event_type,
              event_name: action.event_name,
              event_detail: action.event_detail,
              comment: "",
            },
          ],
        };
      } else {
        return {
          ...state,
          to_be_rejected: [],
        };
      }

    case TOGGLE_TO_BE_REJECTED_ALL:
      if (state.to_be_rejected.length !== 0) {
        return {
          ...state,
          to_be_rejected: [],
        };
      } else {
        return {
          ...state,
          to_be_rejected: action.event_list.map((item: any) => {
            return {
              id: item.id,
            };
          }),
        };
      }

    case RESET_TO_BE_REJECTED:
      return {
        ...state,
        to_be_rejected: [],
      };

    case SET_REJECT_COMMENT:
      return {
        ...state,
        to_be_rejected: state.to_be_rejected.map((item: any) => {
          if (item.id === action.id) {
            return {
              ...item,
              comment: action.comment,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      };

    case SET_REJECT_COMMENT_FOR_ALL:
      return {
        ...state,
        rejection_comment_for_all: action.updated_comment,
        to_be_rejected: state.to_be_rejected.map((item: any) => {
          return {
            ...item,
            comment: action.updated_comment,
          };
        }),
      };

    case RESET_REJECT_COMMENT:
      return {
        ...state,
        rejection_comment_for_all: "",
      };

    case POST_REJECT_SUCCEEDED:
      console.log("succeeded!");
      return {
        ...state,
        to_be_rejected: [],
      };

    case POST_REJECT_FAILED:
      console.log("failed");
      console.log("message: ", action.message);
      return state;

    case ADD_TO_BE_DELETE_REJECT:
      return {
        ...state,
        to_be_deleted_reject: action.daily_event,
      };

    case SET_SELECTED_MONTH_TO_APPROVAL:
      return {
        ...state,
        selected_month: action.selected_month,
      };

    case SET_ALREADY_APPROVED_MONTH:
      return {
        ...state,
        already_approved_month: action.already_approved_month,
      };

    case SET_MONTH_TO_CANCEL_ALL:
      const updated_month_to_be_canceled_all =
        state.month_to_be_canceled_all === action.month_to_be_canceled_all
          ? null
          : action.month_to_be_canceled_all;
      return {
        ...state,
        month_to_be_canceled_all: updated_month_to_be_canceled_all,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
