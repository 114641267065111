import React, { useCallback } from "react";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import styled from "styled-components";
import { color } from "../../mixins";
import { useDropzone } from "react-dropzone";
import editmode from "images/icons/editmode.svg";
import editmodeCancel from "images/icons/editmode_cancel.svg";
interface HeroEditContainerProps {
  hookedManual: ManualItemForSelector;
  setHookedManual: (ManualItemForSelector: ManualItemForSelector) => void;
  editImageMode: boolean;
  setEditImageMode: (editImageMode: boolean) => void;
  cancelEditImage: () => void;
  setImageToUpload: (file: any) => void;
}

const HeroEditContainer: React.FC<HeroEditContainerProps> = ({
  hookedManual,
  setHookedManual,
  editImageMode,
  setEditImageMode,
  cancelEditImage,
  setImageToUpload
}) => {
  const handleStartEditImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEditImageMode(!editImageMode);
    e.stopPropagation();
  };

  const handleEditImageCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    cancelEditImage();
    setEditImageMode(!editImageMode);
    setImageToUpload(null);
    e.stopPropagation();
  };

  const handleCatchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHookedManual({
      ...hookedManual,
      catch: e.target.value
    });
    e.stopPropagation();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHookedManual({
      ...hookedManual,
      name: e.target.value
    });
    e.stopPropagation();
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // ファイルデータアップロード用
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log("reader.result", binaryStr);
        console.log("acceptedFiles[0]", acceptedFiles[0]);
        // setImageToUpload(binaryStr);
        setImageToUpload(acceptedFiles[0]);
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);

      // 画面描画用
      const createObjectURL = (window.URL || window.webkitURL).createObjectURL;
      console.log(
        "createObjectURL(acceptedFiles[0]",
        createObjectURL(acceptedFiles[0])
      );
      // Manualコンポーネントステートの画像を更新
      if (acceptedFiles.length !== 0) {
        setHookedManual({
          ...hookedManual,
          image: createObjectURL(acceptedFiles[0])
        });
      }
    },
    [hookedManual, setHookedManual, setImageToUpload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const stopProp = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };
  return (
    <>
      {editImageMode ? (
        <ContainerSC
          style={{ backgroundImage: `url(${hookedManual.image || ""})` }}
          {...getRootProps()}
        >
          <EditModeBtn
            className={editImageMode ? "" : "cancel"}
            onClick={handleEditImageCancel}
          >
            <img src={editmodeCancel} alt="editModeCancel" />
            <span>キャンセル</span>
          </EditModeBtn>
          <input {...getInputProps()} />
          {isDragActive ? (
            <FileNote>ここにドロップ</FileNote>
          ) : (
            <FileNote>
              ファイルをドラッグ・アンド・ドロップまたはクリック
            </FileNote>
          )}
          <ContentsSC>
            <WrapperSC>
              <CatchInputSC
                id="manual_catch_input"
                type="text"
                value={hookedManual.catch || ""}
                onChange={handleCatchChange}
                onClick={stopProp}
              />
              <NameInputSC
                id="manual_name_input"
                type="text"
                value={hookedManual.name}
                onChange={handleNameChange}
                onClick={stopProp}
              />
            </WrapperSC>
          </ContentsSC>
        </ContainerSC>
      ) : (
        <ContainerSC
          style={{ backgroundImage: `url(${hookedManual.image || ""})` }}
        >
          <EditModeBtn
            className={editImageMode ? "" : "cancel"}
            onClick={handleStartEditImage}
          >
            <img src={editmode} alt="editModePen" />
            <span>編集</span>
          </EditModeBtn>

          <ContentsSC>
            <WrapperSC>
              <CatchSC>{hookedManual.catch || ""}</CatchSC>
              <GuideSC>{hookedManual.name || ""}</GuideSC>
            </WrapperSC>
          </ContentsSC>
        </ContainerSC>
      )}
    </>
  );
};

export default HeroEditContainer;

// =========================================================
// styles
// =========================================================
const ContainerSC = styled.div`
  max-width: 100%;
  height: clamp(535px, calc(700 / 1920 * 100vw), 700px);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: relative;
  margin-bottom: 30px;
  &::before {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    border-radius: 8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
  }
`;

const ContentsSC = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  padding-top: 24px;
`;

const WrapperSC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const CatchSC = styled.h2`
  font-size: 48px;
  color: ${color.white};
  margin-bottom: 120px;
  margin-top: 0;
`;

const CatchInputSC = styled.input`
  display: block;
  width: 90%;
  margin: 0 auto;
  font-size: 48px;
  color: ${color.white};
  margin-bottom: 120px;
  margin-top: 0;
  border: 1px solid black;
  padding: 4px 8px;
`;

const GuideSC = styled.p`
  font-size: 24px;
  color: ${color.white};
  font-weight: bold;
`;

const NameInputSC = styled.input`
  display: block;
  margin: 0 auto;
  width: 90%;
  font-size: 24px;
  color: ${color.white};
  font-weight: bold;
  border: 1px solid black;
  padding: 4px 8px;
`;

const EditModeBtn = styled.button`
  background: none;
  border: none;
  height: auto;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 20px;
  padding-left: 30px;
  z-index: 1;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-image: url(${editmode});
    background-size: contain;
    background-position: center;
  }

  &.cancel {
    &::before {
      background-image: url() (${editmodeCancel});
    }
  }
`;

const FileNote = styled.span`
  position: absolute;
  top: 30px;
  left: 20px;
`;
