const SET_ACCOUNT_NEW_NAME = "SET_ACCOUNT_NEW_NAME";
const SET_ACCOUNT_NEW_POSITION_ID = "SET_ACCOUNT_NEW_POSITION_ID";
const SET_ACCOUNT_NEW_EMAIL = "SET_ACCOUNT_NEW_EMAIL";
const SET_ACCOUNT_NEW_PASSWORD = "SET_ACCOUNT_NEW_PASSWORD";
const SET_ACCOUNT_NEW_FACILITIES = "SET_ACCOUNT_NEW_FACILITIES"

const POST_ACCOUNT_NEW_REQUESTED = "POST_ACCOUNT_NEW_REQUESTED";
const POST_ACCOUNT_NEW_SUCCEEDED = "POST_ACCOUNT_NEW_SUCCEEDED";
const POST_ACCOUNT_NEW_FAILED = "POST_ACCOUNT_NEW_FAILED";

const TOGGLE_ACCOUNT_NEW_POST_STATUS = "TOGGLE_ACCOUNT_NEW_POST_STATUS";

export {

  SET_ACCOUNT_NEW_NAME,
  SET_ACCOUNT_NEW_POSITION_ID,
  SET_ACCOUNT_NEW_EMAIL,
  SET_ACCOUNT_NEW_PASSWORD,
  SET_ACCOUNT_NEW_FACILITIES,

  POST_ACCOUNT_NEW_REQUESTED,
  POST_ACCOUNT_NEW_SUCCEEDED,
  POST_ACCOUNT_NEW_FAILED,

  TOGGLE_ACCOUNT_NEW_POST_STATUS

}
