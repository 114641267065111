import React from "react";
import { useDispatch, useSelector } from "react-redux";
import color from "../mixins/color";
import Select from "react-select";
import { EventType } from "./type";
import { setTypeIds } from "store/event/event_search/action";

export function SearchEventTypeSelect() {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState<Value[]>([]);
  const type_of_event = useSelector((state: any) => state.events.type_of_event);
  const typeOptions = type_of_event.map((type: EventType) => {
    return {
      value: type.id.toString(),
      label: type.name
    };
  });
  //SelectBoxのスタイリング
  // ...providedで既存スタイルを展開して、追加や上書きを行う
  const customStyles = {
    container: () => ({
      width: "100%"
    }),
    // 選択リストのスタイリング
    menu: (provided: any) => ({
      ...provided,
      top: "auto",
      maxWidth: 280
    }),
    // 選択リスト内アイテムのスタイリング
    option: (provided: any, { isFocused }: any) => ({
      ...provided,
      backgroundColor: isFocused && "rgba(41, 137, 72, 0.1)",
      cursor: "pointer"
    }),
    //セレクト表示エリアのスタイリング
    control: (provided: any) => ({
      ...provided,
      ":hover": {
        cursor: "pointer"
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "12px"
    })
  };

  type Value = {
    value: string;
    label: string;
  };
  const handleSelectType = (new_value: Value[]) => {
    console.log(new_value);
    setSelected(new_value);
    dispatch(setTypeIds(new_value));
  };

  return (
    <Select
      placeholder="行事名を選択"
      className="react-select-container"
      classNamePrefix="react-select"
      value={selected}
      onChange={(new_value: any) => {
        handleSelectType(new_value);
      }}
      closeMenuOnSelect={false} //一つ選択してもリストが閉じないようにする
      isMulti //複数選択できるようにする
      styles={customStyles} //定義したスタイルを読み込む
      options={typeOptions} //セレクトするアイテムを読み込む（配列）
      theme={(theme: any) => ({
        //テーマカラーを上書きする
        ...theme,
        colors: {
          ...theme.colors,
          primary25: `${color.primary}`,
          primary: `${color.tag_color}`
        }
      })}
    />
  );
}
