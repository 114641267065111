import React, { ReactNode } from "react";
import { TabAreaContainerSC, TabAreaInputSC, TabAreaLabelSC } from "./style";

interface ITabSwitch {
  active?: boolean;
  setIsShown?: () => void;
  id: string;
  name: string;
  label: string;
  show: () => void;
}

interface ITabContainer {
  children: ReactNode;
}

export const TabSwitch = (props: ITabSwitch) => {
  const { active, id, name, show, label } = props;
  return (
    <>
      <TabAreaInputSC
        type="radio"
        id={id}
        name={name}
        checked={active}
        onClick={show}
      />
      <TabAreaLabelSC htmlFor={id}>{label}</TabAreaLabelSC>
    </>
  );
};

export const TabContainer = (props: ITabContainer) => {
  const { children } = props;
  return <TabAreaContainerSC>{children}</TabAreaContainerSC>;
};
