import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import icon from "../../../images/icons/header_icon.svg";
import {
  clearSystemCache,
  endSessionRequest,
  toggleCacheCleared
} from "../../../store/session/action";
import {
  inquiry,
  inquiry_history,
  inquiry_history_current,
  inquiry_form,
  inquiry_form_current,
  faq,
  faq_list,
  faq_list_current,
  faq_register,
  faq_register_current,
  news,
  news_list,
  news_list_current,
  news_register,
  news_register_current,
  master,
  master_setting,
  master_setting_current,
  account,
  account_list,
  account_list_current,
  account_register,
  account_register_current,
  up,
  down,
  sidebar_close,
  sidebar_open,
  manual,
  manual_current
} from "../../Sidebar/sidebarIcons";
import {
  toggleSidebar,
  toggleFaq,
  toggleNews,
  toggleMaster,
  toggleAccount,
  toggleInquiry
} from "../../../store/common/sidebar/action";
import { postArticleRequest } from "../../../store/faq/article_new/action";
import { postNewsRequest } from "../../../store/global/news_new/action";
import { toggleHamburgerMenu } from "../../../store/common/hamburger/action";

function HeaderAdminSP() {
  const gsuite_id = useSelector((state: any) => state.session.gsuite_id);
  const picture = useSelector((state: any) => state.session.picture);

  const token = useSelector((state: any) => state.session.token);
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const isLoggedIn = useSelector((state: any) => state.session.isLoggedIn);
  const name = useSelector((state: any) => state.session.name);
  const isOpen = useSelector((state: any) => state.sidebar.sidebarIsOpen);
  const inquiryIsOpen = useSelector(
    (state: any) => state.sidebar.inquiryIsOpen
  );
  const position_id = useSelector((state: any) => state.session.position.id);
  // =====================================================================================
  // article_new =========================================================================
  const new_article_id = useSelector(
    (state: any) => state.article_new.new_article_id
  );
  const title = useSelector((state: any) => state.article_new.title);
  const content = useSelector((state: any) => state.article_new.content);
  // const search_string = useSelector((state: any) => state.article_new.search_string);
  const search_string = "test search string";
  const status = useSelector((state: any) => state.article_new.status);
  const categories = useSelector((state: any) => state.article_new.categories);
  const tags = useSelector((state: any) => state.article_new.tags);
  // =====================================================================================

  // =====================================================================================
  // news_new ============================================================================
  const new_news_id = useSelector((state: any) => state.news_new.new_news_id);
  const new_news_title = useSelector((state: any) => state.news_new.title);
  const new_news_content = useSelector((state: any) => state.news_new.content);
  const new_news_status = useSelector((state: any) => state.news_new.status);
  // =====================================================================================

  // const isOpen = useSelector((state: any) => state.sidebar.sidebarIsOpen)
  const faqIsOpen = useSelector((state: any) => state.sidebar.faqIsOpen);
  const newsIsOpen = useSelector((state: any) => state.sidebar.newsIsOpen);
  const masterIsOpen = useSelector((state: any) => state.sidebar.masterIsOpen);
  const accountIsOpen = useSelector(
    (state: any) => state.sidebar.accountIsOpen
  );

  // ハンバーガーメニューの開閉状態
  const hamburger_open = useSelector((state: any) => state.hamburger.open);

  const handleClick = () => {
    dispatch(endSessionRequest(token));
    dispatch(toggleHamburgerMenu(!hamburger_open));
  };

  const handleSystemClearClick = () => {
    dispatch(clearSystemCache());
    dispatch(toggleCacheCleared(true));
    setInterval(() => {
      dispatch(toggleCacheCleared(false));
      window.location.reload(true);
    }, 2000);
  };

  return (
    <MenuSC>
      <MenuContainerSC>
        <User>
          <UserIconSC
            src={gsuite_id !== null && picture !== null ? picture : icon}
            alt="icon"
          />
          <UserNameSC>{name}</UserNameSC>
        </User>
        <div>
          {/* メニュー */}
          <MenuList>
            <MenuItem>
              <MenuUser>メニュー</MenuUser>
            </MenuItem>

            {/* FAQ */}
            <MenuItem
              className={pathName === "/" ? "active" : ""}
              style={{ height: "50px" }}
            >
              <MenuTitle
                to="/"
                onClick={() => dispatch(toggleHamburgerMenu(!hamburger_open))}
              >
                FAQ
              </MenuTitle>
            </MenuItem>
            {position_id === 3 || position_id === 2 ? (
              <MenuItem
                className={pathName === "/manual" ? "active" : ""}
                style={{ height: "50px" }}
              >
                <MenuTitle
                  to="/manual"
                  className="manual"
                  onClick={() => dispatch(toggleHamburgerMenu(!hamburger_open))}
                >
                  マニュアル
                </MenuTitle>
              </MenuItem>
            ) : (
              <></>
            )}

            {/* お知らせ */}
            <MenuItem
              className={pathName === "/news" ? "active" : ""}
              style={{ height: "50px" }}
            >
              <MenuTitle
                to="/news"
                className="news_list"
                onClick={() => dispatch(toggleHamburgerMenu(!hamburger_open))}
              >
                お知らせ
              </MenuTitle>
            </MenuItem>

            {/* お問合せ */}
            <MenuItem className={inquiryIsOpen ? "active" : ""}>
              <MenuLabel
                className="inquiry"
                onClick={() => dispatch(toggleInquiry())}
              >
                お問合せ
                {isOpen && inquiryIsOpen ? (
                  <Arrow src={up} alt="up" />
                ) : isOpen && !inquiryIsOpen ? (
                  <Arrow src={down} alt="down" />
                ) : (
                  ""
                )}
              </MenuLabel>
              {inquiryIsOpen ? (
                <ul>
                  <FoldingItem className="inquiries_list">
                    <Link
                      to="/inquiries"
                      onClick={() =>
                        dispatch(toggleHamburgerMenu(!hamburger_open))
                      }
                    >
                      お問合せ履歴
                    </Link>
                  </FoldingItem>
                  <FoldingItem className="inquiries_new">
                    <Link
                      to="/inquiries/new"
                      onClick={() =>
                        dispatch(toggleHamburgerMenu(!hamburger_open))
                      }
                    >
                      お問合せフォーム
                    </Link>
                  </FoldingItem>
                </ul>
              ) : (
                ""
              )}
            </MenuItem>

            {/* FAQ */}
            {
              // 一般編集者(position_id === 1),
              // 本部所属(position_id === 2),
              // 管理者(position_id === 3)なら表示
              (position_id === 1 || position_id === 2 || position_id === 3) && (
                <MenuItem className={faqIsOpen ? "active" : ""}>
                  <MenuLabel
                    className="faq"
                    onClick={() => dispatch(toggleFaq())}
                  >
                    FAQ管理
                    {isOpen && faqIsOpen ? (
                      <Arrow src={up} alt="up" />
                    ) : isOpen && !faqIsOpen ? (
                      <Arrow src={down} alt="down" />
                    ) : (
                      ""
                    )}
                  </MenuLabel>
                  {faqIsOpen ? (
                    <ul>
                      <FoldingItem className="faq_list">
                        <Link
                          to="/articles"
                          onClick={() =>
                            dispatch(toggleHamburgerMenu(!hamburger_open))
                          }
                        >
                          FAQ記事一覧
                        </Link>
                      </FoldingItem>
                      <FoldingItem className="faq_register">
                        <Link
                          to="/articles/new"
                          onClick={() => {
                            dispatch(
                              postArticleRequest(
                                token,
                                title,
                                content,
                                search_string,
                                status,
                                categories,
                                tags
                              )
                            );
                            dispatch(toggleHamburgerMenu(!hamburger_open));
                          }}
                        >
                          FAQ記事登録
                        </Link>
                      </FoldingItem>
                    </ul>
                  ) : (
                    ""
                  )}
                </MenuItem>
              )
            }
            {/* お知らせ */}
            {(position_id === 2 || position_id === 3) && (
              <MenuItem className={newsIsOpen ? "active" : ""}>
                <MenuLabel
                  className="news"
                  onClick={() => dispatch(toggleNews())}
                >
                  お知らせ管理
                  {isOpen && newsIsOpen ? (
                    <Arrow src={up} alt="up" />
                  ) : isOpen && !newsIsOpen ? (
                    <Arrow src={down} alt="down" />
                  ) : (
                    ""
                  )}
                </MenuLabel>
                {newsIsOpen ? (
                  <ul>
                    <FoldingItem className="news_list">
                      <Link
                        to="/news-edit-list"
                        onClick={() =>
                          dispatch(toggleHamburgerMenu(!hamburger_open))
                        }
                      >
                        お知らせ一覧
                      </Link>
                    </FoldingItem>
                    <FoldingItem className="news_register">
                      <Link
                        to="/news/new"
                        onClick={() => {
                          dispatch(
                            postNewsRequest(
                              token,
                              new_news_title,
                              new_news_content,
                              new_news_status
                            )
                          );
                          dispatch(toggleHamburgerMenu(!hamburger_open));
                        }}
                      >
                        お知らせ登録
                      </Link>
                    </FoldingItem>
                  </ul>
                ) : (
                  ""
                )}
              </MenuItem>
            )}

            {/* マスタ */}
            {position_id === 3 && (
              <MenuItem className={masterIsOpen ? "active" : ""}>
                <MenuLabel
                  className="master"
                  onClick={() => dispatch(toggleMaster())}
                >
                  マスタ管理
                  {isOpen && masterIsOpen ? (
                    <Arrow src={up} alt="up" />
                  ) : isOpen && !masterIsOpen ? (
                    <Arrow src={down} alt="down" />
                  ) : (
                    ""
                  )}
                </MenuLabel>
                {masterIsOpen ? (
                  <ul>
                    <FoldingItem className="category">
                      <Link
                        to="/master/categories"
                        onClick={() =>
                          dispatch(toggleHamburgerMenu(!hamburger_open))
                        }
                      >
                        カテゴリ設定
                      </Link>
                    </FoldingItem>
                    <FoldingItem className="tag">
                      <Link
                        to="/master/tags"
                        onClick={() =>
                          dispatch(toggleHamburgerMenu(!hamburger_open))
                        }
                      >
                        タグ設定
                      </Link>
                    </FoldingItem>
                  </ul>
                ) : (
                  ""
                )}
              </MenuItem>
            )}

            {/* アカウント */}
            {position_id === 3 && (
              <MenuItem className={accountIsOpen ? "active" : ""}>
                <MenuLabel
                  className="account"
                  onClick={() => dispatch(toggleAccount())}
                >
                  アカウント管理
                  {isOpen && accountIsOpen ? (
                    <Arrow src={up} alt="up" />
                  ) : isOpen && !accountIsOpen ? (
                    <Arrow src={down} alt="down" />
                  ) : (
                    ""
                  )}
                </MenuLabel>
                {accountIsOpen ? (
                  <ul>
                    <FoldingItem className="account_list">
                      <Link
                        to="/accounts"
                        onClick={() =>
                          dispatch(toggleHamburgerMenu(!hamburger_open))
                        }
                      >
                        アカウント一覧
                      </Link>
                    </FoldingItem>
                    <FoldingItem className="account_register">
                      <Link
                        to="/accounts/new"
                        onClick={() =>
                          dispatch(toggleHamburgerMenu(!hamburger_open))
                        }
                      >
                        アカウント登録
                      </Link>
                    </FoldingItem>
                  </ul>
                ) : (
                  ""
                )}
              </MenuItem>
            )}
          </MenuList>

          {/* アカウント */}
          <MenuList>
            <MenuItem>
              <MenuUser>アカウント</MenuUser>
            </MenuItem>

            <MenuItem>
              <MenuItemSC onClick={handleClick}>ログアウト</MenuItemSC>
            </MenuItem>

            <MenuItem>
              <MenuItemSC onClick={handleSystemClearClick}>
                システム更新
              </MenuItemSC>
            </MenuItem>
          </MenuList>
        </div>
      </MenuContainerSC>
    </MenuSC>
  );
}

export default HeaderAdminSP;

// ===================================================================================
// スタイル
// ===================================================================================
const MenuSC = styled.div`
  position: fixed;
  bottom: 0;
  right: -372px;
  z-index: 100;
  max-width: 372px;
  width: 80%;
  height: calc(100% - 56px);
  background: ${color.primary};
  display: block;
  transition: all 0.5s;
  overflow: scroll;
  ${pc`
    display: none;
  `}
`;

const MenuContainerSC = styled.div`
  padding: 30px 20px;
`;

const User = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
`;

const UserIconSC = styled.img`
  max-width: 40px;
  width: 100%;
  display: inline-block;
  margin-right: 10px;
  border-radius: 20px;
`;

const UserNameSC = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${color.white};
`;

const MenuList = styled.ul`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const MenuItem = styled.li`
  height: auto;
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background-color: ${color.primary_light};
  }
`;

const MenuUser = styled.p`
  color: ${color.white};
  padding: 0 0 6px;
  font-size: 12px;
  cursor: default;
  white-space: nowrap;
`;

const FoldingItem = styled.li`
  height: 50px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  &:hover {
    background-color: ${color.primary_white};
    color: ${color.primary};
  }
  &.faq_list {
    background-image: url(${faq_list});
    &:hover,
    &.current {
      background-image: url(${faq_list_current});
    }
  }
  &.faq_register {
    background-image: url(${faq_register});
    &:hover,
    &.current {
      background-image: url(${faq_register_current});
    }
  }
  &.news_list {
    background-image: url(${news_list});
    &:hover,
    &.current {
      background-image: url(${news_list_current});
    }
  }
  &.news_register {
    background-image: url(${news_register});
    &:hover,
    &.current {
      background-image: url(${news_register_current});
    }
  }
  &.inquiries_list {
    background-image: url(${inquiry_history});
    &:hover,
    &.current {
      background-image: url(${inquiry_history_current});
    }
  }
  &.inquiries_new {
    background-image: url(${inquiry_form});
    &:hover,
    &.current {
      background-image: url(${inquiry_form_current});
    }
  }
  &.tag {
    background-image: url(${master_setting});
    &:hover,
    &.current {
      background-image: url(${master_setting_current});
    }
  }
  &.category {
    background-image: url(${master_setting});
    &:hover,
    &.current {
      background-image: url(${master_setting_current});
    }
  }
  &.account_list {
    background-image: url(${account_list});
    &:hover,
    &.current {
      background-image: url(${account_list_current});
    }
  }
  &.manual {
    background-image: url(${manual});
    &:hover,
    &.current {
      background-image: url(${manual_current});
    }
  }
  &.account_register {
    background-image: url(${account_register});
    &:hover,
    &.current {
      background-image: url(${account_register_current});
    }
  }
  a,
  button {
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    padding: 18px 0 18px 50px;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
    a {
      border-radius: 0 0 4px 4px;
    }
  }
`;

const MenuTitle = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  background-image: url(${faq});
  border-radius: 4px;
  box-sizing: border-box;
  &:hover {
    background-color: ${color.primary_light};
  }
  &.manual {
    background-image: url(${manual});
  }
  &.news_list {
    background-image: url(${news});
  }
`;

const MenuLabel = styled.p`
  position: relative;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  white-space: nowrap;
  &.faq {
    background-image: url(${faq});
  }
  &.manual {
    background-image: url(${manual});
  }
  &.news {
    background-image: url(${news});
  }
  &.master {
    background-image: url(${master});
  }
  &.account {
    background-image: url(${account});
  }
  &.inquiry {
    background-image: url(${inquiry});
  }
  &:hover {
    background-color: ${color.primary_light};
  }
`;

const Arrow = styled.img`
  position: absolute;
  right: 16px;
  top: 23px;
`;

const MenuItemSC = styled.div`
  border-top: 1px solid ${color.primary_light};
  border-bottom: 1px solid ${color.primary_light};
  padding: 18px 0;
`;
