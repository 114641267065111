import React from "react";
import { Page } from "store/manual/manuals/pages/types.d";
import styled from "styled-components";
import { color } from "components/mixins";
import PagesEditableItem from "./PagesEditableItem";

interface Props {
  hookedPages: Page[];
  updatePageTitle: (data: {
    order: number;
    new_title: string;
    status: number;
  }) => void;
  handleAdd: (hookedPages: Page[]) => void;
  removeHookedItem: (data: { page: Page }) => void;
}

function PagesEditableList(props: Props) {
  const { hookedPages, updatePageTitle, handleAdd, removeHookedItem } = props;
  return (
    <>
      <PagesAdminListSC>
        {hookedPages.map((page: any, index: number) => {
          return (
            <PagesEditableItem
              key={index}
              page={page}
              updatePageTitle={updatePageTitle}
              removeHookedItem={removeHookedItem}
            />
          );
        })}
      </PagesAdminListSC>
      <AddButtonSC onClick={() => handleAdd(hookedPages)}>＋</AddButtonSC>
    </>
  );
}

export default PagesEditableList;

const PagesAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;

const AddButtonSC = styled.button`
  width: 33px;
  height: 33px;
  margin: 0 auto;
  line-height: 33px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid ${color.list_border};
  color: ${color.black};
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
  text-align: center;
  border-radius: 50%;
`;
