import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import DeleteEventConfirmModalContent from "./DeleteEventConfirmModalContent";

import { toggleDeleteEventModal } from "../../../../store/common/modal/action";

export default function FacilitySelectModal() {
  const dispatch = useDispatch();
  return (
    <div>
      <DeleteEventConfirmModalBg
        onClick={() => dispatch(toggleDeleteEventModal(false))}
      ></DeleteEventConfirmModalBg>
      <DeleteEventConfirmModalContent />
    </div>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const DeleteEventConfirmModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 120;
`;
