import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";

import { toggleDeleteEventModal } from "../../../../store/common/modal/action";

export default function DeleteEventButton() {
  const events_to_delete = useSelector(
    (state: any) => state.events.deleteItemArray
  );
  const monthly_item_to_delete_array = useSelector(
    (state: any) => state.monthly_event_delete.to_delete_array
  );

  const dispatch = useDispatch();
  return (
    <DeleteEventsSC>
      <ConfirmText>
        {events_to_delete.length + monthly_item_to_delete_array.length}
        件の行事予定が選択されています。削除しますか？
      </ConfirmText>
      <DeleteButton onClick={() => dispatch(toggleDeleteEventModal(true))}>
        削除
      </DeleteButton>
    </DeleteEventsSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const DeleteEventsSC = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: ${color.white};
  z-index: 110;
  bottom: 0;
  padding: 16px 0;
`;

const ConfirmText = styled.p`
  color: ${color.gray};
  font-size: 14px;
  margin-right: 20px;
  padding-top: 12px;
  display: block;
`;

const DeleteButton = styled.div`
  background-color: ${color.gray};
  color: ${color.white};
  font-size: 14px;
  line-height: 14px;
  padding: 12px 66px;
  height: 38px;
  border-radius: 4px;
  cursor: pointer;
`;
