import { color } from "components/mixins";
import React from "react";
import styled from "styled-components";

interface EventItemCheckboxProps {
  id: number;
  disabled: boolean;
  onClick: (event: React.MouseEvent) => void;
}

export const EventItemCheckbox: React.FC<EventItemCheckboxProps> = props => {
  const { id, disabled, onClick } = props;
  return (
    <>
      <EventsItemCheckbox
        id={String(id)}
        type="checkbox"
        name="events_item"
        onClick={onClick}
        disabled={disabled}
      />
      <EventsItemCheckboxLabel
        htmlFor={String(id)}
        className={disabled ? "disable" : ""}
        onClick={onClick}
      />
    </>
  );
};

const EventsItemCheckbox = styled.input`
  display: none;
  &:checked + label::before {
    background-color: ${color.gray};
  }
  &:checked + label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
  &:disabled {
    cursor: default;
  }
`;
const EventsItemCheckboxLabel = styled.label`
  padding-right: 39px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${color.gray};
    background-color: ${color.white};
  }
  &.hover {
    cursor: pointer;
  }
  &.disable {
    cursor: default;
  }
`;
