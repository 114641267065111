import { Button } from "components/Camera/components/Button";
import { TagItemList } from "components/Camera/components/TagItemList";
import {
  TagItemListUpdateButtonSC,
  TagItemListUpdateSC
} from "components/Camera/components/TagItemList/style";
import PageHeading from "components/Common/PageHeading";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToBeCreatedCameraTag,
  addToBeDeletedCameraTagIds,
  deleteCameraTagsRequested,
  getCameraTagsRequested,
  resetToBeDeletedCameraTags,
  setToBeUpdatedCameraTagData,
  setToBeCreatedCameraTagData,
  batchCameraTagsRequested
} from "store/camera/tags/action";
import { CameraTagsState } from "store/camera/tags/reducer";
import { RootState } from "store/store";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

export const CameraTagList = () => {
  const cameraTagState: CameraTagsState = useSelector(
    (state: RootState) => state.camera_tags
  );
  const {
    camera_tags,
    camera_tags_to_be_created,
    camera_tags_to_be_deleted_ids
  } = cameraTagState;
  const token = useSelector((state: RootState) => state.session.token);
  const dispatch = useDispatch();
  const handleAddToBeCreatedTag = () => {
    dispatch(addToBeCreatedCameraTag());
  };
  const handleAddTobeDeletedTagIds = (targetId: number | string) => {
    dispatch(addToBeDeletedCameraTagIds(targetId));
  };
  const handleUpdateTags = () => {
    dispatch(toggleImgLoadingState(true));
    dispatch(
      batchCameraTagsRequested(token, camera_tags, camera_tags_to_be_created)
    );
    if (camera_tags_to_be_deleted_ids.length > 0) {
      camera_tags_to_be_deleted_ids.forEach(id => {
        dispatch(deleteCameraTagsRequested(token, id));
      });
    }
  };
  const handleChangeTagInfo = (
    id: string | number,
    value: string,
    target: "name" | "order"
  ) => {
    if (typeof id === "number")
      dispatch(setToBeUpdatedCameraTagData(value, target, id));
    if (typeof id === "string")
      dispatch(setToBeCreatedCameraTagData(value, target, id));
  };

  useEffect(() => {
    dispatch(getCameraTagsRequested(token));
    dispatch(resetToBeDeletedCameraTags());
  }, [dispatch, token]);
  return (
    <>
      <div style={{ margin: "20px" }}>
        <PageHeading title="タグ管理画面" />
        <TagItemList
          tags={camera_tags}
          onClickDeleteTagBtn={handleAddTobeDeletedTagIds}
          onClickAddTagBtn={handleAddToBeCreatedTag}
          toBeCreatedTags={camera_tags_to_be_created}
          onChangeTagInfoForUpdate={handleChangeTagInfo}
          onChangeTagInfoForCreate={handleChangeTagInfo}
        />
      </div>
      <TagItemListUpdateSC>
        <TagItemListUpdateButtonSC>
          <Button label="更新" disabled={false} onClick={handleUpdateTags} />
        </TagItemListUpdateButtonSC>
      </TagItemListUpdateSC>
    </>
  );
};
