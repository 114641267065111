import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import { containerBaseStyle } from "../../mixins";
import SelectBox from "./ApprovalRequestMonthSelect";

import EventsList from "./EventsList";

export default function EventsListBlock() {
  const position_id = useSelector((state: any) => state.session.position.id);
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  return (
    <EventsListBlockSC className={rightSectionOpen ? "open" : "close"}>
      {
        //一般閲覧者(ID: 4), 一般編集者(id: 1)以外のアカウントではで承認申請月セレクトエリアを表示する
        position_id !== 4 && position_id !== 1 && <SelectBox />
      }
      <ListHeader>
        <ListHeaderItem style={{ padding: "0 17px" }}></ListHeaderItem>
        <ListHeaderItem className="date">日付</ListHeaderItem>
        <ListHeaderItem className="title">行事名</ListHeaderItem>
        <ListHeaderItem className="content">行事内容</ListHeaderItem>
        <ListHeaderItem className="detail">詳細（目的地）</ListHeaderItem>
        <ListHeaderItem className="class_age">クラス</ListHeaderItem>
      </ListHeader>
      <EventsList />
    </EventsListBlockSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const EventsListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 20px 16px;
  ${pc`
    &.close {
      width: 100%;
      margin-right: 0px;
    }
    width: calc(100% - 380px);
    margin-right: 10px;
    padding: 30px;
  `}
`;

const ListHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${color.list_header};
  color: ${color.light_gray};
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  border: 1px solid ${color.divide_border};
`;

const ListHeaderItem = styled.div`
  &.date {
    width: calc(14%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
`;
