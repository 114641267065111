import {
  Page,
  UpdateAllPagesRequestBodyItem,
  UpdatePageRequestBody,
  TagItem
} from "./types.d";

import {
  GET_PAGES_REQUESTED,
  GET_PAGE_REQUESTED,
  UPDATE_ALL_PAGES_REQUESTED,
  SET_PAGE_DETAIL,
  UPDATE_PAGE_REQUESTED,
  SET_PAGE_DETAIL_EDIT_MODE,
  SET_STORED_PAGE_DETAIL_STATUS,
  SET_SEARCH_TAGS,
  SET_SEARCH_KEYWORD,
  GET_PAGES_SEARCH_REQUESTED,
  SET_PAGE_TITLE_ACTION,
  SET_PAGE_DETAIL_FROM_PAGES
} from "./types";

export const setPageDetailAction = (pageDetail: Page) => {
  return {
    type: SET_PAGE_DETAIL,
    pageDetail
  };
};

export const getPagesRequest = (data: {
  token: string;
  type_id?: number;
  topic_id?: number;
  statuses?: number[];
  tags?: string[];
  keyword?: string;
}) => {
  const { token, type_id, topic_id, statuses, tags, keyword } = data;
  return {
    type: GET_PAGES_REQUESTED,
    token,
    type_id,
    topic_id,
    statuses,
    tags,
    keyword
  };
};

export const getPagesSearchRequest = (data: {
  token: string;
  tags?: string[];
  keyword?: string;
}) => {
  const { token, tags, keyword } = data;
  return {
    type: GET_PAGES_SEARCH_REQUESTED,
    token,
    tags,
    keyword
  };
};

export const getPageRequest = (data: { token: string; id: number }) => {
  const { token, id } = data;
  return {
    type: GET_PAGE_REQUESTED,
    token,
    id
  };
};

export const updateAllPagesRequest = (data: {
  token: string;
  req_body: UpdateAllPagesRequestBodyItem[];
  to_be_deleted_ids: (number | null)[] | null;
  type_id: number | null;
  topic_id: number | null;
}) => {
  const { token, req_body, to_be_deleted_ids, type_id, topic_id } = data;
  return {
    type: UPDATE_ALL_PAGES_REQUESTED,
    token,
    req_body,
    to_be_deleted_ids,
    type_id,
    topic_id
  };
};

export const savePageDetailRequest = (data: {
  token: string;
  id: number;
  manual_id: number;
  req_body: UpdatePageRequestBody;
}) => {
  console.log("set data", data);
  const { token, id, req_body, manual_id } = data;
  return {
    type: UPDATE_PAGE_REQUESTED,
    token,
    id,
    manual_id,
    req_body
  };
};

export const setPageDetailEditMode = (pageDetailEditMode: boolean) => {
  return {
    type: SET_PAGE_DETAIL_EDIT_MODE,
    pageDetailEditMode
  };
};

export const setStoredPageDetailStatus = (status: number) => {
  return {
    type: SET_STORED_PAGE_DETAIL_STATUS,
    status
  };
};

export const setSearchTagsAction = (data: {
  oldTags: TagItem[];
  tagToAdd?: TagItem;
}) => {
  const { oldTags, tagToAdd } = data;
  return {
    type: SET_SEARCH_TAGS,
    oldTags,
    tagToAdd
  };
};
export const setSearchKeywordAction = (searchKeyword: string) => {
  return {
    type: SET_SEARCH_KEYWORD,
    searchKeyword
  };
};

export const setStoredPageTitleAction = (title_draft: string) => {
  return {
    type: SET_PAGE_TITLE_ACTION,
    title_draft
  };
};

export const setPageDetailFromPages = (pages: Page[], pageId: string) => {
  return {
    type: SET_PAGE_DETAIL_FROM_PAGES,
    pages,
    pageId
  };
};
