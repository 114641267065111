import { EventType } from "components/Events/type";
import React from "react";
import styled from "styled-components";

interface Props {
  typeOfEvent: EventType[];
  eventTypeId: number;
  content?: string;
  detail?: string | null;
  classes: {
    class_age: string;
    checked: boolean;
  }[];
}

const EventInputPreview = (props: Props) => {
  const { content, detail, classes, typeOfEvent, eventTypeId } = props;
  const i = typeOfEvent.findIndex(event => event.id === eventTypeId);
  const contentIsRequired = typeOfEvent[i].form_rules.content === "required";
  const eventName = typeOfEvent[i].name;

  const formattedContent = () => {
    if (eventTypeId === 20 || eventTypeId === 11) {
      return `${content}`;
    } else if (eventTypeId !== 20 && contentIsRequired) {
      return `${eventName}(${content})`;
    } else {
      return `${eventName}`;
    }
  };
  return (
    <Content>
      <p className="content">
        {formattedContent()}
        {detail !== "" && <span>@{detail}</span>}
        {classes.some(item => item.checked) && (
          <span>
            (
            {classes.map(item =>
              item.checked ? <ClassAge>{item.class_age}</ClassAge> : ""
            )}
            歳児)
          </span>
        )}
      </p>
      <span className="note">と表示されます</span>
    </Content>
  );
};

export default EventInputPreview;

const Content = styled.div`
  width: 100%;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .content {
    line-height: 20px;
    padding-bottom: 5px;
  }
  .note {
    display: inline-block;
  }
`;

const ClassAge = styled.span`
  &:not(:last-child)::after {
    content: ",";
  }
`;
