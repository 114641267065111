import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import EventsSearchBlock from "../EventsSearchBlock";
import ApprovalListBlock from "./ApprovalListBlock";
import {
  toggleReturnEventModal,
  toggleCancelAllEventsModal
} from "../../../store/common/modal/action";
import {
  getApprovalMonthlyStatusRequest,
  resetSelectedOptions,
  resetToBeApproved
} from "../../../store/event/approval/action";
import { getEventListRequest } from "../../../store/event/events/action";
import { getMonthlyEventsRequest } from "../../../store/event/monthly_events/action";
import FacilityHeader from "../FacilityHeader";
import { SetIsSearchByEventType } from "store/event/event_search/action";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function Approval() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const position_id = useSelector((state: any) => state.session.position.id);
  const year = useSelector((state: any) => state.event_search.filtered_year);
  const to_be_rejected = useSelector(
    (state: any) => state.approval.to_be_rejected
  );
  const month_to_be_canceled_all = useSelector(
    (state: any) => state.approval.month_to_be_canceled_all
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const filtered_class = useSelector(
    (state: any) => state.event_search.filtered_class
  );
  const filtered_remand = useSelector(
    (state: any) => state.event_search.filtered_remand
  );

  const eventList = useSelector((state: any) => state.events.events);

  // 一覧取得
  useEffect(() => {
    if (facility_id !== null) {
      // LoadingModuleの表示開始
      dispatch(toggleImgLoadingState(true));
      dispatch(getEventListRequest(token, facility_id, year, [], [], ""));
      dispatch(
        getMonthlyEventsRequest(
          token,
          facility_id,
          year,
          filtered_months,
          filtered_class,
          filtered_remand
        )
      );
      dispatch(getApprovalMonthlyStatusRequest(token, facility_id, year));
    }
    dispatch(resetSelectedOptions());
    dispatch(resetToBeApproved());
  }, [dispatch, token, facility_id]);

  useEffect(() => {
    // ページに訪れたときに、検索状態をリセット
    dispatch(SetIsSearchByEventType([]));
  }, [dispatch]);

  // LoadingModuleの表示終了
  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, [dispatch, eventList]);

  return (
    <MainSC>
      <FacilityHeader />
      <ContentTitle>承認・申請</ContentTitle>

      <ContentSC>
        <EventsSearchBlock />
        <ApprovalListBlock />
      </ContentSC>

      {to_be_rejected.length !== 0 &&
        (position_id === 2 || position_id === 6) && (
          <FixedButtonAreaSC>
            <ButtonAreaDeskSC>
              <ButtonAreaDesk>選択した行事予定を差戻しますか？</ButtonAreaDesk>
              <ButtonAreaModal
                onClick={() => dispatch(toggleReturnEventModal(true))}
              >
                差戻し
              </ButtonAreaModal>
            </ButtonAreaDeskSC>
          </FixedButtonAreaSC>
        )}
      {month_to_be_canceled_all !== null &&
        (position_id === 3 || position_id === 6) && (
          <FixedButtonAreaSC>
            <ButtonAreaDeskSC>
              <ButtonAreaDesk>
                選択した行事予定を一括取り消ししますか？
              </ButtonAreaDesk>
              <ButtonAreaModal
                onClick={() => dispatch(toggleCancelAllEventsModal(true))}
              >
                取り消し
              </ButtonAreaModal>
            </ButtonAreaDeskSC>
          </FixedButtonAreaSC>
        )}
    </MainSC>
  );
}

export default Approval;

// ===================================================================================
// スタイル
// ===================================================================================

const MainSC = styled.div`
  display: block;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
`;

const ContentTitle = styled.div`
  padding: 0 12px;
  font-size: 18px;
  color: ${color.gray};
  font-weight: 600;
  ${pc`
    padding: 0 30px;
  `}
`;

const ContentSC = styled.div`
  padding: 20px 12px 130px;

  display: block;
  ${pc`
    padding: 20px 30px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
  width: 100%;
`;

const FixedButtonAreaSC = styled.div``;

const ButtonAreaDeskSC = styled.div`
  width: calc(100vw - 200px);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.white};
  position: fixed;
  bottom: 0;
  right: 0;
`;

const ButtonAreaDesk = styled.p`
  font-size: 14px;
  margin-right: 20px;
`;

const ButtonAreaModal = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 11px 0;
  cursor: pointer;
`;
