import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import pulldown_arrow from "../../../../images/icons/pulldown_arrow.svg";
import close from "../../../../images/icons/close.svg";

import { toggleDeleteReturnEventModal } from "../../../../store/common/modal/action";
import { deleteRejectRequest } from "../../../../store/event/approval/action";

export default function ReturnEventsModalContent() {
  const dispatch = useDispatch();
  const facility_id = useSelector((state: any) => state.facility.id);
  const token = useSelector((state: any) => state.session.token);
  const to_be_deleted_reject = useSelector(
    (state: any) => state.approval.to_be_deleted_reject
  );
  const search_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const search_month = useSelector(
    (state: any) => state.event_search.filtered_month
  );
  const search_classes = useSelector(
    (state: any) => state.event_search.filtered_classes
  );

  return (
    <DeleteReturnModalContentSC>
      <TitleBlock>
        <Title>差戻し内容の削除</Title>
        <CloseIcon
          src={close}
          alt="閉じる　ボタン"
          onClick={() => dispatch(toggleDeleteReturnEventModal(false))}
        />
      </TitleBlock>

      <ReturnEventsForm>
        <ReturnEventsFormDesc>
          以下の行事の差戻し内容を削除します。よろしいですか？
        </ReturnEventsFormDesc>
        <ReturnEventsFormList>
          <ReturnEventsFormItem>
            <ReturnEventsFormBlock>
              <ReturnEventsFormLabel>日付</ReturnEventsFormLabel>
              <ReturnEventsFormField>
                {to_be_deleted_reject.date}
              </ReturnEventsFormField>
            </ReturnEventsFormBlock>
          </ReturnEventsFormItem>

          <ReturnEventsFormItem>
            <ReturnEventsFormBlock>
              <ReturnEventsFormLabel>行事名</ReturnEventsFormLabel>
              <ReturnEventsFormField>
                {to_be_deleted_reject.title}
              </ReturnEventsFormField>
            </ReturnEventsFormBlock>
          </ReturnEventsFormItem>

          <ReturnEventsFormItem>
            <ReturnEventsFormBlock>
              <ReturnEventsFormLabel>行事内容</ReturnEventsFormLabel>
              <ReturnEventsFormField>
                {to_be_deleted_reject.content}
              </ReturnEventsFormField>
            </ReturnEventsFormBlock>
          </ReturnEventsFormItem>

          <ReturnEventsFormItem>
            <ReturnEventsFormBlock>
              <ReturnEventsFormLabel>詳細 (目的地)</ReturnEventsFormLabel>
              <ReturnEventsFormField>
                {to_be_deleted_reject.detail}
              </ReturnEventsFormField>
            </ReturnEventsFormBlock>
          </ReturnEventsFormItem>
          {to_be_deleted_reject.remand.map((reject: any) => {
            return (
              <ReturnEventsFormItem>
                <ReturnEventsFormBlock>
                  <ReturnEventsFormLabel>差戻し内容</ReturnEventsFormLabel>
                  <ReturnEventsFormField>
                    {reject.comment}({reject.user})
                  </ReturnEventsFormField>
                </ReturnEventsFormBlock>
              </ReturnEventsFormItem>
            );
          })}
        </ReturnEventsFormList>

        <Buttons>
          <CancelButton
            onClick={() => dispatch(toggleDeleteReturnEventModal(false))}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            value="削除"
            onClick={(e) => {
              e.preventDefault();
              const reject_id_array = to_be_deleted_reject.remand.map(
                (item: any) => item.id
              );
              reject_id_array.forEach((reject_id: number) => {
                dispatch(
                  deleteRejectRequest(
                    token,
                    facility_id,
                    reject_id,
                    search_year,
                    search_month,
                    search_classes
                  )
                );
              });
            }}
          />
        </Buttons>
      </ReturnEventsForm>
    </DeleteReturnModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const DeleteReturnModalContentSC = styled.div`
  max-width: 780px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const ReturnEventsForm = styled.div`
  padding: 33px 54px 30px;
`;

const ReturnEventsFormDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 50px;
`;

const ReturnEventsFormList = styled.ul`
  margin-bottom: 60px;
`;

const ReturnEventsFormItem = styled.li`
  font-size: 14px;
  line-height: calc(21 / 14);
`;

const ReturnEventsFormBlock = styled.dl`
  width: 302px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
`;

const ReturnEventsFormLabel = styled.dt`
  font-weight: normal;
  text-align: left;
  width: 142px;
  display: block;
  position: relative;
  &::after {
    content: ":";
    display: inline-block;
    position: absolute;
    right: 5px;
  }
`;
const ReturnEventsFormField = styled.dd`
  width: calc(100% - 142px);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  &:disabled {
    opacity: 0.6;
    cursur: none;
  }
`;
