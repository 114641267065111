import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { pc } from "../../mixins/mediaQuery";
import AccountPagination from "../../Common/AccountPagination";
import AccountsList from "./AccountsList";
import { ErrorBoundary } from "@sentry/react";
import { getAccountsRequest } from "store/global/accounts/action";
import { useHistory } from "react-router-dom";
import { setAccountEmailToSearch } from "store/common/paginate/action";

function AccountsListBlock() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const token = useSelector((state: any) => state.session.token);
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.account_per_page
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const handleErrorReset = () => {
    dispatch(getAccountsRequest(token, 1, paginate_per_page, ""));
    dispatch(setAccountEmailToSearch(""));
    push("/");
  };
  return (
    <AccountsListBlockSC className={rightSectionOpen ? "open" : "close"}>
      <ErrorBoundary
        fallback={({ resetError }) => {
          return (
            <>
              <ErrorTextSC>
                データ取得に失敗、もしくは不正なデータが存在します。
                <br />
                恐れ入りますが、システム開発者にご連絡ください。
              </ErrorTextSC>
              <ButtonSC onClick={handleErrorReset}>TOPに戻る</ButtonSC>
            </>
          );
        }}
      >
        <AccountPagination />
        <AccountsList />
        <AccountPagination position="bottom" />
      </ErrorBoundary>
    </AccountsListBlockSC>
  );
}

export default AccountsListBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const AccountsListBlockSC = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    &.close {
      width: 100%;
      margin-right: 0px;
    }
    width: calc(100% - 380px);
    margin-right: 10px;
    padding: 30px;
    order: 1;
  `}
`;

const ErrorTextSC = styled.p`
  font-size: 12px;
  margin-bottom: 1rem;
`;
const ButtonSC = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #298948;
  font-weight: bold;
  padding: 9px;
  font-size: 12px;
  border-radius: 4px;
  max-width: fit-content;
`;
