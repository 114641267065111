import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import EditEventModalContent from "./EditEventModalContent";

import { toggleEditEventModal } from "../../../../store/common/modal/action";

export default function EditEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <EditEventsModalBg
        onClick={() => dispatch(toggleEditEventModal(false))}
      ></EditEventsModalBg>
      <EditEventModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const EditEventsModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
