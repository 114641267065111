import React from 'react'

const pageNumControl = (PageNumItems: any, paginate_current_page: number, PageNum: any, handlePageNumClick: any) => {
 // ページが増えたときにスタイルが崩れないようにする
  // 総ページ数の取得
  const pages = PageNumItems.length
  let startIndex, endIndex
  const pageNumbers = []
  if(pages <= 3) {　//総ページ数が３ページ以下の場合
    startIndex = 1
    endIndex = pages
    // ページの配列を作る。
    for(let i = startIndex; i <=endIndex; i++) {
      pageNumbers.push(<PageNum className={paginate_current_page === i ? 'current' : ''} onClick={(e: any) => handlePageNumClick(e, i)}>{`${i.toString()}`}</PageNum>)
    }
  } else { //総ページ数が５ページ以上の場合
    if(paginate_current_page <=2) {　//５ページ以上で現在のページが３ページ以前の時
      startIndex = 1
      endIndex = 3
      // ページの配列を作る。末尾に「...」を追加する
        for(let i = startIndex; i <=endIndex; i++) {
        pageNumbers.push(<PageNum className={paginate_current_page === i ? 'current' : ''} onClick={(e: any) => handlePageNumClick(e, i)}>{`${i.toString()}`}</PageNum>)
      }
      pageNumbers.push(<PageNum className="leader">...</PageNum>)
    }else if (paginate_current_page >2 && paginate_current_page < pages-1){
      startIndex = paginate_current_page　-1
      endIndex = paginate_current_page + 1
      // ページの配列を作る。先頭と末尾に「...」を追加する
      pageNumbers.push(<PageNum className="leader">...</PageNum>)
        for(let i = startIndex; i <=endIndex; i++) {
        pageNumbers.push(<PageNum className={paginate_current_page === i ? 'current' : ''} onClick={(e: any) => handlePageNumClick(e, i)}>{`${i.toString()}`}</PageNum>)
      }
      pageNumbers.push(<PageNum className="leader">...</PageNum>)
    } else {
      startIndex = pages - 2
      endIndex = pages
      // ページの配列を作る。先頭に「...」を追加する
      pageNumbers.push(<PageNum className="leader">...</PageNum>)
      for(let i = startIndex; i <=endIndex; i++) {
        pageNumbers.push(<PageNum className={paginate_current_page === i ? 'current' : ''} onClick={(e: any) => handlePageNumClick(e, i)}>{`${i.toString()}`}</PageNum>)
      }
    }
  }

return pageNumbers
}

export default pageNumControl


 