import styled from "styled-components";

export interface ITabContainer {
  active?: boolean;
}

export const TabSwitchWrapper = styled.div`
  display: flex;
`;

export const TabAreaInputSC = styled.input`
  display: none;
  &:checked + label {
    background-color: #fff;
  }
`;

export const TabAreaLabelSC = styled.label`
  width: 165px;
  height: 40px;
  background-color: #c4c4c4;
  align-items: center;
  text-align: center;
  display: grid;
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
`;

export const TabAreaContainerSC = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 25px;
  padding-bottom: 60px;
  border-radius: 0 4px 4px 4px;
  position: relative;
`;

export const TabContent = styled.div<ITabContainer>`
  display: ${props => (props.active ? "block" : "none")};
`;
