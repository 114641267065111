const TOGGLE_FACILITY_MODAL = "TOGGLE_FACILITY_MODAL"
const GET_FACILITIES_SUCCEEDED = "GET_FACILITIES_SUCCEEDED"
const GET_FACILITIES_FAILED = "GET_FACILITIES_FAILED"
const GET_FACILITIES_REQUESTED = "GET_FACILITIES_REQUESTED"

export {
  TOGGLE_FACILITY_MODAL,
  GET_FACILITIES_SUCCEEDED,
  GET_FACILITIES_FAILED,
  GET_FACILITIES_REQUESTED  
}