import { AxiosResponse } from "axios";
import { put, call, takeLatest, all } from "redux-saga/effects";
import {
  BEGIN_SESSION_REQUESTED,
  BEGIN_SESSION_SUCCEEDED,
  BEGIN_SESSION_FAILED,
  END_SESSION_REQUESTED,
  END_SESSION_SUCCEEDED,
  END_SESSION_FAILED,
  GOOGLE_LOGIN_REQUESTED,
  LOGIN_STATUS_401,
  GET_STATUS_SUCCEEDED,
  MAINTENANCE_ON,
  SYSTEM_UP,
  MAINTENANCE_ON_REQUESTED,
  SYSTEM_UP_REQUESTED,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500,
  GET_STATUS_REQUESTED,
  LOG_OUT,
  LOG_OUT_REQUESTED
} from "../store/session/type";
import { GET_REGISTERED_CATEGORIES_SUCCEEDED } from "../store/faq/categories/type";
import { GET_REGISTERED_TAGS_SUCCEEDED } from "../store/faq/tags/type";
import { GET_POSITIONS_SUCCEEDED } from "../store/common/positions/type";
import { GET_FACILITIES_SUCCEEDED } from "../store/event/facilities/type";

import {
  apiLogin,
  apiLogout,
  apiGetCategories,
  apiGetTags,
  googleLoginApi,
  apiGetPositions,
  apiGetFacilities,
  apiGetStatus,
  apiUpdateSystemStatus
} from "../api/global/sessionApi";
import {
  GetCategoriesResponseBody,
  GetFacilitiesResponseBody,
  GetFaqTagsResponseBody,
  GetPositionsResponseBody,
  GetStatusResponseBody,
  LoginResponceBody
} from "store/session/types";

function* getStatus(token: string) {
  try {
    const result: AxiosResponse<GetStatusResponseBody> = yield call(
      apiGetStatus,
      token
    );
    return result.data.system_status;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      console.log("Categories fetch failed", e.message);
      return e;
    }
  }
}

function* getFacilities(token: string) {
  try {
    const result: AxiosResponse<GetFacilitiesResponseBody> = yield call(
      apiGetFacilities,
      token
    );
    console.log("get facilities success: ", result);
    return result.data.facilities;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      console.log("Categories fetch failed", e.message);
    }
  }
}

function* getPositions(token: string) {
  try {
    const result: AxiosResponse<GetPositionsResponseBody> = yield call(
      apiGetPositions,
      token
    );
    console.log("get positions success: ", result);
    return result.data.positions;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      console.log("Categories fetch failed", e.message);
    }
  }
}

function* getRegisteredCategories(token: string) {
  try {
    const result: AxiosResponse<GetCategoriesResponseBody> = yield call(
      apiGetCategories,
      token
    );
    console.log("get registered categories success: ", result);
    return result.data.categories;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      console.log("Categories fetch failed", e.message);
    }
  }
}

function* getRegisteredTags(token: string) {
  try {
    const result: AxiosResponse<GetFaqTagsResponseBody> = yield call(
      apiGetTags,
      token
    );
    console.log("get registered tags success: ", result);
    return result.data.tags;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      console.log("Tags fetch failed", e.message);
    }
  }
}

function* loginForBeginSession(action: any) {
  try {
    const result: AxiosResponse<LoginResponceBody> = yield call(
      apiLogin,
      action.email,
      action.password
    );
    return result;
  } catch (e) {
    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      console.log("Tags fetch failed", e.message);
    }
  }
}

function* beginSession(action: any) {
  try {
    const result = yield* loginForBeginSession(action);
    const facilities = result ? yield* getFacilities(result.data.token) : [];
    const status = result ? yield* getStatus(result.data.token) : {};
    const categories = result
      ? yield* getRegisteredCategories(result.data.token)
      : [];
    const tags = result ? yield* getRegisteredTags(result.data.token) : [];
    const positions =
      result?.data.user.position.id === 3
        ? yield* getPositions(result.data.token)
        : [];
    if (result) {
      yield all([
        put({ type: BEGIN_SESSION_SUCCEEDED, result: result.data }),
        put({ type: GET_POSITIONS_SUCCEEDED, positions: positions }),
        put({ type: GET_FACILITIES_SUCCEEDED, facilities: facilities }),
        put({ type: GET_STATUS_SUCCEEDED, status: status }),
        put({
          type: GET_REGISTERED_CATEGORIES_SUCCEEDED,
          categories: categories
        }),
        put({ type: GET_REGISTERED_TAGS_SUCCEEDED, tags: tags })
      ]);
    }
  } catch (e) {
    yield put({ type: BEGIN_SESSION_FAILED, message: e.data.response });
  }
}

function* endSession(action: any) {
  try {
    const result = yield call(apiLogout, action.token);
    console.log("result", result);
    console.log("result.data", result.data);
    yield put({ type: END_SESSION_SUCCEEDED, result: result.data });
  } catch (e) {
    yield put({ type: END_SESSION_FAILED, message: e.data.response });
  }
}

function* logout(action: any) {
  try {
    const result = yield call(apiLogout, action.token);
    yield put({ type: LOG_OUT, result: result.data });
  } catch (e) {
    console.log(e);
  }
}

function* googleLogin(action: any) {
  try {
    const result = yield call(googleLoginApi, action.code);
    const facilities = result ? yield* getFacilities(result.data.token) : [];
    const categories = result
      ? yield* getRegisteredCategories(result.data.token)
      : [];
    const tags = result ? yield* getRegisteredTags(result.data.token) : [];
    const status = result ? yield* getStatus(result.data.token) : {};
    const positions =
      result?.data.user.position.id === 3
        ? yield* getPositions(result.data.token)
        : [];
    console.log("result", result);
    console.log("result.data", result.data);
    yield all([
      put({ type: BEGIN_SESSION_SUCCEEDED, result: result.data }),
      put({
        type: GET_REGISTERED_CATEGORIES_SUCCEEDED,
        categories: categories
      }),
      put({ type: GET_REGISTERED_TAGS_SUCCEEDED, tags: tags }),
      put({ type: GET_FACILITIES_SUCCEEDED, facilities: facilities }),
      put({ type: GET_STATUS_SUCCEEDED, status: status }),
      put({ type: GET_POSITIONS_SUCCEEDED, positions: positions })
    ]);
  } catch (e) {
    yield put({ type: BEGIN_SESSION_FAILED, message: e.response.data });
  }
}

function* maintenanceOn(action: any) {
  try {
    yield call(apiUpdateSystemStatus, action.token, action.system_status);
    yield put({ type: MAINTENANCE_ON });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getSystemStatus(action: any) {
  console.log("getSystemStatus is worked");
  try {
    const result: AxiosResponse<GetStatusResponseBody> = yield call(
      apiGetStatus,
      action.token
    );
    yield put({
      type: GET_STATUS_SUCCEEDED,
      status: result.data.system_status
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* systemUp(action: any) {
  try {
    yield call(apiUpdateSystemStatus, action.token, action.system_status);
    yield put({ type: SYSTEM_UP });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const sessionSaga = [
  takeLatest(BEGIN_SESSION_REQUESTED, beginSession),
  takeLatest(END_SESSION_REQUESTED, endSession),
  takeLatest(GOOGLE_LOGIN_REQUESTED, googleLogin),
  takeLatest(MAINTENANCE_ON_REQUESTED, maintenanceOn),
  takeLatest(SYSTEM_UP_REQUESTED, systemUp),
  takeLatest(GET_STATUS_REQUESTED, getSystemStatus),
  takeLatest(LOG_OUT_REQUESTED, logout)
];

export default sessionSaga;
