import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color } from "../../../mixins";
import FacilityHeading from "../../../Common/FacilityHeading";
import StatusMonthlyListBlock from "./StatusMonthlyListBlock";
import FacilitySelectNav from "../../../Common/FacilitySelectNav/FacilitySelectNav";
import {
  toggleAlreadyApprovedErrorModal,
  toggleMonthlyApprovalModal,
  toggleCancelYearMonthsEventsModal
} from "../../../../store/common/modal/action";
import { setAlreadyApprovedMonths } from "../../../../store/event/approval/action";
import { useCurrentPosition } from "components/Events/Constants/useCurrentPosition";

function Status() {
  const sidebarIsOpen = useSelector(
    (state: any) => state.session.sidebarIsOpen
  );
  const dispatch = useDispatch();
  const to_be_approved = useSelector(
    (state: any) => state.approval.to_be_approved
  );
  const monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );
  const authority_status = useSelector(
    (state: any) => state.session.position.id
  );

  // 承認済みの月が配列に入っているか確認する
  // 月別承認状況の配列から、現在選択されている月だけを抜き出す
  const checkedMonth = to_be_approved.map((month: any) => {
    const checkedArray = monthly_status.find(
      (item: any) => item.month === month
    );
    return checkedArray;
  });
  // 権限ごとに、現在選択されている月の承認状況を抜き出す。
  const status_array = checkedMonth.map((month: any) => {
    if (authority_status === 5) {
      return { month: month.month, status: month.leader_approved };
    } else if (authority_status === 6) {
      return { month: month.month, status: month.administrator_approved };
    } else {
      return { month: month.month, status: month.director_approved };
    }
  });
  // すでに承認されている月が1つでもあるかどうか
  const alreadyApproved = status_array.some(
    (item: any) => item.status === true
  );
  // 選択されている月のうちすでに承認されている月は何月か
  const alreadyApprovedMonths = status_array
    .filter((item: any) => item.status === true)
    .map((item: any) => item.month);

  const handleModalOpen = (e: any) => {
    e.preventDefault();
    if (alreadyApproved) {
      dispatch(toggleAlreadyApprovedErrorModal(true));
      dispatch(setAlreadyApprovedMonths(alreadyApprovedMonths));
    } else {
      dispatch(toggleMonthlyApprovalModal(true));
    }
  };
  const { isAdmin } = useCurrentPosition();

  return (
    <MainSC>
      <FacilityHeader>
        <FacilityHeading />
        <FacilitySelectNav />
      </FacilityHeader>
      <ContentTitle>{"行事予定承認 > 月別承認"}</ContentTitle>

      <ContentSC>
        <StatusMonthlyListBlock />
      </ContentSC>
      {to_be_approved.length > 0 && (
        <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
          <ButtonAreaDeskSC>選択した月の行事予定を</ButtonAreaDeskSC>
          <SubmitButtonSC type="button" onClick={handleModalOpen}>
            承認
          </SubmitButtonSC>
          {isAdmin && (
            <ButtonAreaModal
              onClick={() => dispatch(toggleCancelYearMonthsEventsModal(true))}
            >
              取り消し
            </ButtonAreaModal>
          )}
        </FixedButtonAreaSC>
      )}
    </MainSC>
  );
}

export default Status;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: block;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
`;

const FacilityHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  // height: 38px;
  // margin: 20px 0 0 0;
  padding: 20px 0 8px;
  margin: 0 30px 30px;
  border-bottom: 1px solid #bbbbbb;
`;

const ContentTitle = styled.div`
  padding: 0 12px;
  font-size: 18px;
  color: ${color.gray};
  font-weight: 600;
  ${pc`
    padding: 0 30px;
  `}
`;

const ContentSC = styled.div`
  padding: 20px 12px 130px;
  display: block;
  ${pc`
    padding: 20px 30px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
  width: 100%;
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${color.gray};
  ${pc`
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 14px;
    `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  width: 160px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background-color: ${color.gray};
  margin-right: 12px;
  opacity: 1;
  cursor: pointer;
  ${pc`
    height: 36px;
  `}
`;

const ButtonAreaModal = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 11px 0;
  cursor: pointer;
`;
