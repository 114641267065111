import {
  GET_ARTICLES_REQUESTED,
  GET_ARTICLES_FOR_USER_REQUESTED,
  RESET_ARTCLE_DETAIL,
} from "./type";

// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// ================================================================= //
// Actions
// ----------------------------------------------------------------- //

// 記事情報登録(管理者、編集者)
export const getArticlesRequest = (
  token: string,
  pathName: string,
  articles_page_num?: number,
  articles_count?: number,
  categories_to_be_searched?: string[],
  tags_to_be_searched?: string[],
  keyword?: string
) => {
  return {
    type: GET_ARTICLES_REQUESTED,
    token,
    pathName,
    articles_page_num,
    articles_count,
    categories_to_be_searched,
    tags_to_be_searched,
    keyword,
  };
};
// 記事情報登録(閲覧者)
export const getArticlesForUserRequest = (
  token: string,
  pathName: string,
  articles_page_num?: number,
  articles_count?: number,
  categories_to_be_searched?: string[],
  tags_to_be_searched?: string[],
  keyword?: string
) => {
  return {
    type: GET_ARTICLES_FOR_USER_REQUESTED,
    token,
    pathName,
    articles_page_num,
    articles_count,
    categories_to_be_searched,
    tags_to_be_searched,
    keyword,
  };
};
