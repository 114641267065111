export const BEGIN_SESSION_REQUESTED = "BEGIN_SESSION_REQUESTED";
export const BEGIN_SESSION_SUCCEEDED = "BEGIN_SESSION_SUCCEEDED";
export const BEGIN_SESSION_FAILED = "BEGIN_SESSION_FAILED";
export const END_SESSION_REQUESTED = "END_SESSION_REQUESTED";
export const END_SESSION_SUCCEEDED = "END_SESSION_SUCCEEDED";
export const END_SESSION_FAILED = "END_SESSION_FAILED";
export const GOOGLE_LOGIN_REQUESTED = "GOOGLE_LOGIN_REQUESTED";
export const TOGGLE_SEARCH_ACCOUNT_IS_OPENED =
  "TOGGELE_SEARCH_ACCOUNT_IS_OPENED";
export const TOGGLE_SEARCH_ARTICLE_IS_OPENED =
  "TOGGLE_SEARCH_ARTICLE_IS_OPENED";
export const HANDLE_CLEAR_SEARCH_TERMS = "HANDLE_CLEAR_SEARCH_TERMS";
export const LOGIN_STATUS_401 = "LOGIN_STATUS_401";
export const CLEAR_SYSTEM_CACHE = "CLEAR_SYSTEM_CACHE";
export const TOGGLE_CACHE_CLEARED = "TOGGLE_CACHE_CLEARED";
export const TOGGLE_TO_CLEAR_CACHE = "TOGGLE_TO_CLEAR_CACHE";
export const GET_STATUS_REQUESTED = "GET_STATUS_REQUESTED";
export const GET_STATUS_SUCCEEDED = "GET_STATUS_SUCCEEDED";
export const MAINTENANCE_ON = "MAINTENANCE_ON";
export const SYSTEM_UP = "SYSTEM_UP";
export const MAINTENANCE_ON_REQUESTED = "MAINTENANCE_ON_REQUESTED";
export const SYSTEM_UP_REQUESTED = "SYSTEM_UP_REQUESTED";
export const RESPONSE_STATUS_200 = "RESPONSE_STATUS_200";
export const RESPONSE_STATUS_401 = "RESPONSE_STATUS_401";
export const RESPONSE_STATUS_403 = "RESPONSE_STATUS_403";
export const RESPONSE_STATUS_404 = "RESPONSE_STATUS_404";
export const RESPONSE_STATUS_422 = "RESPONSE_STATUS_422";
export const RESPONSE_STATUS_500 = "RESPONSE_STATUS_500";
export const RESET_RESPONSE_STATUS = "RESET_RESPONSE_STATUS";
export const LOG_OUT_REQUESTED = "LOG_OUT_REQUESTED";
export const LOG_OUT = "LOG_OUT";
export const IS_LOGGED_IN_TURN_TO_FALSE = "IS_LOGGED_IN_TURN_TO_FALSE";
