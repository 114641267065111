import { ADD_TO_BE_UPDATED, CHANGE_NAME_TO_BE_UPDATED, CHANGE_ORDER_TO_BE_UPDATED, DELETE_TO_BE_UPDATED } from './type'

export const addToBeUpdated = (update_target_id:number, categories:[]) => {
  return {
    type: ADD_TO_BE_UPDATED,
    update_target_id,
    categories
  }
}

export const deleteToBeUpdated = (target_remove_index: number) => {
  return {
    type: DELETE_TO_BE_UPDATED,
    target_remove_index
  }
}

export const changeNameToBeUpdated = (updated_name: string, updated_target_id:number) => {
  return {
    type:CHANGE_NAME_TO_BE_UPDATED,
    updated_name,
    updated_target_id
  }
}

export const changeOrderToBeUpdated = (updated_order: number, updated_target_id:number) => {
  return {
    type:CHANGE_ORDER_TO_BE_UPDATED,
    updated_order,
    updated_target_id
  }
}