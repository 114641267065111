import axios from "axios";
import { env_url, env_v2_url } from "../../serverEnv";

// ユーザー情報一覧取得
// const getAccountsApi = (token: string) => {
//   return axios.get(
//     `${env_url}/users`,
//     {
//       headers: {
//         "Authorization": `Bearer ${token}`,
//       }
//     }
//   );
// };

// ユーザー情報一覧取得
const getAccountsApi = (
  token: string,
  accounts_page_num: number,
  accounts_per_page: number,
  email_to_search: string
) => {
  return axios.get(`${env_url}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: accounts_page_num,
      limit: accounts_per_page,
      email: email_to_search,
    },
  });
};

function postAccountApi(
  token: string,
  name: string,
  position_id: number,
  email: string,
  password: string,
  facilities: any
) {
  return axios.post(
    `${env_url}/users`,
    {
      name: name,
      position_id: position_id,
      email: email,
      password: password,
      facilities: facilities,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

// v1のAPI
// function putAccountEditApi(token: string, id: number, name: string, role_id: number, email: string, password: string) {
//   return axios.put(
//     `${env_url}/users/${id}`,
//     {
//       name: name,
//       role_id: role_id,
//       email: email,
//       password: password
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//   });
// };

//v2のAPI
function putAccountEditApi(
  token: string,
  id: number,
  name: string,
  position_id: number,
  email: string,
  password: string,
  facilities: any
) {
  return axios.put(
    `${env_v2_url}/users/${id}`,
    {
      name: name,
      position_id: position_id,
      email: email,
      password: password,
      facilities: facilities,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

function deleteAccountEditApi(token: string, id: number) {
  return axios.delete(`${env_url}/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// 記事新規作成後の記事取得
// 記事情報一覧取得
// function* getAccountssAfterPostApi(token: string, accounts_page_num: number, accounts_limit: number, email: string) {
//   const result = yield axios.get(`https://api.gowest.techbeans.jp/api/v1/users`, {
//     headers: {
//       Authorization: `Bearer ${token}`
//     },
//     params: {
//       page: accounts_page_num,
//       limit: accounts_limit,
//       email: email
//     }
//   });
//   return result;
// }

// ユーザー情報登録
function* postAccountNewApi(
  token: string,
  name: string,
  position_id: number,
  email: string,
  password: string
) {
  try {
    const result = yield axios.post(
      `${env_url}/users`,
      {
        name: name,
        position_id: position_id,
        email: email,
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 記事新規作成後の記事取得
// 記事情報一覧取得
function* getAccountsAfterPostApi(
  token: string,
  accounts_page_num: number,
  accounts_limit: number,
  email: string
) {
  const result = yield axios.get(`${env_url}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: accounts_page_num,
      limit: accounts_limit,
      email: email,
    },
  });
  return result;
}

const getAccountEditApi = (token: string, id: number) => {
  console.log("id:", id);
  return axios.get(`${env_url}/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  getAccountsApi,
  postAccountApi,
  postAccountNewApi,
  putAccountEditApi,
  getAccountsAfterPostApi,
  deleteAccountEditApi,
  getAccountEditApi,
};
