import React, { FC } from "react";
import { CheckBoxSC, CheckBoxInputSC, CheckBoxLabelSC } from "./style";

interface CheckBoxProps {
  checked?: boolean;
  id: string;
  onChange: () => void;
  label?: string;
}

export const Checkbox: FC<CheckBoxProps> = props => {
  const { checked, id, onChange, label } = props;
  const handleClickCheckbox = () => {
    onChange();
  };
  return (
    <CheckBoxSC>
      <CheckBoxInputSC
        type="checkbox"
        id={id}
        onChange={handleClickCheckbox}
        checked={checked}
      />
      <CheckBoxLabelSC htmlFor={id}>{label}</CheckBoxLabelSC>
    </CheckBoxSC>
  );
};
