import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_REGISTERED_TAGS_SUCCEEDED,
  GET_REGISTERED_TAGS_FAILED,
  DELETE_TAG_ITEM_FROM_VIEW,
  UPDATE_VIEW_TAG_NAME,
  UPDATE_VIEW_TAG_ORDER,
  DELETE_TAG_ITEM_SUCCEEDED,
  DELETE_TAG_ITEM_FAILED,
} from "./type";

interface Tag {
  id: number;
  name: string;
  order: number;
  articles_count: number;
}

const initialState: Tag[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_REGISTERED_TAGS_SUCCEEDED:
      return [
        // ...state,
        ...action.tags,
      ];

    case GET_REGISTERED_TAGS_FAILED:
      console.log("message: ", action.message);
      return [...state];

    case UPDATE_VIEW_TAG_NAME:
      const updatedNameItemIndex = state.findIndex(
        (updated_item: any) =>
          updated_item.id === Number(action.updated_target_id)
      );
      const updatedNameArray = [...state];
      updatedNameArray[updatedNameItemIndex].name = action.updated_name;
      return [...updatedNameArray];

    case UPDATE_VIEW_TAG_ORDER:
      const updatedOrderItemIndex = state.findIndex(
        (updated_item: any) =>
          updated_item.id === Number(action.updated_target_id)
      );
      const updatedOrderArray = [...state];
      updatedOrderArray[updatedOrderItemIndex].order = action.updated_order;
      return [...updatedOrderArray];

    // View上で該当アイテムを削除
    case DELETE_TAG_ITEM_FROM_VIEW:
      const deleted_array = state.filter((results: any, index: number) => {
        return state[index] !== state[action.deleteOrder];
      });
      return deleted_array;

    case DELETE_TAG_ITEM_SUCCEEDED:
      return state;

    case DELETE_TAG_ITEM_FAILED:
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
