import React, { useMemo } from "react";
import { SvgArrowBottomDom, SvgArrowBottomDomProps } from "./dom";

export const SvgArrowBottom = (props: SvgArrowBottomDomProps) => {
  //-----------------------------------------------------
  // init
  //-----------------------------------------------------

  //-----------------------------------------------------
  // memo
  //-----------------------------------------------------
  const Dom = useMemo(() => {
    return <SvgArrowBottomDom {...props} />;
  }, [props]);

  //-----------------------------------------------------
  // render
  //-----------------------------------------------------
  return Dom;
};
