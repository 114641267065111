import React from "react";

import { ButtonSC } from "./style";

interface ButtonProps {
  label: string;
  type?: "default" | "alert";
  disabled?: boolean;
  onClick: () => void;
}
export const Button: React.FC<ButtonProps> = props => {
  const { label, type, disabled, onClick } = props;
  return (
    <ButtonSC className={type} disabled={disabled} onClick={onClick}>
      {label}
    </ButtonSC>
  );
};
