import { SET_REFERER } from "./type";

interface Referer {
  referer: string;
}

const inititalState: Referer = {
  referer: "/"
};

const reducer = (state = inititalState, action: any) => {
  switch (action.type) {
    case SET_REFERER:
      if (action.referer !== "/" && action.referer !== "/signin") {
        return {
          referer: action.referer
        };
      } else {
        return {
          ...state
        };
      }
    default:
      return state;
  }
};

export default reducer;
