import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { GET_POSITIONS_SUCCEEDED } from "./type";
interface Positions {
  id: string;
  name: string;
}

const initialState: Positions[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_POSITIONS_SUCCEEDED:
      return [...action.positions];

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
