import {
  ADD_INTO_DELETE_ITEM_ARRAY,
  REMOVE_FROM_DELETE_ITEM_ARRAY,
  RESET_DELETE_ITEM_ARRAY,
  GET_EVENTS_TYPE_SUCCEEDED,
  GET_EVENTS_TYPE_FAILED,
  GET_EVENTS_LIST_SUCCEEDED,
  GET_EVENTS_LIST_FAILED,
  POST_EVENT_SUCCEEDED,
  POST_EVENT_FAILED,
  PUT_EVENT_SUCCEEDED,
  PUT_EVENT_FAILED,
  DELETE_EVENT_SUCCEEDED,
  DELETE_EVENT_FAILED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED,
  TOGGLE_APPROVAL_MONTH_REQUEST,
  POST_EVENTS_APPLY_SUCCEEDED,
  POST_EVENTS_APPLY_FAILED
} from "./type";

import eventTypeList from "../data/eventTypeList.json";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { EventType } from "components/Events/type";

interface EventsInitialState {
  events: Events[];
  number_of_checked_to_delete: number;
  deleteItemArray: number[];
  annual_events: [];
  type_of_event: TypeOfEvent[];
  selected_options: [];
}

interface TypeOfEvent {
  id: number;
  name: string;
  content: string;
  detail: string;
  classes: string;
}

interface Events {
  id: number;
  date: string;
  alreadyAppears: boolean;
  type: {
    id: number;
    name: string;
  };
  content: string;
  detail: string;
  status: number;
  class: string;
  rejects: Rejects[];
}

interface Rejects {
  id: number;
  date: string;
  comment: string;
  user: string;
}

const initialState: EventsInitialState = {
  events: [],
  number_of_checked_to_delete: 0,
  deleteItemArray: [],
  annual_events: [],
  type_of_event: JSON.parse(JSON.stringify(eventTypeList)),
  selected_options: []
};
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_APPROVAL_MONTH_REQUEST:
      return {
        ...state,
        selected_options: action.new_value
      };

    case GET_EVENTS_LIST_SUCCEEDED:
      return {
        ...state,
        events: action.events
      };

    case GET_EVENTS_LIST_FAILED:
      console.log("行事一覧取得エラー message:", action.message);
      return {
        ...state
      };

    case POST_EVENT_SUCCEEDED:
      console.log("新規行事作成に成功しました status: ", action.status);
      return {
        ...state
      };

    case POST_EVENT_FAILED:
      console.log("新規行事作成エラー message:", action.message);
      return {
        ...state
      };

    case PUT_EVENT_SUCCEEDED:
      console.log("行事予定編集に成功しました status: ", action.status);
      return {
        ...state
      };

    case PUT_EVENT_FAILED:
      console.log("行事予定編集エラー message:", action.message);
      return {
        ...state
      };

    case DELETE_EVENT_SUCCEEDED:
      console.log("行事予定削除に成功しました status: ", action.status);
      return {
        ...state,
        events: state.events.filter(
          (event: Events) => event.id !== action.deleted_event_id
        )
      };

    case DELETE_EVENT_FAILED:
      console.log("行事予定削除エラー message:", action.message);
      return {
        ...state
      };

    case ADD_INTO_DELETE_ITEM_ARRAY:
      return {
        ...state,
        deleteItemArray: [...state.deleteItemArray, action.id]
      };

    case REMOVE_FROM_DELETE_ITEM_ARRAY:
      const deleteItemArray = state.deleteItemArray.filter(
        (item: any) => item !== action.id
      );
      return {
        ...state,
        deleteItemArray: deleteItemArray
      };

    case RESET_DELETE_ITEM_ARRAY:
      return {
        ...state,
        deleteItemArray: []
      };

    case POST_EVENTS_APPLY_SUCCEEDED:
      console.log("行事予定承認申請に成功しました status: ", action.status);
      return {
        ...state,
        selected_options: []
      };

    case POST_EVENTS_APPLY_FAILED:
      console.log("行事予定承認申請エラー message:", action.message);
      return {
        ...state
      };

    case GET_EVENTS_TYPE_SUCCEEDED:
      return {
        ...state,
        type_of_event: action.result.types.filter(
          (type: EventType) => type.show === true
        )
      };

    case GET_EVENTS_TYPE_FAILED:
      console.log("行事種別取得エラー message:", action.message);
      return {
        ...state
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
