import { pc } from "components/mixins/mediaQuery";
import React from "react";
import styled from "styled-components";
import { containerBaseStyle } from "../../mixins/common";

const BlockContainer: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default BlockContainer;

const Container = styled.div`
  ${containerBaseStyle}
  width: 100%;
  max-width: auto;
  margin-bottom: 20px;
  order: 2;
  padding: 0px;
  ${pc`
    margin-right: 20px;
    order: 1;
    padding: 30px;
  `}
  position: relative;
`;
