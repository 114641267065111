import axios from "axios";
import { env_url, env_faq_url } from "../../serverEnv";
var qs = require("qs");
// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// 記事情報一覧取得
const getArticlesApi = (
  token: string,
  articles_page_num?: number,
  articles_count?: number,
  categories_to_be_searched?: string[],
  tags_to_be_searched?: string[],
  keyword?: string
) => {
  return axios.get(`${env_faq_url}/articles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: articles_page_num,
      limit: articles_count,
      categories: categories_to_be_searched,
      tags: tags_to_be_searched,
      keyword: keyword,
    },
    // paramsSerializer: params => {
    //   return qs.stringify(params)
    // }
  });
};

const getArticlesToShowApi = (
  token: string,
  articles_page_num?: number,
  articles_count?: number,
  categories_to_be_searched?: string[],
  tags_to_be_searched?: string[],
  keyword?: string
) => {
  return axios.get(`${env_faq_url}/articles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: articles_page_num,
      limit: articles_count,
      categories: categories_to_be_searched,
      tags: tags_to_be_searched,
      keyword: keyword,
      statuses: ["2", "3"],
    },
    // paramsSerializer: params => {
    //   return qs.stringify(params)
    // }
  });
};

// 記事情報登録
const postArticleApi = (
  token: string,
  title: string,
  content: any,
  search_string: string,
  status: number,
  categories: Category[],
  tags: Tag[]
) => {
  return axios.post(
    `${env_faq_url}/articles`,
    {
      title: title,
      content: content,
      search_string: search_string,
      status: status,
      categories: categories,
      tags: tags,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// 記事詳細情報取得
const getArticleApi = (token: string, id: number) => {
  return axios.get(`${env_faq_url}/articles/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

function* deleteArticleApi(token: string, id: number) {
  const result = yield axios.delete(`${env_faq_url}/articles/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      id: id,
    },
  });
  return result;
}

// 記事情報更新
// const putNewArticleApi = (
//   new_article_id: number,
//   token: string,
//   title: string,
//   content: any,
//   search_string: string,
//   status: number,
//   categories: Category[],
//   tags: Tag[]
// ) => {
//   return axios.put(
//     `${env_faq_url}/articles/${new_article_id}`,
//     {
//       "title": title,
//       "content": content,
//       "search_string": search_string,
//       "status": status,
//       "priority": 1,
//       "categories": categories,
//       "tags": tags
//     },
//     {
//       headers: {
//         "Authorization": `Bearer ${token}`,
//       },
//       params: {
//         "id": new_article_id
//       }
//     }
//   );
// };
function* putNewArticleApi(
  new_article_id: number,
  token: string,
  title: string,
  content: any,
  search_string: string,
  status: number,
  categories: Category[],
  tags: Tag[]
) {
  try {
    const result = yield axios.put(
      `${env_faq_url}/articles/${new_article_id}`,
      {
        title: title,
        content: content,
        search_string: search_string,
        status: status,
        priority: 1,
        categories: categories,
        tags: tags,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 記事新規作成後の記事取得
// 記事情報一覧取得
function* getArticlesApiAfterPut(
  token: string,
  articles_page_num?: number,
  articles_count?: number,
  categories_to_be_searched?: string[],
  tags_to_be_searched?: string[],
  keyword?: string
) {
  const result = yield axios.get(`${env_faq_url}/articles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: articles_page_num,
      limit: articles_count,
      categories: categories_to_be_searched,
      tags: tags_to_be_searched,
      keyword: keyword,
    },
  });
  return result;
}

// 記事情報更新
// const putEditArticleApi = (
//   edit_article_id: number,
//   token: string,
//   title: string,
//   content: any,
//   search_string: string,
//   status: number,
//   categories: Category[],
//   tags: Tag[]
// ) => {
//   return axios.put(
//     `${env_faq_url}/articles/${edit_article_id}`,
//     {
//       "title": title,
//       "content": content,
//       "search_string": search_string,
//       "status": status,
//       "priority": 1,
//       "categories": categories,
//       "tags": tags
//     },
//     {
//       headers: {
//         "Authorization": `Bearer ${token}`,
//       },
//       params: {
//         "id": edit_article_id
//       }
//     }
//   );
// };
function* putEditArticleApi(
  id: number,
  token: string,
  title: string,
  content: any,
  search_string: string,
  status: number,
  categories: Category[],
  tags: Tag[]
) {
  try {
    const result = yield axios.put(
      `${env_faq_url}/articles/${id}`,
      {
        title: title,
        content: content,
        search_string: search_string,
        status: status,
        priority: 1,
        categories: categories,
        tags: tags,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 記事既読登録
const readArticleApi = (token: string, id: number) => {
  return axios.post(
    `${env_faq_url}/articles/read`,
    {
      article_id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// 記事ストック取得
const getArticlesStocksApi = (
  token: string,
  user_id: number,
  articles_page_num?: number,
  paginate_per_page?: number,
  keyword?: string
) => {
  console.log("GET ARTICLES STOCK NEW");
  console.log("requsting articles_page_num", articles_page_num);
  console.log("requsting paginate_per_page", paginate_per_page);
  return axios.get(`${env_faq_url}/articles/stocks`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      user_id: user_id,
      page: articles_page_num,
      limit: paginate_per_page,
      keyword: keyword,
    },
  });
};

// 記事ストック登録
const postArticleStockApi = (token: string, id: number) => {
  return axios.post(
    `${env_faq_url}/articles/stocks`,
    {
      article_id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// 記事ストック情報削除
const deleteArticleStockApi = (token: string, id: number) => {
  return axios.delete(`${env_faq_url}/articles/stocks/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      article_id: id,
    },
  });
};

// 画像アップロード
const postArticleImageApi = (token: string) => {
  return axios.post(
    `${env_url}/articles/image`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// 重要記事数取得
const getPrioritisedArticleCountApi = (token: string) => {
  return axios.get(`${env_url}/articles/stocks`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// 新着記事数取得
const getNewArticleCountApi = (token: string) => {
  return axios.get(`${env_url}/articles/stocks`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// 新着記事数取得
const uploadArticleImageApi = (token: string, data: any) => {
  console.log("token", token);
  console.log("data", data);

  return axios.post(
    `${env_faq_url}/articles/image`,
    {
      file: data,
      file_name: "testfilename",
      account_id: "1",
      alt: "testalt",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    }
  );
};

export {
  getArticlesApi,
  getArticlesToShowApi,
  postArticleApi,
  getArticleApi,
  deleteArticleApi,
  putNewArticleApi,
  putEditArticleApi,
  readArticleApi,
  getArticlesStocksApi,
  postArticleImageApi,
  getPrioritisedArticleCountApi,
  getNewArticleCountApi,
  postArticleStockApi,
  deleteArticleStockApi,
  uploadArticleImageApi,
  getArticlesApiAfterPut,
};
