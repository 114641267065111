const GET_ARTICLES_REQUESTED = "GET_ARTICLES_REQUESTED";
const GET_ARTICLES_SUCCEEDED = "GET_ARTICLES_SUCCEEDED";
const GET_ARTICLES_FAILED = "GET_ARTICLES_FAILED";

const GET_ARTICLES_FOR_USER_REQUESTED = "GET_ARTICLES_FOR_USER_REQUESTED";
const GET_ARTICLES_FOR_USER_SUCCEEDED = "GET_ARTICLES_FOR_USER_SUCCEEDED";
const GET_ARTICLES_FOR_USER_FAILED = "GET_ARTICLES_FOR_USER_FAILED";

const GET_STOCKED_ARTICLES_REQUESTED = "GET_STOCKED_ARTICLES_REQUESTED";
const GET_STOCKED_ARTICLES_SUCCEEDED = "GET_STOCKED_ARTICLES_SUCCEEDED";
const GET_STOCKED_ARTICLES_FAILED = "GET_STOCKED_ARTICLES_FAILED";

const GET_ARTICLES_PRIORITY_COUNT_REQUESTED = "GET_ARTICLES_PRIORITY_COUNT_REQUESTED";
const GET_ARTICLES_PRIORITY_COUNT_SUCCEEDED = "GET_ARTICLES_PRIORITY_COUNT_SUCCEEDED";
const GET_ARTICLES_PRIORITY_COUNT_FAILED = "GET_ARTICLES_PRIORITY_COUNT_FAILED";

const GET_NEW_ARTICLES_COUNT_REQUESTED = "GET_NEW_ARTICLES_COUNT_REQUESTED";
const GET_NEW_ARTICLES_COUNT_SUCCEEDED = "GET_NEW_ARTICLES_COUNT_SUCCEEDED";
const GET_NEW_ARTICLES_COUNT_FAILED = "GET_NEW_ARTICLES_COUNT_FAILED";

const RESET_ARTCLE_DETAIL = "RESET_ARTCLE_DETAIL"

export {
  GET_ARTICLES_REQUESTED,
  GET_ARTICLES_SUCCEEDED,
  GET_ARTICLES_FAILED,

  GET_ARTICLES_FOR_USER_REQUESTED,
  GET_ARTICLES_FOR_USER_SUCCEEDED,
  GET_ARTICLES_FOR_USER_FAILED,
  
  GET_STOCKED_ARTICLES_REQUESTED,
  GET_STOCKED_ARTICLES_SUCCEEDED,
  GET_STOCKED_ARTICLES_FAILED,

  GET_ARTICLES_PRIORITY_COUNT_REQUESTED,
  GET_ARTICLES_PRIORITY_COUNT_SUCCEEDED,
  GET_ARTICLES_PRIORITY_COUNT_FAILED,

  GET_NEW_ARTICLES_COUNT_REQUESTED,
  GET_NEW_ARTICLES_COUNT_SUCCEEDED,
  GET_NEW_ARTICLES_COUNT_FAILED,

  RESET_ARTCLE_DETAIL,
}
