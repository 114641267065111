import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../mixins";
import { pc } from "../../mixins/mediaQuery";
import EventsTopItem from "./EventsTopItem";
import EventsTopMonthlyPlanRow from "./EventsTopMonthlyPlanRow";
import EventsTopAgriculturalPlanRow from "./EventsTopAgriculturalPlanRow";

function EventsTopList() {
  const event_list = useSelector((state: any) => state.events.events);
  const date_list = useSelector((state: any) => state.events_top.date_list);
  return (
    <EventsTopListSC>
      {date_list.map((date: any) => {
        const daily_events = event_list.filter((event: any) => {
          return (
            new Date(event.date).getFullYear() ===
              new Date(date).getFullYear() &&
            new Date(event.date).getMonth() === new Date(date).getMonth() &&
            new Date(event.date).getDate() === new Date(date).getDate()
          );
        });
        if (daily_events.length > 0) {
          return daily_events.map((event: any, index: number) => {
            return index === 0 ? (
              <EventsTopItem
                key={event.id}
                id={event.id}
                date={event.date}
                title={event.type.name}
                type_id={event.type.id}
                content={event.content}
                detail={event.detail}
                class_age={event.class}
                remand={event.rejects}
                remand_comments={event.rejects.comment}
                remand_user={event.rejects.user}
                remand_date={event.rejects.date}
                editing_status={event.status}
                approval_statuses={event.approval_statuses}
                additional_information={event.additional_information}
              />
            ) : (
              <EventsTopItem
                key={event.id}
                id={event.id}
                date=""
                title={event.type.name}
                type_id={event.type.id}
                content={event.content}
                detail={event.detail}
                class_age={event.class}
                remand={event.rejects}
                remand_comments={event.rejects.comment}
                remand_user={event.rejects.user}
                remand_date={event.rejects.date}
                editing_status={event.status}
                approval_statuses={event.approval_statuses}
                additional_information={event.additional_information}
              />
            );
          });
        } else {
          return (
            <EventsTopItem
              key={date}
              id=""
              date={date}
              title=""
              type_id=""
              content=""
              detail=""
              class_age=""
              remand=""
              remand_comments=""
              remand_user=""
              remand_date=""
              approval_statuses={[]}
            />
          );
        }
      })}
      <EventsTopMonthlyPlanRow />
      <EventsTopAgriculturalPlanRow />
    </EventsTopListSC>
  );
}

const EventsTopListSC = styled.ul`
  width: 100%;
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 0px 0px 4px 4px;
    &:first-of-type {
      border-top: none;
    }
  `}
`;

export default EventsTopList;
