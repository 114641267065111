import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHistoriesRequest } from "../../../store/manual/histories/actions";
import { historiesSelector } from "store/manual/histories/selectors";
import { getManualTagsRequest } from "store/manual/tags/actions";
import { manualTagsSelector } from "store/manual/tags/selector";

export const useTopHooks = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const histories = useSelector(historiesSelector);
  const tags = useSelector(manualTagsSelector);

  React.useEffect(() => {
    dispatch(getHistoriesRequest(token));
  }, [dispatch, token]);

  React.useEffect(() => {
    dispatch(getManualTagsRequest({ token }));
  }, [dispatch, token]);

  return {
    histories,
    tags
  };
};
