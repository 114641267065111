import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

import * as serviceWorker from "./serviceWorker";
import {
  clearSystemCache,
  isLoggedInTurnToFalse,
  logoutRequest
} from "./store/session/action";
import { useHistory } from "react-router-dom";

const ServiceWorkerWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state: any) => state.session.token);
  const isLoggedIn = useSelector((state: any) => state.session.isLoggedIn);
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    console.log("showReload");
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    console.log("serviceWorker.register");
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    // 1. ログアウトAPIを叩き、isLoggedInのをfalseにする
    if (isLoggedIn) {
      dispatch(logoutRequest(token));
    } else {
      // 1. ログインフラグが立っていなくても、isLoggedInをfalseにする（APIは叩かない）
      dispatch(isLoggedInTurnToFalse());
    }
    // 3. ログインページに戻る
    history.push("/signin");
    // 4. snackbarを消す
    setShowReload(false);
    // 5. ローカルストレージの値をすべて削除
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    window.localStorage.clear();
    // 6. Reduxストアをクリアする
    dispatch(clearSystemCache(true));
    // 7. SWのactivateをかける
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
  };

  return (
    <Snackbar
      open={showReload}
      message="新バージョンが公開されました"
      // onClick={reloadPage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          更新
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
