import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";

import { toggleDeleteEventModal } from "../../../../store/common/modal/action";
import {
  deleteEventRequest,
  resetDeleteItemArray
} from "../../../../store/event/events/action";
import { deleteMonthlyEventsRequest } from "../../../../store/event/monthly_events/action";
import { resetMonthlyEventItemToDeleteArray } from "../../../../store/event/monthly_event_delete/action";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

export default function DeleteEventModal() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );

  const deleteItemArray = useSelector(
    (state: any) => state.events.deleteItemArray
  );

  const monthly_item_to_delete_array = useSelector(
    (state: any) => state.monthly_event_delete.to_delete_array
  );

  // 削除ボタンクリック
  const handleClickDeleteButton = () => {
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));

    deleteItemArray.forEach((id: number) => {
      if (filtered_months.includes("すべて")) {
        dispatch(
          deleteEventRequest(token, id, facility_id, filtered_year, [
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "1",
            "2",
            "3"
          ])
        );
      } else {
        dispatch(
          deleteEventRequest(
            token,
            id,
            facility_id,
            filtered_year,
            filtered_months
          )
        );
      }
    });
    monthly_item_to_delete_array.forEach((id: number) => {
      dispatch(deleteMonthlyEventsRequest(token, id));
    });
    dispatch(toggleDeleteEventModal(false));
    dispatch(resetDeleteItemArray());
    dispatch(resetMonthlyEventItemToDeleteArray());
  };

  return (
    <DeleteEventModalContentSC>
      <TitleBlock>行事予定削除</TitleBlock>
      <ContentBlock>
        <ConfirmText>
          選択した行事予定を削除します。よろしいですか？
        </ConfirmText>
        <Buttons>
          <Button onClick={() => dispatch(toggleDeleteEventModal(false))}>
            キャンセル
          </Button>
          <Button className="delete" onClick={handleClickDeleteButton}>
            削除
          </Button>
        </Buttons>
      </ContentBlock>
    </DeleteEventModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const DeleteEventModalContentSC = styled.div`
  max-width: 440px;
  width: 90%;
  height: 198px;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.h1`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  font-weight: 300;
  padding: 20px 24px;
  font-size: 16px;
  color: ${color.gray};
  border-radius: 6px 6px 0 0;
`;

const ContentBlock = styled.div`
  text-align: center;
  padding: 30px 54px;
`;

const ConfirmText = styled.p`
  font-size: 14px;
  color: ${color.gray};
  padding-bottom: 30px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  &.delete {
    color: ${color.white};
    background-color: ${color.gray};
  }
`;
