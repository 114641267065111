import {
  SET_TO_BE_UPLOADED_CSV,
  TOGGLE_TO_BE_REFLECTED_ALL_FACILITIES,
  CSV_UPLOAD_REQUESTED,
  CSV_UPLOAD_SUCCEEDED,
  CSV_UPLOAD_FAILED,
  TOGGLE_UPLOAD_SUCCEEDED,
  SET_TARGET_YEAR
} from "./type";
export const setTargetYear = (year?: number) => {
  return {
    type: SET_TARGET_YEAR,
    year
  };
};
export const setToBeUploadedCsv = (
  fileName: string | undefined,
  facility_id: number,
  file_data: File | null,
  year?: number
) => {
  return {
    type: SET_TO_BE_UPLOADED_CSV,
    fileName,
    facility_id,
    file_data,
    year
  };
};

export const toggleToBeReflectedAllFacilities = (isAll: boolean) => {
  return {
    type: TOGGLE_TO_BE_REFLECTED_ALL_FACILITIES,
    isAll
  };
};

export const toggleUploadSucceeded = (upload_succeeded: boolean) => {
  return {
    type: TOGGLE_UPLOAD_SUCCEEDED,
    upload_succeeded
  };
};

export const csvUploadRequest = (token: string, formData: FormData) => {
  return {
    type: CSV_UPLOAD_REQUESTED,
    token,
    formData
  };
};
