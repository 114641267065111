import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../mixins";
import { pc } from "../../mixins/mediaQuery";
import ApprovalItem from "./ApprovalItem";
import AgriculturalPlanItem from "./AgriculturaPlanItemForApproval";
import MonthlyPlanItem from "./MonthlyPlanItemForApproval";

function ApprovalList() {
  const eventList = useSelector((state: any) => state.events.events);
  const dateList = useSelector((state: any) => state.event_search.date_list);
  const year = useSelector((state: any) => state.event_search.filtered_year);
  const selected_month = useSelector(
    (state: any) => state.approval.selected_month
  );
  const isSearchByEventType = useSelector(
    (state: any) => state.event_search.is_search_by_event_type
  );
  let dateListToUse: any;
  // 承認状況から月を選択して飛んできた場合
  if (selected_month !== null) {
    const month = selected_month.slice(5, 7);
    //選択された月が、1月、2月、3月のいずれかだった場合、指定する年は現在の検索条件より1プラスして取得する。
    const firstDate: any =
      month === "01" || month === "02" || month === "03"
        ? new Date(year + 1, month - 1, 1)
        : new Date(year, month - 1, 1);
    const lastDate: any =
      month === "01" || month === "02" || month === "03"
        ? new Date(year + 1, month, 0)
        : new Date(year, month, 0);
    const interval = (lastDate - firstDate) / 86400000 + 1;
    dateListToUse = [...Array(interval)].map((_, index) => {
      return new Date(
        firstDate.getFullYear(),
        firstDate.getMonth(),
        firstDate.getDate() + index
      );
    });
    // サイドバーからきた場合
  } else {
    dateListToUse = dateList;
  }

  return (
    <ApprovalListSC>
      {dateListToUse.map((date: any) => {
        const dailyEvents = eventList.filter(
          (event: any) =>
            new Date(event.date).getFullYear() ===
              new Date(date).getFullYear() &&
            new Date(event.date).getMonth() === new Date(date).getMonth() &&
            new Date(event.date).getDate() === new Date(date).getDate()
        );

        const current_day = new Date(date).getTime();
        const last_date = new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth() + 1,
          0
        ).getTime();

        // 月末かどうかを判定
        if (current_day === last_date) {
          //その日にイベントは存在しているかを判定
          if (dailyEvents.length > 0) {
            return dailyEvents.map((event: any, index: number) => {
              return (
                // 月末にイベントが複数個存在していた場合
                dailyEvents.length > 1 ? (
                  // 最初の要素には日付を表示し、月間農業計画は表示しない
                  index === 0 ? (
                    <>
                      <ApprovalItem
                        key={event.id}
                        id={event.id}
                        date_to_show={event.date}
                        date={event.date}
                        title={event.type.name}
                        type_id={event.type.id}
                        content={event.content}
                        detail={event.detail}
                        class_age={event.class}
                        remand={event.rejects}
                        remand_comments={event.rejects.comment}
                        remand_user={event.rejects.user}
                        remand_date={event.rejects.date}
                        editing_status={event.status}
                        approval_statuses={event.approval_statuses}
                        additional_information={event.additional_information}
                      />
                    </>
                  ) : index + 1 === dailyEvents.length ? (
                    // 最後の要素だった場合は、日付は表示せず月間農業計画を表示する
                    <>
                      <ApprovalItem
                        key={event.id}
                        id={event.id}
                        date_to_show={event.date}
                        date=""
                        title={event.type.name}
                        type_id={event.type.id}
                        content={event.content}
                        detail={event.detail}
                        class_age={event.class}
                        remand={event.rejects}
                        remand_comments={event.rejects.comment}
                        remand_user={event.rejects.user}
                        remand_date={event.rejects.date}
                        editing_status={event.status}
                        approval_statuses={event.approval_statuses}
                        additional_information={event.additional_information}
                      />
                      <MonthlyPlanItem date={event.date} />
                      <AgriculturalPlanItem date={event.date} />
                    </>
                  ) : (
                    //月末の要素の最初でも最後でもない要素の時は日付も月間農業計画も表示しない
                    <>
                      <ApprovalItem
                        key={event.id}
                        id={event.id}
                        date_to_show={event.date}
                        date=""
                        title={event.type.name}
                        type_id={event.type.id}
                        content={event.content}
                        detail={event.detail}
                        class_age={event.class}
                        remand={event.rejects}
                        remand_comments={event.rejects.comment}
                        remand_user={event.rejects.user}
                        remand_date={event.rejects.date}
                        editing_status={event.status}
                        approval_statuses={event.approval_statuses}
                        additional_information={event.additional_information}
                      />
                    </>
                  )
                ) : (
                  //月末にイベントが一つのみ存在しない時は、日付も月間農業計画も表示する
                  <>
                    <ApprovalItem
                      key={event.id}
                      id={event.id}
                      date_to_show={event.date}
                      date={event.date}
                      title={event.type.name}
                      type_id={event.type.id}
                      content={event.content}
                      detail={event.detail}
                      class_age={event.class}
                      remand={event.rejects}
                      remand_comments={event.rejects.comment}
                      remand_user={event.rejects.user}
                      remand_date={event.rejects.date}
                      editing_status={event.status}
                      approval_statuses={event.approval_statuses}
                      additional_information={event.additional_information}
                    />
                    <MonthlyPlanItem date={event.date} />
                    <AgriculturalPlanItem date={event.date} />
                  </>
                )
              );
            });
          } else {
            //存在してない場合は日付のみを返す
            if (isSearchByEventType) {
              return "";
            } else {
              return (
                <>
                  <ApprovalItem
                    key={date}
                    id=""
                    date={date}
                    title=""
                    type_id=""
                    content=""
                    detail=""
                    class_age=""
                    remand=""
                    remand_comments=""
                    remand_user=""
                    remand_date=""
                    approval_statuses={[]}
                  />
                  <MonthlyPlanItem date={date} />
                  <AgriculturalPlanItem date={date} />
                </>
              );
            }
          }
          //末日ではない場合
        } else {
          //イベントが存在しているかどうか判定
          if (dailyEvents.length > 0) {
            return dailyEvents.map((event: any, index: number) => {
              return (
                //最初の要素だった場合日付を表示
                index === 0 ? (
                  <ApprovalItem
                    key={event.id}
                    id={event.id}
                    date_to_show={event.date}
                    date={event.date}
                    title={event.type.name}
                    type_id={event.type.id}
                    content={event.content}
                    detail={event.detail}
                    class_age={event.class}
                    remand={event.rejects}
                    remand_comments={event.rejects.comment}
                    remand_user={event.rejects.user}
                    remand_date={event.rejects.date}
                    editing_status={event.status}
                    approval_statuses={event.approval_statuses}
                    additional_information={event.additional_information}
                  />
                ) : (
                  //そうではない時は日付を表示しない
                  <ApprovalItem
                    key={event.id}
                    id={event.id}
                    date_to_show={event.date} // 同じ日付に複数個あった場合の、1個目以外の行事予定にはdate_to_showで差し戻しモーダルにわたす日付情報を受け渡し
                    date=""
                    title={event.type.name}
                    type_id={event.type.id}
                    content={event.content}
                    detail={event.detail}
                    class_age={event.class}
                    remand={event.rejects}
                    remand_comments={event.rejects.comment}
                    remand_user={event.rejects.user}
                    remand_date={event.rejects.date}
                    editing_status={event.status}
                    approval_statuses={event.approval_statuses}
                    additional_information={event.additional_information}
                  />
                )
              );
            });
          } else {
            if (isSearchByEventType) {
              return "";
            } else {
              //存在していない場合日付を返す。
              return (
                <ApprovalItem
                  key={date}
                  id=""
                  date={date}
                  title=""
                  type_id=""
                  content=""
                  detail=""
                  class_age=""
                  remand=""
                  remand_comments=""
                  remand_user=""
                  remand_date=""
                  approval_statuses={[]}
                />
              );
            }
          }
        }
      })}
    </ApprovalListSC>
  );
}

export default ApprovalList;

// ===================================================================================
// スタイル
// ===================================================================================
const ApprovalListSC = styled.ul`
  width: 100%;
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 0px 0px 4px 4px;
    &:first-of-type {
      border-top: none;
    }
  `}
`;
