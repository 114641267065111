import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../mixins";
import Slider from "react-slick";
import { ManualData } from "../demoData";
import { pc } from "components/mixins/mediaQuery";
interface HeroContainerProps {
  data: ManualData[];
}

const HeroContainer: React.FC<HeroContainerProps> = props => {
  const { data } = props;

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "round",
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <SliderWrapperSC>
      <Slider {...settings}>
        {data.map((manual, index) => {
          return (
            <div key={index}>
              <Link to={`/manual/manuals/${manual.id}`}>
                <Bg style={{ backgroundImage: `url(${manual.image})` }}>
                  <ContentsSC>
                    <WrapperSC>
                      <CatchSC>{manual.catch}</CatchSC>
                      <GuideSC>{manual.name}はこちら</GuideSC>
                    </WrapperSC>
                  </ContentsSC>
                </Bg>
              </Link>
            </div>
          );
        })}
      </Slider>
    </SliderWrapperSC>
  );
};

export default HeroContainer;

// =========================================================
// styles
// =========================================================

const SliderWrapperSC = styled.div`
  width: 100%;
  max-width: 1190px;
  border-radius: 8px;
  overflow: hidden;
  margin: auto 0;
  .round {
    border-radius: 8px;
  }
  .slick-dots {
    bottom: 50px;
    right: 20px;
    max-width: 350px;
    display: flex !important;
    justify-content: flex-end;
    flex-wrap: wrap;
    li {
      button {
        &:before {
          color: #fcd900;
        }
      }
    }
  }
`;

const Bg = styled.div`
  width: 100%;
  max-width: 1190px;
  padding-top: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: relative;
  margin-bottom: 30px;
  &::before {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    border-radius: 8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
  }
  &:hover {
    opacity: 0.6;
  }
`;

const ContentsSC = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  ${pc`
    padding-top: 24px;
  `}
`;

const WrapperSC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const CatchSC = styled.h2`
  font-size: 18px;
  color: ${color.white};
  ${pc`
    font-size: 48px;
    margin-bottom: 120px;
    margin-top: 0;
  `}
`;

const GuideSC = styled.p`
  display: none;
  font-size: 24px;
  color: ${color.white};
  font-weight: bold;
  ${pc`
    display: block;
  `}
`;
