import { GET_ARTICLES_STOCKS_REQUESTED } from "./type";

// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// ================================================================= //
// Actions
// ----------------------------------------------------------------- //

// ストック記事一覧情報登録
export const getArticlesStocksRequest = (token: string, user_id: number, articles_page_num?: number, paginate_per_page?: number, keyword?: string) => {
  return {
    type: GET_ARTICLES_STOCKS_REQUESTED,
    token,
    user_id,
    articles_page_num,
    paginate_per_page,
    keyword
  };
};
