import { useState, useCallback, ChangeEvent } from "react";

export const useTextInput = (init: string = "") => {
  const [value, setValue] = useState(init);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );
  return {
    value,
    onChange: handleChange
  };
};
