export const contactValueGen = (urlParam: string) => {
  console.log();
  switch (urlParam) {
    case "it":
      return 1;
    case "unei":
      return 2;
    case "jinji-somu":
      return 3;
    case "uncertain":
      return 4;
    default:
      return null;
  }
};
export const kindValueGen = (urlParam: string) => {
  console.log();
  switch (urlParam) {
    case "staff":
      return 1;
    case "customer":
      return 2;
    case "other":
      return 3;
    case "attendance":
      return 4;
    case "salary-bonus":
      return 5;
    case "year-end-adjustment":
      return 6;
    default:
      return null;
  }
};
