import React from "react";
import styled from "styled-components";
import { ManualData, typesDemo } from "../demoData";
import ManualsItem from "./ManualsItem";
import ManualsAddItem from "./ManualsAddItem";
import color from "../../mixins/color";
import { pc } from "../../mixins/mediaQuery";
import { newTypes } from "./ManualsBlock";
import { ManualItemForSelector } from "store/manual/manuals/types.d";

interface Props {
  types: ManualItemForSelector[];
  add_data?: newTypes[];
}

const ManualsList = (props: Props) => {
  const { types, add_data } = props;
  return (
    <ManualsListSC>
      {/* 既存のデータ */}
      {types.map((value, index) => {
        const { id, name, updated_at } = value;
        return (
          <ManualsItem
            key={id}
            manual_id={id || 1}
            name={name}
            updated_at={updated_at || ""}
          />
        );
      })}
      {/* 新規作成のデータ */}
      {add_data?.map((value, index) => {
        return <ManualsAddItem name={value.name} />;
      })}
    </ManualsListSC>
  );
};

export default ManualsList;

const ManualsListSC = styled.ul`
  border-top: 1px solid ${color.list_border};
  ${pc`
    border-radius: 4px;
    border: 1px solid ${color.list_border};
  `}
  li {
    &:last-child {
      border-bottom: none;
    }
  }
`;
