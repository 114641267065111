import { GET_NEWS_LIST_REQUESTED, GET_NEWS_LIST_SUCCEEDED, GET_NEWS_LIST_FAILED } from './type'

// 管理者、編集者
export const getNewsListRequest = (token: string, page: number, limit: number, pathName: string) => {
  return {
    type: GET_NEWS_LIST_REQUESTED,
    token,
    page,
    limit,
    pathName
  }
}

// ユーザー
export const getNewsListForUserRequest = (token: string, page: number, limit: number, pathName: string) => {
  return {
    type: GET_NEWS_LIST_REQUESTED,
    token,
    page,
    limit,
    pathName
  }
}