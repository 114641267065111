import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  TOGGLE_MONTHLY_EVENT_ITEM_TO_DELETE,
  RESET_MONTHLY_EVENT_ITEM_TO_DELETE_ARRAY,
} from "./type";

const initialState = {
  to_delete_array: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_MONTHLY_EVENT_ITEM_TO_DELETE:
      if (
        state.to_delete_array.some(
          (to_delete_item_id: number) =>
            to_delete_item_id === action.monthly_event_item_to_delete_id
        )
      ) {
        return {
          ...state,
          to_delete_array: state.to_delete_array.filter(
            (to_delete_item_id: number) =>
              to_delete_item_id !== action.monthly_event_item_to_delete_id
          ),
        };
      } else {
        return {
          ...state,
          to_delete_array: [
            ...state.to_delete_array,
            action.monthly_event_item_to_delete_id,
          ],
        };
      }

    case RESET_MONTHLY_EVENT_ITEM_TO_DELETE_ARRAY:
      return {
        ...state,
        to_delete_array: [],
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
