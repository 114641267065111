import {
  SET_DAILY_LIST_FOR_TOP,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED,
  GET_USAGE_GUIDES_REQUESTED,
  GET_CALENDAR_REQUESTED
} from "./type";

export const setDailyListForTop = (year: number, month: string) => {
  return {
    type: SET_DAILY_LIST_FOR_TOP,
    year,
    month
  };
};

export const getEventsForAnnualScheduleRequest = (
  token: string,
  facility_id: number,
  filtered_year: number,
  filtered_remand: string
) => {
  return {
    type: GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED,
    token,
    facility_id,
    filtered_year,
    filtered_remand
  };
};

export const getUsageGuidesRequest = (token: string, year: number) => {
  return {
    type: GET_USAGE_GUIDES_REQUESTED,
    token,
    year
  };
};

export const getCalendarRequest = (
  token: string,
  facility_id: number,
  year: number
) => {
  return {
    type: GET_CALENDAR_REQUESTED,
    token,
    facility_id,
    year
  };
};
