import React from "react";

export interface SvgArrowBottomDomProps {
  color?: string;
}

export const SvgArrowBottomDom = (props: SvgArrowBottomDomProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.828"
      height="7.828"
      viewBox="0 0 12.828 7.828"
    >
      <path
        id="パス_35"
        data-name="パス 35"
        d="M5,0,0,5l5,5"
        transform="translate(1.414 6.414) rotate(-90)"
        fill="none"
        stroke={props.color ? props.color : "#888"}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};
