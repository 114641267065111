import {
  BEGIN_SESSION_REQUESTED,
  END_SESSION_REQUESTED,
  GOOGLE_LOGIN_REQUESTED,
  TOGGLE_SEARCH_ACCOUNT_IS_OPENED,
  TOGGLE_SEARCH_ARTICLE_IS_OPENED,
  HANDLE_CLEAR_SEARCH_TERMS,
  CLEAR_SYSTEM_CACHE,
  TOGGLE_CACHE_CLEARED,
  TOGGLE_TO_CLEAR_CACHE,
  RESET_RESPONSE_STATUS,
  MAINTENANCE_ON_REQUESTED,
  SYSTEM_UP_REQUESTED,
  GET_STATUS_REQUESTED,
  LOG_OUT_REQUESTED,
  IS_LOGGED_IN_TURN_TO_FALSE,
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "./type";

export const beginSessionRequest = (email: string, password: string) => {
  return {
    type: BEGIN_SESSION_REQUESTED,
    email,
    password
  };
};

export const endSessionRequest = (token: string) => {
  return {
    type: END_SESSION_REQUESTED,
    token
  };
};

export const googleLoginRequest = (code: string) => {
  return {
    type: GOOGLE_LOGIN_REQUESTED,
    code
  };
};

export const toggleSearchAccountIsOpened = (isOpened: boolean) => {
  return {
    type: TOGGLE_SEARCH_ACCOUNT_IS_OPENED,
    isOpened
  };
};

export const toggleSearchArticleIsOpened = (isOpened: boolean) => {
  return {
    type: TOGGLE_SEARCH_ARTICLE_IS_OPENED,
    isOpened
  };
};

export const handleClearSearchTerms = (target_term: string) => {
  return {
    type: HANDLE_CLEAR_SEARCH_TERMS,
    target_term
  };
};

export const clearSystemCache = (sw?: boolean) => {
  return {
    type: CLEAR_SYSTEM_CACHE,
    sw
  };
};

export const toggleCacheCleared = (cache_cleared: boolean) => {
  return {
    type: TOGGLE_CACHE_CLEARED,
    cache_cleared
  };
};

export const toggleToClearCache = (to_clear_cache: boolean) => {
  return {
    type: TOGGLE_TO_CLEAR_CACHE,
    to_clear_cache
  };
};

export const resetResponseStatus = () => {
  return {
    type: RESET_RESPONSE_STATUS
  };
};

export const getSystemStatusRequest = (token: string) => {
  return {
    type: GET_STATUS_REQUESTED,
    token
  };
};

export const maintenanceOnRequest = (token: string, system_status: number) => {
  return {
    type: MAINTENANCE_ON_REQUESTED,
    token,
    system_status
  };
};

export const systemUpRequest = (token: string, system_status: number) => {
  return {
    type: SYSTEM_UP_REQUESTED,
    token,
    system_status
  };
};

export const logoutRequest = (token: string) => {
  return {
    type: LOG_OUT_REQUESTED,
    token
  };
};
export const isLoggedInTurnToFalse = () => {
  return {
    type: IS_LOGGED_IN_TURN_TO_FALSE
  };
};

export const responseStatus401 = () => {
  return {
    type: RESPONSE_STATUS_401
  };
};

export const loginStatus401 = () => {
  return {
    type: LOGIN_STATUS_401
  };
};

export const responseStatus403 = () => {
  return {
    type: RESPONSE_STATUS_403
  };
};

export const responseStatus404 = () => {
  return {
    type: RESPONSE_STATUS_404
  };
};

export const responseStatus422 = () => {
  return {
    type: RESPONSE_STATUS_422
  };
};

export const responseStatus500 = () => {
  return {
    type: RESPONSE_STATUS_500
  };
};

export const resetState = () => {
  return {
    type: "RESET_STATE"
  };
};
