import React from "react";
import styled from "styled-components";
import { toggleRightSection } from "../../store/common/sidebar/action";
import { useSelector, useDispatch } from "react-redux";
import { RightSectionToggler } from "../styles/layout/containers";
import FacilitySelectNav from "components/Common/FacilitySelectNav/FacilitySelectNav";
import FacilityHeading from "components/Common/FacilityHeading";
import { pc } from "components/mixins/mediaQuery";

interface FacilityHeaderProps {
  toggleHidden?: boolean;
}
const FacilityHeader = (props: FacilityHeaderProps) => {
  const dispatch = useDispatch();

  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  return (
    <Container>
      <FacilityHeading />
      <ButtonSection>
        <FacilitySelectNav />
        {!props.toggleHidden && (
          <RightSectionToggler
            className={`event ${rightSectionOpen ? "open" : "closed"}`}
            onClick={() => dispatch(toggleRightSection())}
          />
        )}
      </ButtonSection>
    </Container>
  );
};

export default FacilityHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 8px;
  margin: 0 30px 30px;
  border-bottom: 1px solid #bbbbbb;
  ${pc`
      width: 100%;
    `}
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
