import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import ApprovalRequestModalContent from "./ApprovalRequestModalContent";

import { toggleApprovalRequestModal } from "../../../../store/common/modal/action";

export default function ReturnEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <ApprovalRequestModalBg
        onClick={() => dispatch(toggleApprovalRequestModal(false))}
      ></ApprovalRequestModalBg>
      <ApprovalRequestModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ApprovalRequestModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
