import { ViewMode } from "components/Camera/type";
import React from "react";
import Table from "../../images/icon_list_table.svg";
import TableCurrent from "../../images/icon_list_table_current.svg";
import Thumbnail from "../../images/icon_list_thumbnail.svg";
import ThumbnailCurrent from "../../images/icon_list_thumbnail_current.svg";
import { BtnWrapperSC } from "./style";

export const ListViewToggle = (props: {
  viewMode: ViewMode;
  onToggleViewType: (viewType: ViewMode) => void;
}) => {
  const { viewMode, onToggleViewType } = props;
  const handleChangeViewTypeList = () => {
    onToggleViewType("list");
  };
  const handleChangeViewTypeThumbnail = () => {
    onToggleViewType("thumbnail");
  };
  return (
    <BtnWrapperSC>
      <button onClick={handleChangeViewTypeList}>
        <img
          src={viewMode === "list" ? TableCurrent : Table}
          alt="リスト表示"
        />
      </button>
      <button onClick={handleChangeViewTypeThumbnail}>
        <img
          src={viewMode === "thumbnail" ? ThumbnailCurrent : Thumbnail}
          alt="タイル表示"
        />
      </button>
    </BtnWrapperSC>
  );
};
