import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import Sw from "./Sw";
import "./App.css";
import SignIn from "./components/auth/SignIn";
import {
  MainSC,
  SiteContainerSC,
  LayoutContainerSC,
  MainContainerSC
} from "./components/styles/layout/containers";
//========================================================================================================
// Modals
//========================================================================================================
import ArticleDeleteModal from "./components/Faq/Articles/ArticleEdit/ArticleDeleteModal";
import DeleteEventsConfirmModal from "./components/Events/Events/Modal/DeleteEventsConfirmModal";
import AddEventModal from "./components/Events/Events/Modal/AddEventModal";
import EditEventModal from "./components/Events/Events/Modal/EditEventModal";
import AddMonthlyEventModal from "./components/Events/Events/Modal/AddMonthlyEventModal";
import EditMonthlyEventModal from "./components/Events/Events/Modal/EditMonthlyEventModal";
import ServiceSelectModal from "./components/Common/ServiceSelectNav/Modal/ServiceSelectModal";
import FacilitySelectModal from "./components/Common/FacilitySelectNav/Modal/FacilitySelectModal";
// import AnnualEventScheduleModal from "./components/Events/Top/annualEventSchedule/AnnualEventScheduleModal";
import AnnualEventScheduleModal from "./components/Events/Top/annualEventScheduleNew/AnnualEventScheduleModal";
import CSVDownloadRejectModal from "./components/Events/Top/Modal/CSVDownloadRejectModal";
import ApprovalRequestModal from "./components/Events/Events/Modal/ApprovalRequestModal";
import AddApprovalModalEventsModal from "./components/Events/Approval/Modal/AddApprovalEventsModal";
import ErrorApprovalEventsModal from "./components/Events/Approval/Modal/ErrorApprovalEventsModal";
import ReturnEventsModal from "./components/Events/Approval/Modal/ReturnEventsModal";
import DeleteReturnEventsModal from "./components/Events/Approval/Modal/DeleteReturnEventsModal";
import NewsDeleteModal from "./components/Global/News/NewsEdit/NewsDeleteModal";

//=============
// ErrorPages
//========================================================================================================
import Error401 from "./components/Global/Errors/401";
import Error403 from "./components/Global/Errors/403";
import Error404 from "./components/Global/Errors/404";
import Error422 from "./components/Global/Errors/422";
import Error500 from "./components/Global/Errors/500";
import Maintenance from "./components/Global/Errors/Maintenance";
//========================================================================================================
// Routes
//========================================================================================================
import AdminRoutes from "./routes/Admin";
import HonbuRoutes from "./routes/Honbu";
import ProjectManagerRoutes from "./routes/ProjectManager";
import FacilityManagerRoutes from "./routes/FacilityManager";
import GeneralEditorRoutes from "./routes/GeneralEditor";
import GeneralViewerRoutes from "./routes/GeneralViewer";
import CancelAllEventsModal from "./components/Events/Approval/Modal/CancelAllEventsModal";
import CancelYearMonthsEventsModal from "./components/Events/Approval/Modal/CancelYearMonthsEventsModal";

//========================================================================================================
// Modules
//========================================================================================================
import ResetResponseErrorModule from "./ResetResponseErrorModule";
import MaintenanceStatusCheckModule from "./components/Common/MaintenanceStatusCheckModule";
import LoadingModule from "./components/Common/LoadingModule";

// action
import { clearSystemCache, toggleCacheCleared } from "./store/session/action";
import SidebarRoute from "routes/SidebarRoute";
import LoggedInValidate from "routes/LoggedInValidate";
import Header from "components/Common/Header/Header";
import { RootState } from "store/store";

function App({ history }: { history: any }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.session.isLoggedIn);
  const inquiry_new_post_status = useSelector(
    (state: any) => state.inquiry_new.post_status
  );
  const sidebarIsOpened = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const service_modal_open = useSelector(
    (state: any) => state.modal.service_modal_open
  );
  const facility_modal_open = useSelector(
    (state: any) => state.modal.facility_modal_open
  );
  const already_approved_error_modal_open = useSelector(
    (state: any) => state.modal.already_approved_error_modal_open
  );
  const delete_news_modal_open = useSelector(
    (state: any) => state.modal.delete_news_modal_open
  );
  const delete_article_modal_open = useSelector(
    (state: any) => state.modal.delete_article_modal_open
  );
  const delete_event_modal_open = useSelector(
    (state: any) => state.modal.delete_event_modal_open
  );
  const add_event_modal_open = useSelector(
    (state: any) => state.modal.add_event_modal_open
  );
  const edit_event_modal_open = useSelector(
    (state: any) => state.modal.edit_event_modal_open
  );
  const add_monthly_event_modal_open = useSelector(
    (state: any) => state.modal.add_monthly_event_modal_open
  );
  const edit_monthly_event_modal_open = useSelector(
    (state: any) => state.modal.edit_monthly_event_modal_open
  );
  const return_event_modal_open = useSelector(
    (state: any) => state.modal.return_event_modal_open
  );
  const delete_return_event_modal_open = useSelector(
    (state: any) => state.modal.delete_return_event_modal_open
  );
  const monthly_approve_modal_open = useSelector(
    (state: any) => state.modal.monthly_approval_modal_open
  );
  const annual_event_schedule_modal_open = useSelector(
    (state: any) => state.modal.annual_event_schedule_modal_open
  );
  const csv_download_reject_modal_open = useSelector(
    (state: any) => state.modal.csv_download_reject_modal_open
  );
  const approval_request_modal_open = useSelector(
    (state: any) => state.modal.approval_request_modal_open
  );
  const cancel_all_events_modal_open = useSelector(
    (state: any) => state.modal.cancel_all_events_modal_open
  );
  const system_status = useSelector(
    (state: any) => state.session.system_status
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const cancel_year_monthly_events_modal_open = useSelector(
    (state: any) => state.modal.cancel_year_monthly_events_modal_open
  );

  const session = useSelector((state: any) => state.session);
  const session_position = useSelector((state: any) => state.session.position);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const isImgLoading = useSelector(
    (state: RootState) => state.mediafiles.imgLoading
  );
  const response_status = useSelector(
    (state: any) => state.session.response_status
  );

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    setInterval(() => {
      dispatch(toggleCacheCleared(false));
      window.location.reload(true);
    }, 2000);
  };

  if (
    session === undefined ||
    session === null ||
    session_position === undefined ||
    session_position === null
  ) {
    dispatch(clearSystemCache());
    dispatch(toggleCacheCleared(true));
    refreshCacheAndReload();
  }

  const position_id = useSelector((state: any) => state.session.position.id);

  // check for sw updates on page change
  useEffect(() => {
    history.listen((location: any, action: any) => {
      navigator.serviceWorker
        .getRegistrations()
        .then(regs => regs.forEach(reg => reg.update()));
    });
  }, [history]);
  const responseIsValid = response_status === 200;

  useEffect(() => {
    console.log(isLoading);
  }, [isLoading]);

  return (
    <ConnectedRouter history={history}>
      <Sw />
      {isLoading || isImgLoading ? <LoadingModule /> : <></>}
      <div className="App" style={{ background: "#F2F2F2" }}>
        {/* サービス選択モーダル */}
        {service_modal_open && <ServiceSelectModal />}
        {/* 園選択モーダル */}
        {facility_modal_open && <FacilitySelectModal />}
        {/* FAQ記事削除モーダル */}
        {delete_article_modal_open && <ArticleDeleteModal />}
        {/* お知らせ削除モーダル */}
        {delete_news_modal_open && <NewsDeleteModal />}
        {/* イベント削除モーダル */}
        {delete_event_modal_open && <DeleteEventsConfirmModal />}
        {/* イベント追加モーダル */}
        {add_event_modal_open && <AddEventModal />}
        {/* イベント編集モーダル */}
        {edit_event_modal_open && <EditEventModal />}
        {/* 月間計画・農業計画追加モーダル */}
        {add_monthly_event_modal_open && <AddMonthlyEventModal />}
        {/* 月間計画・農業計画編集モーダル */}
        {edit_monthly_event_modal_open && <EditMonthlyEventModal />}
        {/* 承認エラーモーダル */}
        {already_approved_error_modal_open && <ErrorApprovalEventsModal />}
        {/* 差戻しモーダル */}
        {return_event_modal_open && <ReturnEventsModal />}
        {/* 差戻し取り消しモーダル */}
        {delete_return_event_modal_open && <DeleteReturnEventsModal />}
        {/* 月別承認モーダル */}
        {monthly_approve_modal_open && <AddApprovalModalEventsModal />}
        {/* 年間行事予定モーダル */}
        {annual_event_schedule_modal_open && <AnnualEventScheduleModal />}
        {/* CSVダウンロードエラーモーダル */}
        {csv_download_reject_modal_open && <CSVDownloadRejectModal />}
        {/* 承認申請モーダル */}
        {approval_request_modal_open && <ApprovalRequestModal />}
        {/* 一括取り消しモーダル */}
        {cancel_all_events_modal_open && <CancelAllEventsModal />}
        {/* 取り消しモーダル */}
        {cancel_year_monthly_events_modal_open && (
          <CancelYearMonthsEventsModal />
        )}

        <SiteContainerSC>
          <Header />
          <LayoutContainerSC>
            <Route path="/signin" component={SignIn} />
            <LoggedInValidate>
              {isLoggedIn && <SidebarRoute />}
              {responseIsValid ? (
                <>
                  {system_status === "maintenance" ? (
                    <MainContainerSC>
                      <Maintenance />
                    </MainContainerSC>
                  ) : position_id === 3 ? (
                    <MainSC className={sidebarIsOpened ? "" : "closed"}>
                      <ResetResponseErrorModule history={history} />
                      <MaintenanceStatusCheckModule />
                      <AdminRoutes
                        inquiryNewPostStatus={inquiry_new_post_status}
                      />
                    </MainSC>
                  ) : position_id === 2 ? (
                    <MainSC className={sidebarIsOpened ? "" : "closed"}>
                      <ResetResponseErrorModule history={history} />
                      <MaintenanceStatusCheckModule />
                      <HonbuRoutes
                        inquiryNewPostStatus={inquiry_new_post_status}
                      />
                    </MainSC>
                  ) : position_id === 6 ? (
                    <MainSC className={sidebarIsOpened ? "" : "closed"}>
                      <ResetResponseErrorModule history={history} />
                      <MaintenanceStatusCheckModule />
                      <ProjectManagerRoutes
                        inquiryNewPostStatus={inquiry_new_post_status}
                      />
                    </MainSC>
                  ) : position_id === 5 ? (
                    <MainSC className={sidebarIsOpened ? "" : "closed"}>
                      <ResetResponseErrorModule history={history} />
                      <MaintenanceStatusCheckModule />
                      <FacilityManagerRoutes
                        inquiryNewPostStatus={inquiry_new_post_status}
                      />
                    </MainSC>
                  ) : position_id === 1 ? (
                    <MainSC className={sidebarIsOpened ? "" : "closed"}>
                      <ResetResponseErrorModule history={history} />
                      <MaintenanceStatusCheckModule />
                      <GeneralEditorRoutes
                        inquiryNewPostStatus={inquiry_new_post_status}
                      />
                    </MainSC>
                  ) : (
                    <MainSC className={sidebarIsOpened ? "" : "closed"}>
                      <ResetResponseErrorModule history={history} />
                      <MaintenanceStatusCheckModule />
                      <GeneralViewerRoutes
                        inquiryNewPostStatus={inquiry_new_post_status}
                      />
                    </MainSC>
                  )}
                </>
              ) : (
                <MainSC className={sidebarIsOpened ? "" : "closed"}>
                  <MainContainerSC>
                    {response_status === 403 && <Error403 />}
                    {response_status === 404 && <Error404 />}
                    {response_status === 422 && <Error422 />}
                    {response_status === 500 && <Error500 />}
                  </MainContainerSC>
                </MainSC>
              )}
            </LoggedInValidate>
          </LayoutContainerSC>
        </SiteContainerSC>
      </div>
    </ConnectedRouter>
  );
}

export default App;
