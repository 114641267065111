import { put, takeLatest } from "redux-saga/effects";
import {
  POST_GOOGLE_LOGIN_REQUESTED,
  POST_GOOGLE_LOGIN_FAILED
} from "../store/googleOAuth/type";
import { LOGIN_STATUS_401 } from "../store/session/type";

function* postGoogleLogin(action: any) {
  try {
  } catch (e) {
    yield put({ type: POST_GOOGLE_LOGIN_FAILED, message: e.response.data });

    if (e.message === "Request failed with status code 401") {
      yield put({ type: LOGIN_STATUS_401 });
    } else {
      yield put({ type: POST_GOOGLE_LOGIN_FAILED, message: e.response.data });
    }
  }
}

const googleOAuthSaga = [
  takeLatest(POST_GOOGLE_LOGIN_REQUESTED, postGoogleLogin)
];

export default googleOAuthSaga;
