import styled from "styled-components";

export const ToggleSwitchSC = styled.div`
  position: relative;
  width: 32px;
  height: 16px;
  margin: 0;
  display: -webkit-box;
  align-items: center;
`;

export const ToggleSwitchInputSC = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  &:checked + div {
    background-color: #444444;
    &:after {
      left: 16px;
      border: 2px solid #444444;
      cursor: pointer;
    }
  }
`;

export const ToggleSwitchPartsSC = styled.div`
  width: 32px;
  height: 16px;
  background: #ccc;
  position: relative;
  display: block;
  border-radius: 46px;
  transition: 0.3s;
  box-sizing: border-box;
  &:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: solid 2px #888888;
    border-radius: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    cursor: pointer;
    &:checked {
      left: 40px;
    }
  }
`;

export const ToggleSwitchLabelSC = styled.label`
  font-size: 12px;
  align-items: center;
  display: block;
  margin-left: 10px;
  width: max-content;
`;
