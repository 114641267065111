import { PostInquiryManagementBody } from "api/FAQ/inquiriesApi";
import {
  POST_INQUIRY_NEW_REQUESTED,
  SET_INQUIRY_INPUT_NEW,
  TOGGLE_INQUIRY_NEW_POST_STATUS,
  SET_TERMINAL,
  RESET_POSTED_DETAILS,
  POST_INQUIRY_MANAGEMENT_REQUESTED
} from "./type";

export const postInquiryNewRequest = (
  token: string,
  inquiry_name: string,
  employee_id: number | null,
  facility: string,
  summary: string,
  terminal: string,
  terminal_details: string,
  child_id: number | null,
  model: string,
  model_name: string,
  proxy_login: boolean | null,
  details: string
) => {
  return {
    type: POST_INQUIRY_NEW_REQUESTED,
    token,
    inquiry_name,
    employee_id,
    facility,
    summary,
    terminal,
    terminal_details,
    child_id,
    model,
    model_name,
    proxy_login,
    details
  };
};
export const postInquiryManagementRequest = (
  token: string,
  requestBody: PostInquiryManagementBody
) => {
  return {
    type: POST_INQUIRY_MANAGEMENT_REQUESTED,
    token,
    requestBody
  };
};

export const setInquiryInputNew = (
  input_value: string | number | boolean | null,
  input_target: string
) => {
  return {
    type: SET_INQUIRY_INPUT_NEW,
    input_value,
    input_target
  };
};

export const toggleInquiryNewPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_INQUIRY_NEW_POST_STATUS,
    post_status
  };
};

export const setTerminal = (terminal_string: string) => {
  return {
    type: SET_TERMINAL,
    terminal_string
  };
};

export const resetPostedDetails = () => {
  return {
    type: RESET_POSTED_DETAILS
  };
};
