import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import close from "../../../../images/icons/close.svg";

import { toggleAddEventModal } from "../../../../store/common/modal/action";
import {
  postEventRequest,
  getEventListRequest
} from "../../../../store/event/events/action";
import {
  setAddEventDate,
  setAddEventType,
  setAddEventContent,
  setAddEventDetail,
  setAddEventClass,
  resetAddEventClass
} from "../../../../store/event/event_add/action";

import { toggleImgLoadingState } from "store/camera/mediafiles/action";

import {
  responseStatus401,
  loginStatus401,
  responseStatus403,
  responseStatus404,
  responseStatus422,
  responseStatus500
} from "../../../../store/session/action";

// デートピッカー
import "../../../../react-dates-custom.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { Class, EventType } from "components/Events/type";
import EventInputPreview from "./modal-parts/EventInputPreview";
import { EventClassCheckbox } from "./modal-parts/EventClassCheckbox";
import EventTypeSelect from "./modal-parts/EventTypeSelect";
import { AccordionBox } from "components/Common/AccordionBox";
import { CheckBox } from "components/Common/ui/form/checkBox";
import { postEventsMultipleApi } from "api/event/eventApi";

registerLocale("ja", ja);

export default function AddEventModalContent() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);

  const facility_id = useSelector((state: any) => state.facility.id);

  // 行事予定一覧取得
  const type_of_event = useSelector((state: any) => state.events.type_of_event);

  const [typeAlert, setTypeAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState(false);
  const [detailAlert, setDetailAlert] = useState(false);
  const [classAlert, setClassAlert] = useState(false);
  const event_add_year = useSelector((state: any) => state.event_add.year);
  const event_add_month = useSelector((state: any) => state.event_add.month);
  const event_add_date = useSelector((state: any) => state.event_add.date);
  const event_add_type_id = useSelector(
    (state: any) => state.event_add.type_id
  );
  const event_add_type_name = useSelector(
    (state: any) => state.event_add.type_name
  );
  const event_add_content = useSelector(
    (state: any) => state.event_add.content
  );
  const event_add_detail = useSelector((state: any) => state.event_add.detail);
  const event_add_classes = useSelector(
    (state: any) => state.event_add.classes
  );

  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_month = useSelector(
    (state: any) => state.event_search.filtered_month
  );
  const filtered_class = useSelector(
    (state: any) => state.event_search.filtered_class
  );
  const filtered_remand = useSelector(
    (state: any) => state.event_search.filtered_remand
  );

  const [multipleFormAlert, setMultipleFormAlert] = useState(false);

  // 複数入力フォームの型
  type FormType = {
    detail: string;
    class: string;
    inValid: {
      detail: boolean;
      class: boolean;
    };
  };
  type MultipleFormType = {
    multiple: FormType[];
  };

  // 複数入力フォームの数
  const initialMultipleNumber = 6;

  // 複数入力の初期値
  const initialMultipleFormData: FormType[] = Array.from({
    length: initialMultipleNumber
  }).map(() => {
    return {
      detail: "",
      class: "",
      inValid: {
        detail: false,
        class: false
      }
    };
  });
  // 複数入力のフォーム
  const [multipleForm, setMultipleForm] = useState<MultipleFormType>({
    multiple: initialMultipleFormData
  });

  // デートピッカー関連
  const initialDate = useSelector(
    (state: any) => state.event_add.yearMonthDate
  );
  const [calenderDate, setCalenderDate] = useState(new Date(initialDate));
  const getFormatDate = (date: any) => {
    return (
      date.getFullYear() +
      "年" +
      (date.getMonth() + 1) +
      "月" +
      date.getDate() +
      "日"
    );
  };

  // 日付変更時
  const handleChangeDate = (date: any) => {
    setCalenderDate(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const date_to_submit = date.getDate();
    dispatch(setAddEventDate(year, month, date_to_submit));
  };

  // 行事名（イベントタイプ）が変わった場合
  const handleChangeEventType = (event: any) => {
    const target_type_id = Number(event.target.value);
    const target_type_index = type_of_event.findIndex(
      (item: any) => item.id === target_type_id
    );
    const target_type_name =
      target_type_index > 0 ? type_of_event[target_type_index].name : "";
    // イベントタイプのidをstoreに登録
    if (event.target.value !== "") {
      dispatch(setAddEventType(target_type_id, target_type_name));
    } else {
      dispatch(setAddEventType("", ""));
    }

    // タイプが変更されたら編集内容をリセットする
    dispatch(setAddEventContent(""));
    dispatch(setAddEventDetail(""));
    dispatch(resetAddEventClass());
    setMultipleForm({
      multiple: initialMultipleFormData
    });
    setMultipleFormAlert(false);
  };

  //行事内容の入力
  const handleChangeEventContent = (event: any) => {
    dispatch(setAddEventContent(event.target.value));
  };

  // 詳細（目的地）の入力
  const handleChangeEventDetail = (event: any) => {
    dispatch(setAddEventDetail(event.target.value));
    // 文字が入力されたらアラートを外す
  };

  // クラスの入力
  const handleCheckClass = (event: any) => {
    dispatch(setAddEventClass(event.target.value));
  };

  // 選択してる行事予定がcontentを入力するものかどうか
  const contentIsEnable = () => {
    // 行事予定一覧(type_of_event)から選択してる行事の内容を取得
    const targetIndex = type_of_event.findIndex(
      (type: EventType) => type.id === event_add_type_id
    );
    if (targetIndex !== -1) {
      const formRule = type_of_event[targetIndex].form_rules;
      console.log(type_of_event[targetIndex]);
      return formRule.content !== "disabled";
    } else {
      return false;
    }
  };

  // 選択してる行事予定のcontent,class,detailが必須かどうか
  const isRequired = (inputType: "content" | "class" | "detail") => {
    // 行事予定一覧(type_of_event)から選択してる行事の内容を取得
    const targetIndex = type_of_event.findIndex(
      (type: EventType) => type.id === event_add_type_id
    );
    if (targetIndex !== -1) {
      const formRule = type_of_event[targetIndex].form_rules;
      return formRule[inputType] === "required";
    } else {
      return false;
    }
  };

  // 選択してる行事予定のclass,detailがhiddenかどうか
  const isHidden = (inputType: "class" | "detail") => {
    // 行事予定一覧(type_of_event)から選択してる行事の内容を取得
    const targetIndex = type_of_event.findIndex(
      (type: EventType) => type.id === event_add_type_id
    );
    if (targetIndex !== -1) {
      const formRule = type_of_event[targetIndex].form_rules;
      return formRule[inputType] !== "hidden";
    } else {
      return false;
    }
  };

  // 選択してる行事予定のclass,detailがmultipleかどうか
  const isMultiple = (inputType: "class" | "detail") => {
    // 行事予定一覧(type_of_event)から選択してる行事の内容を取得
    const targetIndex = type_of_event.findIndex(
      (type: EventType) => type.id === event_add_type_id
    );
    if (targetIndex !== -1) {
      const formRule = type_of_event[targetIndex].form_rules;
      return formRule[inputType] === "multiple";
    } else {
      return false;
    }
  };

  // submitボタンが押された時
  const handleClickSubmitButton = (e: any) => {
    e.preventDefault();
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));

    if (event_add_type_id === "") {
      setTypeAlert(true);
      // LoadingModuleの表示停止
      dispatch(toggleImgLoadingState(false));
      return false;
    } else {
      setTypeAlert(false);
    }

    if (
      event_add_type_id !== "" &&
      isRequired("content") &&
      event_add_content === ""
    ) {
      setContentAlert(true);
      // LoadingModuleの表示停止
      dispatch(toggleImgLoadingState(false));
      return false;
    } else {
      setContentAlert(false);
    }

    if (
      event_add_type_id !== "" &&
      isRequired("detail") &&
      event_add_detail === ""
    ) {
      setDetailAlert(true);
      // LoadingModuleの表示停止
      dispatch(toggleImgLoadingState(false));
      return false;
    } else {
      setDetailAlert(false);
    }

    if (
      event_add_type_id !== "" &&
      isRequired("class") &&
      !event_add_classes.some(
        (event_add_class_item: any) => event_add_class_item.checked === true
      ) // クラスが1つも設定されていない
    ) {
      setClassAlert(true);
      // LoadingModuleの表示停止
      dispatch(toggleImgLoadingState(false));
      return false;
    } else {
      setClassAlert(false);
    }

    if (!typeAlert && !contentAlert && !detailAlert && !classAlert) {
      event_add_content === ""
        ? //contentが何も入力されていない場合は、行事名を内容に渡す。
          dispatch(
            postEventRequest(
              token,
              facility_id,
              event_add_year,
              event_add_month,
              event_add_date,
              event_add_type_id,
              event_add_type_name,
              event_add_detail,
              event_add_classes,
              filtered_year,
              filtered_month,
              filtered_class,
              filtered_remand
            )
          )
        : dispatch(
            postEventRequest(
              token,
              facility_id,
              event_add_year,
              event_add_month,
              event_add_date,
              event_add_type_id,
              event_add_content,
              event_add_detail,
              event_add_classes,
              filtered_year,
              filtered_month,
              filtered_class,
              filtered_remand
            )
          );
      // LoadingModuleの表示停止
      dispatch(toggleImgLoadingState(false));
      dispatch(toggleAddEventModal(false));
    }
  };

  // 複数入力のフォーム
  const multipleForm01 = Array.from({ length: initialMultipleNumber })
    .slice(0, 3)
    .map((_, formIndex) => {
      return (
        <React.Fragment key={`multiple-form0${formIndex}`}>
          <InputItem className={`multiple`}>
            <InputItemLabel className={isRequired("content") ? "required" : ""}>
              詳細（目的地）
            </InputItemLabel>
            <EventMultipleWrapper>
              <InputItemTextInput
                type="text"
                placeholder="詳細（目的地）を入力"
                id={`multiple-detail0${formIndex}`}
                value={multipleForm.multiple[formIndex].detail}
                required={isRequired("detail")}
                onChange={event => {
                  handleMultipleDetailForm(event, formIndex);
                }}
              />
              <EventMultipleAlert>
                {multipleForm.multiple[formIndex].inValid.detail && (
                  <Alert>行事詳細（目的地）を入力してください。</Alert>
                )}
              </EventMultipleAlert>
            </EventMultipleWrapper>
          </InputItem>
          <InputItem className={`multiple`}>
            <InputItemLabel className="class">クラス</InputItemLabel>
            <EventMultipleWrapper>
              <InputItemCheckboxList>
                {Array.from({ length: 6 }).map((_, index) => (
                  <InputItemCheckboxItem
                    key={`multiple-form0${formIndex}__${index}`}
                  >
                    <CheckBox
                      label={`${index}歳児`}
                      name={`multiple-class0${formIndex}__${index}`}
                      value={`${index}`}
                      // multipleForm.multiple[formIndex].classにindexと同じ数字があるか
                      checked={
                        // multipleForm.multiple[formIndex].classをカンマで区切って配列にする
                        multipleForm.multiple[formIndex].class
                          ?.split(",")
                          // 配列の中にindexと同じ数字があるか？
                          .some(
                            (item: string) => item === `${index}`
                          ) as boolean
                      }
                      onChange={event => {
                        handleMultipleCheckForm(event, formIndex);
                      }}
                    />
                  </InputItemCheckboxItem>
                ))}
              </InputItemCheckboxList>
              <EventMultipleAlert>
                {multipleForm.multiple[formIndex].inValid.class && (
                  <Alert>クラスを選択してください。</Alert>
                )}
              </EventMultipleAlert>
            </EventMultipleWrapper>
          </InputItem>
        </React.Fragment>
      );
    });

  // アコーディオン内にに入る複数入力のフォーム
  const multipleForm02 = Array.from({ length: initialMultipleNumber })
    .slice(3)
    .map((_, formIndex) => {
      return (
        <React.Fragment key={`multiple-form0${formIndex + 3}`}>
          <InputItem className={`multiple`}>
            <InputItemLabel className={isRequired("content") ? "required" : ""}>
              詳細（目的地）
            </InputItemLabel>
            <EventMultipleWrapper>
              <InputItemTextInput
                type="text"
                placeholder="詳細（目的地）を入力"
                id={`multiple-detail0${formIndex + 3}`}
                value={multipleForm.multiple[formIndex + 3].detail}
                required={isRequired("detail")}
                onChange={event => {
                  handleMultipleDetailForm(event, formIndex + 3);
                }}
              />
              <EventMultipleAlert>
                {multipleForm.multiple[formIndex + 3].inValid.detail && (
                  <Alert>行事詳細（目的地）を入力してください。</Alert>
                )}
              </EventMultipleAlert>
            </EventMultipleWrapper>
          </InputItem>
          <InputItem className={`multiple`}>
            <InputItemLabel className="class">クラス</InputItemLabel>
            <EventMultipleWrapper>
              <InputItemCheckboxList>
                {Array.from({ length: 6 }).map((_, index) => (
                  <InputItemCheckboxItem
                    key={`multiple-form0${formIndex + 3}__${index}`}
                  >
                    <CheckBox
                      label={`${index}歳児`}
                      name={`multiple-class0${formIndex + 3}__${index}`}
                      value={`${index}`}
                      // multipleForm.multiple[formIndex + 3].classにindexと同じ数字があるか
                      checked={
                        // multipleForm.multiple[formIndex + 3].classをカンマで区切って配列にする
                        multipleForm.multiple[formIndex + 3].class
                          ?.split(",")
                          // 配列の中にindexと同じ数字があるか？
                          .some(
                            (item: string) => item === `${index}`
                          ) as boolean
                      }
                      onChange={event => {
                        handleMultipleCheckForm(event, formIndex + 3);
                      }}
                    />
                  </InputItemCheckboxItem>
                ))}
              </InputItemCheckboxList>
              <EventMultipleAlert>
                {multipleForm.multiple[formIndex + 3].inValid.class && (
                  <Alert>クラスを選択してください。</Alert>
                )}
              </EventMultipleAlert>
            </EventMultipleWrapper>
          </InputItem>
        </React.Fragment>
      );
    });

  // 複数入力の詳細（目的地）の値が変更された時
  const handleMultipleDetailForm = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // multipleForm.multiple[index]の値を取得する
    const multipleFormValue = multipleForm.multiple[index];
    // multipleFormValueの中身を変更する
    const newMultipleFormValue = {
      ...multipleFormValue,
      detail: event.target.value
    };
    // multipleFormValueの中身を変更したものをmultipleFormにセットする
    setMultipleForm(prevState => {
      return {
        multiple: [
          ...prevState.multiple.slice(0, index),
          newMultipleFormValue,
          ...prevState.multiple.slice(index + 1)
        ]
      };
    });
  };

  // 複数入力のチェックボックスの値が変更された時
  const handleMultipleCheckForm = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // multipleForm.multiple[index]の値を取得する
    const multipleFormValue = multipleForm.multiple[index];

    // multipleFormValueからclassを取得する
    // 空文字を削除する
    const multipleFormValueClassArray = multipleFormValue?.class
      ?.split(",")
      .filter((item: string) => item !== "");

    // multipleFormValueClassArrayの中にevent.target.valueがあるかどうか
    const isExist = multipleFormValueClassArray?.some(
      (item: string) => item === event.target.value
    );

    // multipleForm.multipleのすべてのindexのclsasからevent.target.valueがあるかどうか
    const searchClass = (searchText: string) => {
      const result: {
        index?: number;
        class?: string;
      } = {};
      for (let i = 0; i < multipleForm.multiple.length; i++) {
        const classes = multipleForm.multiple[i].class.split(",");
        for (let j = 0; j < classes?.length; j++) {
          if (classes[j].includes(searchText)) {
            result.index = i;
            result.class = classes[j];
            return result;
          }
        }
      }
      return result;
    };

    const result = searchClass(event.target.value);
    // 他のindexにevent.target.valueがある場合は削除する
    if (result && result.index !== undefined && result.class !== undefined) {
      // multipleForm.multipleからresult.indexを取得する
      const multipleFormValue = multipleForm.multiple[result.index];
      // multipleForm.multipleからresult.indexのclassを削除する
      const newClassArrayDeleted = multipleFormValue.class
        ?.split(",")
        .filter((item: string) => item !== result.class);

      // newClassArrayDeletedをmultipleForm.multipleにセットする
      const newMultipleFormValue = {
        ...multipleFormValue,
        class: newClassArrayDeleted?.join(",")
      };

      setMultipleForm(prevState => {
        return {
          multiple: [
            ...prevState.multiple.slice(0, result.index),
            newMultipleFormValue,
            ...prevState.multiple.slice((result.index as number) + 1)
          ]
        };
      });
    }
    // すでにevent.target.valueがある場合は削除する
    if (isExist) {
      // multipleFormValueClassArrayからevent.target.valueを削除する
      const newClassArray = multipleFormValueClassArray?.filter(
        (item: string) => item !== event.target.value
      );

      // newClassArrayをカンマでつなげる
      const newClass = newClassArray?.join(",");

      // multipleFormValueの中身を変更する
      const newMultipleFormValue = {
        ...multipleFormValue,
        class: newClass
      };

      // multipleFormValueの中身を変更したものをmultipleFormにセットする
      setMultipleForm(prevState => {
        return {
          multiple: [
            ...prevState.multiple.slice(0, index),
            newMultipleFormValue,
            ...prevState.multiple.slice(index + 1)
          ]
        };
      });
      return;
    } else {
      // multipleFormValueClassArrayにevent.target.valueを追加する
      const newClassArray = multipleFormValueClassArray
        ?.concat(event.target.value)
        .sort((a: string, b: string) => Number(a) - Number(b));

      // newClassArrayをカンマでつなげる
      const newClass = newClassArray?.join(",");

      // multipleFormValueの中身を変更する
      const newMultipleFormValue = {
        ...multipleFormValue,
        class: newClass
      };

      // multipleFormValueの中身を変更したものをmultipleFormにセットする
      setMultipleForm(prevState => {
        return {
          multiple: [
            ...prevState.multiple.slice(0, index),
            newMultipleFormValue,
            ...prevState.multiple.slice(index + 1)
          ]
        };
      });

      return;
    }
  };

  // 複数入力のプレビュー
  const MultiplePreview = (data: MultipleFormType) => {
    // stateのmultipleFormに影響が出ないようにここでコピーする
    const multipleForm = JSON.parse(JSON.stringify(data.multiple));

    // 行事予定@目的地(クラス)/目的地(クラス)の形にする
    const formatMultiplePreview = () => {
      const multiplePreviewArray: string[] = [];

      // classの値が大きい順にソートする
      const sortMultiple = multipleForm.sort((a: FormType, b: FormType) => {
        const classA = a.class.split(",").map(Number);
        const classB = b.class.split(",").map(Number);

        // 空文字列を0として扱い、classプロパティ内の最大値を比較
        const maxClassA = Math.max(...classA, 0);
        const maxClassB = Math.max(...classB, 0);

        // 大きい順にソート
        return maxClassB - maxClassA;
      });

      sortMultiple.forEach((item: FormType, index: number) => {
        // detailとclassが両方入力されている場合
        if (item.detail !== "" && item.class !== "") {
          multiplePreviewArray.push(`${item.detail}(${item.class}歳児)`);
        } else if (item.detail !== "" && item.class === "") {
          multiplePreviewArray.push(`${item.detail}`);
        } else if (item.detail === "" && item.class !== "") {
          multiplePreviewArray.push(`(${item.class}歳児)`);
        }
      });

      return multiplePreviewArray.join("／");
    };
    return (
      <EventMultiplePreview>
        <EventMultiplePreviewText>
          {event_add_type_name}
          {formatMultiplePreview() !== "" && `@${formatMultiplePreview()}`}
        </EventMultiplePreviewText>
        <EventMultiplePreviewNote>と表示されます</EventMultiplePreviewNote>
      </EventMultiplePreview>
    );
  };

  // 複数入力のsubmit
  const handleClickMultipleSubmitButton = (e: any) => {
    e.preventDefault();
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));

    // 複数入力の各フォームのバリデーション
    const multipleFormValidation = multipleForm.multiple.map(
      (item: FormType) => {
        // クラスが入力されていて詳細（目的地）が入力されていない場合
        if (item.class !== "" && item.detail === "") {
          return {
            detail: true,
            class: false
          };
        }
        // クラスが入力されていなくて詳細（目的地）が入力されている場合
        if (item.class === "" && item.detail !== "") {
          return {
            detail: false,
            class: true
          };
        }
        // 詳細（目的地）とクラスが入力されている場合
        if (item.detail !== "" && item.class !== "") {
          return {
            detail: false,
            class: false
          };
        }
        // 詳細（目的地）とクラスが入力されていない場合
        if (item.detail === "" && item.class === "") {
          return {
            detail: false,
            class: false
          };
        }
        return {
          detail: false,
          class: false
        };
      }
    );
    // multipleFormValidationの中にtrueがあるかどうか
    if (
      multipleFormValidation.some(
        (item: any) => item.detail === true || item.class === true
      )
    ) {
      // バリデーションメッセージを表示させるためにmultipleFormValidationをセットする
      setMultipleForm(prevState => {
        return {
          multiple: prevState.multiple.map((item: FormType, index: number) => {
            return {
              ...item,
              inValid: {
                class: multipleFormValidation[index]?.class as boolean,
                detail: multipleFormValidation[index]?.detail as boolean
              }
            };
          })
        };
      });
      // LoadingModuleの表示停止
      dispatch(toggleImgLoadingState(false));
      return false;
    } else {
      // バリデーションメッセージを消すためにmultipleFormValidationをセットする
      setMultipleForm(prevState => {
        return {
          multiple: prevState.multiple.map((item: FormType, index: number) => {
            return {
              ...item,
              inValid: {
                class: multipleFormValidation[index]?.class as boolean,
                detail: multipleFormValidation[index]?.detail as boolean
              }
            };
          })
        };
      });
    }
    // multipleForm.multipleの中のdetail,classが両方入力されているものを取得する
    const multipleFormValue = multipleForm.multiple.filter(
      (item: FormType) => item.detail !== "" && item.class !== ""
    );
    if (multipleFormValue.length === 0) {
      dispatch(toggleImgLoadingState(false));
      setMultipleFormAlert(true);
      return false;
    } else {
      setMultipleFormAlert(false);
    }
    /**
     * multipleFormValueの中のdetail,classを配列にする
     *   destination: string;
     *   class: string;
     * */
    const multipleFormValueArray = multipleFormValue.map((item: FormType) => {
      return {
        destination: item.detail,
        class: item.class
      };
    });
    postEventsMultipleApi(
      token,
      facility_id,
      event_add_year,
      event_add_month,
      event_add_date,
      event_add_type_id,
      event_add_type_name,
      multipleFormValueArray
    )
      .then(() => {
        // LoadingModuleの表示停止
        dispatch(toggleAddEventModal(false));
        dispatch(
          getEventListRequest(
            token,
            facility_id,
            filtered_year,
            filtered_month,
            filtered_class,
            filtered_remand
          )
        );
      })
      .catch(e => {
        // LoadingModuleの表示停止
        dispatch(toggleImgLoadingState(false));
        dispatch(toggleAddEventModal(false));
        const errorStatus = e.response.status;
        console.log(errorStatus);
        if (errorStatus === 401) {
          dispatch(responseStatus401());
          dispatch(loginStatus401());
          return;
        } else if (errorStatus === 403) {
          dispatch(responseStatus403());
          return;
        } else if (errorStatus === 404) {
          dispatch(responseStatus404());
          return;
        } else if (errorStatus === 422) {
          dispatch(responseStatus422());
          return;
        }
        dispatch(responseStatus500());
      });
    // LoadingModuleの表示停止
    dispatch(toggleImgLoadingState(false));
  };

  return (
    <EditEventModalContentSC
      className={
        isMultiple("class") && isMultiple("detail") ? "multipleModal" : ""
      }
    >
      <TitleBlock>
        <Title>行事予定追加</Title>
        <CloseIcon
          src={close}
          alt="閉じる"
          onClick={() => dispatch(toggleAddEventModal(false))}
        />
      </TitleBlock>
      <EditEventsForm>
        <InputList>
          <InputItem>
            <InputItemLabel>日付</InputItemLabel>
            <DatePicker
              selected={calenderDate}
              onChange={handleChangeDate}
              locale="ja"
              id="calender-id"
              customInput={<button>{getFormatDate(calenderDate)}</button>}
            />
          </InputItem>

          <InputItem>
            <InputItemLabel>行事名</InputItemLabel>
            <InputItemSelectWrapper>
              <EventTypeSelect
                typeOfEvent={type_of_event}
                typeId={event_add_type_id}
                onChangeFunc={handleChangeEventType}
              />
              {typeAlert && <Alert>行事名を選択してください。</Alert>}
            </InputItemSelectWrapper>
          </InputItem>

          {/* contentを入力する行事予定 */}
          {event_add_type_id !== "" &&
            contentIsEnable() &&
            !isMultiple("class") &&
            !isMultiple("detail") && (
              <InputItem>
                <InputItemLabel
                  className={isRequired("content") ? "required" : ""}
                >
                  行事内容
                </InputItemLabel>
                {event_add_type_id !== 20 && event_add_type_id !== 11 && (
                  <InputWrapper>
                    <p>{event_add_type_name}</p>
                    (
                    <InputItemTextInput
                      type="text"
                      id="content"
                      className="content"
                      placeholder="内容を入力"
                      onChange={handleChangeEventContent}
                      value={event_add_content}
                      required={isRequired("content")}
                    />
                    )
                  </InputWrapper>
                )}
                {(event_add_type_id === 20 || event_add_type_id === 11) && (
                  <InputItemTextInput
                    type="text"
                    id="content"
                    placeholder="内容を入力"
                    onChange={handleChangeEventContent}
                    value={event_add_content}
                    required={isRequired("content")}
                  />
                )}
                {contentAlert && <Alert>行事内容を入力してください。</Alert>}
              </InputItem>
            )}

          {/* detailを入力する行事予定*/}
          {event_add_type_id !== "" &&
            isHidden("detail") &&
            !isMultiple("class") &&
            !isMultiple("detail") && (
              <>
                <InputItem>
                  <InputItemLabel
                    className={isRequired("content") ? "required" : ""}
                  >
                    詳細（目的地）
                  </InputItemLabel>
                  <InputItemTextInputWrapper>
                    {event_add_type_id === 4 && (
                      <p
                        style={{
                          fontSize: "12px",
                          lineHeight: "1.2",
                          marginBottom: "8px"
                        }}
                      >
                        下記パターンで入力してください
                        <br />
                        ①晴：●●&nbsp;&nbsp;雨：▲▲&nbsp;&nbsp;②&nbsp;●●（雨天決行）③&nbsp;●●（雨天中止）
                      </p>
                    )}
                    <InputItemTextInput
                      type="text"
                      placeholder="詳細（目的地）を入力"
                      id="detail"
                      onChange={handleChangeEventDetail}
                      value={event_add_detail}
                      required={isRequired("detail")}
                    />
                    {detailAlert && (
                      <Alert>行事詳細（目的地）を入力してください。</Alert>
                    )}
                  </InputItemTextInputWrapper>
                </InputItem>
              </>
            )}

          {/* classを入力する行事予定*/}
          {event_add_type_id !== "" &&
            isHidden("class") &&
            !isMultiple("class") &&
            !isMultiple("detail") && (
              <InputItem>
                <InputItemLabel className="class">クラス</InputItemLabel>
                <InputItemCheckboxList>
                  {event_add_classes.map(({ class_age, checked }: Class) => (
                    <InputItemCheckboxItem key={class_age}>
                      <EventClassCheckbox
                        classAge={class_age}
                        checked={checked}
                        onChangeFunc={handleCheckClass}
                        required={isRequired("class")}
                      />
                    </InputItemCheckboxItem>
                  ))}
                  {classAlert && <Alert>クラスを選択してください。</Alert>}
                </InputItemCheckboxList>
              </InputItem>
            )}

          {/* 複数入力 */}
          {event_add_type_id !== "" &&
            isMultiple("class") &&
            isMultiple("detail") && (
              <>
                {multipleForm01}
                {initialMultipleNumber >= 4 && (
                  <AccordionBox caption={"目的地が3件を超える場合"}>
                    {multipleForm02}
                  </AccordionBox>
                )}
              </>
            )}

          {/* プレビュー */}
          {event_add_type_id !== "" &&
            !isMultiple("class") &&
            !isMultiple("detail") && (
              <InputItem className="preview">
                <InputItemLabel>プレビュー</InputItemLabel>
                <EventInputPreview
                  typeOfEvent={type_of_event}
                  eventTypeId={event_add_type_id}
                  content={event_add_content}
                  detail={event_add_detail}
                  classes={event_add_classes}
                />
              </InputItem>
            )}

          {/* 複数入力のプレビュー */}
          {event_add_type_id !== "" &&
            isMultiple("class") &&
            isMultiple("detail") && (
              <InputItem className="preview">
                <InputItemLabel>プレビュー</InputItemLabel>
                {MultiplePreview(multipleForm)}
              </InputItem>
            )}
        </InputList>

        {/* 複数入力のバリデーションメッセージ*/}
        {multipleFormAlert && isMultiple("class") && isMultiple("detail") && (
          <InputItem>
            <Alert>最低一つの目的地とクラスを入力してください</Alert>
          </InputItem>
        )}

        {/* ボタン */}
        {!isMultiple("class") && !isMultiple("detail") && (
          <Buttons>
            <CancelButton onClick={() => dispatch(toggleAddEventModal(false))}>
              キャンセル
            </CancelButton>
            <SubmitButton
              type="submit"
              value="登録"
              onClick={handleClickSubmitButton}
            />
          </Buttons>
        )}

        {/* 複数入力のボタン */}
        {isMultiple("class") && isMultiple("detail") && (
          <Buttons>
            <CancelButton onClick={() => dispatch(toggleAddEventModal(false))}>
              キャンセル
            </CancelButton>
            <SubmitButton
              type="submit"
              value="登録"
              onClick={event => {
                handleClickMultipleSubmitButton(event);
              }}
            />
          </Buttons>
        )}
      </EditEventsForm>
    </EditEventModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const EditEventModalContentSC = styled.div`
  max-width: 950px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  // 複数入力の時のみ、高さを変更する (徒歩遠足)
  &.multipleModal {
    max-height: 90vh;
    overflow-y: auto;
  }
`;

const TitleBlock = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const EditEventsForm = styled.div`
  padding: 11px 129px 30px;
`;

const InputList = styled.ul`
  margin-bottom: 66px;
`;

const InputItem = styled.li`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  &.preview {
    align-items: center;
  }

  &.multiple {
    margin-bottom: 0;
  }
`;

const InputItemLabel = styled.label`
  display: block;
  width: 15%;
  max-width: 100px;
  flex-shrink: 0;
  margin-right: 54px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  /* &.required::after {
    content: "*";
    color: red;
  } */
`;

const InputItemSelectWrapper = styled.div`
  width: 100%;
`;

const InputItemTextInputWrapper = styled.div`
  width: 100%;
`;

const InputItemTextInput = styled.input`
  padding: 12px;
  border: 1px solid ${color.gray_border};
  border-radius: 4px;
  width: 90%;
  max-width: 400px;
  font-size: 14px;
  &::placeholder {
    color: ${color.gray_placeholder};
    font-size: 14px;
  }
  &.content {
    width: 60%;
    margin: 0 4px;
    padding: 8px;
  }
`;

const InputItemCheckboxList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const InputItemCheckboxItem = styled.li`
  margin-right: 19px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const Alert = styled.p`
  font-size: 14px;
  color: red;
  margin: 0;
  display: block;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const EventMultipleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EventMultiplePreview = styled.div`
  width: 100%;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const EventMultiplePreviewText = styled.p`
  line-height: 20px;
  padding: 10px 0 5px;
`;

const EventMultiplePreviewNote = styled.span`
  display: inline-block;
`;

const EventMultipleAlert = styled.div`
  height: 20px;
`;
