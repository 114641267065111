const SET_INITIAL_EDIT_MONTHLY_EVENT = "SET_INITIAL_EDIT_MONTHLY_EVENT"
const SET_EDIT_MONTHLY_EVENT_TYPE = "SET_EDIT_MONTHLY_EVENT_TYPE"
const SET_EDIT_MONTHLY_EVENT_CONTENT = "SET_EDIT_MONTHLY_EVENT_CONTENT"
const RESET_EDIT_MONTHLY_EVENT_TYPE = "RESET_EDIT_MONTHLY_EVENT_TYPE"
const RESET_EDIT_MONTHLY_EVENT_CONTENT = "RESET_EDIT_MONTHLY_EVENT_CONTENT"

export {
  SET_INITIAL_EDIT_MONTHLY_EVENT,
  SET_EDIT_MONTHLY_EVENT_TYPE,
  SET_EDIT_MONTHLY_EVENT_CONTENT,
  RESET_EDIT_MONTHLY_EVENT_TYPE,
  RESET_EDIT_MONTHLY_EVENT_CONTENT
}