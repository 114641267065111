import { pc } from "components/mixins/mediaQuery";
import React from "react";
import styled from "styled-components";
import { containerBaseStyle } from "../../mixins/common";

const ModuleContainer: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ModuleContainer;

const Container = styled.div`
  ${containerBaseStyle}
  background-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
  ${pc`
    width: 360px;
  `}
`;
