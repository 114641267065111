import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import pulldown_arrow from "../../../../images/icons/pulldown_arrow.svg";
import close from "../../../../images/icons/close.svg";

import { toggleAddMonthlyEventModal } from "../../../../store/common/modal/action";

import { postMonthlyEventsRequest } from "../../../../store/event/monthly_events/action";
import {
  setAddMonthlyEventType,
  setAddMonthlyEventContent,
} from "../../../../store/event/monthly_event_add/action";

export default function AddEventModalContent() {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);

  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const filtered_class = useSelector(
    (state: any) => state.event_search.filtered_class
  );
  const filtered_remand = useSelector(
    (state: any) => state.event_search.filtered_remand
  );

  const add_monthly_event_month = useSelector(
    (state: any) => state.monthly_event_add.month
  );

  const year_to_post =
    add_monthly_event_month === 1 ||
    add_monthly_event_month === 2 ||
    add_monthly_event_month === 3
      ? filtered_year + 1
      : filtered_year;

  const add_monthly_event_type_id = useSelector(
    (state: any) => state.monthly_event_add.type_id
  );
  const add_monthly_event_content = useSelector(
    (state: any) => state.monthly_event_add.content
  );

  // 月間計画・農業計画の種類設定
  const handleMonthlyEventTypeChange = (event: any) => {
    dispatch(setAddMonthlyEventType(Number(event.target.value)));
  };

  // 月間計画・農業計画の内容設定
  const handleMonthlyEventContentChange = (event: any) => {
    dispatch(setAddMonthlyEventContent(event.target.value));
  };

  // 登録ボタンをクリック
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const kind_to_request = add_monthly_event_type_id === 52 ? 2 : 3; // 行事種類が月間計画(52)であれば2, 農業計画(53)であれば3をセット
    dispatch(
      postMonthlyEventsRequest(
        token,
        facility_id,
        year_to_post,
        add_monthly_event_month,
        add_monthly_event_type_id,
        add_monthly_event_content,
        "",
        1,
        kind_to_request,
        filtered_year,
        filtered_months,
        filtered_class,
        filtered_remand
      )
    );
    dispatch(toggleAddMonthlyEventModal(false));
  };

  return (
    <EditEventModalContentSC>
      <TitleBlock>
        <Title>
          月間・農業計画追加：{add_monthly_event_month.toString()}月
        </Title>
        <CloseIcon
          src={close}
          alt="閉じる　ボタン"
          onClick={() => dispatch(toggleAddMonthlyEventModal(false))}
        />
      </TitleBlock>
      <EditEventsForm>
        <InputList>
          <InputItem>
            <InputItemLabel>行事名</InputItemLabel>
            <InputItemSelectWrapper>
              <InputItemSelect
                value={add_monthly_event_type_id}
                onChange={handleMonthlyEventTypeChange}
                required
              >
                <InputItemOption value="">選択してください</InputItemOption>
                <InputItemOption value={52}>月間計画</InputItemOption>
                <InputItemOption value={53}>農業計画</InputItemOption>
              </InputItemSelect>
            </InputItemSelectWrapper>
          </InputItem>

          <InputItem>
            <InputItemLabel>行事内容</InputItemLabel>
            <InputItemTextInput
              type="text"
              id="content"
              placeholder="内容を入力"
              value={add_monthly_event_content}
              onChange={handleMonthlyEventContentChange}
            />
          </InputItem>
        </InputList>
        <Buttons>
          <CancelButton
            onClick={() => dispatch(toggleAddMonthlyEventModal(false))}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            value="登録"
            onClick={handleSubmit}
            disabled={
              add_monthly_event_type_id === null ||
              add_monthly_event_content === ""
            }
          />
        </Buttons>
      </EditEventsForm>
    </EditEventModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const EditEventModalContentSC = styled.div`
  max-width: 950px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const EditEventsForm = styled.div`
  padding: 60px 129px 30px;
`;

const InputList = styled.ul`
  margin-bottom: 66px;
`;

const InputItem = styled.li`
  display: flex;
  margin-bottom: 20px;
`;

const InputItemLabel = styled.label`
  display: block;
  width: 13%;
  max-width: 87px;
  flex-shrink: 0;
  margin-right: 54px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  padding-top: 13px;
  &.class {
    padding-top: 6px;
  }
`;

const InputItemSelectWrapper = styled.div`
  width: 100%;
`;

const InputItemSelect = styled.select`
  padding: 11px;
  border: 1px solid ${color.gray_border};
  border-radius: 4px;
  width: 90%;
  max-width: 400px;
  font-size: 14px;
  line-height: 1;
  background-image: url(${pulldown_arrow});
  background-repeat: no-repeat;
  background-position: right 12px top 15px;
  cursor: pointer;
`;

const InputItemOption = styled.option``;

const InputItemTextInput = styled.input`
  padding: 12px;
  border: 1px solid ${color.gray_border};
  border-radius: 4px;
  width: 90%;
  max-width: 400px;
  font-size: 14px;
  &::placeholder {
    color: ${color.gray_placeholder};
    font-size: 14px;
  }
  &.disabled {
    background-color: ${color.primary_white};
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  &:disabled {
    opacity: 0.6;
  }
`;
