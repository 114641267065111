import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import facilityIcon from "../../images/icons/facility.svg"

function FacilityHeading() {
  const facility_name = useSelector((state: any) => state.facility.name);
  return (
    <FacilityHeadingSC>
      {facility_name}
    </FacilityHeadingSC>
  )
}

export default FacilityHeading

// ===================================================================================
// スタイル
// ===================================================================================
const FacilityHeadingSC = styled.h1`
  font-size: 18px;
  line-height: inherit;
  font-weight: 600;
  margin: 6px 0;
  color: #009DFF;
  width: 100%;
  padding-left: 26px;
  background-image: url(${facilityIcon});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14.4px 16px;
`;