import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { toggleFacilityModal } from "../../../../store/common/modal/action";
import { setFacility } from "../../../../store/event/facility/action";
import {
  getEventListRequest,
  getEventTypesRequest
} from "../../../../store/event/events/action";
import {
  getApprovalMonthlyStatusRequest,
  resetSelectedOptions,
  resetToBeApproved,
  resetToBeRejected
} from "../../../../store/event/approval/action";
import { getEventsForAnnualScheduleRequest } from "../../../../store/event/events_top/action";
import {
  getAllMonthlyEventsRequest,
  getMonthlyEventsRequest
} from "../../../../store/event/monthly_events/action";

export default function FacilityItem(props: any) {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);

  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const filtered_class = useSelector(
    (state: any) => state.event_search.filtered_class
  );
  const filtered_remand = useSelector(
    (state: any) => state.event_search.filtered_remand
  );

  const handleClick = () => {
    console.log("filtered_year", filtered_year);
    console.log("filtered_months", filtered_months);
    console.log("filtered_class", filtered_class);
    console.log("filtered_remand", filtered_remand);
    dispatch(toggleFacilityModal(false));
    dispatch(resetToBeApproved());
    dispatch(resetToBeRejected());
    dispatch(resetSelectedOptions());
    dispatch(setFacility(props.id, props.name));
    dispatch(getApprovalMonthlyStatusRequest(token, props.id, filtered_year));
    dispatch(
      getEventListRequest(
        token,
        props.id,
        filtered_year,
        filtered_months,
        filtered_class,
        filtered_remand
      )
    );
    dispatch(
      getEventsForAnnualScheduleRequest(
        token,
        props.id,
        filtered_year,
        filtered_remand
      )
    );
    dispatch(
      getMonthlyEventsRequest(
        token,
        props.id,
        filtered_year,
        filtered_months,
        filtered_class,
        filtered_remand
      )
    );
    dispatch(getAllMonthlyEventsRequest(token, props.id, filtered_year));
    dispatch(getEventTypesRequest(token));
  };
  return <FacilityItemSC onClick={handleClick}>{props.name}</FacilityItemSC>;
}

// ===================================================================================
// スタイル
// ===================================================================================
const FacilityItemSC = styled.li`
  max-width: 365px;
  width: calc(50% - 5px);
  height: 38px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #444444;
  font-size: 14px;
  font-weight: bold;
  &:nth-of-type(2n) {
    margin-right: 0;
  }
  text-align: center;
  padding: 10px 0;
  color: #444444;
  &:hover {
    cursor: pointer;
  }
`;
