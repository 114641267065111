const SET_EVENT_ADD_INITIAL_DATE = "SET_EVENT_ADD_INITIAL_DATE"
const SET_ADD_EVENT_DATE = "SET_ADD_EVENT_DATE"
const SET_ADD_EVENT_TYPE = "SET_ADD_EVENT_TYPE"
const SET_ADD_EVENT_CONTENT = "SET_ADD_EVENT_CONTENT"
const SET_ADD_EVENT_DETAIL = "SET_ADD_EVENT_DETAIL"
const SET_ADD_EVENT_CLASS = "SET_ADD_EVENT_CLASS"
const RESET_ADD_EVENT_CONTENT = "RESET_ADD_EVENT_CONTENT"
const RESET_ADD_EVENT_CLASS = "RESET_ADD_EVENT_CLASS"

export {
  SET_EVENT_ADD_INITIAL_DATE,
  SET_ADD_EVENT_DATE,
  SET_ADD_EVENT_TYPE,
  SET_ADD_EVENT_CONTENT,
  SET_ADD_EVENT_DETAIL,
  SET_ADD_EVENT_CLASS,
  RESET_ADD_EVENT_CONTENT,
  RESET_ADD_EVENT_CLASS
}