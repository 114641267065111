import { CLEAR_SYSTEM_CACHE } from "../session/type";
import { TOGGLE_SERVICE_MODAL } from "./type";
interface Service {
  title: string;
  pathName: string;
}

const initialState: Service[] = [
  { title: "FAQ", pathName: "/articles" },
  { title: "行事予定", pathName: "/events/home" },
  { title: "マニュアル", pathName: "/manual" }
];
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
