import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PageBreadcrumb from "../../../Common/PageBreadcrumb";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, buttonBaseStyle } from "../../../mixins";
import trash from "../../../../images/icons/trash.svg";
import {
  putArticleEditRequest,
  toggleArticleEditPostStatus,
  inputEditArticleTitle,
  resetPreviousPublishStatus,
  saveTempEditData,
  setEditorIsToggled
} from "../../../../store/faq/article_edit/action";
import { toggleDeleteArticleModal } from "../../../../store/common/modal/action";
import { getArticleRequest } from "../../../../store/faq/article_detail/action";

import ArticleEditor from "./ArticleEditor";
import ArticleCurrentView from "./ArticleCurrentView";

function ArticlesDetailEdit(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const stocks = useSelector((state: any) => state.articles_stocked);
  const token = useSelector((state: any) => state.session.token);
  const edit_article_id = useSelector((state: any) => state.article_edit.id);
  const edit_article_title = useSelector(
    (state: any) => state.article_edit.title
  );
  const edit_article_content = useSelector(
    (state: any) => state.article_edit.content
  );
  const edit_article_categories = useSelector(
    (state: any) => state.article_edit.categories
  );
  const edit_article_search_string = useSelector(
    (state: any) => state.article_edit.search_string
  );
  const edit_article_tags = useSelector(
    (state: any) => state.article_edit.tags
  );
  const edit_article_status = useSelector(
    (state: any) => state.article_edit.status
  );
  const edit_article_draft = useSelector(
    (state: any) => state.article_edit.draft
  );
  const edit_article_title_draft = useSelector(
    (state: any) => state.article_edit.title_draft
  );
  const articleIsEditing = useSelector(
    (state: any) => state.article_edit.articleIsEditing
  );
  const articleTitleIsEdited = useSelector(
    (state: any) => state.article_edit.articleTitleIsEdited
  );
  const title = useSelector((state: any) => state.article_edit.title);
  const editorIsToggled = useSelector(
    (state: any) => state.article_edit.editorIsToggled
  );

  const savedTempData = useSelector(
    (state: any) => state.article_edit.savedTempData
  );
  const savedTempContent = useSelector(
    (state: any) => state.article_edit.savedTempData.content
  );
  const savedTempTitle = useSelector(
    (state: any) => state.article_edit.savedTempData.title
  );
  const savedTempTitleDraft = useSelector(
    (state: any) => state.article_edit.savedTempData.title_draft
  );
  const savedTempSearchString = useSelector(
    (state: any) => state.article_edit.savedTempData.search_string
  );
  const savedTempDraft = useSelector(
    (state: any) => state.article_edit.savedTempData.draft
  );
  const savedTempCategories = useSelector(
    (state: any) => state.article_edit.savedTempData.categories
  );
  const savedTempTags = useSelector(
    (state: any) => state.article_edit.savedTempData.tags
  );
  const savedTempIsEditing = useSelector(
    (state: any) => state.article_edit.savedTempData.articleIsEditing
  );
  const savedTempTitleIsEditing = useSelector(
    (state: any) => state.article_edit.savedTempData.articleTitleIsEditing
  );
  const currentArticleStatus = useSelector(
    (state: any) => state.article_detail.status
  );

  // const search_string = "test search string";

  const article_edit = useSelector((state: any) => state.article_edit);

  const post_status = useSelector(
    (state: any) => state.article_edit.post_status
  );
  // 必須入力項目の入力バリデーションに必要
  const title_input_status = useSelector(
    (state: any) => state.article_edit.title_input_status
  );

  // 編集画面と公開記事確認画面の切り替え
  const [showEditor, setShowEditor] = useState(true);
  const handleToggleEditor = () => {
    setShowEditor(!showEditor);
    if (showEditor && !editorIsToggled) {
      //最初の一回目はデータ保存する
      dispatch(saveTempEditData(article_edit, currentArticleStatus));
    } else if (showEditor && editorIsToggled) {
      //２回目以降はTempデータを上書き保存する
      dispatch(saveTempEditData(savedTempData));
    } else {
      return false;
    }
    dispatch(setEditorIsToggled(true));
  };

  useEffect(() => {
    dispatch(getArticleRequest(token, edit_article_id, stocks));
  }, [dispatch, token, edit_article_id]);

  // const handleRegister = () => {
  //   if(editorIsToggled) {
  //     savedTempIsEditing ?
  //     dispatch(putArticleEditRequest(edit_article_id, token, savedTempTitle, savedTempContent, savedTempSearchString, edit_article_status, savedTempCategories, savedTempTags))
  //     :
  //     dispatch(putArticleEditRequest(edit_article_id, token, savedTempTitle, savedTempDraft, savedTempSearchString, edit_article_status, savedTempCategories, savedTempTags))
  //   } else {
  //     // 「登録」ボタンクリック時に実際の新規記事情報を入力
  //     if(articleIsEditing) {
  //       // 記事本文を編集していたらcontentをputリクエストに載せる。
  //       dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, edit_article_status, edit_article_categories, edit_article_tags))
  //     } else {
  //       if(status === 3) {
  //         // 本文を未編集かつ下書きステータスのまま変更しなかった場合、draftをリクエストに載せる
  //         previousStatusIsDraft === null ?
  //         dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title_draft, edit_article_draft, edit_article_search_string, edit_article_status, edit_article_categories, edit_article_tags))
  //         :
  //         // 本文未編集かつ、ステータスを一旦変更して、また下書きにした場合もdraftをリクエストに載せる
  //         previousStatusIsDraft ?
  //         dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title_draft, edit_article_draft, edit_article_search_string, edit_article_status, edit_article_categories, edit_article_tags))
  //         :
  //         dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, edit_article_status, edit_article_categories, edit_article_tags))
  //       } else {
  //         // 本文を未編集かつ、ステータス変更前が下書きだった場合はdraftをリクエストに載せる
  //         previousStatusIsDraft ?
  //         dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title_draft, edit_article_draft, edit_article_search_string, edit_article_status, edit_article_categories, edit_article_tags))
  //         :
  //         dispatch(putArticleEditRequest(edit_article_id, token, edit_article_title, edit_article_content, edit_article_search_string, edit_article_status, edit_article_categories, edit_article_tags))
  //       }
  //     }
  //   }
  // };

  //更新イベントハンドラー
  const handleRegister = () => {
    if (editorIsToggled) {
      //一度でもセーブされてたらセーブした本文とタイトルをリクエストに載せる。
      if (savedTempIsEditing) {
        !savedTempTitleIsEditing && currentArticleStatus === 3
          ? //本文は編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合（最新のタイトルの状態がtitle_draft）
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitleDraft,
                savedTempContent,
                savedTempSearchString,
                edit_article_status,
                savedTempCategories,
                savedTempTags
              )
            )
          : //本文・タイトルともに編集済みの場合(最新情報が全て更新されている場合)
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitle,
                savedTempContent,
                savedTempSearchString,
                edit_article_status,
                savedTempCategories,
                savedTempTags
              )
            );
      } else {
        !savedTempTitleIsEditing && currentArticleStatus === 3
          ? //本文・タイトルともに未編集で、直近のステータスが下書きだった場合（最新の状態がdraftとtitle_draft）
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitleDraft,
                savedTempDraft,
                savedTempSearchString,
                edit_article_status,
                savedTempCategories,
                savedTempTags
              )
            )
          : //本文は未編集だが、タイトルは編集している場合（最新の状態がdraftとtitle）
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                savedTempTitle,
                savedTempDraft,
                savedTempSearchString,
                edit_article_status,
                savedTempCategories,
                savedTempTags
              )
            );
      }
    } else {
      //一度もセーブしてない場合
      if (articleIsEditing) {
        !articleTitleIsEdited && currentArticleStatus === 3
          ? // 本文編集済みで、タイトルは編集しておらず、直近のステータスが下書きだった場合 (本文最新: content, タイトル最新: title_draft)
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title_draft,
                edit_article_content,
                edit_article_search_string,
                edit_article_status,
                edit_article_categories,
                edit_article_tags
              )
            )
          : // 本文・タイトルを編集している場合 ( 本文最新: content, タイトル最新: title )
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title,
                edit_article_content,
                edit_article_search_string,
                edit_article_status,
                edit_article_categories,
                edit_article_tags
              )
            );
      } else {
        !articleTitleIsEdited && currentArticleStatus === 3
          ? // 本文、タイトルともに未編集で、直近のステータスが下書きだった場合 ( 本文最新: draft, タイトル最新: title_draft )
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title_draft,
                edit_article_draft,
                edit_article_search_string,
                edit_article_status,
                edit_article_categories,
                edit_article_tags
              )
            )
          : // 本文は未編集で、タイトルは編集している場合 ( 本文最新: draft, タイトル最新: title )
            dispatch(
              putArticleEditRequest(
                edit_article_id,
                token,
                edit_article_title,
                edit_article_draft,
                edit_article_search_string,
                edit_article_status,
                edit_article_categories,
                edit_article_tags
              )
            );
      }
    }
  };

  useEffect(() => {
    // タイトル入力ステータスを管理
    if (editorIsToggled) {
      //データ切り替えてたら、一時保存データを入れ込む。
      dispatch(inputEditArticleTitle(savedTempTitle));
    } else {
      dispatch(inputEditArticleTitle(title));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleArticleEditPostStatus(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetPreviousPublishStatus());
  }, [dispatch]);

  return (
    <>
      {post_status ? (
        history.push("/articles")
      ) : (
        <MainSC>
          <BreadCrumbAndToggleButtonWrapper>
            <PageBreadcrumb
              firstLink="/articles"
              firstTitle="FAQ一覧"
              secondTitle={title}
            />
            <ToggleButtonWrapper>
              <p>編集中記事</p>
              <div>
                <ToggleButton
                  id="toggleButton"
                  className="toggle-button"
                  type="checkbox"
                  onClick={handleToggleEditor}
                />
                <ToggleButtonLabel htmlFor="toggleButton"></ToggleButtonLabel>
              </div>

              <p>公開中記事</p>
            </ToggleButtonWrapper>
          </BreadCrumbAndToggleButtonWrapper>
          <ArticlesDetailEditBlockSC
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            {showEditor ? <ArticleEditor /> : <ArticleCurrentView />}
          </ArticlesDetailEditBlockSC>
          <TrashDescSC onClick={() => dispatch(toggleDeleteArticleModal(true))}>
            このFAQを削除
          </TrashDescSC>

          {title_input_status ? (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、ありません
              </ButtonAreaDeskSC>
              <SubmitButtonSC className="active" onClick={handleRegister}>
                登録
              </SubmitButtonSC>
              <PreviewButtonSC to="/articles/edit-preview">
                プレビュー
              </PreviewButtonSC>
            </FixedButtonAreaSC>
          ) : (
            <FixedButtonAreaSC
              className={sidebarIsOpen ? "" : "sidebar-closed"}
            >
              <ButtonAreaDeskSC>
                未入力の必須項目は、残り１つです
              </ButtonAreaDeskSC>
              <SubmitButtonSC>登録</SubmitButtonSC>
              <PreviewButtonSC to="/articles/edit-preview">
                プレビュー
              </PreviewButtonSC>
            </FixedButtonAreaSC>
          )}
        </MainSC>
      )}
    </>
  );
}

export default ArticlesDetailEdit;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const ArticlesDetailEditBlockSC = styled.form`
  width: 100%;
  background: ${color.white};
  padding: 24px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    padding: 30px;
    margin-right: 10px;
  `}
`;

// FixedButton
const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    margin-left: -30px;
    justify-content: center;
    align-items: center;
    left: auto;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${color.gray};
  ${pc`
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 14px;
    `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  margin-right: 12px;
  &.active {
    &:hover {
      opacity: 0.6;
    }
  }
`;

const PreviewButtonSC = styled(Link)`
  ${buttonBaseStyle}
  opacity: 1;
  color: ${color.primary};
  background-color: ${color.white};
  border: 1px solid ${color.primary};
  padding: 10px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const TrashDescSC = styled.p`
  color: ${color.primary};
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  padding-left: 18px;
  position: absolute;
  right: 30px;
  bottom: 110px;
  background-image: url(${trash});
  background-size: 12px 14px;
  background-position: left center;
  background-repeat: no-repeat;
  cursor: pointer;
  ${pc`
    bottom: 90px;
    left: 730px;
  `}
`;
const ToggleButtonWrapper = styled.div`
  display: flex;
  width: 240px;
  div {
    position: relative;
    width: 44px;
    margin: 0 10px;
  }
  p {
    padding: 6px 0;
    font-size: 12px;
  }
`;

const ToggleButton = styled.input`
  .toggle-size {
    width: auto;
    max-width: 60px;
    height: auto;
    max-height: 50px;
  }

  &.toggle-button {
    display: block;
    appearance: none;
    position: absolute;
    right: 0;
    width: 44px;
    height: 25px;
    border: 1px solid #b3b3b3;
    border-radius: 50px;
    background-color: #fff;
    transition: background-color 0.4s ease;
    &:focus {
      outline: none;
    }
    &:checked {
      background-color: ${color.primary};
    }
  }

  &.toggle-button + label {
    position: relative;
    display: inline-block;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border: 1px solid #b3b3b3;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    transition: left 0.4s ease, background-color 0.4s ease;
  }

  &.toggle-button:checked + label {
    position: relative;
    display: inline-block;
    top: 2px;
    left: 22px;
    width: 20px;
    height: 20px;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
`;

const BreadCrumbAndToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 810px;
  position: relative;
`;

const ToggleButtonLabel = styled.label``;
