import styled from "styled-components";

export const RadiobuttonSC = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
  margin: 0;
`;

export const RadioButtonInputSC = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border: 1px solid #444444;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10%;
  cursor: pointer;
  margin: 0;

  &:checked {
    border-color: #444444;
    background-color: #444444;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 4px;
      top: 4px;
      border: 3px solid #ffffff;
      border-radius: 50%;
    }
  }
`;

export const RadioButtonLabelSC = styled.label`
  font-size: 12px;
  margin-left: 22px;
  align-items: center;
`;
