import React, { useEffect } from "react";
import { color } from "../../mixins";
import { pc } from "../../mixins/mediaQuery";
import { useLocation } from "react-router-dom";
import NewsItem from "./NewsItem";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewsListRequest,
  getNewsListForUserRequest,
} from "../../../store/global/news/action";
import { toggleNewsNewPostStatus } from "../../../store/global/news_new/action";
import { toggleNewsEditPostStatus } from "../../../store/global/news_edit/action";
import { animateScroll as scroll } from "react-scroll";

function NewsList() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const newsList = useSelector((state: any) => state.news);
  const news_page_num = useSelector(
    (state: any) => state.paginate.news_page_num
  );
  const paginate_news_per_page = useSelector(
    (state: any) => state.paginate.news_per_page
  );
  const location = useLocation();
  const pathName = location.pathname;
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);

  // 一覧取得
  useEffect(() => {
    scroll.scrollToTop({ duration: 200 });
    if (isAdmin || isEditor) {
      dispatch(
        getNewsListRequest(
          token,
          news_page_num,
          paginate_news_per_page,
          pathName
        )
      );
    } else {
      dispatch(
        getNewsListForUserRequest(
          token,
          news_page_num,
          paginate_news_per_page,
          pathName
        )
      );
    }
    // dispatch(getNewsListRequest(token, news_page_num, paginate_news_per_page, isAdmin, isEditor, pathName))
  }, [dispatch, token]);

  // 投稿ステータス変更
  useEffect(() => {
    dispatch(toggleNewsNewPostStatus(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleNewsEditPostStatus(false));
  }, [dispatch]);

  return (
    <NewsListSC>
      {newsList &&
        newsList.length > 0 &&
        newsList.map((news: any) => {
          if (news.title !== null && news.title_draft === null) {
            return (
              <NewsItem
                key={news.id}
                id={news.id}
                title={news.title}
                content={news.content}
                status={news.status}
                updated_at={news.updated_at}
                read={news.read}
                is_news_edit_list={pathName === "/news-edit-list"}
              />
            );
          } else if (news.title === null && news.title_draft !== null) {
            return (
              <NewsItem
                key={news.id}
                id={news.id}
                title={news.title_draft}
                content={news.content}
                status={news.status}
                updated_at={news.updated_at}
                read={news.read}
                is_news_edit_list={pathName === "/news-edit-list"}
              />
            );
          } else {
            return (
              <NewsItem
                key={news.id}
                id={news.id}
                title={news.title_draft}
                content={news.content}
                status={news.status}
                updated_at={news.updated_at}
                read={news.read}
                is_news_edit_list={pathName === "/news-edit-list"}
              />
            );
          }
        })}
    </NewsListSC>
  );
}

const NewsListSC = styled.ul`
  width: 100%;
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;

export default NewsList;
