import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { GET_INQUIRIES_SUCCEEDED, GET_INQUIRIES_FAILED } from "./type";

interface Inquiry {
  id: number | null;
  user_id: number | null;
  user_name: string;
  inquiry_name: string;
  employee_id: string;
  facility: string;
  summary: string;
  terminal: string;
  terminal_details: string;
  child_id: string;
  model: string;
  model_name: string;
  proxy_login: boolean;
  details: string;
  backlog_id: number | null;
  backlog_status: string;
  created_at: string;
  updated_at: string;

  post_status: boolean;
}
const initialState: Inquiry[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INQUIRIES_SUCCEEDED:
      return [...action.inquiries];

    case GET_INQUIRIES_FAILED:
      console.log("error", action.message);
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
