const SET_CURRENT_PAGE_REQUESTED = "SET_CURRENT_PAGE_REQUESTED";
const SET_CURRENT_PAGE_SUCCEEDED = "SET_CURRENT_PAGE_SUCCEEDED";
const SET_CURRENT_PAGE_FAILED = "SET_CURRENT_PAGE_FAILED";

const SET_PER_PAGE = "SET_PER_PAGE";

const SET_TOTAL = "SET_TOTAL";

const SET_KEYWORD = "SET_KEYWORD";

const SET_DATA_PER_PAGE = "SET_DATA_PER_PAGE";

const SET_ACCOUNTS_PER_PAGE = "SET_ACCOUNTS_PER_PAGE";

const SET_ACCOUNTS_TOTAL = "SET_ACCOUNTS_TOTAL";


const SET_ACCOUNTS_CURRENT_PAGE = "SET_ACCOUNTS_CURRENT_PAGE";

const SET_ACCOUNTS_LAST_PAGE = "SET_ACCOUNTS_LAST_PAGE";

const SET_ACCOUNT_EMAIL_TO_SEARCH = "SET_ACCOUNT_EMAIL_TO_SEARCH";

const SET_STOCKS_TOTAL = "SET_STOCKS_TOTAL"

const SET_STOCKS_CURRENT_PAGE_SUCCEEDED = "SET_STOCKS_CURRENT_PAGE_SUCCEEDED"

const SET_SEARCH_STATUS = "SET_SEARCH_STATUS"

export {
  SET_CURRENT_PAGE_REQUESTED, SET_CURRENT_PAGE_SUCCEEDED, SET_CURRENT_PAGE_FAILED, SET_PER_PAGE, SET_TOTAL, SET_KEYWORD, SET_ACCOUNTS_PER_PAGE, SET_ACCOUNTS_TOTAL, SET_ACCOUNTS_CURRENT_PAGE, SET_ACCOUNTS_LAST_PAGE, SET_ACCOUNT_EMAIL_TO_SEARCH, SET_DATA_PER_PAGE, SET_STOCKS_TOTAL, SET_STOCKS_CURRENT_PAGE_SUCCEEDED, SET_SEARCH_STATUS
}
