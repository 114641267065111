import React from "react";
import styled from "styled-components";
import ModuleContainer from "components/Manual/Parts/ModuleContainer";
import Topics from "components/Manual/PageDetail/indexModule/Topics";
import useHooks from "components/Manual/PageDetail/indexModule/Hooks";
import closeModal from "images/icons/close-page-detail.svg";
const IndexModalSP = (props: {
  index: any;
  setIndexModalSPDisplay: (indexModalSPDisplya: boolean) => void;
  indexModalSPDisplay: boolean;
}) => {
  //  hooks
  const { index, setIndexModalSPDisplay, indexModalSPDisplay } = props;
  const { pageId } = useHooks();

  return (
    <>
      <ModuleContainerSPBG
        onClick={() => setIndexModalSPDisplay(false)}
      ></ModuleContainerSPBG>
      <ModuleContainerSP>
        <CloseBtn
          src={closeModal}
          onClick={() => setIndexModalSPDisplay(false)}
        />
        <BlockHeadingSC>インデックス</BlockHeadingSC>
        {index &&
          index.topics &&
          index.topics.map((val: any) => {
            return <Topics key={val.id} topic={val} pageId={pageId} />;
          })}
      </ModuleContainerSP>
    </>
  );
};

export default IndexModalSP;

const CloseBtn = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

const ModuleContainerSP = styled.div`
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 90%;
  height: calc(90% - 56px);
  margin: auto;
  background: #fff;
  z-index: 2;
  padding: 20px;
`;
const ModuleContainerSPBG = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
`;
const BlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  padding-right: 10px;
  margin-bottom: 10px;
`;
