import {
  GET_ACCOUNT_EDIT_SUCCEEDED,
  GET_ACCOUNT_EDIT_FAILED,
  PUT_ACCOUNT_EDIT_SUCCEEDED,
  PUT_ACCOUNT_EDIT_FAILED,
  DELETE_ACCOUNT_EDIT_SUCCEEDED,
  DELETE_ACCOUNT_EDIT_FAILED,
  SET_EDIT_ACCOUNT_ID,
  SET_EDIT_ACCOUNT_NAME,
  SET_EDIT_ACCOUNT_POSITION_ID,
  SET_EDIT_ACCOUNT_EMAIL,
  SET_EDIT_ACCOUNT_PASSWORD,
  SET_EDIT_ACCOUNT_FACILITIES,
  TOGGLE_EDIT_ACCOUNT_POST_STATUS,
  GET_ACCOUNT_EDIT_FAILED_404,
} from "./type";

import { GET_ACCOUNTS_SUCCEEDED } from "../accounts/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";

const initialState = {
  id: null,
  name: "",
  position_id: null,
  email: "",
  password: "",
  post_status: false,
  message: "",
  facilities: [
    {
      id: null,
    },
  ],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ACCOUNTS_SUCCEEDED:
      return {
        ...state,
        post_status: false,
      };
    case GET_ACCOUNT_EDIT_SUCCEEDED:
      return {
        ...state,
        ...action.account_edit,
        password: "",
        message: "",
      };

    case GET_ACCOUNT_EDIT_FAILED:
      console.log("error", action.message);
      return state;

    case GET_ACCOUNT_EDIT_FAILED_404:
      return {
        ...state,
        message: action.message,
      };

    case PUT_ACCOUNT_EDIT_SUCCEEDED:
      console.log("ユーザー編集登録に成功しました status: ", action.status);
      return {
        id: null,
        name: "",
        position_id: null,
        email: "",
        password: "",
        post_status: true,
      };

    case PUT_ACCOUNT_EDIT_FAILED:
      console.log("投稿編集登録作成エラー エラーコード:", action.error_code);
      return state;

    case DELETE_ACCOUNT_EDIT_SUCCEEDED:
      console.log("ユーザー削除に成功しました status: ", action.status);
      return {
        id: null,
        name: "",
        position_id: null,
        email: "",
        password: "",
        post_status: true,
      };

    case DELETE_ACCOUNT_EDIT_FAILED:
      console.log("ユーザー削除エラー エラーコード:", action.error_code);
      return state;

    case SET_EDIT_ACCOUNT_ID:
      return {
        ...state,
        id: action.id,
        name: action.name,
        position_id: action.position_id,
        email: action.email,
        password: "",
        facilities: [],
      };

    case SET_EDIT_ACCOUNT_NAME:
      return {
        ...state,
        name: action.name,
      };

    case SET_EDIT_ACCOUNT_POSITION_ID:
      return {
        ...state,
        position_id: action.position_id,
      };

    case SET_EDIT_ACCOUNT_EMAIL:
      return {
        ...state,
        email: action.email,
      };

    case SET_EDIT_ACCOUNT_PASSWORD:
      return {
        ...state,
        password: action.password,
      };

    case SET_EDIT_ACCOUNT_FACILITIES:
      if (
        state.facilities.some(
          (facility: any) => facility.id === action.facility_id
        )
      ) {
        return {
          ...state,
          facilities: state.facilities.filter(
            (facility: any) => facility.id !== action.facility_id
          ),
        };
      }
      return {
        ...state,
        facilities: [
          ...state.facilities,
          {
            id: action.facility_id,
          },
        ],
      };

    case TOGGLE_EDIT_ACCOUNT_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
