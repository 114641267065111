import { all, put, call, takeLatest } from "redux-saga/effects";
import { BEGIN_SESSION_REQUESTED } from "../store/session/type";
import {
  GET_FACILITIES_SUCCEEDED,
  GET_FACILITIES_FAILED,
  GET_FACILITIES_REQUESTED
} from "../store/event/facilities/type";

import { getFacilitiesApi } from "../api/global/facilitiesApi";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";

function* getFacilities(action: any) {
  try {
    const result = yield call(getFacilitiesApi, action.token);
    console.log("action.token", action.token);
    console.log("result_facilities", result);
    yield all([
      put({
        type: GET_FACILITIES_SUCCEEDED,
        facilities: result.data.facilities
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const facilitiesSaga = [takeLatest(GET_FACILITIES_REQUESTED, getFacilities)];

export default facilitiesSaga;
