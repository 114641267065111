import { inputBaseStyle } from "components/mixins";
import { pc } from "components/mixins/mediaQuery";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface TextInputProps {
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  multiple?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  return (
    <InputBoxSC
      multiple={props.multiple}
      as={props.multiple ? "textarea" : "input"}
      {...props}
    />
  );
};

const InputBoxSC = styled.input<{ multiple?: boolean }>`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    width: 400px;
  `}
  &#user_name {
    border: none;
  }
  ${props =>
    props.multiple &&
    `
    min-height: 150px;
  `}
`;
