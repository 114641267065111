const GET_ARTICLE_REQUESTED = "GET_ARTICLE_REQUESTED";
const GET_ARTICLE_SUCCEEDED = "GET_ARTICLE_SUCCEEDED";
const GET_ARTICLE_FAILED = "GET_ARTICLE_FAILED";

const READ_ARTICLE_REQUESTED = "READ_ARTICLE_REQUESTED";
const READ_ARTICLE_SUCCEEDED = "READ_ARTICLE_SUCCEEDED";
const READ_ARTICLE_FAILED = "READ_ARTICLE_FAILED";

const UPDATE_ARTICLE_REQUESTED = "UPDATE_ARTICLE_REQUESTED";
const UPDATE_ARTICLE_SUCCEEDED = "UPDATE_ARTICLE_SUCCEEDED";
const UPDATE_ARTICLE_FAILED = "UPDATE_ARTICLE_FAILED";

const POST_ARTICLE_STOCK_REQUESTED = "POST_ARTICLE_STOCK_REQUESTED";
const POST_ARTICLE_STOCK_SUCCEEDED = "POST_ARTICLE_STOCK_SUCCEEDED";
const POST_ARTICLE_STOCK_FAILED = "POST_ARTICLE_STOCK_FAILED";

const DELETE_ARTICLE_STOCK_REQUESTED = "DELETE_ARTICLE_STOCK_REQUESTED";
const DELETE_ARTICLE_STOCK_SUCCEEDED = "DELETE_ARTICLE_STOCK_SUCCEEDED";
const DELETE_ARTICLE_STOCK_FAILED = "DELETE_ARTICLE_STOCK_FAILED";

const SET_ARTICLE_DETAIL_ID = "SET_ARTICLE_DETAIL_ID";

const RESET_ARTCLE_DETAIL = "RESET_ARTCLE_DETAIL";

const TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED = "TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED";

const GET_ARTICLE_FAILED_404 = "GET_ARTICLE_FAILED_404";

const RESET_ARTICLE_DETAIL_MESSAGE = "RESET_ARTICLE_DETAIL_MESSAGE";

export { GET_ARTICLE_REQUESTED, GET_ARTICLE_SUCCEEDED, GET_ARTICLE_FAILED, READ_ARTICLE_REQUESTED, READ_ARTICLE_SUCCEEDED, READ_ARTICLE_FAILED, UPDATE_ARTICLE_REQUESTED, UPDATE_ARTICLE_SUCCEEDED, UPDATE_ARTICLE_FAILED, POST_ARTICLE_STOCK_REQUESTED, POST_ARTICLE_STOCK_SUCCEEDED, POST_ARTICLE_STOCK_FAILED, DELETE_ARTICLE_STOCK_REQUESTED, DELETE_ARTICLE_STOCK_SUCCEEDED, DELETE_ARTICLE_STOCK_FAILED, SET_ARTICLE_DETAIL_ID, RESET_ARTCLE_DETAIL, TOGGLE_ARTICLE_DETAIL_CONTENT_LOADED, GET_ARTICLE_FAILED_404, RESET_ARTICLE_DETAIL_MESSAGE };
