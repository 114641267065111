import React from "react";
import styled from "styled-components";
import { pc } from "components/mixins/mediaQuery";
import { color } from "components/mixins";
import openRightSectionicon from "images/icons/open-right-section.svg";
import closeRightSectionicon from "images/icons/close-right-section.svg";
import { useDispatch } from "react-redux";
import { setRightSectionOpen } from "store/common/sidebar/action";

// ===================================================================================
// スタイル
// ===================================================================================
export const SiteContainerSC = styled.div`
  width: 100%;
`;

export const LayoutContainerSC = styled.div`
  display: flex;
  justify-content: space-between;
  ${pc`
    height: calc(100% - 60px);
  `}
`;

export const MainSC = styled.main`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  margin-right: 0;
  margin-left: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  background: ${color.primary_white};
  ${pc`
    height: 100%;
    width: calc(100% - 200px);
    padding-top: 0;
    padding-bottom: 60px;
    overflow-y: auto;
    margin-right: 0;
    margin-left: auto;
    background: #F2F2F2;
    position: relative;
    top: 60px;
  `}
  @media (min-width: 1025px) {
    &.closed {
      width: calc(100% - 50px);
      transition-duration: 0.2s;
    }
  }
`;

export const MainContainerSC = styled.div`
  width: 100%;
  position: relative;
  ${pc`
    position: static;
  `}
  &.signIn {
    position: static;
  }
`;

const scrollTop = (): number => {
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
};
interface RightSectionTogglerProps {
  className: string;
  onClick: () => void;
}
export const RightSectionToggler = (props: RightSectionTogglerProps) => {
  const [isTop, setIsTop] = React.useState<boolean>(true);
  const mediaQuery =
    typeof window !== "undefined" && window.matchMedia("(max-width: 1024px)");
  const dispatch = useDispatch();
  const mediaQueryChangeEvent = (e: MediaQueryListEvent) => {
    if (e.matches) {
      dispatch(setRightSectionOpen(true));
    }
    mediaQuery &&
      mediaQuery.removeEventListener("change", mediaQueryChangeEvent);
  };
  if (mediaQuery) {
    mediaQuery.addEventListener("change", mediaQueryChangeEvent);
  }
  const onScroll = (): void => {
    const position = scrollTop();
    if (position >= 80) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };
  React.useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return (): void => document.removeEventListener("scroll", onScroll);
  });
  return (
    <StyledRightSectionToggler
      className={`${props.className} ${isTop ? "" : "fixed"}`}
      onClick={props.onClick}
    />
  );
};

const StyledRightSectionToggler = styled.button`
  display: none;
  ${pc`
      display: block;
      position: absolute;
      top: 18px;
      right: 28px;
      content: "";
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 1px solid ${color.gray_border};
      background-size: 22px;
      background-position: center;
      background-repeat: no-repeat;
      &:hover {
        opacity: 0.6;
      }
      &.open {
        background-color: ${color.white};
        background-image: url(${closeRightSectionicon});
      }
      &.closed {
        background-color: ${color.primary};
        background-image: url(${openRightSectionicon});
      }
      &.event {
        position: relative;
        margin-left: 12px;
        top: auto;
        right: auto;
        &.fixed {
          position: fixed;
          z-index: 100;
          box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
          right: 60px;
        }
      }
      &.fixed {
        position: fixed;
        z-index: 100;
        box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
        right: 60px;
        top: 87px;
      }
    `}
`;
