import { all, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_EVENTS_LIST_SUCCEEDED,
  GET_EVENTS_LIST_REQUESTED,
  DELETE_EVENT_REQUESTED,
  DELETE_EVENT_SUCCEEDED,
  POST_EVENT_REQUESTED,
  POST_EVENT_SUCCEEDED,
  PUT_EVENT_REQUESTED,
  PUT_EVENT_SUCCEEDED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED,
  POST_EVENTS_APPLY_REQUESTED,
  POST_EVENTS_APPLY_SUCCEEDED,
  GET_EVENTS_TYPE_REQUESTED,
  GET_EVENTS_TYPE_SUCCEEDED
} from "../store/event/events/type";

import {
  GET_EVENT_CSV_REQUESTED,
  GET_EVENT_CSV_SUCCEEDED
} from "../store/event/event_csv/type";

import {
  getEventsApi,
  postEventApi,
  putEventApi,
  deleteEventApi,
  getEventsAfterSaveApi,
  getEventsCSVApi,
  postEventsApplyApi,
  getEventsTypesApi,
  getUsageGuidesApi,
  getCalendarApi
} from "../api/event/eventApi";

import { getMonthlyEventsAfterSaveApi } from "../api/event/monthlyEventsApi";

import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { getApprovalsAfterPostApi } from "../api/event/approvalsApi";
import { GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED } from "../store/event/approval/type";
import {
  GET_CALENDAR_REQUESTED,
  GET_CALENDAR_SUCCEEDED,
  GET_USAGE_GUIDES_REQUESTED,
  GET_USAGE_GUIDES_SUCCEEDED
} from "store/event/events_top/type";
function* getEvents(action: any) {
  try {
    const result = yield call(
      getEventsApi,
      action.token,
      action.facility_id,
      action.filtered_year,
      action.type_ids,
      action.filtered_months,
      action.filtered_class,
      action.filtered_remand
    );
    yield all([
      put({ type: GET_EVENTS_LIST_SUCCEEDED, events: result.data.events })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* deleteEvent(action: any) {
  try {
    yield deleteEventApi(action.token, action.id)
    const results = yield* getEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_months
    );

    yield all([
      put({
        type: DELETE_EVENT_SUCCEEDED
      }),
      put({
        type: GET_EVENTS_LIST_SUCCEEDED,
        events: results.data.events
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postEvent(action: any) {
  try {
    const checked_classes = action.classes
      .filter((class_item: any) => class_item.checked)
      .map((class_item: any) => class_item.class_age);
    const classes_to_post = checked_classes.join(",");
    console.log("checked_classes", checked_classes);
    console.log("classes_to_post", classes_to_post);

    const post_result = yield* postEventApi(
      action.token,
      action.facility_id,
      action.year,
      action.month,
      action.date,
      action.type_id,
      action.content,
      action.detail,
      classes_to_post
    );
    const result = yield* getEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_month
    );
    yield all([
      put({
        type: POST_EVENT_SUCCEEDED,
        status: post_result.data.status,
        id: post_result.data.id
      }),
      put({ type: GET_EVENTS_LIST_SUCCEEDED, events: result.data.events })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* putEvent(action: any) {
  try {
    const checked_classes = action.classes
      .filter((class_item: any) => class_item.checked)
      .map((class_item: any) => class_item.class_age);
    const classes_to_put = checked_classes.join(",");

    const put_result = yield* putEventApi(
      action.event_id,
      action.token,
      action.facility_id,
      action.year,
      action.month,
      action.date,
      action.type_id,
      action.content,
      action.detail,
      classes_to_put
    );
    const result = yield* getEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_month,
      action.filtered_class,
      action.filtered_remand
    );
    const result_monthly = yield* getMonthlyEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.year,
      action.filtered_months,
      action.filtered_class,
      action.filtered_remand
    );

    yield all([
      put({
        type: PUT_EVENT_SUCCEEDED,
        status: put_result.status,
        result: put_result
      }),
      put({
        type: GET_EVENTS_LIST_SUCCEEDED,
        events: result.data.events,
        monthly_events: result_monthly.data.events
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getEventsCSV(action: any) {
  try {
    const result = yield call(
      getEventsCSVApi,
      action.token,
      action.facility_id,
      action.year
    );
    console.log("action.token", action.token);
    console.log("RESULT", result);
    const result_headers = result.headers;

    yield all([
      put({
        type: GET_EVENT_CSV_SUCCEEDED,
        csv: result.data,
        result_headers: result_headers
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postEventsApply(action: any) {
  try {
    const post_result = yield* postEventsApplyApi(
      action.token,
      action.facility_id,
      action.months
    );
    const approvals_after_post = yield* getApprovalsAfterPostApi(
      action.token,
      action.facility_id,
      action.filtered_year
    );
    const event_after_apply = yield* getEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_month
    );
    console.log("post_result", post_result);

    yield all([
      put({
        type: POST_EVENTS_APPLY_SUCCEEDED,
        status: post_result.data.status
      }),
      put({
        type: GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
        result: approvals_after_post.data
      }),
      put({
        type: GET_EVENTS_LIST_SUCCEEDED,
        events: event_after_apply.data.events
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// 行事予定の種類を取得
function* getEventTypes(action: any) {
  try {
    const result = yield getEventsTypesApi(action.token);
    console.log("result", result);
    yield all([put({ type: GET_EVENTS_TYPE_SUCCEEDED, result: result.data })]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// 年間の行事予定を取得
function* getAnnualEvents(action: any) {
  try {
    const result = yield call(
      getEventsApi,
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_months,
      action.filtered_class,
      action.filtered_remand
    );
    yield all([
      put({
        type: GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED,
        events: result.data.events
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getUsageGuides(action: any) {
  try {
    const response = yield call(getUsageGuidesApi, action.token, action.year);
    yield put({
      type: GET_USAGE_GUIDES_SUCCEEDED,
      usage_guides: response.data.usage_guides
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* getCalender(action: any) {
  try {
    const response = yield call(
      getCalendarApi,
      action.token,
      action.facility_id,
      action.year
    );
    yield put({
      type: GET_CALENDAR_SUCCEEDED,
      calendars: response.data.calendars
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const eventsSaga = [
  takeLatest(GET_EVENTS_LIST_REQUESTED, getEvents),
  takeLatest(POST_EVENT_REQUESTED, postEvent),
  takeLatest(DELETE_EVENT_REQUESTED, deleteEvent),
  takeLatest(PUT_EVENT_REQUESTED, putEvent),
  takeLatest(GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED, getAnnualEvents),
  takeLatest(GET_EVENT_CSV_REQUESTED, getEventsCSV),
  takeLatest(POST_EVENTS_APPLY_REQUESTED, postEventsApply),
  takeLatest(GET_EVENTS_TYPE_REQUESTED, getEventTypes),
  takeLatest(GET_USAGE_GUIDES_REQUESTED, getUsageGuides),
  takeLatest(GET_CALENDAR_REQUESTED, getCalender)
];

export default eventsSaga;
