import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { historiesSelector } from "store/manual/histories/selectors";
import {
  getHistoriesRequest,
  updateHistoryRequest
} from "../../../../store/manual/histories/actions";
import { History } from "../../demoData";

export const useHooks = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const historiesData = useSelector(historiesSelector);
  const [histories, setHistories] = React.useState<History[]>([]);

  React.useEffect(() => {
    dispatch(getHistoriesRequest(token));
  }, [dispatch, token]);

  React.useEffect(() => {
    setHistories(historiesData);
  }, [historiesData]);

  /**表示チェックボックスの切り替え */
  const toggleHistoryDisplay = (id: number) => {
    setHistories(() => {
      const toggleDisplayArray = histories.map(history => {
        if (history.id === id) {
          return {
            ...history,
            priority: !history.priority
          };
        } else {
          return history;
        }
      });
      return toggleDisplayArray;
    });
  };

  /**
   * 更新ボタンクリックした時のロジック
   * コンポーネントステートで持っている値（今まさに表示されている状態）を元にリクエストをforeachで投げる
   */
  const handleUpdateBtnClick = () => {
    histories.forEach(history => {
      dispatch(updateHistoryRequest(token, history.id, history.priority));
    });
  };

  console.log(histories);
  return {
    histories,
    toggleHistoryDisplay,
    handleUpdateBtnClick
  };
};
