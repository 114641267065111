import {
  SET_EVENT_ADD_INITIAL_DATE,
  SET_ADD_EVENT_DATE,
  SET_ADD_EVENT_TYPE,
  SET_ADD_EVENT_CONTENT,
  SET_ADD_EVENT_DETAIL,
  SET_ADD_EVENT_CLASS,
  RESET_ADD_EVENT_CONTENT,
  RESET_ADD_EVENT_CLASS,
} from "./type";

export const setAddEventModalInitialDate = (
  yearMonthDate: Date,
  year: number,
  month: number,
  date: number
) => {
  return {
    type: SET_EVENT_ADD_INITIAL_DATE,
    yearMonthDate,
    year,
    month,
    date,
  };
};

export const resetAddEventModalContent = () => {
  return {
    type: RESET_ADD_EVENT_CONTENT,
  };
};

export const setAddEventDate = (year: number, month: number, date: number) => {
  return {
    type: SET_ADD_EVENT_DATE,
    year,
    month,
    date,
  };
};
export const setAddEventType = (
  type_id: number | string,
  type_name: string
) => {
  return {
    type: SET_ADD_EVENT_TYPE,
    type_id,
    type_name,
  };
};
export const setAddEventContent = (content: string) => {
  return {
    type: SET_ADD_EVENT_CONTENT,
    content,
  };
};
export const setAddEventDetail = (detail: string) => {
  return {
    type: SET_ADD_EVENT_DETAIL,
    detail,
  };
};
export const setAddEventClass = (classes_class_age: string) => {
  return {
    type: SET_ADD_EVENT_CLASS,
    classes_class_age,
  };
};
export const resetAddEventClass = () => {
  return {
    type: RESET_ADD_EVENT_CLASS,
  };
};
