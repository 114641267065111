import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { resetResponseStatus } from "store/session/action";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function Error403() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, []);
  return (
    <React.Fragment>
      <Container>
        <h2>権限エラー</h2>
        <Description>その操作はできません。</Description>
        <BackLink to="/" onClick={() => dispatch(resetResponseStatus())}>
          TOPにもどる
        </BackLink>
      </Container>
    </React.Fragment>
  );
}

export default Error403;

const Container = styled.div`
  padding: 30px;
`;

const Description = styled.p`
  margin-bottom: 1rem;
`;
const BackLink = styled(Link)`
  font-weight: bold;
`;
