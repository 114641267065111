import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import { getAccountsRequest } from "../../../store/global/accounts/action";
import { setAccountEditId } from "../../../store/global/account_edit/action";

const AccountsList = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const accounts = useSelector((state: any) => state.accounts);
  const accounts_page_num = 1;
  const email_to_search = "";

  const paginate_per_page = useSelector(
    (state: any) => state.paginate.account_per_page
  );

  // アカウント一覧取得
  useEffect(() => {
    dispatch(
      getAccountsRequest(
        token,
        accounts_page_num,
        paginate_per_page,
        email_to_search
      )
    );
  }, [dispatch, token]);

  return (
    <AccountsListContainerSC>
      <AccountsListHeadingListSC>
        <AccountsListHeadingItemSC>ユーザ名</AccountsListHeadingItemSC>
        <AccountsListHeadingItemSC>権限</AccountsListHeadingItemSC>
        <AccountsListHeadingItemSC>メールアドレス</AccountsListHeadingItemSC>
      </AccountsListHeadingListSC>
      {accounts &&
        accounts.length > 0 &&
        accounts.map((account: any, index: number) => {
          const name = account.name;
          const role = account.position.name;
          const email = account.email;
          const id = account.id;
          return (
            <AccountsListSC>
              <AccountsListItemSC>
                <AccountEditLinkSC
                  to={"accounts/" + id + "/edit"}
                  onClick={() =>
                    dispatch(
                      setAccountEditId(
                        Number(id),
                        name,
                        account.position.id,
                        email
                      )
                    )
                  }
                >
                  <p>{name}</p>
                  <p>{role}</p>
                  <p>{email}</p>
                </AccountEditLinkSC>
              </AccountsListItemSC>
            </AccountsListSC>
          );
        })}
    </AccountsListContainerSC>
  );
};

export default AccountsList;

// ===================================================================================
// スタイル
// ===================================================================================

const AccountsListContainerSC = styled.div`
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  overflow: hidden;
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;

const AccountsListHeadingListSC = styled.ul`
  display: flex;
  align-items: center;
  background-color: ${color.primary_white};
  border-bottom: 1px solid ${color.list_border};
  color: ${color.tag_color};
  font-size: 14px;
  padding: 13px 20px;
`;

const AccountsListHeadingItemSC = styled.li`
  font-size: 14px;
  color: ${color.gray};

  &:nth-child(1) {
    width: 25%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 50%;
  }
`;

const AccountsListSC = styled.ul`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
`;

const AccountsListItemSC = styled.li`
  color: ${color.gray};
  font-size: 14px;
  width: 100%;
`;

const AccountEditLinkSC = styled(Link)`
  display: flex;
  width: 100%;
  padding: 16px 20px 16px 20px;
  &:hover {
    background-color: ${color.primary_white};
  }
  p {
    &:nth-child(1) {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 50%;
    }
  }
`;
