/**
 * 基になる配列に対して、targetのIDを削除・追加を行う
 * @param arr 基になる配列
 * @param target 対象のID
 */
export const numArrayGen = (arr: number[], target: number) => {
  if (arr.includes(target)) {
    return arr.filter(num => num !== target);
  } else {
    return [...arr, target];
  }
};
