import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc, minSp } from "../../mixins/mediaQuery";
import {
  color,
  inputBaseStyle,
  containerBaseStyle,
  buttonBaseStyle,
} from "../../mixins";
import deleteIcon from "../../../images/icons/close.svg";
import {
  deleteTagItemFromView,
  updateViewTagName,
  updateViewTagOrder,
} from "../../../store/faq/tags/action";
import {
  addToBeCreated,
  changeNameToBeCreated,
  changeOrderToBeCreated,
  deleteToBeCreated,
} from "../../../store/faq/tags_to_be_created/action";
import {
  addToBeUpdated,
  changeNameToBeUpdated,
  changeOrderToBeUpdated,
  deleteToBeUpdated,
} from "../../../store/faq/tags_to_be_updated/action";
import { addToBeDeleted } from "../../../store/faq/tags_to_be_deleted/action";

const TagsList = () => {
  const tags = useSelector((state: any) => state.tags);
  const tags_to_be_created = useSelector(
    (state: any) => state.tags_to_be_created
  );
  const dispatch = useDispatch();

  // ==========================================================================
  // 新規カテゴリ
  // ==========================================================================
  const handleAddCategory = (e: any) => {
    console.log("clicked");
    e.preventDefault();
    dispatch(addToBeCreated());
  };
  // 新規:カテゴリー欄を削除
  const handleDeleteAddedTag = (e: any, index: number) => {
    e.preventDefault();
    const target_remove_index = index;
    dispatch(deleteToBeCreated(target_remove_index));
  };
  // 新規:カテゴリ名編集
  const handleChangeNameTobeCreated = (e: any) => {
    const changed_name = e.target.value;
    const changed_target_id = Number(e.target.id);
    dispatch(changeNameToBeCreated(changed_name, changed_target_id));
  };
  //新規:並び順編集
  const handleChangeOrderTobeCreated = (e: any) => {
    const changed_order = Number(e.target.value);
    const changed_target_id = Number(e.target.id);
    dispatch(changeOrderToBeCreated(changed_order, changed_target_id));
  };
  // ==========================================================================

  // ==========================================================================
  // 既存カテゴリ
  // ==========================================================================
  //フォーカスが当たったらto_be_updatedに追加
  const handleUpdateFocus = (e: any, tags: []) => {
    const update_target_id = Number(e.target.id);
    dispatch(addToBeUpdated(update_target_id, tags));
  };

  const handleUpdateTagName = (e: any) => {
    const updated_name = e.target.value;
    const updated_target_id = Number(e.target.id);
    dispatch(updateViewTagName(updated_name, updated_target_id));
    dispatch(changeNameToBeUpdated(updated_name, updated_target_id));
  };

  // カテゴリー並び順編集
  const handleUpdateTagOrder = (e: any) => {
    const updated_order = Number(e.target.value);
    const updated_target_id = Number(e.target.id);
    dispatch(updateViewTagOrder(updated_order, updated_target_id));
    dispatch(changeOrderToBeUpdated(updated_order, updated_target_id));
  };

  // カテゴリー削除(VIEW)
  const handleRemoveTag = (e: any, index: number, tags: []) => {
    e.preventDefault();
    const target_remove_index = index;
    dispatch(addToBeDeleted(target_remove_index, tags));
    dispatch(deleteTagItemFromView(target_remove_index));
  };
  // ==========================================================================

  return (
    <TagsListContainerSC>
      <form action="">
        <TagsListHeadingListSC>
          <TagsListHeadingItemSC>タグ名</TagsListHeadingItemSC>
          <TagsListHeadingItemSC>並び順</TagsListHeadingItemSC>
          <TagsListHeadingItemSC>該当記事件数</TagsListHeadingItemSC>
          <TagsListHeadingItemSC>削除</TagsListHeadingItemSC>
        </TagsListHeadingListSC>
        <div>
          {tags[0] === null
            ? ""
            : tags.map((tag: any, index: number) => {
                return (
                  <TagsListSC>
                    <TagsListItemSC>
                      <TagsListInputSC
                        className="name"
                        type="text"
                        id={tag.id}
                        value={tag.name}
                        onChange={handleUpdateTagName}
                        onFocus={(e) => handleUpdateFocus(e, tags)}
                      />
                    </TagsListItemSC>
                    <TagsListItemSC>
                      <TagsListInputSC
                        className="order"
                        type="number"
                        id={tag.id}
                        value={tag.order}
                        onChange={handleUpdateTagOrder}
                        onFocus={(e) => handleUpdateFocus(e, tags)}
                        min={0}
                      />
                    </TagsListItemSC>
                    {tag.articles_count ? (
                      <TagsListItemSC>{tag.articles_count}</TagsListItemSC>
                    ) : (
                      <TagsListItemSC />
                    )}
                    <TagsListItemSC>
                      <TagsListDeleteButtonSC
                        onClick={(e) => handleRemoveTag(e, index, tags)}
                      />
                    </TagsListItemSC>
                  </TagsListSC>
                );
              })}
          {tags_to_be_created.map((add_item: any, index: number) => {
            return (
              <TagsListSC>
                <TagsListItemSC>
                  <TagsListInputSC
                    id={add_item.temp_id}
                    className="name"
                    type="text"
                    value={add_item.name}
                    onChange={handleChangeNameTobeCreated}
                  />
                </TagsListItemSC>
                <TagsListItemSC>
                  <TagsListInputSC
                    id={add_item.temp_id}
                    className="order"
                    type="number"
                    value={add_item.order}
                    onChange={handleChangeOrderTobeCreated}
                    min={0}
                  />
                </TagsListItemSC>
                {add_item.articles_count ? (
                  <TagsListItemSC>{add_item.articles_count}</TagsListItemSC>
                ) : (
                  <TagsListItemSC />
                )}
                <TagsListItemSC>
                  <TagsListDeleteButtonSC
                    onClick={(e) => handleDeleteAddedTag(e, index)}
                  />
                </TagsListItemSC>
              </TagsListSC>
            );
          })}
        </div>
        <TagsListSC as="div">
          <TagsListAddButtonSC onClick={handleAddCategory}>
            追加
          </TagsListAddButtonSC>
        </TagsListSC>
      </form>
    </TagsListContainerSC>
  );
};

export default TagsList;

// ===================================================================================
// スタイル
// ===================================================================================

const TagsListContainerSC = styled.div`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
  overflow: auto;
  ${minSp`
    overflow: hidden;
  `}
  form {
    width: 440px;
    ${minSp`
      width: auto;
    `}
  }
`;

const TagsListHeadingListSC = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${color.primary_white};
  border-bottom: 1px solid ${color.list_border};
  color: ${color.tag_color};
  font-size: 14px;
  padding: 13px 10px;
  ${pc`
    max-width: 750px;
    padding: 13px 20px;
  `}
`;

const TagsListHeadingItemSC = styled.li`
  font-size: 14px;
  color: ${color.gray};

  &:nth-child(1) {
    min-width: 130px;
    width: 40%;
    ${pc`
      width: 50%;
    `}
  }
  &:nth-child(2) {
    min-width: 90px;
    width: 20%;
  }
  &:nth-child(3) {
    width: 30%;
    ${pc`
      width: 20%;
    `}
  }
  &:nth-child(4) {
    width: 10%;
    text-align: right;
  }
`;

const TagsListSC = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid ${color.list_border};
  &:last-child {
    border-bottom: none;
  }
  ${pc`
    padding: 12px 20px;
  `}
`;

const TagsListItemSC = styled(TagsListHeadingItemSC)`
  color: ${color.gray};
`;

const TagsListInputSC = styled.input`
  ${inputBaseStyle}
  border-color: ${color.category_bg};
  &.name {
    width: 85%;
  }
  &.order {
    max-width: 80px;
    width: 100%;
  }
  &::placeholder {
    color: ${color.primary};
  }
`;

const TagsListDeleteButtonSC = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(${deleteIcon});
  background-size: 10px 10px;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
`;
const TagsListAddButtonSC = styled.button`
  ${buttonBaseStyle}
  opacity: 1;
  background-color: #fff;
  color: ${color.primary};
  border: 1px solid ${color.primary};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  &:hover {
    opacity: 0.7;
    transition-duration: 0.2s;
    cursor: pointer;
  }
`;
