import React from "react";
import styled from "styled-components";
import { TopicsProps } from "../../demoData";
import searchOpen from "../../../../images/icons/down_arrow_gray.svg";
import Pages from "./Pages";
import useHooks from "./Hooks";

const Topics = (props: { topic: TopicsProps; pageId: string }) => {
  const { topic, pageId } = props;
  const { open, handleClick } = useHooks();
  return (
    <TopicsSC>
      <TopicTitleSC onClick={handleClick}>
        <ToggleArrowSC
          className={open ? "open" : ""}
          src={searchOpen}
          alt="toggleArrow"
        />
        {topic.name}
      </TopicTitleSC>
      {open && <Pages contents={topic.contents} pageId={pageId} />}
    </TopicsSC>
  );
};

export default Topics;

const TopicsSC = styled.dl``;

const TopicTitleSC = styled.dt`
  font-size: 16px;
  font-weight: normal;
  line-height: 2;
  cursor: pointer;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 16px;
  height: 16px;
  transition-duration: 0.2s;
  margin-right: 12px;
  position: relative;
  bottom: 5px;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;
