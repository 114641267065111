import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getFacilitiesApprovalsStatusApi,
  getApprovalsMonthlyApi,
  postApprovalMonthlyApi,
  postCancelAllEventsApi,
  getApprovalsAfterPostApi
} from "../api/event/approvalsApi";
import { getEventsAfterSaveApi } from "../api/event/eventApi";
import {
  GET_FACILITIES_APPROVALS_STATUS_REQUESTED,
  GET_FACILITIES_APPROVALS_STATUS_SUCCEEDED,
  GET_FACILITIES_APPROVALS_STATUS_FAILED,
  GET_MONTHLY_APPROVAL_STATUS_REQUESTED,
  GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
  GET_MONTHLY_APPROVAL_STATUS_FAILED,
  POST_APPROVALS_MONTHLY_REQUSEDTED,
  POST_APPROVALS_MONTHLY_SUCCEEDED,
  POST_APPROVALS_MONTHLY_FAILED,
  RESET_SELECTED_OPTOIONS,
  RESET_TO_BE_APPROVED,
  CANCEL_ALL_EVENTS_REQUESTED,
  CANCEL_ALL_EVENTS_SUCCEEDED,
  CANCEL_ALL_EVENTS_FAILED,
  SET_MONTH_TO_CANCEL_ALL
} from "../store/event/approval/type";
import { GET_EVENTS_LIST_SUCCEEDED } from "../store/event/events/type";
import {
  TOGGLE_MONTHLY_APPROVAL_MODAL,
  TOGGLE_CANCEL_ALL_EVENTS_MODAL
} from "../store/common/modal/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "../store/session/type";

function* getFacilitiesApprovalsStatus(action: any) {
  const token = action.token;
  const year = action.year;
  try {
    const result = yield call(getFacilitiesApprovalsStatusApi, token, year);
    yield all([
      put({
        type: GET_FACILITIES_APPROVALS_STATUS_SUCCEEDED,
        result: result.data.facilities
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* getApprovalMonthlyStatus(action: any) {
  const token = action.token;
  const facility_id = action.facility_id;
  const year = action.year;
  try {
    const result = yield call(getApprovalsMonthlyApi, token, facility_id, year);
    yield all([
      put({ type: GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED, result: result.data })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postApprovalMonthly(action: any) {
  console.log("post");
  const token = action.token;
  const facility_id = action.facility_id;
  const months = action.months;
  const filtered_months = action.filtered_months;
  const year = action.year;
  try {
    const result = yield* postApprovalMonthlyApi(token, facility_id, months);
    const approvals_after_post = yield* getApprovalsAfterPostApi(
      token,
      facility_id,
      year
    );
    const events_after_post = yield* getEventsAfterSaveApi(
      token,
      facility_id,
      year,
      filtered_months
    );

    console.log("result", result);
    yield all([
      put({ type: POST_APPROVALS_MONTHLY_SUCCEEDED, result: result.data }),
      put({
        type: GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
        result: approvals_after_post.data
      }),
      put({
        type: GET_EVENTS_LIST_SUCCEEDED,
        events: events_after_post.data.events
      }),
      put({ type: RESET_SELECTED_OPTOIONS }),
      put({ type: RESET_TO_BE_APPROVED }),
      put({
        type: TOGGLE_MONTHLY_APPROVAL_MODAL,
        monthly_approval_modal_open: false
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// function* postCancelAllEvents(action: any) {
//   const token = action.token
//   const facility_id = action.facility_id
//   const month_to_be_canceled_all = action.month_to_be_canceled_all
//   const year = Number(month_to_be_canceled_all.slice(0, 4));
//   const month = Number(month_to_be_canceled_all.slice(5, 7));
//   console.log('sliced_year', year)
//   console.log('sliced_month', month)
//   try {
//     const result = yield call(postCancelAllEventsApi, token, facility_id, year, month)
//     console.log('result', result)
//     yield all([
//       put({type: CANCEL_ALL_EVENTS_SUCCEEDED, result: result.data}),
//       put({type: TOGGLE_CANCEL_ALL_EVENTS_MODAL, cancel_all_events_modal_open: false}),
//       put({type: SET_MONTH_TO_CANCEL_ALL, month_to_be_canceled_all: action.month_to_be_canceled_all}),
//     ])
//   } catch(e) {
//     if(e.message === 'Request failed with status code 401') {
//       yield put({ type: LOGIN_STATUS_401 })
//     } else {
//       yield put({ type: CANCEL_ALL_EVENTS_FAILED, message: e.response.data });
//     }
//   }
// }

// 一括承認解除
function* postCancelAllEvents(action: any) {
  const token = action.token;
  const facility_id = action.facility_id;
  const month_to_be_canceled_all = action.month_to_be_canceled_all;
  const year = Number(month_to_be_canceled_all.slice(0, 4));
  const month = Number(month_to_be_canceled_all.slice(5, 7));
  const monthInString = Array(month.toString());
  console.log("sliced_year", year);
  console.log("sliced_month", month);
  try {
    // const result = yield call(postCancelAllEventsApi, token, facility_id, year, month)
    // console.log("result", result);

    const result = yield* postCancelAllEventsApi(
      token,
      facility_id,
      year,
      month
    );
    const approvals_after_post = yield* getApprovalsAfterPostApi(
      token,
      facility_id,
      year
    );
    const events_after_post = yield* getEventsAfterSaveApi(
      token,
      facility_id,
      year,
      monthInString
    );

    console.log("approvals_after_post", approvals_after_post);
    yield all([
      put({ type: CANCEL_ALL_EVENTS_SUCCEEDED, result: result.data }),
      put({
        type: GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
        result: approvals_after_post.data
      }),
      put({
        type: GET_EVENTS_LIST_SUCCEEDED,
        events: events_after_post.data.events
      }),
      put({
        type: TOGGLE_CANCEL_ALL_EVENTS_MODAL,
        cancel_all_events_modal_open: false
      }),
      put({
        type: SET_MONTH_TO_CANCEL_ALL,
        month_to_be_canceled_all: action.month_to_be_canceled_all
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const approvalSaga = [
  takeLatest(
    GET_FACILITIES_APPROVALS_STATUS_REQUESTED,
    getFacilitiesApprovalsStatus
  ),
  takeLatest(GET_MONTHLY_APPROVAL_STATUS_REQUESTED, getApprovalMonthlyStatus),
  takeLatest(POST_APPROVALS_MONTHLY_REQUSEDTED, postApprovalMonthly),
  takeLatest(CANCEL_ALL_EVENTS_REQUESTED, postCancelAllEvents)
];

export default approvalSaga;
