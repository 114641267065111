import React from "react";
import styled from "styled-components";
import { Content } from "../../demoData";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const Pages = (props: { contents: Content[]; pageId: string }) => {
  const { contents, pageId } = props;
  const { manualId, topicId } =
    useParams<{ manualId: string; topicId: string }>();

  console.log("contentscontentscontents", contents);
  return (
    <PagesSC>
      {contents &&
        contents.map((val, index) => {
          return (
            <ul key={val.id}>
              {val.status !== 1 && (
                <PageItem
                  key={index}
                  className={Number(pageId) === val.id ? "current" : ""}
                >
                  <Link
                    to={`/manual/manuals/${manualId}/topics/${topicId}/pages/${val.id}`}
                  >
                    {val.title}
                  </Link>
                </PageItem>
              )}
            </ul>
          );
        })}
    </PagesSC>
  );
};

export default Pages;

const PagesSC = styled.dd`
  display: block;
`;

const PageItem = styled.li`
  font-size: 14px;
  padding-left: 16px;
  &.current {
    font-weight: bold;
  }

  a {
    display: inline-block;
    margin: 5px 0;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
      text-decoration: underline;
    }
  }
`;
