import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import AccountsListBlock from "./AccountsListBlock";
import PageHeading from "../../Common/PageHeading";
import AccountsSearchBlock from "./AccountsSerchBlock";
import { useLocation } from "react-router-dom";
import { toggleRightSection } from "../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../styles/layout/containers";

function Accounts() {
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <MainSC>
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <PageHeading title="アカウント一覧" />

      {pathName === "/accounts" && <AccountsSearchBlock />}
      <AccountsListBlock />
    </MainSC>
  );
}

export default Accounts;

const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;
