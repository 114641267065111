import axios from "axios";
import { env_camera_url } from "serverEnv";
import { CameraClass } from "../type";

interface GetCameraClassesResponseType {
  status: number;
  classes: CameraClass[];
}

// クラス情報一覧取得
export const getCameraClassesApi = (token: string) => {
  return axios.get<GetCameraClassesResponseType>(`${env_camera_url}/classes`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
