import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { GET_ACCOUNTS_SUCCEEDED, GET_ACCOUNTS_FAILED } from "./type";

interface Account {
  id: number;
  gsuite_id: string;
  name: string;
  email: string;
  given_name: string;
  family_name: string;
  picture: string;
  email_verified_at: string;
  email_verified: boolean;
  gsuite: string;
  locale: string;
  hd: string;
  verified_email: boolean;
  link: string | null;
  no_read: number;
  read: number;
  role: Role[];
}

interface Role {
  id: number;
  name: string;
}

const initialState: Account[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ACCOUNTS_SUCCEEDED:
      console.log("action.accounts", action.accounts);
      return action.accounts;

    case GET_ACCOUNTS_FAILED:
      console.log("message: ", action.message);
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
