import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FacilityListBlock from "./FacilityListBlock";
import NoFacilityBlock from "./NoFacilityBlock";

export default function FacilitySelectModalContainer() {
  const facilities = useSelector((state: any) => state.session.facilities);

  return (
    <FacilitySelectModalContentSC className={facilities.length === 0 ? "noFacilities" : ""}>
      <TitleBlock>施設選択</TitleBlock>
      {facilities.length !== 0 ? <FacilityListBlock /> : <NoFacilityBlock />}
    </FacilitySelectModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const FacilitySelectModalContentSC = styled.div`
  max-width: 800px;
  width: 90%;
  height: 600px;
  position: fixed;
  z-index: 120;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  &.noFacilities {
    height: 300px;
  }
`;

const TitleBlock = styled.h1`
  position: fixed;
  height: 48px;
  width: 100%;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
  background-color: #ffffff;
  margin: 0;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  color: #444;
`;
