const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
const TOGGLE_FAQ = "TOGGLE_FAQ";
const TOGGLE_NEWS = "TOGGLE_NEWS";
const TOGGLE_MASTER = "TOGGLE_MASTER";
const TOGGLE_ACCOUNT = "TOGGLE_ACCOUNT";
const TOGGLE_INQUIRY = "TOGGLE_INQUIRY";
const TOGGLE_RIGHT_SECTION = "TOGGLE_RIGHT_SECTION";
const SET_RIGHT_SECTION_OPEN = "SET_RIGHT_SECTION_OPEN";

export {
  TOGGLE_SIDEBAR,
  TOGGLE_FAQ,
  TOGGLE_NEWS,
  TOGGLE_MASTER,
  TOGGLE_ACCOUNT,
  TOGGLE_INQUIRY,
  TOGGLE_RIGHT_SECTION,
  SET_RIGHT_SECTION_OPEN
};
