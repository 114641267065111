import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import { useReactToPrint } from "react-to-print";
import { toggleAnnualEventScheduleModal } from "../../../../store/common/modal/action";
import close from "../../../../images/icons/close.svg";
import { AnnualEventScheduleContentHeading } from "./AnnualEventScheduleContentHeading";
import { AnnualEventScheduleCommonEventRowNew } from "./AnnualEventScheduleCommonEventRowNew";
import { AnnualEventScheduleMonthlyPlanRowNew } from "./AnnualEventScheduleMonthlyEventRowNew";

export const AnnualEventScheduleModalContentNew = ({
  filteredYear,
  facilityId,
  stateData
}: {
  filteredYear: number;
  facilityId: number;
  stateData: any;
}) => {
  console.log("filteredYear", filteredYear);
  console.log("facilityId", facilityId);
  console.log("stateData", stateData);
  const dispatch = useDispatch();

  //=========================================================================================================================
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "page"
  });

  if (
    !stateData ||
    !stateData.apiData ||
    !stateData.apiData.facility ||
    !stateData.apiData.facility.name ||
    !stateData.apiData.heading ||
    !stateData.apiData.heading.header_events ||
    !stateData.apiData.heading.header_events ||
    !stateData.apiData.first_half_year ||
    !stateData.apiData.first_half_year.schedules ||
    !stateData.apiData.first_half_year.monthly_events ||
    !stateData.apiData.first_half_year.agricultural_events ||
    !stateData.apiData.second_half_year ||
    !stateData.apiData.second_half_year.schedules ||
    !stateData.apiData.second_half_year.monthly_events ||
    !stateData.apiData.second_half_year.agricultural_events
  ) {
    return <></>;
  }

  return (
    <AnnualEventScheduleContentSC>
      <HeaderMenuSC>
        <ButtonWrapperSC>
          <PrintButtonSC onClick={handlePrint}>プリントアウト</PrintButtonSC>
          <CloseButtonSC
            onClick={() => dispatch(toggleAnnualEventScheduleModal(false))}
          />
        </ButtonWrapperSC>
      </HeaderMenuSC>
      <ScheduleWrapperSC>
        <div ref={componentRef}>
          {/* ========================== 上半期 ========================= */}
          <HalfSpanContainerSC className="page">
            <HeaderSC>
              <AnnualEventScheduleContentHeading
                facilityName={
                  stateData &&
                  stateData.apiData &&
                  stateData.apiData.facility &&
                  stateData.apiData.facility.name
                    ? stateData.apiData.facility.name
                    : ""
                }
                filteredYear={filteredYear ?? new Date().getFullYear()} // filteredYearがセットされていなければ今年の年度
                usageGuides={
                  stateData &&
                  stateData.apiData &&
                  stateData.apiData.heading &&
                  stateData.apiData.heading.header_events
                    ? stateData.apiData.heading.header_events
                    : []
                }
                span="4-9"
              />
              <ScheduleContainerSC>
                {stateData &&
                stateData.apiData &&
                stateData.apiData.first_half_year &&
                stateData.apiData.first_half_year.schedules ? (
                  stateData.apiData.first_half_year.schedules.map(
                    (schedule: any) => (
                      <MonthColumnSC key={schedule.month}>
                        <MonthSC>{schedule.month}月</MonthSC>

                        <AnnualEventScheduleMonthlyPlanRowNew
                          filteredYear={filteredYear}
                          monthIndex={schedule.month}
                          allMonthlyPlans={
                            stateData.apiData.first_half_year.monthly_events
                          } // ここが月間計画
                          type="monthly"
                        />

                        <AnnualEventScheduleMonthlyPlanRowNew
                          filteredYear={filteredYear}
                          monthIndex={schedule.month}
                          allMonthlyPlans={
                            stateData.apiData.first_half_year
                              .agricultural_events
                          } // ここが農業計画
                          type="agricultural"
                        />

                        {schedule.days.map(
                          (dailyEvent: any, dayIndex: number) => (
                            <AnnualEventScheduleCommonEventRowNew
                              key={`${schedule.month}-${dayIndex + 1}`}
                              dailyEvent={dailyEvent}
                              monthIndex={schedule.month}
                              dayIndex={dayIndex + 1}
                            />
                          )
                        )}

                        {schedule.days_in_month !== 31 &&
                          [31 - schedule.days_in_month].map(
                            (day: any, dayIndex: number) => (
                              <AnnualEventScheduleCommonEventRowNew
                                key={`${schedule.month}-${
                                  schedule.days_in_month + 1 + dayIndex
                                }`}
                                dailyEvent={{
                                  events: [],
                                  holiday_name: "",
                                  day_type: "",
                                  day: schedule.days_in_month + 1 + dayIndex
                                }}
                                monthIndex={schedule.month}
                                dayIndex={schedule.days_in_month + 1 + dayIndex}
                              />
                            )
                          )}
                      </MonthColumnSC>
                    )
                  )
                ) : (
                  <></>
                )}
              </ScheduleContainerSC>
            </HeaderSC>
          </HalfSpanContainerSC>

          {/* ========================== 下半期 ========================= */}
          <HalfSpanContainerSC className="page">
            <HeaderSC>
              <AnnualEventScheduleContentHeading
                facilityName={
                  stateData &&
                  stateData.apiData &&
                  stateData.apiData.facility &&
                  stateData.apiData.facility.name
                    ? stateData.apiData.facility.name
                    : ""
                }
                filteredYear={filteredYear ?? new Date().getFullYear()} // filteredYearがセットされていなければ今年の年度
                usageGuides={
                  stateData &&
                  stateData.apiData &&
                  stateData.apiData.heading &&
                  stateData.apiData.heading.header_events
                    ? stateData.apiData.heading.header_events
                    : []
                }
                span="10-3"
              />
              <ScheduleContainerSC>
                {stateData &&
                stateData.apiData &&
                stateData.apiData.first_half_year &&
                stateData.apiData.first_half_year.schedules ? (
                  stateData.apiData.second_half_year.schedules.map(
                    (schedule: any) => (
                      <MonthColumnSC key={schedule.month}>
                        <MonthSC>{schedule.month}月</MonthSC>

                        <AnnualEventScheduleMonthlyPlanRowNew
                          filteredYear={filteredYear}
                          monthIndex={schedule.month}
                          allMonthlyPlans={
                            stateData.apiData.second_half_year.monthly_events
                          } // ここが月間計画
                          type="monthly"
                        />

                        <AnnualEventScheduleMonthlyPlanRowNew
                          filteredYear={filteredYear}
                          monthIndex={schedule.month}
                          allMonthlyPlans={
                            stateData.apiData.second_half_year
                              .agricultural_events
                          } // ここが農業計画
                          type="agricultural"
                        />

                        {schedule.days.map(
                          (dailyEvent: any, dayIndex: number) => (
                            <AnnualEventScheduleCommonEventRowNew
                              key={`${schedule.month}-${dayIndex + 1}`}
                              dailyEvent={dailyEvent}
                              monthIndex={schedule.month}
                              dayIndex={dayIndex + 1}
                            />
                          )
                        )}

                        {schedule.days_in_month < 31 &&
                          [31 - schedule.days_in_month].map(
                            (day: any, dayIndex: number) => (
                              <AnnualEventScheduleCommonEventRowNew
                                key={`${schedule.month}-${
                                  schedule.days_in_month + 1 + dayIndex
                                }`}
                                dailyEvent={{
                                  events: [],
                                  holiday_name: "",
                                  day_type: "",
                                  day: schedule.days_in_month + 1 + dayIndex
                                }}
                                monthIndex={schedule.month}
                                dayIndex={schedule.days_in_month + 1 + dayIndex}
                              />
                            )
                          )}
                      </MonthColumnSC>
                    )
                  )
                ) : (
                  <></>
                )}
              </ScheduleContainerSC>
            </HeaderSC>
          </HalfSpanContainerSC>
        </div>
      </ScheduleWrapperSC>
    </AnnualEventScheduleContentSC>
  );
};

// ===================================================================================
// スタイル
// ===================================================================================
const AnnualEventScheduleContentSC = styled.div`
  width: 90%;
  height: 95%;
  position: fixed;
  display: block;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  overflow: hidden;
`;

const HalfSpanContainerSC = styled.div`
  height: 1100px;
  width: 1556px;
  margin: 0 auto;
  padding: 5px 10px 0px;
  :first-of-type {
    margin-bottom: 15px;
  }
`;

const HeaderSC = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
`;

const HeaderMenuSC = styled.div`
  height: 40px;
  border-bottom: 1px solid ${color.gray_border};
`;

const ButtonWrapperSC = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PrintButtonSC = styled.div`
  display: inline-block;
  padding: 13px;
  font-size: 12px;
  border-left: 1px solid ${color.gray_border};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const CloseButtonSC = styled.div`
  display: inline-block;
  height: 40px;
  width: 60px;
  background-image: url(${close});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  border-left: 1px solid ${color.gray_border};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const ScheduleWrapperSC = styled.div`
  overflow: scroll;
  height: calc(100vh - 120px);
`;

const ScheduleContainerSC = styled.div`
  display: flex;
  border: 1px solid ${color.gray_border};
  border-right: none;
  border-bottom: none;
`;

const MonthColumnSC = styled.div`
  display: flex;
  width: 16.7%;
  height: 100%;
  font-size: 10px;
  background-color: white;
  flex-direction: column;
  border-right: 1px solid ${color.gray_border};
`;

const MonthSC = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid ${color.gray_border};
  background-color: ${color.primary_white};
  &:last-of-type {
    border-bottom: 10px solid ${color.gray_border};
  }
`;

const RowSC = styled.div`
  display: flex;
  height: 31px;
  border-bottom: 1px solid ${color.gray_border};
  &.sunday {
    background-color: ${color.primary_white};
  }
`;

const DayCellSC = styled.div<{ hasDate: boolean }>`
  border-right: 1px solid ${color.gray_border};
  padding-top: 5px;
  width: ${props => (props.hasDate ? "30px" : "10px")};
  padding-top: 5px;
  background-color: ${color.primary_white};
`;

const IconCellSC = styled.div`
  width: 15px;
`;
