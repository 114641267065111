import {
  ADD_TO_BE_CREATED_MANUAL_TAG,
  CHANGE_NAME_TO_BE_CREATED_MANUAL_TAG,
  CHANGE_ORDER_TO_BE_CREATED_MANUAL_TAG,
  DELETE_TO_BE_CREATED_MANUAL_TAG,
  ADD_TO_BE_UPDATED_MANUAL_TAG,
  CHANGE_NAME_TO_BE_UPDATED_MANUAL_TAG,
  CHANGE_ORDER_TO_BE_UPDATED_MANUAL_TAG,
  UPDATE_VIEW_MANUAL_TAG_NAME,
  UPDATE_VIEW_MANUAL_TAG_ORDER,
  ADD_TO_BE_DELETED_MANUAL_TAG,
  ALL_MANUAL_TAG_UPDATE_REQUESTED,
  SET_DRAFT_TAGS
} from "./types";
import {
  ManualTags,
  ManualTagsToBeCreated,
  updateAllManualTagsRequestBodyProps
} from "./types.d";
import { GET_MANUAL_TAGS_REQUESTED } from "./types";

export const getManualTagsRequest = (data: { token: string }) => {
  const { token } = data;
  return {
    type: GET_MANUAL_TAGS_REQUESTED,
    token
  };
};

export const clickUpdateBtnClickAction = (data: {
  token: string;
  to_be_created_tags: ManualTagsToBeCreated[];
  to_be_deleted_ids: number[];
  to_be_updated_tags: ManualTags[];
}) => {
  const { token, to_be_created_tags, to_be_deleted_ids, to_be_updated_tags } =
    data;
  return {
    type: ALL_MANUAL_TAG_UPDATE_REQUESTED,
    token,
    to_be_created_tags,
    to_be_deleted_ids,
    to_be_updated_tags
  };
};

export const updateAllManualTagRequest = (data: {
  token: string;
  req_body: updateAllManualTagsRequestBodyProps[];
}) => {
  const { token, req_body } = data;
  return {
    token,
    req_body
  };
};

// 新規追加関連Action
export const addToBeCreateManualTag = () => {
  return {
    type: ADD_TO_BE_CREATED_MANUAL_TAG
  };
};

export const addTobeUpdatedManualTag = (target_id: number) => {
  return {
    type: ADD_TO_BE_UPDATED_MANUAL_TAG,
    target_id
  };
};

export const changeNameToBeCratedManualTag = (data: {
  target_id: number;
  value: string;
}) => {
  const { target_id, value } = data;
  return {
    type: CHANGE_NAME_TO_BE_CREATED_MANUAL_TAG,
    target_id,
    value
  };
};

export const changeOrderToBeCratedManualTag = (data: {
  target_id: number;
  order: number;
}) => {
  const { target_id, order } = data;
  return {
    type: CHANGE_ORDER_TO_BE_CREATED_MANUAL_TAG,
    target_id,
    order
  };
};

export const updateViewManualTagName = (data: {
  target_id: number;
  value: string;
}) => {
  const { target_id, value } = data;
  return {
    type: UPDATE_VIEW_MANUAL_TAG_NAME,
    target_id,
    value
  };
};

export const updateViewManualTagOrder = (data: {
  target_id: number;
  order: number;
}) => {
  const { target_id, order } = data;
  return {
    type: UPDATE_VIEW_MANUAL_TAG_ORDER,
    target_id,
    order
  };
};
export const changeNameToBeUpdatedManualTag = (data: {
  target_id: number;
  value: string;
}) => {
  const { target_id, value } = data;
  return {
    type: CHANGE_NAME_TO_BE_UPDATED_MANUAL_TAG,
    target_id,
    value
  };
};

export const changeOrderToBeUpdatedManualTag = (data: {
  target_id: number;
  order: number;
}) => {
  const { target_id, order } = data;
  return {
    type: CHANGE_ORDER_TO_BE_UPDATED_MANUAL_TAG,
    target_id,
    order
  };
};

export const deleteTobeCreatedManualTag = (target_id: number) => {
  return {
    type: DELETE_TO_BE_CREATED_MANUAL_TAG,
    target_id
  };
};

export const addToBeDeletedManualTag = (target_id: number) => {
  return {
    type: ADD_TO_BE_DELETED_MANUAL_TAG,
    target_id
  };
};
