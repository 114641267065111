import styled from "styled-components";

export const ModalDeleteBackgroundSC = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const ModalDeleteSC = styled.div`
  z-index: 200;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const ModalDeleteDisplaySC = styled.div`
  background-color: #ffffff;
  width: 700px;
  height: 310px;
  border: 1px solid #ffffff;
  border-radius: 26px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 180px auto;
  max-width: 100%;
  max-height: 100%;
`;

export const ModalDeleteDisplayCanselSC = styled.span`
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  top: 8%;
  left: 91%;
  cursor: pointer;
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::before,
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 30px;
    background: #c4c4c4;
    border-radius: 3px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const ModalDeleteTitleSC = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-left: 50px;
`;
export const ModalDeleteTextSC = styled.p`
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin-top: 80px;
`;

export const ModalDeleteButtonSC = styled.div`
  display: flex;
  margin-top: 70px;
  justify-content: center;
`;
