import React from "react";
import styled from "styled-components";
import { Page } from "store/manual/manuals/pages/types.d";
import { SortableContainer, arrayMove } from "react-sortable-hoc";
import PagesSortableItem from "./PagesSortableItem";
import { color } from "components/mixins";

interface Props {
  hookedPages: Page[];
  changePagesOrder: (orderChangedPages: Page[]) => void;
}

function PagesSortableList(props: Props) {
  const { hookedPages, changePagesOrder } = props;

  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    changePagesOrder(arrayMove(hookedPages, oldIndex, newIndex));
  };

  const SortableContainerWrapper = SortableContainer(
    ({ children }: { children: any }) => {
      return <PagesAdminListSC>{children}</PagesAdminListSC>;
    }
  );
  return (
    <>
      <SortableContainerWrapper useDragHandle onSortEnd={onSortEnd}>
        {hookedPages.map((page: any, index: number) => {
          return (
            <PagesSortableItem
              index={index}
              id={page.id}
              title={page.title}
              title_draft={page.title_draft}
              status={page.status}
            />
          );
        })}
      </SortableContainerWrapper>
    </>
  );
}

export default PagesSortableList;

const PagesAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;
