import { CameraTag } from "components/Camera/type";
import React, { ChangeEvent } from "react";
import { Button } from "../Button";
import { TextInput } from "../TextInput";
import {
  TagItemListAddSC,
  TagItemListSC,
  TagItemListThDeleteSC,
  TagItemListThOrderSC,
  TagItemListThSC,
  TagItemListThTagSC,
  TagItemListTrDeleteSC,
  TagItemListTrOrderSC,
  TagItemListTrSC,
  TagItemListTrTagSC
} from "./style";

interface TagItemListProps {
  tags: CameraTag[];
  toBeCreatedTags: {
    id: string;
    name: string;
    order: number;
    is_enabled: boolean;
  }[];
  onClickDeleteTagBtn: (targetId: number | string) => void;
  onClickAddTagBtn: () => void;
  onChangeTagInfoForUpdate: (
    id: string | number,
    value: string,
    target: "name" | "order"
  ) => void;
  onChangeTagInfoForCreate: (
    id: string | number,
    value: string,
    target: "name" | "order"
  ) => void;
}

export const TagItemList: React.FC<TagItemListProps> = props => {
  const {
    tags,
    toBeCreatedTags,
    onChangeTagInfoForUpdate,
    onChangeTagInfoForCreate,
    onClickAddTagBtn,
    onClickDeleteTagBtn
  } = props;
  return (
    <>
      <TagItemListSC>
        <TagItemListThSC>
          <TagItemListThTagSC>タグ名</TagItemListThTagSC>
          <TagItemListThOrderSC>並び順</TagItemListThOrderSC>
          <TagItemListThDeleteSC>削除</TagItemListThDeleteSC>
        </TagItemListThSC>
        {tags.map(tag => {
          return (
            <TagItemRow
              tagName={tag.name}
              order={tag.order ? tag.order.toString() : ""}
              id={tag.id}
              onClickDeleteBtn={() => onClickDeleteTagBtn(tag.id)}
              onChangeName={onChangeTagInfoForUpdate}
              onChangeOrder={onChangeTagInfoForUpdate}
            />
          );
        })}
        {toBeCreatedTags.map(tag => {
          return (
            <TagItemRow
              tagName={tag.name}
              order={tag.order.toString()}
              id={tag.id}
              onClickDeleteBtn={() => onClickDeleteTagBtn(tag.id)}
              onChangeName={onChangeTagInfoForCreate}
              onChangeOrder={onChangeTagInfoForCreate}
            />
          );
        })}
        <TagItemListAddSC>
          <Button label="追加" type="alert" onClick={onClickAddTagBtn} />
        </TagItemListAddSC>
      </TagItemListSC>
    </>
  );
};

const TagItemRow = (props: {
  tagName: string;
  order: string;
  onClickDeleteBtn: () => void;
  onChangeName: (
    id: number | string,
    value: string,
    target: "name" | "order"
  ) => void;
  onChangeOrder: (
    id: number | string,
    value: string,
    target: "name" | "order"
  ) => void;
  id: number | string;
}) => {
  const { tagName, order, onClickDeleteBtn, onChangeName, onChangeOrder, id } =
    props;

  const handleChangeNameValue = (
    e: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => {
    const newValue = e.target.value;
    onChangeName(id, newValue, "name");
  };
  const handleChangeOrderValue = (
    e: ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => {
    const newValue = e.target.value;
    onChangeOrder(id, newValue, "order");
  };
  return (
    <TagItemListTrSC>
      <TagItemListTrTagSC>
        <TextInput
          value={tagName}
          onChange={e => handleChangeNameValue(e, id)}
          outline
        />
      </TagItemListTrTagSC>

      <TagItemListTrOrderSC>
        <TextInput
          value={order}
          type="number"
          onChange={e => handleChangeOrderValue(e, id)}
          outline
        />
      </TagItemListTrOrderSC>
      <TagItemListTrDeleteSC onClick={onClickDeleteBtn}></TagItemListTrDeleteSC>
    </TagItemListTrSC>
  );
};
