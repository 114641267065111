// お問合せ先の取得
const GET_INQUIRIES_CONTACTS = "GET_INQUIRIES_CONTACTS";
const GET_INQUIRIES_CONTACTS_SUCCEEDED = "GET_INQUIRIES_CONTACTS_SUCCEEDED";
const GET_INQUIRIES_CONTACTS_FAILED = "GET_INQUIRIES_CONTACTS_FAILED";

// お問合せ種別の取得
const GET_INQUIRIES_KINDS = "GET_INQUIRIES_KINDS";
const GET_INQUIRIES_KINDS_SUCCEEDED = "GET_INQUIRIES_KINDS_SUCCEEDED";
const GET_INQUIRIES_KINDS_FAILED = "GET_INQUIRIES_KINDS_FAILED";

// お問合せ内容回答優先度の取得
const GET_INQUIRIES_PRIORITIES = "GET_INQUIRIES_PRIORITIES";
const GET_INQUIRIES_PRIORITIES_SUCCEEDED = "GET_INQUIRIES_PRIORITIES_SUCCEEDED";
const GET_INQUIRIES_PRIORITIES_FAILED = "GET_INQUIRIES_PRIORITIES_FAILED";

// お問合せ内容回答方法の取得
const GET_INQUIRIES_ANSWERS = "GET_INQUIRIES_ANSWERS";
const GET_INQUIRIES_ANSWERS_SUCCEEDED = "GET_INQUIRIES_ANSWERS_SUCCEEDED";
const GET_INQUIRIES_ANSWERS_FAILED = "GET_INQUIRIES_ANSWERS_FAILED";

//お問い合わせ該当する項目にチェックを取得
const GET_INQUIRIES_CHECKLIST = "GET_INQUIRIES_CHECKLIST";
const GET_INQUIRIES_CHECKLIST_SUCCEEDED = "GET_INQUIRIES_CHECKLIST_SUCCEEDED";
const GET_INQUIRIES_CHECKLIST_FAILED = "GET_INQUIRIES_CHECKLIST_FAILED";

export {
  GET_INQUIRIES_CONTACTS,
  GET_INQUIRIES_CONTACTS_SUCCEEDED,
  GET_INQUIRIES_CONTACTS_FAILED,
  GET_INQUIRIES_KINDS,
  GET_INQUIRIES_KINDS_SUCCEEDED,
  GET_INQUIRIES_KINDS_FAILED,
  GET_INQUIRIES_PRIORITIES,
  GET_INQUIRIES_PRIORITIES_SUCCEEDED,
  GET_INQUIRIES_PRIORITIES_FAILED,
  GET_INQUIRIES_ANSWERS,
  GET_INQUIRIES_ANSWERS_SUCCEEDED,
  GET_INQUIRIES_ANSWERS_FAILED,
  GET_INQUIRIES_CHECKLIST,
  GET_INQUIRIES_CHECKLIST_SUCCEEDED,
  GET_INQUIRIES_CHECKLIST_FAILED
};
