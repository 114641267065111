import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../components/mixins/mediaQuery";
import { inputBaseStyle, color } from "../mixins";
import { beginSessionRequest } from "../../store/session/action";

const SignInForm = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailInputStatus, setEmailInputStatus] = useState(false);
  const [passwordInputStatus, setPasswordInputStatus] = useState(false);
  const [adminLoginVisible, setAdminLoginVisible] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.type) {
      case "email":
        setEmail(e.target.value);
        if (e.target.value !== "") {
          setEmailInputStatus(true);
        } else {
          setEmailInputStatus(false);
        }
        break;
      case "password":
        setPassword(e.target.value);
        if (e.target.value !== "") {
          setPasswordInputStatus(true);
        } else {
          setPasswordInputStatus(false);
        }
        break;
      default:
        console.log("key not found");
    }
  };

  let not_inputed_required_input_number = 0;
  if (emailInputStatus === false) {
    not_inputed_required_input_number++;
  }
  if (passwordInputStatus === false) {
    not_inputed_required_input_number++;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(beginSessionRequest(email, password));
  };

  return (
    <SignInFormSC id="SignInForm" onSubmit={handleSubmit}>
      {adminLoginVisible && (
        <InputAreaSC>
          <InputBoxSC
            className="email"
            id="email"
            type="email"
            value={email}
            placeholder="メールアドレス"
            onChange={handleChange}
          />
          <InputBoxSC
            className="password"
            id="password"
            type="password"
            value={password}
            placeholder="パスワード"
            onChange={handleChange}
          />
          {not_inputed_required_input_number === 0 ? (
            <SignInButton className="active">ログイン</SignInButton>
          ) : (
            <SignInButton>ログイン</SignInButton>
          )}
        </InputAreaSC>
      )}
      <FormShowLink
        className="formShowLink"
        onClick={() => setAdminLoginVisible(!adminLoginVisible)}
      >
        {!adminLoginVisible ? "管理者用アカウントをお持ちの方" : "閉じる"}
      </FormShowLink>
    </SignInFormSC>
  );
};

export default SignInForm;

const SignInFormSC = styled.form`
  border-top: 1px solid ${color.divide_border};
  padding-top: 24px;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  ${pc`
    padding-top: 30px;
  `}
`;

const InputBoxSC = styled.input`
  ${inputBaseStyle}
  background-color: #fff;
  text-align: left;
  margin-bottom: 12px;
  max-width: 280px;
  width: 100%;
  &.password {
    margin-bottom: 20px;
  }
`;

const InputAreaSC = styled.div``;

const SignInButton = styled.button`
  display: block;
  max-width: 280px;
  width: 100%;
  color: ${color.white};
  background-color: ${color.primary};
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 8px 12px 9px 12px;
  box-sizing: border-box;
  font-size: 14px;
  opacity: 0.3;
  &.active {
    opacity: 1;
  }
`;

const FormShowLink = styled.p`
  color: ${color.primary};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
