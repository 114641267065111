import React from "react";
import styled from "styled-components";
import { FlexPageLayout } from "../../Parts/MainLayoutForResults";
import SearchModuleForManual from "../../SearchBlockForManual";
import ModuleArea from "../../Parts/ModuleArea";

import { useHooks } from "./Hooks";
import UpdateHistoriesModule from "components/Manual/UpdateHistoriesModule";

import BlockContainer from "components/Manual/Parts/BlockContainer";
import BlockHeaderAdminForResults from "components/Manual/Parts/BlockHeaderAdminForResults";

import PagesUneditableList from "./PagesUneditableList";
import { useLocation } from "react-router";

function Results() {
  const { histories, tags, manualId, topicId, hookedPages } = useHooks({});
  const location = useLocation();
  return (
    <FlexPageLayout
      pageTitle={"検索結果"}
      topicTitle={"ページ検索結果"}
      toTopicsPath={
        location.pathname.includes("admin")
          ? `/manual/admin/manuals`
          : `/manual/manuals`
      }
    >
      <BlockContainer>
        <BlockHeaderAdminForResults blockTitle="ページ検索結果" />

        <Content>
          <PagesUneditableList
            hookedPages={hookedPages}
            manualId={manualId}
            topicId={topicId}
          />
        </Content>
      </BlockContainer>
      <ModuleArea>
        <SearchModuleForManual data={tags} />
        <UpdateHistoriesModule data={histories} />
      </ModuleArea>
    </FlexPageLayout>
  );
}

export default Results;

const Content = styled.div`
  position: relative;
  padding-bottom: 60px;
`;
