import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import {
  color,
  headingBaseStyle,
  containerBaseStyle,
  inputBaseStyle,
  buttonBaseStyle
} from "../../../mixins";
import {
  setAccountNewName,
  setAccountNewRoleId,
  setAccountNewEmail,
  setAccountNewPassword,
  setAccountNewFacilities,
  postAccountNewRequest,
  toggleAccountNewPostStatus
} from "../../../../store/global/account_new/action";
import { history } from "../../../../store/store";

function AccountNew() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const account_name = useSelector((state: any) => state.account_new.name);
  const account_position_id = useSelector(
    (state: any) => state.account_new.position_id
  );
  const account_email = useSelector((state: any) => state.account_new.email);
  const account_password = useSelector(
    (state: any) => state.account_new.password
  );
  const account_facilities = useSelector(
    (state: any) => state.account_new.facilities
  );
  const post_status = useSelector(
    (state: any) => state.account_new.post_status
  );
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const positions = useSelector((state: any) => state.positions);
  const facilities = useSelector((state: any) => state.facilities);
  console.log("account_position_id", account_position_id);
  console.log("account_facilities", account_facilities);
  const [nameStatus, setNameStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState("notInput");
  const [passwordStatus, setPasswordStatus] = useState("notInput");
  const [positionIsSelected, setPositionIsSellected] = useState(false);

  // リロードした場合の初期値の状態に合わせてT/Fを変更する
  useEffect(() => {
    if (account_name !== "") {
      setNameStatus(true);
    }
    if (
      account_password !== "" &&
      account_password.match(/^[A-Za-z0-9]{8,}$/)
    ) {
      setPasswordStatus("correctInput");
    }
    if (account_email.match(/[^\s]+@[^\s]+/)) {
      setEmailStatus("correctInput");
    }
  }, []);
  const handleAccountNewName = (e: any) => {
    if (e.target.value !== "") {
      dispatch(setAccountNewName(e.target.value));
      setNameStatus(true);
    } else {
      dispatch(setAccountNewName(e.target.value));
      setNameStatus(false);
    }
  };

  const handleAccountNewRoleId = (e: any) => {
    dispatch(setAccountNewRoleId(Number(e.target.value)));
    if (e.target.value !== "0") {
      setPositionIsSellected(true);
      // 本部所属だった場合全ての施設にチェックをつける
      if (e.target.value === "2" || e.target.value === "3") {
        facilities.map((facility: any) => {
          const alreadyChecked = account_facilities.some(
            (item: any) => item.id === facility.id
          );
          if (!alreadyChecked) {
            dispatch(setAccountNewFacilities(facility.id));
          }
        });
      }
    } else {
      setPositionIsSellected(false);
    }
  };

  const handleAccountNewEmail = (e: any) => {
    if (e.target.value === "") {
      dispatch(setAccountNewEmail(e.target.value));
      setEmailStatus("notInput");
    } else if (!e.target.value.match(/[^\s]+@[^\s]+/)) {
      dispatch(setAccountNewEmail(e.target.value));
      setEmailStatus("incorrectInput");
    } else {
      dispatch(setAccountNewEmail(e.target.value));
      setEmailStatus("correctInput");
    }
  };

  const handleAccountNewPassword = (e: any) => {
    if (e.target.value === "") {
      dispatch(setAccountNewPassword(e.target.value));
      setPasswordStatus("notInput");
    } else {
      if (e.target.value.match(/^[A-Za-z0-9]{8,}$/)) {
        dispatch(setAccountNewPassword(e.target.value));
        setPasswordStatus("correctInput");
      } else {
        dispatch(setAccountNewPassword(e.target.value));
        setPasswordStatus("incorrectInput");
      }
    }
  };

  const handleCheckFacility = (e: any) => {
    dispatch(setAccountNewFacilities(Number(e.target.value)));
  };

  // チェックボックスを全て未選択にする
  const handleClearFacilities = () => {
    facilities.map((facility: any) => {
      const alreadyChecked = account_facilities.some(
        (item: any) => item.id === facility.id
      );
      if (alreadyChecked) {
        dispatch(setAccountNewFacilities(facility.id));
      }
    });
  };

  const handleAccountNewRegister = (e: any) => {
    e.preventDefault();
    console.log("token", token);
    console.log("account_name", account_name);
    console.log("account_position_id", account_position_id);
    console.log("account_email", account_email);
    console.log("account_password", account_password);
    console.log("account_facilities", account_facilities);
    dispatch(
      postAccountNewRequest(
        token,
        account_name,
        account_position_id,
        account_email,
        account_password,
        account_facilities
      )
    );
  };

  let not_inputed_required_input_number = 0;
  if (nameStatus === false) {
    not_inputed_required_input_number++;
  }
  if (emailStatus !== "correctInput") {
    not_inputed_required_input_number++;
  }
  if (passwordStatus !== "correctInput") {
    not_inputed_required_input_number++;
  }
  if (positionIsSelected !== true) {
    not_inputed_required_input_number++;
  }

  useEffect(() => {
    dispatch(toggleAccountNewPostStatus(false));
  }, [dispatch]);
  return (
    <>
      {post_status ? (
        history.push("/accounts")
      ) : (
        <MainSC>
          <AccountNewHeadingSC>アカウント登録A</AccountNewHeadingSC>
          <AccountsNewContainerSC>
            <form action="">
              <InputListSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="name">ユーザー名*</label>
                    </dt>
                    <dd>
                      <InputBoxSC
                        id="name"
                        type="text"
                        onChange={handleAccountNewName}
                        value={account_name}
                        required
                      />
                    </dd>
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="authority">権限*</label>
                    </dt>
                    <dd>
                      <InputBoxSC
                        as="select"
                        className={
                          !positionIsSelected ? "select unSelected" : "select"
                        }
                        name="position_id"
                        id="position_id"
                        onChange={handleAccountNewRoleId}
                        required
                      >
                        <option value="">選択してください</option>
                        {positions.map((position: any) => (
                          <option value={position.id}>{position.name}</option>
                        ))}
                      </InputBoxSC>
                    </dd>
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="email">メールアドレス*</label>
                    </dt>
                    {emailStatus === "correctInput" ? (
                      <dd>
                        <InputBoxSC
                          id="email"
                          type="email"
                          value={account_email}
                          onChange={handleAccountNewEmail}
                          required
                        />
                      </dd>
                    ) : (
                      <dd>
                        <InputBoxSC
                          id="email"
                          type="email"
                          className="alert"
                          value={account_email}
                          onChange={handleAccountNewEmail}
                          required
                        />
                        <InputAlert>
                          メールアドレスの形式が間違っています。
                        </InputAlert>
                      </dd>
                    )}
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC>
                    <dt>
                      <label htmlFor="password">パスワード*</label>
                    </dt>
                    <dd>
                      <InputBoxSC
                        id="password"
                        type="password"
                        className={
                          passwordStatus === "incorrectInput" ? "alert" : ""
                        }
                        value={account_password}
                        onChange={handleAccountNewPassword}
                        required
                      />
                      <InputDescSC>
                        半角英数字8文字以上で設定してください
                      </InputDescSC>
                    </dd>
                  </InputRawSC>
                </InputItemSC>
                <InputItemSC>
                  <InputRawSC className="facility">
                    <dt>
                      <label>所属/管轄 施設</label>
                      <ClearButton onClick={handleClearFacilities}>
                        クリア
                      </ClearButton>
                    </dt>
                    <dd>
                      <FacilityListSC>
                        {facilities.map((facility: any) => {
                          const ExistingCheck = account_facilities.some(
                            (item: any) => item.id === facility.id
                          );
                          return (
                            <>
                              <FacilityItemSC>
                                <CheckboxLabel>
                                  <Checkbox
                                    className="checkbox"
                                    id={facility.id}
                                    type="checkbox"
                                    value={facility.id}
                                    onChange={handleCheckFacility}
                                    checked={ExistingCheck}
                                  />
                                  <CheckboxSpan className="label">
                                    {facility.name}
                                  </CheckboxSpan>
                                </CheckboxLabel>
                              </FacilityItemSC>
                            </>
                          );
                        })}
                      </FacilityListSC>
                    </dd>
                  </InputRawSC>
                </InputItemSC>
              </InputListSC>
              {not_inputed_required_input_number === 0 ? (
                <FixedButtonAreaSC
                  className={sidebarIsOpen ? "" : "sidebar-closed"}
                >
                  <ButtonAreaDeskSC>
                    未入力の必須項目は、ありません
                  </ButtonAreaDeskSC>
                  <SubmitButtonSC
                    className="active"
                    onClick={handleAccountNewRegister}
                  >
                    登録
                  </SubmitButtonSC>
                </FixedButtonAreaSC>
              ) : (
                <FixedButtonAreaSC
                  className={sidebarIsOpen ? "" : "sidebar-closed"}
                >
                  <ButtonAreaDeskSC>
                    未入力の必須項目は、残り
                    <span>{not_inputed_required_input_number}</span>つです
                  </ButtonAreaDeskSC>
                  <SubmitButtonSC>登録</SubmitButtonSC>
                </FixedButtonAreaSC>
              )}
            </form>
          </AccountsNewContainerSC>
        </MainSC>
      )}
    </>
  );
}

// ==========================================================
// スタイル
// ==========================================================
const MainSC = styled.div`
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;
const AccountsNewContainerSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  padding: 24px 16px;
  ${pc`
    padding: 30px;
  `}
`;

const AccountNewHeadingSC = styled.div`
  ${headingBaseStyle}
`;

const InputListSC = styled.ul`
  width: 100%;
`;

const InputItemSC = styled.li`
  width: 100%;
  padding: 20px 0;
  border-bottom: solid 1px ${color.divide_border};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const InputRawSC = styled.dl`
  width: 100%;
  ${pc`
    width: 560px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.facility {
      width: 100%;
      justify-content: flex-start;
      align-items: start;
    }
  `}
  dt {
    margin-bottom: 14px;
    color: ${color.gray};
    ${pc`
      margin-bottom: 0;
      margin-right: 38px;
      width: 120px;
    `}
  }
`;
const ClearButton = styled.p`
  display: block;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 8px;
  margin: 10px 0 0 auto;
  width: 50px;
  border-radius: 4px;
  border: 1px solid ${color.primary};
  background: ${color.white};
  color: ${color.primary};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const InputBoxSC = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    width: 400px;
  `}
  &.select {
    option {
      &:first-child {
        display: none;
      }
    }
    &.unSelected {
      color: #bbb;
    }
  }
`;

const InputAlert = styled.p`
  display: block;
  font-size: 12px;
  color: #ff1111;
  margin-top: 12px;
`;

const InputDescSC = styled.p`
  color: ${color.primary_light};
  font-size: 12px;
  line-height: 18px;
  padding-top: 12px;
`;

const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 90px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -60px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width:calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  color: ${color.gray};
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
`;

//checkbox

const FacilityListSC = styled.ul`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  ${pc`
    width: 535px;
  `}
`;

const FacilityItemSC = styled.li`
  width: 33%;
  margin-bottom: 12px;
`;

const PrefectureLabel = styled.p`
  width: 100%;
  padding: 12px;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  ${pc`
    width: 535px;
  `}
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;

export default AccountNew;
