import {
  GET_MONTHLY_EVENTS_LIST_REQUESTED,
  GET_MONTHLY_EVENTS_LIST_SUCCEEDED,
  GET_MONTHLY_EVENTS_LIST_FAILED,
  DELETE_MONTHLY_EVENTS_REQUESTED,
  DELETE_MONTHLY_EVENTS_SUCCEEDED,
  DELETE_MONTHLY_EVENTS_FAILED,
  POST_MONTHLY_EVENTS_REQUESTED,
  POST_MONTHLY_EVENTS_SUCCEEDED,
  POST_MONTHLY_EVENTS_FAILED,
  PUT_MONTHLY_EVENTS_REQUESTED,
  PUT_MONTHLY_EVENTS_SUCCEEDED,
  PUT_MONTHLY_EVENTS_FAILED,
  GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED,
} from "./type";

export const getMonthlyEventRequest = (
  facility_id: number,
  year: number,
  months: string | [],
  classes: string | [],
  remand: string
) => {
  return {
    facility_id,
    year,
    months,
    classes,
    remand,
  };
};

export const getMonthlyEventsRequest = (
  token: string,
  facility_id: number,
  year: number,
  months: string[],
  classes: string[],
  remand: string
) => {
  return {
    type: GET_MONTHLY_EVENTS_LIST_REQUESTED,
    token,
    facility_id,
    year,
    months,
    classes,
    remand,
  };
};

export const getAllMonthlyEventsRequest = (
  token: string,
  facility_id: number,
  year: number
) => {
  return {
    type: GET_ALL_MONTHLY_EVENTS_LIST_REQUESTED,
    token,
    facility_id,
    year,
  };
};

export const postMonthlyEventsRequest = (
  token: string,
  facility_id: number,
  year_to_post: number,
  add_monthly_event_month: number,
  add_monthly_event_type_id: number,
  content: string,
  detail: string,
  status: number,
  kind_to_request: number,
  filtered_year: number,
  filtered_months: string[],
  filtered_class: string[],
  filtered_remand: string
) => {
  return {
    type: POST_MONTHLY_EVENTS_REQUESTED,
    token,
    facility_id,
    year_to_post,
    add_monthly_event_month,
    add_monthly_event_type_id,
    content,
    detail,
    status,
    kind_to_request,
    filtered_year,
    filtered_months,
    filtered_class,
    filtered_remand,
  };
};

export const putMonthlyEventsRequest = (
  token: string,
  edit_monthly_event_id: number,
  facility_id: number,
  year_to_post: number,
  edit_monthly_event_month: number,
  edit_monthly_event_type_id: number,
  content: string,
  detail: string,
  status: number,
  kind_to_request: number,
  filtered_year: number,
  filtered_months: string[],
  filtered_class: string[],
  filtered_remand: string
) => {
  return {
    type: PUT_MONTHLY_EVENTS_REQUESTED,
    token,
    edit_monthly_event_id,
    facility_id,
    year_to_post,
    edit_monthly_event_month,
    edit_monthly_event_type_id,
    content,
    detail,
    status,
    kind_to_request,
    filtered_year,
    filtered_months,
    filtered_class,
    filtered_remand,
  };
};

export const deleteMonthlyEventsRequest = (
  token: string,
  monthly_event_id: number
) => {
  return {
    type: DELETE_MONTHLY_EVENTS_REQUESTED,
    token,
    monthly_event_id,
  };
};
