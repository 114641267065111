import styled from "styled-components";

export const CheckBoxSC = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
  margin: 0;
`;

export const CheckBoxInputSC = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border: 1px solid #444444;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin: 0;
  content: "";
  &:checked {
    border-color: #444444;
    background-color: #444444;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 7px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
    }
  }
`;
export const CheckBoxLabelSC = styled.label`
  font-size: 12px;
  padding-left: 22px;
  line-height: 19px;
`;
