import { CameraClass } from "components/Camera/type";
import React from "react";
import cardnumber0 from "../../images/age_0.svg";
import cardnumber1 from "../../images/age_1.svg";
import cardnumber2 from "../../images/age_2.svg";
import cardnumber3 from "../../images/age_3.svg";
import cardnumber4 from "../../images/age_4.svg";
import cardnumber5 from "../../images/age_5.svg";
import { CardNumberSC, CardNumbersSC, CradNumberImgSC } from "./style";

const classNumbers: { [key: string]: string } = {
  "0": cardnumber0,
  "1": cardnumber1,
  "2": cardnumber2,
  "3": cardnumber3,
  "4": cardnumber4,
  "5": cardnumber5
};

export const ClassNum = (props: { classes: CameraClass[] }) => {
  return (
    <CardNumbersSC>
      {props.classes
        .sort((a, b) => Number(a.name) - Number(b.name))
        .map((classroom, i) => {
          if (classNumbers[classroom.name]) {
            return (
              <CardNumberSC key={i}>
                <CradNumberImgSC
                  src={classNumbers[classroom.name]}
                  alt="cardnumber"
                />
              </CardNumberSC>
            );
          } else {
            return "";
          }
        })}
    </CardNumbersSC>
  );
};
