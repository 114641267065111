// 一覧取得
export const GET_TOPICS_REQUESTED = "GET_TOPICS_REQUESTED";
export const GET_TOPICS_SUCCEEDED = "GET_TOPICS_SUCCEEDED";
export const GET_TOPICS_FAILED = "GET_TOPICS_FAILED";

// 詳細取得
export const GET_TOPIC_REQUESTED = "GET_TOPIC_REQUESTED";
export const GET_TOPIC_SUCCEEDED = "GET_TOPIC_SUCCEEDED";
export const GET_TOPIC_FAILED = "GET_TOPIC_FAILED";

// 一括更新
export const UPDATE_ALL_TOPICS_REQUESTED = "UPDATE_ALL_TOPICS_REQUESTED";
export const UPDATE_ALL_TOPICS_SUCCEEDED = "UPDATE_ALL_TOPICS_SUCCEEDED";
export const UPDATE_ALL_TOPICS_FAILED = "UPDATE_ALL_TOPICS_FAILED";
