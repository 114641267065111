import { CameraFacility, isMultiValue } from "components/Camera/type";
import React from "react";
import Select, { StylesConfig } from "react-select";

const customStyles: StylesConfig<{ value: string; label: string }, true> = {
  control: (provided, state) => {
    return {
      ...provided,
      width: "300px",
      cursor: "pointer"
    };
  },
  menu: (base, props) => {
    return {
      ...base,
      width: "300px"
    };
  }
};

export const FacilitySelect = (props: {
  facilities: CameraFacility[];
  selectedFacilities?: number[];
  onFacilitySelect: (v: isMultiValue) => void;
}) => {
  const options = props.facilities.map(facility => {
    return {
      value: facility.id.toString(),
      label: facility.name
    };
  });
  const selectedValues = options.filter(option => {
    if (props.selectedFacilities) {
      return props.selectedFacilities.some(
        id => id.toString() === option.value
      );
    } else {
      return false;
    }
  });
  return (
    <Select
      options={options}
      styles={customStyles}
      defaultValue={selectedValues}
      isMulti
      onChange={v => props.onFacilitySelect(v)}
    />
  );
};
