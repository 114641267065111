import React from "react";
import { useSelector, useDispatch } from "react-redux";
import BlockContainer from "../../Parts/BlockContainer";
import TinyMCEEditor from "./TinyMCEEditor";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, inputBaseStyle } from "../../../mixins";
import { Page, TagItem } from "store/manual/manuals/pages/types.d";
import { useHooks } from "components/Manual/Admin/PageDetail/Hooks";
import { setStoredPageDetailStatus } from "store/manual/manuals/pages/actions";
import ManualEditPreview from "./ManualEditPreview/ManualEditPreview";

const PagesDetailBlock = (props: {
  storedPageDetail: Page;
  storedPageDetailOnEdit: Page;
  showEditor: boolean;
  previewMode: boolean;
  publishedData: any;
  draftData: any;
}) => {
  const {
    storedPageDetail,
    storedPageDetailOnEdit,
    showEditor,
    previewMode,
    publishedData,
    draftData
  } = props;
  const dispatch = useDispatch();

  // ステータスの判定
  const handleStoredPageDetailStauts = (e: any) => {
    console.log("status consoling...", e.target.value);
    dispatch(setStoredPageDetailStatus(Number(e.target.value)));
  };
  const {
    manualTags,
    storedPageTags,
    handleToggleCheckbox,
    handleChangeTitle
  } = useHooks();

  return (
    <>
      {!previewMode ? (
        <BlockContainer>
          {showEditor && (
            <>
              {/* ステータス */}
              <StatusBlock>
                <StatusLabel>ステータス*</StatusLabel>
                <StatusEditorBlock>
                  <StatusEditorInput
                    className="select"
                    value={
                      storedPageDetailOnEdit && storedPageDetailOnEdit.status
                        ? storedPageDetailOnEdit.status
                        : 2
                    }
                    onChange={handleStoredPageDetailStauts}
                  >
                    <option value={2}>公開</option>
                    <option value={1}>非公開</option>
                    <option value={3}>下書き</option>
                  </StatusEditorInput>
                </StatusEditorBlock>
              </StatusBlock>

              <TitleBlock>
                <TitleLabel>記事タイトル*</TitleLabel>
                <TitleEditorBlock>
                  <TitleEditorInput
                    value={
                      storedPageDetailOnEdit &&
                      storedPageDetailOnEdit.title_draft
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeTitle(e)
                    }
                  />
                </TitleEditorBlock>
              </TitleBlock>

              {/* タグ */}
              <TagBlock>
                <TagLabel>タグ</TagLabel>
                <TagEditorBlock>
                  <TagsList>
                    {manualTags.map((tag: any) => {
                      return (
                        <TagsItem>
                          <CheckboxLabel htmlFor={`tag_${tag.id}`}>
                            {
                              <Checkbox
                                className="checkbox"
                                id={`tag_${tag.id}`}
                                type="checkbox"
                                value={`${tag.id}`}
                                onChange={() => handleToggleCheckbox(tag.id)}
                                checked={storedPageDetailOnEdit.tags.some(
                                  (pageTag: TagItem) =>
                                    pageTag && tag.id === pageTag.id
                                )}
                              />
                            }
                            <CheckboxSpan className="label">
                              {tag.name}({tag.count ? tag.count : 0})
                            </CheckboxSpan>
                          </CheckboxLabel>
                        </TagsItem>
                      );
                    })}
                  </TagsList>
                </TagEditorBlock>
              </TagBlock>

              <ManualEditorBlock>
                <ManualEditorBlockLabel>ページ本文*</ManualEditorBlockLabel>
                <TinyMCEEditor
                  storedPageDetail={storedPageDetailOnEdit}
                  pageTagIds={storedPageTags}
                  pageTitle={storedPageDetailOnEdit.title_draft}
                />
              </ManualEditorBlock>
            </>
          )}
          {!showEditor && (
            <div
              dangerouslySetInnerHTML={{ __html: storedPageDetail.content }}
            ></div>
          )}
        </BlockContainer>
      ) : (
        <ManualEditPreview
          publishedData={publishedData}
          draftData={draftData}
        />
      )}
    </>
  );
};

export default PagesDetailBlock;

// Title ============================
const TitleBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
    align-items: center;
  `}
`;

const TitleEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const TitleLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const ManualEditorBlock = styled.div`
  display: block;
  width: 100%;
`;

const ManualEditorBlockLabel = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-bottom: 14px;
  `}
`;

const TitleEditorInput = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;
// ==================================

// Body =============================
const BodyBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
  display: flex;
  `}
`;

const BodyEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const BodyLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;
// ==================================

// category ============================
const CategoryBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
`;
const CategoryEditorBlock = styled.div`
  width: 100%;
  max-width: 610px;
`;
const CategoryLabel = styled.label`
  display: block;
  width: 90px;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
`;

const CategoriesList = styled.ul``;
const CategoriesItem = styled.li`
  display: inline-block;
  margin-right: 20px;
`;
// =======================================

// Tag ===================================
const TagBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
`;
const TagLabel = styled.label`
  display: block;
  width: 90px;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
`;

const TagEditorBlock = styled.div`
  width: 100%;
  max-width: 610px;
`;

const TagsList = styled(CategoriesList)``;
const TagsItem = styled(CategoriesItem)``;

// ========================================

// Status =================================
const StatusLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const StatusEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const StatusEditorInput = styled.select`
  ${inputBaseStyle}
  width: 240px;
`;

const StatusBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  ${pc`
    display: flex;
    align-items: center;
  `}
`;
// ===========================================

// checkBox ==================================
const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;

const CheckboxSpan = styled.span`
  padding-left: 22px;
`;

const Checkbox = styled.input`
  display: none;
`;
