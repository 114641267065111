import React, { FC } from "react";
import {
  AlertTextSC,
  LabelSC,
  TextInputSC,
  ContainerSC,
  TextContainerSC
} from "./style";

interface TextInputProps {
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  outline?: boolean;
  isValid?: boolean;
  alertText?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "number";
}

export const TextInput: FC<TextInputProps> = ({
  label = "",
  defaultValue,
  placeholder,
  disabled,
  outline,
  isValid,
  alertText,
  value,
  type = "text",
  onChange
}) => {
  return (
    <>
      <TextContainerSC>
        {label ? <LabelSC>{label}</LabelSC> : ""}
        <ContainerSC>
          <TextInputSC
            className={`${outline ? "outline" : ""} ${isValid ? "alert" : ""}`}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={e => onChange(e)}
          />
          <AlertTextSC
            className={`${outline ? "outline" : ""} ${isValid ? "alert" : ""}`}
          >
            {alertText}
          </AlertTextSC>
        </ContainerSC>
      </TextContainerSC>
    </>
  );
};
