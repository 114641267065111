import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../Common/PageHeading";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import CategoryList from "./CategoryList";
import { color, buttonBaseStyle, containerBaseStyle } from "../../mixins";
import {
  deleteCategoryItemRequested,
  postCategoryItemRequested,
  updateCategorisItemRequested
} from "../../../store/faq/categories/action";
import { getRegisteredCategoriesRequested } from "../../../store/faq/categories/action";

function Categories(props: any) {
  const dispatch = useDispatch();
  const sidebarIsOpen = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );
  const to_be_deleted_array = useSelector(
    (state: any) => state.categories_to_be_deleted
  );
  const to_be_created_array = useSelector(
    (state: any) => state.categories_to_be_created
  );
  const to_be_updated_array = useSelector(
    (state: any) => state.categories_to_be_updated
  );
  const token = useSelector((state: any) => state.session.token);
  console.log("to_be_created_array", to_be_created_array);

  const handleUpdateButtonClicked = (e: any) => {
    e.preventDefault();
    to_be_created_array.forEach((item: any) => {
      const temp_id = item.temp_id;
      const new_category_name = item.name;
      const new_category_order = item.order;
      dispatch(
        postCategoryItemRequested(
          token,
          new_category_name,
          new_category_order,
          temp_id
        )
      );
    });
    to_be_updated_array.forEach((item: any) => {
      const id = item.id;
      const updated_name = item.name;
      const updated_order = item.order;
      console.log("to_be_updated_array", to_be_updated_array);
      console.log("id", id);
      console.log("updated_name", updated_name);
      console.log("updated_order", updated_order);
      dispatch(
        updateCategorisItemRequested(token, id, updated_name, updated_order)
      );
    });
    to_be_deleted_array.forEach((id: number) => {
      dispatch(deleteCategoryItemRequested(token, id));
    });
  };

  useEffect(() => {
    dispatch(getRegisteredCategoriesRequested(token));
  }, [dispatch, token]);

  return (
    <MainSC>
      <PageHeading title="カテゴリ設定" />
      <CategoryListBlockSC>
        <CategoryDescSC>
          ※並び順は1~99999の整数で指定してください。
          <br />
          ※カテゴリ及びタグは並び順の昇順で表示され、並び順が同じものは登録順で表示されます。
        </CategoryDescSC>
        <CategoryList />
      </CategoryListBlockSC>
      <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
        <SubmitButtonSC onClick={handleUpdateButtonClicked}>
          更新
        </SubmitButtonSC>
      </FixedButtonAreaSC>
    </MainSC>
  );
}

export default Categories;

// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;

const CategoryListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  // padding: 30px 20px !important;
  padding: 24px 16px;
  background: #ffffff;
  ${pc`
    max-width: 810px;
    width: 65.5%;
    margin-right: 10px;
    padding: 30px;
  `}
`;

const CategoryDescSC = styled.p`
  color: ${color.gray};
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 12px;
`;

const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  // width: calc(100vw - 200px);
  width: 100%;
  height: 70px;
  padding: 13px 0;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};

  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -30px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width:calc(100vw - 50px);
    `}
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
  opacity: 1;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
