import { POST_ARTICLE_REQUESTED, PUT_NEW_ARTICLE_REQUESTED, PUT_EDIT_ARTICLE_REQUESTED, TOGGLE_NEW_ARTICLE_CATEGORY_CHECK, TOGGLE_NEW_ARTICLE_TAG_CHECK, INPUT_NEW_ARTICLE_TITLE, SELECT_NEW_ARTICLE_STATUS, SET_RAW_CONTENT, TOGGLE_NEW_ARTICLE_POST_STATUS, SET_SEARCH_STRING_ARTICLE_NEW } from "./type";

// ================================================================= //
// Interface
// ----------------------------------------------------------------- //
interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

// =====================================TOGGLE_CHECKED_ARTICLE_CATEGORY============================ //
// Actions
// ----------------------------------------------------------------- //

// 記事情報登録
export const postArticleRequest = (token: string, title: string, content: any, search_string: string, status: number, categories: Category[], tags: Tag[]) => {
  return {
    type: POST_ARTICLE_REQUESTED,
    token,
    title,
    content,
    search_string,
    status,
    categories,
    tags
  };
};

// 記事情報登録
export const putNewArticleRequest = (new_article_id: number, token: string, title: string, content: any, search_string: string, status: number, categories: Category[], tags: Tag[]) => {
  return {
    type: PUT_NEW_ARTICLE_REQUESTED,
    new_article_id,
    token,
    title,
    content,
    search_string,
    status,
    categories,
    tags
  };
};

// 記事情報更新
export const putEditArticleRequest = (edit_article_id: number, token: string, title: string, content: any, search_string: string, status: number, categories: Category[], tags: Tag[]) => {
  return {
    type: PUT_EDIT_ARTICLE_REQUESTED,
    edit_article_id,
    token,
    title,
    content,
    search_string,
    status,
    categories,
    tags
  };
};


export const inputNewArticleTitle = (title: string) => {
  return {
    type: INPUT_NEW_ARTICLE_TITLE,
    title
  };
};


export const toggleNewArticleCategoryCheck = (id: number, category: Category) => {
  return {
    type: TOGGLE_NEW_ARTICLE_CATEGORY_CHECK,
    id,
    category
  };
};

export const toggleNewArticleTagCheck = (id: number, tag: Tag) => {
  return {
    type: TOGGLE_NEW_ARTICLE_TAG_CHECK,
    id,
    tag
  };
};

export const selectNewArticleStatus = (id: number) => {
  return {
    type: SELECT_NEW_ARTICLE_STATUS,
    id
  };
};

export const setRawContent = (content: any) => {
  return {
    type: SET_RAW_CONTENT,
    content
  }
}

export const toggleArticleNewPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_NEW_ARTICLE_POST_STATUS,
    post_status
  }
}

export const setSearchStringArticleNew = (search_string: string) => {
  return {
    type: SET_SEARCH_STRING_ARTICLE_NEW,
    search_string
  }
}
