export const useEventYear = () => {
  // 来年を取得
  const nextYear = new Date().getFullYear() + 1;

  // 初期化
  let years: number[] = [];

  // 2021年を起点に来年になるまで、配列に値をpushする
  for (let i = 2021; i <= nextYear; i++) {
    years.push(i);
  }

  return {
    years
  };
};
