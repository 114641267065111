import React from 'react'
import styled from 'styled-components';
import ServiceListBlock from './ServiceListBlock'

export default function ServiceSelectModalContent() {
  return (
    <ServiceSelectModalContentSC>
      <TitleBlock>サービス選択</TitleBlock>
      <ServiceListBlock />
    </ServiceSelectModalContentSC>
  )
}

// ===================================================================================
// スタイル
// ===================================================================================
const ServiceSelectModalContentSC = styled.div`
  max-width: 800px;
  width: 90%;
  height: 600px;
  position: fixed;
  z-index: 120;
  background-color: #FFFFFF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.h1`
  position: fixed;
  height: 48px;
  width: 100%;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,.16));
  background-color: #FFFFFF;
  margin: 0;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  color: #444;
`
