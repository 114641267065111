import React, {
  HtmlHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";
import {
  AccordionBoxDomStyled,
  AccordionBoxDomStyledBody,
  AccordionBoxDomStyledHheader,
  AccordionBoxDomStyledHeaderIcon,
  AccordionBoxDomStyledHeaderText,
  AccordionBoxDomStyledMain,
  AccordionBoxDomStyledMainInner
} from "./style";
import { SvgArrowBottom } from "components/Common/svg/arrowBottom";

/**
 * AccordionBoxDom
 */
export interface AccordionBoxDomProps
  extends HtmlHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  caption?: string;
  active?: boolean;
  setActive?: (active: boolean) => void;
}
export const AccordionBoxDom = (props: AccordionBoxDomProps) => {
  //-----------------------------------------------------
  // init
  //-----------------------------------------------------
  const [isOpen, setIsOpen] = useState(props.active || false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  //-----------------------------------------------------
  // handler
  //-----------------------------------------------------
  const handleHeaderClick = () => {
    if (isOpen) {
      setIsOpen(false);
      if (props.setActive) {
        props.setActive(false);
      }
    } else {
      setIsOpen(true);
      if (props.setActive) {
        props.setActive(true);
      }
    }
  };

  //-----------------------------------------------------
  // effect
  //-----------------------------------------------------
  useEffect(() => {
    setHeight(contentRef.current?.offsetHeight || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current?.offsetHeight]);

  //-----------------------------------------------------
  // render
  //-----------------------------------------------------
  return (
    <AccordionBoxDomStyled
      className={`${props.className || ""} ${isOpen ? "open" : ""}`}
    >
      <AccordionBoxDomStyledBody>
        {/** ヘッダー */}
        <AccordionBoxDomStyledHheader
          onClick={event => {
            event.preventDefault();
            handleHeaderClick();
          }}
        >
          <AccordionBoxDomStyledHeaderText>
            {props.caption || "タイトル"}
          </AccordionBoxDomStyledHeaderText>
          <AccordionBoxDomStyledHeaderIcon>
            <SvgArrowBottom />
          </AccordionBoxDomStyledHeaderIcon>
        </AccordionBoxDomStyledHheader>

        {/** メイン */}
        <AccordionBoxDomStyledMain
          style={{
            height: isOpen ? `${height}px` : 0
          }}
        >
          <AccordionBoxDomStyledMainInner ref={contentRef}>
            {props.children}
          </AccordionBoxDomStyledMainInner>
        </AccordionBoxDomStyledMain>
      </AccordionBoxDomStyledBody>
    </AccordionBoxDomStyled>
  );
};
