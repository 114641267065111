import { EventType } from "components/Events/type";
import { color } from "components/mixins";
import React from "react";
import styled from "styled-components";
import pulldown_arrow from "../../../../../images/icons/pulldown_arrow.svg";

interface EventTypeSelectProps {
  onChangeFunc: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  typeId: number;
  typeOfEvent: EventType[];
}

const EventTypeSelect = (props: EventTypeSelectProps) => {
  const { onChangeFunc, typeId, typeOfEvent } = props;
  return (
    <InputItemSelect onChange={onChangeFunc} value={typeId} required>
      <option
        key="unselected"
        value=""
        disabled
        selected
        style={{ display: "none" }}
        className="placeholder"
      >
        選択してください
      </option>
      {typeOfEvent.map(({ id, name }: EventType) => (
        <option key={id} value={id} className="placeholder">
          {name}
        </option>
      ))}
    </InputItemSelect>
  );
};

export default EventTypeSelect;

const InputItemSelect = styled.select`
  padding: 11px;
  border: 1px solid ${color.gray_border};
  border-radius: 4px;
  width: 90%;
  max-width: 400px;
  font-size: 14px;
  line-height: 1;
  background-image: url(${pulldown_arrow});
  background-repeat: no-repeat;
  background-position: right 12px top 15px;
  cursor: pointer;
`;
