import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_INITIAL_EDIT_INPUT,
  RESET_EDIT_EVENT_CONTENT,
  SET_EDIT_EVENT_DATE,
  SET_EDIT_EVENT_TYPE,
  SET_EDIT_EVENT_CONTENT,
  SET_EDIT_EVENT_DETAIL,
  SET_EDIT_EVENT_CLASS
} from "./type";

interface EventEditClass {
  class_age: string;
  checked: boolean;
}

interface AdditionalInformation {
  detail: string;
  class: string;
}

interface Event {
  yearMonthDate: string;
  month: number | null;
  date: number | null;
  id: number | null;
  title: string;
  type_id: number | string;
  type_name: string;
  content: string;
  detail: string;
  classes: EventEditClass[];
  kind: number | null;
}

const initialState: Event = {
  yearMonthDate: "",
  month: null,
  date: null,
  id: null,
  title: "",
  type_id: "",
  type_name: "",
  content: "",
  detail: "",
  classes: [
    {
      class_age: "0",
      checked: false
    },
    {
      class_age: "1",
      checked: false
    },
    {
      class_age: "2",
      checked: false
    },
    {
      class_age: "3",
      checked: false
    },
    {
      class_age: "4",
      checked: false
    },
    {
      class_age: "5",
      checked: false
    }
  ],
  kind: null
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_INITIAL_EDIT_INPUT:
      console.log("action:", action);
      const class_age_array = action.class_age.split(",");

      const initially_set_classes = state.classes.map(
        (class_item: EventEditClass) => {
          if (
            class_age_array.some(
              (class_age: string) => class_age === class_item.class_age
            )
          ) {
            return {
              class_age: class_item.class_age,
              checked: true
            };
          }
          return {
            class_age: class_item.class_age,
            checked: false
          };
        }
      );

      return {
        yearMonthDate: action.yearMonthDate,
        year: action.year,
        month: action.month,
        date: action.date,
        id: action.id,
        title: action.title,
        type_id: action.type_id,
        content: action.content,
        detail: action.detail,
        classes: initially_set_classes,
        remand: action.remand,
        additional_information: action.additional_information
      };

    case RESET_EDIT_EVENT_CONTENT:
      return {
        ...state,
        type_id: "",
        content: "",
        detail: "",
        class_age: ""
      };

    case SET_EDIT_EVENT_DATE:
      return {
        ...state,
        year: action.year,
        month: action.month,
        date: action.date
      };

    case SET_EDIT_EVENT_TYPE:
      return {
        ...state,
        type_id: action.type_id,
        type_name: action.type_name,
        classes: [
          {
            class_age: "0",
            checked: false
          },
          {
            class_age: "1",
            checked: false
          },
          {
            class_age: "2",
            checked: false
          },
          {
            class_age: "3",
            checked: false
          },
          {
            class_age: "4",
            checked: false
          },
          {
            class_age: "5",
            checked: false
          }
        ]
      };

    case SET_EDIT_EVENT_CONTENT:
      return {
        ...state,
        content: action.content
      };

    case SET_EDIT_EVENT_DETAIL:
      return {
        ...state,
        detail: action.detail
      };

    case SET_EDIT_EVENT_CLASS:
      console.log("action.classes_class_age", action.classes_class_age);
      const updated_classes = state.classes.map(
        (class_item: EventEditClass) => {
          if (class_item.class_age === action.classes_class_age) {
            return {
              class_age: class_item.class_age,
              checked: !class_item.checked
            };
          }
          return class_item;
        }
      );
      return {
        ...state,
        classes: updated_classes
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
