import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import CancelAllEventsModalContent from "./CancelAllEventsModalContent";

import { toggleCancelAllEventsModal } from "../../../../store/common/modal/action";

export default function CancelAllEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <ModalBg
        onClick={() => dispatch(toggleCancelAllEventsModal(false))}
      ></ModalBg>
      <CancelAllEventsModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
