import styled from "styled-components";

export const TextContainerSC = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelSC = styled.label`
  margin-right: 19px;
  font-size: 12px;
  display: block;
  color: #444444;
`;

export const ContainerSC = styled.div`
  display: block;
  position: relative;
`;

interface TextInputSCProps {
  isValid?: boolean;
  outline?: boolean;
}

export const TextInputSC = styled.input`
  width: 347px;
  padding: 10px 6px;
  border-bottom: 1px solid #c4c4c4;

  &::placeholder {
    color: #888888;
    font-size: 12px;
  }
  &:hover,
  :focus {
    color: #444444;
    border-bottom: 1px solid #444444;
    &::placeholder {
      color: #444444;
    }
  }

  &.alert {
    border-bottom: 1px solid #dd0000;
  }

  &.outline {
    border: 1px solid #c4c4c4;
    width: 300px;
    height: 36px;
    background-color: #ffffff;
    padding: 10px 6px;
    &::placeholder {
      color: #888888;
    }
    &:hover,
    :focus {
      color: #444444;
      border: 1px solid #444444;
      &::placeholder {
        color: #444444;
      }
    }
    &.alert {
      border: 1px solid #dd0000;
    }
  }
`;

interface AlertTextSCProps {
  isValid?: boolean;
  outline?: boolean;
}

export const AlertTextSC = styled.label`
  display: none;
  &.alert,
  .outline {
    color: #dd0000;
    display: block;
    font-size: 12px;
    margin-top: 6px;
    margin-left: 5.5px;
    align-items: center;
    position: absolute;
    top: 35px;
    left: 0;
  }
`;
