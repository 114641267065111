import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Page } from "store/manual/manuals/pages/types.d";
import { pc } from "components/mixins/mediaQuery";
import { Link, useParams } from "react-router-dom";
import PaginateLeftArrowIcon from "images/icons/paginate-left-arrow.svg";
import PaginateRightArrowIcon from "images/icons/paginate-right-arrow.svg";
import { color, containerBaseStyle } from "components/mixins";
import { manualPagesSelector } from "store/manual/manuals/pages/selectors";
import { manualManualsSelector } from "store/manual/manuals/selectors";

import "components/Manual/Parts/TinyMCEStyles/default-content.min.scss";
import "components/Manual/Parts/TinyMCEStyles/content.min.scss";
import "components/Manual/Parts/TinyMCEStyles/skin.min.scss";

const PagesDetailBlock = (props: { storedPageDetail: Page }) => {
  const { storedPageDetail } = props;
  const { manualId, topicId, pageId } =
    useParams<{ manualId: string; topicId: string; pageId: string }>();

  const manuals = useSelector(manualManualsSelector) || [];
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  const targetManual = manuals.find(
    (manual: any) => manual.id === Number(manualId)
  );

  const manualPages = useSelector(manualPagesSelector) || [];
  console.log("manualPages", manualPages);

  const targetManualPages = manualPages.filter(
    (page: any) => page.status !== 1
  );
  console.log("targetManualPages", targetManualPages);

  const currentPage = (page: any) => page.id === Number(pageId);
  console.log("currentPage", currentPage);
  console.log(
    "targetManualPages.findIndex(currentPage)",
    targetManualPages.findIndex(currentPage)
  );
  const prevPageIndex = targetManualPages.findIndex(currentPage) - 1;
  const nextPageIndex = targetManualPages.findIndex(currentPage) + 1;

  const prevPageId =
    prevPageIndex !== -1 && targetManualPages[prevPageIndex]
      ? targetManualPages[prevPageIndex].id
      : null;
  console.log("prevPageId", prevPageId);

  const nextPageId =
    nextPageIndex &&
    nextPageIndex < targetManualPages.length &&
    targetManualPages[nextPageIndex]
      ? targetManualPages[nextPageIndex].id
      : null;
  console.log("nextPageId", nextPageId);

  return (
    <PageDetailBlock className={rightSectionOpen ? "open" : "close"}>
      <ContentWrapper>
        <div
          className="mce-content-body"
          dangerouslySetInnerHTML={{ __html: storedPageDetail.content }}
        ></div>
      </ContentWrapper>

      <ManualPagePaginationSC>
        {prevPageId && (
          <ManualPagePaginationLeftSC
            to={`/manual/manuals/${manualId}/topics/${topicId}/pages/${prevPageId}`}
          >
            <img src={PaginateLeftArrowIcon} alt="go back" />
            <span>前へ</span>
          </ManualPagePaginationLeftSC>
        )}

        <ManualPagePaginationManualLink
          to={`/manual/manuals/${targetManual && targetManual.id}`}
        >
          {targetManual && targetManual.name}TOPへ
        </ManualPagePaginationManualLink>

        {nextPageId && (
          <ManualPagePaginationRightSC
            to={`/manual/manuals/${manualId}/topics/${topicId}/pages/${nextPageId}`}
          >
            <span>次へ</span>
            <img src={PaginateRightArrowIcon} alt="go back" />
          </ManualPagePaginationRightSC>
        )}
      </ManualPagePaginationSC>
    </PageDetailBlock>
  );
};

export default PagesDetailBlock;

const PageDetailBlock = styled.div`
  ${containerBaseStyle}
  width: 100%;
  max-width: auto;
  margin-bottom: 20px;
  order: 2;
  padding: 0px;
  ${pc`
    width: calc( 100% - 380px);
    margin-right: 20px;
    order: 1;
    padding: 30px;
    &.close {
      width: 100%;
      margin-right: 0px;
    }
  `}
  position: relative;
`;

const ContentWrapper = styled.div`
  padding: 12px;
  ${pc`
    padding: 0px;
  `}
  img {
    width: auto;
    max-width: 100%;
  }
  iframe {
    max-width: 100%;
  }
  strong {
    font-weight: bolder;
  }
  *.center {
    text-align: center;
  }
  *.right {
    text-align: right;
  }
  *.left {
    text-align: left;
  }
  ol {
    list-style-type: decimal;
  }
`;

const ManualPagePaginationSC = styled.div`
  position: relative;
  margin-top: 80px;
  border-top: 1px solid lightgray;
  padding: 30px 12px 30px;
  display: block;
  text-align: center;
`;

const ManualPagePaginationLeftSC = styled(Link)`
  position: absolute;
  top: 30px;
  left: 12px;
  ${pc`
    left: 0;
  `}
  display: flex;
  justify-content: space-between;
  &:hover {
    opacity: 0.6;
    text-decoration: underline;
  }
  img {
    width: 14px;
    display: block;
    margin-right: 4px;
  }
  span {
    font-size: 14px;
    display: none;
    ${pc`
      display: block;
    `}
  }
`;
const ManualPagePaginationRightSC = styled(Link)`
  position: absolute;
  top: 30px;
  right: 12px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${pc`
    right: 0;
  `}
  &:hover {
    opacity: 0.6;
    text-decoration: underline;
  }
  img {
    width: 14px;
    display: block;
    margin-left: 4px;
  }
  span {
    font-size: 14px;
    display: none;
    ${pc`
      display: block;
    `}
  }
`;
const ManualPagePaginationManualLink = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${color.primary};
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
    text-decoration: underline;
  }
`;
