import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { History } from "../components/Manual/demoData";
import {
  getHistoriesApi,
  updateAllHistoryApi,
  updateHistoryApi
} from "../api/manual/historiesApi";
import {
  GET_HISTORIES_REQUESTED,
  GET_HISTORIES_SUCCEEDED,
  GET_HISTORIES_FAILED,
  UPDATE_HISTORY_REQUESTED,
  UPDATE_HISTORY_SUCCEEDED,
  UPDATE_HISTORY_FAILED,
  UPDATE_ALL_HISTORIES_REQUESTED
} from "store/manual/histories/types";
import { AxiosResponse } from "axios";
import {
  AllHistoriesUpdateResponseProps,
  AllHitoriesUpdateRequestBodyProps,
  HistoriesGetResponseProps,
  HistoryUpdateResponseProps
} from "store/manual/histories/type";

//　履歴取得
function* getHistories(action: any) {
  console.log("saga is worked");
  try {
    const result: Promise<AxiosResponse<HistoriesGetResponseProps>> =
      yield call(getHistoriesApi, {
        token: action.token
      });
    yield put({
      type: GET_HISTORIES_SUCCEEDED,
      histories: result
    });
  } catch (e) {
    console.log(e);
    yield put({ type: GET_HISTORIES_FAILED, error: e.response.data });
  }
}

// 個別更新
function* updateHistory(action: any) {
  try {
    const result: Promise<AxiosResponse<HistoryUpdateResponseProps>> =
      yield call(updateHistoryApi, {
        token: action.token,
        id: action.id,
        priority: action.priority
      });
    yield put({
      type: UPDATE_HISTORY_SUCCEEDED,
      histories: result
    });
  } catch (e) {
    yield put({ type: UPDATE_HISTORY_FAILED, error: e.response });
  }
}

//一括更新
function* updateAllHistories(action: any) {
  const priorityStates: AllHitoriesUpdateRequestBodyProps[] =
    action.histories.map((history: History) => {
      return {
        id: history.id,
        priority: history.priority
      };
    });
  try {
    const result: Promise<AxiosResponse<AllHistoriesUpdateResponseProps>> =
      yield call(updateAllHistoryApi, {
        token: action.token,
        req_body: priorityStates
      });
    console.log(result);
    yield put({
      type: UPDATE_HISTORY_SUCCEEDED,
      data: result
    });
  } catch (e) {
    console.log(e);
    yield put({ type: UPDATE_HISTORY_FAILED, error: e.response });
  }
}

const historiesSaga = [
  takeLatest(GET_HISTORIES_REQUESTED, getHistories),
  takeEvery(UPDATE_HISTORY_REQUESTED, updateHistory),
  takeLatest(UPDATE_ALL_HISTORIES_REQUESTED, updateAllHistories)
];

export default historiesSaga;
