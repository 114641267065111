import React, { FC, ChangeEvent } from "react";
import { RadioButtonInputSC, RadioButtonLabelSC, RadiobuttonSC } from "./style";

interface RadioButtonProps {
  id: string;
  label: string;
  name: string;
  value: string;
  selected?: boolean;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButton: FC<RadioButtonProps> = props => {
  const { label, name, value, selected, checked, onChange, id } = props;
  return (
    <>
      <RadiobuttonSC>
        <RadioButtonInputSC
          type="radio"
          name={name}
          value={value}
          onChange={onChange}
          id={id}
          checked={checked}
        />
        <RadioButtonLabelSC htmlFor={id}>{label}</RadioButtonLabelSC>
      </RadiobuttonSC>
    </>
  );
};
