import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TabContainer, TabSwitch } from "components/Camera/components/TabArea";
import {
  TabContent,
  TabSwitchWrapper
} from "components/Camera/components/TabArea/style";
import { AlbumListThumbnail } from "../components/AlbumListThumbnail";
import { ToggleSwitch } from "../components/ToggleSwitch";
import { AlbumListTable } from "../components/AlbumListTable";
import { ModalDelete } from "components/Camera/components/ModalDelete";
import { ModalSetting } from "components/Camera/components/ModalSetting";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { ListViewToggle } from "../components/ListViewToggle";
import { SwitchWrapperSC } from "./style";
import { useCurrentPosition } from "components/Events/Constants/useCurrentPosition";
import { SearchBlock } from "../components/SearchBlock";
import { useTextInput } from "../hooks/useTextInput";
import PageHeading from "components/Common/PageHeading";
import { getFacilitiesMeRequested } from "store/camera/facilities_me/action";
import { getCameraClassesRequested } from "store/camera/classes/action";
import {
  getMediaFilesMineRequested,
  getMediaFilesShareRequested,
  getMediaFilesAdminRequested,
  toggleMediaFileViewMode,
  toggleMediaFileSelectMode,
  setSelectedMediaIds,
  toggleImgLoadingState,
  deleteMediaFileRequested,
  setAlbumViewType,
  searchMediaFilesRequested
} from "store/camera/mediafiles/action";
import { ViewMode, AlbumViewType } from "../type";
import { getCameraTagsRequested } from "store/camera/tags/action";
import { FixedButtonArea } from "../components/FixedButtonArea";
import { getFacilityUsersRequested } from "store/camera/facility_users/action";
import { useSearchBlock } from "../components/SearchBlock/use-search-block";
import { useBatchSettingModal } from "../components/ModalSetting/use-batch-setting-modal";
import { Paginate } from "../components/Paginate";

import { SearchParams } from "store/camera/mediafiles/reducer";

import ReactPaginate from "react-paginate";
import "../components/Paginate/pagination.css";

const ONE_PAGE_DISPLAY_FILES = 20;
const LAST_DISPLAY_SIZE = 20;
const AROUND_DISPLAY_PAGES = 5;

export const CameraHome = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  // states ===============================================================
  // 一括削除モーダル
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  // 公開状態
  const [isShareBan, setIsShareBan] = useState<boolean>(false);
  // ======================================================================

  // store states =========================================================
  // 個人アルバムデータ
  const mediaFilesMine = useSelector(
    (state: RootState) => state.mediafiles.media_files_mine
  );
  // 共有済みアルバムデータ
  const mediaFilesShare = useSelector(
    (state: RootState) => state.mediafiles.media_files_share
  );
  // 管理者アルバムデータ
  const mediaFilesAdmin = useSelector(
    (state: RootState) => state.mediafiles.media_files_admin
  );
  // 選択されたメディアのID配列
  const selectedMedia: number[] = useSelector(
    (state: RootState) => state.mediafiles.selectedMediaIds
  );
  // 現在のViewの状態
  const viewMode = useSelector((state: RootState) => state.mediafiles.viewMode);
  // 表示しているアルバムの区分
  const albumViewType: AlbumViewType = useSelector(
    (state: RootState) => state.mediafiles.albumViewType
  );
  // 編集モードの状態
  const isSelectMode = useSelector(
    (state: RootState) => state.mediafiles.isSelectMode
  );
  // トークン
  const token = useSelector((state: RootState) => state.session.token);
  // 所属施設
  const facilities = useSelector((state: RootState) => state.facilities_me);
  // 所属施設ユーザー
  const users = useSelector((state: RootState) => state.facility_users);
  // 登録済みタグ
  const tags = useSelector((state: RootState) => state.camera_tags.camera_tags);
  // クラス一覧
  const classes = useSelector((state: RootState) => state.classes);

  // file_id

  // ======================================================================

  const searchParams: SearchParams = useSelector(
    (state: RootState) => state.mediafiles.search_params
  );

  const pagination_data = useSelector(
    (state: RootState) => state.mediafiles.pagination_data
  );

  const paginateAdmin = useSelector(
    (state: RootState) => state.mediafiles.paginate_admin
  );

  const paginateShare = useSelector(
    (state: RootState) => state.mediafiles.paginate_share
  );

  const paginateMine = useSelector(
    (state: RootState) => state.mediafiles.paginate_mine
  );

  const imgLoading = useSelector(
    (state: RootState) => state.mediafiles.imgLoading
  );

  // ログインユーザーが管理者かどうかの判定
  const { isAdmin } = useCurrentPosition();
  const fileId = useTextInput();
  const fileIdData = {
    fileId: fileId.value,
    onChangeFileId: fileId.onChange
  };
  const searchBlockData = useSearchBlock(token);
  const { onSearchFunc, setPageNumberHook } = searchBlockData;

  const {
    open,
    toggleBatchSettingModal,
    handleChangeSettingClasses,
    handleChangeSettingTags,
    handleChangeSettingFacility,
    handleChangeSetttingShareBan,
    handleSelectedUpdateMediaFiles
  } = useBatchSettingModal(token, selectedMedia, isAdmin);

  const { page } = searchParams;

  // handlers =============================================================
  // 一括削除モーダル開閉
  const toggleDeleteModalOpen = () => {
    setDeleteModalOpen(state => !state);
  };
  // 表示アルバム種別切り替え
  const handleSetAlbumViewType = (view: AlbumViewType) => {
    dispatch(setAlbumViewType(view));
  };
  // 表示モード切り替え
  const handleToggleViewMode = (viewMode: ViewMode) => {
    dispatch(toggleMediaFileViewMode(viewMode));
  };
  // 選択モード切り替え
  const handleToggleSelectMode = () => {
    dispatch(toggleMediaFileSelectMode());
  };

  // メディアファイル選択
  const handleSelectedMedia = (mediaFileID: number) => {
    dispatch(setSelectedMediaIds(mediaFileID));
  };
  const handleSelectedDeleteMediaFiles = async () => {
    dispatch(toggleImgLoadingState(true));
    selectedMedia.forEach((id: number) => {
      dispatch(deleteMediaFileRequested(token, id));
    });
    toggleDeleteModalOpen();
  };

  const handlePaginate = (selectedItem: { selected: number }) => {
    const currentPage = selectedItem.selected + 1;
    dispatch(
      searchMediaFilesRequested(token, albumViewType, {
        ...searchParams,
        page: currentPage
      })
    );

    // setPageNumberHook(currentPage.toString());
    // setTimeout(()=> onSearchFunc(), 700);
  };
  // ページ数の計算
  const calculatePageCount = () => {
    return Math.ceil(pagination_data.total / ONE_PAGE_DISPLAY_FILES);
  };

  // ======================================================================

  // ページ訪問時にデータ取得
  useEffect(() => {
    dispatch(toggleImgLoadingState(true));
    dispatch(getCameraTagsRequested(token));
    dispatch(getFacilitiesMeRequested(token));
    dispatch(getFacilityUsersRequested(token));
    dispatch(getCameraClassesRequested(token));
    setTimeout(() => {
      if (!isAdmin && albumViewType === "admin") {
        handleSetAlbumViewType("mine");
        dispatch(
          searchMediaFilesRequested(token, "mine", {
            ...searchParams,
            page: page
          })
        );
      } else {
        dispatch(
          searchMediaFilesRequested(token, albumViewType, {
            ...searchParams,
            page: page
          })
        );
      }
    }, 1000);
  }, [dispatch, token]);

  return (
    <>
      <div style={{ margin: "30px 20px" }}>
        <PageHeading title="アルバム" />
        <SearchBlock
          isAdmin={isAdmin}
          viewType={albumViewType}
          tags={tags}
          classes={classes}
          facilities={facilities}
          users={users}
          {...searchBlockData}
          fileId={fileIdData.fileId}
          onChangeFileId={fileIdData.onChangeFileId}
        />
        <SwitchWrapperSC>
          <ToggleSwitch
            label="選択モード切替"
            name="toggule"
            value="value"
            onChange={handleToggleSelectMode}
            selected={isSelectMode}
            id="1"
          />
          <ListViewToggle
            viewMode={viewMode}
            onToggleViewType={handleToggleViewMode}
          />
        </SwitchWrapperSC>
        <TabSwitchWrapper>
          <TabSwitch
            id="share"
            name="Tab"
            label="共有済みアルバム"
            show={() => {
              handleSetAlbumViewType("share");
              dispatch(searchMediaFilesRequested(token, "share", searchParams));
            }}
            active={albumViewType === "share"}
          />
          <TabSwitch
            id="mine"
            name="Tab"
            label="個人アルバム"
            show={() => {
              handleSetAlbumViewType("mine");
              dispatch(searchMediaFilesRequested(token, "mine", searchParams));
            }}
            active={albumViewType === "mine"}
          />
          {isAdmin && (
            <TabSwitch
              id="admin"
              name="Tab"
              label="管理者アルバム"
              show={() => {
                handleSetAlbumViewType("admin");
                dispatch(
                  searchMediaFilesRequested(token, "admin", searchParams)
                );
              }}
              active={albumViewType === "admin"}
            />
          )}
        </TabSwitchWrapper>

        <TabContainer>
          <TabContent active={albumViewType === "share"}>
            {viewMode === "list" ? (
              <AlbumListTable
                mediaFiles={mediaFilesShare}
                isSelectMode={isSelectMode}
                onSelectMedia={handleSelectedMedia}
                selectedMedia={selectedMedia}
                albumViewType={albumViewType}
              />
            ) : (
              <AlbumListThumbnail
                mediaFiles={mediaFilesShare}
                isSelectMode={isSelectMode}
                onSelectMedia={handleSelectedMedia}
                selectedMedia={selectedMedia}
              />
            )}
            {/* <Paginate
                userSize={paginateShare.total}
                handleSearchMediafiles={() => onSearchFunc()}
                setCurrentPageNumber={pgNumber =>
                  setPageNumberHook(pgNumber.toString())
                }
              /> */}
          </TabContent>
          <TabContent active={albumViewType === "mine"}>
            {viewMode === "list" ? (
              <AlbumListTable
                mediaFiles={mediaFilesMine}
                isSelectMode={isSelectMode}
                onSelectMedia={handleSelectedMedia}
                selectedMedia={selectedMedia}
                albumViewType={albumViewType}
              />
            ) : (
              <AlbumListThumbnail
                mediaFiles={mediaFilesMine}
                isSelectMode={isSelectMode}
                onSelectMedia={handleSelectedMedia}
                selectedMedia={selectedMedia}
              />
            )}
            {/* <Paginate
                userSize={paginateMine.total}
                handleSearchMediafiles={() => onSearchFunc()}
                setCurrentPageNumber={pgNumber =>
                  setPageNumberHook(pgNumber.toString())
                }
              /> */}
          </TabContent>
          {isAdmin && (
            <TabContent active={albumViewType === "admin"}>
              {viewMode === "list" ? (
                <AlbumListTable
                  mediaFiles={mediaFilesAdmin}
                  isSelectMode={isSelectMode}
                  onSelectMedia={handleSelectedMedia}
                  selectedMedia={selectedMedia}
                  albumViewType={albumViewType}
                />
              ) : (
                <AlbumListThumbnail
                  mediaFiles={mediaFilesAdmin}
                  isSelectMode={isSelectMode}
                  onSelectMedia={handleSelectedMedia}
                  selectedMedia={selectedMedia}
                />
              )}
              {/* <Paginate
                  userSize={paginateAdmin.total}
                  handleSearchMediafiles={() => onSearchFunc()}
                  setCurrentPageNumber={pgNumber =>
                    setPageNumberHook(pgNumber.toString())
                  }
                /> */}
            </TabContent>
          )}

          <div className="paginateContainer">
            <ReactPaginate
              pageCount={calculatePageCount()}
              marginPagesDisplayed={LAST_DISPLAY_SIZE}
              pageRangeDisplayed={AROUND_DISPLAY_PAGES}
              onPageChange={handlePaginate}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              activeClassName="active"
              activeLinkClassName="active"
              previousLinkClassName="previous-link"
              nextLinkClassName="next-link"
              disabledClassName="disabled-button"
            />
          </div>
        </TabContainer>
      </div>
      {open && (
        <ModalSetting
          open={open}
          toggleVisible={toggleBatchSettingModal}
          tags={tags}
          classes={classes}
          facilities={facilities}
          isShareBan={isShareBan}
          onClickUpdateBtnFunc={handleSelectedUpdateMediaFiles}
          onChangeClassFunc={handleChangeSettingClasses}
          onChangeFacilityFunc={handleChangeSettingFacility}
          onChangeShareBanFunc={handleChangeSetttingShareBan}
          onChangeTagFunc={handleChangeSettingTags}
        />
      )}
      <ModalDelete
        open={deleteModalOpen}
        toggleVisible={toggleDeleteModalOpen}
        onAcceptConfirm={handleSelectedDeleteMediaFiles}
      />
      {isSelectMode && (
        <FixedButtonArea
          onUpdateFunc={toggleBatchSettingModal}
          onDeleteFunc={toggleDeleteModalOpen}
        />
      )}
    </>
  );
};
