import React from "react";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color } from "../../../mixins";
import tag from "../../../../images/icons/tag.svg";
import WysiwygArticleEditPreview from "./WysiwygArticleEditPreview";

function ArticleEditPreviewContent(props: any) {
  return (
    <>
      <DetailHeaderSC>
        <HeaderUpsideSC>
          {/* カテゴリー一覧 */}
          <CategoryListSC>
            {props.categories.map((category: any) => {
              return <CategoryItemSC>{category.name}</CategoryItemSC>;
            })}
          </CategoryListSC>
        </HeaderUpsideSC>

        {/* タイトル */}
        <ArticleTitleSC>{props.title}</ArticleTitleSC>

        {/* タグ */}
        <TagListSC>
          {props.tags.map((tag: any) => {
            return <TagItemSC>{tag.name}</TagItemSC>;
          })}
        </TagListSC>
      </DetailHeaderSC>

      {/* コンテンツ */}
      <DetailContentsSC>
        {props.content && typeof props.content === "string" && (
          <>
            <WysiwygArticleEditPreview content={props.content} />
          </>
        )}
      </DetailContentsSC>
    </>
  );
}

export default ArticleEditPreviewContent;
// ===================================================================================
// スタイル
// ===================================================================================

const DetailHeaderSC = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  ${pc`
    padding: 30px;
  `}
`;

const HeaderUpsideSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CategoryListSC = styled.ul`
  display: inline-block;
`;

const CategoryItemSC = styled.li`
  display: inline-block;
  color: ${color.white};
  background-color: ${color.category_bg};
  font-size: 12px;
  padding: 4px 11px;
  border-radius: 10px;
  font-weight: 600;
  margin-right: 8px;
  margin-bottom: 0;
  ${pc`
    margin-bottom: 5px;
  `}
`;

const TagListSC = styled.ul`
  display: flex;
  margin-bottom: 16px;
  ${pc`
    margin-bottom: 0;
  `}
`;

const TagItemSC = styled.li`
  display: inline-block;
  color: ${color.gray};
  font-size: 12px;
  border-radius: 10px;
  margin-right: 8px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background-image: url(${tag});
    background-repeat: no-repeat;
    background-position: left center;
    margin-right: 3px;
  }
`;

const ArticleTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
`;
