import { GET_ACCOUNT_EDIT_REQUESTED, PUT_ACCOUNT_EDIT_REQUESTED, SET_EDIT_ACCOUNT_ID, SET_EDIT_ACCOUNT_NAME, SET_EDIT_ACCOUNT_POSITION_ID, SET_EDIT_ACCOUNT_EMAIL, SET_EDIT_ACCOUNT_PASSWORD, SET_EDIT_ACCOUNT_FACILITIES, TOGGLE_EDIT_ACCOUNT_POST_STATUS, DELETE_ACCOUNT_EDIT_REQUESTED } from "./type";

// 記事情報取得
export const getAccountEditRequest = (token: string, id: number) => {
  return {
    type: GET_ACCOUNT_EDIT_REQUESTED,
    token,
    id
  };
};

// 記事情報登録
export const putAccountEditRequest = (token: string, account_id: number, account_name: string, account_position_id: number, account_email: string, account_password: string, account_facilities: any) => {
  return {
    type: PUT_ACCOUNT_EDIT_REQUESTED,
    token,
    account_id,
    account_name,
    account_position_id,
    account_email,
    account_password,
    account_facilities
  };
};

// 記事情報削除
export const deleteAccountEditRequest = (token: string, account_id: number) => {
  return {
    type: DELETE_ACCOUNT_EDIT_REQUESTED,
    token,
    account_id
  };
};

export const setAccountEditId = (id: number, name: string, position_id: number, email: string) => {
  return {
    type: SET_EDIT_ACCOUNT_ID,
    id,
    name,
    position_id,
    email
  };
};

export const setAccountEditName = (name: string) => {
  return {
    type: SET_EDIT_ACCOUNT_NAME,
    name
  };
};

export const setAccountEditPositionId = (position_id: number) => {
  return {
    type: SET_EDIT_ACCOUNT_POSITION_ID,
    position_id
  };
};

export const setAccountEditEmail = (email: string) => {
  return {
    type: SET_EDIT_ACCOUNT_EMAIL,
    email
  };
};

export const setAccountEditPassword = (password: string) => {
  return {
    type: SET_EDIT_ACCOUNT_PASSWORD,
    password
  };
};

export const setAccountEditFacilities = (facility_id: number) => {
  return {
    type: SET_EDIT_ACCOUNT_FACILITIES,
    facility_id
  }
}

export const toggleAccountEditPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_EDIT_ACCOUNT_POST_STATUS,
    post_status
  };
};
