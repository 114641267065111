export const classTransformer = (classList: string) => {
  // クラスの文字列をクラスの配列にする
  let sortedClassList = classList.split(",").sort().join(",");
  switch (sortedClassList) {
    case "0,1,2,3,4,5":
      return "0~5";

    case "0,1,2,3,4":
      return "0~4";

    case "0,1,2,3,":
      return "0~3";

    case "1,2,3,4,5":
      return "1~5";

    case "1,2,3,4":
      return "1~4";

    case "2,3,4,5":
      return "2~5";

    default:
      return sortedClassList;
  }
};
