import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import PageHeading from "../../Common/PageHeading";
import { color, containerBaseStyle } from "../../mixins";
import {
  toggleInquiryNewPostStatus,
  resetPostedDetails
} from "../../../store/faq/inquiry_new/action";
interface Data {
  contact: number | null;
  kind?: number;
  details: string;
  terminal_details?: string;
  terminal?: string;
  model_name?: string;
  proxy_login?: boolean;
  user_id?: string;
  user_name?: string;
  user_email?: string;
  employee_check?: boolean;
  employee_id?: string;
  employee_name?: string;
  facility?: number;
  priority?: number;
  answer?: number;
  child_id?: string;
}
function InquiresNew() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleInquiryNewPostStatus(false));
  }, [dispatch]);

  const handlePostedStatus = () => {
    dispatch(resetPostedDetails());
  };

  const location = useLocation<Data>();
  const history = useHistory();
  if (typeof location.state === "undefined") {
    history.push("/");
  }
  const details =
    location.state && location.state.details ? location.state.details : "";

  return (
    <MainSC>
      <PageHeading title="お問合せフォーム" />
      <InquiriesNewContainerSC>
        <InquiriesNewStatusSC>
          <InquiriesNewStatusInput>
            <span>STEP.1</span>入力
          </InquiriesNewStatusInput>
          <InquiriesNewStatusComplete>
            <span>STEP.2</span>完了
          </InquiriesNewStatusComplete>
        </InquiriesNewStatusSC>
        <InquiriesDescriptionSC>
          お問合せが送信されました
        </InquiriesDescriptionSC>
        <InquiriesCompleteMessageSC>{details}</InquiriesCompleteMessageSC>
        <InquiriesTopButtonSC to="/" onClick={() => handlePostedStatus()}>
          TOPに戻る
        </InquiriesTopButtonSC>
      </InquiriesNewContainerSC>
    </MainSC>
  );
}

export default InquiresNew;

// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const InquiriesNewContainerSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  padding: 24px 16px;
  ${pc`
    padding: 30px;
  `}
`;

const InquiriesNewStatusSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const InquiriesNewStatusInput = styled.p`
  font-size: 14px;
  color: ${color.white};
  font-weight: 600;
  // width: 363px;
  margin-right: 18px;
  width: calc((100% - 50%) - 18px);
  padding: 12px;
  background-color: ${color.gray};
  border-radius: 4px;
  position: relative;
  ${pc`
    width: 43%;
    margin-right: 24px;
  `}
  span {
    font-size: 10px;
    color: ${color.gray};
    margin-right: 16px;
    padding: 4px 10px;
    background-color: ${color.white};
    border-radius: 15px;
  }
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 12px solid ${color.gray};
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
  }
`;

const InquiriesNewStatusComplete = styled.p`
  font-size: 14px;
  color: ${color.white};
  font-weight: 600;
  width: calc(100% - 50%);
  padding: 12px;
  background-color: ${color.gray};
  border-radius: 4px;
  ${pc`
  width: 43%;
`}
  span {
    font-size: 10px;
    color: ${color.gray};
    margin-right: 16px;
    padding: 4px 10px;
    background-color: ${color.white};
    border-radius: 15px;
  }
`;

const InquiriesDescriptionSC = styled.p`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    margin-bottom: 30px;
  `}
`;

const InquiriesCompleteMessageSC = styled.p`
  font-size: 14px;
  line-height: calc(21 / 14);
  margin-bottom: 24px;
  color: ${color.gray};
  ${pc`
    margin-bottom: 30px;
  `}
`;

const InquiriesTopButtonSC = styled(Link)`
  font-size: 14px;
  color: ${color.white};
  font-weight: 600;
  text-align: center;
  max-width: 200px;
  width: 100%;
  padding: 14px 43px;
  border-radius: 4px;
  background-color: ${color.inquiries_black};
  ${pc`
    max-width: 160px;
    padding: 11px 43px;
  `}
`;
