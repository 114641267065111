import { color } from "components/mixins";
import { pc } from "components/mixins/mediaQuery";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface InquiryInputRowProps {
  label: string;
  required?: boolean;
  children: ReactNode;
}

export const InquiryInputRow = (props: InquiryInputRowProps) => {
  return (
    <InputRowSC>
      <dt>
        {props.label}
        {props.required && <span>＊</span>}
      </dt>
      <dd>{props.children}</dd>
    </InputRowSC>
  );
};

const InputRowSC = styled.dl`
  ${pc`
    display: flex;
    align-items: center;
  `}
  margin-bottom: 24px;
  dt {
    font-size: 12px;
    font-weight: 600;
    max-width: 144px;
    width: 100%;
    margin-right: 50px;
    margin-bottom: 14px;
    color: ${color.gray};
    ${pc`
      margin-bottom: 0;
    `}
    span {
      color: red;
      font: 14px;
    }
  }
  dd {
    width: 100%;
  }
`;
