import React from "react";
import styled from "styled-components";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import { SortableContainer, arrayMove } from "react-sortable-hoc";
import { useHooks } from "./Hooks";
import ManualsSortableItem from "./ManualsSortableItem";
import { color } from "components/mixins";

interface Props {
  hookedManuals: ManualItemForSelector[];
  changeManualsOrder: (orderChangedManuals: ManualItemForSelector[]) => void;
}

function ManualsSortableList(props: Props) {
  const { hookedManuals, changeManualsOrder } = props;

  const onSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    changeManualsOrder(arrayMove(hookedManuals, oldIndex, newIndex));
  };

  const SortableContainerWrapper = SortableContainer(
    ({ children }: { children: any }) => {
      return <ManualsAdminListSC>{children}</ManualsAdminListSC>;
    }
  );
  return (
    <>
      <SortableContainerWrapper useDragHandle onSortEnd={onSortEnd}>
        {hookedManuals.map((manual: any, index: number) => {
          return (
            <ManualsSortableItem
              index={index}
              id={manual.id}
              title={manual.name}
            />
          );
        })}
      </SortableContainerWrapper>
    </>
  );
}

export default ManualsSortableList;

const ManualsAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;
