import { ADD_TO_BE_CREATED_TAG, CHANGE_NAME_TO_BE_CREATED_TAG, CHANGE_ORDER_TO_BE_CREATED_TAG, DELETE_TO_BE_CREATED_TAG } from './type'

export const addToBeCreated = () => {
  return {
    type: ADD_TO_BE_CREATED_TAG,
  }
}

export const deleteToBeCreated = (target_remove_index: number) => {
  return {
    type: DELETE_TO_BE_CREATED_TAG,
    target_remove_index
  }
}

export const changeNameToBeCreated = (changed_name: string, changed_target_id:number) => {
  return {
    type:CHANGE_NAME_TO_BE_CREATED_TAG,
    changed_name,
    changed_target_id
  }
}

export const changeOrderToBeCreated = (changed_order: number, changed_target_id:number) => {
  return {
    type:CHANGE_ORDER_TO_BE_CREATED_TAG,
    changed_order,
    changed_target_id
  }
}