import { CameraClass } from "components/Camera/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { GET_CAMERA_CLASSES_SUCEEDED, GET_CAMERA_CLASSES_FAILED } from "./type";

const initialState: CameraClass[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CAMERA_CLASSES_SUCEEDED:
      return [...action.classes];

    case GET_CAMERA_CLASSES_FAILED:
      console.log("message: ", action.message);
      return [...state];

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
