import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "../../mixins";
import { Page } from "../demoData";
import tag from "../../../images/icons/tag.svg";
import { pc } from "components/mixins/mediaQuery";
import { localeTimeForManual } from "components/Common/localeTimeCustomizerForManual";

const PagesItem = (props: Page) => {
  const { id, manual_id, topic_id, title, updated_at, tags } = props;
  return (
    <PageItemContainer>
      <ContentWrapper
        to={`/manual/manuals/${manual_id}/topics/${topic_id}/pages/${id}`}
      >
        <PagesItemContent>
          <PagesItemName>{title}</PagesItemName>
          <PagesItemDate>
            最終更新: {localeTimeForManual(updated_at)}
          </PagesItemDate>
        </PagesItemContent>
        <PagesItemTags>
          {tags.map(tag => (
            <PagesItemTag key={tag.id}>{tag.name}</PagesItemTag>
          ))}
        </PagesItemTags>
      </ContentWrapper>
    </PageItemContainer>
  );
};

export default PagesItem;

const PageItemContainer = styled.li`
  min-height: 75px;
  border-bottom: 1px solid ${color.list_border};
  &:hover {
    opacity: 0.6;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const ContentWrapper = styled(Link)`
  display: block;
  padding: 20px 20px 12px 20px;
`;

const PagesItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
`;

const PagesItemName = styled.dt`
  font-size: 14px;
  width: 50%;
  ${pc`
    font-size: 16px;
  `}
`;

const PagesItemDate = styled.dd`
  font-size: 12px;
  color: ${color.light_gray};
  width: 50%;
  text-align: right;
  ${pc`
    font-size: 11px;
  `}
`;

const PagesItemTags = styled.ul`
  display: flex;
  justify-content: flex-start;
`;

const PagesItemTag = styled.li`
  position: relative;
  font-size: 14px;
  color: ${color.black};
  margin-right: 14px;
  padding-left: 15px;
  &::before {
    position: absolute;
    top: 2px;
    left: 0;
    content: "";
    background: url(${tag});
    background-size: contain;
    background-position: center;
    width: 12px;
    height: 12px;
  }
`;
