import { GET_INDEXES_REQUESTED, GET_INDEX_REQUESTED } from "./types";

export const getIndexesRequest = (token: string) => {
  return {
    type: GET_INDEXES_REQUESTED,
    token,
  };
};

export const getIndexRequest = (token: string, type_id: number) => {
  return {
    type: GET_INDEX_REQUESTED,
    token,
    type_id,
  };
};
