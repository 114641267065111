import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  POST_ARTICLE_SUCCEEDED,
  POST_ARTICLE_FAILED,
  PUT_NEW_ARTICLE_SUCCEEDED,
  PUT_NEW_ARTICLE_FAILED,
  INPUT_NEW_ARTICLE_TITLE,
  TOGGLE_NEW_ARTICLE_CATEGORY_CHECK,
  TOGGLE_NEW_ARTICLE_TAG_CHECK,
  SELECT_NEW_ARTICLE_STATUS,
  SET_RAW_CONTENT,
  TOGGLE_NEW_ARTICLE_POST_STATUS,
  SET_SEARCH_STRING_ARTICLE_NEW,
} from "./type";

interface Article {
  new_article_id: number | null;
  title: string;
  content: any;
  search_string: string;
  status: number;
  categories: Category[];
  tags: Tag[];
  post_status: boolean;
  title_input_status: boolean;
}
interface Category {
  id: number;
  name: string;
}
interface Tag {
  id: number;
  name: string;
}
const initialState: Article = {
  new_article_id: null,
  title: "",
  content:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  search_string: "",
  status: 1,
  categories: [],
  tags: [],
  post_status: false,
  title_input_status: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_ARTICLE_SUCCEEDED:
      console.log("新規投稿作成に成功しました status: ", action.status);
      return {
        ...state,
        new_article_id: action.id,
      };

    case POST_ARTICLE_FAILED:
      console.log("新規投稿作成エラー エラーコード:", action.error_code);
      return {
        ...state,
      };

    case PUT_NEW_ARTICLE_SUCCEEDED:
      console.log("新規投稿登録に成功しました status: ", action.status);
      console.log("新規投稿登録に成功しました reslut: ", action.result);
      return {
        new_article_id: null,
        title: "",
        content:
          '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        search_string: "",
        status: 2,
        categories: [],
        tags: [],
        post_status: true,
      };

    case PUT_NEW_ARTICLE_FAILED:
      console.log("新規投稿登録作成エラー エラーコード:", action.error_code);
      return {
        ...state,
        id: null,
        title: "",
        content: "",
        search_string: "",
        status: 2,
        categories: [],
        tags: [],
      };

    case INPUT_NEW_ARTICLE_TITLE:
      if (action.title !== "") {
        return {
          ...state,
          title: action.title,
          title_input_status: true,
        };
      } else {
        return {
          ...state,
          title: action.title,
          title_input_status: false,
        };
      }

    case TOGGLE_NEW_ARTICLE_CATEGORY_CHECK:
      // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
      if (
        state.categories.length > 0 &&
        state.categories.some((category: any) => category.id === action.id)
      ) {
        return {
          ...state,
          categories: state.categories.filter(
            (category: any) => category.id !== action.id
          ),
        };
      } else {
        return {
          ...state,
          categories: [...state.categories, action.category],
        };
      }

    case TOGGLE_NEW_ARTICLE_TAG_CHECK:
      // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
      if (
        state.tags.length > 0 &&
        state.tags.some((tag: any) => tag.id === action.id)
      ) {
        return {
          ...state,
          tags: state.tags.filter((tag: any) => tag.id !== action.id),
        };
      } else {
        return {
          ...state,
          tags: [...state.tags, action.tag],
        };
      }

    case SELECT_NEW_ARTICLE_STATUS:
      return {
        ...state,
        status: action.id,
      };

    case SET_RAW_CONTENT:
      return {
        ...state,
        content: action.content,
      };

    case TOGGLE_NEW_ARTICLE_POST_STATUS:
      return {
        ...state,
        post_status: action.post_status,
      };

    case SET_SEARCH_STRING_ARTICLE_NEW:
      return {
        ...state,
        search_string: action.search_string,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
