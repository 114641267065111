import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "../../mixins";
import { pc } from "components/mixins/mediaQuery";
import { localeTimeForManual } from "components/Common/localeTimeCustomizerForManual";

interface Props {
  manual_id: number;
  name: string;
  updated_at: string;
}

const ManualsItem = (props: Props) => {
  const { manual_id, name, updated_at } = props;
  return (
    <Container>
      <ManualsItemSC to={`/manual/manuals/${manual_id}`}>
        <ManualsItemContent>
          <ManualsItemName>{name}</ManualsItemName>
          <ManualsItemDate>
            最終更新:{localeTimeForManual(updated_at)}
          </ManualsItemDate>
        </ManualsItemContent>
      </ManualsItemSC>
    </Container>
  );
};

export default ManualsItem;

const Container = styled.li`
  border-bottom: 1px solid ${color.list_border};
`;

const ManualsItemSC = styled(Link)`
  display: block;
  padding: 14px 14px 14px 17px;
  &:hover {
    opacity: 0.6;
  }
  ${pc`
    padding: 22px 22px 22px 55px;
  `}
`;

const ManualsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  ${pc`
    flex-direction: row;
    align-items: center;
  `}
`;

const ManualsItemName = styled.dt`
  font-size: 16px;
  order: 2;
  ${pc`
    order: 1;
    margin-botton: 0;
  `}
`;

const ManualsItemDate = styled.dd`
  font-size: 12px;
  color: ${color.light_gray};
  order: 1;
  margin-bottom: 6px;
  ${pc`
    order: 2;
    margin-bottom: 0px;
  `}
`;
