import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";
import LocalTimeCustomizerForEvents from "../../Common/LocaleTimeCustomizerForEvents";

function EventsTopItem(props: any) {
  const year = useSelector((state: any) => state.events_top.current_year);
  const filtered_month_top = useSelector(
    (state: any) => state.event_search.filtered_month_top
  );
  let month = filtered_month_top;
  // 1~9月は0を先頭につける
  if (month < 10) {
    month = "0" + month;
  }
  const agricultural_plans = useSelector(
    (state: any) => state.monthly_events.agricultural_plans
  );
  const target_agricultural_plan = agricultural_plans.find(
    (agricultural_plan: any) =>
      agricultural_plan.date ===
      `${month > 3 ? year.toString() : (year + 1).toString()}-${month}`
  );
  return (
    <>
      {target_agricultural_plan ? (
        <EventsTopItemSC>
          <EventsTopItemContent className="date"></EventsTopItemContent>
          <EventsTopItemContent className="title">
            {target_agricultural_plan.type.name}
          </EventsTopItemContent>
          <EventsTopItemContent className="content">
            {target_agricultural_plan.content}
          </EventsTopItemContent>
          <EventsTopItemContent className="detail">
            {target_agricultural_plan.detail}
          </EventsTopItemContent>
          <EventsTopItemContent className="class_age">
            {target_agricultural_plan.class}
          </EventsTopItemContent>
        </EventsTopItemSC>
      ) : (
        <EventsTopItemSC>
          <EventsTopItemContent className="date"></EventsTopItemContent>
          <EventsTopItemContent className="title">
            農業計画
          </EventsTopItemContent>
          <EventsTopItemContent className="content"></EventsTopItemContent>
          <EventsTopItemContent className="detail"></EventsTopItemContent>
          <EventsTopItemContent className="class_age"></EventsTopItemContent>
        </EventsTopItemSC>
      )}
    </>
  );
}

export default EventsTopItem;

const EventsTopItemSC = styled.li`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 25px 59px 21px;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
`;

const EventsTopItemContent = styled.div`
  font-size: 14px;
  color: #444;
  &.date {
    width: calc(14%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
`;
