import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  deleteRejectApi,
  postRejectApi,
  postRejectYearMonthlyApi
} from "../api/event/rejectApi";
import {
  POST_REJECT_REQUESTED,
  POST_REJECT_SUCCEEDED,
  POST_REJECT_FAILED,
  DELETE_REJECT_REQUESTED,
  DELETE_REJECT_SUCCEEDED,
  POST_REJECT_YEAR_MONTHLY_REQUESTED,
  POST_REJECT_YEAR_MONTHLY_SUCCEEDED,
  RESET_SELECTED_OPTOIONS,
  RESET_TO_BE_APPROVED,
  GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED
} from "../store/event/approval/type";
import {
  GET_EVENTS_LIST_REQUESTED,
  GET_EVENTS_LIST_SUCCEEDED
} from "../store/event/events/type";
import {
  TOGGLE_DELETE_RETURN_EVENT_MODAL,
  TOGGLE_RETURN_EVENT_MODAL,
  TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL
} from "../store/common/modal/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { getEventsAfterSaveApi } from "../api/event/eventApi";
import { getApprovalsAfterPostApi } from "../api/event/approvalsApi";

function* postReject(action: any) {
  const token = action.token;
  const event_id = action.event_id;
  const comment = action.comment;
  const facility_id = action.facility_id;
  const year = action.year;
  const month: [] = [];
  const classes: [] = [];
  const remand = "";
  try {
    const result = yield call(postRejectApi, token, event_id, comment);
    console.log("result", result);
    yield all([
      put({ type: POST_REJECT_SUCCEEDED, result: result }),
      put({ type: TOGGLE_RETURN_EVENT_MODAL, result: false }),
      put({
        type: GET_EVENTS_LIST_REQUESTED,
        token,
        facility_id,
        filtered_year: year,
        filtered_months: month,
        filtered_classes: classes,
        filtered_remand: remand
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* deleteReject(action: any) {
  const token = action.token;
  const facility_id = action.facility_id;
  const reject_id = action.reject_id;
  const year = action.year;
  const month = action.month;
  const classes = action.classes;
  const remand = "";
  try {
    const result = yield call(deleteRejectApi, token, reject_id);
    console.log("result", result);
    yield all([
      put({ type: DELETE_REJECT_SUCCEEDED, result: result }),
      put({
        type: TOGGLE_DELETE_RETURN_EVENT_MODAL,
        delete_return_event_modal_open: false
      }),
      put({
        type: GET_EVENTS_LIST_REQUESTED,
        token,
        facility_id,
        year,
        month,
        classes,
        remand
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

// 年間一括差し戻し追加
function* postRejectYearMonthly(action: any) {
  try {
    const post_result = yield* postRejectYearMonthlyApi(
      action.token,
      action.facility_id,
      action.year_months
    );
    const approvals_after_post = yield* getApprovalsAfterPostApi(
      action.token,
      action.facility_id,
      action.filtered_year
    );
    const event_after_apply = yield* getEventsAfterSaveApi(
      action.token,
      action.facility_id,
      action.filtered_year,
      action.filtered_months
    );
    console.log("filtered_month------->", action.filtered_month);
    console.log("post_result", post_result);

    yield all([
      put({
        type: POST_REJECT_YEAR_MONTHLY_SUCCEEDED,
        result: post_result.data
      }),
      put({
        type: GET_MONTHLY_APPROVAL_STATUS_SUCCEEDED,
        result: approvals_after_post.data
      }),
      put({
        type: GET_EVENTS_LIST_SUCCEEDED,
        events: event_after_apply.data.events
      }),
      put({ type: RESET_SELECTED_OPTOIONS }),
      put({ type: RESET_TO_BE_APPROVED }),
      put({
        type: TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL,
        cancel_year_monthly_events_modal_open: false
      })
      // put({
      //   type: GET_EVENTS_LIST_REQUESTED,
      //   token,
      //   facility_id,
      //   filtered_year: year,
      //   filtered_months: month,
      //   filtered_classes: classes,
      //   filtered_remand: remand
      // })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const rejectSaga = [
  takeLatest(POST_REJECT_REQUESTED, postReject),
  takeLatest(DELETE_REJECT_REQUESTED, deleteReject),
  takeLatest(POST_REJECT_YEAR_MONTHLY_REQUESTED, postRejectYearMonthly)
];

export default rejectSaga;
