import React from 'react'
import styled from 'styled-components';
import ServiceList from './ServiceList'

export default function ServiceListBlock() {
  return (
    <ServiceListBlockSC>
      <ServiceList />
    </ServiceListBlockSC>
  )
}

// ===================================================================================
// スタイル
// ===================================================================================
const ServiceListBlockSC = styled.div`
  margin: 0 30px;
  padding-top: 78px;
  height: 600px;
  overflow: scroll;
  overflow: scroll;
  ::-webkit-scrollbar {
    display:none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;