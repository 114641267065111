const TOGGLE_FACILITY_MODAL = "TOGGLE_FACILITY_MODAL";
const TOGGLE_SERVICE_MODAL = "TOGGLE_SERVICE_MODAL";
const TOGGLE_DELETE_EVENT_MODAL = "TOGGLE_DELETE_EVENT_MODAL";
const TOGGLE_DELETE_ARTICLE_MODAL = "TOGGLE_DELETE_ARTICLE_MODAL";
const TOGGLE_DELETE_NEWS_MODAL = "TOGGLE_DELETE_NEWS_MODAL";
const TOGGLE_ADD_EVENT_MODAL = "TOGGLE_ADD_EVENT_MODAL";
const TOGGLE_EDIT_EVENT_MODAL = "TOGGLE_EDIT_EVENT_MODAL";
const TOGGLE_DELETE_MONTHLY_EVENT_MODAL = "TOGGLE_DELETE_MONTHLY_EVENT_MODAL";
const TOGGLE_ADD_MONTHLY_EVENT_MODAL = "TOGGLE_ADD_MONTHLY_EVENT_MODAL";
const TOGGLE_EDIT_MONTHLY_EVENT_MODAL = "TOGGLE_EDIT_MONTHLY_EVENT_MODAL";
const TOGGLE_CSV_MODAL = "TOGGLE_CSV_MODAL";
const TOGGLE_AUTHORITY_ERROR_MODAL = "TOGGLE_AUTHORITY_ERROR_MODAL";
const TOGGLE_APPROVAL_MODAL = "TOGGLE_APPROVAL_MODAL";
const TOGGLE_ALREADY_APPROVED_MODAL = "TOGGLE_ALREADY_APPROVED_MODAL";
const TOGGLE_MONTHLY_APPROVAL_MODAL = "TOGGLE_MONTHLY_APPROVAL_MODAL";
const TOGGLE_RETURN_EVENT_MODAL = "TOGGLE_RETURN_EVENT_MODAL";
const TOGGLE_DELETE_RETURN_EVENT_MODAL = "TOGGLE_DELETE_RETURN_EVENT_MODAL";
const TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL = "TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL";
const TOGGLE_CSV_DOWNLOAD_REJECT_MODAL = "TOGGLE_CSV_DOWNLOAD_REJECT_MODAL";
const TOGGLE_APPROVAL_REQUEST_MODAL = "TOGGLE_APPROVAL_REQUEST_MODAL";
const TOGGLE_CANCEL_ALL_EVENTS_MODAL = "TOGGLE_CANCEL_ALL_EVENTS_MODAL";
const TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL =
  "TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL";

export {
  TOGGLE_FACILITY_MODAL,
  TOGGLE_SERVICE_MODAL,
  TOGGLE_DELETE_EVENT_MODAL,
  TOGGLE_ADD_EVENT_MODAL,
  TOGGLE_EDIT_EVENT_MODAL,
  TOGGLE_DELETE_MONTHLY_EVENT_MODAL,
  TOGGLE_ADD_MONTHLY_EVENT_MODAL,
  TOGGLE_EDIT_MONTHLY_EVENT_MODAL,
  TOGGLE_CSV_MODAL,
  TOGGLE_AUTHORITY_ERROR_MODAL,
  TOGGLE_MONTHLY_APPROVAL_MODAL,
  TOGGLE_APPROVAL_MODAL,
  TOGGLE_ALREADY_APPROVED_MODAL,
  TOGGLE_RETURN_EVENT_MODAL,
  TOGGLE_DELETE_RETURN_EVENT_MODAL,
  TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL,
  TOGGLE_CSV_DOWNLOAD_REJECT_MODAL,
  TOGGLE_APPROVAL_REQUEST_MODAL,
  TOGGLE_CANCEL_ALL_EVENTS_MODAL,
  TOGGLE_DELETE_ARTICLE_MODAL,
  TOGGLE_DELETE_NEWS_MODAL,
  TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL
};
