import styled from "styled-components";
import { Link } from "react-router-dom";
import back from "components/Camera/images/icon-back.svg";

export const PhotoDetailImgContainer = styled.div`
  text-align: center;
  margin: 25px 0;
`;

export const PhotoDetailImg = styled.img`
  max-width: 350px;
  width: 100%;
  border-radius: 20px;
`;

export const BackToIndexSC = styled.span`
  padding: 12px;
  display: inline-block;
  padding-right: 20px;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  &::before {
    content: "<<";
    display: inline-block;
    margin-right: 8px;
    position: relative;
    top: -1px;
  }
`;
