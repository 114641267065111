import {
  ADD_TO_BE_CREATED,
  CHANGE_NAME_TO_BE_CREATED,
  CHANGE_ORDER_TO_BE_CREATED,
  DELETE_TO_BE_CREATED,
} from "./type";
import {
  POST_NEW_CATEGORY_ITEM_SUCCEEDED,
  POST_NEW_CATEGORY_ITEM_FAILED,
} from "../categories/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";

interface CategoriesToBeCreated {
  temp_id: number;
  name: string;
  order: number;
}

const initialState: CategoriesToBeCreated[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    //新規入力欄作成
    case ADD_TO_BE_CREATED:
      if (state === null) {
        return [{ temp_id: 0, name: "", order: 0 }];
      } else if (state.length === 0) {
        return [...state, { temp_id: 0, name: "", order: 0 }];
      } else {
        return [
          ...state,
          { temp_id: state[state.length - 1].temp_id + 1, name: "", order: 0 },
        ];
      }

    // 新規入力欄削除
    case DELETE_TO_BE_CREATED:
      const deleted_to_be_created = state.filter((item: any, index: number) => {
        return state[index] !== state[action.target_remove_index];
      });
      return deleted_to_be_created;

    // 新規入力欄名前変更
    case CHANGE_NAME_TO_BE_CREATED:
      const changedNameItemIndex = state.findIndex(
        (changed_item: any) =>
          changed_item.temp_id === Number(action.changed_target_id)
      );
      const changedNameArray = [...state];
      changedNameArray[changedNameItemIndex].name = action.changed_name;
      return [...changedNameArray];

    //新規入力欄順番変更
    case CHANGE_ORDER_TO_BE_CREATED:
      const changedOrderItemIndex = state.findIndex(
        (changed_item: any) =>
          changed_item.temp_id === Number(action.changed_target_id)
      );
      const changedOrderArray = [...state];
      changedOrderArray[changedOrderItemIndex].order = action.changed_order;
      return [...changedOrderArray];

    case POST_NEW_CATEGORY_ITEM_SUCCEEDED:
      const newArray = state.filter(
        (item: any) => item.temp_id !== action.temp_id
      );
      return newArray;

    case POST_NEW_CATEGORY_ITEM_FAILED:
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
    // }
  }
};

export default reducer;
