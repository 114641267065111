const POST_ARTICLE_REQUESTED = "POST_ARTICLE_REQUESTED";
const POST_ARTICLE_SUCCEEDED = "POST_ARTICLE_SUCCEEDED";
const POST_ARTICLE_FAILED = "POST_ARTICLE_FAILED";

const PUT_NEW_ARTICLE_REQUESTED = "PUT_NEW_ARTICLE_REQUESTED";
const PUT_NEW_ARTICLE_SUCCEEDED = "PUT_NEW_ARTICLE_SUCCEEDED";
const PUT_NEW_ARTICLE_FAILED = "PUT_NEW_ARTICLE_FAILED";

const PUT_EDIT_ARTICLE_REQUESTED = "PUT_EDIT_ARTICLE_REQUESTED";
const PUT_EDIT_ARTICLE_SUCCEEDED = "PUT_EDIT_ARTICLE_SUCCEEDED";
const PUT_EDIT_ARTICLE_FAILED = "PUT_EDIT_ARTICLE_FAILED";

const GET_ARTICLE_REQUESTED = "GET_ARTICLE_REQUESTED";
const GET_ARTICLE_SUCCEEDED = "GET_ARTICLE_SUCCEEDED";
const GET_ARTICLE_FAILED = "GET_ARTICLE_FAILED";

const UPDATE_ARTICLE_REQUESTED = "UPDATE_ARTICLE_REQUESTED";
const UPDATE_ARTICLE_SUCCEEDED = "UPDATE_ARTICLE_SUCCEEDED";
const UPDATE_ARTICLE_FAILED = "UPDATE_ARTICLE_FAILED";

const READ_ARTICLE_REQUESTED = "READ_ARTICLE_REQUESTED";
const READ_ARTICLE_SUCCEEDED = "READ_ARTICLE_SUCCEEDED";
const READ_ARTICLE_FAILED = "READ_ARTICLE_FAILED";

const STOCK_ARTICLE_REQUESTED = "STOCK_ARTICLE_REQUESTED";
const STOCK_ARTICLE_SUCCEEDED = "STOCK_ARTICLE_SUCCEEDED";
const STOCK_ARTICLE_FAILED = "STOCK_ARTICLE_FAILED";


const DELETE_STOCKED_ARTICLE_REQUESTED = "DELETE_STOCKED_ARTICLE_REQUESTED";
const DELETE_STOCKED_ARTICLE_SUCCEEDED = "DELETE_STOCKED_ARTICLE_SUCCEEDED";
const DELETE_STOCKED_ARTICLE_FAILED = "DELETE_STOCKED_ARTICLE_FAILED";

const POST_ARTICLE_IMAGE_REQUESTED = "POST_ARTICLE_IMAGE_REQUESTED";
const POST_ARTICLE_IMAGE_SUCCEEDED = "POST_ARTICLE_IMAGE_SUCCEEDED";
const POST_ARTICLE_IMAGE_FAILED = "POST_ARTICLE_IMAGE_FAILED";


const TOGGLE_NEW_ARTICLE_CATEGORY_CHECK = "TOGGLE_NEW_ARTICLE_CATEGORY_CHECK";
const TOGGLE_NEW_ARTICLE_TAG_CHECK = "TOGGLE_NEW_ARTICLE_TAG_CHECK";
const INPUT_NEW_ARTICLE_TITLE = "INPUT_NEW_ARTICLE_TITLE";
const SELECT_NEW_ARTICLE_STATUS = "SELECT_NEW_ARTICLE_STATUS";
const SET_RAW_CONTENT = "SET_RAW_CONTENT";

const TOGGLE_NEW_ARTICLE_POST_STATUS = "TOGGLE_NEW_ARTICLE_POST_STATUS";

const SET_SEARCH_STRING_ARTICLE_NEW = "SET_SEARCH_STRING_ARTICLE_NEW";

export {

  POST_ARTICLE_REQUESTED,
  POST_ARTICLE_SUCCEEDED,
  POST_ARTICLE_FAILED,

  PUT_NEW_ARTICLE_REQUESTED,
  PUT_NEW_ARTICLE_SUCCEEDED,
  PUT_NEW_ARTICLE_FAILED,

  PUT_EDIT_ARTICLE_REQUESTED,
  PUT_EDIT_ARTICLE_SUCCEEDED,
  PUT_EDIT_ARTICLE_FAILED,

  GET_ARTICLE_REQUESTED,
  GET_ARTICLE_SUCCEEDED,
  GET_ARTICLE_FAILED,

  UPDATE_ARTICLE_REQUESTED,
  UPDATE_ARTICLE_SUCCEEDED,
  UPDATE_ARTICLE_FAILED,

  READ_ARTICLE_REQUESTED,
  READ_ARTICLE_SUCCEEDED,
  READ_ARTICLE_FAILED,

  STOCK_ARTICLE_REQUESTED,
  STOCK_ARTICLE_SUCCEEDED,
  STOCK_ARTICLE_FAILED,

  DELETE_STOCKED_ARTICLE_REQUESTED,
  DELETE_STOCKED_ARTICLE_SUCCEEDED,
  DELETE_STOCKED_ARTICLE_FAILED,

  POST_ARTICLE_IMAGE_REQUESTED,
  POST_ARTICLE_IMAGE_SUCCEEDED,
  POST_ARTICLE_IMAGE_FAILED,

  TOGGLE_NEW_ARTICLE_CATEGORY_CHECK,

  TOGGLE_NEW_ARTICLE_TAG_CHECK,

  INPUT_NEW_ARTICLE_TITLE,

  SELECT_NEW_ARTICLE_STATUS,

  SET_RAW_CONTENT,

  TOGGLE_NEW_ARTICLE_POST_STATUS,

  SET_SEARCH_STRING_ARTICLE_NEW

}
