import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from "react-router-dom"
import { pc } from "../../mixins/mediaQuery";
import NewsList from './NewsList';
import NewsListForUser from './NewsListForUser';
import { containerBaseStyle } from '../../mixins'
import NewsPagination from './NewsPagination'

function NewsListBlock() {
  const location = useLocation();
  const pathName = location.pathname;
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  return (
    <NewsListBlockSC>
      <NewsPagination />
      {
        pathName === "/news" &&
        <NewsListForUser />
      }
      {
        pathName === "/news-edit-list" &&
        <>
          {
            (!isAdmin && !isEditor) ?
            <NewsListForUser /> :
            <NewsList />
          }
        </>
      }
      <NewsPagination position="bottom"/>
    </NewsListBlockSC>
  )
}

export default NewsListBlock

// ===================================================================================
// スタイル
// ===================================================================================
const NewsListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  ${pc`
    max-width: 810px;
    // width: 65.5%;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 30px;
  `}
`;
