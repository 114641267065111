import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { manualManualsSelector } from "store/manual/manuals/selectors";
export const useHooks = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const manuals = useSelector(manualManualsSelector);

  const [hookedManuals, setHookedManuals] = useState(manuals ? manuals : []);

  useEffect(() => {
    setHookedManuals(manuals);
  }, [manuals]);

  return {
    token,
    dispatch,
    hookedManuals
  };
};
