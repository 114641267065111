import React from "react";
import { FlexPageLayout } from "../../Parts/MainLayout";
import ModuleArea from "../../Parts/ModuleArea";
import UpdateHistoriesModule from "../../UpdateHistoriesModule";
import UpdateHistoryBlock from "./UpdateHistoriesBlock";
import FixedActionButton from "../../Parts/FixedActionButton";
import { useHooks } from "./Hooks";

const UpdateHistories: React.FC = () => {
  const { histories, toggleHistoryDisplay, handleUpdateBtnClick } = useHooks();
  return (
    <FlexPageLayout pageTitle="更新履歴">
      <UpdateHistoryBlock
        data={histories}
        toggleCheckbox={toggleHistoryDisplay}
      />
      <ModuleArea>
        <UpdateHistoriesModule data={histories} />
      </ModuleArea>
      <FixedActionButton
        buttonText="更新"
        buttonClick={handleUpdateBtnClick}
        description=""
      />
    </FlexPageLayout>
  );
};

export default UpdateHistories;
