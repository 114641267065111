import { GET_INDEXES_SUCCEEDED, GET_INDEX_SUCCEEDED } from "./types";
import { IndexesState } from "./type.d";

const initialState: IndexesState = {
  indexes: [],
  index: {
    id: 0,
    name: "",
    topics: []
  }
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INDEXES_SUCCEEDED:
      return {
        ...state,
        indexes: action.indexes.data.indexes
      };
    case GET_INDEX_SUCCEEDED:
      console.log(
        "action.result GET_INDEX_SUCCEEDED",
        action.result.data.indexes
      );
      return {
        ...state,
        index: action.result.data.indexes
      };
    default:
      return state;
  }
};

export default reducer;
