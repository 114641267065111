import axios from "axios";
import { env_v2_url, env_url } from "../../serverEnv";

// const getFacilitiesApi = (token: string) => {
//   console.log('token:', token)
//   return axios.get(
//     `https://test.gowest.techbeans.jp/api/v1/facilities`,
//     {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     }
//   );
// };

function* getFacilitiesApi(token: string) {
  const result = yield axios.get(`${env_v2_url}/facilities`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result;
}

export { getFacilitiesApi };
