import React, { ChangeEvent } from "react";
import { ButtonSC } from "../Button/style";
import {
  ModalSettingBackgroundSC,
  ModalSettingButtonSC,
  ModalSettingDdSC,
  ModalSettingSelectDdSC,
  ModalSettingDisplayCanselSC,
  ModalSettingDisplaySC,
  ModalSettingDlSC,
  ModalSettingDtSC,
  ModalSettingListSC,
  ModalSettingSC,
  ModalSettingTitleSC,
  ModalSettingWarningIconSC,
  ModalSettingWarningTextSC
} from "./style";

import warningIcon from "./img/warning_icon.svg";
import { Checkbox } from "../Checkbox";
import { RadioButton } from "../Radiobutton";
import {
  CameraClass,
  CameraTag,
  CameraFacility,
  isMultiValue
} from "components/Camera/type";
import { MultiSelectBox } from "../SelectBox";

interface ModalSettingProps {
  open: boolean;
  classes: CameraClass[];
  tags: CameraTag[];
  facilities: CameraFacility[];
  isShareBan: boolean;
  toggleVisible: () => void;
  onClickUpdateBtnFunc: (targets: string[]) => void;
  onChangeFacilityFunc: (v: isMultiValue) => void;
  onChangeTagFunc: (id: number) => void;
  onChangeClassFunc: (id: number) => void;
  onChangeShareBanFunc: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ModalSetting: React.FC<ModalSettingProps> = props => {
  const {
    onClickUpdateBtnFunc,
    onChangeFacilityFunc,
    onChangeTagFunc,
    onChangeClassFunc,
    onChangeShareBanFunc,
    toggleVisible,
    open,
    classes,
    tags,
    facilities,
    isShareBan
  } = props;

  const [targets, setTargets] = React.useState<string[]>([]);
  const handleSetTargets = (target: string) => {
    if (targets.includes(target)) {
      setTargets(targets.filter(t => t !== target));
    } else {
      setTargets([...targets, target]);
    }
  };
  const facilitiesOption = facilities.map(f => {
    return { label: f.name, value: f.id.toString() };
  });
  return (
    <>
      <ModalSettingBackgroundSC
        onClick={toggleVisible}
        style={{ display: open ? "block" : "none" }}
      ></ModalSettingBackgroundSC>

      <ModalSettingSC>
        <ModalSettingDisplaySC style={{ display: open ? "block" : "none" }}>
          <ModalSettingDisplayCanselSC onClick={toggleVisible} />
          <ModalSettingTitleSC>選択した写真を一括で設定</ModalSettingTitleSC>

          <ModalSettingWarningTextSC>
            <ModalSettingWarningIconSC src={warningIcon} />
            ※更新された項目は全て上書きになります。
          </ModalSettingWarningTextSC>

          <ModalSettingListSC>
            <ModalSettingDlSC>
              <ModalSettingDtSC>
                <Checkbox
                  id="1"
                  onChange={() => handleSetTargets("facility")}
                  label="共有施設"
                />
              </ModalSettingDtSC>
              <ModalSettingSelectDdSC>
                <MultiSelectBox
                  options={facilitiesOption}
                  onSelectValue={value => onChangeFacilityFunc(value)}
                />
              </ModalSettingSelectDdSC>
            </ModalSettingDlSC>

            <ModalSettingDlSC>
              <ModalSettingDtSC>
                <Checkbox
                  id="tag"
                  onChange={() => handleSetTargets("tag")}
                  label="設定タグ"
                />
              </ModalSettingDtSC>
              <ModalSettingDdSC>
                {tags.map(tag => (
                  <Checkbox
                    id={`setting-tag-${tag.id}`}
                    onChange={() => onChangeTagFunc(tag.id)}
                    label={tag.name}
                  />
                ))}
              </ModalSettingDdSC>
            </ModalSettingDlSC>

            <ModalSettingDlSC>
              <ModalSettingDtSC>
                <Checkbox
                  id="class"
                  onChange={() => handleSetTargets("class")}
                  label="設定クラス"
                />
              </ModalSettingDtSC>

              <ModalSettingDdSC>
                {classes.map(classroom => (
                  <Checkbox
                    id={`setting-class-${classroom.id}`}
                    onChange={() => onChangeClassFunc(classroom.id)}
                    label={`${classroom.name}歳児`}
                  />
                ))}
              </ModalSettingDdSC>
            </ModalSettingDlSC>

            {/* <ModalSettingDlSC>
              <ModalSettingDtSC>
                <Checkbox
                  id="4"
                  onChange={() => console.log("")}
                  label="公開施設"
                />
              </ModalSettingDtSC>

              <ModalSettingDdSC>
                <RadioButton
                  label="公開"
                  name="publish_type_setting"
                  value="published"
                  onChange={onChangeShareBanFunc}
                  id="published-setting"
                  checked={!isShareBan}
                />

                <RadioButton
                  label="非公開"
                  name="publish_type_Setting"
                  value="private"
                  onChange={onChangeShareBanFunc}
                  id="private-setting"
                  checked={isShareBan}
                />
              </ModalSettingDdSC>
            </ModalSettingDlSC> */}
          </ModalSettingListSC>

          <ModalSettingButtonSC>
            <ButtonSC
              style={{
                width: "160px",
                height: "38px",
                padding: "9px 45px",
                textAlign: "center"
              }}
              className="default"
              onClick={() => onClickUpdateBtnFunc(targets)}
            >
              更新
            </ButtonSC>
          </ModalSettingButtonSC>
        </ModalSettingDisplaySC>
      </ModalSettingSC>
    </>
  );
};
