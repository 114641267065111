import { 
  GET_NEWS_EDIT_REQUESTED,
  PUT_NEWS_EDIT_REQUESTED,
  INPUT_EDIT_NEWS_TITLE,
  SELECT_EDIT_NEWS_STATUS,
  SET_NEWS_RAW_EDIT_CONTENT,
  START_NEWS_EDIT,
  SET_NEWS_EDITING_STATUS,
  TOGGLE_EDIT_NEWS_POST_STATUS,
  SET_SEARCH_STRING_NEWS_EDIT,
  TOGGLE_NEWS_EDIT_CONTENT_LOADED,
  DELETE_NEWS_REQUESTED,
  TOGGLE_NEWS_IS_EDITING,
  RESET_PREVIOUS_PUBLISH_STATUS,
  SET_NEWS_EDITOR_IS_TOGGLED,
  SAVE_TEMP_NEWS_EDIT_DATA,
  RESET_TEMP_NEWS_EDIT_DATA,
  TOGGLE_NEWS_TITLE_IS_EDITED
} from "./type";
// =====================================TOGGLE_CHECKED_ARTICLE_CATEGORY============================ //
// Actions
// ----------------------------------------------------------------- //

// 記事情報取得
export const getNewsEditRequest = (token: string, id: number, stocks: any) => {
  return {
    type: GET_NEWS_EDIT_REQUESTED,
    token,
    id,
    stocks
  };
};

// おしらせ更新
export const putNewsEditRequest = (id: number, token: string, title: string, content: any, status: number, search_string: string) => {
  return {
    type: PUT_NEWS_EDIT_REQUESTED,
    id,
    token,
    title,
    content,
    status,
    search_string
  };
};

//記事情報削除
export const deleteNewsRequest = (token: string, id: number) => {
  return {
    type: DELETE_NEWS_REQUESTED,
    token,
    id
  }
}



export const inputEditNewsTitle = (title: string) => {
  return {
    type: INPUT_EDIT_NEWS_TITLE,
    title
  };
};

export const selectEditNewsStatus = (id: number, publishedStatus:number) => {
  return {
    type: SELECT_EDIT_NEWS_STATUS,
    id,
    publishedStatus
  };
};

export const setNewsRawEditContent = (content: any) => {
  return {
    type: SET_NEWS_RAW_EDIT_CONTENT,
    content
  }
}

export const startNewsEdit = (id: number, title: string, content: any, status: number, editing_status: any) => {
  return {
    type: START_NEWS_EDIT,
    id,
    title,
    content,
    status,
    editing_status
  }
}

export const setNewsEditingStatus = (editing_status: string) => {
  return {
    type: SET_NEWS_EDITING_STATUS,
    editing_status
  }
}

export const toggleNewsEditPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_EDIT_NEWS_POST_STATUS,
    post_status
  }
}

export const setSearchStringNewsEdit = (search_string: string) => {
  return {
    type: SET_SEARCH_STRING_NEWS_EDIT,
    search_string
  }
}

export const toggleNewsEditContentLoaded = (contentLoaded: boolean) => {
  return {
    type: TOGGLE_NEWS_EDIT_CONTENT_LOADED,
    contentLoaded
  };
};

// 現在その記事が編集中かどうか判断する
export const toggleNewsIsEditing = (newsIsEditing: boolean) => {
  return {
    type: TOGGLE_NEWS_IS_EDITING,
    newsIsEditing,
  };
};

export const toggleNewsTitleIsEdited = (newsTitleIsEdited: boolean, current_value: string) => {
  return {
    type: TOGGLE_NEWS_TITLE_IS_EDITED,
    newsTitleIsEdited,
    current_value
  }
}

export const resetPreviousPublishStatus = () => {
  return {
    type: RESET_PREVIOUS_PUBLISH_STATUS,
  }
}

export const saveTempNewsEditData = (news_edit: object, current_news_status?: number) => {
  return {
    type: SAVE_TEMP_NEWS_EDIT_DATA,
    news_edit,
    current_news_status
  }
}
export const resetTempNewsEditData = () => {
  return {
    type: RESET_TEMP_NEWS_EDIT_DATA,
  }
}

export const setNewsEditorIsToggled = (editorIsToggled: boolean) => {
  return {
    type: SET_NEWS_EDITOR_IS_TOGGLED,
    editorIsToggled
  }
}


