import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { historiesSelector } from "store/manual/histories/selectors";
import {
  manualManualsSelector,
  manualManualDetailSelector
} from "store/manual/manuals/selectors";
import { manualTopicsSelector } from "store/manual/manuals/topics/selectors";
import {
  updateManualWithImageRequest,
  getManualRequest,
  updateManualTitleDesc,
  manualSetWithoutRequest
} from "store/manual/manuals/actions";
import { useParams } from "react-router-dom";
import { manualTagsSelector } from "store/manual/tags/selector";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import {
  getTopicsRequest,
  updateAllTopicsRequest
} from "store/manual/manuals/topics/actions";
import { ManualItemForSelector } from "store/manual/manuals/types.d";

export const useHooks = () => {
  const [editTitleMode, setEditTitleMode] = useState<boolean>(false);
  const [editOrderMode, setEditOrderMode] = useState<boolean>(false);

  const topics = useSelector(manualTopicsSelector);
  const [hookedTopics, setHookedTopics] = useState<TopicItem[]>([]);

  React.useEffect(() => {
    if (topics) setHookedTopics(topics);
  }, [topics]);
  const [toBeDeletedTopics, setToBeDeletedTopics] = useState<TopicItem[]>([]);

  // 編集中、「キャンセル」押下で編集破棄
  const cancelEdit = () => {
    setHookedTopics(topics);
  };

  const dispatch = useDispatch();
  const histories = useSelector(historiesSelector);
  const tags = useSelector(manualTagsSelector);

  const token = useSelector((state: any) => state.session.token);
  const { manualId } = useParams<{ manualId: string }>();
  const manuals = useSelector(manualManualsSelector);

  // マニュアル詳細情報をストアから取得
  const stored_manual = useSelector(manualManualDetailSelector);
  console.log("stored_manual", stored_manual);
  const manualName = stored_manual.name ? stored_manual.name : "";
  const empty_manual = {
    id: null,
    name: "",
    catch: "",
    description: "",
    order: 1,
    updated_at: "",
    image: "",
    history_flag: false
  };
  const [hookedManual, setHookedManual] = useState(
    stored_manual ? stored_manual : empty_manual
  );

  // URLと合致するマニュアル詳細情報を取得
  React.useEffect(() => {
    console.log("in useEffect");
    // ストア内にURLと合致するマニュアル情報があり、かつストア内の設定中マニュアル詳細idとURLが合致する場合はdispatchしない
    if (
      hookedManual.id && // マニュアル詳細データのidがある
      manuals && //マニュアル一覧が取得済み
      manuals.find((manual: any) => manual.id === Number(manualId)) && // マニュアル一覧内にURLのmanualIdと等しいマニュアルがある
      Number(manualId) === hookedManual.id // ストア内のマニュアル詳細データがURLのmanualIdと等しい
    ) {
      dispatch(manualSetWithoutRequest(Number(manualId)));
      console.log("No Need for getManualRequest");
      return;
    }
    dispatch(getManualRequest({ token, id: Number(manualId) }));
  }, [dispatch, token, manualId, manuals, hookedManual]);

  React.useEffect(() => {
    if (stored_manual) setHookedManual(stored_manual);
  }, [stored_manual]);

  // 画像編集モード切り替え
  const [editImageMode, setEditImageMode] = useState<boolean>(false);

  // 画像編集中、「キャンセル」押下で編集破棄
  const cancelEditImage = () => {
    setHookedManual(stored_manual);
  };

  const [imageToUpload, setImageToUpload] = useState<any>(null);

  // URLと合致するトピック一覧を取得
  React.useEffect(() => {
    dispatch(getTopicsRequest({ token, type_id: Number(manualId) }));
  }, [dispatch, token, manualId]);

  // タイトル・説明編集モード切り替え
  const [editTitleDescMode, setEditTitleDescMode] = useState<boolean>(false);

  // 画像編集中、「キャンセル」押下で編集破棄
  const cancelEditTitleDesc = () => {
    setHookedManual(stored_manual);
  };

  // +アイコンで末尾にアイテムを追加
  const handleAdd = (hookedTopics: TopicItem[]) => {
    setHookedTopics([
      ...hookedTopics,
      {
        id: null,
        type_id: hookedManual.id,
        name: "",
        order: hookedTopics.length + 1,
        updated_at: new Date().toISOString()
      }
    ]);
  };

  // マニュアルの順番を変更
  const changeTopicsOrder = (sortedTopics: TopicItem[]) => {
    const orderUpdatedTopics = sortedTopics.map((topic, index) => {
      return {
        ...topic,
        order: index + 1
      };
    });
    setHookedTopics(orderUpdatedTopics);
  };

  // マニュアルのタイトル編集
  const updateTopicTitle = (data: { order: number; new_name: string }) => {
    const updatedHookedTopics = hookedTopics.map(
      (topic: any, index: number) => {
        if (topic.order === data.order) {
          return {
            ...topic,
            name: data.new_name
          };
        }
        return topic;
      }
    );
    setHookedTopics(updatedHookedTopics);
  };

  // 削除用配列に追加
  const removeHookedItem = (data: { topic: TopicItem }) => {
    console.log("hookedManuals before", hookedTopics);
    console.log("toBeDeletedTopics before", toBeDeletedTopics);

    // 追加ボタンを押した後の空アイテムを削除する場合
    if (data.topic.id === null) {
      const itemRemovedHookedTopics = hookedTopics.filter(
        (topic: any) => topic.order !== data.topic.order
      );
      setHookedTopics(itemRemovedHookedTopics);
    } else {
      // 実データを削除する場合
      const itemRemovedHookedTopics = hookedTopics.filter(
        (topic: any) => topic.id !== data.topic.id
      );
      setHookedTopics(itemRemovedHookedTopics);
      setToBeDeletedTopics([...toBeDeletedTopics, data.topic]);
      console.log("hookedTopics after", hookedTopics);
      console.log("toBeDeletedTopics after", toBeDeletedTopics);
    }
  };

  // 「完了」ボタンでリクエスト
  const updateAllTopicsAction = (data: {
    req_body: TopicItem[];
    manual_id: number | null;
  }) => {
    const sanitized_req_body = data.req_body.filter(topic => topic.name !== "");
    const reordered_sanitized_req_body = sanitized_req_body.map(
      (topic, index) => {
        return {
          ...topic,
          order: index + 1
        };
      }
    );
    const req_body = reordered_sanitized_req_body.map(topic => {
      return {
        id: topic.id,
        type_id: hookedManual.id,
        name: topic.name,
        order: topic.order,
        history_flag: false
      };
    });
    const to_be_deleted_ids =
      toBeDeletedTopics && toBeDeletedTopics.length > 0
        ? toBeDeletedTopics.map(topic => topic.id)
        : null;
    dispatch(
      updateAllTopicsRequest({
        token: token,
        id: data.manual_id,
        req_body: req_body,
        to_be_deleted_ids: to_be_deleted_ids
      })
    );
  };

  // 「完了」ボタンで画像を保存後にマニュアル自体を更新
  const updateImageAction = (data: {
    hookedManual: ManualItemForSelector;
    imageToUpload: any;
  }) => {
    console.log("hookedManualhookedManualhookedManual", data.hookedManual);
    console.log("imageToUploadimageToUploadimageToUpload", data.imageToUpload);
    dispatch(
      updateManualWithImageRequest({
        token: token,
        hookedManual: data.hookedManual,
        imageToUpload: data.imageToUpload
      })
    );
  };

  // 「完了」ボタンで画像を保存後にマニュアル自体を更新
  const updateTitleDesc = (data: { hookedManual: ManualItemForSelector }) => {
    console.log("hookedManualhookedManualhookedManual", data.hookedManual);
    dispatch(
      updateManualTitleDesc({
        token: token,
        hookedManual: data.hookedManual
      })
    );
  };

  return {
    hookedManual,
    setHookedManual,
    topics,
    histories,
    tags,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    hookedTopics,
    setHookedTopics,
    cancelEdit,
    updateTopicTitle,
    handleAdd,
    removeHookedItem,
    changeTopicsOrder,
    updateAllTopicsAction,
    stored_manual,
    editImageMode,
    setEditImageMode,
    cancelEditImage,
    updateImageAction,
    imageToUpload,
    setImageToUpload,
    editTitleDescMode,
    setEditTitleDescMode,
    cancelEditTitleDesc,
    updateTitleDesc,
    manualName,
    manualId
  };
};
