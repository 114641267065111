import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GET_FACILITIES_ME_REQUESTED,
  GET_FACILITIES_ME_SUCCEEDED
} from "store/camera/facilities_me/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { getFacilitiesMeApi } from "../api/facilities";

function* getFacilitiesMe(action: any): Generator<any, any, any> {
  try {
    const response = yield call(getFacilitiesMeApi, action.token);
    yield put({
      type: GET_FACILITIES_ME_SUCCEEDED,
      facilities_me: response.data.facilities
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const cameraFacilitiesSaga = [
  takeLatest(GET_FACILITIES_ME_REQUESTED, getFacilitiesMe)
];

export default cameraFacilitiesSaga;
