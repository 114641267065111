import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GET_INQUIRIES_REQUESTED,
  GET_INQUIRIES_SUCCEEDED,
  GET_INQUIRIES_FAILED
} from "../store/faq/inquiries/type";
import {
  POST_INQUIRY_NEW_REQUESTED,
  POST_INQUIRY_NEW_SUCCEEDED,
  POST_INQUIRY_NEW_FAILED,
  POST_INQUIRY_MANAGEMENT_SUCCEEDED,
  POST_INQUIRY_MANAGEMENT_REQUESTED
} from "../store/faq/inquiry_new/type";
import {
  GET_INQUIRIES_CONTACTS,
  GET_INQUIRIES_CONTACTS_SUCCEEDED,
  GET_INQUIRIES_KINDS,
  GET_INQUIRIES_KINDS_SUCCEEDED,
  GET_INQUIRIES_PRIORITIES,
  GET_INQUIRIES_PRIORITIES_SUCCEEDED,
  GET_INQUIRIES_ANSWERS,
  GET_INQUIRIES_ANSWERS_SUCCEEDED,
  GET_INQUIRIES_CHECKLIST,
  GET_INQUIRIES_CHECKLIST_SUCCEEDED
} from "../store/faq/inquiries_choices/type";
import {
  getInquiriesApi,
  postInquiryManagementApi,
  postInquiryNewApi,
  getInquiryContact,
  getInquiryKinds,
  getInquiryPriorities,
  getInquiryAnswers,
  getInquiryCheckList
} from "../api/FAQ/inquiriesApi";
import {
  SET_CURRENT_PAGE_FAILED,
  SET_CURRENT_PAGE_SUCCEEDED,
  SET_TOTAL
} from "../store/common/paginate/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import { AxiosResponse } from "axios";

function* getInquiries(action: any) {
  try {
    console.log("action.token", action.token);
    console.log("action.page", action.page);
    console.log("action.limit", action.limit);
    const result = yield call(
      getInquiriesApi,
      action.token,
      action.page,
      action.limit
    );
    console.log("result", result);
    yield all([
      put({ type: GET_INQUIRIES_SUCCEEDED, inquiries: result.data.inquiries }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "inquiry"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "inquiry"
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postInquiryManagement(
  action: any
): Generator<any, any, AxiosResponse<any>> {
  try {
    console.log(action.token);
    const result = yield postInquiryManagementApi(
      action.token,
      action.requestBody
    );
    console.log(result);
    yield put({
      type: POST_INQUIRY_MANAGEMENT_SUCCEEDED,
      status: result.status,
      details: action.requestBody.details
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postInquiryNew(action: any) {
  try {
    console.log("==============================");
    console.log("action", action);
    const post_result = yield postInquiryNewApi(
      action.token,
      action.inquiry_name,
      action.employee_id,
      action.facility,
      action.summary,
      action.terminal,
      action.terminal_details,
      action.child_id,
      action.model,
      action.model_name,
      action.proxy_login,
      action.details
    );
    yield all([
      yield put({
        type: POST_INQUIRY_NEW_SUCCEEDED,
        status: post_result,
        details: action.details
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getInquiriesContacts(
  action: any
): Generator<any, any, AxiosResponse<any>> {
  try {
    const result = yield getInquiryContact(action.token);
    yield put({
      type: GET_INQUIRIES_CONTACTS_SUCCEEDED,
      contacts: result.data.contacts
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getInquiriesKinds(
  action: any
): Generator<any, any, AxiosResponse<any>> {
  try {
    const result = yield getInquiryKinds(action.token);
    yield put({
      type: GET_INQUIRIES_KINDS_SUCCEEDED,
      kinds1: result.data.contacts[0].kinds,
      kinds2: result.data.contacts[1].kinds
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getInquiriesPriorities(
  action: any
): Generator<any, any, AxiosResponse<any>> {
  try {
    const result = yield getInquiryPriorities(action.token);
    yield put({
      type: GET_INQUIRIES_PRIORITIES_SUCCEEDED,
      priorities: result.data.priorities
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getInquiriesAnswers(
  action: any
): Generator<any, any, AxiosResponse<any>> {
  try {
    const result = yield getInquiryAnswers(action.token);
    yield put({
      type: GET_INQUIRIES_ANSWERS_SUCCEEDED,
      answers: result.data.answers
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getInquiriesChecklist(
  action: any
): Generator<any, any, AxiosResponse<any>> {
  try {
    const result = yield getInquiryCheckList(action.token);
    yield put({
      type: GET_INQUIRIES_CHECKLIST_SUCCEEDED,
      checklist: result.data.checklist
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const inquirySaga = [
  takeLatest(GET_INQUIRIES_REQUESTED, getInquiries),
  takeLatest(POST_INQUIRY_NEW_REQUESTED, postInquiryNew),
  takeLatest(POST_INQUIRY_MANAGEMENT_REQUESTED, postInquiryManagement),
  takeLatest(GET_INQUIRIES_CONTACTS, getInquiriesContacts),
  takeLatest(GET_INQUIRIES_KINDS, getInquiriesKinds),
  takeLatest(GET_INQUIRIES_PRIORITIES, getInquiriesPriorities),
  takeLatest(GET_INQUIRIES_ANSWERS, getInquiriesAnswers),
  takeLatest(GET_INQUIRIES_CHECKLIST, getInquiriesChecklist)
];

export default inquirySaga;
