import { User, isMultiValue } from "components/Camera/type";
import React from "react";
import Select, { StylesConfig } from "react-select";

const customStyles: StylesConfig<{ value: string; label: string }, true> = {
  control: (provided, state) => {
    return {
      ...provided,
      width: "300px",
      cursor: "pointer"
    };
  },
  menu: (base, props) => {
    return {
      ...base,
      width: "300px"
    };
  }
};

export const UploaderSelect = (props: {
  users: User[];
  onSelectUploader: (v: isMultiValue) => void;
  disabled?: boolean;
}) => {
  const options = props.users.map(user => {
    return {
      value: user.id.toString(),
      label: user.name
    };
  });
  return (
    <Select
      options={options}
      styles={customStyles}
      isMulti
      isDisabled={props.disabled}
      onChange={v => props.onSelectUploader(v)}
    />
  );
};
