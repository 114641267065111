import React from "react";
import Select from "react-select";
import { labelValueStringOption, isMultiValue } from "components/Camera/type";

interface SelectBoxProps {
  options: labelValueStringOption[];
  deafultValue?: labelValueStringOption[];
  onSelectValue: (value: isMultiValue) => void;
}

export const MultiSelectBox = (props: SelectBoxProps) => {
  const { options, onSelectValue, deafultValue } = props;
  return (
    <Select
      name="option"
      options={options}
      isMulti
      onChange={value => onSelectValue(value)}
      defaultValue={deafultValue}
    />
  );
};
