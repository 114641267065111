import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from "react-router-dom"
import { pc } from "../../../mixins/mediaQuery";
import ArticlesList from '../ArticlesList';
import ArticlesListForUser from '../ArticlesListForUser';
import ArticlesStocksList from './ArticlesStocksList';
import ArticleStockPagination from './ArticleStockPagination'

function ArticlesStocksListBlock() {
  const location = useLocation();
  const pathName = location.pathname
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);
  return (
    <ArticlesStocksListBlockSC>
      <ArticleStockPagination />
      <ArticlesStocksList />
      <ArticleStockPagination position="bottom"/>
    </ArticlesStocksListBlockSC>
  )
}

export default ArticlesStocksListBlock

// ===================================================================================
// スタイル
// ===================================================================================
const ArticlesStocksListBlockSC = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 24px 0;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .16);
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 30px;
    order: 1;
  `}
`;

