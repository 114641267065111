import { put, call, takeLatest, takeEvery, all } from "redux-saga/effects";
import {
  deleteCategoryItemApi,
  postNewCategoryItemApi,
  updateCategoryItemApi,
  getCategoriesApiAfterPost
} from "../api/FAQ/categoryApi";
import { apiGetCategories } from "../api/global/sessionApi";
import {
  DELETE_CATEGORY_ITEM_SUCCEEDED,
  DELETE_CATEGORY_ITEM_FAILED,
  DELETE_CATEGORY_ITEM_REQUESTED,
  GET_REGISTERED_CATEGORIES_REQUESTED,
  GET_REGISTERED_CATEGORIES_SUCCEEDED,
  POST_NEW_CATEGORY_ITEM_REQUESTED,
  POST_NEW_CATEGORY_ITEM_SUCCEEDED,
  POST_NEW_CATEGORY_ITEM_FAILED,
  UPDATE_CATEGORY_ITEM_SUCCEEDED,
  UPDATE_CATEGORY_ITEM_FAILED,
  UPDATE_CATEGORY_ITEM_REQUESTED
} from "../store/faq/categories/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";

function* getRegisteredCategories(action: any) {
  const token = action.token;
  try {
    const result = yield call(apiGetCategories, token);
    console.log("get registered categories success: ", result);
    // return result.data.categories
    yield put({
      type: GET_REGISTERED_CATEGORIES_SUCCEEDED,
      categories: result.data.categories
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* deleteRegisteredCategory(action: any) {
  try {
    const token = action.token;
    const id = action.id;
    const result = yield call(deleteCategoryItemApi, token, id);
    yield put({ type: DELETE_CATEGORY_ITEM_SUCCEEDED, status: result.status });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postNewCategoryItem(action: any) {
  try {
    const token = action.token;
    const temp_id = action.temp_id;
    const name = action.new_category_name;
    const order = action.new_category_order;
    const post_result = yield* postNewCategoryItemApi(token, name, order);
    const get_result = yield* getCategoriesApiAfterPost(token);
    yield all([
      put({
        type: POST_NEW_CATEGORY_ITEM_SUCCEEDED,
        status: post_result.status,
        temp_id: temp_id
      }),
      put({
        type: GET_REGISTERED_CATEGORIES_SUCCEEDED,
        categories: get_result.data.categories
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* updateCategoryItem(action: any) {
  try {
    const token = action.token;
    const name = action.updated_name;
    const order = action.updated_order;
    const id = action.id;
    console.log("token", token);
    console.log("id", id);
    console.log("order", order);
    console.log("name", name);
    const result = yield call(updateCategoryItemApi, token, name, order, id);
    yield put({
      type: UPDATE_CATEGORY_ITEM_SUCCEEDED,
      status: result.status,
      id
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const categorySaga = [
  takeLatest(GET_REGISTERED_CATEGORIES_REQUESTED, getRegisteredCategories),
  takeLatest(GET_REGISTERED_CATEGORIES_REQUESTED, getRegisteredCategories),
  takeEvery(DELETE_CATEGORY_ITEM_REQUESTED, deleteRegisteredCategory),
  takeEvery(POST_NEW_CATEGORY_ITEM_REQUESTED, postNewCategoryItem),
  takeEvery(UPDATE_CATEGORY_ITEM_REQUESTED, updateCategoryItem)
];

export default categorySaga;
