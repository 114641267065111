import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { historiesSelector } from "store/manual/histories/selectors";
import { manualTagsSelector } from "store/manual/tags/selector";
import { manualPagesSelector } from "store/manual/manuals/pages/selectors";

export const useHooks = (data: { title?: string }) => {
  const tags = useSelector(manualTagsSelector);
  const location = useLocation();
  const token = useSelector((state: any) => state.session.token);
  const { manualId, topicId } =
    useParams<{ manualId: string; topicId: string }>();

  // ストアからページ一覧取得
  const pages = useSelector(manualPagesSelector);

  // コンポーネントに渡すページ一覧
  const [hookedPages, setHookedPages] = useState(pages);
  React.useEffect(() => {
    if (pages) setHookedPages(pages);
  }, [pages]);

  // 更新履歴
  const histories = useSelector(historiesSelector);

  return {
    token,
    manualId,
    topicId,
    tags,
    pages,
    histories,
    hookedPages,
    setHookedPages,
    location
  };
};
