import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  SET_CURRENT_PAGE_SUCCEEDED,
  SET_CURRENT_PAGE_FAILED,
  SET_PER_PAGE,
  SET_TOTAL,
  SET_ACCOUNTS_PER_PAGE,
  SET_ACCOUNTS_TOTAL,
  SET_ACCOUNTS_CURRENT_PAGE,
  SET_ACCOUNTS_LAST_PAGE,
  SET_ACCOUNT_EMAIL_TO_SEARCH,
  SET_DATA_PER_PAGE,
  SET_STOCKS_TOTAL,
  SET_STOCKS_CURRENT_PAGE_SUCCEEDED,
  SET_KEYWORD,
  SET_SEARCH_STATUS,
} from "./type";

const initialState: object = {
  article_current_page: 1,
  article_last_page: 1,
  article_per_page: 20,
  article_total: 0,
  article_keyword: "",

  news_page_num: 1,
  news_current_page: 1,
  news_last_page: 1,
  news_per_page: 20,
  news_total: 0,
  news_keyword: "",

  account_current_page: 1,
  account_last_page: 1,
  account_per_page: 20,
  account_total: 0,
  account_email_to_search: "",

  inquiry_current_page: 1,
  inquiry_per_page: 20,
  inquiry_last_page: 1,
  inquiry_total: 0,
  inquiry_keyword: "",

  article_stock_current_page: 1,
  article_stock_last_page: 1,
  article_stock_per_page: 20,
  article_stock_total: 0,
  article_stock_keyword: "",

  search_status: false,
};
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_PAGE_SUCCEEDED:
      const current_page_key = action.target_data + "_current_page";
      return {
        ...state,
        [current_page_key]: action.current_page,
      };

    case SET_CURRENT_PAGE_FAILED:
      console.log("SET_CURRENT_PAGE error", action.message);
      return state;

    case SET_PER_PAGE:
      const per_page_key = action.target_data + "_per_page";
      return {
        ...state,
        [per_page_key]: action.per_page,
      };

    case SET_TOTAL:
      const total_page_key = action.target_data + "_total";
      console.log("action.total", action.total);
      return {
        ...state,
        [total_page_key]: action.total,
      };

    case SET_KEYWORD:
      const keyword_key = action.target_data + "_keyword";
      return {
        ...state,
        [keyword_key]: action.keyword,
      };

    case SET_DATA_PER_PAGE:
      const data_per_page_key = action.target_data + "_per_page";
      return {
        ...state,
        [data_per_page_key]: action.per_page,
      };

    case SET_ACCOUNTS_TOTAL:
      return {
        ...state,
        accounts_total: action.total,
      };

    case SET_ACCOUNTS_CURRENT_PAGE:
      return {
        ...state,
        accounts_current_page: action.current_page,
      };

    case SET_ACCOUNTS_LAST_PAGE:
      return {
        ...state,
        accounts_last_page: action.last_page,
      };

    case SET_ACCOUNT_EMAIL_TO_SEARCH:
      return {
        ...state,
        account_email_to_search: action.account_email_to_search,
      };

    case SET_STOCKS_TOTAL:
      const total_stock_page_key = action.target_data + "_total";
      console.log("action.total", action.total);
      return {
        ...state,
        article_stock_total: action.total,
      };

    case SET_STOCKS_CURRENT_PAGE_SUCCEEDED:
      return {
        ...state,
        article_stock_current_page: action.current_page,
      };

    case SET_SEARCH_STATUS:
      return {
        ...state,
        search_status: action.search_status,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
