import React, { useState } from "react";
import styled from "styled-components";
import { pc } from "../mixins/mediaQuery";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { color } from "../mixins";
import {
  inquiry,
  inquiry_history,
  inquiry_history_current,
  inquiry_form,
  inquiry_form_current,
  faq,
  faq_list,
  faq_list_current,
  faq_register,
  faq_register_current,
  news,
  news_list,
  news_list_current,
  news_register,
  news_register_current,
  master,
  master_setting,
  master_setting_current,
  account,
  account_list,
  account_list_current,
  account_register,
  account_register_current,
  up,
  down,
  sidebar_close,
  sidebar_open,
  event_home,
  manual,
  camera
} from "./sidebarIcons";
import {
  toggleSidebar,
  toggleFaq,
  toggleNews,
  toggleMaster,
  toggleAccount,
  toggleInquiry
} from "../../store/common/sidebar/action";
import { postArticleRequest } from "../../store/faq/article_new/action";
import { postNewsRequest } from "../../store/global/news_new/action";

function SidebarAdmin() {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  // =====================================================================================
  // article_new =========================================================================
  const new_article_id = useSelector(
    (state: any) => state.article_new.new_article_id
  );
  const title = useSelector((state: any) => state.article_new.title);
  const content = useSelector((state: any) => state.article_new.content);
  // const search_string = useSelector((state: any) => state.article_new.search_string);
  const search_string = "test search string";
  const status = useSelector((state: any) => state.article_new.status);
  const categories = useSelector((state: any) => state.article_new.categories);
  const tags = useSelector((state: any) => state.article_new.tags);
  // =====================================================================================

  // =====================================================================================
  // news_new ============================================================================
  const new_news_id = useSelector((state: any) => state.news_new.new_news_id);
  const new_news_title = useSelector((state: any) => state.news_new.title);
  const new_news_content = useSelector((state: any) => state.news_new.content);
  const new_news_status = useSelector((state: any) => state.news_new.status);

  console.log("news_new_id", new_news_id);
  console.log("news_new_title", new_news_title);
  console.log("new_newscontent", new_news_content);
  console.log("new_news_status", new_news_status);
  // =====================================================================================

  const isOpen = useSelector((state: any) => state.sidebar.sidebarIsOpen);
  const faqIsOpen = useSelector((state: any) => state.sidebar.faqIsOpen);
  const newsIsOpen = useSelector((state: any) => state.sidebar.newsIsOpen);
  const masterIsOpen = useSelector((state: any) => state.sidebar.masterIsOpen);
  const accountIsOpen = useSelector(
    (state: any) => state.sidebar.accountIsOpen
  );
  const inquiryIsOpen = useSelector(
    (state: any) => state.sidebar.inquiryIsOpen
  );

  return (
    <SidebarSC className={isOpen ? "" : "closed"}>
      <SidebarList>
        <SideBarItem>
          <SideBarLabel onClick={() => dispatch(toggleSidebar())}>
            &nbsp;
            {isOpen ? (
              <SidebarArrow src={sidebar_close} alt="close" />
            ) : (
              <SidebarArrow src={sidebar_open} alt="open" />
            )}
          </SideBarLabel>
        </SideBarItem>

        <SideBarItem style={{ height: "46px" }}>
          <SidebarServiceMenu>{isOpen && "サービス"}</SidebarServiceMenu>
        </SideBarItem>

        <SideBarItem
          className={pathName === "/" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/">{isOpen && "FAQ"}</SidebarTitle>
        </SideBarItem>

        <SideBarItem style={{ height: "50px" }}>
          <SidebarTitle to="/events/home" className="eventHome">
            {isOpen && "行事予定"}
          </SidebarTitle>
        </SideBarItem>

        {/*  マニュアルTOP */}
        <SideBarItem
          className={pathName === "/manual" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/manual" className="manual">
            {isOpen && "マニュアル"}
          </SidebarTitle>
        </SideBarItem>
        <SideBarItem
          className={pathName === "/camera" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/camera" className="camera">
            {isOpen && "アルバム管理"}
          </SidebarTitle>
        </SideBarItem>

        {/* お知らせ */}
        <SideBarItem
          className={pathName === "/news" ? "active" : ""}
          style={{ height: "50px" }}
        >
          <SidebarTitle to="/news" className="news_list">
            {isOpen && "お知らせ"}
          </SidebarTitle>
        </SideBarItem>

        {/* お問合せ */}
        <SideBarItem
          className={inquiryIsOpen ? "active inquiries_list" : "inquiries_list"}
        >
          <SideBarLabel
            className="inquiry"
            onClick={() => dispatch(toggleInquiry())}
          >
            {isOpen && "お問合せ"}
            {isOpen && inquiryIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !inquiryIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {inquiryIsOpen ? (
            <ul>
              <FoldingItem className="inquiries_list">
                <Link to="/inquiries">{isOpen && "お問合せ履歴"}</Link>
              </FoldingItem>
              <FoldingItem className="inquiries_new">
                <Link to="/inquiries/new">{isOpen && "お問合せフォーム"}</Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        <SideBarItem style={{ height: "50px" }}>
          <SidebarAdminMenu>{isOpen && "メニュー"}</SidebarAdminMenu>
        </SideBarItem>

        {/* FAQ */}
        <SideBarItem className={faqIsOpen ? "active" : ""}>
          <SideBarLabel className="faq" onClick={() => dispatch(toggleFaq())}>
            {isOpen && "FAQ管理"}
            {isOpen && faqIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !faqIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {faqIsOpen ? (
            <ul>
              <FoldingItem className="faq_list">
                <Link to="/articles">{isOpen && "FAQ記事一覧"}</Link>
              </FoldingItem>
              <FoldingItem className="faq_register">
                <Link
                  to="/articles/new"
                  onClick={() =>
                    dispatch(
                      postArticleRequest(
                        token,
                        title,
                        content,
                        search_string,
                        status,
                        categories,
                        tags
                      )
                    )
                  }
                >
                  {isOpen && "FAQ記事登録"}
                </Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        {/* お知らせ */}
        <SideBarItem className={newsIsOpen ? "active" : ""}>
          <SideBarLabel className="news" onClick={() => dispatch(toggleNews())}>
            {isOpen && "お知らせ管理"}
            {isOpen && newsIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !newsIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {newsIsOpen ? (
            <ul>
              <FoldingItem className="news_list">
                <Link to="/news-edit-list">{isOpen && "お知らせ一覧"}</Link>
              </FoldingItem>
              <FoldingItem className="news_register">
                <Link
                  to="/news/new"
                  onClick={() =>
                    dispatch(
                      postNewsRequest(
                        token,
                        new_news_title,
                        new_news_content,
                        new_news_status
                      )
                    )
                  }
                >
                  {isOpen && "お知らせ登録"}
                </Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        {/* マスタ */}
        <SideBarItem className={masterIsOpen ? "active" : ""}>
          <SideBarLabel
            className="master"
            onClick={() => dispatch(toggleMaster())}
          >
            {isOpen && "マスタ管理"}
            {isOpen && masterIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !masterIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {masterIsOpen ? (
            <ul>
              <FoldingItem className="category">
                <Link to="/master/categories">{isOpen && "カテゴリ設定"}</Link>
              </FoldingItem>
              <FoldingItem className="tag">
                <Link to="/master/tags">{isOpen && "タグ設定"}</Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>

        {/* アカウント */}
        <SideBarItem className={accountIsOpen ? "active" : ""}>
          <SideBarLabel
            className="account"
            onClick={() => dispatch(toggleAccount())}
          >
            {isOpen && "アカウント管理"}
            {isOpen && accountIsOpen ? (
              <Arrow src={up} alt="up" />
            ) : isOpen && !accountIsOpen ? (
              <Arrow src={down} alt="down" />
            ) : (
              ""
            )}
          </SideBarLabel>
          {accountIsOpen ? (
            <ul>
              <FoldingItem className="account_list">
                <Link to="/accounts">{isOpen && "アカウント一覧"}</Link>
              </FoldingItem>
              <FoldingItem className="account_register">
                <Link to="/accounts/new">{isOpen && "アカウント登録"}</Link>
              </FoldingItem>
            </ul>
          ) : (
            ""
          )}
        </SideBarItem>
      </SidebarList>
    </SidebarSC>
  );
}

export default SidebarAdmin;

// ===================================================================================
// スタイル
// ===================================================================================
const SidebarSC = styled.aside`
  position: relative;
  min-width: 200px;
  height: calc(100vh - 60px);
  background: ${color.primary};
  overflow-x: hidden;
  overflow-y: scroll;
  transition-duration: 0.2s;
  display: none;
  ${pc`
    display: block;
    position: fixed;
    min-width: 200px;
    height: calc( 100vh - 60px );
    background: #298948;
    overflow-x: hidden;
    overflow-y: scroll;
    transition-duration: 0.2s;
    top: 60px;
  `}
  &.closed {
    max-width: 50px;
    min-width: 50px;
    transition-duration: 0.2s;
  }
`;

const SidebarServiceMenu = styled.p`
  color: ${color.primary_light};
  padding: 15px 0px 18px 50px;
  height: 46px;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
`;

const SidebarAdminMenu = styled.p`
  color: ${color.primary_light};
  padding: 18px 0px 18px 50px;
  height: 50px;
  font-size: 14px;
  cursor: default;
  white-space: nowrap;
`;
const SidebarTitle = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  background-image: url(${faq});
  // border-bottom: 1px solid ${color.primary_light};border-bottomborder-bottom
  box-sizing: border-box;
  box-sizing &:hover {
    background-color: ${color.primary_light};
  }
  &.news_list {
    background-image: url(${news});
  }
  &.eventHome {
    background-image: url(${event_home});
  }
  &.manual {
    background-image: url(${manual});
  }
  &.camera {
    background-image: url(${camera});
  }
`;

const SidebarList = styled.ul`
  width: 100%;
`;

const SideBarLabel = styled.p`
  position: relative;
  height: 50px;
  padding-top: 18px;
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  white-space: nowrap;
  &.faq {
    background-image: url(${faq});
  }
  &.news {
    background-image: url(${news});
  }
  &.master {
    background-image: url(${master});
  }
  &.account {
    background-image: url(${account});
  }
  &.inquiry {
    background-image: url(${inquiry});
  }
  &:hover {
    background-color: ${color.primary_light};
  }
`;

const Arrow = styled.img`
  position: absolute;
  right: 16px;
  top: 23px;
`;

const SidebarArrow = styled(Arrow)`
  top: 18px;
`;

const SideBarItem = styled.li`
  height: auto;
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
  cursor: pointer;
  &.active {
    background-color: ${color.primary_light};
  }
  &.inquiries_list {
    border-bottom: 1px solid ${color.primary_light};
  }
`;

const FoldingItem = styled.li`
  height: 50px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px 20px;
  &:hover {
    background-color: ${color.primary_white};
    color: ${color.primary};
  }
  &.faq_list {
    background-image: url(${faq_list});
    &:hover,
    &.current {
      background-image: url(${faq_list_current});
    }
  }
  &.faq_register {
    background-image: url(${faq_register});
    &:hover,
    &.current {
      background-image: url(${faq_register_current});
    }
  }
  &.inquiries_list {
    background-image: url(${inquiry_history});
    &:hover,
    &.current {
      background-image: url(${inquiry_history_current});
    }
  }
  &.inquiries_new {
    background-image: url(${inquiry_form});
    &:hover,
    &.current {
      background-image: url(${inquiry_form_current});
    }
  }
  &.news_list {
    background-image: url(${news_list});
    &:hover,
    &.current {
      background-image: url(${news_list_current});
    }
  }
  &.news_register {
    background-image: url(${news_register});
    &:hover,
    &.current {
      background-image: url(${news_register_current});
    }
  }
  &.tag {
    background-image: url(${master_setting});
    &:hover,
    &.current {
      background-image: url(${master_setting_current});
    }
  }
  &.category {
    background-image: url(${master_setting});
    &:hover,
    &.current {
      background-image: url(${master_setting_current});
    }
  }
  &.account_list {
    background-image: url(${account_list});
    &:hover,
    &.current {
      background-image: url(${account_list_current});
    }
  }
  &.account_register {
    background-image: url(${account_register});
    &:hover,
    &.current {
      background-image: url(${account_register_current});
    }
  }
  a,
  button {
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    padding: 18px 0 18px 50px;
  }
`;
