import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import pulldown_arrow from "../../../../images/icons/pulldown_arrow.svg";
import close from "../../../../images/icons/close.svg";

import { toggleMonthlyApprovalModal } from "../../../../store/common/modal/action";
import { postApprovalsMonthlyRequest } from "../../../../store/event/approval/action";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";

export default function ReturnEventsModalContent() {
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );
  const to_be_approved = useSelector(
    (state: any) => state.approval.to_be_approved
  );
  const authority_status = useSelector(
    (state: any) => state.session.position.id
  );
  const dispatch = useDispatch();
  const handleApprove = (e: any) => {
    e.preventDefault();
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));
    dispatch(
      postApprovalsMonthlyRequest(
        token,
        facility_id,
        to_be_approved,
        filtered_months,
        filtered_year
      )
    );
  };

  // LoadingModuleの表示終了
  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, [dispatch, to_be_approved]);

  return (
    <AddApprovalEventsModalContentSC>
      <TitleBlock>
        <Title>行事予定承認</Title>
        <CloseIcon
          src={close}
          alt="閉じる　ボタン"
          onClick={() => dispatch(toggleMonthlyApprovalModal(false))}
        />
      </TitleBlock>

      <AddApprovalEventsBlock>
        <AddApprovalEventsBlockDesc>
          以下の月の行事予定を承認しますがよろしいですか？
        </AddApprovalEventsBlockDesc>

        <AddApprovalEventsBlockSelectDesc>
          {to_be_approved &&
            to_be_approved.length > 0 &&
            to_be_approved.map((item: any, index: number) => {
              if (to_be_approved.length - 1 === index) {
                return `${new Date(item).getMonth() + 1}月`;
              }
              return `${new Date(item).getMonth() + 1}月、`;
            })}
        </AddApprovalEventsBlockSelectDesc>

        <AddApprovalEventsBlockDesc>
          現在未承認の月は下記の通りです。
        </AddApprovalEventsBlockDesc>

        <AddApprovalEventsBlockUnSelectDesc>
          {monthly_status.map((item: any, index: number) => {
            // 施設長
            if (authority_status === 5) {
              if (item.leader_approved) {
                return "";
              }
              return `${new Date(item.month).getMonth() + 1}月、`;
            } else if (authority_status === 6) {
              if (item.administrator_approved) {
                return "";
              }
              return `${new Date(item.month).getMonth() + 1}月、`;
              // 本部 管理者
            } else {
              if (item.director_approved) {
                return "";
              }
              return `${new Date(item.month).getMonth() + 1}月、`;
            }
          })}
        </AddApprovalEventsBlockUnSelectDesc>

        <AddApprovalEventsBlockDesc>
          <span>※承認後の編集はできません。</span>
          修正指示がある場合は承認・申請画面から「差戻し」を行ってください。
        </AddApprovalEventsBlockDesc>

        <Buttons>
          <CancelButton
            onClick={() => dispatch(toggleMonthlyApprovalModal(false))}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            value="承認"
            onClick={(e: any) => handleApprove(e)}
          />
        </Buttons>
      </AddApprovalEventsBlock>
    </AddApprovalEventsModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const AddApprovalEventsModalContentSC = styled.div`
  max-width: 600px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const AddApprovalEventsBlock = styled.div`
  padding: 33px 54px 30px;
`;

const AddApprovalEventsBlockDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  span {
    margin-bottom: 10px;
    display: block;
  }
`;

const AddApprovalEventsBlockSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const AddApprovalEventsBlockUnSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  &:disabled {
    opacity: 0.6;
    cursur: none;
  }
`;
