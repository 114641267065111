import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FlexPageLayout } from "../Parts/MainLayout";
import ModuleArea from "../Parts/ModuleAreaPageDetail";
import { useHooks } from "../Admin/PageDetail/Hooks";
import usePageDetailHooks from "./Hooks";
import IndexBlock from "./indexModule/IndexBlock";
import PagesDetailBlock from "./PagesDetailBlock";
import { pc, minSp } from "components/mixins/mediaQuery";
import IndexModalSP from "./IndexModalSP";
import { useLocation } from "react-router-dom";
import { toggleRightSection } from "../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../styles/layout/containers";

const PageDetail = () => {
  // hooks
  const { storedPageDetail, manualId, topicId, storedTopicTitle } = useHooks();
  const { index } = usePageDetailHooks();
  console.log("index", index);
  const dispatch = useDispatch();
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  const [indexModalSPDisplay, setIndexModalSPDisplay] = React.useState(false);

  const location = useLocation();
  const pathName = location.pathname;
  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <>
      {storedPageDetail.status !== 1 ? (
        <FlexPageLayout
          pageTitle={storedPageDetail.title}
          toTopicsPath={`/manual/manuals/${manualId}/`}
          topicTitle={storedTopicTitle}
          toPagesPath={`/manual/manuals/${manualId}/topics/${topicId}`}
          currentPageTitle={storedPageDetail.title}
        >
          <RightSectionToggler
            className={rightSectionOpen ? "open" : "closed"}
            onClick={() => dispatch(toggleRightSection())}
          />
          <PagesDetailBlock storedPageDetail={storedPageDetail} />
          <ModuleArea>
            <IndexBlock index={index} />
          </ModuleArea>

          {/* SP時の目次表示ボタン */}
          <ModuleAreaBtnSP
            onClick={() => setIndexModalSPDisplay(!indexModalSPDisplay)}
          >
            目次
          </ModuleAreaBtnSP>
          {/* SP時のモーダル */}
          {indexModalSPDisplay && (
            <IndexModalSP
              index={index}
              setIndexModalSPDisplay={setIndexModalSPDisplay}
              indexModalSPDisplay={indexModalSPDisplay}
            />
          )}
        </FlexPageLayout>
      ) : (
        <FlexPageLayout
          pageTitle={"非公開ページ"}
          toTopicsPath={`/manual/manuals/${manualId}/`}
          topicTitle={storedTopicTitle}
          toPagesPath={`/manual/manuals/${manualId}/topics/${topicId}`}
        >
          <div>このページは非公開です</div>
        </FlexPageLayout>
      )}
    </>
  );
};

export default PageDetail;

const ModuleAreaBtnSP = styled.span`
  ${minSp`
    position: fixed;
    top: calc(50% - 88px);
    right: 0;
    width: 30px;
    height: 88px;
    letter-spacing: 6px;
    background: #414141;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px 0 0 3px;
    cursor: pointer;
    -ms-writing-mode: tb-rl;
	  writing-mode: vertical-rl;
    padding-right: 8px;
    vertical-align: center;
  `}
  ${pc`
    display: none;
  `}
`;
