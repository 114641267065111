import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toggleFacilityModal } from "../../../store/common/modal/action";
import { getFacilitiesRequest } from "../../../store/event/facilities/action";

export default function FacilitySelectNav() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const facility_name = useSelector((state: any) => state.facility.name);

  const handleClickFacilitySelect = () => {
    dispatch(toggleFacilityModal(true));
    dispatch(getFacilitiesRequest(token));
  };

  useEffect(() => {
    if (facility_name === "") {
      dispatch(toggleFacilityModal(true));
    }
  }, []);

  return (
    <>
      <FacilitySelect onClick={handleClickFacilitySelect}>
        施設選択
      </FacilitySelect>
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const FacilitySelect = styled.div`
  background-color: #444444;
  padding: 9px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  width: 88px;
  height: 30px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;
