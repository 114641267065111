import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import searchOpen from "../../../images/icons/down_arrow_gray.svg";
import searchIcon from "../../../images/icons/search.svg";
import pulldown_arrow from "../../../images/icons/pulldown_arrow.svg";

import { toggleSearchArticleIsOpened } from "../../../store/session/action";
import { getEventListRequest } from "../../../store/event/events/action";
import {
  setDateList,
  setFilteredYear
} from "../../../store/event/event_search/action";

import { animateScroll as scroll } from "react-scroll";
import { setDailyListForTop } from "../../../store/event/events_top/action";
import { getMonthlyEventsRequest } from "../../../store/event/monthly_events/action";
import { useEventYear } from "utils/eventYear";

function EventsSearchBlock() {
  const dispatch = useDispatch();
  const { years } = useEventYear();
  const searchIsOpened = useSelector(
    (state: any) => state.session.searchArticleIsOpened
  );

  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_month_top = useSelector(
    (state: any) => state.event_search.filtered_month_top
  );

  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  // 年選択
  const handleYearSelect = (e: any) => {
    dispatch(setFilteredYear(e.target.value));
  };

  // 検索実施
  const submitSearch = (e: any) => {
    e.preventDefault();
    scroll.scrollToTop({ duration: 200 });
    dispatch(setDailyListForTop(filtered_year, filtered_month_top));
    dispatch(setDateList());
    dispatch(
      getEventListRequest(token, facility_id, filtered_year, [], [], "")
    );
    dispatch(
      getMonthlyEventsRequest(
        token,
        facility_id,
        filtered_year,
        [filtered_month_top],
        [],
        ""
      )
    );
  };

  window.onscroll = function () {
    const searchBlock = document.getElementById("event_search_block");

    if (document.documentElement.scrollTop > 181) {
      if (searchBlock) {
        searchBlock.classList.add("fixed");
      }
    } else {
      if (searchBlock) {
        searchBlock.classList.remove("fixed");
      }
    }
  };

  return (
    <EventsSearchBlockSC
      id="event_search_block"
      className={rightSectionOpen ? "open" : "close"}
    >
      <EventsSearchBlockHeaderSC
        onClick={() => dispatch(toggleSearchArticleIsOpened(searchIsOpened))}
      >
        <EventsSearchBlockHeadingSC>絞り込み条件</EventsSearchBlockHeadingSC>
        <ToggleArrowSC
          className={searchIsOpened ? "open" : ""}
          src={searchOpen}
          alt="toggleArrow"
        />
      </EventsSearchBlockHeaderSC>

      {/* 検索条件 */}
      <SearchForm className={searchIsOpened ? "open" : ""} action="">
        <SearchContentsSC>
          {/* キーワード */}
          <SearchContentsLabelSC>年度</SearchContentsLabelSC>
          <SelectLabel onChange={handleYearSelect} value={filtered_year}>
            {years.map((year: number) => (
              <option
                key={year}
                value={year}
              >{`${year.toString()}年度`}</option>
            ))}
          </SelectLabel>
        </SearchContentsSC>

        <SearchButton onClick={submitSearch}>
          <img src={searchIcon} alt="search icon" />
          この条件で絞り込み
        </SearchButton>
      </SearchForm>
    </EventsSearchBlockSC>
  );
}

export default EventsSearchBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const EventsSearchBlockSC = styled.div`
  // max-width: 360px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  &.close {
    display: none;
  }
  ${pc`
    max-width: 360px;
    width: 34.5%;
    margin-bottom: 0;
    padding: 30px;
    order: 2;
  `}
  &.fixed {
    display: block;
    position: fixed;
    top: 67px;
    right: 40px;
    left: calc((100% - 30px) - (100% - 260px) * 0.345);
    width: calc((100% - 260px) * 0.345);
    border-radius: 6px;
    z-index: 99;
    &.close {
      display: none;
    }
    @media (min-width: 1527px) {
      left: calc(100vw - 400px);
    }
    & + div {
      top: 68px;
    }
  }
`;

const EventsSearchBlockHeaderSC = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const EventsSearchBlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;
// =====================================
// SerchForm  ==========================
const SearchForm = styled.form`
  padding-top: 20px;
  display: none;
  &.open {
    display: block;
  }
`;

const SearchContentsSC = styled.dl`
  position: relative;
  margin-bottom: 19px;
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.light_gray};
  margin-bottom: 12px;
`;

const SelectLabel = styled.select`
  background-image: url(${pulldown_arrow});
  background-repeat: no-repeat;
  background-position: top 2px right 0px;
  width: 75px;
  height: 13px;
  font-size: 12px;
  color: ${color.light_gray};
`;
const Option = styled.option``;
const SearchButton = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: ${color.white};
  background-color: ${color.gray};
  font-weight: bold;
  padding: 11px 9px;
  font-size: 14px;
  border-radius: 4px;
  img {
    transform: translateY(-1px);
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
  ${pc`
    padding: 9px;
    font-size: 12px;
  `}
`;
// SerchForm  ==========================
//======================================
