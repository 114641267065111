import axios from "axios";
import { env_camera_url } from "serverEnv";
import { GetMediaFileDataType, AlbumViewType } from "../type";
import { SearchParams } from "store/camera/mediafiles/reducer";

/**メディアフファイル取得 個人用
 * @param token: アクセストークン
 */
export const getMediaFilesMineApi = (token: string) => {
  return axios.get<GetMediaFileDataType>(`${env_camera_url}/media-files/mine`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

/**メディアフファイル取得 共有済み
 * @param token: アクセストークン
 */
export const getMediaFilesShareApi = (token: string) => {
  return axios.get<GetMediaFileDataType>(
    `${env_camera_url}/media-files/share`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

/**メディアフファイル取得 管理者用
 * @param token: アクセストークン
 */
export const getMediaFilesAdminApi = (token: string) => {
  return axios.get<GetMediaFileDataType>(
    `${env_camera_url}/media-files/admin`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const searchMediaFilesApi = (
  token: string,
  view: AlbumViewType,
  params: SearchParams
) => {
  console.log(params);
  return axios.get<GetMediaFileDataType>(
    `${env_camera_url}/media-files/${view}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params
    }
  );
};

// メディアファイル詳細取得
export const getMediaFileDetailApi = (token: string, id: number) => {
  return axios.get(`${env_camera_url}/media-files/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      id: id
    }
  });
};

// メディアファイル削除
export const deleteMediaFileApi = (token: string, id: number) => {
  return axios.delete(`${env_camera_url}/media-files/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

// メディアファイル情報更新API
export const putMediaFileApi = (params: {
  token: string;
  id: number;
  requestBody: {
    tag_ids: number[];
    facility_ids: number[];
    class_ids: number[];
  };
}) => {
  const { token, id, requestBody } = params;
  return axios.put(`${env_camera_url}/media-files/${id}`, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

// メディアファイル一括更新
export const putMediaFilesApi = (params: {
  token: string;
  requestBody: {
    ids: number[];
    facility_ids: number[];
    tag_ids: number[];
    class_ids: number[];
  };
}) => {
  const { token, requestBody } = params;
  return axios.put(`${env_camera_url}/media-files/batch`, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const postMediaFileUpload = (
  token: string,
  requestBody: { encoded_file: string; async: boolean }
) => {
  return axios.post(`${env_camera_url}/media-files`, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const putMediaFileShareBan = (token: string, id: number | string) => {};
export const putMediaFileBatch = (token: string, id: number | string) => {};
export const putMediaFileUnshare = (token: string, id: number | string) => {};
export const putMediaFile = (token: string, id: number | string) => {};
export const postMediaFileReUpload = (token: string) => {};
