import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import pulldown_arrow from "../../../../images/icons/pulldown_arrow.svg";
import close from "../../../../images/icons/close.svg";

import { toggleAlreadyApprovedErrorModal } from "../../../../store/common/modal/action";
import { setAlreadyApprovedMonths } from "../../../../store/event/approval/action";

export default function ReturnEventsModalContent() {
  const dispatch = useDispatch();
  const authority_status = useSelector(
    (state: any) => state.session.position.id
  );
  const monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );
  const already_approved_month = useSelector(
    (state: any) => state.approval.already_approved_month
  );

  const handleClickCloseBtn = () => {
    dispatch(toggleAlreadyApprovedErrorModal(false));
    dispatch(setAlreadyApprovedMonths([]));
  };

  const handleClickCancelBtn = () => {
    dispatch(toggleAlreadyApprovedErrorModal(false));
    dispatch(setAlreadyApprovedMonths([]));
  };

  // すでに承認されている月を「、」で繋ぐ
  const already_approved_month_list = already_approved_month
    .map((item: any) => `${new Date(item).getMonth() + 1}月`)
    .join("、");

  return (
    <AddApprovalEventsModalContentSC>
      <TitleBlock>
        <Title>行事予定承認</Title>
        <CloseIcon
          src={close}
          alt="閉じる　ボタン"
          onClick={handleClickCloseBtn}
        />
      </TitleBlock>

      <AddApprovalEventsBlock>
        <AddApprovalEventsBlockDesc>
          下記の月は既に承認済みです。
        </AddApprovalEventsBlockDesc>
        <AddApprovalEventsBlockSelectDesc>
          {already_approved_month_list}
        </AddApprovalEventsBlockSelectDesc>

        <AddApprovalEventsBlockDesc>
          現在未承認の月は下記の通りです。
        </AddApprovalEventsBlockDesc>

        <AddApprovalEventsBlockUnSelectDesc>
          {monthly_status.map((item: any, index: number) => {
            // 施設長
            if (authority_status === 5) {
              if (item.leader_approved) {
                return "";
              }
              return `${new Date(item.month).getMonth() + 1}月、`;
              // PJ管理者
            } else if (authority_status === 6) {
              if (item.administrator_approved) {
                return "";
              }
              return `${new Date(item.month).getMonth() + 1}月、`;
              // 本部 管理者
            } else {
              if (item.director_approved) {
                return "";
              }
              return `${new Date(item.month).getMonth() + 1}月、`;
            }
          })}
        </AddApprovalEventsBlockUnSelectDesc>

        <Buttons>
          <CancelButton onClick={handleClickCancelBtn}>OK</CancelButton>
        </Buttons>
      </AddApprovalEventsBlock>
    </AddApprovalEventsModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const AddApprovalEventsModalContentSC = styled.div`
  max-width: 600px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const AddApprovalEventsBlock = styled.div`
  padding: 33px 54px 30px;
`;

const AddApprovalEventsBlockDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  span {
    margin-bottom: 10px;
    display: block;
  }
`;

const AddApprovalEventsBlockSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const AddApprovalEventsBlockUnSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
`;
