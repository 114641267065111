import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import color from "../../../mixins/color";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { setNewsRawEditContent, setSearchStringNewsEdit, toggleNewsIsEditing } from "../../../../store/global/news_edit/action";
import { env_faq_url } from "../../../../serverEnv";

function WysiwygEditor(props: any) {
  const dispatch = useDispatch();

  // const article_content = useSelector((state: any) => state.article_edit.content);
  const content = props.content;
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(JSON.parse(content))));

  const news_id = useSelector((state: any) => state.news_edit.id);

  // 検索文字列を初期化
  let search_string: any = "";
  convertToRaw(editorState.getCurrentContent()).blocks.forEach((block: any) => {
    search_string = search_string + block.text;
  });
  useEffect(() => {
    dispatch(setSearchStringNewsEdit(search_string));
    dispatch(toggleNewsIsEditing(false));
  }, []);

  const handleEditorStateChange = (state: any) => {
    setEditorState(state);

    // 検索文字列を都度生成
    search_string = "";
    convertToRaw(editorState.getCurrentContent()).blocks.forEach((block: any) => {
      search_string = search_string + block.text;
    });

    dispatch(setNewsRawEditContent(JSON.stringify(convertToRaw(editorState.getCurrentContent()))));
    dispatch(setSearchStringNewsEdit(search_string));
    dispatch(toggleNewsIsEditing(true));
  };

  const token = useSelector((state: any) => state.session.token);

  function uploadImageCallBack(file: any) {
    console.log("file", file);
    console.log("news_id", news_id);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open("POST", `${env_faq_url}/news/image`);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      const data = new FormData(); // eslint-disable-line no-undef
      data.append("file", file);
      data.append("news_id", news_id);
      data.append("file_name", "file_name");
      data.append("alt", "test alt");
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        console.log("response", response);
        resolve({ data: { link: response.path } });
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  return (
    <WrapperSC>
      <Editor
        defaultEditorState={EditorState.createWithContent(convertFromRaw(JSON.parse(content)))}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        editorStyle={{
          borderRadius: "4px",
          border: "1px solid #BBBBBB",
          minHeight: "200px",
          background: "white",
          padding: "10px"
        }}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      {/* {console.log("editorState => ", convertToRaw(editorState.getCurrentContent()))} */}
    </WrapperSC>
  );
}

export default WysiwygEditor;

const WrapperSC = styled.div`
  span,
  li {
    letter-spacing: 2px;
  }
  span {
    line-height: 1.8;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    div {
      margin: 0;
    }
    span {
      line-height: 1.6;
    }
  }
  h2 {
    letter-spacing: 6px;
    padding-left: 10px;
    border-left: 10px solid ${color.wysiwyg_border};
  }
  h3 {
    letter-spacing: 4px;
    padding: 0 0 5px 10px;
    border-bottom: 1px solid ${color.wysiwyg_border};
  }
  h4 {
    letter-spacing: 3px;
    position: relative;
    padding-left: 15px;
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      display: block;
      background-color: ${color.wysiwyg_border};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  .public-DraftStyleDefault-orderedListItem {
    line-height: 1.6;
    margin-left: 2.1em;
    span {
      line-height: 1.6;
    }
  }
  .public-DraftStyleDefault-unorderedListItem {
    font-size: 14px;
    line-height: 1.6;
    span {
      line-height: 1.6;
    }
  }
  .DraftEditor-root {
    a {
      color: ${color.primary};
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
      span {
        color: ${color.primary};
      }
    }
  }
  .rdw-link-decorator-wrapper {
    img {
      display: none;
    }
  }
`;
