import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import ArticlesItem from "../ArticlesItem";
import ArticlesItemForStocks from "../ArticlesItemForStocks";
import colors from "../../../mixins/color";
import { getArticlesStocksRequest } from "../../../../store/faq/articles_stocked/action";
import { resetArticleDetail } from "../../../../store/faq/article_detail/action";

import { toggleArticleNewPostStatus } from "../../../../store/faq/article_new/action";
import { toggleArticleEditPostStatus } from "../../../../store/faq/article_edit/action";

function ArticlesList() {
  const token = useSelector((state: any) => state.session.token);
  const user_id = useSelector((state: any) => state.session.id);
  const articles_stocked = useSelector((state: any) => state.articles_stocked);
  const dispatch = useDispatch();
  const paginate_current_page = useSelector(
    (state: any) => state.paginate.article_stock_current_page
  );

  const paginate_per_page = useSelector(
    (state: any) => state.paginate.article_stock_per_page
  );
  const keyword = useSelector(
    (state: any) => state.paginate.article_stock_keyword
  );

  // 一覧記事取得
  useEffect(() => {
    dispatch(
      getArticlesStocksRequest(
        token,
        user_id,
        paginate_current_page,
        paginate_per_page,
        keyword
      )
    );
  }, [dispatch, token, user_id]);

  // 記事詳細のリセット
  useEffect(() => {
    dispatch(resetArticleDetail());
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleArticleNewPostStatus(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleArticleEditPostStatus(false));
  }, [dispatch]);

  return (
    <ArticlesListSC>
      {
        // お気に入りの時はお気に入りだけの一覧を表示
        articles_stocked &&
          articles_stocked.length > 0 &&
          articles_stocked.map((article_stocked: any) => (
            <ArticlesItemForStocks
              key={article_stocked.id}
              id={article_stocked.id}
              title={article_stocked.title}
              content={article_stocked.content}
              status={article_stocked.status}
              updated_at={article_stocked.updated_at}
              categories={article_stocked.categories}
              tags={article_stocked.tags}
              stocked={true}
              read={article_stocked.read}
              className="stockedItem"
            />
          ))
      }
    </ArticlesListSC>
  );
}

export default ArticlesList;

const ArticlesListSC = styled.ul`
  width: 100%;
  border-top: 1px solid ${colors.list_border};
  border-bottom: 1px solid ${colors.list_border};
  ${pc`
    border: 1px solid ${colors.list_border};
    border-radius: 4px;
  `}
`;
