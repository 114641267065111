import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_NEWS_DETAIL_SUCCEEDED,
  GET_NEWS_DETAIL_FAILED,
  READ_NEWS_DETAIL_SUCCEEDED,
  READ_NEWS_DETAIL_FAILED,
  SET_NEWS_DETAIL_ID,
  TOGGLE_NEWS_DETAIL_CONTENT_LOADED,
  GET_NEWS_DETAIL_FAILED_404,
} from "./type";

interface news_detail {
  title: string;
  content: any;
  status: number;
  user_id: number;
  contentLoaded: boolean;
  message: string;
}

const initialState: news_detail = {
  title: "",
  content:
    '{"blocks":[{"key":"","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  status: 2,
  user_id: 1,
  contentLoaded: false,
  message: "",
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_NEWS_DETAIL_ID:
      return {
        ...state,
        id: action.id,
      };

    case GET_NEWS_DETAIL_SUCCEEDED:
      return {
        ...state,
        ...action.news_detail,
        contentLoaded: true,
        message: "",
      };

    case GET_NEWS_DETAIL_FAILED:
      return {
        ...state,
        message: action.message,
      };

    case GET_NEWS_DETAIL_FAILED_404:
      return {
        ...initialState,
        message: action.message,
        contentLoaded: true,
      };

    case READ_NEWS_DETAIL_SUCCEEDED:
      return {
        ...state,
      };

    case READ_NEWS_DETAIL_FAILED:
      return {
        ...state,
        message: action.message,
      };

    case TOGGLE_NEWS_DETAIL_CONTENT_LOADED:
      return {
        ...state,
        contentLoaded: action.contentLoaded,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
