import {
  GET_INQUIRIES_CONTACTS,
  GET_INQUIRIES_KINDS,
  GET_INQUIRIES_PRIORITIES,
  GET_INQUIRIES_ANSWERS,
  GET_INQUIRIES_CHECKLIST
} from "./type";

export const getInquiriesContacts = (token: string) => {
  return {
    type: GET_INQUIRIES_CONTACTS,
    token
  };
};

export const getInquiriesKinds = (token: string) => {
  return {
    type: GET_INQUIRIES_KINDS,
    token
  };
};

export const getInquiriesPriorities = (token: string) => {
  return {
    type: GET_INQUIRIES_PRIORITIES,
    token
  };
};

export const getInquiriesAnswers = (token: string) => {
  return {
    type: GET_INQUIRIES_ANSWERS,
    token
  };
};

export const getInquiriesChecklist = (token: string) => {
  return {
    type: GET_INQUIRIES_CHECKLIST,
    token
  };
};
