import {
  SET_FACILITY_ID,
  ADD_TO_BE_APPROVED,
  TOGGLE_TO_BE_APPROVED,
  TOGGLE_TO_BE_APPROVED_ALL,
  TOGGLE_SELECTED_OPTIONS,
  RESET_TO_BE_APPROVED,
  RESET_SELECTED_OPTOIONS,
  RESET_SELECTED_MONTH,
  GET_FACILITIES_APPROVALS_STATUS_REQUESTED,
  GET_MONTHLY_APPROVAL_STATUS_REQUESTED,
  TOGGLE_TO_BE_REJECTED,
  TOGGLE_TO_BE_REJECTED_ALL,
  SET_REJECT_COMMENT,
  SET_REJECT_COMMENT_FOR_ALL,
  POST_REJECT_REQUESTED,
  POST_APPROVALS_MONTHLY_REQUSEDTED,
  DELETE_REJECT_REQUESTED,
  ADD_TO_BE_DELETE_REJECT,
  SET_SELECTED_MONTH_TO_APPROVAL,
  RESET_REJECT_COMMENT,
  RESET_TO_BE_REJECTED,
  SET_ALREADY_APPROVED_MONTH,
  SET_MONTH_TO_CANCEL_ALL,
  CANCEL_ALL_EVENTS_REQUESTED,
  POST_REJECT_YEAR_MONTHLY_REQUESTED
} from "./type";
import { SET_SELECTED_MONTH } from "../events_top/type";

export const setFacilityId = (facility_id: string) => {
  return {
    type: SET_FACILITY_ID,
    facility_id
  };
};

export const addToBeApproved = (to_be_approved_array: []) => {
  return {
    type: ADD_TO_BE_APPROVED,
    to_be_approved_array
  };
};

export const toggleToBeApproved = (id: string, month: string) => {
  return {
    type: TOGGLE_TO_BE_APPROVED,
    id,
    month
  };
};

export const toggleToBeApprovedAll = (id: string, monthly_list: []) => {
  return {
    type: TOGGLE_TO_BE_APPROVED_ALL,
    id,
    monthly_list
  };
};

export const toggleSelectedOptions = (selected_options: []) => {
  return {
    type: TOGGLE_SELECTED_OPTIONS,
    selected_options
  };
};

export const resetToBeApproved = () => {
  return {
    type: RESET_TO_BE_APPROVED
  };
};

export const resetSelectedOptions = () => {
  return {
    type: RESET_SELECTED_OPTOIONS
  };
};

export const resetSelectedMonth = () => {
  return {
    type: RESET_SELECTED_MONTH
  };
};

export const getFacilitiesApprovalsStatusRequest = (
  token: string,
  year: number
) => {
  return {
    type: GET_FACILITIES_APPROVALS_STATUS_REQUESTED,
    token,
    year
  };
};

export const getApprovalMonthlyStatusRequest = (
  token: string,
  facility_id: number,
  year: number
) => {
  return {
    type: GET_MONTHLY_APPROVAL_STATUS_REQUESTED,
    token,
    facility_id,
    year
  };
};

export const postApprovalsMonthlyRequest = (
  token: string,
  facility_id: string,
  months: [],
  filtered_months: [],
  year: number
) => {
  return {
    type: POST_APPROVALS_MONTHLY_REQUSEDTED,
    token,
    facility_id,
    months,
    filtered_months,
    year
  };
};

// すでに承認されている月を設定（承認エラーモーダルで使用）
export const setAlreadyApprovedMonths = (already_approved_month: any) => {
  return {
    type: SET_ALREADY_APPROVED_MONTH,
    already_approved_month
  };
};

//差し戻し関連
//差し戻しする行事予定の管理
export const toggleToBeRejected = (
  id: number,
  date: string,
  event_type: string,
  event_name: string,
  event_detail: string
) => {
  return {
    type: TOGGLE_TO_BE_REJECTED,
    id,
    date,
    event_type,
    event_name,
    event_detail
  };
};

export const toggleToBeRejectedAll = (event_list: []) => {
  return {
    type: TOGGLE_TO_BE_REJECTED_ALL,
    event_list
  };
};

// 差し戻しリストリセット（管理者）
export const resetToBeRejected = () => {
  return {
    type: RESET_TO_BE_REJECTED
  };
};

//差し戻しコメント入力欄管理
export const setRejectComment = (id: number, comment: string) => {
  return {
    type: SET_REJECT_COMMENT,
    id,
    comment
  };
};

//差し戻しコメントリセット
export const resetRejectComment = () => {
  return {
    type: RESET_REJECT_COMMENT
  };
};

export const setRejectCommentForAll = (updated_comment: string) => {
  return {
    type: SET_REJECT_COMMENT_FOR_ALL,
    updated_comment
  };
};

//差し戻し投稿
export const postRejectRequest = (
  token: string,
  event_id: number,
  comment: string,
  facility_id: number,
  year: any
) => {
  return {
    type: POST_REJECT_REQUESTED,
    token,
    event_id,
    comment,
    facility_id,
    year
  };
};
export const addToBeDeletedReject = (daily_event: any) => {
  return {
    type: ADD_TO_BE_DELETE_REJECT,
    daily_event
  };
};

//差し戻し削除リクエスト
export const deleteRejectRequest = (
  token: string,
  facility_id: number,
  reject_id: number,
  year: number,
  month: [],
  classes: []
) => {
  return {
    type: DELETE_REJECT_REQUESTED,
    token,
    facility_id,
    reject_id,
    year,
    month,
    classes
  };
};

// 差し戻し月の指定
export const setSelectedMonthToApproval = (selected_month: any) => {
  return {
    type: SET_SELECTED_MONTH_TO_APPROVAL,
    selected_month
  };
};

export const setMonthToCancelAll = (
  month_to_be_canceled_all: string | null
) => {
  return {
    type: SET_MONTH_TO_CANCEL_ALL,
    month_to_be_canceled_all
  };
};

export const cancelAllEventsRequest = (
  token: string,
  facility_id: number,
  month_to_be_canceled_all: string
) => {
  return {
    type: CANCEL_ALL_EVENTS_REQUESTED,
    token,
    facility_id,
    month_to_be_canceled_all
  };
};

// 一括年間差し戻し postRejectMonthlyRequest
export const cancelYearMonthsEventsRequest = (
  token: string,
  facility_id: number,
  year_months: string[],
  filtered_year: number,
  filtered_months: string[]
) => {
  return {
    type: POST_REJECT_YEAR_MONTHLY_REQUESTED,
    token,
    facility_id,
    year_months,
    filtered_year,
    filtered_months
  };
};
