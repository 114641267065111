import React, { useState } from "react";
import styled from "styled-components";
import color from "../../../mixins/color";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { setRawEditContent } from "../../../../store/faq/article_edit/action";

function WysiwygEditor(props: any) {
  const dispatch = useDispatch();

  // const article = useSelector((state: any) => state.article_detail);
  const content = convertFromRaw(JSON.parse(props.content));
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(content)
  );
  return (
    <WrapperSC>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="hide-toolbar"
        editorStyle={{ minHeight: "200px" }}
        toolbar={undefined}
        readOnly={true}
      />
    </WrapperSC>
  );
}

export default WysiwygEditor;

const WrapperSC = styled.div`
  span,
  li {
    letter-spacing: 2px;
  }
  span {
    line-height: 1.8;
  }
  .hide-toolbar {
    display: none !important;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    div {
      margin: 0;
    }
    span {
      line-height: 1.6;
    }
  }
  h2 {
    letter-spacing: 6px;
    padding-left: 10px;
    border-left: 10px solid ${color.wysiwyg_border};
  }
  h3 {
    letter-spacing: 4px;
    padding: 0 0 5px 10px;
    border-bottom: 1px solid ${color.wysiwyg_border};
  }
  h4 {
    letter-spacing: 3px;
    position: relative;
    padding-left: 15px;
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      display: block;
      background-color: ${color.wysiwyg_border};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  .public-DraftStyleDefault-orderedListItem {
    line-height: 1.6;
    margin-left: 2.1em;
    span {
      line-height: 1.6;
    }
  }
  .public-DraftStyleDefault-unorderedListItem {
    font-size: 14px;
    line-height: 1.6;
    span {
      line-height: 1.6;
    }
  }
  .DraftEditor-root {
    a {
      color: ${color.primary};
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
      span {
        color: ${color.primary};
      }
    }
  }
  .rdw-link-decorator-wrapper {
    img {
      display: none;
    }
  }
`;
