import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import close from "../../../images/icons/close.svg";
import color from "../../mixins/color";
import { pc } from "../../mixins/mediaQuery";
import { containerBaseStyle, buttonBaseStyle } from "../../mixins/common";
import { toggleCsvModal } from "../../../store/common/modal/action";
import { csvUploadRequest } from "../../../store/faq/csv/action";

export default function ConfirmationModal(props: any) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const file_name = useSelector((state: any) => state.csv.fileName);
  const isAll = useSelector((state: any) => state.csv.isAll);
  const file_data = useSelector((state: any) => state.csv.file_data);
  const year = useSelector((state: any) => state.csv.year);
  const facility_id = useSelector((state: any) => state.facility.id);
  const facility_name = useSelector((state: any) => state.facility.name);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file_name", file_name);
    formData.append("facility_id", facility_id);
    formData.append("all", isAll);
    formData.append("year", year);
    formData.append("file", file_data);
    console.log("file_data", file_data);
    console.log("formData", ...formData.entries());
    dispatch(csvUploadRequest(token, formData));
  };

  return (
    <ModalBgSC onClick={() => dispatch(toggleCsvModal(false))}>
      <ModalContainerSC onClick={e => e.stopPropagation()}>
        <ModalHeaderSC>
          <ModalHeadingSC>CSVアップロード</ModalHeadingSC>
          <ModalCloseBtnSC
            src={close}
            alt="close"
            onClick={() => dispatch(toggleCsvModal(false))}
          />
        </ModalHeaderSC>
        <ModalContentSC>
          <ModalDescSC>
            選択したCSVを{facility_name}に反映しますがよろしいですか？
          </ModalDescSC>
          <ModalCautionSC>
            ※現在のデータは上書きされます。この操作は取り消せません。
          </ModalCautionSC>
          <ModalButtonWrapperSC>
            <ModalCancelBtnSC onClick={() => dispatch(toggleCsvModal(false))}>
              キャンセル
            </ModalCancelBtnSC>
            <ModalDeleteBtnSC type="submit" onClick={handleSubmit}>
              アップロード
            </ModalDeleteBtnSC>
          </ModalButtonWrapperSC>
        </ModalContentSC>
      </ModalContainerSC>
    </ModalBgSC>
  );
}

const ModalBgSC = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: ${color.modal_bg};
  color: ${color.primary};
`;

const ModalContainerSC = styled.div`
  ${containerBaseStyle}
  padding: 0;
  width: 600px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
`;

const ModalHeaderSC = styled.div`
  width: 100%;
  background-color: ${color.primary_white};
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeadingSC = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const ModalCloseBtnSC = styled.img`
  display: inline-block;
  cursor: pointer;
`;

const ModalContentSC = styled.div`
  padding: 30px 54px;
`;

const ModalDescSC = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const ModalCautionSC = styled.p`
  font-size: 12px;
  text-align: center;
  margin-bottom: 25px;
`;

const ModalButtonWrapperSC = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalCancelBtnSC = styled.p`
  width: 160px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  color: ${color.primary};
  font-size: 14px;
  font-weight: bold;
  background-color: ${color.white};
  border: 1px solid ${color.primary};
  cursor: pointer;
  margin-right: 12px;
  padding: 10px;
  ${pc`
    height: 38px;
  `}
  &:hover {
    opacity: 0.6;
  }
`;

const ModalDeleteBtnSC = styled.button`
  display: block;
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
  width: 160px;
  height: 44px;
  border-radius: 4px;
  text-align: center;
  color: ${color.white};
  font-size: 14px;
  font-weight: bold;
  background-color: ${color.gray};
  ${pc`
    height: 38px;
  `}
  &:hover {
    opacity: 0.6;
  }
`;
