import React from "react";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import styled from "styled-components";
import { color } from "components/mixins";
import ManualsEditableItem from "./ManualsEditableItem";

interface Props {
  hookedManuals: ManualItemForSelector[];
  updateManualTitle: (data: { order: number; new_name: string }) => void;
  handleAdd: (hookedManuals: ManualItemForSelector[]) => void;
  removeHookedItem: (data: { manual: ManualItemForSelector }) => void;
}

function ManualsEditableList(props: Props) {
  const { hookedManuals, updateManualTitle, handleAdd, removeHookedItem } =
    props;
  return (
    <>
      <ManualsAdminListSC>
        {hookedManuals.map((manual: any, index: number) => {
          return (
            <ManualsEditableItem
              key={index}
              manual={manual}
              updateManualTitle={updateManualTitle}
              removeHookedItem={removeHookedItem}
            />
          );
        })}
      </ManualsAdminListSC>
      <AddButtonSC onClick={() => handleAdd(hookedManuals)}>＋</AddButtonSC>
    </>
  );
}

export default ManualsEditableList;

const ManualsAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;

const AddButtonSC = styled.button`
  width: 33px;
  height: 33px;
  margin: 0 auto;
  line-height: 33px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid ${color.list_border};
  color: ${color.black};
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
  text-align: center;
  border-radius: 50%;
`;
