import styled from "styled-components";
import { color } from "../../mixins";

export const AccordionBoxDomStyled = styled.div``;

export const AccordionBoxDomStyledBody = styled.div``;

export const AccordionBoxDomStyledHheader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  line-height: ${21 / 14};
  cursor: pointer;
  background-color: ${color.white};
`;

export const AccordionBoxDomStyledHeaderText = styled.p`
  position: relative;
  min-width: 3em;
  text-align: center;
  user-select: none;
  color: ${color.gray};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    content: "閉じる";
    opacity: 0;
  }

  ${`${AccordionBoxDomStyled}.open`} & {
    color: transparent;

    &::after {
      color: ${color.gray};
      opacity: 1;
    }
  }
`;

export const AccordionBoxDomStyledHeaderIcon = styled.div`
  width: ${24 / 14}em;
  transition: all 0.15s ease;

  svg {
    width: 100%;
  }

  ${`${AccordionBoxDomStyled}.open`} & {
    transform: scale(1, -1);
  }
`;

export const AccordionBoxDomStyledMain = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease;

  ${AccordionBoxDomStyled}.open &,
  ${AccordionBoxDomStyled}.animation & {
    overflow: visible;
    transition: height 0.15s ease, overflow 0 ease 0.15s;
  }
`;

export const AccordionBoxDomStyledMainInner = styled.div`
  margin-top: 1px;
  background-color: ${color.white};
`;
