import { pc } from "components/mixins/mediaQuery";
import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";

interface Props {
  blockTitle: string;
}

const BlockHeader = (props: Props) => {
  return <BlockHeaderSC>{props.blockTitle}</BlockHeaderSC>;
};

export default BlockHeader;

const BlockHeaderSC = styled.h2`
  color: ${color.black};
  font-size: 16px;
  margin-bottom: 0px;
  padding: 25px 16px;
  ${pc`
    padding: 0px;
    margin-bottom: 20px;
  `}
`;
