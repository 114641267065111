import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import ReturnEventsModalContent from "./ReturnEventsModalContent";

import { toggleReturnEventModal } from "../../../../store/common/modal/action";

export default function ReturnEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <ReturnEventsModalBg
        onClick={() => dispatch(toggleReturnEventModal(false))}
      ></ReturnEventsModalBg>
      <ReturnEventsModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ReturnEventsModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
