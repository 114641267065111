import React from "react";
import { TopicItem } from "store/manual/manuals/topics/types.d";
import styled from "styled-components";
import { color } from "components/mixins";
import TopicsEditableItem from "./TopicsEditableItem";

interface Props {
  hookedTopics: TopicItem[];
  updateTopicTitle: (data: { order: number; new_name: string }) => void;
  handleAdd: (hookedTopics: TopicItem[]) => void;
  removeHookedItem: (data: { topic: TopicItem }) => void;
}

function TopicsEditableList(props: Props) {
  const { hookedTopics, updateTopicTitle, handleAdd, removeHookedItem } = props;
  return (
    <>
      <TopicsAdminListSC>
        {hookedTopics.map((topic: any, index: number) => {
          return (
            <TopicsEditableItem
              key={index}
              topic={topic}
              updateTopicTitle={updateTopicTitle}
              removeHookedItem={removeHookedItem}
            />
          );
        })}
      </TopicsAdminListSC>
      <AddButtonSC onClick={() => handleAdd(hookedTopics)}>＋</AddButtonSC>
    </>
  );
}

export default TopicsEditableList;

const TopicsAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;

const AddButtonSC = styled.button`
  width: 33px;
  height: 33px;
  margin: 0 auto;
  line-height: 33px;
  font-size: 13px;
  font-weight: bold;
  border: 1px solid ${color.list_border};
  color: ${color.black};
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
  text-align: center;
  border-radius: 50%;
`;
