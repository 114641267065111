import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc, minSp } from "../../mixins/mediaQuery";
import { useLocation } from "react-router-dom";
import { color } from "../../mixins";
import selectArrow from "../../../images/icons/多角形_1.svg";
import {
  getArticlesRequest,
  getArticlesForUserRequest,
} from "../../../store/faq/articles/action";
import { setPerPage } from "../../../store/common/paginate/action";
import pageNumControl from "../../Common/PaginationPageControl";
import { animateScroll as scroll } from "react-scroll";

function ArticlesPagination(props: any) {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);

  const location = useLocation();
  const pathName = location.pathname;
  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);

  const handlePageNumClick = (e: any, page_num: number) => {
    if (isAdmin || isEditor) {
      dispatch(
        getArticlesRequest(
          token,
          pathName,
          page_num,
          paginate_per_page,
          categories_to_be_searched,
          tags_to_be_searched,
          keyword
        )
      );
    } else {
      dispatch(
        getArticlesForUserRequest(
          token,
          pathName,
          page_num,
          paginate_per_page,
          categories_to_be_searched,
          tags_to_be_searched,
          keyword
        )
      );
    }
    scroll.scrollToTop({ duration: 200 });
  };
  console.log("pathName", pathName);

  let paginate_total = useSelector(
    (state: any) => state.paginate.article_total
  );
  const paginate_current_page = useSelector(
    (state: any) => state.paginate.article_current_page
  );
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.article_per_page
  );

  const categories_to_be_searched = useSelector(
    (state: any) => state.categories_to_be_searched
  );
  const tags_to_be_searched = useSelector(
    (state: any) => state.tags_to_be_searched
  );
  const keyword = useSelector((state: any) => state.paginate.article_keyword);

  // ページネーションの数字の数
  const pagenate_num_array_count = Math.floor(
    paginate_total / paginate_per_page
  );
  const rest_articles = paginate_total % paginate_per_page;
  const articles_in_rest_page = rest_articles !== 0 ? rest_articles : 0;
  const last_page =
    rest_articles !== 0
      ? pagenate_num_array_count + 1
      : pagenate_num_array_count;

  const PageNumItems: any = [];

  for (let step = 1; step <= pagenate_num_array_count; step++) {
    PageNumItems.push(
      <PageNum
        className={paginate_current_page === step ? "current" : ""}
        onClick={(e: any) => handlePageNumClick(e, step)}
      >{`${step.toString()}`}</PageNum>
    );
  }

  if (articles_in_rest_page !== 0) {
    PageNumItems.push(
      <PageNum
        className={
          paginate_current_page === pagenate_num_array_count + 1
            ? "current"
            : ""
        }
        onClick={(e: any) =>
          handlePageNumClick(e, pagenate_num_array_count + 1)
        }
      >{`${(pagenate_num_array_count + 1).toString()}`}</PageNum>
    );
  }

  const pageNumbers = pageNumControl(
    PageNumItems,
    paginate_current_page,
    PageNum,
    handlePageNumClick
  );

  const handlePaginatePerPage = (e: any) => {
    dispatch(setPerPage(Number(e.target.value), "article"));
    if (isAdmin || isEditor) {
      dispatch(
        getArticlesRequest(
          token,
          pathName,
          1,
          Number(e.target.value),
          categories_to_be_searched,
          tags_to_be_searched,
          keyword
        )
      );
    } else {
      dispatch(
        getArticlesForUserRequest(
          token,
          pathName,
          1,
          Number(e.target.value),
          categories_to_be_searched,
          tags_to_be_searched,
          keyword
        )
      );
    }
  };

  return (
    <PaginationContainer className={props.position}>
      <PaginationRightBlock>
        <PaginationInfo>
          <>
            全<span>{paginate_total}</span>件中
            <span>
              {paginate_current_page === 1
                ? "1"
                : (paginate_current_page - 1) * paginate_per_page + 1}
            </span>
            〜
            <span>
              {paginate_current_page !== last_page
                ? paginate_current_page * paginate_per_page
                : (last_page - 1) * paginate_per_page + articles_in_rest_page}
            </span>
            件を表示
          </>
        </PaginationInfo>
        <PageNums>
          {paginate_current_page !== 1 && (
            <PageNum
              onClick={(e: any) =>
                handlePageNumClick(e, paginate_current_page - 1)
              }
            >
              前へ
            </PageNum>
          )}
          <>
            {pageNumbers.map((item: any) => {
              return item;
            })}
          </>
          {paginate_current_page !== last_page && (
            <PageNum
              onClick={(e: any) =>
                handlePageNumClick(e, paginate_current_page + 1)
              }
            >
              次へ
            </PageNum>
          )}
        </PageNums>
      </PaginationRightBlock>
      <PaginationLeftBlock>
        <ShowPagesSelect
          className="select"
          name="paginate_per_page"
          id="paginate_per_page"
          value={paginate_per_page}
          onChange={handlePaginatePerPage}
        >
          <option value="20">20件表示</option>
          <option value="50">50件表示</option>
          <option value="100">100件表示</option>
        </ShowPagesSelect>
      </PaginationLeftBlock>
    </PaginationContainer>
  );
}

export default ArticlesPagination;

const PaginationContainer = styled.div`
  font-size: 12px;
  color: ${color.gray};
  margin-bottom: 12px;
  padding: 0 16px;
  ${minSp`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
  ${pc`
    align-items: center;
    padding: 0;
  `}
  &.bottom {
    margin-bottom: 0px;
    padding-top: 12px;
  }
`;

const PaginationRightBlock = styled.div`
  margin-bottom: 12px;
  ${minSp`
    margin-bottom: 0;
  `}
  ${pc`
    display: flex;
  `}
`;

const PaginationInfo = styled.p`
  margin-bottom: 12px;
  padding-top: 5px;
  ${pc`
    margin-bottom: 0;
    margin-right: 20px;
  `}
  span {
    color: ${color.gray};
    margin: 0 4px;
  }
`;

const PageNums = styled.ul`
  display: flex;
`;
const PageNum = styled.li`
  padding: 5px 7px;
  margin-right: 6px;
  cursor: pointer;
  color: ${color.primary};
  &.current {
    color: ${color.white};
    border-radius: 50%;
    background-color: ${color.primary};
  }
  &.leader {
    padding: 5px 0px;
  }
`;

const PaginationLeftBlock = styled.div``;

const ShowPagesSelect = styled.select`
  border: solid 1px ${color.primary};
  height: 26px;
  width: 96px;
  padding: 5px 7px;
  border-radius: 14px;
  font-size: 12px;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: right 7px top 9px;
  color: ${color.gray};
  ${pc`
    height: 22px;
    padding: 5px 7px;
    border-radius: 11px;
    font-size: 10px;
    background-position: right 7px top 7px;
  `}
`;
