import React from "react";
import styled from "styled-components";
import { Page } from "../demoData";
import color from "../../mixins/color";
import PagesItem from "./PagesItem";
import { pc } from "components/mixins/mediaQuery";

function PagesList(props: { pages: Page[]; manualId: number }) {
  const { pages, manualId } = props;
  return (
    <PagesListSC>
      {/* 既存のデータ */}
      {pages &&
        pages.map((value, index) => {
          const { id, topic_id, title, content, updated_at, tags, status } =
            value;
          return (
            <>
              {/* {ユーザー側} */}
              {/* 非公開ステータス(1)は公開しない */}
              {/* 公開ステータス(2)は公開する */}
              {/* 下書きステータス(3)は公開する */}
              {status !== 1 && (
                <PagesItem
                  key={id}
                  id={id}
                  manual_id={manualId}
                  topic_id={topic_id}
                  title={title}
                  content={content}
                  updated_at={updated_at}
                  tags={tags}
                />
              )}
            </>
          );
        })}
    </PagesListSC>
  );
}

export default PagesList;

const PagesListSC = styled.ul`
  border-top: 1px solid ${color.list_border};
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    border: 1px solid ${color.list_border};
    border-radius: 4px;
  `}
`;
