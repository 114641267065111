import React, { useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import axios, { AxiosResponse } from "axios";
import { env_manual_url } from "serverEnv";
import { Page } from "store/manual/manuals/pages/types.d";
import { color, buttonBaseStyle } from "components/mixins";
import { pc } from "components/mixins/mediaQuery";
import { Link, useParams } from "react-router-dom";

import {
  savePageDetailRequest,
  setPageDetailAction,
  setPageDetailEditMode
} from "store/manual/manuals/pages/actions";
import { manualPageDetailOnEditSelector } from "store/manual/manuals/pages/selectors";
import { history } from "store/store";
import { pageTagsSelector } from "store/manual/manuals/selectors";

const TinyCMEEditor = React.memo(
  (props: {
    storedPageDetail: Page;
    pageTagIds: { id: number }[];
    pageTitle: string;
  }) => {
    const { pageTagIds, pageTitle } = props;
    const editorAPIKey = process.env.REACT_APP_TINY_MCE_API_KEY || "";

    const dispatch = useDispatch();

    const editorRef = useRef<any>(null);

    const token = useSelector((state: any) => state.session.token);
    const storedPageTags = useSelector(pageTagsSelector);

    const storedPage = useSelector(manualPageDetailOnEditSelector);

    const handleChange = (value: any, event: any) => {
      console.log("value", value);
      console.log("event", event);
      dispatch(
        setPageDetailAction({
          ...storedPage,
          content_draft: value
        })
      );
    };
    const { manualId, topicId, pageId } =
      useParams<{ manualId: string; topicId: string; pageId: string }>();
    const requestSave = async () => {
      if (editorRef == null) return;
      if (editorRef.current == null) return;
      console.log(editorRef.current.getContent());
      // ページ編集モードをオフ
      dispatch(setPageDetailEditMode(false));
      history.push(`/manual/admin/manuals/${manualId}/topics/${topicId}`);
      // 保存処理
      dispatch(
        savePageDetailRequest({
          token,
          id: storedPage.id,
          manual_id: Number(manualId),
          req_body: {
            topic_id: storedPage.topic_id,
            order: storedPage.order,
            title: pageTitle,
            status: storedPage.status,
            tags:
              storedPageTags &&
              storedPageTags.map((tag: any) => {
                return { id: tag.id };
              }),
            history_flag: true,
            content: storedPage.content_draft
          }
        })
      );
    };

    return (
      <>
        <Editor
          apiKey={editorAPIKey}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onEditorChange={handleChange}
          // initialValue={
          //   storedPage && storedPage.content ? storedPage.content : ""
          // }
          value={
            storedPage && storedPage.content_draft
              ? storedPage.content_draft
              : ""
          }
          init={{
            height: 500,
            menubar: "insert edit view format tools table",
            plugins: [
              "visualblocks advlist autolink lists link image imagetools charmap print preview anchor table",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount"
            ],
            // toolbar: "visualblocks | undo redo | formatselect | styleselect " + "bold italic backcolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | help" + "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
            toolbar:
              "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
            formats: {
              alignleft: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video",
                classes: "left"
              },
              aligncenter: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video",
                classes: "center"
              },
              alignright: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video",
                classes: "right"
              }
            },
            style_formats: [
              { title: "文章フォーマット" },
              { title: "p(標準)", block: "p" },
              { title: "左寄せ", format: "alignleft" },
              { title: "中央寄せ", format: "aligncenter" },
              { title: "右寄せ", format: "alignright" },
              { title: "見出しフォーマット" },
              { title: "h1", block: "h1" },
              { title: "h2", block: "h2" },
              { title: "h3", block: "h3" },
              { title: "h4", block: "h4" },
              { title: "h5", block: "h5" },
              { title: "h6", block: "h6" },
              {
                title: "Badge",
                inline: "span",
                styles: {
                  display: "inline-block",
                  border: "1px solid #2276d2",
                  "border-radius": "5px",
                  padding: "2px 5px",
                  margin: "0 2px",
                  color: "#2276d2"
                }
              },
              { title: "Table row 1", selector: "tr", classes: "tablerow1" },
              { title: "画像フォーマット" },
              {
                title: "画像中央",
                selector: "img",
                styles: {
                  display: "block",
                  float: "none",
                  margin: "0 auto"
                }
              },
              {
                title: "画像寄せリセット",
                selector: "img",
                styles: {
                  float: "none",
                  margin: "0"
                }
              },
              {
                title: "画像左寄せ",
                selector: "img",
                styles: {
                  float: "left",
                  margin: "0 10px 0 10px"
                }
              },
              {
                title: "画像右寄せ",
                selector: "img",
                styles: {
                  float: "right",
                  margin: "0 0 10px 10px"
                }
              }
            ],

            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            file_picker_types: "file image",
            images_upload_handler: async function (
              blobInfo: any,
              success: any,
              failure: any
            ) {
              let xhr: any, formData: any;

              xhr = new XMLHttpRequest();
              xhr.withCredentials = false;

              xhr.open("POST", `${env_manual_url}/contents/image`);
              xhr.setRequestHeader("Authorization", `Bearer ${token}`);
              xhr.onload = function () {
                var json;

                if (xhr.status !== 200) {
                  failure("HTTP Error: " + xhr.status);
                  return;
                }

                console.log("xhr.responseText", xhr.responseText);
                console.log("typeof xhr.responseText", typeof xhr.responseText);
                json = JSON.parse(xhr.responseText);
                console.log("json", json);

                if (!json || typeof json.path != "string") {
                  failure("Invalid JSON: " + xhr.responseText);
                  return;
                }

                success(json.path);
              };

              formData = new FormData();
              formData.append("file", blobInfo.blob(), blobInfo.filename());
              formData.append("manual_id", 1);
              formData.append("file_name", blobInfo.filename());
              formData.append("alt", blobInfo.filename());

              xhr.send(formData);
            }
          }}
        />
      </>
    );
  }
);

export default TinyCMEEditor;
