import { Link } from "react-router-dom";
import styled from "styled-components";

export const AlbumListSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 30px;
`;

export const CardSC = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
`;

export const CardLinkSC = styled(Link)`
  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;
export const CardWrapperSC = styled.a`
  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

export const CardContainerSC = styled.div`
  margin-bottom: 4px;
  position: relative;
  width: 169px;
  height: 204px;
  display: block;
  text-align: center;
`;

export const CardImgSC = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

export const CardNumbersSC = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5%;
  color: #ffffff;
  display: -webkit-inline-box;
`;

export const CardNumberSC = styled.div`
  margin-right: 4px;
`;

export const CradNumberImgSC = styled.img`
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

export const CardListsSC = styled.div`
  font-family: Poppins;
  overflow-wrap: break-word;
  inline-size: 168px;
`;

export const CardTagSC = styled.p`
  color: #848484;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 600;
  line-height: 21px;
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CardTagNameSC = styled.span`
  margin-right: 11px;
`;

export const CardTitleSC = styled.p`
  color: #848484;
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 21px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 21px;
`;

export const CardFacilitiesSC = styled.span`
  margin-right: 18px;
`;

export const CardFileNameSC = styled.p`
  color: #393939;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 14px;
`;

export const CardCheckBoxWrapperSC = styled.div`
  position: absolute;
  z-index: 3;
  right: 8px;
  top: 8px;
`;
