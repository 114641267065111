import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";

import { toggleServiceModal } from "../../../store/common/modal/action";

export default function ServiceSelectNav() {
  const dispatch = useDispatch();
  return (
    <ServiceSelectNuvButton onClick={() => dispatch(toggleServiceModal(true))}>
      サービス選択
    </ServiceSelectNuvButton>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ServiceSelectNuvButton = styled.div`
  background-color: #444444;
  padding: 9px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  max-width: 92px;
  width: 100%;
  height: 30px;
  text-align: center;
  ${pc`
  max-width: 112px;
  margin-right: 60px;
  `}
  &:hover {
    cursor: pointer;
  }
`;
