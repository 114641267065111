import { AnyAction } from "redux";
import {
  GET_HISTORIES_SUCCEEDED,
  UPDATE_HISTORY_SUCCEEDED,
  UPDATE_ALL_HISTORIES_SUCCEEDED,
  GET_HISTORIES_FAILED
} from "./types";

interface History {
  id: number;
  type: number;
  manual_name: string;
  changed_at: Date;
  priority: boolean;
}

const initialState: History[] = [];

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_HISTORIES_SUCCEEDED:
      return (state = action.histories.data.histories);
    case GET_HISTORIES_FAILED:
      console.log("error", action.error);
      return state;
    case UPDATE_HISTORY_SUCCEEDED:
      return state;
    case UPDATE_ALL_HISTORIES_SUCCEEDED:
      return state;
    default:
      return state;
  }
};

export default reducer;
