import React from 'react';
import styled from 'styled-components';
import {containerBaseStyle} from '../mixins'
import SignInGSuite from './SignInGsuite'
import { pc, minSp } from "../../components/mixins/mediaQuery";
import SignInForm from './SignInForm'

const SignInBox = () => {
  return (
    <SignInContainerSC>
      <SignInGSuite/>
      <SignInForm />
    </SignInContainerSC>
  );
};

export default SignInBox;

const SignInContainerSC = styled.div`
  ${containerBaseStyle}
  padding: 40px 16px;
  height: auto;
  text-align: center;
  ${minSp`
    padding: 40px;
  `}
  ${pc`
    padding: 40px 60px;
  `}
` 