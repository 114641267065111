export const contactPathGen = (contactId: number | null): string => {
  console.log("contactId", contactId);
  switch (contactId) {
    case 1:
      return "it";
    case 2:
      return "unei";
    case 3:
      return "jinji-somu";
    case 4:
      return "uncertain";
    default:
      return "";
  }
};
export const kindPathGen = (kindId: number): string => {
  console.log("kindId", kindId);
  switch (kindId) {
    case 1:
      return "staff";
    case 2:
      return "customer";
    case 3:
      return "other";
    case 4:
      return "attendance";
    case 5:
      return "salary-bonus";
    case 6:
      return "year-end-adjustment";
    case 7:
      return "other";
    default:
      return "";
  }
};
