import { CLEAR_SYSTEM_CACHE } from "store/session/type";
import { GET_FACILITIES_ME_FAILED, GET_FACILITIES_ME_SUCCEEDED } from "./type";

export interface FacilitiesMeState {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

const initialState: FacilitiesMeState[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_FACILITIES_ME_SUCCEEDED:
      return [...action.facilities_me];
    case GET_FACILITIES_ME_FAILED:
      return [...state];
    case CLEAR_SYSTEM_CACHE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
