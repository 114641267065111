import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import CancelYearMonthsEventsModalContent from "./CancelYearMonthsEventsModalContent";

import { toggleCancelYearMonthsEventsModal } from "../../../../store/common/modal/action";

export default function CancelYearMontlyEventsModal() {
  const dispatch = useDispatch();
  return (
    <>
      <ModalBg
        onClick={() => dispatch(toggleCancelYearMonthsEventsModal(false))}
      ></ModalBg>
      <CancelYearMonthsEventsModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
