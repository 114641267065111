import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";
import reject_icon from "../../../images/icons/exclamation_red.svg";
import LocalTimeCustomizerForEvents from "../../Common/LocaleTimeCustomizerForEvents";

function EventsTopItem(props: any) {
  //props.additional_information[index]のdestinationを'／'で区切って文字列にする
  const multipleDetail = props.additional_information
    ? props.additional_information
        .map((item: any) => item.destination)
        .join("／")
    : undefined;

  //props.additional_information[index]のclassを','で区切って文字列にする
  const multipleClasses = props.additional_information
    ? props.additional_information.map((item: any) => item.class).join("／")
    : undefined;

  return (
    <EventsTopItemSC>
      <EventsTopItemContent className="date">
        {props.date !== "" ? LocalTimeCustomizerForEvents(props.date) : ""}
      </EventsTopItemContent>

      {/* 行事名 */}
      {props.title && props.title.length >= 7 ? (
        <>
          <EventsTopItemContent className="title pointer">
            {props.title.slice(0, 6) + "..."}
          </EventsTopItemContent>
          <EventsTopItemHiddenContent className="title">
            {props.title}
          </EventsTopItemHiddenContent>
        </>
      ) : (
        <EventsTopItemContent className="title">
          <p>{props.title}</p>
        </EventsTopItemContent>
      )}

      {/* 行事内容 */}
      {props.content && props.content.length >= 9 ? (
        <>
          <EventsTopItemContent className="content pointer">
            {props.content.slice(0, 8) + "..."}
          </EventsTopItemContent>
          <EventsTopItemHiddenContent className="content">
            {props.content}
          </EventsTopItemHiddenContent>
        </>
      ) : (
        <EventsTopItemContent className="content">
          <p>{props.content}</p>
        </EventsTopItemContent>
      )}

      {/* 詳細（目的地） */}
      {props.detail && !multipleDetail && (
        <>
          {props.detail.length >= 10 ? (
            <>
              <EventsTopItemContent className="detail pointer">
                {props.detail.slice(0, 9) + "..."}
              </EventsTopItemContent>
              <EventsTopItemHiddenContent className="detail">
                {props.detail}
              </EventsTopItemHiddenContent>
            </>
          ) : (
            <EventsTopItemContent className="detail">
              <p>{props.detail}</p>
            </EventsTopItemContent>
          )}
        </>
      )}

      {/* 複数入力の詳細（目的地） */}
      {multipleDetail && (
        <>
          {multipleDetail.length >= 10 ? (
            <>
              <EventsTopItemContent className="detail pointer">
                {multipleDetail.slice(0, 9) + "..."}
              </EventsTopItemContent>
              <EventsTopItemHiddenContent className="detail">
                {multipleDetail}
              </EventsTopItemHiddenContent>
            </>
          ) : (
            <EventsTopItemContent className="detail">
              <p>{multipleDetail}</p>
            </EventsTopItemContent>
          )}
        </>
      )}

      {/* 詳細（目的地）が存在しない場合 */}
      {!props.detail && !multipleDetail && (
        <EventsTopItemContent className="detail" />
      )}

      {/* 対象クラス */}
      {props.class_age && !multipleClasses && (
        <>
          {props.class_age.length >= 4 ? (
            <>
              <EventsTopItemContent className="class_age pointer">
                {props.class_age.slice(0, 3) + "..."}
              </EventsTopItemContent>
              <EventsTopItemHiddenContent className="class_age">
                {props.class_age}
              </EventsTopItemHiddenContent>
            </>
          ) : (
            <EventsTopItemContent className="class_age">
              <p>{props.class_age}</p>
            </EventsTopItemContent>
          )}
        </>
      )}

      {/* 複数入力の対象クラス */}
      {multipleClasses && (
        <>
          {multipleClasses.length >= 4 ? (
            <>
              <EventsTopItemContent className="class_age pointer">
                {multipleClasses.slice(0, 3) + "..."}
              </EventsTopItemContent>
              <EventsTopItemHiddenContent className="class_age">
                {multipleClasses}
              </EventsTopItemHiddenContent>
            </>
          ) : (
            <EventsTopItemContent className="class_age">
              <p>{multipleClasses}</p>
            </EventsTopItemContent>
          )}
        </>
      )}

      {/* 対象クラスが存在しない場合 */}
      {!props.class_age && !multipleClasses && (
        <EventsTopItemContent className="class_age" />
      )}

      {/* 差し戻しアイコン */}
      {props.editing_status === 2 || props.remand.length > 0 ? (
        <RejectedIcon src={reject_icon} />
      ) : (
        ""
      )}
    </EventsTopItemSC>
  );
}

export default EventsTopItem;

const EventsTopItemSC = styled.li`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  padding: 25px 59px 21px;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
`;

const RejectedIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 20px;
`;

const EventsTopItemContent = styled.div`
  font-size: 14px;
  color: #444;
  flex-shrink: 0;
  &:hover + li {
    display: block;
  }
  &.date {
    width: calc(14%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
`;

const EventsTopItemHiddenContent = styled.div`
  display: none;
  position: absolute;
  top: 12px;
  left: 4px;
  border: 1px solid ${color.light_gray};
  border-radius: 4px;
  padding: 11px;
  background-color: ${color.white};
  font-size: 14px;
  color: ${color.gray};
  z-index: 100;
  &.title {
    left: calc(57px + (100%) * 0.13 + 5px);
    &.hover {
      display: block;
    }
  }
  &.content {
    left: calc(57px + (100% - 77px) * 0.32 + 7px);
  }
  &.detail {
    left: calc(57px + (100% - 77px) * 0.54 + 7px);
  }
  &.class_age {
    left: calc(57px + (100% - 77px) * 0.81 + 7px);
  }
  &:hover {
    display: block;
  }
`;
