import {
  TOGGLE_FACILITY_MODAL,
  TOGGLE_SERVICE_MODAL,
  TOGGLE_DELETE_EVENT_MODAL,
  TOGGLE_ADD_EVENT_MODAL,
  TOGGLE_EDIT_EVENT_MODAL,
  TOGGLE_DELETE_MONTHLY_EVENT_MODAL,
  TOGGLE_ADD_MONTHLY_EVENT_MODAL,
  TOGGLE_EDIT_MONTHLY_EVENT_MODAL,
  TOGGLE_CSV_MODAL,
  TOGGLE_APPROVAL_MODAL,
  TOGGLE_MONTHLY_APPROVAL_MODAL,
  TOGGLE_RETURN_EVENT_MODAL,
  TOGGLE_DELETE_RETURN_EVENT_MODAL,
  TOGGLE_AUTHORITY_ERROR_MODAL,
  TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL,
  TOGGLE_CSV_DOWNLOAD_REJECT_MODAL,
  TOGGLE_APPROVAL_REQUEST_MODAL,
  TOGGLE_ALREADY_APPROVED_MODAL,
  TOGGLE_CANCEL_ALL_EVENTS_MODAL,
  TOGGLE_DELETE_ARTICLE_MODAL,
  TOGGLE_DELETE_NEWS_MODAL,
  TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL
} from "./type";

export const toggleFacilityModal = (facility_modal_open: boolean) => {
  console.log("facility_modal_open:", facility_modal_open);
  return {
    type: TOGGLE_FACILITY_MODAL,
    facility_modal_open
  };
};

export const toggleServiceModal = (service_modal_open: boolean) => {
  return {
    type: TOGGLE_SERVICE_MODAL,
    service_modal_open
  };
};

export const toggleDeleteArticleModal = (
  delete_article_modal_open: boolean
) => {
  return {
    type: TOGGLE_DELETE_ARTICLE_MODAL,
    delete_article_modal_open
  };
};

export const toggleDeleteNewsModal = (delete_news_modal_open: boolean) => {
  return {
    type: TOGGLE_DELETE_NEWS_MODAL,
    delete_news_modal_open
  };
};

export const toggleDeleteEventModal = (delete_event_modal_open: boolean) => {
  return {
    type: TOGGLE_DELETE_EVENT_MODAL,
    delete_event_modal_open
  };
};

export const toggleAddEventModal = (add_event_modal_open: boolean) => {
  return {
    type: TOGGLE_ADD_EVENT_MODAL,
    add_event_modal_open
  };
};

export const toggleEditEventModal = (edit_event_modal_open: boolean) => {
  return {
    type: TOGGLE_EDIT_EVENT_MODAL,
    edit_event_modal_open
  };
};

export const toggleDeleteMonthlyEventModal = (
  delete_monthly_event_modal_open: boolean
) => {
  return {
    type: TOGGLE_DELETE_MONTHLY_EVENT_MODAL,
    delete_monthly_event_modal_open
  };
};

export const toggleAddMonthlyEventModal = (
  add_monthly_event_modal_open: boolean
) => {
  return {
    type: TOGGLE_ADD_MONTHLY_EVENT_MODAL,
    add_monthly_event_modal_open
  };
};

export const toggleEditMonthlyEventModal = (
  edit_monthly_event_modal_open: boolean
) => {
  return {
    type: TOGGLE_EDIT_MONTHLY_EVENT_MODAL,
    edit_monthly_event_modal_open
  };
};

export const toggleCsvModal = (csv_modal_open: boolean) => {
  return {
    type: TOGGLE_CSV_MODAL,
    csv_modal_open
  };
};

export const toggleAuthorityErrorModal = (
  authority_error_modal_open: boolean
) => {
  return {
    type: TOGGLE_AUTHORITY_ERROR_MODAL,
    authority_error_modal_open
  };
};

export const toggleApprovalModal = (approval_modal_open: boolean) => {
  return {
    type: TOGGLE_APPROVAL_MODAL,
    approval_modal_open
  };
};

export const toggleAlreadyApprovedErrorModal = (
  already_approved_modal_open: boolean
) => {
  return {
    type: TOGGLE_ALREADY_APPROVED_MODAL,
    already_approved_modal_open
  };
};

export const toggleMonthlyApprovalModal = (
  monthly_approval_modal_open: boolean
) => {
  return {
    type: TOGGLE_MONTHLY_APPROVAL_MODAL,
    monthly_approval_modal_open
  };
};

export const toggleReturnEventModal = (return_event_modal_open: boolean) => {
  return {
    type: TOGGLE_RETURN_EVENT_MODAL,
    return_event_modal_open
  };
};

export const toggleDeleteReturnEventModal = (
  delete_return_event_modal_open: boolean
) => {
  return {
    type: TOGGLE_DELETE_RETURN_EVENT_MODAL,
    delete_return_event_modal_open
  };
};

export const toggleAnnualEventScheduleModal = (
  annual_event_schedule_modal_open: boolean
) => {
  return {
    type: TOGGLE_ANNUAL_EVENT_SCHEDULE_MODAL,
    annual_event_schedule_modal_open
  };
};

export const toggleCSVDownloadRejectModal = (
  csv_download_reject_modal_open: boolean
) => {
  return {
    type: TOGGLE_CSV_DOWNLOAD_REJECT_MODAL,
    csv_download_reject_modal_open
  };
};

export const toggleApprovalRequestModal = (
  approval_request_modal_open: boolean
) => {
  return {
    type: TOGGLE_APPROVAL_REQUEST_MODAL,
    approval_request_modal_open
  };
};

export const toggleCancelAllEventsModal = (
  cancel_all_events_modal_open: boolean
) => {
  return {
    type: TOGGLE_CANCEL_ALL_EVENTS_MODAL,
    cancel_all_events_modal_open
  };
};

export const toggleCancelYearMonthsEventsModal = (
  cancel_year_monthly_events_modal_open: boolean
) => {
  return {
    type: TOGGLE_REJECT_YEAR_MONTHLY_EVENTS_MODAL,
    cancel_year_monthly_events_modal_open
  };
};
