import axios, { AxiosResponse } from "axios";
import {
  GetCategoriesResponseBody,
  GetFacilitiesResponseBody,
  GetFaqTagsResponseBody,
  GetPositionsResponseBody,
  GetStatusResponseBody
} from "store/session/types";
import { env_url, env_faq_url, env_v2_url } from "../../serverEnv";

// ログインの処理
const apiLogin = (email: string, password: string) => {
  const bodyData = {
    email: email,
    password: password
  };
  return axios.post(`${env_url}/login`, bodyData);
};

const apiGetStatus = (token: string) => {
  try {
    const result: Promise<AxiosResponse<GetStatusResponseBody>> = axios.get(
      `${env_v2_url}/status`,
      {
        headers: {
          Authorization: `bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log(e.response.data);
  }
};

//ログイン時にポジションのリストを取得
const apiGetPositions = (token: string) => {
  try {
    const result: Promise<AxiosResponse<GetPositionsResponseBody>> = axios.get(
      `${env_v2_url}/positions`,
      {
        headers: {
          Authorization: `bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};

//ログイン時に施設のリストを取得
const apiGetFacilities = (token: string) => {
  try {
    const result: Promise<AxiosResponse<GetFacilitiesResponseBody>> = axios.get(
      `${env_v2_url}/facilities`,
      {
        headers: {
          Authorization: `bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};

// ログイン時に登録済みカテゴリー一覧を取得
const apiGetCategories = (token: string) => {
  try {
    const result: Promise<AxiosResponse<GetCategoriesResponseBody>> = axios.get(
      `${env_faq_url}/categories`,
      {
        headers: {
          Authorization: `bearer ${token}`
        }
      }
    );
    console.log("result", result);
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};

// ログイン時に登録済みタグ一覧を取得
const apiGetTags = (token: string) => {
  try {
    const result: Promise<AxiosResponse<GetFaqTagsResponseBody>> = axios.get(
      `${env_faq_url}/tags`,
      {
        headers: {
          Authorization: `bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error", e);
  }
};

// ログアウトの処理
const apiLogout = (token: string) => {
  try {
    const result: Promise<AxiosResponse<{ message: string }>> = axios.post(
      `${env_url}/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.response.data);
  }
};

const apiUpdateSystemStatus = (token: string, system_status: number) => {
  try {
    const result: Promise<AxiosResponse<{ status: number }>> = axios.post(
      `${env_url}/status`,
      {
        system_status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.response.data);
  }
};

const googleLoginApi = (code: string) => {
  return axios.post(`${env_url}/google/login`, { code: code });
};

// ログイン時に登録済みカテゴリー一覧を取得
function* getCategoriesApiAfterPut(token: string) {
  try {
    const result = yield axios.get(`${env_faq_url}/categories`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// ログイン時に登録済みタグ一覧を取得
function* getTagsApiAfterPut(token: string) {
  try {
    const result = yield axios.get(`${env_faq_url}/tags`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

export {
  apiLogin,
  apiLogout,
  apiGetStatus,
  apiGetFacilities,
  apiGetPositions,
  apiGetCategories,
  apiGetTags,
  googleLoginApi,
  getCategoriesApiAfterPut,
  getTagsApiAfterPut,
  apiUpdateSystemStatus
};
