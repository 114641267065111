import {
  DoronkoMediaFileType,
  Paginate,
  AlbumViewType
} from "components/Camera/type";
import {
  GET_MEDIA_FILES_MINE_SUCCEEDED,
  GET_MEDIA_FILES_MINE_FAILED,
  GET_MEDIA_FILES_SHARE_SUCCEEDED,
  GET_MEDIA_FILES_SHARE_FAILED,
  GET_MEDIA_FILES_ADMIN_SUCCEEDED,
  GET_MEDIA_FILES_ADMIN_FAILED,
  SEARCH_MEDIA_FILES_SUCCEEDED,
  SEARCH_MEDIA_FILES_FAILED,
  TOGGLE_MEDIA_FILES_VIEW_MODE,
  TOGGLE_MEDIA_FILES_SELECT_MODE,
  SET_SELECTED_MEDIA_IDS,
  UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED,
  TOGGLE_IMG_LOADING_STATE,
  RESET_SELECTED_MEDIA_FILE_IDS,
  SET_SEARCH_PARAMS,
  SET_ALBUM_VIEW_TYPE,
  RESET_SEARCH_PARAMS
} from "./type";

export interface MediaFilesState {
  media_files_mine: DoronkoMediaFileType[];
  media_files_admin: DoronkoMediaFileType[];
  media_files_share: DoronkoMediaFileType[];
  search_params: SearchParams;
  isSelectMode: boolean;
  selectedMediaIds: number[];
  albumViewType: AlbumViewType;
  viewMode: "thumbnail" | "list";
  imgLoading: boolean;
  pagination_data: Paginate;
  paginate_mine: Paginate;
  paginate_admin: Paginate;
  paginate_share: Paginate;
}

export interface SearchParams {
  page?: number;
  limit?: number;
  id?: number[];
  taken_from?: string | null;
  taken_to?: string | null;
  tag_id?: number[];
  class_id?: number[];
  share_ban?: boolean;
  upload_user_id?: number[];
  facility_id?: number[];
  file_id?: number[] | null;
  only_doesnt_have_class?: number;
}

const initialState: MediaFilesState = {
  media_files_mine: [],
  media_files_admin: [],
  media_files_share: [],
  search_params: {
    page: 1,
    limit: 20,
    id: [],
    taken_from: null,
    taken_to: null,
    tag_id: [],
    class_id: [],
    share_ban: false,
    upload_user_id: [],
    facility_id: [],
    file_id: null,
    only_doesnt_have_class: 0
  },
  isSelectMode: false,
  viewMode: "thumbnail",
  albumViewType: "mine",
  selectedMediaIds: [],
  imgLoading: false,
  pagination_data: { total: 0, per_page: 20, current_page: 1, last_page: 1 },
  paginate_mine: { total: 0, per_page: 20, current_page: 1, last_page: 1 },
  paginate_admin: { total: 0, per_page: 20, current_page: 1, last_page: 1 },
  paginate_share: { total: 0, per_page: 20, current_page: 1, last_page: 1 }
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_MEDIA_FILES_MINE_SUCCEEDED:
      return {
        ...state,
        media_files_mine: action.media_files,
        paginate_mine: action?.paginate_mine
      };
    case GET_MEDIA_FILES_MINE_FAILED:
      return {
        ...state,
        imgLoading: false
      };
    case GET_MEDIA_FILES_SHARE_SUCCEEDED:
      return {
        ...state,
        media_files_share: action.media_files,
        paginate_share: action?.paginate_share
      };
    case GET_MEDIA_FILES_SHARE_FAILED:
      return {
        ...state,
        imgLoading: false
      };
    case GET_MEDIA_FILES_ADMIN_SUCCEEDED:
      return {
        ...state,
        media_files_admin: action.media_files,
        paginate_admin: action?.paginate_admin
      };
    case GET_MEDIA_FILES_ADMIN_FAILED:
      return {
        ...state,
        imgLoading: false
      };
    case SEARCH_MEDIA_FILES_SUCCEEDED: {
      switch (action.view as AlbumViewType) {
        case "admin":
          return {
            ...state,
            media_files_admin: action.media_files,
            paginate_admin: action.pagination_data,
            pagination_data: action.pagination_data,
            imgLoading: false
          };
        case "mine":
          return {
            ...state,
            media_files_mine: action.media_files,
            paginate_mine: action.pagination_data,
            pagination_data: action.pagination_data,
            imgLoading: false
          };
        case "share":
          return {
            ...state,
            media_files_share: action.media_files,
            paginate_share: action.pagination_data,
            pagination_data: action.pagination_data,
            imgLoading: false
          };
        default:
          return {
            ...state,
            imgLoading: false
          };
      }
    }
    case SEARCH_MEDIA_FILES_FAILED:
      return {
        ...state,
        imgLoading: false
      };
    case UPDATE_SELECTED_MEDIA_FILES_SUCCEEDED:
      return {
        ...state,
        selectedMediaIds: [],
        isSelectMode: false,
        imgLoading: false
      };
    case TOGGLE_MEDIA_FILES_VIEW_MODE:
      return {
        ...state,
        viewMode: action.mode
      };
    case TOGGLE_MEDIA_FILES_SELECT_MODE:
      return {
        ...state,
        isSelectMode: !state.isSelectMode
      };
    case TOGGLE_IMG_LOADING_STATE:
      return {
        ...state,
        imgLoading: action.isLoading
      };
    case SET_SELECTED_MEDIA_IDS:
      let selectedMediaIds = [...state.selectedMediaIds];
      if (state.selectedMediaIds.includes(action.targetMediaId)) {
        selectedMediaIds = state.selectedMediaIds.filter(
          id => id !== action.targetMediaId
        );
      } else {
        selectedMediaIds = [...state.selectedMediaIds, action.targetMediaId];
      }
      return {
        ...state,
        selectedMediaIds
      };
    case RESET_SELECTED_MEDIA_FILE_IDS:
      return {
        ...state,
        selectedMediaIds: []
      };
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        search_params: {
          ...state.search_params,
          [action.target]:
            action.target === "file_id"
              ? action.value.split(",").map((v: string) => Number(v))
              : action.value
        }
      };
    case SET_ALBUM_VIEW_TYPE:
      return {
        ...state,
        albumViewType: action.view
      };
    case RESET_SEARCH_PARAMS:
      return {
        ...state,
        search_params: {
          ...state.search_params,
          id: [],
          taken_from: null,
          taken_to: null,
          tag_id: [],
          class_id: [],
          share_ban: false,
          upload_user_id: [],
          facility_id: [],
          page: 1
        }
      };
    default:
      return state;
  }
};

export default reducer;
