import {
  GET_CAMERA_TAGS_REQUESTED,
  ADD_TO_BE_CREATED_CAMERA_TAG,
  ADD_TO_BE_DELETED_CAMERA_TAGS_IDS,
  SET_TO_BE_CREATED_CAMERA_TAG_DATA,
  SET_TO_BE_UPDATED_CAMERA_TAG_DATA,
  RESET_TO_BE_DELETED_CAMERA_TAGS,
  BATCH_CAMERA_TAGS_REQUESTED,
  DELETE_CAMERA_TAGS_REQUESTED
} from "./type";
import { CameraTagsToBeCreatedState } from "./reducer";
import { CameraTag } from "components/Camera/type";

export const getCameraTagsRequested = (token: string) => {
  return {
    type: GET_CAMERA_TAGS_REQUESTED,
    token
  };
};
export const deleteCameraTagsRequested = (token: string, tagId: number) => {
  return {
    type: DELETE_CAMERA_TAGS_REQUESTED,
    token,
    tagId
  };
};
export const batchCameraTagsRequested = (
  token: string,
  camera_tags: CameraTag[],
  camera_tags_to_be_created: CameraTagsToBeCreatedState[]
) => {
  return {
    type: BATCH_CAMERA_TAGS_REQUESTED,
    token,
    camera_tags,
    camera_tags_to_be_created
  };
};

export const addToBeCreatedCameraTag = () => {
  return {
    type: ADD_TO_BE_CREATED_CAMERA_TAG
  };
};

export const addToBeDeletedCameraTagIds = (
  targetCameraTagId: number | string
) => {
  return {
    type: ADD_TO_BE_DELETED_CAMERA_TAGS_IDS,
    targetCameraTagId
  };
};

export const setToBeCreatedCameraTagData = (
  value: string,
  target: "order" | "name",
  targetTagId: string
) => {
  return {
    type: SET_TO_BE_CREATED_CAMERA_TAG_DATA,
    target,
    value,
    targetTagId
  };
};

export const setToBeUpdatedCameraTagData = (
  value: string,
  target: "order" | "name",
  targetTagId: number
) => {
  return {
    type: SET_TO_BE_UPDATED_CAMERA_TAG_DATA,
    target,
    value,
    targetTagId
  };
};

export const resetToBeDeletedCameraTags = () => {
  return {
    type: RESET_TO_BE_DELETED_CAMERA_TAGS
  };
};
