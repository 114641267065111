import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import ErrorApprovalEventsModalContent from "./ErrorApprovalEventsModalContent";

import { toggleAlreadyApprovedErrorModal } from "../../../../store/common/modal/action";
import { setAlreadyApprovedMonths } from "../../../../store/event/approval/action";

export default function ReturnEventsModal() {
  const dispatch = useDispatch();

  const handleClickBg = () => {
    dispatch(toggleAlreadyApprovedErrorModal(false));
    dispatch(setAlreadyApprovedMonths([]));
  };
  return (
    <>
      <ErrorApprovalEventsModalBg
        onClick={handleClickBg}
      ></ErrorApprovalEventsModalBg>
      <ErrorApprovalEventsModalContent />
    </>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ErrorApprovalEventsModalBg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;
