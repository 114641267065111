import {
  ADD_TO_BE_UPDATED_TAG,
  CHANGE_NAME_TO_BE_UPDATED_TAG,
  CHANGE_ORDER_TO_BE_UPDATED_TAG,
  DELETE_TO_BE_UPDATED_TAG,
} from "./type";
import {
  UPDATE_TAG_ITEM_SUCCEEDED,
  UPDATE_TAG_ITEM_FAILED,
} from "../tags/type";
import { CLEAR_SYSTEM_CACHE } from "../../session/type";

interface TagsToBeUpdated {
  id: number;
  name: string;
  order: number;
}

const initialState: TagsToBeUpdated[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_TO_BE_UPDATED_TAG:
      const foundID = state.some(
        (item: any) => item.id === Number(action.update_target_id)
      );
      if (foundID) {
        return state;
      } else {
        const newArray = action.categories.filter(
          (category: any) => category.id === Number(action.update_target_id)
        );
        return [...state, ...newArray];
      }

    // 入力欄削除
    // case DELETE_TO_BE_UPDATED_TAG:
    //   const deleted_to_be_updated = state.filter((item:any, index:number) =>{
    //     return(
    //       state[index] !== state[action.target_remove_index]
    //     )
    //   })
    // return deleted_to_be_updated

    // 入力欄名前変更
    case CHANGE_NAME_TO_BE_UPDATED_TAG:
      const updatedNameItemIndex = state.findIndex(
        (changed_item: any) =>
          changed_item.id === Number(action.updated_target_id)
      );
      const updatedNameArray = [...state];
      console.log("updatedNameItemIndex", updatedNameItemIndex);
      console.log("updatedNameArray", updatedNameArray);

      updatedNameArray[updatedNameItemIndex].name = action.updated_name;
      return [...updatedNameArray];

    //入力欄順番変更
    case CHANGE_ORDER_TO_BE_UPDATED_TAG:
      const updatedOrderItemIndex = state.findIndex(
        (updated_item: any) =>
          updated_item.id === Number(action.updated_target_id)
      );
      const updatedOrderArray = [...state];
      console.log("action.updated_target_id", action.updated_target_id);
      console.log("updatedOrderItemIndex", updatedOrderItemIndex);
      console.log("updatedOrderArray", updatedOrderArray);
      updatedOrderArray[updatedOrderItemIndex].order = action.updated_order;
      return [...updatedOrderArray];

    //成功したら、stateから削除する
    case UPDATE_TAG_ITEM_SUCCEEDED:
      const newArray = state.filter((item: any) => item.id !== action.id);
      return newArray;

    //失敗したら、stateはそのまま
    case UPDATE_TAG_ITEM_FAILED:
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
    // }
  }
};

export default reducer;
