// 一覧取得
export const GET_MANUALS_REQUESTED = "GET_MANUALS_REQUESTED";
export const GET_MANUALS_SUCCEEDED = "GET_MANUALS_SUCCEEDED";
export const GET_MANUALS_FAILED = "GET_MANUALS_FAILED";

// 詳細取得
export const GET_MANUAL_REQUESTED = "GET_MANUAL_REQUESTED";
export const GET_MANUAL_SUCCEEDED = "GET_MANUAL_SUCCEEDED";
export const GET_MANUAL_FAILED = "GET_MANUAL_FAILED";

// 詳細新規登録
export const CREATE_MANUAL_REQUESTED = "CREATE_MANUAL_REQUESTED";
export const CREATE_MANUAL_SUCCEEDED = "CREATE_MANUAL_SUCCEEDED";
export const CREATE_MANUAL_FAILED = "CREATE_MANUAL_FAILED";

// 詳細更新
export const UPDATE_MANUAL_REQUESTED = "UPDATE_MANUAL_REQUESTED";
export const UPDATE_MANUAL_SUCCEEDED = "UPDATE_MANUAL_SUCCEEDED";
export const UPDATE_MANUAL_FAILED = "UPDATE_MANUAL_FAILED";

// 詳細削除
export const DELETE_MANUAL_REQUESTED = "DELETE_MANUAL_REQUESTED";
export const DELETE_MANUAL_SUCCEEDED = "DELETE_MANUAL_SUCCEEDED";
export const DELETE_MANUAL_FAILED = "DELETE_MANUAL_FAILED";

// 一括更新
export const UPDATE_ALL_MANUALS_REQUESTED = "UPDATE_ALL_MANUALS_REQUESTED";
export const UPDATE_ALL_MANUALS_SUCCEEDED = "UPDATE_ALL_MANUALS_SUCCEEDED";
export const UPDATE_ALL_MANUALS_FAILED = "UPDATE_ALL_MANUALS_FAILED";

// 詳細画像登録
export const CREATE_MANUAL_IMAGE_REQUESTED = "CREATE_MANUAL_IMAGE_REQUESTED";
export const CREATE_MANUAL_IMAGE_SUCCEEDED = "CREATE_MANUAL_IMAGE_SUCCEEDED";
export const CREATE_MANUAL_IMAGE_FAILED = "CREATE_MANUAL_IMAGE_FAILED";

// 画像・キャッチコピー・タイトル更新
export const UPDATE_MANUAL_WITH_IMAGE_REQUESTED =
  "UPDATE_MANUAL_WITH_IMAGE_REQUESTED";
export const UPDATE_MANUAL_WITH_IMAGE_SUCCEEDED =
  "UPDATE_MANUAL_WITH_IMAGE_SUCCEEDED";
export const UPDATE_MANUAL_WITH_IMAGE_FAILED =
  "UPDATE_MANUAL_WITH_IMAGE_FAILED";

// タイトル・説明更新
export const UPDATE_MANUAL_TITLE_DESC_REQUESTED =
  "UPDATE_MANUAL_TITLE_DESC_REQUESTED";
export const UPDATE_MANUAL_TITLE_DESC_SUCCEEDED =
  "UPDATE_MANUAL_TITLE_DESC_SUCCEEDED";
export const UPDATE_MANUAL_TITLE_DESC_FAILED =
  "UPDATE_MANUAL_TITLE_DESC_FAILED";

export const SET_PAGE_TAGS = "SET_PAGE_TAGS";
export const TOGGLE_PAGE_TAGS = "TOGGLE_PAGE_TAGS";

export const MANUAL_SET_WITHOUT_REQUEST = "MANUAL_SET_WITHOUT_REQUEST";
