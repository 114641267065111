import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import color from "../../../mixins/color";
import close from "../../../../images/icons/close.svg";
import { toggleDeleteArticleModal } from "../../../../store/common/modal/action";
import { deleteArticleRequest } from "../../../../store/faq/article_edit/action";

export default function ArticleDeleteModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state: any) => state.session.token);
  const edit_article_id = useSelector((state: any) => state.article_edit.id);
  const handleClickDeleteButton = () => {
    dispatch(deleteArticleRequest(token, edit_article_id));
    dispatch(toggleDeleteArticleModal(false));
    history.push("/articles");
  };
  return (
    <ModalBgSC onClick={() => dispatch(toggleDeleteArticleModal(false))}>
      <ModalContainerSC onClick={(e) => e.stopPropagation()}>
        <TitleBlock>
          <Title>FAQ記事削除</Title>
          <CloseIcon
            src={close}
            alt="close"
            onClick={() => dispatch(toggleDeleteArticleModal(false))}
          />
        </TitleBlock>
        <ContentBlock>
          <ConfirmText>
            選択したイベントを削除します。よろしいですか？
          </ConfirmText>
          <Buttons>
            <Button onClick={() => dispatch(toggleDeleteArticleModal(false))}>
              キャンセル
            </Button>
            <Button className="delete" onClick={handleClickDeleteButton}>
              削除
            </Button>
          </Buttons>
        </ContentBlock>
      </ModalContainerSC>
    </ModalBgSC>
  );
}

const ModalBgSC = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
`;

const ModalContainerSC = styled.div`
  max-width: 600px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const ContentBlock = styled.div`
  text-align: center;
  padding: 30px 54px;
`;

const ConfirmText = styled.p`
  font-size: 14px;
  color: ${color.gray};
  padding-bottom: 30px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.primary};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.primary};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0px;
  }
  &.delete {
    color: ${color.white};
    background-color: ${color.primary};
  }
`;
