import {
  SET_ADD_MONTHLY_EVENT_INITIAL_DATE,
  SET_ADD_MONTHLY_EVENT_TYPE,
  SET_ADD_MONTHLY_EVENT_CONTENT,
  RESET_ADD_MONTHLY_EVENT_TYPE,
  RESET_ADD_MONTHLY_EVENT_CONTENT
} from "./type";

interface EditEventClass {
  class_age: string;
  checked: boolean;
}

export const setAddMonthlyEventModalInitialDate = (
  yearMonthDate: Date,
  year: number,
  month: number,
  date: number
) => {
  return {
    type: SET_ADD_MONTHLY_EVENT_INITIAL_DATE,
    yearMonthDate,
    year,
    month,
    date,
  };
};


export const setAddMonthlyEventType = (type_id: number) => {
  return {
    type: SET_ADD_MONTHLY_EVENT_TYPE,
    type_id,
  };
};

export const setAddMonthlyEventContent = (content: string) => {
  return {
    type: SET_ADD_MONTHLY_EVENT_CONTENT,
    content,
  };
};


export const resetAddMonthlyEventType = () => {
  return {
    type: RESET_ADD_MONTHLY_EVENT_TYPE,
  };
};

export const resetAddMonthlyEventContent = () => {
  return {
    type: RESET_ADD_MONTHLY_EVENT_CONTENT,
  };
};
