import React from "react";
import styled from "styled-components";
import color from "../../mixins/color";
interface Props {
  blockTitle: string;
}

const BlockHeaderAdmin = (props: Props) => {
  const { blockTitle } = props;

  return (
    <BlockHeaderAdminSC>
      <BlockHeaderAdminTitle>{blockTitle}</BlockHeaderAdminTitle>
    </BlockHeaderAdminSC>
  );
};

export default BlockHeaderAdmin;

const BlockHeaderAdminSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BlockHeaderAdminTitle = styled.h2`
  color: ${color.black};
  font-size: 16px;
  margin-bottom: 20px;
`;
