import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import { TOGGLE_HAMBURGER_MENU } from "./type";

const initialState = {
  open: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_HAMBURGER_MENU:
      return {
        ...state,
        open: action.status,
      };

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
