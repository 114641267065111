import React from "react";
import styled from "styled-components";
import { color } from "components/mixins";

import { Page } from "store/manual/manuals/pages/types.d";
import PagesUneditableItem from "./PagesUneditableItem";

interface Props {
  hookedPages: Page[];
  manualId: string;
  topicId: string;
}
function PagesUneditableList(props: Props) {
  const { hookedPages, manualId, topicId } = props;
  return (
    <PagesAdminListSC>
      {hookedPages.map((page: any, index: number) => {
        return (
          <PagesUneditableItem
            key={index}
            page={page}
            manualId={manualId}
            topicId={topicId}
          />
        );
      })}
    </PagesAdminListSC>
  );
}

export default PagesUneditableList;

const PagesAdminListSC = styled.ul`
  border: 1px solid ${color.list_border};
  border-radius: 4px;
`;
