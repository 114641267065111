import React from 'react'
import styled from 'styled-components';
import { pc } from "../../mixins/mediaQuery";
import InquiriesList from './InquiriesList';
import { containerBaseStyle } from '../../mixins'
import color from "../../mixins/color";
import InquiryPagination from './InquiryPagination'

function InquiriesListBlock() {
  return (
    <InquiriesListBlockSC>
      {/* ページング */}
      <InquiryPagination />
      <InquiriesList />
      <InquiryPagination position="bottom" />
    </InquiriesListBlockSC>
  )
}

export default InquiriesListBlock

// ===================================================================================
// スタイル
// ===================================================================================
const InquiriesListBlockSC = styled.div`
  ${containerBaseStyle}
  width: 100%;
  padding: 24px 0;
  background: #ffffff;
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 30px;
  `}
`;
const InquiriesPaging = styled.p`
  font-size: 12px;
  color: ${color.inquiries_gray};
  margin-bottom: 12px;
  padding: 0 16px;
  ${pc`
    padding: 0;
  `}
`;

