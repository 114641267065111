import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  DELETE_TAG_ITEM_FAILED,
  DELETE_TAG_ITEM_SUCCEEDED,
} from "../tags/type";
import { ADD_TO_BE_DELETED_TAG } from "./type";

const initialState: [] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_TO_BE_DELETED_TAG:
      const to_be_deleted_id = action.categories
        .filter((results: any, index: number) => {
          return (
            action.categories[index] === action.categories[action.deleteOrder]
          );
        })
        .flatMap((to_be_deleted: any) => to_be_deleted.id);
      return [...state, ...to_be_deleted_id];

    case DELETE_TAG_ITEM_SUCCEEDED:
      return (state = []);

    case DELETE_TAG_ITEM_FAILED:
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
