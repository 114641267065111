import {
  TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK,
  TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL,
  TOGGLE_MONTH_TO_BE_SEARCHED_CHECK,
  TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL,
  SET_DATE_LIST,
  TOGGLE_REJECT_TO_BE_SEARCHED_CHECK,
  SET_FILTERED_YEAR,
  SET_FILTERED_MONTH_TOP,
  HANDLE_CLEAR_EVENT_SEARCH_TERMS,
  SET_TYPE_IDS,
  SET_IS_SEARCHED_BY_EVENT_TYPE
} from "./type";
import { CLEAR_SYSTEM_CACHE, END_SESSION_SUCCEEDED } from "../../session/type";

interface EventSearch {
  filtered_year: number;
  filtered_months: string[];
  filtered_class: string[];
  filtered_remand: string;
  type_ids: string[];
  date_list: Date[];
  filtered_month_top: string;
  is_search_by_event_type: boolean;
}

// うるう年の判定（initialDateistに使う）
const checkYear: any = new Date().getFullYear();
let dateListaArray: any = [];
if ((checkYear % 4 === 0 && checkYear % 100 !== 0) || checkYear % 400 === 0) {
  dateListaArray = Array(366);
} else {
  dateListaArray = Array(365);
}

// initialDateListの最初の日を4月１日に設定
const firstDay =
  new Date().getMonth() === 0 ||
  new Date().getMonth() === 1 ||
  new Date().getMonth() === 2
    ? new Date(new Date().getFullYear(), 3, 1, 0, 0)
    : new Date(new Date().getFullYear() + 1, 3, 1, 0, 0);
// 365日分（うるう年では３６６日分）回してinitialDateListを作成
const initialDateList = [...dateListaArray].map((_, index) => {
  return new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate() + index
  );
});

// 初期状態
const initialState: EventSearch = {
  filtered_year:
    new Date().getMonth() === 0 ||
    new Date().getMonth() === 1 ||
    new Date().getMonth() === 2
      ? new Date().getFullYear()
      : new Date().getFullYear() + 1,
  filtered_months: [],
  filtered_class: [],
  filtered_remand: "",
  type_ids: [],
  date_list: initialDateList,
  filtered_month_top: "4",
  is_search_by_event_type: false
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // クラス選択
    case TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK:
      // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
      if (
        state.filtered_class.length > 0 &&
        state.filtered_class.some((classes: any) => classes === action.classes)
      ) {
        return {
          ...state,
          filtered_class: state.filtered_class.filter(
            (classes: any) => classes !== action.classes
          )
        };
      } else {
        return {
          ...state,
          filtered_class: [...state.filtered_class, ...action.classes]
        };
      }

    // クラス選択「すべて」
    case TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL:
      if (state.filtered_class.some((month: any) => month === "すべて")) {
        return {
          ...state,
          filtered_class: []
        };
      } else {
        return {
          ...state,
          filtered_class: ["すべて"]
        };
      }

    // 月選択（チェックボックスクリック時）
    case TOGGLE_MONTH_TO_BE_SEARCHED_CHECK:
      // すでに新規articleのカテゴリー配列内にカテゴリーが追加されている場合
      if (
        state.filtered_months.length > 0 &&
        state.filtered_months.some((month: any) => month === action.month)
      ) {
        return {
          ...state,
          filtered_months: state.filtered_months.filter(
            (month: any) => month !== action.month
          )
        };
      } else {
        return {
          ...state,
          filtered_months: [...state.filtered_months, action.month]
        };
      }

    case TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL:
      if (state.filtered_months.some((month: any) => month === "すべて")) {
        return {
          ...state,
          filtered_months: []
        };
      } else {
        return {
          ...state,
          filtered_months: ["すべて"]
        };
      }

    // 検索ボタンが押されたタイミングで選択した月だけリストに表示する
    case SET_DATE_LIST:
      let dateList: any[] = [];
      // 月が1つも選択されていないか「すべて」が選択されていた場合
      if (
        state.filtered_months.length === 0 ||
        state.filtered_months.some((month: any) => month === "すべて")
      ) {
        // うるう年の判定
        let dateListaArray: any = [];
        if (
          (state.filtered_year % 4 === 0 && state.filtered_year % 100 !== 0) ||
          state.filtered_year % 400 === 0
        ) {
          dateListaArray = Array(366);
        } else {
          dateListaArray = Array(365);
        }
        // 最初の日を4月１日に設定
        const firstDay = new Date(state.filtered_year, 3, 1, 0, 0);
        // 365日分（うるう年では３６６日分）回してDateListを作成
        const searchedDateList = [...dateListaArray].map((_, index) => {
          return new Date(
            state.filtered_year,
            firstDay.getMonth(),
            firstDay.getDate() + index
          );
        });
        return {
          ...state,
          date_list: searchedDateList
        };

        // 月が選択されていた場合
      } else {
        const selected_month = [...state.filtered_months];
        // 選択された月を順番に並べる
        const sorted_months = selected_month.sort((a: any, b: any) => a - b);
        let month_after_jan: any;
        let month_before_jan: any;
        // 選択された月のリストに３月、２月、１月が入っていた場合そこで切り取る
        if (sorted_months.indexOf("3") !== -1) {
          month_after_jan = sorted_months.slice(
            0,
            sorted_months.indexOf("3") + 1
          );
          month_before_jan = sorted_months.slice(
            sorted_months.indexOf("3") + 1
          );
        } else if (sorted_months.indexOf("2") !== -1) {
          month_after_jan = sorted_months.slice(
            0,
            sorted_months.indexOf("2") + 1
          );
          month_before_jan = sorted_months.slice(
            sorted_months.indexOf("2") + 1
          );
        } else {
          month_after_jan = sorted_months.slice(
            0,
            sorted_months.indexOf("1") + 1
          );
          month_before_jan = sorted_months.slice(
            sorted_months.indexOf("1") + 1
          );
        }
        // 切り取った配列の部分を最後にくっつける
        const new_sorted_months = [...month_before_jan, ...month_after_jan];

        new_sorted_months.forEach((month: any) => {
          let firstDayInMonth: any;
          let firstDayInNextMonth: any;
          let dateListInThisMonth: any[];
          // ３月より大きい数字では年度はstate.filtered_yearとし１ヶ月分の日付を作る
          if (month > 3) {
            firstDayInMonth = new Date(state.filtered_year, month - 1, 1, 0, 0);
            console.log("firstDayInMonth", firstDayInMonth);
            firstDayInNextMonth = new Date(state.filtered_year, month, 1, 0, 0);
            console.log("firstDayInNextMonth", firstDayInNextMonth);
            const interval = (firstDayInNextMonth - firstDayInMonth) / 86400000;
            dateListInThisMonth = [...Array(interval)].map((_, index) => {
              return new Date(
                firstDayInMonth.getFullYear(),
                firstDayInMonth.getMonth(),
                firstDayInMonth.getDate() + index
              );
            });
            // 1,2,3月の場合は年度をstate.filtered_year+1として１ヶ月分の日付を作る
          } else {
            firstDayInMonth = new Date(
              state.filtered_year + 1,
              month - 1,
              1,
              9,
              0
            );
            console.log("firstDayInMonth", firstDayInMonth);
            firstDayInNextMonth = new Date(
              state.filtered_year + 1,
              month,
              1,
              9,
              0
            );
            console.log("firstDayInNextMonth", firstDayInNextMonth);
            const interval = (firstDayInNextMonth - firstDayInMonth) / 86400000;
            dateListInThisMonth = [...Array(interval)].map((_, index) => {
              return new Date(
                firstDayInMonth.getFullYear(),
                firstDayInMonth.getMonth(),
                firstDayInMonth.getDate() + index
              );
            });
          }
          dateList = [...dateList, ...dateListInThisMonth];
        });
      }

      return {
        ...state,
        date_list: dateList
      };

    // 差し戻し
    case TOGGLE_REJECT_TO_BE_SEARCHED_CHECK:
      return {
        ...state,
        filtered_remand: action.filtered_remand
      };

    // 年設定（検索時）
    case SET_FILTERED_YEAR:
      return {
        ...state,
        filtered_year: Number(action.filtered_year)
      };

    // TOPページの月設定
    case SET_FILTERED_MONTH_TOP:
      return {
        ...state,
        filtered_month_top: action.filtered_month_top
      };

    case END_SESSION_SUCCEEDED:
      return {
        ...initialState
      };

    case SET_TYPE_IDS:
      return {
        ...state,
        type_ids: action.value.map(
          (item: { value: string; label: string }) => item.value
        )
      };

    case SET_IS_SEARCHED_BY_EVENT_TYPE:
      const isSearchByEventType = action.typeIds.length !== 0;
      return {
        ...state,
        is_search_by_event_type: isSearchByEventType
      };

    case HANDLE_CLEAR_EVENT_SEARCH_TERMS:
      if (action.target_term === "month") {
        return {
          ...state,
          filtered_months: []
        };
      } else if (action.target_term === "class_age") {
        return {
          ...state,
          filtered_class: []
        };
      } else {
        return state;
      }

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
