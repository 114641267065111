import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import color from "../../mixins/color";
import clock from "../../../images/icons/clock.svg";
import localeTimeCustomizer from "../../Common/LocaleTimeCustomizer";

function InquiriesItem(props: any) {
  const { id, user_name, inquiry_name, employee_id, facility, created_at } =
    props;
  console.log(facility);
  return (
    <InquiriesItemSC>
      {/* 問合せアイテム上部 */}
      <InquiriesItemHeading>
        {/* お問い合わせ日時 */}
        <InquiriesId>ID: {id}</InquiriesId>
        <CreatedAt>
          {localeTimeCustomizer(created_at)}
          {/* {time} */}
        </CreatedAt>
      </InquiriesItemHeading>

      {/* 問合せ内容 */}
      <InquiriesItemContents>
        <InquiriesItemContent>
          <ItemContentName>ログインユーザ名</ItemContentName>
          <ItemContentValue>{user_name ?? ""}</ItemContentValue>
        </InquiriesItemContent>

        <InquiriesItemContent>
          <ItemContentName>お問合せユーザ名</ItemContentName>
          <ItemContentValue>{inquiry_name ?? ""}</ItemContentValue>
        </InquiriesItemContent>

        <InquiriesItemContent>
          <ItemContentName>お問合せ職員ID</ItemContentName>
          <ItemContentValue>{employee_id ?? ""}</ItemContentValue>
        </InquiriesItemContent>

        <InquiriesItemContent>
          <ItemContentName>対象施設</ItemContentName>
          <ItemContentValue>
            {facility && facility.name !== null ? facility.name : ""}
          </ItemContentValue>
        </InquiriesItemContent>
      </InquiriesItemContents>
    </InquiriesItemSC>
  );
}

export default InquiriesItem;

const InquiriesItemSC = styled.li`
  width: 100%;
  padding: 15px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${color.list_border};
  ${pc`
    padding: 15px 20px;
  `}
  &:last-of-type {
    border-bottom: none;
  }
`;

const InquiriesItemLink = styled(NavLink)`
  display: block;
  width: 100%;
  min-height: 108px;
  padding: 16px 20px;
  cursor: pointer;
  &:hover {
    background: ${color.primary_white};
  }
`;

const InquiriesItemHeading = styled.div`
  margin-bottom: 14px;
  display: flex;
  justify-content: flex-start;
`;

const InquiriesItemContents = styled.div`
  padding: 10px 14px;
  border-radius: 4px;
  background-color: ${color.inquiries_item_bg};
`;

const InquiriesId = styled.p`
  font-size: 12px;
  color: ${color.inquiries_gray};
  margin-right: 20px;
`;

const CreatedAt = styled.p`
  display: block;
  font-size: 12px;
  color: ${color.gray};
  padding-left: 18px;
  background-image: url(${clock});
  background-repeat: no-repeat;
  background-position: left center;
  margin-right: 24px;
`;

const InquiriesItemContent = styled.dl`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemContentName = styled.dt`
  font-size: 12px;
  color: ${color.gray};
  font-weight: 300;
  line-height: calc(18 / 12);
  width: 120px;
  ${pc`
    width: 30%;
  `}
`;

const ItemContentValue = styled.dd`
  font-size: 12px;
  color: ${color.gray};
  width: calc(100% - 120px);
  font-weight: 300;
  line-height: calc(18 / 12);
  ${pc`
    width: 70%;
  `}
`;
