import {
  SET_INITIAL_EDIT_INPUT,
  RESET_EDIT_EVENT_CONTENT,
  SET_EDIT_EVENT_DATE,
  SET_EDIT_EVENT_TYPE,
  SET_EDIT_EVENT_CONTENT,
  SET_EDIT_EVENT_DETAIL,
  SET_EDIT_EVENT_CLASS
} from "./type";

interface EditEventClass {
  class_age: string;
  checked: boolean;
}

interface AdditionalInformation {
  detail: string;
  class: string;
}

export const setInitialEditInput = (
  id: number,
  yearMonthDate: Date,
  year: number,
  month: number,
  date: number,
  title: string,
  type_id: number,
  content: string,
  detail: string,
  class_age: string,
  remand: object[],
  additional_information: object[]
) => {
  return {
    type: SET_INITIAL_EDIT_INPUT,
    id,
    yearMonthDate,
    year,
    month,
    date,
    title,
    type_id,
    content,
    detail,
    class_age,
    remand,
    additional_information
  };
};

export const resetEditEventModalContent = () => {
  return {
    type: RESET_EDIT_EVENT_CONTENT
  };
};

export const setEditEventDate = (year: number, month: number, date: number) => {
  return {
    type: SET_EDIT_EVENT_DATE,
    year,
    month,
    date
  };
};
export const setEditEventType = (
  type_id: number | string,
  type_name: string
) => {
  return {
    type: SET_EDIT_EVENT_TYPE,
    type_id,
    type_name
  };
};
export const setEditEventContent = (content: string) => {
  return {
    type: SET_EDIT_EVENT_CONTENT,
    content
  };
};
export const setEditEventDetail = (detail: string) => {
  return {
    type: SET_EDIT_EVENT_DETAIL,
    detail
  };
};
export const setEditEventClass = (classes_class_age: string) => {
  return {
    type: SET_EDIT_EVENT_CLASS,
    classes_class_age
  };
};
