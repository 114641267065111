import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../../mixins/mediaQuery";
import { color, inputBaseStyle } from "../../../mixins";
import {
  inputNewNewsTitle,
  selectNewNewsStatus,
} from "../../../../store/global/news_new/action";

import WysiwygNewsNew from "./WysiwygNewsNew";

function NewsNewEditor() {
  const dispatch = useDispatch();
  const title = useSelector((state: any) => state.news_new.title);
  const status = useSelector((state: any) => state.news_new.status);
  const content = useSelector((state: any) => state.news_new.content);

  // 新規articleのタイトル管理
  const handleNewNewsTitle = (e: any) => {
    dispatch(inputNewNewsTitle(e.target.value));
  };

  // ステータスの判定
  const handleNewNewsStatusChange = (e: any) => {
    console.log("status consoling...", e.target.value);
    dispatch(selectNewNewsStatus(Number(e.target.value)));
  };

  return (
    <NewsEditorSC>
      <TitleBlock>
        <TitleLabel>記事タイトル*</TitleLabel>
        <TitleEditorBlock>
          <TitleEditorInput value={title} onChange={handleNewNewsTitle} />
        </TitleEditorBlock>
      </TitleBlock>

      <BodyBlock>
        <BodyLabel>記事本文</BodyLabel>
        <BodyEditorBlock>
          <WysiwygNewsNew content={content} />
        </BodyEditorBlock>
      </BodyBlock>

      {/* ステータス */}
      <StatusBlock>
        <StatusLabel>ステータス*</StatusLabel>
        <StatusEditorBlock>
          <StatusEditorInput
            className="select"
            value={status}
            onChange={handleNewNewsStatusChange}
          >
            <option value={2}>公開</option>
            <option value={1}>非公開</option>
            <option value={3}>下書き</option>
          </StatusEditorInput>
        </StatusEditorBlock>
      </StatusBlock>
    </NewsEditorSC>
  );
}

export default NewsNewEditor;
// ===================================================================================
// スタイル
// ===================================================================================
const NewsEditorSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

// Title ============================
const TitleBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
    align-items: center;
  `}
`;

const TitleEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const TitleLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const TitleEditorInput = styled.input`
  ${inputBaseStyle}
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;
// ==================================

// Body =============================
const BodyBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${color.divide_border};
  ${pc`
    display: flex;
  `}
`;

const BodyEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const BodyLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;
// ==================================

// Status =================================
const StatusLabel = styled.label`
  display: block;
  width: 90px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${color.gray};
  ${pc`
    margin-right: 50px;
    margin-bottom: 0;
  `}
`;

const StatusEditorBlock = styled.div`
  width: 100%;
  ${pc`
    max-width: 610px;
  `}
`;

const StatusEditorInput = styled.select`
  ${inputBaseStyle}
  width: 240px;
`;

const StatusBlock = styled.div`
  width: 100%;
  padding-bottom: 20px;
  ${pc`
    display: flex;
    align-items: center;
  `}
`;
// ===========================================

// checkBox ==================================
const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .checkbox:checked + .label::before {
    background-color: ${color.primary};
  }
  .checkbox:checked + .label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;
