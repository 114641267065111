import PageHeading from "components/Common/PageHeading";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../images/icon_upload.svg";
import { DescriptionSC, DndContainerSC, SelectBtn } from "./style";
import { FixedButtonArea } from "components/Camera/components/FixedButtonArea";
import { uploadMediaFileRequested } from "store/camera/mediafiles/action";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";

export const CameraUpload = () => {
  type UploadFile = {
    encoded_file: string;
    async: boolean;
  };
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.session.token);
  const [toBeUploadFiles, setTobeUploadFiles] = useState<UploadFile[]>([]);
  const onDropHandler = (files: File[]) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        if (e.target && typeof e.target.result === "string") {
          const result = e.target.result!.replace(/^data:\w+\/\w+;base64,/, "");
          const objForUpload = {
            encoded_file: result,
            async: false
          };
          setTobeUploadFiles(state => [...state, objForUpload]);
        }
      };
    });
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler
  });
  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const handleUpload = () => {
    toBeUploadFiles.forEach(data => {
      dispatch(uploadMediaFileRequested(token, data));
    });
  };

  useEffect(() => {
    console.log(toBeUploadFiles);
  }, [toBeUploadFiles]);

  return (
    <>
      <div style={{ margin: "20px 20px" }}>
        <PageHeading title="アップロード" />
        <DndContainerSC {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div>
            <img src={upload} alt="" />
            <DescriptionSC>
              ここにファイルをドラッグ＆ドロップ
              <br />
              または
            </DescriptionSC>
            <SelectBtn>ファイルを選択</SelectBtn>
          </div>
        </DndContainerSC>
        <aside>
          <h4>Files</h4>
          <ul>{files}</ul>
        </aside>
      </div>
      <FixedButtonArea updateLabel="アップロード" onUpdateFunc={handleUpload} />
    </>
  );
};
