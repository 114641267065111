import { resultsAriaMessage } from "react-select/src/accessibility";
import {
  BEGIN_SESSION_SUCCEEDED,
  BEGIN_SESSION_FAILED,
  END_SESSION_SUCCEEDED,
  END_SESSION_FAILED,
  TOGGLE_SEARCH_ACCOUNT_IS_OPENED,
  TOGGLE_SEARCH_ARTICLE_IS_OPENED,
  LOGIN_STATUS_401,
  CLEAR_SYSTEM_CACHE,
  TOGGLE_CACHE_CLEARED,
  TOGGLE_TO_CLEAR_CACHE,
  MAINTENANCE_ON,
  SYSTEM_UP,
  RESPONSE_STATUS_200,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500,
  GET_STATUS_SUCCEEDED,
  RESET_RESPONSE_STATUS,
  LOG_OUT,
  IS_LOGGED_IN_TURN_TO_FALSE
} from "./type";
import { SessionState } from "./types.d";

const initialState: SessionState = {
  id: null,
  isAdmin: false,
  isEditor: false,
  isLoggedIn: false,
  token: "",
  gsuite_id: "",
  name: "FAQ001テスト",
  email: "test_faq01@doronko.jp",
  given_name: "FAQ001",
  family_name: "テスト",
  picture: "https://lh6.googleusercontent.com/1/photo.jpg",
  email_verified_at: null,
  email_verified: true,
  gsuite: "1",
  locale: "ja",
  hd: "doronko.jp",
  verified_email: true,
  link: null,
  no_read: 10,
  read: 190,
  role: [
    {
      id: 1,
      name: "閲覧者"
    }
  ],
  position: {
    id: 1,
    name: "閲覧者"
  },
  facilities: [],
  articles_page_num: 1,
  articles_count: 20,
  searchArticleIsOpened: true,
  searchAccountIsOpened: false,
  status401: false,
  to_clear_cache: false,
  system_status: "up",
  response_status: 200,
  referer: null
};
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_STATUS_SUCCEEDED:
      if (action.status === 1) {
        return {
          ...state,
          system_status: "up"
        };
      } else if (action.status === 2) {
        return {
          ...state,
          system_status: "maintenance"
        };
      } else {
        return state;
      }

    case RESPONSE_STATUS_200:
      return {
        ...state,
        response_status: 200
      };
    case RESPONSE_STATUS_401:
      return {
        ...state,
        response_status: 401
      };
    case RESPONSE_STATUS_403:
      return {
        ...state,
        response_status: 403
      };
    case RESPONSE_STATUS_404:
      return {
        ...state,
        response_status: 404
      };
    case RESPONSE_STATUS_422:
      return {
        ...state,
        response_status: 422
      };
    case RESPONSE_STATUS_500:
      return {
        ...state,
        response_status: 500
      };

    case BEGIN_SESSION_SUCCEEDED:
      console.log("action", action.result);
      return {
        ...state,
        ...action.result.user,
        token: action.result.token,
        isEditor: false,
        isAdmin: true,
        isLoggedIn: true,
        gsuite_id: action.result.user.gsuite_id
          ? action.result.user.gsuite_id
          : "",
        name: action.result.user.name,
        email: action.result.user.email,
        given_name: action.result.user.given_name,
        family_name: action.result.user.family_name,
        picture: action.result.user.picture,
        email_verified_at: action.result.user.email_verified_at,
        email_verified: action.result.user.email_verified,
        gsuite: action.result.user.gsuite,
        locale: action.result.user.locale,
        hd: action.result.user.hd,
        verified_email: action.result.user.verified_email,
        link: action.result.user.link,
        no_read: action.result.user.no_read,
        read: action.result.user.read,
        position: action.result.user.position,
        facilities: action.result.user.facilities,
        articles_page_num: 1,
        articles_count: 20,
        status401: false,
        system_status: "up",
        response_status: 200
      };

    case BEGIN_SESSION_FAILED:
      return {
        ...state,
        message: action.message
      };

    case END_SESSION_SUCCEEDED:
      return {
        isLoggedIn: false,
        position: {
          id: null,
          name: ""
        }
      };

    case END_SESSION_FAILED:
      return {
        ...state,
        message: action.message
      };

    case TOGGLE_SEARCH_ACCOUNT_IS_OPENED:
      return {
        ...state,
        searchAccountIsOpened: !action.isOpened
      };

    case TOGGLE_SEARCH_ARTICLE_IS_OPENED:
      return {
        ...state,
        searchArticleIsOpened: !action.isOpened
      };

    case LOGIN_STATUS_401:
      return {
        id: null,
        isAdmin: false,
        isEditor: false,
        isLoggedIn: false,
        token: "",
        gsuite_id: "",
        name: "FAQ001テスト",
        email: "test_faq01@doronko.jp",
        given_name: "FAQ001",
        family_name: "テスト",
        picture: "https://lh6.googleusercontent.com/1/photo.jpg",
        email_verified_at: {},
        email_verified: true,
        gsuite: "1",
        locale: "ja",
        hd: "doronko.jp",
        verified_email: true,
        link: null,
        no_read: 10,
        read: 190,
        role: [
          {
            id: 1,
            name: "閲覧者"
          }
        ],
        position: {
          id: 1,
          name: "閲覧者"
        },
        articles_page_num: 1,
        articles_count: 20,
        searchArticleIsOpened: false,
        searchAccountIsOpened: false,
        to_clear_cache: true,
        status401: true,
        response_status: 401
      };

    case CLEAR_SYSTEM_CACHE:
      return {
        ...initialState,
        cache_cleared: action.sw ? false : true
      };

    case TOGGLE_CACHE_CLEARED:
      return {
        ...state,
        cache_cleared: action.cache_cleared
      };

    case TOGGLE_TO_CLEAR_CACHE:
      return {
        ...state,
        to_clear_cache: action.to_clear_cache
      };

    case RESET_RESPONSE_STATUS:
      return {
        ...state,
        response_status: 200
      };

    case MAINTENANCE_ON:
      return {
        ...state,
        system_status: "maintenance"
      };

    case SYSTEM_UP:
      return {
        ...state,
        system_status: "up"
      };

    case LOG_OUT:
      return {
        ...initialState
      };

    case IS_LOGGED_IN_TURN_TO_FALSE:
      return {
        ...initialState,
        isLoggedIn: false
      };

    default:
      return state;
  }
};

export default reducer;
