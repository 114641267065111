import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";
import LocalTimeCustomizerForEvents from "../../Common/LocaleTimeCustomizerForEvents";

import reject_icon from "../../../images/icons/exclamation_gray.svg";

import { toggleDeleteReturnEventModal } from "../../../store/common/modal/action";
import {
  addToBeDeletedReject,
  toggleToBeRejected
} from "../../../store/event/approval/action";

function ApprovalItem(props: any) {
  const dispatch = useDispatch();
  const to_be_rejected = useSelector(
    (state: any) => state.approval.to_be_rejected
  );
  const position_id = useSelector((state: any) => state.session.position.id);
  const approval_monthly_status = useSelector(
    (state: any) => state.approval.monthly_status
  );

  const handleClickButton = () => {
    dispatch(toggleDeleteReturnEventModal(true));
    dispatch(addToBeDeletedReject(props));
  };

  //props.additional_information[index]のdestinationを'／'で区切って文字列にする
  const multipleDetail = props.additional_information
    ? props.additional_information
        .map((item: any) => item.destination)
        .join("／")
    : undefined;

  //props.additional_information[index]のclassを','で区切って文字列にする
  const multipleClasses = props.additional_information
    ? props.additional_information.map((item: any) => item.class).join("／")
    : undefined;

  // イベントが入ってる行の、差し戻し可否表示出し分け判定
  let isEditableClass = "";
  if (
    // editing_statusが５（CSVUpload、本部登録イベント）の場合、編集不可能。もしくは、editing_statusが4(登録完了)だった場合は編集不可
    props.editing_status === 5 ||
    props.editing_status === 4 ||
    // 現在ログインしているユーザーがすでにその行事予定を承認している場合
    props.approval_statuses.some(
      (approval_status: any) =>
        approval_status.id === position_id && approval_status.approved === true
    ) ||
    // 施設長（5）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    (position_id === 5 &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.leader_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      )) ||
    // プロジェクト管理者（6）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    (position_id === 6 &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.administrator_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      )) ||
    // 本部（2）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    ((position_id === 2 || position_id === 3) &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.director_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      ))
  ) {
    isEditableClass = "not_editable";
  } else {
    isEditableClass = "";
  }

  // イベントが入っていない行の、差し戻し可否表示出し分け判定
  let isEditableClassForEmpty = "";
  if (
    // 現在ログインしているユーザーがすでにその行事予定を承認している場合
    props.approval_statuses.some(
      (approval_status: any) =>
        approval_status.id === position_id && approval_status.approved === true
    ) ||
    // 施設長（5）一般閲覧者（4）一般編集者（1）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    ((position_id === 5 || position_id === 4 || position_id === 1) &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.leader_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      )) ||
    // プロジェクト管理者（6）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    (position_id === 6 &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.administrator_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      )) ||
    // 本部（2）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
    ((position_id === 2 || position_id === 3) &&
      approval_monthly_status.some(
        (status_item: any) =>
          status_item.director_approved === true &&
          `${new Date(props.date).getFullYear()}-${(
            "0" +
            (new Date(props.date).getMonth() + 1)
          ).slice(-2)}`.includes(status_item.month)
      ))
  ) {
    isEditableClassForEmpty = "not_editable";
  } else {
    isEditableClassForEmpty = "";
  }
  return (
    <>
      {props.title !== "" ||
      props.content !== "" ||
      props.detail !== "" ||
      props.class_age !== "" ? (
        <ApprovalItemSC className={isEditableClass}>
          <ApprovalItemWrapper>
            <ApprovalItemContent>
              {/* 施設長が承認している状態で、本部(2)、プロジェクトマネージャー(6)の場合に差し戻しチェックボックスを表示 */}
              {approval_monthly_status.some((status_item: any) =>
                props.date !== ""
                  ? status_item.leader_approved === true &&
                    `${new Date(props.date).getFullYear()}-${(
                      "0" +
                      (new Date(props.date).getMonth() + 1)
                    ).slice(-2)}`.includes(status_item.month)
                  : status_item.leader_approved === true &&
                    `${new Date(props.date_to_show).getFullYear()}-${(
                      "0" +
                      (new Date(props.date_to_show).getMonth() + 1)
                    ).slice(-2)}`.includes(status_item.month)
              ) ? (
                <>
                  {position_id === 6 || position_id === 2 ? (
                    <ApprovalItemCheckboxWrapper>
                      <ApprovalItemCheckbox
                        id={props.id}
                        type="checkbox"
                        name="events_item"
                        onClick={() =>
                          dispatch(
                            toggleToBeRejected(
                              props.id,
                              props.date,
                              props.title,
                              props.content,
                              props.detail
                            )
                          )
                        }
                        checked={to_be_rejected.some(
                          (item: any) => item.id === props.id
                        )}
                        //
                        disabled={
                          //本部権限で、ステータスが本部登録で、当該月の承認状況がtrueじゃなかったら、差し戻し可能
                          (position_id === 2 || position_id === 3) &&
                          props.editing_status === 5 &&
                          !approval_monthly_status.some(
                            (status_item: any) =>
                              status_item.director_approved === true &&
                              `${new Date(props.date).getFullYear()}-${(
                                "0" +
                                (new Date(props.date).getMonth() + 1)
                              ).slice(-2)}`.includes(status_item.month)
                          )
                            ? false
                            : isEditableClass === "not_editable"
                            ? true
                            : false
                        }
                      />
                      <ApprovalItemCheckboxLabel
                        htmlFor={props.id}
                        className={
                          (position_id === 2 || position_id === 3) &&
                          props.editing_status === 5
                            ? ""
                            : isEditableClass
                        }
                      />
                    </ApprovalItemCheckboxWrapper>
                  ) : (
                    <div style={{ width: "40px" }}></div>
                  )}
                </>
              ) : (
                <div style={{ width: "40px" }}></div>
              )}
            </ApprovalItemContent>
            <ApprovalItemContent className="date">
              {props.date !== ""
                ? LocalTimeCustomizerForEvents(props.date)
                : ""}
            </ApprovalItemContent>

            {props.title && props.title.length >= 7 ? (
              <>
                <ApprovalItemContent className="title pointer">
                  {props.title.slice(0, 6) + "..."}
                </ApprovalItemContent>
                <ApprovalItemHiddenContent className="title">
                  {props.title}
                </ApprovalItemHiddenContent>
              </>
            ) : (
              <ApprovalItemContent className="title">
                <p>{props.title}</p>
              </ApprovalItemContent>
            )}

            {props.content && props.content.length >= 9 ? (
              <>
                <ApprovalItemContent className="content pointer">
                  {props.content.slice(0, 8) + "..."}
                </ApprovalItemContent>
                <ApprovalItemHiddenContent className="content">
                  {props.content}
                </ApprovalItemHiddenContent>
              </>
            ) : (
              <ApprovalItemContent className="content">
                <p>{props.content}</p>
              </ApprovalItemContent>
            )}

            {/* 詳細（目的地） */}
            {props.detail && !multipleDetail && (
              <>
                {props.detail.length >= 10 ? (
                  <>
                    <ApprovalItemContent className="detail pointer">
                      {props.detail.slice(0, 9) + "..."}
                    </ApprovalItemContent>
                    <ApprovalItemHiddenContent className="detail">
                      {props.detail}
                    </ApprovalItemHiddenContent>
                  </>
                ) : (
                  <ApprovalItemContent className="detail">
                    <p>{props.detail}</p>
                  </ApprovalItemContent>
                )}
              </>
            )}

            {/* 複数入力の詳細（目的地） */}
            {multipleDetail && (
              <>
                {multipleDetail.length >= 10 ? (
                  <>
                    <ApprovalItemContent className="detail pointer">
                      {multipleDetail.slice(0, 9) + "..."}
                    </ApprovalItemContent>
                    <ApprovalItemHiddenContent className="detail">
                      {multipleDetail}
                    </ApprovalItemHiddenContent>
                  </>
                ) : (
                  <ApprovalItemContent className="detail">
                    <p>{multipleDetail}</p>
                  </ApprovalItemContent>
                )}
              </>
            )}

            {/* 詳細（目的地）が存在しない場合 */}
            {!multipleDetail && !props.detail && (
              <ApprovalItemContent className="detail" />
            )}

            {/* 対象クラス */}
            {props.class_age && !multipleClasses && (
              <>
                {props.class_age.length >= 4 ? (
                  <>
                    <ApprovalItemContent className="class_age pointer">
                      {props.class_age.slice(0, 3) + "..."}
                    </ApprovalItemContent>
                    <ApprovalItemHiddenContent className="class_age">
                      {props.class_age}
                    </ApprovalItemHiddenContent>
                  </>
                ) : (
                  <ApprovalItemContent className="class_age">
                    <p>{props.class_age}</p>
                  </ApprovalItemContent>
                )}
              </>
            )}

            {/* 複数入力の対象クラス */}
            {multipleClasses && (
              <>
                {multipleClasses.length >= 4 ? (
                  <>
                    <ApprovalItemContent className="class_age pointer">
                      {multipleClasses.slice(0, 3) + "..."}
                    </ApprovalItemContent>
                    <ApprovalItemHiddenContent className="class_age">
                      {multipleClasses}
                    </ApprovalItemHiddenContent>
                  </>
                ) : (
                  <ApprovalItemContent className="class_age">
                    <p>{multipleClasses}</p>
                  </ApprovalItemContent>
                )}
              </>
            )}

            {/* 対象クラスが存在しない場合 */}
            {!multipleClasses && !props.class_age && (
              <ApprovalItemContent className="class_age" />
            )}

            {props.remand && props.remand.length > 0 && (
              <ApprovalRejectButton
                onClick={handleClickButton}
                src={reject_icon}
              />
            )}
          </ApprovalItemWrapper>
        </ApprovalItemSC>
      ) : (
        <ApprovalItemSC className={isEditableClassForEmpty}>
          <ApprovalItemWrapper>
            <div style={{ padding: "20px" }}></div>
            <ApprovalItemContent className="date">
              {props.date !== ""
                ? LocalTimeCustomizerForEvents(props.date)
                : ""}
            </ApprovalItemContent>
            <ApprovalItemContent className="title">
              {props.title.length >= 7
                ? props.title.slice(0, 6) + "..."
                : props.title}
            </ApprovalItemContent>
            <ApprovalItemContent className="content">
              {props.content.length >= 9
                ? props.content.slice(0, 8) + "..."
                : props.content}
            </ApprovalItemContent>
            <ApprovalItemContent className="detail">
              {props.detail.length >= 10
                ? props.detail.slice(0, 9) + "..."
                : props.detail}
            </ApprovalItemContent>
            <ApprovalItemContent className="class_age">
              {props.class_age.length >= 4
                ? props.class_age.slice(0, 3) + "..."
                : props.class_age}
            </ApprovalItemContent>
            {props.remand && <ApprovalRejectButton src={reject_icon} />}
          </ApprovalItemWrapper>
        </ApprovalItemSC>
      )}
    </>
  );
}

export default ApprovalItem;

// ===================================================================================
// スタイル
// ===================================================================================

const ApprovalItemSC = styled.li`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  padding: 0px 20px;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
  &.not_editable {
    background-color: ${color.divide_border};
  }
  // cursor: pointer;
`;

const ApprovalItemWrapper = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

const ApprovalItemCheckboxWrapper = styled.li`
  padding-top: 25px;
  .none {
    padding-top: 12px;
  }
`;

const ApprovalItemCheckbox = styled.input`
  display: none;
  &:checked + label::before {
    background-color: ${color.gray};
  }
  &:checked + label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;
const ApprovalItemCheckboxLabel = styled.label`
  padding-right: 39px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${color.gray}; /* 枠の色変更 お好きな色を */
    background-color: ${color.white}; /* 背景の色変更 お好きな色を */
  }
  &.hover {
    cursor: pointer;
  }
  &.not_editable {
    cursor: default;
  }
`;

const ApprovalRejectButton = styled.img`
  position: absolute;
  right: 16px;
  top: 25px;
  cursor: pointer;
`;

const ApprovalItemContent = styled.li`
  padding: 25px 0 21px;
  font-size: 14px;
  color: #444;
  flex-shrink: 0;
  &:hover + li {
    display: block;
  }
  &.date {
    width: calc(13.5%);
  }
  &.title {
    width: calc(18%);
  }
  &.content {
    width: calc(22%);
  }
  &.detail {
    width: calc(27%);
  }
  &.class_age {
    width: calc(17%);
  }
  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
`;

const ApprovalItemHiddenContent = styled.li`
  display: none;
  position: absolute;
  top: 12px;
  left: 4px;
  border: 1px solid ${color.light_gray};
  border-radius: 4px;
  padding: 11px;
  background-color: ${color.white};
  font-size: 14px;
  color: ${color.gray};
  z-index: 100;
  &.title {
    left: calc(57px + (100%) * 0.13 + 5px);
    &.hover {
      display: block;
    }
  }
  &.content {
    left: calc(57px + (100% - 77px) * 0.32 + 7px);
  }
  &.detail {
    left: calc(57px + (100% - 77px) * 0.54 + 7px);
  }
  &.class_age {
    left: calc(57px + (100% - 77px) * 0.81 + 7px);
  }
`;
