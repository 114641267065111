import React from "react";
import styled from "styled-components";
import { pc } from "components/mixins/mediaQuery";
import { color } from "components/mixins";
import tag from "images/icons/tag.svg";
import { Tag } from "components/Manual/demoData";
import "components/Manual/Parts/TinyMCEStyles/default-content.min.scss";
import "components/Manual/Parts/TinyMCEStyles/content.min.scss";
import "components/Manual/Parts/TinyMCEStyles/skin.min.scss";
interface ManualEditContentProps {
  title: string;
  tags: Tag[];
  currentTagIds: { id: number }[];
  content: string;
  isPublished: boolean;
}

function ManualEditPreviewContent(props: ManualEditContentProps) {
  const { title, tags, currentTagIds, content, isPublished } = props;
  console.log(title);

  return (
    <>
      <DetailHeaderSC>
        {/* タイトル */}
        <ArticleTitleSC>{title}</ArticleTitleSC>

        {/* タグ */}
        <TagListSC>
          {isPublished
            ? tags.map(tag => <TagItemSC>{tag.name}</TagItemSC>)
            : tags.map((tag: any) => {
                if (
                  currentTagIds.some(
                    (currentTagIds: { id: number }) =>
                      currentTagIds.id === tag.id
                  )
                ) {
                  console.log(currentTagIds);
                  return <TagItemSC>{tag.name}</TagItemSC>;
                } else {
                  return "";
                }
              })}
        </TagListSC>
      </DetailHeaderSC>

      {/* コンテンツ */}
      <DetailContentsSC>
        {props.content && typeof props.content === "string" && (
          <div
            className="mce-content-body"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
      </DetailContentsSC>
    </>
  );
}

export default ManualEditPreviewContent;
// ===================================================================================
// スタイル
// ===================================================================================

const DetailHeaderSC = styled.div`
  width: 100%;
  padding: 24px 16px;
  border-bottom: 1px solid ${color.divide_border};
  position: relative;
  ${pc`
    padding: 30px;
  `}
`;

const TagListSC = styled.ul`
  display: flex;
  margin-bottom: 16px;
  ${pc`
    margin-bottom: 0;
  `}
`;

const TagItemSC = styled.li`
  display: inline-block;
  color: ${color.gray};
  font-size: 12px;
  border-radius: 10px;
  margin-right: 8px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    background-image: url(${tag});
    background-repeat: no-repeat;
    background-position: left center;
    margin-right: 3px;
  }
`;

const ArticleTitleSC = styled.h2`
  color: ${color.gray};
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0 0 16px 0;
  ${pc`
    font-size: 20px;
  `}
`;

const DetailContentsSC = styled.div`
  padding: 30px 16px 24px;
  ${pc`
    padding: 30px;
  `}
  img {
    width: auto;
    max-width: 100%;
  }
  iframe {
    max-width: 100%;
  }
  strong {
    font-weight: bolder;
  }
  *.center {
    text-align: center;
  }
  *.right {
    text-align: right;
  }
  *.left {
    text-align: left;
  }
  ol {
    list-style-type: decimal;
  }
`;
