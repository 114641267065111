import { all } from "redux-saga/effects";
import accountSaga from "./accountSaga";
import articleSaga from "./articleSaga";
import newsSaga from "./newsSaga";
import inquirySaga from "./inquirySaga";
import userSaga from "./sessionSaga";
import categorySaga from "./categorySaga";
import tagsSaga from "./tagsSaga";
import googleOAuthSaga from "./googleOAuthSaga";
import facilitiesSaga from "./facilitiesSaga";
import rejectSaga from "./rejectSaga";
import eventsSaga from "./eventsSaga";
import approvalSaga from "./approvalSaga";
import csvSaga from "./csvSaga";
import monthlyEventsSaga from "./monthlyEventsSaga";
import historiesSaga from "./historiesSaga";
import indexesSaga from "./indexesSaga";
import manualsSaga from "./manualsSaga";
import manualTagsSaga from "./manualTagsSaga";
import mediaFileSaga from "components/Camera/sagas/mediaFileSaga";
import mediaFilesSaga from "components/Camera/sagas/mediaFilesSaga";
import cameraClassesSaga from "components/Camera/sagas/cameraClassesSaga";
import cameraFacilitiesSaga from "components/Camera/sagas/cameraFacilitiesSaga";
import cameraTagsSaga from "components/Camera/sagas/cameraTagsSaga";
import cameraFacillityUsersSaga from "components/Camera/sagas/cameraFacilityUsers";

export default function* rootSaga() {
  yield all([
    ...accountSaga,
    ...articleSaga,
    ...newsSaga,
    ...inquirySaga,
    ...userSaga,
    ...categorySaga,
    ...tagsSaga,
    ...googleOAuthSaga,
    ...facilitiesSaga,
    ...eventsSaga,
    ...rejectSaga,
    ...approvalSaga,
    ...csvSaga,
    ...monthlyEventsSaga,
    ...historiesSaga,
    ...indexesSaga,
    ...manualsSaga,
    ...manualTagsSaga,
    ...mediaFileSaga,
    ...mediaFilesSaga,
    ...cameraClassesSaga,
    ...cameraFacilitiesSaga,
    ...cameraTagsSaga,
    ...cameraFacillityUsersSaga
  ]);
}
