import React from "react";
import {
  ToggleSwitchInputSC,
  ToggleSwitchLabelSC,
  ToggleSwitchPartsSC,
  ToggleSwitchSC
} from "./style";

interface ToggleSwitchProps {
  label: string;
  name: string;
  value: string;
  selected?: boolean;
  onChange: () => void;
  id: string;
}

export const ToggleSwitch: React.VFC<ToggleSwitchProps> = props => {
  const { label, name, value, selected, onChange, id } = props;
  return (
    <ToggleSwitchSC onClick={onChange}>
      <ToggleSwitchInputSC
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={selected}
        id={id}
      />
      <ToggleSwitchPartsSC />
      <ToggleSwitchLabelSC htmlFor={id}>{label}</ToggleSwitchLabelSC>
    </ToggleSwitchSC>
  );
};
