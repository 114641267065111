import React from "react";
import styled from "styled-components";
import checklist from "../../../../images/icons/checklist.svg";
import { classTransformer } from "../classTransformer";

type DayCellProps = {
  // eventName: string;
  events: any[];
  holiday: string;
  hasIcon?: boolean;
};
const ContentCellNew = (props: DayCellProps) => {
  const { events, holiday, hasIcon } = props;
  // 対象日のイベント情報を最適化して配列にする。
  const formattedEventArray = events.map((targetEvent, i) => {
    const { detail, content, usage_guide_flag, additional_information } =
      targetEvent;
    const targetContent = content !== null ? content : "";
    const targetDetail = detail !== null ? detail : "";
    const targetClasses = targetEvent.class !== null ? targetEvent.class : "";
    const eventName = targetEvent?.event_type?.name ?? "";
    const isNameAndContentSame = eventName === targetContent;
    const targetAdditionalInformation =
      additional_information !== null ? additional_information : null;
    const formattedContent =
      targetContent.length !== 0 &&
      (eventName === "その他" || eventName === "成長を伝える行事")
        ? `${targetContent}`
        : targetContent.length !== 0
        ? `(${targetContent})`
        : "";
    const formattedDetail = targetDetail.length !== 0 ? `@${targetDetail}` : "";
    const formattedClasses =
      targetClasses.length !== 0
        ? `(${classTransformer(targetClasses)}歳児)`
        : "";
    const separator = i === 0 || usage_guide_flag ? "" : "/";

    // 凡例行事フラグがTrueの行事は表示しない
    if (usage_guide_flag) {
      return {
        formattedEvent: "",
        length: 0
      };
    }

    // 行事名がその他もしくは成長を伝える行事だったら行事名は表示しない
    if (eventName === "その他" || eventName === "成長を伝える行事") {
      const formattedEvent = `${separator}${formattedContent}${formattedDetail}${formattedClasses}`;
      return {
        formattedEvent,
        length: formattedEvent.length
      };
    } else if (targetContent === "徒歩遠足") {
      // 行事予定が徒歩遠足（複数入力の場合）
      if (!targetAdditionalInformation) {
        // 複数入力の実装前に入力されたデータの場合
        const formattedEvent = `${separator}${eventName}${formattedDetail}${formattedClasses}`;
        return {
          formattedEvent,
          length: formattedEvent.length
        };
      }
      // targetAdditionalInformationを${eventName}@${formattedDetail}${formattedClasses}/の形式に変換
      const formattedAdditionalInformation = targetAdditionalInformation
        .map((info: { class: string[]; destination: string }) => {
          const { class: classInfo, destination } = info;
          //classInfoをカンマで区切る
          const formattedClassInfo = classInfo.join(",");
          const transfoemedClassInfo = classTransformer(formattedClassInfo);
          return `${destination}(${transfoemedClassInfo}歳児)`;
        })
        .join("、");
      const formattedEvent = `${separator}${eventName}@${formattedAdditionalInformation}`;
      return {
        formattedEvent,
        length: formattedAdditionalInformation.length
      };
    } else if (targetEvent.event_type.show_event_flag) {
      // 行事内容が必須であれば、行事内容表示
      const formattedEvent = `${separator}${eventName}${
        !isNameAndContentSame ? formattedContent : ""
      }${formattedDetail}${formattedClasses}`;
      return {
        formattedEvent,
        length: formattedEvent.length
      };
    } else {
      const formattedEvent = `${separator}${eventName}${formattedDetail}${formattedClasses}`;
      return {
        formattedEvent,
        length: formattedEvent.length
      };
    }
  });

  // 該当日のイベントデータを一つのオブジェクトに集約。
  const formattedEventData = formattedEventArray.reduce(
    (cv, pv) => {
      return {
        formattedEvent: cv.formattedEvent + pv.formattedEvent,
        length: cv.length + pv.length
      };
    },
    {
      formattedEvent: "",
      length: 0
    }
  );
  const { formattedEvent: formattedEventName } = formattedEventData;
  const splitEventName = formattedEventName.split("");
  return (
    <EventCellSC>
      <Content
        className={
          formattedEventName.length > 84
            ? "x-small"
            : formattedEventName.length > 63
            ? "small"
            : ""
        }
      >
        {holiday && holiday.length !== 0 && `${holiday} `}
        {splitEventName.map((chr: string) => {
          return <span className="word">{chr}</span>;
        })}
      </Content>
      <IconCell>{hasIcon && <Icon src={checklist} alt="凡例" />}</IconCell>
    </EventCellSC>
  );
};

export default ContentCellNew;

const EventCellSC = styled.div`
  width: 100%;
  text-align: left;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Content = styled.p`
  word-break: break-all;
  .word {
    font-size: 9px;
  }
  &.x-small {
    line-height: 0.6;
    .word {
      display: inline-block;
      transform-origin: 0px 3px;
      vertical-align: bottom;
      font-size: 6px;
    }
  }
  &.small {
    line-height: 0.7;
    .word {
      display: inline-block;
      transform-origin: 0px 2px;
      vertical-align: bottom;
      font-size: 7px;
    }
  }
`;

const IconCell = styled.div`
  width: 15px;
  display: flex;
  align-items: center;
`;
const Icon = styled.img`
  width: 15px;
  height: 15px;
  display: block;
`;
