import {GET_REGISTERED_TAGS_REQUESTED, DELETE_TAG_ITEM_FROM_VIEW, UPDATE_VIEW_TAG_NAME, UPDATE_VIEW_TAG_ORDER, DELETE_TAG_ITEM_REQUESTED, POST_NEW_TAG_ITEM_REQUESTED, UPDATE_TAG_ITEM_REQUESTED} from './type'

export const updateViewTagName = ( updated_name:string, updated_target_id: number ) => {
  return  {
    type: UPDATE_VIEW_TAG_NAME,
    updated_name,
    updated_target_id
  }
}

export const updateViewTagOrder = ( updated_order:number, updated_target_id: number ) => {
  return  {
    type: UPDATE_VIEW_TAG_ORDER,
    updated_order,
    updated_target_id
  }
}

export const deleteTagItemFromView = (deleteOrder:number) => {
  return {
    type: DELETE_TAG_ITEM_FROM_VIEW,
    deleteOrder
  }
}

export const getRegisteredTagsRequested = (token: string) => 
{
  return {
    type:GET_REGISTERED_TAGS_REQUESTED,
    token
  } 
}

export const deleteTagItemRequested = (token: string, id: number) => {
  return {
    type: DELETE_TAG_ITEM_REQUESTED,
    token,
    id
  }
}

export const postTagItemRequested = (token: string, new_tag_name: string, new_tag_order:number, temp_id:number) => {
  return {
    type: POST_NEW_TAG_ITEM_REQUESTED,
    token,
    new_tag_name,
    new_tag_order,
    temp_id
  }
}

export const updateTagItemRequested = (token: string, id: number, updated_name: string, updated_order: number,) => {
  return {
    type: UPDATE_TAG_ITEM_REQUESTED,
    token,
    updated_name,
    updated_order,
    id
  }
}