import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FlexPageLayout } from "../Parts/MainLayout";
import SearchModuleForManual from "../SearchBlockForManual";
import ModuleArea from "../Parts/ModuleArea";
import PagesBlock from "./PagesBlock";

import { useTopHooks } from "../Top/Hooks";
import { useHooks } from "../Admin/Pages/Hooks";
import UpdateHistoriesModule from "../UpdateHistoriesModule";
import { useLocation } from "react-router-dom";
import { toggleRightSection } from "../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../styles/layout/containers";

function Page() {
  const { histories, tags } = useTopHooks();
  const dispatch = useDispatch();
  const { hookedPages, hookedTopic, manualId, topicId, topicName } = useHooks(
    {}
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  const location = useLocation();
  const pathName = location.pathname;
  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <FlexPageLayout
      pageTitle={hookedTopic.name}
      topicTitle={topicName}
      toTopicsPath={`/manual/manuals/${manualId}/`}
    >
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <PagesBlock pages={hookedPages} manualId={Number(manualId)} />
      <ModuleArea>
        <SearchModuleForManual
          manualId={Number(manualId)}
          topicId={Number(topicId)}
          data={tags}
        />
        <UpdateHistoriesModule data={histories} />
      </ModuleArea>
    </FlexPageLayout>
  );
}

export default Page;
