import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToBeCreateManualTag,
  addToBeDeletedManualTag,
  addTobeUpdatedManualTag,
  changeNameToBeCratedManualTag,
  changeNameToBeUpdatedManualTag,
  changeOrderToBeCratedManualTag,
  changeOrderToBeUpdatedManualTag,
  clickUpdateBtnClickAction,
  deleteTobeCreatedManualTag,
  updateViewManualTagName,
  updateViewManualTagOrder
} from "store/manual/tags/actions";
import {
  toBeCreateManualTagSelector,
  manualTagsSelector,
  toBeUpdatedManualTagSelector,
  toBeDeletedManualTagSelector
} from "store/manual/tags/selector";

const useHooks = () => {
  const tags = useSelector(manualTagsSelector);
  const token = useSelector((state: any) => state.session.token);
  const tagsToBeCreated = useSelector(toBeCreateManualTagSelector);
  const tagsToBeUpdated = useSelector(toBeUpdatedManualTagSelector);
  const tagsToBeDeleted = useSelector(toBeDeletedManualTagSelector);
  const dispatch = useDispatch();

  // handlers
  const handleAddBtnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(addToBeCreateManualTag());
  };

  const handleChangeNameTobeCreated = (data: {
    target_id: number;
    value: string;
  }) => {
    const { target_id, value } = data;
    dispatch(changeNameToBeCratedManualTag({ target_id, value }));
  };

  const handleChangeOrderTobeCreated = (data: {
    target_id: number;
    order: number;
  }) => {
    const { target_id, order } = data;
    dispatch(changeOrderToBeCratedManualTag({ target_id, order }));
  };

  const handleChangeNameTobeUpdated = (data: {
    target_id: number;
    value: string;
  }) => {
    const { target_id, value } = data;
    dispatch(updateViewManualTagName({ target_id, value }));
    dispatch(changeNameToBeUpdatedManualTag({ target_id, value }));
  };

  const handleChangeOrderTobeUpdated = (data: {
    target_id: number;
    order: number;
  }) => {
    const { target_id, order } = data;
    dispatch(updateViewManualTagOrder({ target_id, order }));
    dispatch(changeOrderToBeUpdatedManualTag({ target_id, order }));
  };

  const handleClickDeleteBtnToBeCreated = (
    e: React.MouseEvent<HTMLElement>,
    target_id: number
  ) => {
    e.preventDefault();
    dispatch(deleteTobeCreatedManualTag(target_id));
  };

  const handleFocusInput = (target_id: number) => {
    dispatch(addTobeUpdatedManualTag(target_id));
  };

  const handleClickDeleteBtn = (
    e: React.MouseEvent<HTMLElement>,
    target_id: number
  ) => {
    e.preventDefault();
    dispatch(addToBeDeletedManualTag(target_id));
  };

  const handleClickUpdateBtn = () => {
    dispatch(
      clickUpdateBtnClickAction({
        token: token,
        to_be_created_tags: tagsToBeCreated,
        to_be_updated_tags: tagsToBeUpdated,
        to_be_deleted_ids: tagsToBeDeleted
      })
    );
  };

  const handlers = {
    changeNameTobeCreated: handleChangeNameTobeCreated,
    changeOrderTobeCreated: handleChangeOrderTobeCreated,
    clickAddBtn: handleAddBtnClick,
    clickDeleteBtn: handleClickDeleteBtn,
    clickDeleteTobeCreated: handleClickDeleteBtnToBeCreated,
    focusTagsInput: handleFocusInput,
    changeNameTobeUpdated: handleChangeNameTobeUpdated,
    changeOrderTobeUpdated: handleChangeOrderTobeUpdated
  };

  const data = React.useMemo(() => {
    return {
      tags,
      tagsToBeCreated
    };
  }, [tags, tagsToBeCreated]);

  return {
    data,
    handlers,
    handleClickUpdateBtn
  };
};

export default useHooks;
