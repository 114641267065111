import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { manualManualsSelector } from "store/manual/manuals/selectors";
import { updateAllManualsRequest } from "store/manual/manuals/actions";
import { ManualItemForSelector } from "store/manual/manuals/types.d";
import { historiesSelector } from "store/manual/histories/selectors";
import { manual } from "components/Sidebar/sidebarIcons";

export const useHooks = (data: {
  title?: string;
  manuals?: ManualItemForSelector[];
}) => {
  const [editTitleMode, setEditTitleMode] = useState<boolean>(false);
  const [editOrderMode, setEditOrderMode] = useState<boolean>(false);
  const [manualTitle, setManualTitle] = useState<string>(data.title || "");

  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const histories = useSelector(historiesSelector);
  const sidebar_is_open = useSelector(
    (state: any) => state.sidebar.sidebarIsOpen
  );

  const manuals = useSelector(manualManualsSelector);

  const [hookedManuals, setHookedManuals] = useState(manuals ? manuals : []);
  const [toBeDeletedManuals, setToBeDeletedManuals] = useState<
    ManualItemForSelector[]
  >([]);

  // +アイコンで末尾にアイテムを追加
  const handleAdd = (hookedManuals: ManualItemForSelector[]) => {
    setHookedManuals([
      ...hookedManuals,
      {
        id: null,
        name: "",
        catch: "",
        description: "",
        order: hookedManuals.length + 1,
        updated_at: new Date().toISOString(),
        image: null
      }
    ]);
  };

  // マニュアルの順番を変更
  const changeManualsOrder = (sortedManuals: ManualItemForSelector[]) => {
    const orderUpdatedManuals = sortedManuals.map((manual, index) => {
      return {
        ...manual,
        order: index + 1
      };
    });
    setHookedManuals(orderUpdatedManuals);
  };

  // マニュアルのタイトル編集
  const updateManualTitle = (data: { order: number; new_name: string }) => {
    const updatedHookedManuals = hookedManuals.map(
      (manual: any, index: number) => {
        if (manual.order === data.order) {
          return {
            ...manual,
            name: data.new_name
          };
        }
        return manual;
      }
    );
    setHookedManuals(updatedHookedManuals);
  };

  // 削除用配列に追加
  const removeHookedItem = (data: { manual: ManualItemForSelector }) => {
    console.log("hookedManuals before", hookedManuals);
    console.log("toBeDeletedManuals before", toBeDeletedManuals);

    // 追加ボタンを押した後の空アイテムを削除する場合
    if (data.manual.id === null) {
      const itemRemovedHookedManuals = hookedManuals.filter(
        (manual: any) => manual.order !== data.manual.order
      );
      setHookedManuals(itemRemovedHookedManuals);
    } else {
      // 実データを削除する場合
      const itemRemovedHookedManuals = hookedManuals.filter(
        (manual: any) => manual.id !== data.manual.id
      );
      setHookedManuals(itemRemovedHookedManuals);
      setToBeDeletedManuals([...toBeDeletedManuals, data.manual]);
      console.log("hookedManuals after", hookedManuals);
      console.log("toBeDeletedManuals after", toBeDeletedManuals);
    }
  };

  // 「完了」ボタンでリクエスト
  const updateAllManualsAction = (data: {
    req_body: ManualItemForSelector[];
  }) => {
    const sanitized_req_body = data.req_body.filter(
      manual => manual.name !== ""
    );
    const reordered_sanitized_req_body = sanitized_req_body.map(
      (manual, index) => {
        return {
          ...manual,
          order: index + 1
        };
      }
    );
    const req_body = reordered_sanitized_req_body.map(manual => {
      return {
        id: manual.id,
        name: manual.name,
        catch: manual.catch ? manual.catch : "",
        description: manual.description ? manual.description : "",
        order: manual.order,
        history_flag: false
      };
    });
    const to_be_deleted_ids =
      toBeDeletedManuals && toBeDeletedManuals.length > 0
        ? toBeDeletedManuals.map(manual => manual.id)
        : null;
    dispatch(
      updateAllManualsRequest({
        token: token,
        req_body: req_body,
        to_be_deleted_ids: to_be_deleted_ids
      })
    );
  };

  // 編集中、「キャンセル」押下で編集破棄
  const cancelEdit = () => {
    setHookedManuals(manuals);
  };

  return {
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    manualTitle,
    setManualTitle,
    manuals,
    histories,
    hookedManuals,
    setHookedManuals,
    sidebar_is_open,
    updateAllManualsAction,
    changeManualsOrder,
    updateManualTitle,
    handleAdd,
    removeHookedItem,
    cancelEdit
  };
};
