import React, { useState, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { updateSelectedMediaFilesRequested } from "store/camera/mediafiles/action";
import { numArrayGen } from "utils/numArrayGen";
import { isMultiValue } from "components/Camera/type";

export const useBatchSettingModal = (
  token: string,
  selectedMedia: number[],
  isAdmin: boolean
) => {
  const dispatch = useDispatch();
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  // 選択済みタグ（モーダル）
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  // 選択済みクラス（モーダル）
  const [selectedClassIds, setSelectedClassIds] = useState<number[]>([]);
  // 選択済み施設（モーダル）
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<number[]>([]);
  // 公開状態
  const [isShareBan, setIsShareBan] = useState<boolean>(false);
  // 一括設定モーダル開閉
  const toggleBatchSettingModal = () => {
    setSettingModalOpen(state => !state);
  };
  // 一括設定クラス選択
  const handleChangeSettingClasses = (id: number) => {
    setSelectedClassIds(state => numArrayGen(state, id));
  };
  // 一括設定タグ選択
  const handleChangeSettingTags = (id: number) => {
    setSelectedTagIds(state => numArrayGen(state, id));
  };
  // 一括設定施設選択
  const handleChangeSettingFacility = (value: isMultiValue) => {
    const newValue = value.map(v => Number(v.value));
    setSelectedFacilityIds(newValue);
  };
  const handleChangeSetttingShareBan = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "private") {
      setIsShareBan(true);
    }
    if (e.target.value === "published") {
      setIsShareBan(false);
    }
  };
  // 一括更新
  const handleSelectedUpdateMediaFiles = async (targets: string[]) => {
    const body = {
      fileIds: selectedMedia,
      selectedClassIds: targets.includes("class") ? selectedClassIds : null,
      selectedFacilityIds: targets.includes("facility")
        ? selectedFacilityIds
        : null,
      selectedTagIds: targets.includes("tag") ? selectedTagIds : null,
      isAdmin: isAdmin
    };
    dispatch(updateSelectedMediaFilesRequested(token, body));
    setSettingModalOpen(false);
    setSelectedTagIds([]);
    setSelectedClassIds([]);
    setSelectedFacilityIds([]);
  };
  return {
    open: settingModalOpen,
    toggleBatchSettingModal,
    handleChangeSettingClasses,
    handleChangeSettingTags,
    handleChangeSettingFacility,
    handleChangeSetttingShareBan,
    handleSelectedUpdateMediaFiles
  };
};
