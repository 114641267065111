import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import searchOpen from "../../../images/icons/down_arrow_green.svg";
import searchIcon from "../../../images/icons/search.svg";
import { setAccountEmailToSearch } from "../../../store/common/paginate/action";
import { getAccountsRequest } from "../../../store/global/accounts/action";
import { toggleSearchAccountIsOpened } from "../../../store/session/action";
import { animateScroll as scroll } from "react-scroll";

function AccountsSearchBlock() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.account_per_page
  );
  const account_email_to_search = useSelector(
    (state: any) => state.paginate.account_email_to_search
  );
  const searchIsOpened = useSelector(
    (state: any) => state.session.searchAccountIsOpened
  );
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );

  const handleEmailInput = (e: any) => {
    dispatch(setAccountEmailToSearch(e.target.value));
  };

  const handleAccountSearch = (e: any) => {
    e.preventDefault();
    scroll.scrollToTop({ duration: 200 });
    dispatch(
      getAccountsRequest(token, 1, paginate_per_page, account_email_to_search)
    );
  };

  window.onscroll = function () {
    const searchBlock = document.getElementById("account_search_block");

    if (window.innerWidth <= 1024) {
      if (document.documentElement.scrollTop > 30) {
        if (searchBlock) {
          searchBlock.classList.add("fixed");
        }
      } else {
        if (searchBlock) {
          searchBlock.classList.remove("fixed");
        }
      }
    } else {
      if (document.documentElement.scrollTop > 106) {
        if (searchBlock) {
          searchBlock.classList.add("fixed");
        }
      } else {
        if (searchBlock) {
          searchBlock.classList.remove("fixed");
        }
      }
    }
  };

  return (
    <AccountsSearchBlockSC
      id="account_search_block"
      className={rightSectionOpen ? "open" : "close"}
    >
      <AccountsSearchBlockHeaderSC
        onClick={() => dispatch(toggleSearchAccountIsOpened(searchIsOpened))}
      >
        <AccountsSearchBlockHeadingSC>検索条件</AccountsSearchBlockHeadingSC>
        <ToggleArrowSC
          className={searchIsOpened ? "open" : ""}
          src={searchOpen}
          alt="toggleArrow"
        />
      </AccountsSearchBlockHeaderSC>

      {/* 検索条件 */}
      <SearchForm className={searchIsOpened ? "open" : ""} action="">
        <SearchContentsSC>
          {/* キーワード */}
          <SearchContentsLabelSC>メールアドレス</SearchContentsLabelSC>
          <dd>
            <KeywordInput
              type="text"
              value={account_email_to_search}
              placeholder="メールアドレスを入力"
              onChange={handleEmailInput}
            />
          </dd>
        </SearchContentsSC>
        <SearchButton onClick={handleAccountSearch}>
          <img src={searchIcon} alt="search" />
          この条件で検索
        </SearchButton>
      </SearchForm>
    </AccountsSearchBlockSC>
  );
}

export default AccountsSearchBlock;

// ===================================================================================
// スタイル
// ===================================================================================
const AccountsSearchBlockSC = styled.div`
  width: 100%;
  max-width: calc(100% - 24px);
  background: #ffffff;
  margin-bottom: 10px;
  padding: 20px 16px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  color: ${color.primary};
  position: absolute;
  &.close {
    display: none;
  }
  ${pc`
    max-width: 360px;
    width: 34.5%;
    margin-bottom: 0;
    padding: 30px;
    order: 2;
    position: static;
  `}
  &.fixed {
    display: block;
    position: fixed;
    top: 56px;
    width: calc(100% - 24px);
    border-radius: 6px;
    z-index: 99;
    ${pc`
    top: 67px;
    right: 40px;
    width: calc((100% - 260px) * 0.345);
    &.close {
      right: 40px;
    }
    `}
    @media(min-width: 1527px) {
      left: calc(100vw - 400px);
    }
  }
`;

const AccountsSearchBlockHeaderSC = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AccountsSearchBlockHeadingSC = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  padding-right: 10px;
`;

const ToggleArrowSC = styled.img`
  display: inline-block;
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  fill: ${color.primary};
  &.open {
    transition-duration: 0.2s;
    transform: rotate(-180deg);
  }
`;
// =====================================
// SerchForm  ==========================
const SearchForm = styled.form`
  margin-top: 20px;
  max-height: calc(100vh - 242px);
  display: none;
  overflow: auto;
  &.open {
    display: block;
  }
`;

const SearchContentsSC = styled.dl`
  margin-bottom: 8px;
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.gray};
  margin-bottom: 12px;
`;

const KeywordInput = styled.input`
  display: block;
  width: 100%;
  padding: 0px 0px 12px 6px;
  border-bottom: 1px solid ${color.divide_border};
  margin-bottom: 20px;
  &::placeholder {
    font-size: 12px;
    color: ${color.category_bg};
  }
`;

const SearchButton = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  color: ${color.white};
  background-color: ${color.primary};
  font-weight: bold;
  padding: 9px;
  font-size: 12px;
  border-radius: 4px;
  img {
    transform: translateY(-1px);
    margin-right: 5px;
  }
  &:hover {
    opacity: 0.7;
  }
`;
// SerchForm  ==========================
//======================================
