import styled from "styled-components";
import Calendar from "../../images/Icon-calendar.svg";

export const SearchBlockContainerSC = styled.div`
  border-radius: 4px;
`;

export const CommonSearchBlockSC = styled.div`
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const FacilitySearchBlockSC = styled.div`
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 4px;
`;

export const FlexWrapperSC = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 16px;
`;
export const WrapperSC = styled.div`
  margin-top: 16px;
`;

export const BtnWrapperSC = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

export const LabelSC = styled.label<{ fullWidth?: boolean }>`
  color: #888;
  font-weight: 600;
  font-size: 12px;
  display: block;
  width: ${props => (props.fullWidth ? "100%" : "60px")};
  ${({ fullWidth }) => fullWidth && `margin-bottom: 8px`}
`;

export const DatePickerWrapper = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 152px;
  .camera_calendar_wrapper {
    background-image: url(${Calendar});
    width: 100%;
    background-position: center left 8px;
    border: none !important;
  }
`;

export const DatePickButtonSC = styled.button`
  cursor: pointer;
  font-size: 11px;
  padding: 8px 30px 8px 30px;
  border: none;
`;

export const ResetBtn = styled.button`
  padding: 4px;
  img {
    display: block;
    width: 12px;
    height: 12px;
  }
`;
