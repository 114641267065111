import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";

function EventsItem(props: any) {
  const dispatch = useDispatch();
  const position_id = useSelector((state: any) => state.session.position.id);
  const agriculturalPlanItems = useSelector((state: any) => state.monthly_events.agricultural_plans);
  const approval_monthly_status = useSelector((state: any) => state.approval.monthly_status);
  const agriculturalPlanTarget = agriculturalPlanItems.find((monthly_plan: any) => {
    return new Date(monthly_plan.date).getMonth() === new Date(props.date).getMonth();
  });
  const month = new Date(props.date).getMonth() + 1;

  let isEditableClass = "";
    if (
      ((position_id === 5 || position_id === 4 || position_id === 1) && approval_monthly_status.some((status_item: any) => status_item.leader_approved === true && `${new Date(props.date).getFullYear()}-${("0" + (new Date(props.date).getMonth() + 1)).slice(-2)}`.includes(status_item.month)))
      ||
      // プロジェクト管理者（6）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
      (position_id === 6 && approval_monthly_status.some((status_item: any) => status_item.administrator_approved === true && `${new Date(props.date).getFullYear()}-${("0" + (new Date(props.date).getMonth() + 1)).slice(-2)}`.includes(status_item.month)))
      ||
      // 本部（2）で、施設長承認済みの月の月文字列（ex: "2022-04"）が現在の日付の日付文字列（ex: "2022-04-01"）内に含まれる場合
      ((position_id === 2 || position_id === 3) && approval_monthly_status.some((status_item: any) => status_item.director_approved === true && `${new Date(props.date).getFullYear()}-${("0" + (new Date(props.date).getMonth() + 1)).slice(-2)}`.includes(status_item.month)))
    ) {
      isEditableClass = "not_editable"
    } else {
    isEditableClass = "";
  }

  return (
    <>
      {agriculturalPlanTarget ? (
        <EventsItemSC   
          className={isEditableClass}
          style={
            agriculturalPlanTarget.status !== 1 && agriculturalPlanTarget.status !== 2 ?
            {backgroundColor:`${color.divide_border}`, cursor: 'default'}
            :
            {}
          }
          >
          <EventsItemCheckboxWrapper>
            <EventsItemCheckbox
              id={agriculturalPlanTarget.type.id}
              type="checkbox"
              name="events_item"
              // onClick={handleClickCheckbox}
            />
            <div style={{ paddingLeft: "40px"}}></div>
            {/* <EventsItemCheckboxLabel htmlFor={agriculturalPlanTarget.type.id} /> */}
          </EventsItemCheckboxWrapper>
          <EventsItemContent className="title">{month}月:農業計画</EventsItemContent>
          <EventsItemContent className="content">{agriculturalPlanTarget.content}</EventsItemContent>
        </EventsItemSC>
      ) : (
        <EventsItemSC
          className={isEditableClass}
        >
          <EventsItemCheckboxWrapper>
            <EventsItemCheckbox type="checkbox" name="events_item" disabled />
            <div style={{ paddingLeft: "40px"}}></div>
            {/* <EventsItemCheckboxLabel /> */}
          </EventsItemCheckboxWrapper>
          <EventsItemContent className="title">{month}月:農業計画</EventsItemContent>
          <EventsItemContent className="content"></EventsItemContent>
        </EventsItemSC>
      )}
    </>
  );
}

export default EventsItem;

const EventsItemSC = styled.li`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  padding: 0 59px 0 18px;
  border-bottom: 1px solid ${color.list_border};
  &:last-of-type {
    border-bottom: none;
  }
  /* cursor: pointer; */
  &.not_editable {
    background-color: ${color.divide_border};
  }
`;

const EventsItemWrapper = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

const EventsItemCheckboxWrapper = styled.li`
  padding-top: 25px;
`;

const EventsItemCheckbox = styled.input`
  display: none;
  &:checked + label::before {
    background-color: ${color.gray};
  }
  &:checked + label::after {
    content: "";
    width: 6px;
    height: 10px;
    border-bottom: 2px solid ${color.white};
    border-right: 2px solid ${color.white};
    transform: rotate(40deg);
    position: absolute;
    top: 0px;
    left: 5px;
  }
`;
const EventsItemCheckboxLabel = styled.label`
  padding-right: 39px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${color.gray}; /* 枠の色変更 お好きな色を */
    background-color: ${color.white}; /* 背景の色変更 お好きな色を */
  }
  &.hover {
    /* cursor: pointer; */
  }
`;

const EventsremandButton = styled.img`
  position: absolute;
  right: 16px;
  top: 25px;
`;

const EventsItemAddButton = styled.img`
  position: absolute;
  right: -11px;
  top: 25px;
  /* cursor: pointer; */
`;

const EventsItemContent = styled.li`
  padding: 25px 0 21px;
  font-size: 14px;
  color: #444;
  flex-shrink: 0;
  &:hover + li {
    display: block;
  }
  &.title {
    font-weight: bold;
    width: calc(14%);
  }
  &.content {
    width: calc(86%);
  }
  &.pointer {
    &:hover {
      /* cursor: pointer; */
    }
  }
`;

const EventsItemHiddenContent = styled.li`
  display: none;
  position: absolute;
  top: 12px;
  left: 4px;
  border: 1px solid ${color.light_gray};
  border-radius: 4px;
  padding: 11px;
  background-color: ${color.white};
  font-size: 14px;
  color: ${color.gray};
  &.title {
    left: calc(57px + (100%) * 0.13 + 5px);
    &.hover {
      display: block;
    }
  }
  &.content {
    left: calc(57px + (100% - 77px) * 0.32 + 7px);
  }
  &.detail {
    left: calc(57px + (100% - 77px) * 0.54 + 7px);
  }
  &.class_age {
    left: calc(57px + (100% - 77px) * 0.81 + 7px);
  }
  &:hover {
    display: block;
  }
`;
