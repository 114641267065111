import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { color } from "../../../mixins";
import close from "../../../../images/icons/close.svg";

import { toggleCancelYearMonthsEventsModal } from "../../../../store/common/modal/action";
import { cancelYearMonthsEventsRequest } from "../../../../store/event/approval/action";

export default function ReturnEventsModalContent() {
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const to_be_approved = useSelector(
    (state: any) => state.approval.to_be_approved
  );
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const handleCancelYearMonthsSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      cancelYearMonthsEventsRequest(
        token,
        facility_id,
        to_be_approved,
        filtered_year,
        filtered_months
      )
    );
    // to_be_rejected.forEach((item: any) => dispatch(postRejectRequest(token, item.id, item.comment, facility_id, year)));
  };

  return (
    <ReturnModalContentSC>
      <TitleBlock>
        <Title>行事予定承認取り消し</Title>
        <CloseIcon
          src={close}
          alt="閉じるボタン"
          onClick={() => dispatch(toggleCancelYearMonthsEventsModal(false))}
        />
      </TitleBlock>
      <AddApprovalEventsBlock>
        <AddApprovalEventsBlockDesc>
          以下の月の行事予定を承認を取り消ししますがよろしいですか？
        </AddApprovalEventsBlockDesc>

        <AddApprovalEventsBlockSelectDesc>
          {to_be_approved &&
            to_be_approved.length > 0 &&
            to_be_approved.map((item: any, index: number) => {
              if (to_be_approved.length - 1 === index) {
                return `${new Date(item).getMonth() + 1}月`;
              }
              return `${new Date(item).getMonth() + 1}月、`;
            })}
        </AddApprovalEventsBlockSelectDesc>

        <Buttons>
          <CancelButton
            onClick={() => dispatch(toggleCancelYearMonthsEventsModal(false))}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            value="取り消し"
            onClick={handleCancelYearMonthsSubmit}
          />
        </Buttons>
      </AddApprovalEventsBlock>
      {/* {
        <ReturnEventsForm>
          <ReturnEventsFormDesc>
            全ての行事予定の承認状況を一括で取り消します。
            <br />
            <span style={{ lineHeight: "21px", fontSize: "12px" }}>
              ※この操作は取り消せません。
            </span>
          </ReturnEventsFormDesc>

          <Buttons>
            <CancelButton
              onClick={() => dispatch(toggleCancelAllEventsModal(false))}
            >
              キャンセル
            </CancelButton>
            <SubmitButton
              type="submit"
              value="一括取り消し"
              onClick={handleCancelAllSubmit}
            />
          </Buttons>
        </ReturnEventsForm>
      } */}
    </ReturnModalContentSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const ReturnModalContentSC = styled.div`
  max-width: 780px;
  width: 90%;
  position: fixed;
  z-index: 120;
  background-color: ${color.white};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
`;

const TitleBlock = styled.div`
  height: 56px;
  width: 100%;
  background-color: ${color.list_header};
  margin: 0;
  padding: 20px 24px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 16px;
  color: ${color.gray};
  font-weight: 300;
  margin: 0;
`;

const CloseIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const AddApprovalEventsBlock = styled.div`
  padding: 33px 54px 30px;
`;

const AddApprovalEventsBlockDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  span {
    margin-bottom: 10px;
    display: block;
  }
`;

const AddApprovalEventsBlockSelectDesc = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${color.gray};
  background-color: ${color.white};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  margin-right: 12px;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  font-weight: 600;
  font-size: 14px;
  color: ${color.white};
  background-color: ${color.gray};
  border-radius: 4px;
  border: 1px solid ${color.gray};
  width: 160px;
  height: 38px;
  text-align: center;
  padding: 12px 0;
  &:disabled {
    opacity: 0.6;
    cursor: none;
  }
`;
