import React from "react";
import styled from "styled-components";
import { color, buttonBaseStyle } from "components/mixins";
import { pc } from "components/mixins/mediaQuery";

interface Props {
  sidebarIsOpen: boolean;
  requiredCount: number;
  onClickButton: () => void;
}
export const FixedInquiryButton = (props: Props) => {
  const { sidebarIsOpen, requiredCount, onClickButton } = props;
  return (
    <FixedButtonAreaSC className={sidebarIsOpen ? "" : "sidebar-closed"}>
      <SubmitButtonSC className="active" onClick={onClickButton}>
        送信
      </SubmitButtonSC>
    </FixedButtonAreaSC>
  );
};

const FixedButtonAreaSC = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  //redux管理でサイドバーが開いたり閉じたりしたときのwidthは可変させる
  width: 100%;
  height: 65px;
  padding: 10px 0 14px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.white};
  ${pc`
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    margin-left: -60px;
    width: calc(100vw - 200px);
    height: 70px;
    padding: 16px 0;
    box-shadow: none;
  `}
  &.sidebar-closed {
    width: 100%;
    ${pc`
      width: calc(100vw - 50px);
    `}
  }
`;

const ButtonAreaDeskSC = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  ${pc`
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 14px;
  `}
  span {
    font-weight: bold;
  }
`;

const SubmitButtonSC = styled.button`
  ${buttonBaseStyle}
`;
