import { POST_NEWS_REQUESTED, PUT_NEWS_REQUESTED, INPUT_NEW_NEWS_TITLE, SELECT_NEW_NEWS_STATUS, SET_NEWS_RAW_CONTENT, TOGGLE_NEW_NEWS_POST_STATUS } from "./type";

// =====================================TOGGLE_CHECKED_NEWS_CATEGORY============================ //
// Actions
// ----------------------------------------------------------------- //

// 記事情報登録
export const postNewsRequest = (token: string, new_news_title: string, new_news_content: any, new_news_status: number,) => {
  return {
    type: POST_NEWS_REQUESTED,
    token,
    new_news_title,
    new_news_content,
    new_news_status,
  };
};

// 記事情報登録
export const putNewsRequest = (new_news_id: number, token: string, title: string, content: any, status: number,) => {
  return {
    type: PUT_NEWS_REQUESTED,
    new_news_id,
    token,
    title,
    content,
    status,
  };
};


export const inputNewNewsTitle = (title: string) => {
  return {
    type: INPUT_NEW_NEWS_TITLE,
    title
  };
};

export const selectNewNewsStatus = (id: number) => {
  return {
    type: SELECT_NEW_NEWS_STATUS,
    id
  };
};

export const setNewsRawContent = (content: any) => {
  return {
    type: SET_NEWS_RAW_CONTENT,
    content
  }
}

export const toggleNewsNewPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_NEW_NEWS_POST_STATUS,
    post_status
  }
}