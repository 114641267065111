import React from "react";
import { Route, Redirect, Switch } from "react-router";

//FAQ ===================================================================================================
import Articles from "../components/Faq/Articles/Articles";
import ArticlesNew from "../components/Faq/Articles/ArticleNew/ArticleNew";
import ArticleEditPreview from "../components/Faq/Articles/ArticlePreview/ArticleEditPreview";
import ArticleNewPreview from "../components/Faq/Articles/ArticlePreview/ArticleNewPreview";
import ArticleDetail from "../components/Faq/Articles/ArticleDetail/ArticleDetail";
import ArticleDetailForStocks from "../components/Faq/Articles/ArticleDetail/ArticleDetailForStocks";
import ArticleEdit from "../components/Faq/Articles/ArticleEdit/ArticleEdit";
import ArticlesStocks from "../components/Faq/Articles/ArticlesStock/ArticlesStocks";

import Categories from "../components/Faq/Master/Categories";
import Tags from "../components/Faq/Master/Tags";

import Inquiries from "../components/Faq/Inquiries/Inquiries";
import InquiryNew from "../components/Faq/Inquiries/InquiryNew/InquiryNew";
import InquiriesComplete from "../components/Faq/Inquiries/InquiriesComplete";
import InquiriesDetail from "../components/Faq/Inquiries/InquiriesDetail";
import InquiryNewV2 from "components/Faq/Inquiries/InquiryNewV2";
//=========================================================================================================

//行事予定 ==================================================================================================
import EventTop from "../components/Events/Top/EventsTop";

import Events from "../components/Events/Events/Events";

import Status from "../components/Events/Status/Status";
import StatusMonthly from "../components/Events/Status/StatusMonthly/StatusMonthly";

import Approval from "../components/Events/Approval/Approval";

import CSVUpload from "../components/Events/CSVUpload/CSVUpload";
import CSVUploadCompleted from "../components/Events/CSVUpload/CSVUploadCompleted";
//=========================================================================================================

// マニュアル ================================================================================================
import ManualTop from "../components/Manual/Top/ManualTop";
import ManualsTopics from "../components/Manual/Topics/Topics";
import ManualsPages from "../components/Manual/Pages/Pages";
import ManualsPageDetail from "../components/Manual/PageDetail/PageDetail";

import ManualsAdmin from "../components/Manual/Admin/Manuals/Manuals";
import ManualsAdminTopics from "../components/Manual/Admin/Topics/Topics";
import ManualsAdminPages from "../components/Manual/Admin/Pages/Pages";
import ManualsAdminResults from "../components/Manual/Admin/Results/Results";
import ManualsAdminPageDetail from "../components/Manual/Admin/PageDetail/PageDetail";
import ManualsAdminTags from "../components/Manual/Admin/Tags/Tags";
import ManualsAdminUpdateHistories from "../components/Manual/Admin/UpdateHistories/UpdateHistories";
//=========================================================================================================

//共通ページ ================================================================================================
import Accounts from "../components/Global/Accounts/Accounts";
import AccountNew from "../components/Global/Accounts/AccountNew/AccountNew";
import AccountDetail from "../components/Global/Accounts/AccountDetail/AccountDetail";
import AccountEdit from "../components/Global/Accounts/AccountEdit/AccountEdit";

import News from "../components/Global/News/News";
import NewsNew from "../components/Global/News/NewsNew/NewsNew";
import NewsEdit from "../components/Global/News/NewsEdit/NewsEdit";
import NewsEditPreview from "../components/Global/News/NewsPreview/NewsEditPreview";
import NewsDetail from "../components/Global/News/NewsDetail/NewsDetail";
import Error404 from "../components/Global/Errors/404";
// カメラ =========================================
import { CameraHome } from "components/Camera/pages";
import { CameraUpload } from "components/Camera/pages/upload";
import { CameraDetail } from "components/Camera/pages/detail";
import { CameraTagList } from "components/Camera/pages/tag";
//===============================================

const AdminRoutes = (props: { inquiryNewPostStatus: boolean }) => {
  return (
    <Switch>
      {/* TOP */}
      <Route exact path="/" component={Articles} />

      {/* お問い合わせ */}
      <Route exact path="/inquiries" component={Inquiries} />
      <Route exact path="/inquiries/new" component={InquiryNewV2} />
      <Route exact path="/inquiries/new/:contact" component={InquiryNewV2} />
      <Route
        exact
        path="/inquiries/new/:contact/:kind"
        component={InquiryNewV2}
      />
      <Route exact path="/inquiries/complete" component={InquiriesComplete} />
      <Route exact path="/inquiries/:id" component={InquiriesDetail} />
      <Route exact path="/inquiries/dev/v2" component={InquiryNewV2} />

      {/* FAQ記事 */}
      <Route exact path="/articles" component={Articles} />
      <Route exact path="/articles/new" component={ArticlesNew} />
      <Route exact path="/articles/stocks" component={ArticlesStocks} />
      <Route
        exact
        path="/articles/stocks/:id"
        component={ArticleDetailForStocks}
      />
      <Route
        exact
        path="/articles/edit-preview"
        component={ArticleEditPreview}
      />
      <Route exact path="/articles/new-preview" component={ArticleNewPreview} />
      <Route exact path="/articles/:id" component={ArticleDetail} />
      <Route exact path="/articles/:id/edit" component={ArticleEdit} />

      {/* お知らせ */}
      <Route exact path="/news" component={News} />
      <Route exact path="/news-edit-list" component={News} />
      <Route exact path="/news/edit-preview" component={NewsEditPreview} />
      <Route exact path="/news/new" component={NewsNew} />
      <Route exact path="/news/:id" component={NewsDetail} />
      <Route exact path="/news/:id/edit" component={NewsEdit} />

      {/* マスタ管理 */}
      <Route exact path="/master/categories" component={Categories} />
      <Route exact path="/master/tags" component={Tags} />

      {/* アカウント管理 */}
      <Route exact path="/accounts" component={Accounts} />
      <Route exact path="/accounts/new" component={AccountNew} />
      <Route exact path="/accounts/:id" component={AccountDetail} />
      <Route exact path="/accounts/:id/edit" component={AccountEdit} />

      {/* 行事予定 */}
      <Route exact path="/events/home" component={EventTop} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/events/approvals/status" component={Status} />
      <Route
        exact
        path="/events/approvals/status/:id"
        component={StatusMonthly}
      />
      <Route exact path="/events/csv/upload" component={CSVUpload} />
      <Route
        exact
        path="/events/csv/completed"
        component={CSVUploadCompleted}
      />
      <Route exact path="/events/approvals" component={Approval} />

      {/* マニュアル */}
      {/* マニュアル ユーザー側 */}

      {/* マニュアル 管理側 検索結果 */}
      <Route path="/manual/results" component={ManualsAdminResults} />

      {/* マニュアルHOME兼一覧 */}
      <Route exact path="/manual" component={ManualTop} />
      <Route exact path="/manual/manuals" component={ManualTop} />

      {/* マニュアル詳細兼トピック一覧 */}
      <Route
        exact
        path="/manual/manuals/:manualId/topics"
        component={ManualsTopics}
      />
      <Route exact path="/manual/manuals/:manualId" component={ManualsTopics} />

      {/* トピック詳細兼ページ一覧 */}
      <Route
        exact
        path="/manual/manuals/:manualId/topics/:topicId/pages"
        component={ManualsPages}
      />
      <Route
        exact
        path="/manual/manuals/:manualId/topics/:topicId"
        component={ManualsPages}
      />

      {/* ページ詳細 */}
      <Route
        exact
        path="/manual/manuals/:manualId/topics/:topicId/pages/:pageId"
        component={ManualsPageDetail}
      />

      {/* マニュアル 管理側 */}
      {/* マニュアル 管理側 検索結果 */}
      <Route path="/manual/admin/results" component={ManualsAdminResults} />

      {/* マニュアル 管理側 一覧 */}
      <Route exact path="/manual/admin/manuals" component={ManualsAdmin} />

      {/* マニュアル 管理側 マニュアル詳細兼トピック一覧 */}
      <Route
        exact
        path="/manual/admin/manuals/:manualId/topics"
        component={ManualsAdminTopics}
      />
      <Route
        exact
        path="/manual/admin/manuals/:manualId"
        component={ManualsAdminTopics}
      />

      {/* マニュアル 管理側 トピック詳細兼ページ一覧 */}
      <Route
        exact
        path="/manual/admin/manuals/:manualId/topics/:topicId/pages"
        component={ManualsAdminPages}
      />
      <Route
        exact
        path="/manual/admin/manuals/:manualId/topics/:topicId"
        component={ManualsAdminPages}
      />

      {/* マニュアル 管理側 ページ詳細 */}
      <Route
        exact
        path="/manual/admin/manuals/:manualId/topics/:topicId/pages/:pageId"
        component={ManualsAdminPageDetail}
      />

      {/* マニュアル 管理側 タグ管理 */}
      <Route exact path="/manual/admin/tags" component={ManualsAdminTags} />

      {/* マニュアル 管理側 更新履歴 */}
      <Route
        exact
        path="/manual/admin/histories"
        component={ManualsAdminUpdateHistories}
      />

      {/* マニュアル 管理側 プレビュー */}
      <Route
        exact
        path="/manual/admin/manuals/:manualId/topics/:topicId/pages/:pageId/preview"
        component={ManualsAdminPageDetail}
      />

      {/* カメラ管理 */}
      <Route exact path="/camera" component={CameraHome} />
      <Route exact path="/camera/tag" component={CameraTagList} />
      <Route exact path="/camera/detail/:id" component={CameraDetail} />
      <Route exact path="/mediafile-upload" component={CameraUpload} />

      <Route component={Error404} />
    </Switch>
  );
};
export default AdminRoutes;
