import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SignInBox from "./SignInBox";
import styled from "styled-components";
import { color } from "../mixins";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";
import { resetState } from "store/session/action";
const SignIn = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const cache_cleared = useSelector(
    (state: any) => state.session.cache_cleared
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, []);

  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
    dispatch(resetState());
  }, []);

  // キャッシュクリア
  // useEffect(() => {
  //   if (to_clear_cache) {
  //     dispatch(clearSystemCache());
  //     if (caches) {
  //       // Service worker cache should be cleared with caches.delete()
  //       caches.keys().then((names) => {
  //         for (const name of names) {
  //           caches.delete(name);
  //         }
  //       });
  //     }
  //     setInterval(() => {
  //       dispatch(toggleCacheCleared(false));
  //       dispatch(toggleToClearCache(false));
  //       window.location.reload(true);
  //     }, 1000)
  //   }
  // }, [])

  return (
    <>
      <SignInContainerSC>
        <SignInHeaderSC>ひろばにログイン</SignInHeaderSC>
        {cache_cleared && (
          <SystemClearingNotification>
            システム更新中です
          </SystemClearingNotification>
        )}
        <SignInBox />
      </SignInContainerSC>
    </>
  );
};

export default SignIn;

const SignInHeaderSC = styled.p`
  color: ${color.primary};
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
`;

const SignInContainerSC = styled.div`
  width: calc(100% - 24px);
  max-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SystemClearingNotification = styled.p`
  text-align: center;
  margin: 15px auto;
`;
