import React, { useEffect } from "react";
import { FlexPageLayout } from "../Parts/MainLayout";
import SearchModuleForManual from "../SearchBlockForManual";
import ModuleArea from "../Parts/ModuleArea";
import TopicsBlock from "./TopicsBlock";
import { useTopHooks } from "../Top/Hooks";
import { useHooks } from "../Admin/Topics/Hooks";
import UpdateHistoriesModule from "../UpdateHistoriesModule";
import { useLocation } from "react-router-dom";
import { toggleRightSection } from "../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../styles/layout/containers";
import { useSelector, useDispatch } from "react-redux";

function Topics() {
  const { histories, tags } = useTopHooks();
  const dispatch = useDispatch();
  const { hookedManual, hookedTopics, manualId } = useHooks();
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const location = useLocation();
  const pathName = location.pathname;
  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <FlexPageLayout pageTitle={hookedManual.name}>
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <TopicsBlock manual={hookedManual} topics={hookedTopics} />
      <ModuleArea>
        <SearchModuleForManual manualId={Number(manualId)} data={tags} />
        <UpdateHistoriesModule data={histories} />
      </ModuleArea>
    </FlexPageLayout>
  );
}

export default Topics;
