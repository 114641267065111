import { color } from "components/mixins";
import React from "react";
import styled from "styled-components";

interface RadioButtonListProps {
  selectedContact: string;
  groupName: string;
  items: { id: number; name: string; contact?: { id: string; name: string } }[];
  onContactChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const RadioButtonList = (props: RadioButtonListProps) => {
  const { selectedContact, onContactChange, groupName, items } = props;
  return (
    <InputRadioCheckboxGroupSC>
      {items.map(item => {
        return (
          <RadioButtonLabel htmlFor={`${groupName}_${item.id}`}>
            <RadioButton
              className="radio"
              id={`${groupName}_${item.id}`}
              type="radio"
              name={groupName}
              value={item.id}
              checked={selectedContact === item.id.toString()}
              onChange={onContactChange}
            />
            <RadioButtonSpan className="label">{item.name}</RadioButtonSpan>
          </RadioButtonLabel>
        );
      })}
    </InputRadioCheckboxGroupSC>
  );
};
const InputRadioCheckboxGroupSC = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

// radioButton ==================================
const RadioButtonLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  position: relative;
  .label::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 6px;
    border: 1px solid ${color.primary};
    background-color: ${color.white};
  }
  .radio:checked + .label::before {
    background-color: ${color.primary};
  }
  .radio:checked + .label::after {
    content: "";
    width: 6px;
    height: 6px;
    border: 3px solid ${color.white};
    border-radius: 50%;
    transform: rotate(40deg);
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

const RadioButtonSpan = styled.span`
  font-size: 14px;
  display: inline-block;
  margin-top: 0px;
  margin-right: 30px;
  margin-left: 20px;
  color: ${color.black};
`;

const RadioButton = styled.input`
  display: none;
`;
