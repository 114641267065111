import axios, { AxiosResponse } from "axios";
import { IndexProps } from "store/manual/indexes/type";
import { env_manual_url } from "../../serverEnv";

export const getIndexesApi = (data: { token: string }) => {
  const { token } = data;
  try {
    const result: Promise<AxiosResponse<IndexProps[]>> = axios.get(
      `${env_manual_url}/indexes`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e);
    return e;
  }
};

export const getIndexApi = (data: { token: string; type_id: number }) => {
  const { token, type_id } = data;
  try {
    const result: Promise<AxiosResponse<IndexProps>> = axios.get(
      `${env_manual_url}/indexes/${type_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e);
    return e;
  }
};
