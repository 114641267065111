import { SET_ACCOUNT_NEW_NAME, SET_ACCOUNT_NEW_POSITION_ID, SET_ACCOUNT_NEW_EMAIL, SET_ACCOUNT_NEW_PASSWORD, SET_ACCOUNT_NEW_FACILITIES, POST_ACCOUNT_NEW_REQUESTED, TOGGLE_ACCOUNT_NEW_POST_STATUS } from "./type";

// =====================================TOGGLE_CHECKED_ARTICLE_CATEGORY============================ //
// Actions
// ----------------------------------------------------------------- //

// アカウント情報 ユーザー名のセット
export const setAccountNewName= (name: string) => {
  return {
    type: SET_ACCOUNT_NEW_NAME,
    name
  };
};

// アカウント情報 ロールIDのセット
export const setAccountNewRoleId= (position_id: number) => {
  return {
    type: SET_ACCOUNT_NEW_POSITION_ID,
    position_id
  };
};

// アカウント情報 メールアドレスのセット
export const setAccountNewEmail= (email: string) => {
  return {
    type: SET_ACCOUNT_NEW_EMAIL,
    email
  };
};

// アカウント情報 パスワードのセット
export const setAccountNewPassword= (password: string) => {
  return {
    type: SET_ACCOUNT_NEW_PASSWORD,
    password
  };
};

export const setAccountNewFacilities = (facility_id: number) => {
  return {
    type: SET_ACCOUNT_NEW_FACILITIES,
    facility_id
  }
}

// アカウント新規登録
export const postAccountNewRequest= (token: string, account_name: string, account_position_id: number, account_email: string, account_password: string, account_facilities: any) => {
  return {
    type: POST_ACCOUNT_NEW_REQUESTED,
    token,
    account_name,
    account_position_id,
    account_email,
    account_password,
    account_facilities
  };
};


export const toggleAccountNewPostStatus = (post_status: boolean) => {
  return {
    type: TOGGLE_ACCOUNT_NEW_POST_STATUS,
    post_status
  }
}
