import React from "react";
import { AlbumListSC } from "./style";

import { Card, CardSelectable } from "./card";
import { DoronkoMediaFileType } from "components/Camera/type";

interface AlubumListProps {
  mediaFiles: DoronkoMediaFileType[];
  isSelectMode: boolean;
  onSelectMedia: (mediaFileID: number) => void;
  selectedMedia: number[];
}

export const AlbumListThumbnail = (props: AlubumListProps) => {
  const { mediaFiles, isSelectMode, onSelectMedia, selectedMedia } = props;
  return (
    <AlbumListSC>
      {mediaFiles.map(mediaFile => {
        return isSelectMode ? (
          <CardSelectable
            key={mediaFile.id}
            mediaFile={mediaFile}
            onSelectMedia={onSelectMedia}
            selectedMedia={selectedMedia}
          />
        ) : (
          <Card key={mediaFile.id} mediaFile={mediaFile} />
        );
      })}
    </AlbumListSC>
  );
};
