import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import EventsSearchBlock from "../EventsSearchBlock";
import EventsListBlock from "./EventsListBlock";
import DeleteEventsButton from "./Modal/DeleteEventsButton";

import {
  getEventListRequest,
  getEventTypesRequest,
  resetDeleteItemArray
} from "../../../store/event/events/action";
import { getMonthlyEventsRequest } from "../../../store/event/monthly_events/action";
import { resetMonthlyEventItemToDeleteArray } from "../../../store/event/monthly_event_delete/action";
import { getApprovalMonthlyStatusRequest } from "../../../store/event/approval/action";
import FacilityHeader from "../FacilityHeader";
import { SetIsSearchByEventType } from "store/event/event_search/action";

import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function Events() {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_months = useSelector(
    (state: any) => state.event_search.filtered_months
  );
  const filtered_class = useSelector(
    (state: any) => state.event_search.filtered_class
  );
  const filtered_remand = useSelector(
    (state: any) => state.event_search.filtered_remand
  );

  // 一覧取得
  const event_item_to_delete_array = useSelector(
    (state: any) => state.events.deleteItemArray
  );
  const monthly_item_to_delete_array = useSelector(
    (state: any) => state.monthly_event_delete.to_delete_array
  );

  const eventList = useSelector((state: any) => state.events.events);

  useEffect(() => {
    // LoadingModuleの表示開始
    dispatch(toggleImgLoadingState(true));

    if (facility_id !== "" && facility_id !== null) {
      dispatch(getEventTypesRequest(token));
      if (
        filtered_months.includes("すべて") &&
        !filtered_class.includes("すべて")
      ) {
        dispatch(
          getEventListRequest(
            token,
            facility_id,
            filtered_year,
            ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
            filtered_class,
            filtered_remand
          )
        );
        dispatch(
          getMonthlyEventsRequest(
            token,
            facility_id,
            filtered_year,
            ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
            [],
            filtered_remand
          )
        );
      } else if (
        !filtered_months.includes("すべて") &&
        filtered_class.includes("すべて")
      ) {
        dispatch(
          getEventListRequest(
            token,
            facility_id,
            filtered_year,
            filtered_months,
            ["0", "1", "2", "3", "4", "5"],
            filtered_remand
          )
        );
        dispatch(
          getMonthlyEventsRequest(
            token,
            facility_id,
            filtered_year,
            filtered_months,
            [],
            filtered_remand
          )
        );
      } else if (
        filtered_months.includes("すべて") &&
        filtered_class.includes("すべて")
      ) {
        dispatch(
          getEventListRequest(
            token,
            facility_id,
            filtered_year,
            ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
            ["0", "1", "2", "3", "4", "5"],
            filtered_remand
          )
        );
        dispatch(
          getMonthlyEventsRequest(
            token,
            facility_id,
            filtered_year,
            ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"],
            [],
            filtered_remand
          )
        );
      } else {
        dispatch(
          getEventListRequest(
            token,
            facility_id,
            filtered_year,
            filtered_months,
            filtered_class,
            filtered_remand
          )
        );
        dispatch(
          getMonthlyEventsRequest(
            token,
            facility_id,
            filtered_year,
            filtered_months,
            [],
            filtered_remand
          )
        );
      }
      dispatch(
        getApprovalMonthlyStatusRequest(token, facility_id, filtered_year)
      );
      // 削除対象行事予定をリセット
      dispatch(resetDeleteItemArray());
      dispatch(resetMonthlyEventItemToDeleteArray());
    }
  }, [dispatch, token, facility_id]);

  useEffect(() => {
    // ページに訪れたときに、
    dispatch(SetIsSearchByEventType([]));
  }, [dispatch]);

  // LoadingModuleの表示終了
  useEffect(() => {
    dispatch(toggleImgLoadingState(false));
  }, [dispatch, eventList]);

  return (
    <MainSC>
      <FacilityHeader />
      <ContentTitle>行事予定</ContentTitle>

      <ContentSC>
        <EventsSearchBlock />
        <EventsListBlock />
      </ContentSC>

      {event_item_to_delete_array.length + monthly_item_to_delete_array.length >
        0 && <DeleteEventsButton />}
    </MainSC>
  );
}

export default Events;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: block;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
`;

const ContentTitle = styled.div`
  padding: 0 12px;
  font-size: 18px;
  color: ${color.gray};
  font-weight: 600;
  ${pc`
    padding: 0 30px;
  `}
`;

const ContentSC = styled.div`
  padding: 20px 12px 130px;
  display: block;
  ${pc`
    padding: 20px 30px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
  width: 100%;
`;
