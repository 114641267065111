import React from "react";
import { Page } from "store/manual/manuals/pages/types.d";
import styled from "styled-components";
import { color } from "components/mixins";
import editCancelIcon from "images/icons/editmode_cancel.svg";

interface Props {
  page: Page;
  updatePageTitle: (data: {
    order: number;
    new_title: string;
    status: number;
  }) => void;
  removeHookedItem: (data: { page: Page }) => void;
}

function PagesEditableItem(props: Props) {
  const { page, updatePageTitle, removeHookedItem } = props;

  const handleChange = (data: {
    e: React.ChangeEvent<HTMLInputElement>;
    order: number;
  }) => {
    const { e, order } = data;
    updatePageTitle({
      order: order,
      new_title: e.target.value,
      status: page.status
    });
  };

  return (
    <PagesItemTitleEditable>
      <PagesItemContent>
        {page.status === 3 ? (
          <input
            type="text"
            value={page.title_draft}
            onChange={e => handleChange({ e: e, order: page.order })}
          />
        ) : (
          <input
            type="text"
            value={page.title}
            onChange={e => handleChange({ e: e, order: page.order })}
          />
        )}
        <img
          src={editCancelIcon}
          alt="Remove"
          style={{ cursor: "pointer" }}
          onClick={() => {
            removeHookedItem({ page });
          }}
        />
      </PagesItemContent>
    </PagesItemTitleEditable>
  );
}

export default PagesEditableItem;

const PagesItemTitleEditable = styled.li`
  display: block;
  padding: 22px 22px 22px 55px;
  border-bottom: 1px solid ${color.list_border};
`;

const PagesItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
