const GET_NEWS_DETAIL_REQUESTED = "GET_NEWS_DETAIL_REQUESTED";
const GET_NEWS_DETAIL_SUCCEEDED = "GET_NEWS_DETAIL_SUCCEEDED";
const GET_NEWS_DETAIL_FAILED = "GET_NEWS_DETAIL_FAILED";

const READ_NEWS_DETAIL_REQUESTED = "READ_NEWS_DETAIL_REQUESTED";
const READ_NEWS_DETAIL_SUCCEEDED = "READ_NEWS_DETAIL_SUCCEEDED";
const READ_NEWS_DETAIL_FAILED = "READ_NEWS_DETAIL_FAILED";

const UPDATE_NEWS_DETAIL_REQUESTED = "UPDATE_NEWS_DETAIL_REQUESTED";
const UPDATE_NEWS_DETAIL_SUCCEEDED = "UPDATE_NEWS_DETAIL_SUCCEEDED";
const UPDATE_NEWS_DETAIL_FAILED = "UPDATE_NEWS_DETAIL_FAILED";

const SET_NEWS_DETAIL_ID = "SET_NEWS_DETAIL_ID";

const TOGGLE_NEWS_DETAIL_CONTENT_LOADED = "TOGGLE_NEWS_DETAIL_CONTENT_LOADED";

const GET_NEWS_DETAIL_FAILED_404 = "GET_NEWS_DETAIL_FAILED_404"

export {

  GET_NEWS_DETAIL_REQUESTED,
  GET_NEWS_DETAIL_SUCCEEDED,
  GET_NEWS_DETAIL_FAILED,

  READ_NEWS_DETAIL_REQUESTED,
  READ_NEWS_DETAIL_SUCCEEDED,
  READ_NEWS_DETAIL_FAILED,

  UPDATE_NEWS_DETAIL_REQUESTED,
  UPDATE_NEWS_DETAIL_SUCCEEDED,
  UPDATE_NEWS_DETAIL_FAILED,

  SET_NEWS_DETAIL_ID,

  TOGGLE_NEWS_DETAIL_CONTENT_LOADED,

  GET_NEWS_DETAIL_FAILED_404
}
