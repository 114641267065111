import {
  GET_CAMERA_TAGS_SUCCEEDED,
  GET_CAMERA_TAGS_FAILED,
  ADD_TO_BE_DELETED_CAMERA_TAGS_IDS,
  ADD_TO_BE_CREATED_CAMERA_TAG,
  SET_TO_BE_CREATED_CAMERA_TAG_DATA,
  RESET_TO_BE_DELETED_CAMERA_TAGS,
  SET_TO_BE_UPDATED_CAMERA_TAG_DATA,
  BATCH_CAMERA_TAGS_SUCCEEDED
} from "./type";
import { CameraTag } from "components/Camera/type";

export interface CameraTagsState {
  camera_tags: CameraTag[];
  camera_tags_to_be_created: CameraTagsToBeCreatedState[];
  camera_tags_to_be_deleted_ids: number[];
}

export interface CameraTagsToBeCreatedState {
  id: string;
  name: string;
  order: number;
  is_enabled: boolean;
}

const initialState: CameraTagsState = {
  camera_tags: [],
  camera_tags_to_be_created: [],
  camera_tags_to_be_deleted_ids: []
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CAMERA_TAGS_SUCCEEDED:
      return {
        ...state,
        camera_tags: action.tags
      };
    case GET_CAMERA_TAGS_FAILED:
      return {
        ...state
      };
    case BATCH_CAMERA_TAGS_SUCCEEDED:
      return {
        ...state,
        camera_tags_to_be_created: []
      };
    case ADD_TO_BE_DELETED_CAMERA_TAGS_IDS:
      if (typeof action.targetCameraTagId === "number") {
        return {
          ...state,
          camera_tags: state.camera_tags.filter(
            tag => tag.id !== action.targetCameraTagId
          ),
          camera_tags_to_be_deleted_ids: [
            ...state.camera_tags_to_be_deleted_ids,
            action.targetCameraTagId
          ]
        };
      } else {
        return {
          ...state,
          camera_tags_to_be_created: state.camera_tags_to_be_created.filter(
            tag => tag.id !== action.targetCameraTagId
          )
        };
      }
    case ADD_TO_BE_CREATED_CAMERA_TAG:
      const newTagFormat = {
        id: new Date().getTime().toString(16),
        name: "",
        order: "",
        is_enabled: true
      };
      return {
        ...state,
        camera_tags_to_be_created: [
          ...state.camera_tags_to_be_created,
          newTagFormat
        ]
      };
    case SET_TO_BE_CREATED_CAMERA_TAG_DATA:
      const currentTobeCreatedState = [...state.camera_tags_to_be_created];
      const toBeCreatedTagsTargetIndex = currentTobeCreatedState.findIndex(
        tag => tag.id === action.targetTagId
      );
      if (action.target === "order") {
        currentTobeCreatedState[toBeCreatedTagsTargetIndex].order =
          action.value;
      }
      if (action.target === "name") {
        currentTobeCreatedState[toBeCreatedTagsTargetIndex].name = action.value;
      }
      return {
        ...state,
        camera_tags_to_be_created: [...currentTobeCreatedState]
      };
    case SET_TO_BE_UPDATED_CAMERA_TAG_DATA:
      const currentState = [...state.camera_tags];
      const targetIndex = currentState.findIndex(
        tag => tag.id === action.targetTagId
      );
      if (action.target === "order") {
        currentState[targetIndex].order = action.value;
      }
      if (action.target === "name") {
        currentState[targetIndex].name = action.value;
      }
      return {
        ...state,
        camera_tags: [...currentState]
      };
    case RESET_TO_BE_DELETED_CAMERA_TAGS:
      return {
        ...state,
        camera_tags_to_be_deleted_ids: []
      };
    default:
      return state;
  }
};

export default reducer;
