import React from "react";
import { useParams } from "react-router-dom";

const useHooks = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return {
    open,
    pageId,
    handleClick,
  };
};

export default useHooks;
