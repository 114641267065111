import React from "react";
import styled from "styled-components";
import SearchModuleForManual from "../../SearchBlockForManual";
import ModuleArea from "../../Parts/ModuleArea";
import HeroEditContainer from "../../Parts/HeroEditContainer";
import { useHooks } from "./Hooks";
import { useSelector, useDispatch } from "react-redux";
import UpdateHistoriesModule from "components/Manual/UpdateHistoriesModule";
import BlockHeaderAdmin from "components/Manual/Parts/BlockHeaderAdmin";
import BlockContainerManualAdmin from "components/Manual/Parts/BlockContainerManualAdmin";
import TopicsUneditableList from "./TopicsUneditableList";
import TopicsEditableList from "./TopicsEditableList";
import TopicsSortableList from "./TopicsSortableList";
import BottomActionButtonsForTopicsList from "components/Manual/Parts/BottomActionButtonsForTopicsList";
import TopicsTitleDesc from "./TopicsTitleDesc";
import ManualHeader from "components/Manual/Parts/ManualHeader";
import ManualBreadcrumb from "components/Manual/Parts/ManualBreadcrumb";
import { toggleRightSection } from "../../../../store/common/sidebar/action";
import { RightSectionToggler } from "../../../styles/layout/containers";

function Topics() {
  const rightSectionOpen = useSelector(
    (state: any) => state.sidebar.rightSectionOpen
  );
  const dispatch = useDispatch();
  const {
    hookedManual,
    setHookedManual,
    histories,
    tags,
    editTitleMode,
    setEditTitleMode,
    editOrderMode,
    setEditOrderMode,
    cancelEdit,
    hookedTopics,
    updateTopicTitle,
    handleAdd,
    removeHookedItem,
    changeTopicsOrder,
    updateAllTopicsAction,
    editImageMode,
    setEditImageMode,
    cancelEditImage,
    updateImageAction,
    imageToUpload,
    setImageToUpload,
    editTitleDescMode,
    setEditTitleDescMode,
    cancelEditTitleDesc,
    updateTitleDesc,
    manualName,
    manualId
  } = useHooks();
  return (
    <TopicsLayoutSC>
      <RightSectionToggler
        className={rightSectionOpen ? "open" : "closed"}
        onClick={() => dispatch(toggleRightSection())}
      />
      <ManualHeader pageTitle={manualName} />
      <ManualBreadcrumb />
      {/* Hero画像 */}
      <HeroEditContainer
        hookedManual={hookedManual}
        setHookedManual={setHookedManual}
        editImageMode={editImageMode}
        setEditImageMode={setEditImageMode}
        cancelEditImage={cancelEditImage}
        setImageToUpload={setImageToUpload}
      />
      <ContentsWrapperSC>
        <ManualDetailArea>
          <BlockContainerManualAdmin>
            <TopicsTitleDesc
              hookedManual={hookedManual}
              setHookedManual={setHookedManual}
              editTitleDescMode={editTitleDescMode}
              setEditTitleDescMode={setEditTitleDescMode}
              cancelEditTitleDesc={cancelEditTitleDesc}
              completeAction={updateTitleDesc}
            />
          </BlockContainerManualAdmin>
          <BlockContainerManualAdmin>
            {/* トピック一覧 */}
            <BlockHeaderAdmin
              blockTitle="トピック一覧"
              editTitleMode={editTitleMode}
              setEditTitleMode={setEditTitleMode}
              editOrderMode={editOrderMode}
              setEditOrderMode={setEditOrderMode}
              cancelEdit={cancelEdit}
            />
            <Content>
              <>
                {/* 最初の表示 */}
                {!editTitleMode && !editOrderMode && hookedTopics && (
                  <TopicsUneditableList
                    hookedTopics={hookedTopics}
                    manual={hookedManual}
                  />
                )}

                {/* 名称編集中 */}
                {editTitleMode && !editOrderMode && hookedTopics && (
                  <TopicsEditableList
                    hookedTopics={hookedTopics}
                    updateTopicTitle={updateTopicTitle}
                    handleAdd={handleAdd}
                    removeHookedItem={removeHookedItem}
                  />
                )}

                {!editTitleMode && editOrderMode && hookedTopics && (
                  <TopicsSortableList
                    hookedTopics={hookedTopics}
                    changeTopicsOrder={changeTopicsOrder}
                  />
                )}

                {hookedTopics && (
                  <BottomActionButtonsForTopicsList
                    sidebar_is_open={true}
                    hookedTopics={hookedTopics}
                    editTitleMode={editTitleMode}
                    setEditTitleMode={setEditTitleMode}
                    editOrderMode={editOrderMode}
                    setEditOrderMode={setEditOrderMode}
                    complete_action={updateAllTopicsAction}
                    hookedManual={hookedManual}
                    editImageMode={editImageMode}
                    setEditImageMode={setEditImageMode}
                    completeImageAction={updateImageAction}
                    imageToUpload={imageToUpload}
                    editTitleDescMode={editTitleDescMode}
                    setEditTitleDescMode={setEditTitleDescMode}
                    completeTitleDescAction={updateTitleDesc}
                  />
                )}
              </>
            </Content>
          </BlockContainerManualAdmin>
        </ManualDetailArea>

        {/* 画面右側モジュール */}
        <ModuleArea>
          <SearchModuleForManual manualId={Number(manualId)} data={tags} />
          <UpdateHistoriesModule data={histories} />
        </ModuleArea>
      </ContentsWrapperSC>
    </TopicsLayoutSC>
  );
}

export default Topics;

const TopicsLayoutSC = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

const ContentsWrapperSC = styled.div`
  display: flex;
`;

const Content = styled.div`
  position: relative;
  padding-bottom: 60px;
`;

const ManualDetailArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
`;
