import React, { useEffect } from "react";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";

import PageHeading from "../../Common/PageHeading";
import { useLocation } from "react-router-dom";
import InquiriesListBlock from "./InquiriesListBlock";

function Inquiries() {
  const pathName = useLocation().pathname;

  // GA計測タグ
  useEffect(() => {
    window.gtag("config", "UA-185940525-1", {
      page_path: pathName // 任意のパス（あるいはprops.location.pathnameなど）
    });
  });

  return (
    <MainSC>
      <PageHeading title="お問い合わせ履歴" />

      <InquiriesListBlock />
    </MainSC>
  );
}

export default Inquiries;

const MainSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 30px 12px 130px;
  ${pc`
    padding: 30px 30px 60px;
  `}
`;
