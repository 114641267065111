import styled from "styled-components";

export const ButtonSC = styled.button`
  background-color: #444444;
  color: white;
  font-size: 14px;
  padding: 10px 16px 11px 16px;
  border-radius: 4px;
  text-align: center;
  min-width: 144px;
  &:hover {
    opacity: 0.7;
  }
  &.alert {
    background-color: #dd0000;
    &:hover {
      opacity: 0.7;
    }
  }
  &:disabled {
    background-color: #bbbbbb;
    &:hover {
      opacity: 0.3;
    }
  }
`;
