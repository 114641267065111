import { SET_CURRENT_PAGE_REQUESTED, SET_PER_PAGE, SET_KEYWORD, SET_DATA_PER_PAGE, SET_ACCOUNT_EMAIL_TO_SEARCH, SET_SEARCH_STATUS } from "./type";

export const setCurrentPageRequest = (current_page: number, target_data: string) => {
  return {
    type: SET_CURRENT_PAGE_REQUESTED,
    current_page,
    target_data
  };
};

export const setPerPage = (per_page: number, target_data: string) => {
  return {
    type: SET_PER_PAGE,
    per_page,
    target_data
  };
};

export const setKeyword = (keyword: string, target_data: string) => {
  return {
    type: SET_KEYWORD,
    keyword,
    target_data
  };
};

export const setDataPerPage = (per_page: number, target_data: string) => {
  return {
    type: SET_DATA_PER_PAGE,
    per_page,
    target_data
  };
};

export const setAccountEmailToSearch = (account_email_to_search: string) => {
  return {
    type: SET_ACCOUNT_EMAIL_TO_SEARCH,
    account_email_to_search
  };
};

export const setSearchStatus = (search_status: boolean) => {
  return {
    type: SET_SEARCH_STATUS,
    search_status
  };
};
