const NUMBER_OF_CHECKED = "NUMBER_OF_CHECKED";
const TOGGLE_APPROVAL_MONTH_REQUEST = "TOGGLE_APPROVAL_MONTH_REQUEST";
const GET_EVENTS_LIST_REQUESTED = "GET_EVENTS_LIST_REQUESTED";
const GET_EVENTS_LIST_SUCCEEDED = "GET_EVENTS_LIST_SUCCEEDED";
const GET_EVENTS_LIST_FAILED = "GET_EVENTS_LIST_FAILED";
const GET_EVENTS_TYPE_REQUESTED = "GET_EVENTS_TYPE_REQUESTED";
const GET_EVENTS_TYPE_SUCCEEDED = "GET_EVENTS_TYPE_SUCCEEDED";
const GET_EVENTS_TYPE_FAILED = "GET_EVENTS_TYPE_FAILED";
const GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED =
  "GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED";
const GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED =
  "GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED";
const GET_EVENTS_FOR_ANNUAL_SCHEDULE_FAILED =
  "GET_EVENTS_FOR_ANNUAL_SCHEDULE_FAILED";
const ADD_INTO_DELETE_ITEM_ARRAY = "ADD_INTO_DELETE_ITEM_ARRAY";
const REMOVE_FROM_DELETE_ITEM_ARRAY = "REMOVE_FROM_DELETE_ITEM_ARRAY";
const RESET_DELETE_ITEM_ARRAY = "RESET_DELETE_ITEM_ARRAY";
const DELETE_EVENT_REQUESTED = "DELETE_EVENT_REQUESTED";
const DELETE_EVENT_SUCCEEDED = "DELETE_EVENT_SUCCEEDED";
const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";
const POST_EVENT_REQUESTED = "POST_EVENT_REQUESTED";
const POST_EVENT_SUCCEEDED = "POST_EVENT_SUCCEEDED";
const POST_EVENT_FAILED = "POST_EVENT_FAILED";
const PUT_EVENT_REQUESTED = "PUT_EVENT_REQUESTED";
const PUT_EVENT_SUCCEEDED = "PUT_EVENT_SUCCEEDED";
const PUT_EVENT_FAILED = "PUT_EVENT_FAILED";
const POST_EVENTS_APPLY_REQUESTED = "POST_EVENTS_APPLY_REQUESTED";
const POST_EVENTS_APPLY_SUCCEEDED = "POST_EVENTS_APPLY_SUCCEEDED";
const POST_EVENTS_APPLY_FAILED = "POST_EVENTS_APPLY_FAILED";
const DELETE_CAMERA_DETAIL_REQUESTED = "DELETE_CAMERA_DETAIL_REQUESTED";

export {
  NUMBER_OF_CHECKED,
  TOGGLE_APPROVAL_MONTH_REQUEST,
  GET_EVENTS_TYPE_REQUESTED,
  GET_EVENTS_TYPE_SUCCEEDED,
  GET_EVENTS_TYPE_FAILED,
  GET_EVENTS_LIST_REQUESTED,
  GET_EVENTS_LIST_SUCCEEDED,
  GET_EVENTS_LIST_FAILED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_FAILED,
  ADD_INTO_DELETE_ITEM_ARRAY,
  REMOVE_FROM_DELETE_ITEM_ARRAY,
  RESET_DELETE_ITEM_ARRAY,
  DELETE_EVENT_REQUESTED,
  DELETE_EVENT_SUCCEEDED,
  DELETE_EVENT_FAILED,
  POST_EVENT_REQUESTED,
  POST_EVENT_SUCCEEDED,
  POST_EVENT_FAILED,
  PUT_EVENT_REQUESTED,
  PUT_EVENT_SUCCEEDED,
  PUT_EVENT_FAILED,
  POST_EVENTS_APPLY_REQUESTED,
  POST_EVENTS_APPLY_SUCCEEDED,
  POST_EVENTS_APPLY_FAILED
};
