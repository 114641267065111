import { CLEAR_SYSTEM_CACHE } from "../../session/type";
import {
  GET_ARTICLES_STOCKS_SUCCEEDED,
  GET_ARTICLES_STOCKS_FAILED,
} from "./type";

interface Article {
  id: number;
  title: string;
  content: string;
  status: number;
  priority: number;
  views: number;
  released_at: Date;
  created_at: Date;
  updated_at: Date;
  categories: Category[];
  tags: Tag[];
}

interface Category {
  id: number;
  name: string;
}

interface Tag {
  id: number;
  name: string;
}

const initialState: Article[] = [];

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ARTICLES_STOCKS_SUCCEEDED:
      // return [...state, ...action.stocks];
      return [...action.stocks];

    case GET_ARTICLES_STOCKS_FAILED:
      console.log("action.message", action.message);
      return state;

    case CLEAR_SYSTEM_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
