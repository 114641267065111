export const GET_CAMERA_TAGS_REQUESTED = "GET_CAMERA_TAGS_REQUESTED";
export const GET_CAMERA_TAGS_SUCCEEDED = "GET_CAMERA_TAGS_SUCCEEDED";
export const GET_CAMERA_TAGS_FAILED = "GET_CAMERA_TAGS_FAILED";

export const ADD_TO_BE_DELETED_CAMERA_TAGS_IDS =
  "ADD_TO_BE_DELETED_CAMERA_TAGS_IDS";
export const ADD_TO_BE_CREATED_CAMERA_TAG = "ADD_TO_BE_CREATED_CAMERA_TAG";
export const ADD_TO_BE_UPDATED_CAMERA_TAGS = "ADD_TO_BE_UPDATED_CAMERA_TAGS";

export const SET_TO_BE_CREATED_CAMERA_TAG_DATA = "SET_TO_BE_CERATED_TAG_DATA";
export const SET_TO_BE_UPDATED_CAMERA_TAG_DATA = "SET_TO_BE_UPDATED_TAG_DATA";

export const RESET_TO_BE_DELETED_CAMERA_TAGS =
  "RESET_TO_BE_DELETED_CAMERA_TAGS";
export const DELETE_CAMERA_TAGS_REQUESTED = "DELETE_CAMERA_TAGS_REQUESTED";
export const DELETE_CAMERA_TAGS_SUCCEEDED = "DELETE_CAMERA_TAGS_SUCCEEDED";
export const DELETE_CAMERA_TAGS_FAILED = "DELETE_CAMERA_TAGS_FAILED";
export const BATCH_CAMERA_TAGS_REQUESTED = "BATCH_CAMERA_TAGS_REQUESTED";
export const BATCH_CAMERA_TAGS_SUCCEEDED = "BATCH_CAMERA_TAGS_SUCCEEDED";
export const BATCH_CAMERA_TAGS_FAILED = "BATCH_CAMERA_TAGS_FAILED";
