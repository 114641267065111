import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
//========================================================================================================
// sidebar
//========================================================================================================
import SidebarAdminFAQ from "components/Sidebar/SidebarAdminFAQ";
import SidebarAdminEvent from "components/Sidebar/SidebarAdminEvent";
import SidebarHeadquartersFAQ from "components/Sidebar/SidebarHeadquartersFAQ";
import SidebarHeadquartersEvent from "components/Sidebar/SidebarHeadquartersEvent";
import SidebarPJManagerFAQ from "components/Sidebar/SidebarPJManagerFAQ";
import SidebarPJManagerEvent from "components/Sidebar/SidebarPJManagerEvent";
import SidebarFacilityOwnerFAQ from "components/Sidebar/SidebarFacilityOwnerFAQ";
import SidebarFacilityOwnerEvent from "components/Sidebar/SidebarFacilityOwnerEvent";
import SidebarEditorFAQ from "components/Sidebar/SidebarEditorFAQ";
import SidebarEditorEvent from "components/Sidebar/SidebarEditorEvent";
import SidebarUserFAQ from "components/Sidebar/SidebarUserFAQ";
import SidebarUserEvent from "components/Sidebar/SidebarUserEvent";
import SidebarManual from "components/Sidebar/SidebarManualAdmin";
import SidebarCamera from "components/Sidebar/SidebarCamera";

const SidebarRoute = () => {
  const position: number = useSelector(
    (state: any) => state.session.position.id
  );
  // FAQのサイドバー出し分け
  const FAQSidebarSwitch = () => {
    switch (position) {
      case 3:
        return SidebarAdminFAQ;
      case 2:
        return SidebarHeadquartersFAQ;
      case 6:
        return SidebarPJManagerFAQ;
      case 5:
        return SidebarFacilityOwnerFAQ;
      case 1:
        return SidebarEditorFAQ;
      case 4:
        return SidebarUserFAQ;
      default:
        return SidebarUserFAQ;
    }
  };
  // 行事予定のサイドバー出し分け
  const EventSidebarSwitch = () => {
    switch (position) {
      case 3:
        return SidebarAdminEvent;
      case 2:
        return SidebarHeadquartersEvent;
      case 6:
        return SidebarPJManagerEvent;
      case 5:
        return SidebarFacilityOwnerEvent;
      case 1:
        return SidebarEditorEvent;
      case 4:
        return SidebarUserEvent;
      default:
        return SidebarUserEvent;
    }
  };

  return (
    <Switch>
      <Route path="/mediafile-upload" component={Empty} />
      <Route path="/camera" component={SidebarCamera} />
      <Route path="/manual" component={SidebarManual} />
      <Route path="/events" component={EventSidebarSwitch()} />
      <Route path="/" component={FAQSidebarSwitch()} />
    </Switch>
  );
};

export default SidebarRoute;

const Empty = () => {
  return <></>;
};
