import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getNewsListApi,
  getNewsListToShowApi,
  postNewsApi,
  putNewsApi,
  readNewsApi,
  putNewNewsApi,
  putEditNewsApi,
  getNewsDetailApi,
  deleteNewsApi,
  getNewsApiAfterPut
} from "../api/global/newsApi";
import { DELETE_ARTICLE_FAILED } from "../store/faq/article_edit/type";
import {
  GET_NEWS_LIST_REQUESTED,
  GET_NEWS_LIST_SUCCEEDED,
  GET_NEWS_LIST_FAILED,
  GET_NEWS_LIST_FOR_USER_REQUESTED,
  GET_NEWS_LIST_FOR_USER_SUCCEEDED,
  GET_NEWS_LIST_FOR_USER_FAILED
} from "../store/global/news/type";
import {
  READ_NEWS_DETAIL_FAILED,
  READ_NEWS_DETAIL_REQUESTED,
  READ_NEWS_DETAIL_SUCCEEDED,
  GET_NEWS_DETAIL_REQUESTED,
  GET_NEWS_DETAIL_SUCCEEDED,
  GET_NEWS_DETAIL_FAILED,
  GET_NEWS_DETAIL_FAILED_404
} from "../store/global/news_detail/type";
import {
  PUT_NEWS_EDIT_REQUESTED,
  PUT_NEWS_EDIT_FAILED,
  PUT_NEWS_EDIT_SUCCEEDED,
  GET_NEWS_EDIT_SUCCEEDED,
  GET_NEWS_EDIT_FAILED,
  GET_NEWS_EDIT_REQUESTED,
  GET_NEWS_EDIT_FAILED_404,
  DELETE_NEWS_REQUESTED,
  DELETE_NEWS_SUCCEEDED
} from "../store/global/news_edit/type";
import {
  POST_NEWS_REQUESTED,
  POST_NEWS_SUCCEEDED,
  POST_NEWS_FAILED,
  PUT_NEWS_FAILED,
  PUT_NEWS_REQUESTED,
  PUT_NEWS_SUCCEEDED,
  GET_NEWS_SUCCEEDED
} from "../store/global/news_new/type";
import {
  SET_CURRENT_PAGE_FAILED,
  SET_CURRENT_PAGE_SUCCEEDED,
  SET_TOTAL
} from "../store/common/paginate/type";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
function* getNewsList(action: any) {
  try {
    let result;
    if (action.pathName === "/news") {
      result = yield call(
        getNewsListToShowApi,
        action.token,
        action.page,
        action.limit
      );
    } else {
      result = yield call(
        getNewsListApi,
        action.token,
        action.page,
        action.limit
      );
    }

    yield all([
      put({ type: GET_NEWS_LIST_SUCCEEDED, news: result.data.news }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "news"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "news"
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getNewsListForUser(action: any) {
  try {
    const result = yield call(
      getNewsListToShowApi,
      action.token,
      action.page,
      action.limit
    );
    yield all([
      put({ type: GET_NEWS_LIST_SUCCEEDED, news: result.data.news }),
      put({
        type: SET_TOTAL,
        total: result.data.paginate.total,
        target_data: "news"
      }),
      put({
        type: SET_CURRENT_PAGE_SUCCEEDED,
        current_page: result.data.paginate.current_page,
        target_data: "news"
      })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* postNews(action: any) {
  const token = action.token;
  const title = action.new_news_title;
  const content = action.new_news_content;
  const status = action.new_news_status;
  try {
    const result = yield call(postNewsApi, token, title, content, status);
    console.log("result", result);
    yield put({
      type: POST_NEWS_SUCCEEDED,
      status: result.data.status,
      id: result.data.id
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* putNews(action: any) {
  try {
    console.log("action.content", action.content);
    console.log("action.content typeof", typeof action.content);
    const result = yield* putNewsApi(
      action.new_news_id,
      action.token,
      action.title,
      action.content,
      action.status
    );
    console.log("result", result);
    yield put({ type: PUT_NEWS_SUCCEEDED, result: result });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* deleteNews(action: any) {
  try {
    const delete_result = yield* deleteNewsApi(action.token, action.id);
    const result = yield* getNewsApiAfterPut(action.token, 1, 20);
    yield all([
      put({ type: DELETE_NEWS_SUCCEEDED, status: delete_result.status }),
      put({ type: GET_NEWS_LIST_SUCCEEDED, news: result.data.news })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* putNewNews(action: any) {
  try {
    const put_new_result = yield* putNewNewsApi(
      action.new_news_id,
      action.token,
      action.title,
      action.content,
      action.status
    );
    // const result = yield* getArticlesApiAfterPut(action.token, 1, 20, [], [], '')
    yield all([
      put({ type: PUT_NEWS_SUCCEEDED, status: put_new_result })
      // put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* putEditArticle(action: any) {
  try {
    console.log("putEditArticle action", putEditArticle);
    const put_edit_result = yield* putEditNewsApi(
      action.id,
      action.token,
      action.title,
      action.content,
      action.status,
      action.search_string
    );
    // const result = yield* getArticlesApiAfterPut(action.token, 1, 20, [], [], '')
    // console.log("result", result);
    yield all([
      put({ type: PUT_NEWS_EDIT_SUCCEEDED, status: put_edit_result.status })
      // put({ type: GET_ARTICLES_SUCCEEDED, articles: result.data.articles })
    ]);
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* readNews(action: any) {
  try {
    const result = yield call(readNewsApi, action.token, action.id);
    console.log("result", result);
    yield put({ type: READ_NEWS_DETAIL_SUCCEEDED, status: result.status });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getNewsDetail(action: any) {
  console.log("YA");
  try {
    const result = yield call(getNewsDetailApi, action.token, action.id);
    console.log("getNewsDetail result", result);
    yield put({
      type: GET_NEWS_DETAIL_SUCCEEDED,
      news_detail: result.data.news
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

function* getNewsEdit(action: any) {
  console.log("YOYO");
  try {
    const result = yield call(getNewsDetailApi, action.token, action.id);
    console.log("getNewsDetail result", result);
    yield put({ type: GET_NEWS_EDIT_SUCCEEDED, news_detail: result.data.news });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const newsSaga = [
  takeLatest(GET_NEWS_LIST_REQUESTED, getNewsList),
  takeLatest(GET_NEWS_DETAIL_REQUESTED, getNewsDetail),
  takeLatest(GET_NEWS_EDIT_REQUESTED, getNewsEdit),
  takeLatest(GET_NEWS_LIST_FOR_USER_REQUESTED, getNewsListForUser),
  takeLatest(POST_NEWS_REQUESTED, postNews),
  takeLatest(PUT_NEWS_REQUESTED, putNewNews),
  takeLatest(PUT_NEWS_EDIT_REQUESTED, putEditArticle),
  takeLatest(READ_NEWS_DETAIL_REQUESTED, readNews),
  takeLatest(DELETE_NEWS_REQUESTED, deleteNews)
];

export default newsSaga;
