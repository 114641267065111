const env_faq_url = "https://api.app.doronko.jp/api/faq/v1";
const env_url = "https://api.app.doronko.jp/api/v1";
const env_event_url = "https://api.app.doronko.jp/api/event/v1";
const env_v2_url = "https://api.app.doronko.jp/api/v1";
const env_manual_url = "https://api.app.doronko.jp/api/manual/v1";
const env_camera_url = "https://api.app.doronko.jp/api/camera/v1";
const env_inquiry_url = "https://api.app.doronko.jp/api/inquiries/v1";

const GOOGLE_CLIENT_ID_PRD =
  "666753475012-ns2i8937hi7okntcgnl4djd3hrf77ccm.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID_STG =
  "666753475012-ok8ta0om1a35eb95uf1atpfiecajb28q.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID_TEST =
  "666753475012-66je8q5r1be41ejg2fp6bd8j7de27c93.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID_TRAINING =
  "666753475012-66je8q5r1be41ejg2fp6bd8j7de27c93.apps.googleusercontent.com";
const google_redirect_uri_prd = "https://api.app.doronko.jp/api/v1/google_auth";
const google_redirect_uri_stg =
  "https://api.gowest.techbeans.jp/api/v1/google_auth";
const google_redirect_uri_test =
  "https://test.gowest.techbeans.jp/api/v1/google_auth";
const google_redirect_uri_training = "https://test-gowest.netlify.app";

const sentry_dns =
  "https://e811a6375488498290f73050252df223@o1244517.ingest.sentry.io/6460353";

export {
  env_faq_url,
  env_url,
  env_v2_url,
  GOOGLE_CLIENT_ID_PRD,
  GOOGLE_CLIENT_ID_STG,
  GOOGLE_CLIENT_ID_TEST,
  GOOGLE_CLIENT_ID_TRAINING,
  google_redirect_uri_stg,
  google_redirect_uri_test,
  google_redirect_uri_prd,
  google_redirect_uri_training,
  env_event_url,
  env_manual_url,
  env_camera_url,
  env_inquiry_url,
  sentry_dns
};
