const SET_SELECTED_MONTH = "SET_SELECTED_MONTH";
const SET_CURRENT_YEAR = "SET_CURRENT_YEAR";
const GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED =
  "GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED";
const GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED =
  "GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED";
const GET_EVENTS_FOR_ANNUAL_SCHEDULE_FAILED =
  "GET_EVENTS_FOR_ANNUAL_SCHEDULE_FAILED";
const SET_DAILY_LIST_FOR_TOP = "SET_DAILY_LIST_FOR_TOP";
const GET_USAGE_GUIDES_REQUESTED = "GET_USAGE_GUIDES_REQUESTED";
const GET_USAGE_GUIDES_SUCCEEDED = "GET_USAGE_GUIDES_SUCCEEDED";
const GET_USAGE_GUIDES_FAILED = "GET_USAGE_GUIDES_FAILED";
const GET_CALENDAR_REQUESTED = "GET_CALENDAR_REQUESTED";
const GET_CALENDAR_SUCCEEDED = "GET_CALENDAR_SUCCEEDED";
const GET_CALENDAR_FAILED = "GET_CALENDAR_FAILED";

export {
  SET_SELECTED_MONTH,
  SET_CURRENT_YEAR,
  SET_DAILY_LIST_FOR_TOP,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_REQUESTED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_SUCCEEDED,
  GET_EVENTS_FOR_ANNUAL_SCHEDULE_FAILED,
  GET_USAGE_GUIDES_REQUESTED,
  GET_USAGE_GUIDES_SUCCEEDED,
  GET_USAGE_GUIDES_FAILED,
  GET_CALENDAR_REQUESTED,
  GET_CALENDAR_SUCCEEDED,
  GET_CALENDAR_FAILED
};
