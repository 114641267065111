import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import { color } from "../../mixins";
import EventsTopSearchBlock from "./EventsTopSearchBlock";
import EventsTopListBlock from "./EventsTopListBlock";
import AuthorityErrorModal from "./AuthorityErrorModal";
import { getEventListRequest } from "../../../store/event/events/action";
import {
  getEventsForAnnualScheduleRequest,
  setDailyListForTop
} from "../../../store/event/events_top/action";
import {
  getAllMonthlyEventsRequest,
  getMonthlyEventsRequest
} from "../../../store/event/monthly_events/action";
import FacilityHeader from "../FacilityHeader";

import { toggleImgLoadingState } from "store/camera/mediafiles/action";

function EventsTop() {
  const dispatch = useDispatch();
  const authorityErrorModalOpen = useSelector(
    (state: any) => state.modal.authority_error_modal_open
  );
  const token = useSelector((state: any) => state.session.token);
  const facility_id = useSelector((state: any) => state.facility.id);
  const filtered_year = useSelector(
    (state: any) => state.event_search.filtered_year
  );
  const filtered_month_top = useSelector(
    (state: any) => state.event_search.filtered_month_top
  );

  const calendars = useSelector((state: any) => state.events_top.calendar);

  const isImgLoading = useSelector((state: any) => state.mediafiles.imgLoading);

  // 一覧取得
  useEffect(() => {
    if (facility_id !== undefined && facility_id !== null) {
      // LoadingModuleの表示開始
      dispatch(toggleImgLoadingState(true));
      dispatch(
        getEventListRequest(
          token,
          facility_id,
          filtered_year,
          [filtered_month_top],
          [],
          ""
        )
      );
      dispatch(
        getEventsForAnnualScheduleRequest(token, facility_id, filtered_year, "")
      );
      dispatch(
        getMonthlyEventsRequest(
          token,
          facility_id,
          filtered_year,
          [filtered_month_top],
          [],
          ""
        )
      );
      dispatch(getAllMonthlyEventsRequest(token, facility_id, filtered_year));
    }
    dispatch(setDailyListForTop(filtered_year, filtered_month_top));
  }, [dispatch, token, facility_id]);

  // LoadingModuleの表示終了
  useEffect(() => {
    if (isImgLoading === true) {
      dispatch(toggleImgLoadingState(false));
    }
  }, [dispatch, facility_id, calendars]);

  return (
    <MainSC>
      <FacilityHeader />
      <ContentTitle>行事予定</ContentTitle>
      <ContentSC>
        <EventsTopSearchBlock />
        <EventsTopListBlock />
      </ContentSC>
      {authorityErrorModalOpen && <AuthorityErrorModal />}
    </MainSC>
  );
}

export default EventsTop;
// ===================================================================================
// スタイル
// ===================================================================================
const MainSC = styled.div`
  display: block;
  ${pc`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
`;

const ContentTitle = styled.div`
  padding: 0 12px;
  font-size: 18px;
  color: ${color.gray};
  font-weight: 600;
  ${pc`
    padding: 0 30px;
  `}
`;

const ContentSC = styled.div`
  padding: 20px 12px 130px;

  display: block;
  ${pc`
    padding: 20px 30px 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
  `}
  width: 100%;
`;
