import React from "react";
import { Route, Redirect, Switch } from "react-router";
import SignIn from "../components/auth/SignIn";
//FAQ ===================================================================================================
import Articles from "../components/Faq/Articles/Articles";
import ArticleDetail from "../components/Faq/Articles/ArticleDetail/ArticleDetail";
import ArticleDetailForStocks from "../components/Faq/Articles/ArticleDetail/ArticleDetailForStocks";
import ArticlesStocks from "../components/Faq/Articles/ArticlesStock/ArticlesStocks";

import Inquiries from "../components/Faq/Inquiries/Inquiries";
import InquiryNew from "../components/Faq/Inquiries/InquiryNew/InquiryNew";
import InquiriesComplete from "../components/Faq/Inquiries/InquiriesComplete";
import InquiriesDetail from "../components/Faq/Inquiries/InquiriesDetail";
//=========================================================================================================

//行事予定 ==================================================================================================
import EventTop from "../components/Events/Top/EventsTop";
import Events from "../components/Events/Events/Events";
//=========================================================================================================

// マニュアル ================================================================================================
import ManualTop from "../components/Manual/Top/ManualTop";
import ManualsTopics from "../components/Manual/Topics/Topics";
import ManualsPages from "../components/Manual/Pages/Pages";
import ManualsPageDetail from "../components/Manual/PageDetail/PageDetail";
import ManualsAdminResults from "../components/Manual/Admin/Results/Results";
//=========================================================================================================

//共通ページ ================================================================================================
import News from "../components/Global/News/News";
import NewsDetail from "../components/Global/News/NewsDetail/NewsDetail";

import Error404 from "../components/Global/Errors/404";
import { CameraHome } from "components/Camera/pages";
import { CameraTagList } from "components/Camera/pages/tag";
import { CameraDetail } from "components/Camera/pages/detail";
import { CameraUpload } from "components/Camera/pages/upload";
import InquiryNewV2 from "components/Faq/Inquiries/InquiryNewV2";

const GeneralEditorRoutes = (props: { inquiryNewPostStatus: boolean }) => {
  return (
    <Switch>
      {/* TOP */}
      <Route exact path="/" component={Articles} />

      {/* お問い合わせ */}
      <Route exact path="/inquiries" component={Inquiries} />
      <Route exact path="/inquiries/new" component={InquiryNewV2} />
      <Route exact path="/inquiries/new/:contact" component={InquiryNewV2} />
      <Route
        exact
        path="/inquiries/new/:contact/:kind"
        component={InquiryNewV2}
      />
      <Route exact path="/inquiries/complete" component={InquiriesComplete} />
      <Route exact path="/inquiries/:id" component={InquiriesDetail} />

      {/* FAQ記事 */}
      <Route exact path="/articles" component={Articles} />
      <Route exact path="/articles/stocks" component={ArticlesStocks} />
      <Route
        exact
        path="/articles/stocks/:id"
        component={ArticleDetailForStocks}
      />
      <Route exact path="/articles/:id" component={ArticleDetail} />

      {/* お知らせ */}
      <Route exact path="/news" component={News} />
      <Route exact path="/news/:id" component={NewsDetail} />

      {/* 行事予定 */}
      <Route exact path="/events/home" component={EventTop} />
      <Route exact path="/events" component={Events} />

      {/* カメラ管理 */}
      <Route exact path="/camera" component={CameraHome} />
      {/* <Route exact path="/camera/tag" component={CameraTagList} /> */}
      <Route exact path="/camera/detail/:id" component={CameraDetail} />
      <Route exact path="/mediafile-upload" component={CameraUpload} />

      {/* マニュアル */}
      {/* マニュアル 検索結果 */}
      {/* <Route path="/manual/results" component={ManualsAdminResults} /> */}

      {/* マニュアルHOME兼一覧 */}
      {/* <Route exact path="/manual" component={ManualTop} />
      <Route exact path="/manual/manuals" component={ManualTop} /> */}

      {/* マニュアル詳細兼トピック一覧 */}
      {/* <Route
        exact
        path="/manual/manuals/:manualId/topics"
        component={ManualsTopics}
      />
      <Route exact path="/manual/manuals/:manualId" component={ManualsTopics} /> */}

      {/* トピック詳細兼ページ一覧 */}
      {/* <Route
        exact
        path="/manual/manuals/:manualId/topics/:topicId/pages"
        component={ManualsPages}
      />
      <Route
        exact
        path="/manual/manuals/:manualId/topics/:topicId"
        component={ManualsPages}
      /> */}

      {/* ページ詳細 */}
      {/* <Route
        exact
        path="/manual/manuals/:manualId/topics/:topicId/pages/:pageId"
        component={ManualsPageDetail}
      /> */}
      <Route component={Error404} />
    </Switch>
  );
};
export default GeneralEditorRoutes;
