import { pc, minSp } from "components/mixins/mediaQuery";
import React from "react";
import styled from "styled-components";
import { containerBaseStyle } from "../../mixins/common";

const MainBlockContainer: React.FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default MainBlockContainer;

const Container = styled.div`
  ${containerBaseStyle}
  width: calc(100vw - 24px);
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  padding: 0px 0px;
  ${pc`
    padding: 30px;
    width: 100%;
  `}
  ${minSp`
    padding: 10px 20px;
  `}
`;
