import React from "react";
import styled from "styled-components";
import { color } from "../../mixins";

interface Props {
  name: string;
}

const ManualsItem = (props: Props) => {
  return (
    <ManualsItemSC>
      <ManualsItemContent>
        <ManualsAddInput type="text" placeholder="タイトルを入力" />
      </ManualsItemContent>
    </ManualsItemSC>
  );
};

export default ManualsItem;

const ManualsItemSC = styled.li`
  display: block;
  padding: 22px 22px 22px 55px;
  border-bottom: 1px solid ${color.list_border};
  &:hover {
    opacity: 0.6;
  }
`;

const ManualsItemContent = styled.dl`
  color: ${color.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ManualsAddInput = styled.input`
  color: ${color.black};
  &::placeholder {
    color: ${color.gray};
  }
`;
