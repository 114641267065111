import axios from "axios";
import { env_event_url, env_camera_url } from "../../serverEnv";

// 複数入力フォームの型
interface AdditionalInformation {
  destination: string;
  class: string;
}

// 行事予定一覧取得
const getEventsApi = (
  token: string,
  facility_id: number,
  year: string,
  type_ids?: string,
  months?: string,
  classes?: string,
  remand?: string
) => {
  return axios.get(`${env_event_url}/events`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      facility_id: facility_id,
      year: year,
      type_ids: type_ids,
      months: months,
      classes: classes,
      remand: remand
    }
  });
};

// 行事予定登録
function* postEventApi(
  token: string,
  facility_id: string,
  year: number,
  month: number,
  date: number,
  type_id: number,
  content: string,
  detail: string,
  class_age: string
) {
  try {
    const result = yield axios.post(
      `${env_event_url}/events`,
      {
        facility_id: facility_id,
        year: year,
        month: month,
        day: date,
        type_id: type_id,
        content: content,
        detail: detail,
        status: 1,
        class: class_age,
        kind: 1
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 複数入力行事予定登録
const postEventsMultipleApi = (
  token: string,
  facility_id: number,
  year: number,
  month: number,
  date: number,
  type_id: number,
  content: string,
  additional_information: AdditionalInformation[]
) => {
  return axios.post(
    `${env_event_url}/events`,
    {
      facility_id: facility_id,
      year: year,
      month: month,
      day: date,
      type_id: type_id,
      content: content,
      status: 1,
      kind: 1,
      additional_information: additional_information
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// 行事予定詳細取得
const getEventApi = (token: string, id: number) => {
  return axios.get(`${env_event_url}/events/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

// 行事予定編集
function* putEventApi(
  event_id: number,
  token: string,
  facility_id: number,
  year: number,
  month: number,
  date: number,
  type_id: number,
  content: string,
  detail: string,
  class_age: string
) {
  try {
    const result = yield axios.put(
      `${env_event_url}/events/${event_id}`,
      {
        facility_id: facility_id,
        year: year,
        month: month,
        day: date,
        type_id: type_id,
        content: content,
        detail: detail,
        status: 1,
        class: class_age,
        kind: 1
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("error", e.message);
  }
}

// 複数入力行事予定編集
const putEventsMultipleApi = (
  event_id: number,
  token: string,
  facility_id: number,
  year: number,
  month: number,
  date: number,
  type_id: number,
  content: string,
  additional_information: AdditionalInformation[]
) => {
  return axios.put(
    `${env_event_url}/events/${event_id}`,
    {
      facility_id: facility_id,
      year: year,
      month: month,
      day: date,
      type_id: type_id,
      content: content,
      status: 1,
      kind: 1,
      additional_information: additional_information
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

function* getEventsAfterSaveApi(
  token: string,
  facility_id: number,
  year: number,
  months?: string[],
  classes?: string[],
  remand?: string
) {
  const result = yield axios.get(`${env_event_url}/events`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      facility_id: facility_id,
      year: year,
      months: months,
      classes: classes,
      remand: remand
    }
  });
  return result;
}

// 行事予定削除
function* deleteEventApi(token: string, id: number) {
  const result = yield axios.delete(`${env_event_url}/events/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      id: id
    }
  });
  return result;
}

// 年間行事予定取得
const getEventsYearsApi = (token: string, facility_id: number) => {
  return axios.get(`${env_event_url}/events/yaers`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      facility_id: facility_id
    }
  });
};

// タイプ別行事予定取得
const getEventsTypesApi = (token: string) => {
  return axios.get(`${env_event_url}/events/types`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

// CSVダウンロード
// 行事予定一覧取得
const getEventsCSVApi = (token: string, facility_id: number, year: string) => {
  return axios.get(`${env_event_url}/csv`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      facility_id: facility_id,
      year: year
    },
    responseType: "blob"
  });
};

// 承認申請依頼をかける

// 行事予定登録
function* postEventsApplyApi(
  token: string,
  facility_id: string,
  months: string[]
) {
  try {
    return yield axios.post(
      `${env_event_url}/events/apply`,
      {
        facility_id: facility_id,
        months: months
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (e) {
    console.log("error", e);
  }
}

// 年間行事予定凡例取得
const getUsageGuidesApi = (token: string, year: number) => {
  return axios.get(`${env_event_url}/events/usage_guides`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      year: year
    }
  });
};

// 年間行事予定取得
const getCalendarApi = (token: string, facility_id: number, year: number) => {
  return axios.get(`${env_event_url}/events/calendars`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      facility_id: facility_id,
      year: year
    }
  });
};

export {
  getEventsApi,
  postEventApi,
  getEventApi,
  putEventApi,
  deleteEventApi,
  getEventsYearsApi,
  getEventsTypesApi,
  getEventsAfterSaveApi,
  getEventsCSVApi,
  postEventsApplyApi,
  getUsageGuidesApi,
  getCalendarApi,
  postEventsMultipleApi,
  putEventsMultipleApi
};
