import {
  TOGGLE_APPROVAL_MONTH_REQUEST,
  GET_EVENTS_LIST_REQUESTED,
  ADD_INTO_DELETE_ITEM_ARRAY,
  REMOVE_FROM_DELETE_ITEM_ARRAY,
  RESET_DELETE_ITEM_ARRAY,
  DELETE_EVENT_REQUESTED,
  POST_EVENT_REQUESTED,
  PUT_EVENT_REQUESTED,
  POST_EVENTS_APPLY_REQUESTED,
  GET_EVENTS_TYPE_REQUESTED
} from "./type";

interface AddEventClass {
  class_age: string;
  checked: boolean;
}
interface PutEventClass {
  class_age: string;
  checked: boolean;
}

export const toggleApprovalMonthRequest = (new_value: any) => {
  return {
    type: TOGGLE_APPROVAL_MONTH_REQUEST,
    new_value
  };
};

export const addIdIntoDeleteItemArray = (id: number) => {
  return {
    type: ADD_INTO_DELETE_ITEM_ARRAY,
    id
  };
};

export const resetDeleteItemArray = () => {
  return {
    type: RESET_DELETE_ITEM_ARRAY
  };
};

export const removeIdFromDeleteItemArray = (id: number) => {
  return {
    type: REMOVE_FROM_DELETE_ITEM_ARRAY,
    id
  };
};

export const deleteEventRequest = (
  token: string,
  id: number,
  facility_id: number,
  filtered_year: number,
  filtered_months: string[]
) => {
  return {
    type: DELETE_EVENT_REQUESTED,
    token,
    id,
    facility_id,
    filtered_year,
    filtered_months
  };
};

export const postEventRequest = (
  token: string,
  facility_id: number,
  year: number,
  month: number,
  date: number,
  type_id: number,
  content: string,
  detail: string,
  classes: AddEventClass[],
  filtered_year: number,
  filtered_month: string[],
  filtered_class: string[],
  filtered_remand: string
) => {
  return {
    type: POST_EVENT_REQUESTED,
    token,
    facility_id,
    year,
    month,
    date,
    type_id,
    content,
    detail,
    classes,
    filtered_year,
    filtered_month,
    filtered_class,
    filtered_remand
  };
};

export const putEventRequest = (
  event_id: number,
  token: string,
  facility_id: number,
  year: number,
  month: number,
  date: number,
  type_id: number,
  content: string,
  detail: string,
  classes: PutEventClass[],
  filtered_year: number,
  filtered_month: string[],
  filtered_class: string[],
  filtered_remand: string
) => {
  return {
    type: PUT_EVENT_REQUESTED,
    event_id,
    token,
    facility_id,
    year,
    month,
    date,
    type_id,
    content,
    detail,
    classes,
    filtered_year,
    filtered_month,
    filtered_class,
    filtered_remand
  };
};

// 一覧取得
export const getEventListRequest = (
  token: string,
  facility_id: number,
  filtered_year: number,
  filtered_months: string[],
  filtered_class: string[],
  filtered_remand: string,
  type_ids?: string[]
) => {
  return {
    type: GET_EVENTS_LIST_REQUESTED,
    token,
    facility_id,
    filtered_year,
    filtered_months,
    filtered_class,
    filtered_remand,
    type_ids
  };
};

// イベント承認依頼
export const postEventsApplyRequest = (
  token: string,
  facility_id: number,
  months: string[],
  filtered_year: number,
  filtered_month: string[]
) => {
  return {
    type: POST_EVENTS_APPLY_REQUESTED,
    token,
    facility_id,
    months,
    filtered_year,
    filtered_month
  };
};

//イベント種別取得
export const getEventTypesRequest = (token: string) => {
  return {
    type: GET_EVENTS_TYPE_REQUESTED,
    token
  };
};
