import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import color from "../../mixins/color";
import { pc } from "../../mixins/mediaQuery";
import ConfirmationModal from "./ConfirmationModal";
import {
  setToBeUploadedCsv,
  setTargetYear
} from "../../../store/faq/csv/action";
import pulldown_arrow from "../../../images/icons/pulldown_arrow.svg";
import { useEventYear } from "utils/eventYear";

export default function CsvUploadContainer(props: any) {
  const dispatch = useDispatch();
  const modalOpen = useSelector((state: any) => state.modal.csv_modal_open);
  const facility_id = useSelector((state: any) => state.facility.id);
  const isAll = useSelector((state: any) => state.csv.isAll);
  const targetYear = useSelector((state: any) => state.csv.year);
  const [selectedFile, setSelectedFile] = useState("");
  const inputEl = useRef<HTMLInputElement>(null);
  const { years } = useEventYear();

  // 年選択
  const handleYearSelect = (e: any) => {
    dispatch(setTargetYear(Number(e.target.value)));
  };

  return (
    <CsvUploadContainerSC>
      <UploadDesc>
        施設を選択してCSVファイルをアップロードしてください
      </UploadDesc>
      <Form>
        {/* キーワード */}
        <SearchContentsLabelSC>対象年度</SearchContentsLabelSC>
        <SelectLabel onChange={handleYearSelect} value={targetYear}>
          {years.map((year: number) => (
            <option key={year} value={year}>{`${year.toString()}年度`}</option>
          ))}
        </SelectLabel>
        <InputWrapper>
          <FileSelectButton as="label" htmlFor="fileSelect">
            ファイルを選択
          </FileSelectButton>

          <SelectedFileDisplay type="text" value={selectedFile} disabled />
          <FakeInput
            id="fileSelect"
            type="file"
            name="selectedFile"
            ref={inputEl}
            onChange={() => {
              const fileName =
                inputEl.current?.files !== null &&
                inputEl.current?.files !== undefined
                  ? inputEl.current?.files[0].name
                  : "";
              const file_data = inputEl.current?.files
                ? inputEl.current.files[0]
                : null;

              fileName !== undefined
                ? setSelectedFile(fileName)
                : setSelectedFile("");
              fileName !== ""
                ? props.setDisableButton(false)
                : props.setDisableButton(true);

              dispatch(setToBeUploadedCsv(fileName, facility_id, file_data));
            }}
          />
        </InputWrapper>
        {modalOpen && <ConfirmationModal />}
      </Form>
    </CsvUploadContainerSC>
  );
}

// ===================================================================================
// スタイル
// ===================================================================================
const CsvUploadContainerSC = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 20px 12px 130px;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  ${pc`
    max-width: 810px;
    width: calc((100% - 34.5%) - 10px);
    margin-right: 10px;
    padding: 42px 30px;
    order: 1;
  `}
`;

const Form = styled.form``;

const UploadDesc = styled.p`
  font-size: 14;
  font-weight: bold;
  margin-bottom: 38px;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  margin-top: 32px;
`;

const FakeInput = styled.input`
  display: none;
`;

const SelectedFileDisplay = styled.input`
  font-size: 14px;
  line-height: 21px;
  background-color: #f2f2f2;
  width: 136px;
  height: 38px;
  border-radius: 4px;
  padding: 12px 12px;
  box-sizing: border-box;
`;

const FileSelectButton = styled.label`
  display: inline-block;
  height: 38px;
  width: 160px;
  font-size: 14px;
  font-weight: bold;
  background-color: ${color.gray};
  color: ${color.white};
  text-align: center;
  padding: 11px;
  border-radius: 4px;
  margin-right: 12px;
  &:hover {
    opacity: 0.7;
  }
`;

const SearchContentsLabelSC = styled.dt`
  font-size: 12px;
  font-weight: 600;
  color: ${color.light_gray};
  margin-bottom: 12px;
`;

const SelectLabel = styled.select`
  background-image: url(${pulldown_arrow});
  background-repeat: no-repeat;
  background-position: top 2px right 0px;
  width: 75px;
  height: 13px;
  font-size: 12px;
  color: ${color.light_gray};
`;
