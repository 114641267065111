import React, { useMemo } from "react";
import { AccordionBoxDom, AccordionBoxDomProps } from "./dom";

export const AccordionBox = (props: AccordionBoxDomProps) => {
  //-----------------------------------------------------
  // init
  //-----------------------------------------------------

  //-----------------------------------------------------
  // memo
  //-----------------------------------------------------
  const Dom = useMemo(() => {
    return <AccordionBoxDom {...props} />;
  }, [props]);

  //-----------------------------------------------------
  // render
  //-----------------------------------------------------
  return Dom;
};
