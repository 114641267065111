import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { pc } from "../../mixins/mediaQuery";
import ArticlesItem from "./ArticlesItem";
import ArticlesItemForStocks from "./ArticlesItemForStocks";

import { resetArticleDetail } from "../../../store/faq/article_detail/action";
import { setCategoryToBeSearchedCheck } from "../../../store/faq/categories_to_be_searched/action";
import { setTagToBeSearchedCheck } from "../../../store/faq/tags_to_be_searched/action";
import {
  setKeyword,
  setSearchStatus
} from "../../../store/common/paginate/action";
import colors from "../../mixins/color";
import {
  getArticlesRequest,
  getArticlesForUserRequest
} from "../../../store/faq/articles/action";

import { toggleArticleNewPostStatus } from "../../../store/faq/article_new/action";
import { toggleArticleEditPostStatus } from "../../../store/faq/article_edit/action";
import { animateScroll as scroll } from "react-scroll";

function ArticlesList() {
  const token = useSelector((state: any) => state.session.token);
  const user_id = useSelector((state: any) => state.session.id);
  const articles = useSelector((state: any) => state.articles);
  const articles_stocked = useSelector((state: any) => state.articles_stocked);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;

  const articles_page_num = useSelector(
    (state: any) => state.session.articles_page_num
  );

  const categories_to_be_searched = useSelector(
    (state: any) => state.categories_to_be_searched
  );
  const tags_to_be_searched = useSelector(
    (state: any) => state.tags_to_be_searched
  );
  const paginate_per_page = useSelector(
    (state: any) => state.paginate.article_per_page
  );
  const keyword = useSelector((state: any) => state.paginate.article_keyword);

  const isAdmin = useSelector((state: any) => state.session.isAdmin);
  const isEditor = useSelector((state: any) => state.session.isEditor);

  const search_status = useSelector(
    (state: any) => state.paginate.search_status
  );

  const defaultContent = `{"blocks": [{"key": "2ng7o", "data": {}, "text": "test", "type": "unstyled", "depth": 0, "entityRanges": [], "inlineStyleRanges": []}], "entityMap": {}}`;

  let keywordToSearch: string = "";
  let categoriesToSearch: any[] = [];
  let tagsToSearch: any[] = [];
  // URLから検索キーワード、カテゴリ、タグを割り出してstoreに保存。またデータ取得用の変数に格納。
  const setKeywordCatTags = () => {
    // URLから検索キーワード、カテゴリ、タグを割り出す
    const searched_keyword_position = decodeURI(location.search).indexOf(
      "keyword"
    );
    const searched_categories_position = decodeURI(location.search).indexOf(
      "categories"
    );
    const searched_tags_position = decodeURI(location.search).indexOf("tags");
    let searched_keyword: any;
    let searched_categories: any;
    let searched_tags: any;
    if (
      searched_keyword_position !== -1 &&
      searched_categories_position !== -1
    ) {
      searched_keyword = decodeURI(location.search).slice(
        9,
        searched_categories_position - 1
      );
    } else if (
      searched_keyword_position !== -1 &&
      searched_categories_position === -1
    ) {
      searched_keyword = decodeURI(location.search).slice(9);
    } else {
      console.log("no searched keyword");
    }
    if (searched_categories_position !== -1 && searched_tags_position !== -1) {
      searched_categories = decodeURI(location.search)
        .slice(searched_categories_position + 11, searched_tags_position - 1)
        .split("+");
    } else if (
      searched_categories_position !== -1 &&
      searched_tags_position === -1
    ) {
      searched_categories = decodeURI(location.search)
        .slice(searched_categories_position + 11)
        .split("+");
    } else {
      console.log("no searched categories");
    }
    if (searched_tags_position !== -1) {
      searched_tags = decodeURI(location.search)
        .slice(searched_tags_position + 5)
        .split("+");
    }
    // 上で割り出したキーワード、カテゴリ、タグを元にkeyword,categories_to_be_searched,tags_to_be_searchedを設定する
    new Promise(resolve => {
      if (searched_keyword) {
        dispatch(setKeyword(searched_keyword, "article"));
        keywordToSearch = searched_keyword;
        resolve(keywordToSearch);
      } else {
        dispatch(setKeyword("", "article"));
        keywordToSearch = "";
        resolve(keywordToSearch);
      }
      if (searched_categories) {
        dispatch(setCategoryToBeSearchedCheck(searched_categories));
        categoriesToSearch = searched_categories;
      } else {
        dispatch(setCategoryToBeSearchedCheck([]));
        categoriesToSearch = [];
      }
      if (searched_tags) {
        dispatch(setTagToBeSearchedCheck(searched_tags));
        tagsToSearch = searched_tags;
      } else {
        dispatch(setTagToBeSearchedCheck([]));
        tagsToSearch = [];
      }
    });
    return {
      keyword: keywordToSearch,
      categories: categoriesToSearch,
      tags: tagsToSearch
    };
  };

  // 一覧記事取得
  useEffect(() => {
    if (!search_status) {
      (async () => {
        const searchItems = await setKeywordCatTags();
        if (isAdmin || isEditor) {
          dispatch(
            getArticlesRequest(
              token,
              pathName,
              articles_page_num,
              paginate_per_page,
              searchItems.categories,
              searchItems.tags,
              searchItems.keyword
            )
          );
        } else {
          dispatch(
            getArticlesForUserRequest(
              token,
              pathName,
              articles_page_num,
              paginate_per_page,
              searchItems.categories,
              searchItems.tags,
              searchItems.keyword
            )
          );
        }
      })();
    }
    setSearchStatus(false);
  }, [dispatch, token, user_id]);

  // 記事詳細のリセット
  useEffect(() => {
    dispatch(resetArticleDetail());
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleArticleNewPostStatus(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleArticleEditPostStatus(false));
  }, [dispatch]);

  // edit完了時
  // useEffect(() => {
  //   dispatch(setArticleEditingStatus('editing'));
  // }, [dispatch]);

  return (
    <ArticlesListSC>
      {
        // TOPもしくはFAQ記事一覧のときには記事の一覧を表示
        articles &&
          articles.length > 0 &&
          articles.map(
            (article: any) =>
              // TOPからの表示の際は、ステータスが「非公開」でなければ表示
              (article.status === 2 || article.status === 3) &&
              article.released_at !== null && (
                <ArticlesItem
                  key={article.id}
                  id={article.id}
                  title={article.title}
                  content={article.content}
                  status={article.status}
                  updated_at={article.updated_at}
                  categories={article.categories}
                  tags={article.tags}
                  stocked={article.stock}
                  read={article.read}
                  is_top={pathName === "/"}
                  className="ordinaryItem"
                />
              )
          )
      }
    </ArticlesListSC>
  );
}

export default ArticlesList;

const ArticlesListSC = styled.ul`
  width: 100%;
  border-top: 1px solid ${colors.list_border};
  border-bottom: 1px solid ${colors.list_border};
  ${pc`
    border: 1px solid ${colors.list_border};
    border-radius: 4px;
  `}
`;
