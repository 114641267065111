import {
  GET_CAMERA_TAGS_SUCCEEDED,
  GET_CAMERA_TAGS_REQUESTED,
  BATCH_CAMERA_TAGS_REQUESTED,
  BATCH_CAMERA_TAGS_SUCCEEDED,
  DELETE_CAMERA_TAGS_REQUESTED
} from "./../../../store/camera/tags/type";
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  LOGIN_STATUS_401,
  RESPONSE_STATUS_401,
  RESPONSE_STATUS_403,
  RESPONSE_STATUS_404,
  RESPONSE_STATUS_422,
  RESPONSE_STATUS_500
} from "store/session/type";
import {
  fetchTagsApi,
  batchCameraTagsUpdateApi,
  BatchTagRequestBodyType,
  deleteTagApi
} from "../api/tags";
import { CameraTag } from "../type";
import { TOGGLE_IMG_LOADING_STATE } from "store/camera/mediafiles/type";

function* getCameraTags(action: any): Generator<any, any, any> {
  try {
    const result = yield call(fetchTagsApi, { token: action.token });
    yield put({
      type: GET_CAMERA_TAGS_SUCCEEDED,
      tags: result.data.tags
    });
  } catch (e) {
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* deleteCameraTags(action: any): Generator<any, any, any> {
  try {
    yield call(deleteTagApi, { token: action.token, tagId: action.tagId });
    const result = yield call(fetchTagsApi, { token: action.token });
    console.log(result);
    yield put({
      type: GET_CAMERA_TAGS_SUCCEEDED,
      tags: result.data.tags
    });
  } catch (e) {
    console.log(e);
    const error_code = e.response.data.error_code;
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}
function* batchCameraTags(action: any): Generator<any, any, any> {
  const tags_to_be_updated: BatchTagRequestBodyType["tags"] =
    action.camera_tags.map((tag: CameraTag) => {
      return {
        id: tag.id,
        name: tag.name,
        order: tag.order ? Number(tag.order) : null,
        is_enabled: true
      };
    });
  const tags_to_be_created: BatchTagRequestBodyType["tags"] =
    action.camera_tags_to_be_created.map((tag: CameraTag) => {
      return {
        name: tag.name,
        id: null,
        order: tag.order ? Number(tag.order) : null,
        is_enabled: true
      };
    });
  const requestBody = {
    tags: [...tags_to_be_updated, ...tags_to_be_created]
  };
  try {
    yield call(batchCameraTagsUpdateApi, { token: action.token, requestBody });
    const result = yield call(fetchTagsApi, { token: action.token });
    yield all([
      put({
        type: GET_CAMERA_TAGS_SUCCEEDED,
        tags: result.data.tags
      }),
      put({
        type: BATCH_CAMERA_TAGS_SUCCEEDED
      }),
      put({
        type: TOGGLE_IMG_LOADING_STATE,
        isLoading: false
      })
    ]);
  } catch (e) {
    yield put({
      type: TOGGLE_IMG_LOADING_STATE,
      isLoading: false
    });
    const error_code = e.response.data.error_code;
    switch (error_code) {
      case 401: {
        yield all([
          put({ type: LOGIN_STATUS_401 }),
          put({ type: RESPONSE_STATUS_401 })
        ]);
        break;
      }
      case 403: {
        yield put({ type: RESPONSE_STATUS_403 });
        break;
      }
      case 404: {
        yield put({ type: RESPONSE_STATUS_404 });
        break;
      }
      case 422: {
        yield put({ type: RESPONSE_STATUS_422 });
        break;
      }
      case 500: {
        yield put({ type: RESPONSE_STATUS_500 });
        break;
      }
      default:
        yield put({ type: RESPONSE_STATUS_500 });
    }
  }
}

const cameraTagsSaga = [
  takeLatest(GET_CAMERA_TAGS_REQUESTED, getCameraTags),
  takeLatest(BATCH_CAMERA_TAGS_REQUESTED, batchCameraTags),
  takeEvery(DELETE_CAMERA_TAGS_REQUESTED, deleteCameraTags)
];

export default cameraTagsSaga;
