import styled from "styled-components";

export const FixedButtonContainerSC = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 16px;
  width: 100%;
`;
export const FixedButtonWrapperSC = styled.div`
  display: flex;
  column-gap: 40px;
  margin: 0 auto;
  width: fit-content;
`;
