import axios, { AxiosResponse } from "axios";
import { env_manual_url } from "serverEnv";
import {
  ManualCreateUpdateRequestBody,
  ManualUpdateRequestBody,
  ManualImage,
  GetManualResponseItem,
  CreateUpdateManualResponse,
  DeleteManualResponse,
  UpdateAllManualsResponse,
  CreateManualImageResponse,
  ManualImageCreateRequestBody
} from "store/manual/manuals/types.d";

export const getManualsApi = (data: { token: string }) => {
  const { token } = data;
  try {
    const result: Promise<AxiosResponse<GetManualResponseItem[]>> = axios.get(
      `${env_manual_url}/types`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const createManualApi = (data: {
  token: string;
  req_body: ManualCreateUpdateRequestBody;
}) => {
  try {
    const { token, req_body } = data;
    const result: Promise<AxiosResponse<CreateUpdateManualResponse>> =
      axios.post(
        `${env_manual_url}/types`,
        { ...req_body },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const getManualApi = (data: { token: string; id: string }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<GetManualResponseItem>> = axios.get(
      `${env_manual_url}/types/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const updateManualApi = (data: {
  token: string;
  req_body: ManualCreateUpdateRequestBody;
}) => {
  try {
    const { token, req_body } = data;
    const result: Promise<AxiosResponse<CreateUpdateManualResponse>> =
      axios.put(
        `${env_manual_url}/types/${req_body.id}`,
        { ...req_body },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const deleteManualApi = (data: { token: string; id: string }) => {
  try {
    const { token, id } = data;
    const result: Promise<AxiosResponse<DeleteManualResponse>> = axios.delete(
      `${env_manual_url}/types/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const updateAllManualsApi = (data: {
  token: string;
  req_body: ManualUpdateRequestBody[];
}) => {
  try {
    const { token, req_body } = data;

    const result: Promise<AxiosResponse<UpdateAllManualsResponse>> = axios.post(
      `${env_manual_url}/types/all`,
      {
        types: req_body
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return result;
  } catch (e) {
    console.log("axios error ", e);
    return e;
  }
};

export const createManualImageApi = (data: {
  token: string;
  // req_body: ManualImageCreateRequestBody;
  req_body: any;
}) => {
  try {
    const { token, req_body } = data;
    const result: Promise<AxiosResponse<CreateManualImageResponse>> =
      axios.post(`${env_manual_url}/types/image`, req_body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      });
    return result;
  } catch (e) {}
};
